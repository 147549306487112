import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { message, Modal } from 'antd';
import Cards from 'react-credit-cards';
import { translate } from '../../../services/i18n';
import SafeIcon from '../../../assets/svg/ic_safe_purchase.svg';
import { AuthActions, CartActions, UtilsActions } from '../../../redux/actions';
import PaymentMethod from '../../../enum/paymentMethod';
import AdvancedRadio from '../../../components/AdvancedRadio/AdvancedRadio';
import {
  cardNumberMask,
  cpfMask,
  dateCardCreditValidatedMask,
} from '../../../utils/masks';
import AdvancedSelect from '../../../components/AdvancedSelect/AdvancedSelect';
import { removeSpecialChars, validaCpf } from '../../../utils/string';
import WhatsappHelpInfo from '../../../components/WhatsappHelpInfo/WhatsappHelpInfo';
import { detectCardBrand } from '../../../enum/cardBrandType';
import * as TransactionActions from '../../../redux/actions/transaction';
import TransactionType from '../../../enum/paymentType';
import { Col } from 'react-bootstrap';
import { parseItems, setDataLayer } from '../../../services/analytics';
import AuthService from '../../../services/auth';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import * as TimerActions from '../../../redux/actions/timer';
import CheckoutCart from '../../../components/CheckoutCart/CheckoutCart';
import { useDebounce } from '../../../hook/useDebounce';
import { ReactComponent as CreditCardIcon } from '../../../assets/svg/credit_card_icon.svg';
import { ReactComponent as PixIcon } from '../../../assets/svg/pix_icon.svg';
import { ReactComponent as PadLock } from '../../../assets/svg/padlock_icon.svg';
import CheckoutInput from '../../../components/CheckoutInput/CheckoutInput';
import CartContent from '../../../components/CartContent/CartContent';
import { I18n } from 'react-redux-i18n';
import { FreightOptions } from '../../../enum/freightOptions';

const paymentFormInitialState = {
  type: PaymentMethod.CREDIT,
  cardNumber: '',
  holder: '',
  expirationDate: '',
  document: '',
  cvv: '',
  id: '',
  installments: 1,
};

const CheckoutStepFour = ({
  upperPaymentForm,
  changePaymentData,
  paymentData,
  createCreditCard,
  calculateTheCart,
  getInstalmentOptions,
  recurrent,
  createTransaction,
  values,
  prescription,
  coupon,
  me,
  getCards,
  products,
  installmentOptions,
  payment,
  removeCoupon,
  qsCoupon,
  changeQsCoupon,
  cleanTheCart,
  getMe,
  stopTimer,
  userActivity,
  freightType
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [ focused, setFocused ] = useState('');
  const [ paymentForm, setPaymentForm ] = useState(paymentFormInitialState);

  const [ errorModalVisible, setErrorModalVisible ] = useState(false);
  const [ nextButtonStyle, setNextButtonStyle ] = useState('buy-button');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    AuthService.isFromEcommerceDomain();

    setPaymentForm({
      ...paymentFormInitialState,
      ...paymentData,
    });

    if (values?.total <= 0 || recurrent) {
      await handleChangeForm(
        'type',
        PaymentMethod.CREDIT,
        setPaymentForm,
        paymentForm,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname, qsCoupon, values?.total, coupon ]);

  const { setDebounce, clearDebounce } = useDebounce();

  const useQuery = new URLSearchParams(window.location.search);
  const validateCard = useQuery.get('validatecard');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (validateCard) {
      const response = await getCards();

      if (response && response.length > 0) {
        setPaymentForm({
          ...paymentForm,
          cardNumber: response[0].cardNumber,
          holder: response[0].holder,
          expirationDate: response[0].expirationDate,
          document: response[0].secretDocument,
          id: response[0].id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    upperPaymentForm(paymentForm, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paymentForm ]);

  useEffect(() => {
    setTimeout(() => {
      if (!products.length) {
        history.push('/checkout/revisao');
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname, products ]);

  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname ]);

  useEffect(() => {
    if (coupon && values && values.total <= coupon.value && recurrent) {
      errorCouponValue();
    }

    getInstalmentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ coupon ]);

  useEffect(() => {
    if (recurrent) {
      handleChangeForm(
        'type',
        PaymentMethod.CREDIT,
        setPaymentForm,
        paymentForm,
      );
      onRemoveCoupon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ recurrent ]);

  const errorCouponValue = () => {
    onRemoveCoupon();
    setErrorModalVisible(true);
  };

  const onRemoveCoupon = () => {
    removeCoupon();
    changeQsCoupon(null);
    getInstalmentOptions();
    calculateTheCart();
  };

  const handleChangeForm = async (name, value, setForm, form) => {
    const newValues = { ...form, [name]: value };

    if (name === 'type' && recurrent) {
      newValues.type = PaymentMethod.CREDIT;
      newValues.installments = 1;
    }

    setForm(newValues);
    changePaymentData(newValues);

    if (name === 'installments') {
      calculateTheCart();
    }
  };

  const onNextClick = async () => {
    const brand = detectCardBrand(removeSpecialChars(paymentForm.cardNumber));

    if (paymentForm.type === PaymentMethod.CREDIT) {
      if (!paymentForm.id || !paymentForm.id.trim()) {
        if (!paymentForm.cardNumber) {
          return message.error('Informe o número do cartão.');
        }

        if (!brand) {
          return message.error('Preencha o número do cartão corretamente.');
        }

        if (!paymentForm.holder) {
          return message.error('Informe o nome do titular do cartão.');
        }

        if (!paymentForm.expirationDate) {
          return message.error('Informe a data de expiração.');
        }

        if (!paymentForm.cvv) {
          return message.error('Informe o CVV do cartão.');
        }

        if (!paymentForm.document) {
          return message.error('Informe o CPF do titular.');
        }

        if (!validaCpf(removeSpecialChars(paymentForm.document))) {
          return message.error('O CPF informado não é válido.');
        }
      }

      setNextButtonStyle('buy-button-disabled');

      const formatCreditCard = {
        ...paymentForm,
        ...me,
        zipcode: removeSpecialChars(me.zipcode),
        cardNumber: removeSpecialChars(paymentForm.cardNumber),
        brandType: detectCardBrand(removeSpecialChars(paymentForm.cardNumber)),
        document: removeSpecialChars(paymentForm.document),
        label: 'Cartão de Crédito',
        installments: paymentForm.installments,
      };

      try {
        const dataLayerPayload = {
          ecommerce: {
            value: values && values?.total.toString(),
            currency: 'BRL',
            coupon: coupon?.title,
            coupon_value: coupon && coupon?.value && coupon?.value.toString(),
            payment_type: 'credit',
          },
        };

        setDataLayer('add_payment_info', dataLayerPayload);
      } catch (e) {
        //
      }

      let responseCreatedCard;

      if (!paymentForm.id || !paymentForm.id.trim()) {
        responseCreatedCard = await createCreditCard(formatCreditCard);
      }

      if (
        (paymentForm.id && paymentForm.id.trim() !== '') ||
        (responseCreatedCard && responseCreatedCard.status === 200)
      ) {
        const transactionPayload = {
          ...me,
          zipcode: removeSpecialChars(me.zipcode),
          products,
          type: recurrent
            ? TransactionType.RECURRENCE
            : TransactionType.PAYMENT,
          prescriptionId: prescription && prescription.id,
          coupon: recurrent ? null : coupon && coupon.title,
          installments: paymentForm.installments,
          freightType: freightType || FreightOptions.DEFAULT
        };

        const resultCreateTransaction = await createTransaction(
          transactionPayload,
        );

        if (resultCreateTransaction) {
          const paymentPayload = {
            antiFraudSessionId: null,
            type: 'credit',
            creditCardId:
              paymentForm.id && paymentForm.id.trim() !== ''
                ? paymentForm.id
                : responseCreatedCard.data.id,
            installments: paymentForm.installments,
          };

          const resultPayment = await payment(
            resultCreateTransaction.data.id,
            paymentPayload,
          );

          const dataLayerPayload = {
            ecommerce: {
              transaction_id: resultPayment.code,
              affiliation: 'Site The Mens',
              value: resultPayment.total,
              shipping: resultPayment.freightValue,
              currency: 'BRL',
              coupon: coupon || null,
              coupon_value: resultPayment.couponValue || null,
              items: parseItems(resultPayment.transactionItems),
              recurring: (recurrent && recurrent.toString()) || 'false',
            },
          };

          try {
            userActivity(
              {
                transactionId: resultPayment.id,
                value: resultPayment.total,
                source: pathname,
              },
              facebookUserActivity.initCheckout,
            );

            userActivity(
              {
                source: pathname,
              },
              facebookUserActivity.paymentMethod,
            );
          } catch (e) {
            console.log({ e2: e });
          }

          if (resultPayment && !resultPayment.error) {
            stopTimer();
            history.push('/checkout/resultado-pagamento');
            setDataLayer('purchase', dataLayerPayload);
            setTimeout(() => cleanTheCart(true), 100);
          }
        } else {
          setNextButtonStyle('buy-button');
          clearDebounce();

          message.error(
            'Algo saiu errado, verifique os dados informados e tente novamente.',
          );
        }
      } else {
        setNextButtonStyle('buy-button');
        clearDebounce();

        message.error(
          'Algo saiu errado, verifique os dados informados e tente novamente.',
        );
      }
    } else if (
      paymentForm.type === PaymentMethod.BANK_SLIP ||
      paymentForm.type === PaymentMethod.PIX
    ) {
      setNextButtonStyle('buy-button-disabled');

      const transactionPayload = {
        ...me,
        zipcode: removeSpecialChars(me.zipcode),
        products,
        type: TransactionType.PAYMENT,
        prescriptionId: prescription && prescription.id,
        coupon: recurrent ? null : coupon && coupon.title,
        freightType: freightType || FreightOptions.DEFAULT
      };

      const resultCreateTransaction = await createTransaction(
        transactionPayload,
      );

      if (resultCreateTransaction) {
        const paymentPayload = {
          antiFraudSessionId: null,
          type:
            paymentForm.type === PaymentMethod.BANK_SLIP ? 'bank_slip' : 'pix',
        };

        const response = await payment(
          resultCreateTransaction.data.id,
          paymentPayload,
        );

        if (!response.error) {
          try {
            const dataLayerPayload = {
              ecommerce: {
                transaction_id: response.code,
                affiliation: 'Site The Mens',
                value: response.total,
                shipping: response.freightValue,
                currency: 'BRL',
                coupon: coupon || null,
                coupon_value: response.couponValue || null,
                items: parseItems(response.transactionItems),
                recurring: (recurrent && recurrent.toString()) || 'false',
              },
            };

            userActivity(
              {
                transactionId: response.id,
                value: response.total,
                source: pathname,
              },
              facebookUserActivity.initCheckout,
            );

            userActivity(
              {
                source: pathname,
              },
              facebookUserActivity.paymentMethod,
            );

            setNextButtonStyle('buy-button-disabled');
            setDataLayer('purchase', dataLayerPayload);
          } catch (e) {
            //
          }

          stopTimer();
          history.push('/checkout/resultado-pagamento');
          setTimeout(() => cleanTheCart(true), 100);
        }

        setNextButtonStyle('buy-button');
        clearDebounce();
      } else {
        setNextButtonStyle('buy-button');
        clearDebounce();

        message.error(
          'Algo saiu errado, verifique os dados informados e tente novamente.',
        );
      }
    }

    changePaymentData(paymentForm);
  };

  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const onBackClick = () => {
    history.push('/checkout/dados-de-entrega');
  };

  return (
    <CartContent
      pageTitle="Pagamento"
      removeFooter={window.innerWidth <= 1100}
      boxed
      padded
      onNextClick={onNextClick}
      debounce={setDebounce}
      nextButtonStyle={nextButtonStyle}
    >
      <div>
        <div
          className="checkout checkout--step-three"
          style={{ paddingTop: '62px' }}
        >
          <Modal
            className="checkout__coupon-modal"
            visible={errorModalVisible}
            centered
            title={null}
            onOk={() => setErrorModalVisible(false)}
            okText="Entendi"
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            O cupom inserido tem o valor de desconto{' '}
            <strong>maior ou igual</strong> ao valor do carrinho.
            <br />
            <br />
            Para <strong>assinaturas</strong>, utilize um cupom de desconto com
            o valor menor que o valor do carrinho.
          </Modal>

          <button
            className="transparent-button checkout__go-back mobile-hidden"
            onClick={() => onBackClick()}
          >
            <ArrowLeftOutlined />
            <span className="checkout__go-back__text">
              {translate('CHECKOUT.STEP_THREE.BACK')}
            </span>
          </button>
          <div className="mobile-visible">
            <CheckoutCart
              onNextClick={onNextClick}
              debounce={setDebounce}
              nextButtonStyle={nextButtonStyle}
            />
          </div>
          <div className="checkout__upper checkout__upper--three">
            <div className="checkout__upper__title">
              <h2 className="checkout__upper__title__text">
                {translate('CHECKOUT.STEP_FOUR.PAYMENT')}
              </h2>
            </div>
          </div>

          <div className="checkout__inner">
            <div className="checkout__content">
              <div className="checkout__content__item">
                <div className="checkout__content__item__label__input">
                  <AdvancedRadio
                    value={paymentForm.type}
                    onChange={(val) =>
                      handleChangeForm('type', val, setPaymentForm, paymentForm)
                    }
                    className="select__type"
                    items={[
                      {
                        icon: <CreditCardIcon className="select__type__icon" />,
                        value: PaymentMethod.CREDIT,
                      },
                      {
                        icon: <PixIcon className="select__type__icon" />,
                        value: PaymentMethod.PIX,
                        disabled: recurrent || values?.total <= 0,
                      },
                    ]}
                  />
                  {recurrent && (
                    <div className="checkout__content__recurrent-info">
                      {translate('CHECKOUT.STEP_FOUR.RECURRENT_INFO')}
                    </div>
                  )}
                  {values?.total <= 0 && (
                    <div className="checkout__content__recurrent-info">
                      {translate('CHECKOUT.STEP_FOUR.RECURRENT_INFO_CARD')}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {paymentForm.type === PaymentMethod.CREDIT && (
              <div>
                <div>
                  <div className="checkout__content__form__card-label">
                    <Col className="no-padding">
                      <div className="checkout__content__form__item__label__input">
                        <CheckoutInput
                          value={paymentForm.cardNumber}
                          onChange={(e) =>
                            handleChangeForm(
                              'cardNumber',
                              cardNumberMask(e.target.value),
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate(
                            'CHECKOUT.STEP_FOUR.LABELS.CARD_NUMBER',
                          )}
                          type="tel"
                          onFocus={handleInputFocus}
                          name="number"
                          disabled={paymentForm.id}
                        />
                      </div>
                      <div className="checkout__content__form__item__label__input">
                        <CheckoutInput
                          value={paymentForm.holder}
                          onChange={(e) =>
                            handleChangeForm(
                              'holder',
                              e.target.value && e.target.value.toUpperCase(),
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate(
                            'CHECKOUT.STEP_FOUR.LABELS.CARD_NAME',
                          )}
                          onFocus={handleInputFocus}
                          name="name"
                          disabled={paymentForm.id}
                        />
                      </div>
                    </Col>

                    <div className="checkout__content__card">
                      <Cards
                        number={paymentForm.cardNumber}
                        name={paymentForm.holder}
                        expiry={paymentForm.expirationDate}
                        focused={focused}
                        cvc={paymentForm.cvv}
                        locale={{ valid: 'Validade' }}
                        placeholders={{
                          name: 'Nome do titular',
                        }}
                      />
                    </div>
                  </div>
                  <div className="checkout__content__form__card-info mobile-hidden">
                    <Col lg={paymentForm.id ? '6' : '4'}
                      className="no-padding">
                      <div className="checkout__content__form__item__label__input">
                        <CheckoutInput
                          value={paymentForm.expirationDate}
                          onChange={(e) =>
                            e.target.value.length <= 5 &&
                            handleChangeForm(
                              'expirationDate',
                              dateCardCreditValidatedMask(e.target.value),
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate(
                            'CHECKOUT.STEP_FOUR.LABELS.VALID_DATE',
                          )}
                          placeholder="__/__"
                          type="tel"
                          onFocus={handleInputFocus}
                          name="expiry"
                          disabled={paymentForm.id}
                        />
                      </div>
                    </Col>
                    <Col
                      lg={4}
                      className={
                        paymentForm.id ? 'checkout__hidden' : 'no-padding'
                      }
                    >
                      <div
                        className={
                          paymentForm.id
                            ? 'checkout__hidden'
                            : 'checkout__content__form__item__label__input checkout__content__form__item__label__input--padded'
                        }
                      >
                        <CheckoutInput
                          value={paymentForm.cvv}
                          onChange={(e) =>
                            e.target.value.length <= 4 &&
                            handleChangeForm(
                              'cvv',
                              e.target.value,
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate('CHECKOUT.STEP_FOUR.LABELS.CVV')}
                          maxLength={4}
                          type="tel"
                          onFocus={handleInputFocus}
                          name="cvc"
                          disabled={paymentForm.id}
                        />
                      </div>
                    </Col>
                    <Col lg={paymentForm.id ? '6' : '4'}
                      className="no-padding">
                      <div className="checkout__content__form__item__label__input checkout__content__form__item__label__input--padded">
                        <CheckoutInput
                          value={paymentForm.document}
                          onChange={(e) =>
                            handleChangeForm(
                              'document',
                              cpfMask(e.target.value),
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate('CHECKOUT.STEP_FOUR.LABELS.CPF')}
                          type="tel"
                          disabled={paymentForm.id}
                        />
                      </div>
                    </Col>
                  </div>
                  <div className="checkout__content__form__card-info--mobile mobile-visible">
                    <div className="checkout__content__form__card-info--mobile-items">
                      <Col
                        lg={paymentForm.id ? '6' : '4'}
                        className="no-padding"
                      >
                        <div className="checkout__content__form__item__label__input">
                          <CheckoutInput
                            value={paymentForm.expirationDate}
                            onChange={(e) =>
                              e.target.value.length <= 5 &&
                              handleChangeForm(
                                'expirationDate',
                                dateCardCreditValidatedMask(e.target.value),
                                setPaymentForm,
                                paymentForm,
                              )
                            }
                            label={translate(
                              'CHECKOUT.STEP_FOUR.LABELS.VALID_DATE',
                            )}
                            placeholder="__/__"
                            type="tel"
                            onFocus={handleInputFocus}
                            name="expiry"
                            disabled={paymentForm.id}
                          />
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        className={
                          paymentForm.id ? 'checkout__hidden' : 'no-padding'
                        }
                      >
                        <div
                          className={
                            paymentForm.id
                              ? 'checkout__hidden'
                              : 'checkout__content__form__item__label__input checkout__content__form__item__label__input--padded'
                          }
                        >
                          <CheckoutInput
                            value={paymentForm.cvv}
                            onChange={(e) =>
                              e.target.value.length <= 4 &&
                              handleChangeForm(
                                'cvv',
                                e.target.value,
                                setPaymentForm,
                                paymentForm,
                              )
                            }
                            label={translate('CHECKOUT.STEP_FOUR.LABELS.CVV')}
                            maxLength={4}
                            type="tel"
                            onFocus={handleInputFocus}
                            name="cvc"
                            disabled={paymentForm.id}
                          />
                        </div>
                      </Col>
                    </div>
                    <Col lg={paymentForm.id ? '6' : '4'}
                      className="no-padding">
                      <div className="checkout__content__form__item__label__input checkout__content__form__item__label__input--padded">
                        <CheckoutInput
                          value={paymentForm.document}
                          onChange={(e) =>
                            handleChangeForm(
                              'document',
                              cpfMask(e.target.value),
                              setPaymentForm,
                              paymentForm,
                            )
                          }
                          label={translate('CHECKOUT.STEP_FOUR.LABELS.CPF')}
                          type="tel"
                          disabled={paymentForm.id}
                        />
                      </div>
                    </Col>
                  </div>
                  {values && (
                    <div className="checkout__content__form__item__label__input">
                      <AdvancedSelect
                        borderRadius={15}
                        height={70}
                        fontFamily={'PrimeformProRegular'}
                        label={translate('CHECKOUT.STEP_FOUR.LABELS.NUMBER')}
                        onChange={(e) =>
                          handleChangeForm(
                            'installments',
                            e,
                            setPaymentForm,
                            paymentForm,
                          )
                        }
                        value={!recurrent ? paymentForm.installments : 1}
                        removeGreater={recurrent}
                        noPadding
                        items={installmentOptions}
                      />
                    </div>
                  )}
                </div>
                {recurrent && (
                  <div className="checkout__content__recurrent-info">
                    {translate('CHECKOUT.STEP_FOUR.RECURRENT_INFO_INSTALLMENT')}
                  </div>
                )}
              </div>
            )}

            {paymentForm.type === PaymentMethod.BANK_SLIP && (
              <div className="checkout__content__info">
                <img
                  className="checkout__content__info__logo"
                  src="/assets/img/ic_billet.png"
                  alt="billet logo"
                />
                <div className="checkout__content__info__text">
                  Iremos enviar o boleto para o e-mail{' '}
                  <strong>{me && me.email}</strong>, que está cadastrado em sua
                  conta.
                </div>
                <div className="checkout__content__form">
                  <div className="checkout__content__form__safe-icon">
                    <img src={SafeIcon}
                      alt=""
                      width="200px" />
                  </div>
                </div>
              </div>
            )}

            {paymentForm.type === PaymentMethod.PIX && (
              <div className="checkout__content__info">
                <img
                  className="checkout__content__info__logo"
                  src="/assets/img/ic_pix_logo.png"
                  alt="pix logo"
                />
                <div className="checkout__content__info__text">
                  {translate('COMPONENTS.APPROVED.ITEMS_OTHER.FOUR.TEXT')}
                  <br />
                  <WhatsappHelpInfo />
                  <br />
                </div>
              </div>
            )}
          </div>
          <>
            <div className="checkout-security-buy">
              <span className="checkout-security-buy__icon">
                <PadLock />
              </span>
              {I18n.t('CHECKOUT.STEP_FIVE.SAFE_BUY')}
            </div>
          </>
        </div>
        <Col className="mobile-no-padding mobile-visible">
          <div
            className={`checkout--step-four__${nextButtonStyle}`}
            onClick={() => setDebounce(() => onNextClick())}
          >
            <div className="checkout--step-four__buy-button__item">
              <span className="checkout--step-four__buy-button__item__text">
                {I18n.t('CHECKOUT.STEP_FOUR.BUY')}
              </span>
            </div>
          </div>
        </Col>
      </div>
    </CartContent>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
  products: state.cart.products || [],
  values: state.cart.values || null,
  recurrent: state.cart.recurrent || false,
  paymentData: state.cart.paymentData || null,
  prescription: state.cart.prescription || null,
  coupon: state.cart.coupon || null,
  qsCoupon: state.cart.qsCoupon || null,
  installmentOptions: state.cart.installmentOptions || [],
  freightType: state.cart.freightType,
});

const mapDispatchToProps = (dispatch) => ({
  getCards: () => dispatch(UtilsActions.getMyCards()),
  calculateTheCart: () => dispatch(CartActions.calculateTheCart()),
  getInstalmentOptions: () => dispatch(CartActions.getInstalmentOptions()),
  changePaymentData: (data) => dispatch(CartActions.changePaymentData(data)),
  createCreditCard: (data) =>
    dispatch(TransactionActions.createCreditCard(data)),
  createTransaction: (productsList) =>
    dispatch(TransactionActions.createTransaction(productsList)),
  payment: (idTransaction, body, callback) =>
    dispatch(TransactionActions.payment(idTransaction, body, callback)),
  getCouponByTitle: (title) => dispatch(CartActions.getCouponByTitle(title)),
  removeCoupon: () => dispatch(CartActions.removeCoupon()),
  changeQsCoupon: (value) => dispatch(CartActions.changeQsCoupon(value)),
  cleanTheCart: (keepValues) => dispatch(CartActions.cleanTheCart(keepValues)),
  getMe: () => dispatch(AuthActions.getMe()),
  userActivity: (params, userActivity) =>
    dispatch(FacebookActions.userActivity(params, userActivity)),
  stopTimer: () => dispatch(TimerActions.decreaseTimer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepFour);

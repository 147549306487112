import { MedicalAppointmentsActions } from '../actions';

const initialState = {
  allMedicalAppointments: [],
  medicalAppointmentByID: null,
  mineMedicalAppointments: [],
};

const medicalAppointmentReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case MedicalAppointmentsActions.ACTION_GET_ALL_MEDICAL_APPOINTMENTS:
      state = {
        ...state,
        allMedicalAppointments: action.payload,
      };
      return state;
    case MedicalAppointmentsActions.ACTION_GET_MEDICAL_APPOINTMENT_BY_ID:
      state = {
        ...state,
        medicalAppointmentByID: action.payload,
      };
      return state;
    case MedicalAppointmentsActions.ACTION_GET_MINE_MEDICAL_APPOINTMENT:
      state = {
        ...state,
        mineMedicalAppointments: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default medicalAppointmentReducer;

import { RecurrenceActions } from '../actions';

const initialState = {
  recurrence: null,
};

const recurrenceReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case RecurrenceActions.ACTION_GET_RECURRENCE:
      state = {
        ...state,
        recurrence: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default recurrenceReducer;
import React, { useEffect } from 'react';
import { translate } from '../../../services/i18n';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import VerticalBoxSlider from '../../../components/VerticalBoxSlider/VerticalBoxSlider';
import FeatureSection from '../../../components/FeatureSection/FeatureSection';
import FeatureSectionFull from '../../../components/FeatureSectionFull/FeatureSectionFull';
import FeatureCarousel from '../../../components/FeatureCarousel/FeatureCarousel';
import PromoSection from '../../../components/PromoSection/PromoSection';
import AuthService from '../../../services/auth';

const headerCarouselItems = [
  {
    id: 1,
    title: '',
    description: '',
    imageUrl: '/assets/img/vertical-slider/img_vertical_header_one.png',
  },
  {
    id: 2,
    title: '',
    description: '',
    imageUrl: '/assets/img/vertical-slider/img_vertical_header_one.png',
  },
  {
    id: 3,
    title: '',
    description: '',
    imageUrl: '/assets/img/vertical-slider/img_vertical_header_one.png',
  },
];

const Help = (props) => {

  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('body').scrollTo(0,0);
    AuthService.isFromEcommerceDomain();
  }, [ pathname ]);

  return (
    <div className="search">
      <Container>
        <Row className="search__row">
          <Col lg={8}>
            <Link to={translate('PAGES.HOME.HAIR_KIT.URL')}>
              <VerticalBoxSlider
                items={headerCarouselItems}
              />
            </Link>
          </Col>
          <Col lg={4}>
            <input
              type="text"
              className="search__row__input"
              placeholder={translate('PAGES.AUTH.SEARCH.PLACEHOLDER')}
            />
          </Col>
        </Row>
      </Container>
      <div className="home">
        <div className="home__section">
          <FeatureSection
            title={translate('COMPONENTS.FEATURE_SECTION.ONE.TITLE')}
            description={translate('COMPONENTS.FEATURE_SECTION.ONE.DESCRIPTION')}
            buttomText={translate('COMPONENTS.FEATURE_SECTION.ONE.BUTTOM_TEXT')}
            imageUrl={translate('COMPONENTS.FEATURE_SECTION.ONE.IMAGE_URL')}
            buttomUrl={translate('COMPONENTS.FEATURE_SECTION.ONE.BUTTOM_URL')}
            id='40c5747d-1b87-4768-a3aa-c1186a9e3b1f'
          />

          <FeatureSection
            title={translate('COMPONENTS.FEATURE_SECTION.TWO.TITLE')}
            description={translate('COMPONENTS.FEATURE_SECTION.TWO.DESCRIPTION')}
            buttomText={translate('COMPONENTS.FEATURE_SECTION.TWO.BUTTOM_TEXT')}
            imageUrl={translate('COMPONENTS.FEATURE_SECTION.TWO.IMAGE_URL')}
            buttomUrl={translate('COMPONENTS.FEATURE_SECTION.TWO.BUTTOM_URL')}
            reverse
            id='3b445d33-0849-4976-aa07-11cc61770141'
          />

          <FeatureSection
            title={translate('COMPONENTS.FEATURE_SECTION.THREE.TITLE')}
            description={translate('COMPONENTS.FEATURE_SECTION.THREE.DESCRIPTION')}
            buttomText={translate('COMPONENTS.FEATURE_SECTION.THREE.BUTTOM_TEXT')}
            imageUrl={translate('COMPONENTS.FEATURE_SECTION.THREE.IMAGE_URL')}
            buttomUrl={translate('COMPONENTS.FEATURE_SECTION.THREE.BUTTOM_URL')}
            id='fbd7397f-8071-4f0a-9a64-90debfbf908a'
          />

          <FeatureSection
            title={translate('COMPONENTS.FEATURE_SECTION.FOUR.TITLE')}
            description={translate('COMPONENTS.FEATURE_SECTION.FOUR.DESCRIPTION')}
            buttomText={translate('COMPONENTS.FEATURE_SECTION.FOUR.BUTTOM_TEXT')}
            imageUrl={translate('COMPONENTS.FEATURE_SECTION.FOUR.IMAGE_URL')}
            buttomUrl={translate('COMPONENTS.FEATURE_SECTION.FOUR.BUTTOM_URL')}
            reverse
            id='2ad58d0e-5934-4f1a-9d51-8cba378122b6'
          />

          <FeatureSectionFull
            title={translate('COMPONENTS.FEATURE_SECTION_FULL.ONE.TITLE')}
            description={translate('COMPONENTS.FEATURE_SECTION_FULL.ONE.DESCRIPTION')}
            buttomText={translate('COMPONENTS.FEATURE_SECTION_FULL.ONE.BUTTOM_TEXT')}
            imageUrl={translate('COMPONENTS.FEATURE_SECTION_FULL.ONE.IMAGE_URL')}
            buttomUrl={translate('COMPONENTS.FEATURE_SECTION_FULL.ONE.BUTTOM_URL')}
            id='2ad58d0e-5934-4f1a-9d51-8cba378122b6'
          />

          <FeatureCarousel />

          <PromoSection
            title={translate('COMPONENTS.PROMO_SECTION.ONE.TITLE')}
            product={translate('COMPONENTS.PROMO_SECTION.ONE.PRODUCT')}
            image={translate('COMPONENTS.PROMO_SECTION.ONE.IMAGE')}
            buttonText={translate('COMPONENTS.PROMO_SECTION.ONE.BUTTON_TEXT')}
            buttonLink={translate('COMPONENTS.PROMO_SECTION.ONE.BUTTON_LINK')}
            bottomInfo={translate('COMPONENTS.PROMO_SECTION.ONE.BOTTOM_INFO')}
          />
        </div>
      </div>
    </div>
  );
};

export default Help;

import React from 'react';
import { toCurrencyLocale } from '../../utils/masks';
import { ArrowRightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PaymentMethod from '../../enum/paymentMethod';

const CheckoutFloatingBar = ({
  values,
  freight,
  products,
  loading,
  onNextClick,
  disabled,
  nextLabel,
  coupon,
  recurrent,
  paymentData,
  removed,
}) => {

  const getProductCountAmount = () => products.reduce((a, b) => a + b.quantity, 0);

  return (
    <div className="checkout-floating-bar">
      {window.innerWidth <= 1100 && (
        <div className="checkout-floating-bar__meta">
          <div className="checkout-floating-bar__meta__total">
            <span className="checkout-floating-bar__meta__total__title">
              Subtotal:&nbsp;
            </span>
            <strong>{toCurrencyLocale(values?.total)}</strong>
            <span className="checkout-floating-bar__meta__total">
              &nbsp;{paymentData?.type === PaymentMethod.CREDIT ? ` (${paymentData.installments}x ${paymentData.installments > 3 ? 'com juros' : 'sem juros'})` : '(à vista ou 3x)'}
            </span>
          </div>

          {!recurrent && freight && (
            <div className="checkout-floating-bar__meta__total">
              <span className="checkout-floating-bar__meta__total__title">
                Frete:&nbsp;
              </span>
              <strong>
                {freight.freight && parseFloat(freight.freight) > 0 && values?.total > 0
                  ? toCurrencyLocale(freight.freight)
                  : <strong>GRÁTIS!</strong>}
                {recurrent && ' (assinatura)'}
              </strong>
              {freight && freight.deadline && (
                <span>
                  &nbsp;({freight.deadline} dia{freight.deadline > 1 && 's'} útei{freight.deadline > 1 && 's'})
                </span>
              )}
            </div>
          )}

          {recurrent && (
            <div className="checkout-floating-bar__meta__total">
              <span className="checkout-floating-bar__meta__total__title">
                Frete:&nbsp;
              </span>
              <strong>
                GRÁTIS!
              </strong>
              {freight && freight.deadline && (
                <span>
                  &nbsp;({freight.deadline} dia{freight.deadline > 1 && 's'} útei{freight.deadline > 1 && 's'})
                </span>
              )}
            </div>
          )}


          <div className="checkout-floating-bar__meta__quantity">
            {getProductCountAmount()} produto{getProductCountAmount() > 1 ? 's' : ''}
            {coupon && (
              <span className="checkout-floating-bar__meta__total__coupon">
                | Cupom: <strong>{toCurrencyLocale(coupon.value)}</strong>
              </span>
            )}
          </div>
        </div>
      )}

      <div className="checkout-floating-bar__actions">
        {!removed && (
          <button
            className="checkout-floating-bar__actions__button"
            onClick={() => onNextClick()}
            disabled={disabled || loading > 0 || products.length <= 0}
          >
            {nextLabel}
            <span className="checkout-floating-bar__actions__button__icon">
              <ArrowRightOutlined />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  products: state.cart.products || [],
  freight: state.cart.freight || null,
  coupon: state.cart.coupon || null,
  values: state.cart.values || null,
  recurrent: state.cart.recurrent || false,
  paymentData: state.cart.paymentData || null,
});

export default connect(mapStateToProps)(CheckoutFloatingBar);

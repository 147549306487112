import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';
import { PersistGate } from 'redux-persist/integration/react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';

import languages from './app/i18n';
import { initAmplitude } from './app/utils/amplitude';
import './app/styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import createStore from './app/redux/reducers/createStore';
import AppConfig from './app/config/app.config';
import themeConfig from './app/config/theme';

window.newrelic = window.newrelic || {};

export const { store, persistor } = createStore();

const envLanguage = process.env.LANGUAGE || 'pt';
syncTranslationWithStore(store);
store.dispatch(loadTranslations(languages));
store.dispatch(setLocale(envLanguage));
initAmplitude();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeConfig['@primary-color'],
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: themeConfig['@primary-color'],
      },
      colorSecondary: {
        '&$checked': {
          color: themeConfig['@primary-color'],
        },
      },
    },
  },
});

Bugsnag.start({
  apiKey: AppConfig.bugsnag.token,
  plugins: [ new BugsnagPluginReact() ]
});

const ErrorBoundary = Bugsnag
  .getPlugin('react')
  .createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null}
        persistor={persistor}>
        <HelmetProvider>
          <ThemeProvider theme={theme} >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'antd';
import Slider from '@ant-design/react-slick';
import { connect } from 'react-redux';
import { ReactComponent as CartIcon } from '../../../assets/svg/new_menu/ic_header_bag.svg';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';
import {
  ArrowRightOutlined, CloseOutlined
} from '@ant-design/icons';
import { Col, Container, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import * as ActionsCart from '../../../redux/actions/cart';
import AuthService from '../../../services/auth';
import { keepOnlyNumbers } from '../../../utils/string';
import { toCurrencyLocale } from '../../../utils/masks';
import hairKitItems from '../../../services/hairKitItems';
import hairKitFaq from '../../../services/hairKitFaq';
import { getProductByCode } from '../../../services/product';
import RecomendedProducts from '../../../components/RecomendedProducts/RecomendedProducts';
import SubscriptionMobile from './SubscriptionMobile';
import { translate } from '../../../services/i18n';

const Subscription = ({
  replaceCartProducts,
  calculateFreight,
  me
}) => {
  const [ heroImage, setHeroImage ] = useState(0);
  const [ padding, setPadding ] = useState(0);
  const [ cartModalOpen, setCartModalOpen ] = useState(false);
  const [ zipcode, setZipcode ] = useState('');
  const [ freightData, setFreightData ] = useState(null);
  const [ dropDown, setDropDown ] = useState(null);
  const [ activeCollapse, setActiveCollapse ] = useState(null);
  const sliderRef = useRef();
  const { Panel } = Collapse;
  const heroImages = [
    '/assets/img/hair-kit/hero/1.png',
    '/assets/img/hair-kit/hero/2.png',
    '/assets/img/hair-kit/hero/3.jpg',
  ];


  const products = [ 4, 5, 6, 7 ].map((o) => ({
    ...getProductByCode(o),
    quantity: 1,
  }));

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    centerMode: true,
    centerPadding: '8%',
  };

  useEffect(() => {
    const getScrolPosition = () => {
      productRightPadding(document.body.scrollTop);
    };

    document.querySelector('body').addEventListener('scroll', getScrolPosition);

    return () => {
      document.querySelector('body').addEventListener('scroll', getScrolPosition);
    };
  }, []);


  useEffect(() => {
    AuthService.isFromEcommerceDomain();
  });

  const productRightPadding = (pagePosition) => {
    if (document.querySelector('.page-header')) {
      if (pagePosition < document.querySelector('.page-header')?.clientHeight) {
        setPadding(pagePosition);
      }
    }
  };

  const addProductToCart = () => {
    if (!me || !me?.notNeedMedicalPrescription) {
      setCartModalOpen(true);
    } else {
      replaceCartProducts(products, true);
    }
  };

  const manageDropDown = (index) => {
    if (index === dropDown) {
      setDropDown(null);
    } else {
      setDropDown(index);
    }
  };

  const onCalculateFreight = () => {
    if (zipcode) {
      calculateFreight(zipcode, products, (response) => {
        if (response && !response.error) {
          setFreightData(response.freight);
        } else {
          setFreightData({ error: true });
        }
      });
    }
  };

  const goToMedicalAppointment = () => {
    const { host, protocol } = window.location;
    window.open(`${protocol}//${host}/avaliacao?fromProduct=1`, '_blank');
  };


  const cartModal = () => {
    return (
      <div className="cart-modal">
        <div onClick={() => setCartModalOpen(false)}
          className="cart-modal__close">
          <div className="cart-modal__close__button">
            <CloseOutlined />
          </div>
          <p className="cart-modal__text">
            {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.TEXT')}<strong>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.PRESCRIPTION')}</strong>
            <p>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.NO_PRESCRIPTION')}</p>
          </p>
          <div
            onClick={() => {
              goToMedicalAppointment();
            }}
            className="cart-modal__confirm"
          >
            FAZER MINHA 1ª AVALIAÇÃO
          </div>
          <div
            onClick={() => {
              setCartModalOpen(false);
              replaceCartProducts(products, true);
            }}
            className="cart-modal__deny"
          >
            JÁ TENHO AVALIAÇÃO
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <div className="subscription mobile-hidden">
        <div className="subscription__left">
          <div className="subscription__left__carousel">
            <div className="subscription__left__carousel__image-container">
              <img className="subscription__left__carousel__image-container__img"
                src={heroImages[heroImage]} />
            </div>
            <div className="subscription__left__carousel__clickable">
              <div onClick={() => setHeroImage(0)}
                className="subscription__left__carousel__clickable__item">
                <img
                  className={`subscription__left__carousel__clickable__item__img ${heroImage === 0 && 'subscription__left__carousel__clickable__item__img--selected'}`}
                  src="/assets/img/hair-kit/hero/mini-1.jpg"
                />
              </div>
              <div onClick={() => setHeroImage(1)}
                className="subscription__left__carousel__clickable__item">
                <img
                  className={`subscription__left__carousel__clickable__item__img ${heroImage === 1 && 'subscription__left__carousel__clickable__item__img--selected'}`}
                  src="/assets/img/hair-kit/hero/mini-2.jpg"
                />
              </div>
              <div onClick={() => setHeroImage(2)}
                className="subscription__left__carousel__clickable__item">
                <img
                  className={`subscription__left__carousel__clickable__item__img ${heroImage === 2 && 'subscription__left__carousel__clickable__item__img--selected'}`}
                  src="/assets/img/hair-kit/hero/mini-3.png"
                />
              </div>
            </div>
          </div>
          <div className="subscription__left__content-one">
            <div className="subscription__left__content-one__image-container">
              <img className="subscription__left__content-one__image-container__img"
                src="/assets/img/hair-kit/signature-page/1.png" />
            </div>
            <div onClick={() => goToMedicalAppointment()}
              className="subscription__left__content-one__button">
              Iniciar consulta médica
            </div>
          </div>
          <div className="subscription__left__content-two">
            <h3 className="subscription__left__content-two__title">
              Antes e depois
            </h3>
            <p className="subscription__left__content-two__subtitle">
              Fotos de membros The Men’s tiradas após o tratamento usando o hair kit
            </p>
            <div className="subscription__left__content-two__slider">
              <Slider {...settings}
                ref={sliderRef}>
                <div className="subscription__left__content-two__slider__item">
                  <img className="subscription__left__content-two__slider__item__img"
                    src="/assets/img/hair-kit/treatment/1.jpg" />
                  <p className="subscription__left__content-two__slider__item__text">
                    3 meses de tratamento
                  </p>
                </div>
                <div className="subscription__left__content-two__slider__item">
                  <img className="subscription__left__content-two__slider__item__img"
                    src="/assets/img/hair-kit/treatment/4.jpg" />
                  <p className="subscription__left__content-two__slider__item__text">
                    4 meses de tratamento
                  </p>
                </div>
                <div className="subscription__left__content-two__slider__item">
                  <img className="subscription__left__content-two__slider__item__img"
                    src="/assets/img/hair-kit/treatment/6.png" />
                  <p className="subscription__left__content-two__slider__item__text">
                    3 meses de tratamento
                  </p>
                </div>
                <div className="subscription__left__content-two__slider__item">
                  <img className="subscription__left__content-two__slider__item__img"
                    src="/assets/img/hair-kit/treatment/7.png" />
                  <p className="subscription__left__content-two__slider__item__text">
                    2 meses de tratamento
                  </p>
                </div>
              </Slider>
            </div>
            <div className="subscription__left__content-two__slider-next">
              <img onClick={() => sliderRef.current.slickNext()}
                className="subscription__left__content-two__slider-next__img"
                src="/assets/img/hair-kit/arrow.png" />
            </div>
          </div>
          <div className="subscription__left__content-three">
            <h3 className="subscription__left__content-three__title">
              O quarteto fantástico
            </h3>
            <p className="subscription__left__content-three__subtitle">
              Conheça os 4 produtos que compõem o hair kit completo
            </p>
            <div className="subscription__left__content-three__dropdown">
              {hairKitItems && hairKitItems.map((item, index) =>
                <>
                  <div className="subscription__left__content-three__dropdown__item">
                    <div className="subscription__left__content-three__dropdown__item__left">
                      <h4 className="subscription__left__content-three__dropdown__item__title">
                        {ReactHtmlParser(item.title)}
                      </h4>
                      {dropDown === index && (
                        <div className="subscription__left__content-three__dropdown__item__text">
                          {ReactHtmlParser(item.text)}
                        </div>
                      )}
                    </div>
                    {dropDown === index && (
                      <>
                        <img className="subscription__left__content-three__dropdown__item__img"
                          src={item.img} />
                      </>
                    )}
                    <div
                      onClick={() => manageDropDown(index)}
                      className={`subscription__left__content-three__dropdown__item__arrow ${dropDown === index && 'subscription__left__content-three__dropdown__item__arrow--active'}`}
                    >
                      <ArrowDown />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="subscription__left__content-four">
            <img className="subscription__left__content-four__img"
              src="/assets/img/hair-kit/content-4.png" />
          </div>
          <div className="subscription__left__content-five">
            <img className="subscription__left__content-four__img"
              src="/assets/img/hair-kit/content-5.png" />
          </div>
          <div className="subscription__left__content-six">
            <h3 className="subscription__left__content-six__title">
              Sobre queda de cabelo
            </h3>
            <div className="subscription__left__content-six__slider">
              <div className="subscription__left__content-six__slider__item">
                <img className="subscription__left__content-six__slider__item__img"
                  src="/assets/img/hair-kit/hair-loss/1.png" />
                <p className="subscription__left__content-six__slider__item__text">
                  Tratamentos para a calvície masculina: Conheça todos
                </p>
                <a href="https://blog.themens.com.br/calvicie-masculina-causa-sintomas-e-possiveis-tratamentos/">Ler este artigo</a>
              </div>
              <div className="subscription__left__content-six__slider__item">
                <img className="subscription__left__content-six__slider__item__img"
                  src="/assets/img/hair-kit/hair-loss/2.png" />
                <p className="subscription__left__content-six__slider__item__text">
                  Minoxidil e o efeito shedding: uma explicação científica!
                </p>
                <a href="https://blog.themens.com.br/guia-da-finasterida-o-que-voce-precisa-saber/">Ler este artigo</a>
              </div>
              <div className="subscription__left__content-six__slider__item">
                <img className="subscription__left__content-six__slider__item__img"
                  src="/assets/img/hair-kit/hair-loss/3.png" />
                <p className="subscription__left__content-six__slider__item__text">
                  Fases da calvície: Saiba quais são e o que é a Escala de Nordwood
                </p>
                <a href="https://blog.themens.com.br/minoxidil-e-o-efeito-shedding-uma-explicacao-cientifica/">Ler este artigo</a>
              </div>
            </div>
          </div>
        </div>
        <div className="subscription__right">
          <div className="subscription__right__inner">
            <div style={{ height: `${padding }px` }}></div>
            {cartModalOpen ? cartModal() :
              <>
                <div className="subscription__right__inner__top">
                  <h3 className="subscription__right__inner__top__title">
                    <strong className="subscription__right__inner__top__title__bold">
                      Hair Kit
                    </strong>
                    4.9/5.0
                    <span className="subscription__right__inner__top__title__star">
                      ★
                    </span>
                  </h3>
                  <div className="subscription__right__inner__top__price">
                    <p className="subscription__right__inner__top__price__text">A partir de</p>
                    <p className="subscription__right__inner__top__price__value"> R$93,90 </p>
                  </div>
                  <div onClick={() => addProductToCart()}
                    className="subscription__right__inner__top__button">
                    ADICIONAR NA SACOLA <CartIcon />
                  </div>
                </div>
                <div className="subscription__right__inner__mid">
                  <div className="subscription__right__inner__mid__image-container">
                    <img src="/assets/img/hair-kit/info/product-info.svg"
                      className="subscription__right__inner__mid__image-container__img" />
                  </div>
                  <p className="subscription__right__inner__mid__text">
                    Tratamento personalizado para mais cabelos.
                  </p>
                  <p className="subscription__right__inner__mid__text">
                    Receba seu kit em casa e conte com acompanhamento médico online por todo o tratamento.
                  </p>
                  <p className="subscription__right__inner__mid__anvisa">
                    {translate('HAIR_KIT.TEXT')} <a href="https://blog.themens.com.br/outros/registros-aprovacoes-e-eficacia/"><u>{translate('HAIR_KIT.APPROVALS')}</u></a>
                  </p>
                </div>
                <div className="product__info__right__inner__calc">
                  <div className="product__info__right__inner__calc__form">
                    <div className="product__info__right__inner__calc__form__container">
                      <div className="product__info__right__inner__calc__form__input">
                        <input
                          className={`product__info__right__inner__calc__form__input__element ${zipcode !== '' && 'product__info__right__inner__calc__form__input__element--hidden'}`}
                          type="text"
                          value={zipcode}
                          placeholder="Digite o CEP de entrega"
                          onChange={(e) => (
                            e.target.value &&
                            e.target.value.length <= 8 ||
                            !e.target.value) &&
                            setZipcode(keepOnlyNumbers(e.target.value))
                          }
                        />
                      </div>
                      <div className="product__info__right__inner__calc__form__button">
                        <button
                          className="product__info__right__inner__calc__form__button__inner"
                          onClick={() => onCalculateFreight()}
                          disabled={zipcode?.length < 8}
                        >
                          <ArrowRightOutlined />
                        </button>
                      </div>
                      <div className="product__info__right__inner__calc__form__container__text-shipping">
                        <p>{translate('COMPONENTS.PRODUCT.SHIPPING.DESCRIPTION')}</p>
                      </div>
                    </div>
                    {freightData && !freightData.error && (
                      <div className="product__info__right__inner__calc__form__freight-success">
                        <div className="product__info__right__inner__calc__form__freight-success__city">
                          Região: {freightData.city}
                        </div>
                        <div className="product__info__right__inner__calc__form__freight-success__data">
                          Normal - {Number(freightData.deadline)} dias úteis - <strong>{freightData.freight === 0 ? 'Frete Grátis' : toCurrencyLocale(freightData.freight)}</strong>
                        </div>
                      </div>
                    )}
                    {freightData && freightData.error && (
                      <div>
                        <div className="product__info__right__inner__calc__form__freight-error">
                          Querido The Lovers, ainda não operamos na sua região, mas queremos chegar ai! Acreditamos que
                          todos os brasileiros devam se cuidar de forma prática e segura.
                          <br />
                          Estamos trabalhando duro para atingirmos todo o Brasil até o final do ano.
                        </div>
                        <br />
                        <div className="product__info__right__inner__calc__form__freight-normal">
                          Caso queira ser um dos primeiros a se cuidar com the men&apos;s na sua região,
                          entre agora na nossa lista exclusiva:&nbsp;
                          <a href="mailto:memanda@themens.com.br">memanda@themens.com.br</a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="subscription__right__inner__mid__safe-buy">
                  <img src="/assets/img/hair-kit/info/safe-buy.png"
                    className="subscription__right__inner__mid__safe-buy__img" />
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="subscribe-bottom mobile-hidden">
        <div className="product__faq">
          <Container>
            <Row>
              <Col>
                <div className="product__faq__title">
                  Perguntas frequentes
                </div>
              </Col>
            </Row>
            <Row>
              {hairKitFaq.map((faqItem, faqItemIndex) => (
                <Col
                  lg={6}
                  key={faqItemIndex.toString()}
                >
                  <Collapse
                    className="product__faq__collapse"
                    bordered={false}
                    accordion
                    onChange={(e) => setActiveCollapse(e)}
                    activeKey={activeCollapse}
                  >
                    <Panel
                      className="product__faq__collapse__panel"
                      header={ReactHtmlParser(faqItem.title)}
                      key={faqItemIndex.toString()}
                    >
                      {ReactHtmlParser(faqItem.description)}
                    </Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div className="product__phrase">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <img
                  className="product__phrase__img"
                  src="/assets/img/products-images/the-mens-delivery-phrase.jpg"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="mobile-hidden">
        <RecomendedProducts />
      </div>
      <div className="mobile-visible">
        <SubscriptionMobile />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quantById: state.product.quantById,
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  replaceCartProducts: (products, recurrent) => dispatch(ActionsCart.replaceCartProducts(products, recurrent)),
  calculateFreight: (zipcode, products, callback) => dispatch(ActionsCart.calculateFreight({
    products,
    calculateFreight: true,
    zipcode
  }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);


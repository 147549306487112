import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const AdvancedInputPassword = ({
  value,
  placeholder,
  disabled,
  onChange,
  className,
  passwordInput,
  suffix,
  type,
}) => {
  const [ showPassword, setShowPassword ] = useState(false);

  return (

    <Input
      className={className}
      type={passwordInput ? (showPassword ? 'text' : 'password') : type || 'text'}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      suffix={
        passwordInput ? (
          <Button
            onClick={() => setShowPassword(!showPassword)}
            shape="circle"
            icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          />
        ) : (
          suffix
        )
      }
    />
  );
};

export default AdvancedInputPassword;
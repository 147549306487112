import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PrescriptionActions } from '../../../../redux/actions';
import { translate } from '../../../../services/i18n';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { products } from '../../../../services/product';


const CollapsePrescription = ({
  prescription,
  getPrescription,
}) => {

  useEffect(() => {
    getPrescription();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {prescription?.rows?.length ? prescription.rows.map((item) => {
        const product = products.find((productItem) => productItem.id === item.prescribedProductsList);

        return (
          <div
            key={item.prescribedProductsList}
            className="register__profile__prescription-container__box"
          >
            <Col xs
              lg={10}>
              <div className="register__profile__prescription-container__title">
                {product?.name}
              </div>
              <div className="register__profile__prescription-container__subtitle">
                {moment(item.createdAt).format('DD/MM/YYYY') || ''}
              </div>
              {moment(item.createdAt).add(30, 'days').format('DD/MM/YYYY') && (
                <a className="register__profile__prescription-container__link"
                  href="/avaliacao">{translate('PAGES.HOME.PROFILE_REGISTER.FORM.PRESCRIPTION.NEW')}</a>
              )}
            </Col>
            {moment(item.createdAt).add(30, 'days').format('DD/MM/YYYY') ? (
              <p className="register__profile__prescription-container__box__disabled">
                  Vencida
              </p>
            ) : (
              <p className="register__profile__prescription-container__box__active">
                  Ativa
              </p>
            )}
          </div>
        );
      }) : (
        <p className="register__profile__prescription-container__no-prescription">{translate('PAGES.HOME.PROFILE_REGISTER.FORM.PRESCRIPTION.NOTHING')}</p>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  prescription: state.prescription.prescription,
});

const mapDispatchToProps = (dispatch) => ({
  getPrescription: () => dispatch(PrescriptionActions.getPrescription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapsePrescription);
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrescriptionStep from './PrescriptionStep/PrescriptionStep';
import { getPages, QuestionType } from '../../services/prescription';
import PrescriptionContent from '../../components/PrescriptionContent/PrescriptionContent';
import PrescriptionPayment from './PrescriptionPayment/PrescriptionPayment';

const PrescriptionNavigationStack = () => {

  return (
    <div>
      <Switch>
        {getPages(QuestionType.MELATONINA).map((page, pageIndex) => (
          <Route
            path={`/questionario/melatonina/passo-${page}`}
            key={pageIndex.toString()}
          >
            <PrescriptionContent pageTitle={`Questionário de Melatonina (passo ${page})`}>
              <PrescriptionStep
                page={page}
                type={QuestionType.MELATONINA}
              />
            </PrescriptionContent>
          </Route>
        ))}

        {getPages(QuestionType.ED).map((page, pageIndex) => (
          <Route
            path={`/questionario/ed/passo-${page}`}
            key={pageIndex.toString()}
          >
            <PrescriptionContent pageTitle={`Questionário de ED (passo ${page})`}>
              <PrescriptionStep
                page={page}
                type={QuestionType.ED}
              />
            </PrescriptionContent>
          </Route>
        ))}

        {getPages(QuestionType.EJ).map((page, pageIndex) => (
          <Route
            path={`/questionario/ej/passo-${page}`}
            key={pageIndex.toString()}
          >
            <PrescriptionContent pageTitle={`Questionário de EJ (passo ${page})`}>
              <PrescriptionStep
                page={page}
                type={QuestionType.EJ}
              />
            </PrescriptionContent>
          </Route>
        ))}

        {getPages(QuestionType.HAIR_LOSS).map((page, pageIndex) => (
          <Route
            path={`/questionario/quedadecabelo/passo-${page}`}
            key={pageIndex.toString()}
          >
            <PrescriptionContent pageTitle={`Questionário de queda de cabelo (passo ${page})`}>
              <PrescriptionStep
                page={page}
                type={QuestionType.HAIR_LOSS}
              />
            </PrescriptionContent>
          </Route>
        ))}
        <Route
          path={'/questionario/ed/pagamento'}
        >
          <PrescriptionContent pageTitle={'Pagamento'}>
            <PrescriptionPayment type={QuestionType.ED}/>
          </PrescriptionContent>
        </Route>
        <Route
          path={'/questionario/melatonina/pagamento'}
        >
          <PrescriptionContent pageTitle={'Pagamento'}>
            <PrescriptionPayment type={QuestionType.MELATONINA}/>
          </PrescriptionContent>
        </Route>
        <Route
          path={'/questionario/quedadecabelo/pagamento'}
        >
          <PrescriptionContent pageTitle={'Pagamento'}>
            <PrescriptionPayment type={QuestionType.HAIR_LOSS}/>
          </PrescriptionContent>
        </Route>
        <Route
          path={'/questionario/ej/pagamento'}
        >
          <PrescriptionContent pageTitle={'Pagamento'}>
            <PrescriptionPayment type={QuestionType.EJ}/>
          </PrescriptionContent>
        </Route>
      </Switch>
    </div>
  );
};

export default PrescriptionNavigationStack;

import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';
import { I18n } from 'react-redux-i18n';
import { message } from 'antd';

import * as MessageService from '../../services/message';
import { decreaseLoading, increaseLoading } from './loading';
import { setDataLayer } from '../../services/analytics';

export const ACTION_AUTH_LOGIN = 'auth:ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGIN_CHECK = 'auth:ACTION_AUTH_LOGIN_CHECK';
export const ACTION_AUTH_LOGOUT = 'auth:ACTION_AUTH_LOGOUT';
export const ACTION_AUTH_ME = 'auth:ACTION_AUTH_ME';
export const ACTION_SET_FAKE_EMAIL = 'auth:ACTION_SET_FAKE_EMAIL';

export const authenticate = (userData, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let payload;

  try {
    userData = {
      ...userData,
      grantType: 'password',
    };
    payload = await AuthRequests.auth(userData);
    AuthService.create(payload);

    dispatch({
      type: ACTION_AUTH_LOGIN,
      payload,
    });
    await dispatch(getMe());

    setDataLayer('login', {
      method: 'password',
    });

    MessageService.success('SUCCESS.LOGGED');

    if (callback && typeof callback === 'function') {
      callback({
        ...payload,
        error: false,
      });
    }
  } catch (err) {
    if (callback && typeof callback === 'function') {
      callback({ error: true });
    }

    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const authenticateV2 = (userData, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let payload;

  try {
    userData = {
      ...userData,
      grantType: 'password',
    };
    payload = await AuthRequests.auth(userData);
    AuthService.create(payload);

    dispatch({
      type: ACTION_AUTH_LOGIN,
      payload,
    });
    await dispatch(getMe());

    setDataLayer('login', {
      method: 'password',
    });

    MessageService.success('SUCCESS.LOGGED');

    if (callback && typeof callback === 'function') {
      callback({
        ...payload,
        error: false,
      });
    }
  } catch (err) {
    if (callback && typeof callback === 'function') {
      callback({ error: true });
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const logout = () => (dispatch) => {
  dispatch(increaseLoading());

  try {
    dispatch({
      type: ACTION_AUTH_LOGOUT,
    });
    AuthService.reset();
    setTimeout(() => {
      window.location.href = '/';
    }, 10);
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  AuthService.reset();
};

export const getMe = () => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await AuthRequests.me();
    dispatch({
      type: ACTION_AUTH_ME,
      payload,
    });
    return payload;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const sendRecoveryEmail = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    data = {
      ...data,
      sendTo: 'email',
    };
    await AuthRequests.sendRecoveryEmail(data);
    message.success(I18n.t('SUCCESS.SEND_RECOVERY_EMAIL'));

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message === 'entity_not_found') {
      return message.error(
        'Usuário não encontrado, você digitou o e-mail corretamente?',
      );
    }

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const passwordRecovery = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await AuthRequests.passwordRecovery(data);
    message.success(I18n.t('SUCCESS.RECOVERY_PASSWORD'));

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const newsLetter = (payload, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await AuthRequests.newsLetter(payload);

    if (callback && typeof callback === 'function') {
      callback(true);
    }
  } catch (error) {
    if (callback && typeof callback === 'function') {
      callback(false);
    }

    throw error;
  } finally {
    dispatch(decreaseLoading());
  }
};

export const refresh = (refreshToken) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const auth = await AuthService.refresh(refreshToken);
    AuthService.create(auth);

    dispatch({
      type: ACTION_AUTH_LOGIN,
      payload: auth,
    });
    return auth;
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const checkLogged = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGIN_CHECK,
    payload: !!payload,
  });
};

export const setFakeEmail = (email) => async (dispatch) => {
  dispatch({
    type: ACTION_SET_FAKE_EMAIL,
    payload: email,
  });
};

import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ActionProducts from '../../redux/actions/products';

const FeatureSectionFull = (props) => {
  const { getProductByID } = props;

  const getProduct = async () => {
    await getProductByID(props.id);
  };

  return (
    <div className="feature-section-full">
      <Container>
        <Row className={`${props.reverse ? 'flex-row-reverse' : ''}`}>
          <Col lg={6}>
            <div className="feature-section-full__container__wrapper">
              {props.home && (
                <img
                  className="feature-section-full__container__wrapper__img mobile-visible"
                  src="/assets/img/the-mens-gummy-banner.png"
                  alt=""
                />
              )}
              <div className="feature-section-full__container__inner">
                <div className="feature-section-full__container__inner__title">
                  {props.title}
                </div>
                <div className="feature-section-full__container__inner__description">
                  {props.description}
                </div>
                <div className="feature-section-full__container__inner__buttom">
                  <Link
                    onClick={() => getProduct()}
                    className="feature-section-full__container__inner__buttom__link"
                    to={props.buttomUrl}
                  >
                    <span className="feature-section-full__container__inner__buttom__link__inner">
                      {props.buttomText}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className="feature-section-full__image mobile-hidden"
        src={props.imageUrl}
        alt=""
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  productByID: state.product.productByID,
});

const mapDispatchToProps = (dispatch) => ({
  getProductByID: (id) => dispatch(ActionProducts.getProductByID(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSectionFull);

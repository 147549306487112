import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as AuthActions from '../../../../redux/actions/auth';
import * as UserActions from '../../../../redux/actions/user';
import { translate } from '../../../../services/i18n';

import { cpfMask, phoneMask } from '../../../../utils/masks';

import AuthService from '../../../../services/auth';
import { ReactComponent as EditPencil } from '../../../../assets/svg/edit_pencil.svg';
import { ProfileRegister } from '../../../../enum/profileForm';
import CollapseEdit from './CollapseEdit';
import { ProfileType } from '../../../../enum/profileType';


const CollapseProfile = ({
  getMe,
  updateUser,
  loading,
  me,
}) => {
  const [ form, setForm ] = useState({
    email: '',
    phone: '',
    password: '',
    document: '',
    firstName: '',
    lastName: '',
    grantType: '',
    birthDate: '',
    profileType: ProfileType.USER,
    lastname: '',
    confirmpassword: '',
    oldpassword: '',
  });

  useEffect(async () => {
    AuthService.isFromEcommerceDomain();
    await getMe();
  }, []);

  const [ profileScreen, setProfileScreen ] = useState(ProfileRegister.REGISTER_PROFILE);

  const {
    email,
    phone,
    password,
    document,
    firstName,
    lastName,
    birthDate,
  } = form;

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        email: me.email,
        phone: me.phone,
        password: me.password,
        document: me.document,
        firstName: me.name.split(' ') && me.name.split(' ').length > 0 ? me.name.split(' ')[0] : me.name,
        lastName: me.name.split(' ') && me.name.split(' ').length > 0 ? me.name.split(' ')[me.name.split(' ').length - 1] : '',
        grantType: me.grantType,
        birthDate: moment(me.birthDate).format('DD/MM/YYYY') || '',
        profileType: me.profileType,
        lastname: me.lastname,
        confirmpassword: me.confirmpassword,
        reference: me.reference,
        oldpassword: me.oldpassword,
      });
    }
  }, [ me ]);

  function onEdit () {
    setProfileScreen(ProfileRegister.EDIT_PROFILE);
  }

  return (
    <>
      {profileScreen === ProfileRegister.REGISTER_PROFILE ? (
        <>
          <div className="register__profile__profile-container__item__text">
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.NAME')}
              <input
                name='firstName'
                className='register__profile__profile-container__item__text__user-item'
                value={`${firstName} ${lastName}`}
                disabled
              />
            </div>
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.EMAIL')}
              <input
                name="document"
                className='register__profile__profile-container__item__text__user-item'
                value={email && email.toLowerCase()}
                disabled
              />
            </div>
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.PASSWORD')}
              <input className='register__profile__profile-container__item__text__user-item'
                placeholder="********"
                type='password'
                disabled
              />
            </div>
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.FONE')}
              <input
                name='phone'
                className='register__profile__profile-container__item__text__user-item'
                value={phoneMask(phone)}
                disabled
              />
            </div>
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.BIRTH')}
              <input
                name="birthDate"
                className='register__profile__profile-container__item__text__user-item'
                value={birthDate || (me && moment(me.birthDate).format('DD/MM/YYYY'))}
                disabled
              />
            </div>
            <div className="register__profile__profile-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.CPF')}
              <input
                name="document"
                className='register__profile__profile-container__item__text__user-item'
                value={cpfMask(document)}
                disabled
              />
            </div>
            <div className='register__profile__profile-container__edit-button'>
              <a className="register__profile__dropdown__address-container__item__edit"
                onClick={onEdit}><EditPencil />EDITAR</a>
              <hr className='underline' />
            </div>
          </div>
        </>
      ) : (<CollapseEdit />)}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseProfile);

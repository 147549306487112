import ProductRequests from '../../api/products';

import * as MessageService from '../../services/message';
import { decreaseLoading, increaseLoading } from './loading';

export const ACTION_SET_ALL_PRODUCTS = 'product:ACTION_SET_ALL_PRODUCTS';
export const ACTION_GET_PRODUCT_BY_ID = 'product:ACTION_GET_PRODUCT_BY_ID';
export const ACTION_INCREASE_QUANT = 'product:ACTION_INCREASE_QUANT';
export const ACTION_DESCREASE_QUANT = 'product:ACTION_DESCREASE_QUANT';

export const increaseQuant = (value) => ({
  type: ACTION_INCREASE_QUANT,
  payload: value,
});

export const decreaseQuant = (value) => ({
  type: ACTION_DESCREASE_QUANT,
  payload: value,
});

export const setAllProducts = () => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await ProductRequests.getAllProducts();
    dispatch({
      type: ACTION_SET_ALL_PRODUCTS,
      payload,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getProductByID = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await ProductRequests.getProductByID(id);
    dispatch({
      type: ACTION_GET_PRODUCT_BY_ID,
      payload,
    });
    return payload;
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { message, Radio, Space } from 'antd';
import { Col } from 'react-bootstrap';
import { AuthActions, CartActions, PrescriptionActions, UserActions } from '../../../redux/actions';
import { removeSpecialChars } from '../../../utils/string';
import AuthService from '../../../services/auth';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';
import { pixelTrack } from '../../../utils/pixel';
import CheckoutCart from '../../../components/CheckoutCart/CheckoutCart';
import { ReactComponent as Info } from '../../../assets/svg/info.svg';
import { translate } from '../../../services/i18n';
import CartContent from '../../../components/CartContent/CartContent';
import { I18n } from 'react-redux-i18n';
import AdvancedFreight from '../../../components/AdvancedFreight/AdvancedFreight';
import * as ActionsCart from '../../../redux/actions/cart';
import { FreightOptions } from '../../../enum/freightOptions';
import { FreightOption } from '../../../components/FreightOption/FreightOption';

const addressFormInitialState = {
  zipcode: '',
  street: '',
  addressNumber: '',
  neighborhood: '',
  city: '',
  uf: '',
  complement: '',
  phone: '',
};

const CheckoutStepThree = ({
  getAddressByZipcode,
  addressByZipcode,
  updateUserProfile,
  freight,
  freightOptions,
  products,
  calculateTheCart,
  me,
  getMe,
  calculateFreight,
  freightType
}) => {
  const history = useHistory();
  const [ deliveryAddressForm, setDeliveryAddressForm ] = useState(addressFormInitialState);
  const [ zipcodeObtained, setZipcodeObtained ] = useState(false);
  const [ firstIteration, setFirstIteration ] = useState(false);
  const [ freightOption, setFreightOption ] = useState(freightType || FreightOptions.DEFAULT);

  const { pathname } = useLocation();

  useEffect(() => {
    if (freightType) {
      const selectedFreight = freightOptions.find(item => item.freightType === freightType);
      setFreightOption(!selectedFreight.available ? FreightOptions.DEFAULT : freightType);
    } else {
      setFreightOption(freightType || FreightOptions.DEFAULT);
    }

    AuthService.isFromEcommerceDomain();
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname ]);

  useEffect(() => {
    setTimeout(() => {
      if (!products.length) {
        history.push('/checkout/revisao');
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname, products ]);

  useEffect(() => {
    if (me && (me.zipcode || me.phone)) {
      setDeliveryAddressForm(me);
      setZipcodeObtained(true);
    }
  }, [ me ]);

  useEffect(() => {
    if (me && me.zipcode && !addressByZipcode) {
      onZipcodeUpdate(me.zipcode);
      setZipcodeObtained(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ me, addressByZipcode ]);

  useEffect(() => {
    if (addressByZipcode) {
      setDeliveryAddressForm({
        ...addressByZipcode,
        complement: deliveryAddressForm.complement || (me && me.complement),
        addressNumber: deliveryAddressForm.addressNumber || (me && me.addressNumber),
      });
    } else {
      setDeliveryAddressForm({
        ...me,
        zipcode: removeSpecialChars(me?.zipcode),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ addressByZipcode ]);

  const onNextClick = async () => {

    if (!deliveryAddressForm.zipcode || !zipcodeObtained) {
      return message.error('Informe o CEP.');
    }

    if (!deliveryAddressForm.street) {
      return message.error('Informe a rua.');
    }

    if (!deliveryAddressForm.addressNumber) {
      return message.error('Informe o número.');
    }

    if (!deliveryAddressForm.city) {
      return message.error('Informe a cidade.');
    }

    if (!deliveryAddressForm.uf) {
      return message.error('Informe o estado.');
    }

    const payload = {
      zipcode: removeSpecialChars(deliveryAddressForm.zipcode),
      street: deliveryAddressForm.street,
      addressNumber: deliveryAddressForm.addressNumber,
      neighborhood: deliveryAddressForm.neighborhood,
      city: deliveryAddressForm.city,
      uf: deliveryAddressForm.uf,
      complement: deliveryAddressForm.complement,
    };

    updateUserProfile(me.id, payload, () => {
      history.push('/checkout/pagamento');
    });
  };

  const onBackClick = () => {
    history.push('/checkout/revisao');
  };

  const onZipcodeUpdate = async (zipcode) => {
    if (zipcode && removeSpecialChars(zipcode).length === 8) {
      const zipCodeFound = await getAddressByZipcode(zipcode);

      if (!zipCodeFound) {
        setZipcodeObtained(false);
        return;
      }

      await calculateTheCart();
      setZipcodeObtained(true);
    }
  };

  const handleChangeForm = async (name, value) => {
    if (!firstIteration) {
      pixelTrack('startCartInfo', {
        name: me.name,
        email: me.email,
        id: me.id,
      }, true);
      setFirstIteration(true);
    }

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setDeliveryAddressForm({ ...deliveryAddressForm, [name]: value });

    if (name === 'zipcode') {
      await onZipcodeUpdate(value);
    }
  };


  const onCalculateFreight = () => {
    const data = {
      products,
      freightType: freightOption,
      zipcode: removeSpecialChars(deliveryAddressForm.zipcode),
    };

    calculateFreight(data);
    calculateTheCart();
  };

  useEffect(() => {
    onCalculateFreight();
  }, [ freightOption ]);

  return (
    <CartContent
      pageTitle="Dados de entrega"
      removeFooter={window.innerWidth <= 1100}
      boxed
      finished
      padded
      onNextClick={onNextClick}
    >
      <div
        className="checkout checkout--step-three"
        style={{ paddingTop: '62px' }}>

        <button
          className="transparent-button checkout__go-back mobile-hidden"
          onClick={() => onBackClick()}
        >
          <ArrowLeftOutlined />
          <span className="checkout__go-back__text">{translate('CHECKOUT.STEP_THREE.BACK')}</span>
        </button>

        <div className="checkout__upper">
          {window.innerWidth < 1100 && products && products.length > 0 && (
            <div className="checkout__upper__title mobile-visible">
              <CheckoutCart onNextClick={onNextClick} />
            </div>
          )}
        </div>
        <div className="checkout__upper">
          <div className="checkout__upper__title">
            <h2 className="checkout__upper__title__text">
              {translate('CHECKOUT.STEP_THREE.ADDRESS')}
            </h2>
          </div>
        </div>
        <div className="advanced-freight">
          <AdvancedFreight
            handleChangeForm={(field, value) => handleChangeForm(field, value)}
            freightInfos={freight}
            deliveryInfos={deliveryAddressForm}
          />

          <div className="checkout__content__form__item__label__value">
            <Radio.Group onChange={(e) => setFreightOption(e.target.value)}
              value={freightOption}>
              <Space direction="vertical">
                {freightOptions?.map((freightOption, index) => (
                  <FreightOption key={index}
                    freight={freightOption} />
                ))}
              </Space>
            </Radio.Group>
          </div>
        </div>

        <div className='checkout__info'>
          <span className='checkout__info__icon'>
            <Info />
          </span>
          <span className='checkout__info__text'>
            {translate('CHECKOUT.STEP_THREE.INFO')}
          </span>
        </div>
        <Col className="mobile-no-padding mobile-visible">
          <div
            className="checkout--step-one__next-button"
            onClick={() => onNextClick()}
          >
            <div className="checkout--step-one__next-button__item">
              <span className="checkout--step-one__next-button__item__text">
                {I18n.t('CHECKOUT.STEP_ONE.NEXT')}
              </span>
              <span className='checkout--step-one__next-button__item__icon'>
                <ArrowRightOutlined />
              </span>
            </div>
          </div>
        </Col>
      </div>
    </CartContent>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
  products: state.cart.products || [],
  freight: state.cart.freight || null,
  freightType: state.cart.freightType || null,
  freightOptions: state.cart.freightOptions || null,
  values: state.cart.values || null,
  prescription: state.cart.prescription || false,
  addressByZipcode: state.cart.addressByZipcode || null,
});

const mapDispatchToProps = (dispatch) => ({
  calculateTheCart: () => dispatch(CartActions.calculateTheCart()),
  getAddressByZipcode: (data) => dispatch(CartActions.getAddressByZipcode(data)),
  getFreightAmount: (data) => dispatch(CartActions.getFreightAmount(data)),
  checkForValidPrescription: (parameters, callback) => dispatch(PrescriptionActions.checkValid(parameters, callback)),
  updateUserProfile: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  calculateFreight: (data, callback) => dispatch(ActionsCart.calculateFreight(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepThree);

import React from 'react';
import AdvancedInput from '../AdvancedInput/AdvancedInput';

const CheckoutInput = ({
  label,
  value,
  onChange,
  className,
  disabled,
  maxLength,
  type,
  onFocus,
  name
}) => {
  return (
    <AdvancedInput
      label={label}
      value={value}
      onChange={onChange}
      className={className}
      inputProps={{
        style: {
          borderRadius: '15px',
          height: '70px',
        }
      }}
      disabled={disabled}
      maxLength={maxLength}
      type={type}
      onFocus={onFocus}
      name={name}
    />
  );
};

export default CheckoutInput;
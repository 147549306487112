import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svg/new_menu/ic_arrow_one.svg';

const ProductCard = ({
  title,
  description,
  link,
  background,
  image,
  mobile,
  medical,
}) => {
  const [ currentImage ] = useState(image);

  return (
    <div className="product-card__wrapper">
      <Link
        to={link}
        className="product-card"
        style={{
          backgroundColor: background,
          backgroundImage: `url(${currentImage && currentImage})`,
        }}
      >
        <div className="product-card__inner">
          <div
            className={`product-card__inner__top ${
              mobile && 'product-card__inner__top--mobile'
            }`}
          >
            <div
              className={`product-card__inner__top__title ${
                medical && 'product-card__inner__top__title--center'
              } `}
            >
              {title}
            </div>
            {!mobile && (
              <div className="product-card__inner__top__icon">
                <Arrow />
              </div>
            )}
          </div>
          <div className="product-card__inner__description mobile-hidden">
            {description}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;

import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const AdvancedRadio = ({
  value,
  label,
  onChange,
  name,
  items,
  inline,
  className
}) => {
  return (
    <div className="advanced-radio">
      <FormControl
        component="fieldset"
        className="advanced-radio__inner"
      >
        <FormLabel component="legend">
          {label}
        </FormLabel>
        <RadioGroup
          row
          aria-label={name}
          name={name}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          className={`advanced-radio__inner__group ${inline ? 'advanced-radio__inner__group--inline' : ''}`}
        >
          {items && items.map((item, itemIndex) => (
            <FormControlLabel
              className={className || 'advanced-radio__inner__group__label'}
              key={itemIndex.toString()}
              label={
                <>
                  {item.icon}
                  {item.label}
                  {item.className}
                </>
              }
              value={item.value}
              control={<Radio />}
              disabled={item.disabled}
              icon={item.icon}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default AdvancedRadio;

import React from 'react';

const CustomTitle = ({
  className,
  title
}) => {
  return (
    <div className={className}>
      {title}
    </div>
  );
};

export default CustomTitle;
import * as StorageService from './storage';
import QueryString from 'query-string';
import Url from '../config/api.config';

function isAuthenticated () {
  const auth = StorageService.get('auth');
  return (auth && !!auth.token);
}

function get () {
  if (isAuthenticated()) {
    return StorageService.get('auth');
  }
}

function create (data) {
  data = {
    ...data,
    authenticated: true,
  };
  StorageService.set('auth', data);
}

function reset () {
  StorageService.reset('auth');
}

function isFromPrescriptionDomain () {
  const { hostname } = window.location;
  let key;

  // verify if domain is whitelisted to do the quiz
  switch (hostname) {
    case 'dev.themens.com.br':
      key = `${Url.prescriptionDev}/avaliacao`;
      break;
    case 'themens.com.br':
    case 'www.themens.com.br':
      key = `${Url.prescriptionProd}/avaliacao`;
      break;
    default:
      key = null;
  }

  // if blacklisted redirect to new location (if logged pass its credentials to new location)
  if (key) {

    if (isAuthenticated()) {
      const tokens = get();
      key = `${key}?token=${tokens.token}&refreshToken=${tokens.refreshToken}`;
    }

    /* window.location.href = key; */
  }

  // if whitelisted verify if there are querystring with credentials
  const qs = QueryString.parse(window.location.search);

  // if there are credentials (came from blacklisted and the user was logged in)
  if (qs && qs.token && qs.refreshToken) {
    create(qs);
    window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port && `:${window.location.port}`}/avaliacao`;
  }
}

function isFromEcommerceDomain () {
  const { hostname } = window.location;
  let key;

  // verify if domain is whitelisted to do the quiz
  switch (hostname) {
    case 'hml.consultoriomedico.digital':
      key = `${Url.ecommerceDev}`;
      break;
    case 'consultoriomedico.digital':
    case 'www.consultoriomedico.digital':
      key = `${Url.ecommerceProd}`;
      break;
    default:
      key = null;
  }

  // if blacklisted redirect to new location (if logged pass its credentials to new location)
  if (key) {

    if (isAuthenticated()) {
      const tokens = get();
      key = `${key}?token=${tokens.token}&refreshToken=${tokens.refreshToken}`;
    }

    window.location.href = `${key}${window.location.pathname}`;
  }

  // if whitelisted verify if there are querystring with credentials
  const qs = QueryString.parse(window.location.search);

  // if there are credentials (came from blacklisted and the user was logged in)
  if (qs && qs.token && qs.refreshToken) {
    create(qs);
    window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.port && `:${window.location.port}`}`;
  }
}

export default {
  get,
  isAuthenticated,
  create,
  reset,
  isFromPrescriptionDomain,
  isFromEcommerceDomain,
};

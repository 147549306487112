import getInstance from './instance';

export default class ProductsRequests {
  static async getAllProducts () {
    const instance = await getInstance();
    const { data } = await instance.get('/product');
    return data;
  }

  static async getProductByID (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/product/${id}`);
    return data;
  }
}



import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { i18nReducer } from 'react-redux-i18n';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
//import storageSession from 'redux-persist/lib/storage/session'

import authReducer from './auth';
import loadingReducer from './loading';
import loginModalReducer from './loginModal';
import userReducer from './user';
import productReducer from './products';
import cartReducer from './cart';
import transactionReducer from './transaction';
import medicalAppointmentReducer from './medical-appointment';
import chatMessageReducer from './chat-message';
import quizReducer from './quiz';
import evolutionReducer from './evolution';
import couponReducer from './coupon';
import prescriptionReducer from './prescription';
import utilsReducer from './utils';
import TimerReducer from './timer';
import recurrenceReducer from './recurrence';
import productModalReducer from './productModal';
import trackOrderReducer from './track-order';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'product',
    'cart',
    'medicalAppointment',
    'chatMessage',
    'transaction',
    'prescription',
    'recurrence',
  ],
  blacklist: [
    'auth',
    'loading',
  ],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(
  {
    i18n: i18nReducer,
    auth: authReducer,
    loading: loadingReducer,
    login: loginModalReducer,
    user: userReducer,
    product: productReducer,
    cart: cartReducer,
    transaction: transactionReducer,
    medicalAppointment: medicalAppointmentReducer,
    chatMessage: chatMessageReducer,
    quiz: quizReducer,
    evolution: evolutionReducer,
    coupon: couponReducer,
    prescription: prescriptionReducer,
    base64: utilsReducer,
    timer: TimerReducer,
    recurrence: recurrenceReducer,
    productModal: productModalReducer,
    statusOrder: trackOrderReducer,
  }
));

const theStore = () => {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default theStore;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AdvancedSelect = ({
  value,
  removeGreater,
  onChange,
  items,
  noPadding,
  borderRadius,
  height,
  fontFamily
}) => {
  const classes = useStyles();

  const getWithGreater = () => {
    if (removeGreater) {
      return items.filter((o) => o.value === 1);
    }

    return items;
  };

  return (
    <div
      className="advanced-select"
      style={{ padding: noPadding && 0 }}
    >
      <FormControl
        className={classes.formControl}
        variant="outlined"
      >
        <Select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{ borderRadius, height, fontFamily }}
        >
          {getWithGreater(items).map((item, i) => (
            <MenuItem key={i}
              value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AdvancedSelect;

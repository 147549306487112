import getInstance from './instance';

export default class TransactionRequests {
  //requests transactions
  static async getDetails (id) {
    const instance = getInstance();
    const { data } = await instance.get(`/transaction/${id}`);
    return data;
  }

  static async create (userData) {
    const instance = getInstance();
    const data = await instance.post('/transaction', userData);
    return data;
  }

  static async checkout (idTransaction, body) {
    const instance = getInstance();
    const { data } = await instance.post(`/transaction/${idTransaction}/checkout`, body);
    return data;
  }

  static async update (id, body) {
    const instance = getInstance();
    const { data } = await instance.put(`/transaction/${id}`, body);
    return data;
  }

  static async getMineTransactions (params) {
    const instance = getInstance();
    const { data } = await instance.get('/transaction/mine', { params });
    return data;
  }

  // requests created card credit
  static async createCard (userData) {
    const instance = getInstance();
    const data = await instance.post('/credit-card', userData);
    return data;
  }

  static async getAllMineCardsCredit (userData) {
    const instance = getInstance();
    const { data } = await instance.get('/credit-card/mine', userData);
    return data;
  }

  static async getMineCardsCreditById (id) {
    const instance = getInstance();
    const { data } = await instance.get(`/credit-card/${id}`);
    return data;
  }

  static async deleteMineCardsCreditById (id) {
    const instance = getInstance();
    const { data } = await instance.delete(`/credit-card/${id}`);
    return data;
  }

  static async getTransactionDetails (product) {
    const instance = getInstance();
    const { data } = await instance.get('/transaction/mine/details', { params: { product } });

    return data;
  }
}

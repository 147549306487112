import React, { useRef } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { getProductByCode } from '../../services/product';
import { ReactComponent as DoctorRequested } from '../../assets/svg/new_menu/ic_doctor_requested.svg';
import Slider from 'react-slick';


const ProductCardSection = ({
  addProductInCart,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 5,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    autoplay: false,
    centerMode: true,
    centerPadding: '29%'
  };

  let sliderRef = useRef();
  return (
    <>
      <div className="product-card-section mobile-hidden">

        <div className="product-card-section__item">
          <ProductCard
            title="Cabelo"
            link="/assinatura"
            background="#CADEEB"
            image="/assets/img/product-cards/1.png"
            backgroudPosition="0px"
          />
        </div>

        <div className="product-card-section__item">
          <ProductCard
            title="Barba"
            link="/produtos/tonico-para-barba"
            background="#F0E1DA"
            image="/assets/img/product-cards/2.png"
            addProductInCart={(product) => addProductInCart(product, 1)}
            product={getProductByCode(6)}
            backgroudPosition="10px"
          />
        </div>

        <div className="product-card-section__item">
          <ProductCard
            title="Pele"
            link="/produtos/anti-aging"
            background="#F4EDE6"
            image="/assets/img/product-cards/3.png"
            addProductInCart={(product) => addProductInCart(product, 1)}
            product={getProductByCode(1)}
            backgroudPosition="3px"
          />
        </div>

        <div className="product-card-section__item">
          <ProductCard
            title="SEXO"
            link="/avaliacao?carrinho=312974bf-1dac-434d-8084-942e42beed8b&fromProduct=3"
            background="#EEEEEE"
            image="/assets/img/product-cards/4.png"
            addProductInCart={(product) => addProductInCart(product, 1)}
            product={getProductByCode(2)}
            backgroudPosition="33px"
          />
        </div>

        <div className="product-card-section__item">
          <ProductCard
            title="SONO"
            link="/produtos/9847293873423"
            background="#E9E0EC"
            image="/assets/img/product-cards/5.png"
            addProductInCart={(product) => addProductInCart(product, 1)}
            product={getProductByCode(8)}
            backgroudPosition="25px"
          />
        </div>

      </div>
      <div className="product-card-section__mobile mobile-visible">
        <Slider ref={slider => (sliderRef = slider)}
          {...settings}>

          <div className="product-card-section__item">
            <ProductCard
              mobile
              title="Cabelo"
              link="/assinatura"
              background="#CADEEB"
              image="/assets/img/product-cards/1.png"
              backgroudPosition="0px"
            />
          </div>

          <div className="product-card-section__item">
            <ProductCard
              mobile
              title="SONO"
              link="/produtos/9847293873423"
              background="#E9E0EC"
              image="/assets/img/product-cards/5.png"
              addProductInCart={(product) => addProductInCart(product, 1)}
              product={getProductByCode(8)}
              backgroudPosition="25px"
            />
          </div>

          <div className="product-card-section__item">
            <ProductCard
              mobile
              title="Barba"
              link="/produtos/tonico-para-barba"
              background="#F0E1DA"
              image="/assets/img/product-cards/2.png"
              addProductInCart={(product) => addProductInCart(product, 1)}
              product={getProductByCode(6)}
              backgroudPosition="10px"
            />
          </div>

          <div className="product-card-section__item">
            <ProductCard
              mobile
              title="Pele"
              link="/produtos/anti-aging"
              background="#F4EDE6"
              image="/assets/img/product-cards/3.png"
              addProductInCart={(product) => addProductInCart(product, 1)}
              product={getProductByCode(1)}
              backgroudPosition="3px"
            />
          </div>

          <div className="product-card-section__item">
            <ProductCard
              mobile
              title="SEXO"
              link="/avaliacao?carrinho=312974bf-1dac-434d-8084-942e42beed8b&fromProduct=3"
              background="#EEEEEE"
              image="/assets/img/product-cards/4.png"
              addProductInCart={(product) => addProductInCart(product, 1)}
              product={getProductByCode(2)}
              backgroudPosition="33px"
            />
          </div>

          <div className="product-card-section__item mobile-visible">
            <ProductCard
              mobile
              title="CONSULTÓRIO MÉDICO"
              link="/avaliacao"
              background="#6DA7CB"
              addProductInCart={(product) => addProductInCart(product, 1)}
              product={getProductByCode(2)}
              backgroudPosition="33px"
              image="/assets/img/product-cards/61.png"
              medical
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default ProductCardSection;

import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as CartIcon } from '../../assets/svg/new_menu/ic_header_bag.svg';
import { ProductModalActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { translate } from '../../services/i18n';

const CartModal = (props) => {

  return (
    <>
      {!props.isVariant && (
        <div className="cart-modal">
          <div onClick={() => props.setCartModalOpen(false)}
            className="cart-modal__close">
            <div className="cart-modal__close__button">
              <CloseOutlined />
            </div>
            <p className="cart-modal__text">
              {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.TEXT')}<strong>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.PRESCRIPTION')}</strong>
              <p>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.NO_PRESCRIPTION')}</p>
            </p>
            <div onClick={() => props.prescriptionRedirect(props.product.prescriptionLink)}
              className="cart-modal__confirm">
              FAZER MINHA 1ª AVALIAÇÃO
            </div>
            <div
              onClick={() => {
                props.setCartModalOpen(false);
                props.addToCart(props.product.id, props.quantity);
                props.setVisibleModal(true);
              }}
              className="cart-modal__deny"
            >
              JÁ TENHO AVALIAÇÃO
            </div>
          </div>
        </div>
      )}

      {props.isVariant && (
        <div className="cart-modal"
          style={{ marginTop: 0 }}>
          <div className="cart-modal__close">
            <div onClick={() => props.setVariantModal(false)}
              className="cart-modal__close__button">
              <CloseOutlined />
            </div>
            <div className="cart-modal-variant">
              <div className="cart-modal-variant__title">
                Escolha a quantidade ideal para você:
              </div>
              <div className="cart-modal-variant__content">
                {props.product.hasVariant.variants.map((item, index) =>
                  <div
                    key={index}
                    className={`cart-modal-variant__content__item ${props.selectedVariant === index && 'cart-modal-variant__content__item--selected'}`}
                    onClick={() => props.setSelectedVariant(index)}
                  >
                    <div className="cart-modal-variant__content__item__radio">
                      {props.selectedVariant === index && (
                        <div className="cart-modal-variant__content__item__radio__selected" />
                      )}
                    </div>
                    <img className="cart-modal-variant__content__item__image"
                      src={item.image} />
                    <div className="cart-modal-variant__content__item__name">
                      {item.name}
                      <span className="cart-modal-variant__content__item__name__unit-price">
                        {item.unitPrice}
                      </span>
                    </div>
                    <div className="cart-modal-variant__content__item__price">
                      R${item.price}0
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="product__info__right__inner__calc__installments__add-to-cart">
              <div
                className="product__info__right__inner__calc__installments__add-to-cart__popover__button"
                onClick={() => props.addVariantToCart()}
              >
                <>ADICIONAR NA SACOLA &nbsp;&nbsp;<CartIcon /></>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

const mapStateToProps = (state) => ({
  isVisible: state.productModal
});

const mapDispatchToProps = (dispatch) => ({
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
  setInvisibleModal: () => dispatch(ProductModalActions.hideModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
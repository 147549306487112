import React, { useMemo } from 'react';
import { Radio } from 'antd';
import { translate } from '../../services/i18n';

export const FreightOption = ({ freight }) => {

  const infos = useMemo(() => {
    const freightOptions = [
      translate('CHECKOUT.STEP_THREE.TYPES.DEFAULT'),
      translate('CHECKOUT.STEP_THREE.TYPES.SEDEX'),
    ];

    return {
      freight: translate('CHECKOUT.STEP_THREE.FREIGHT'),
      type: freightOptions[freight.freightType],
      cost: freight?.freightValue ? new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(freight.freightValue) : translate('CHECKOUT.STEP_THREE.FREE'),
      days: translate('CHECKOUT.STEP_THREE.DAYS')
    };
  }, [ freight ]);

  return (
    <Radio value={freight.freightType}
      disabled={!freight.available}>
      <strong className='radio_freight_option'>
        {infos.freight}
        <b>{infos.type} &nbsp;</b>
        {freight.available ? (
          <>
            <b className={!freight.freightValue && 'radio_freight_option__free'}>{infos.cost} - </b>
            <i>{freight.deadline} {infos.days}</i>
          </>
        ) : (
          <b className='radio_freight_option__unavailable'>{translate('CHECKOUT.STEP_THREE.UNAVAILABLE')}</b>
        )}
      </strong>
    </Radio>
  );
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as AuthActions from '../../../../redux/actions/auth';
import * as UserActions from '../../../../redux/actions/user';
import AuthService from '../../../../services/auth';

import { ProfileRegister } from '../../../../enum/profileForm';

import AdvancedForm from '../../../../components/AdvancedForm/AdvancedForm';
import { takeOutBlankSpace } from '../../../../utils/spaceRegex';
import { cpfMask, dateOfBirthMask, phoneMask } from '../../../../utils/masks';
import moment from 'moment';
import { removeSpecialChars, validateEmail } from '../../../../utils/string';
import { message } from 'antd';
import CollapseProfile from './CollapseProfile';
import { useHistory } from 'react-router';
import { ProfileType } from '../../../../enum/profileType';

const CollapseEdit = ({
  getMe,
  me,
  updateUser,
  updateUserPassword,
  addTwoFactor,
}) => {
  const [ form, setForm ] = useState({
    email: '',
    phone: '',
    document: '',
    firstName: '',
    lastName: '',
    grantType: '',
    birthDate: '',
    profileType: ProfileType.USER,
    lastname: '',
  });

  useEffect(async () => {
    AuthService.isFromEcommerceDomain();
    await getMe();
  }, []);

  const {
    email,
    phone,
    twoFactor,
    document,
    firstName,
    lastName,
    birthDate,
  } = form;

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        email: me.email,
        phone: me.phone,
        document: me.document,
        firstName: me.name.split(' ') && me.name.split(' ').length > 0 ? me.name.split(' ')[0] : me.name,
        lastName: me.name.split(' ') && me.name.split(' ').length > 0 ? me.name.split(' ')[me.name.split(' ').length - 1] : '',
        grantType: me.grantType,
        birthDate: moment(me.birthDate).format('DD/MM/YYYY') || '',
        lastname: me.lastname,
      });
    }
  }, [ me ]);

  const [ profileScreen, setProfileScreen ] = useState(ProfileRegister.EDIT_PROFILE);

  const history = useHistory();

  const onFormSubmit = async (e) => {
    if (e) e.preventDefault();

    if (removeSpecialChars(phone).length < 10 || phone.includes('99999-9999')) {
      return message.error('Telefone inválido');
    }

    if (!validateEmail(email)) {
      return message.error('Email inválido');
    }

    if (!firstName) {
      return message.error('Informe o nome completo.');
    }

    if (!lastName) {
      return message.error('Informe o sobrenome.');
    }

    const formValidate = {
      ...form,
      name: form.firstName.concat(' ', form.lastName),
      phone: removeSpecialChars(form.phone),
      birthDate: moment(form.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    };

    if (twoFactor) {
      addTwoFactor(me.id, { twoFactor });
    }

    await updateUser(me && me.id, formValidate, async (response) => {
      if (response) {
        await getMe();
        message.success('Usuário atualizado com sucesso.');
        history.push(setProfileScreen(ProfileRegister.REGISTER_PROFILE));
      }
    });
  };

  const handleChangeForm = (name, value) => {

    if (name === 'birthDate') {
      value = dateOfBirthMask(value);
    }

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });
  };

  function onReturn () {
    setProfileScreen(ProfileRegister.REGISTER_PROFILE);
  }

  return (
    <>
      {profileScreen === ProfileRegister.EDIT_PROFILE ? (
        <>
          <AdvancedForm>
            <div className="edit-profile">
              <div className="edit-profile__aux">
                <div className="edit-profile__aux__input">
                  <input
                    name="firstName"
                    value={firstName}
                    onChange={(e) => handleChangeForm('firstName', e.target.value)}
                    placeholder='Nome'
                  />
                </div>
                <div className="edit-profile__aux__input">
                  <input
                    name="lastName"
                    value={lastName}
                    onChange={(e) => handleChangeForm('lastName', e.target.value)}
                    placeholder='Sobrenome'
                  />
                </div>
              </div>
              <div className="edit-profile__aux">
                <div className="edit-profile__aux__input-large"
                  style={{ backgroundColor: '#F2F2F2' }}>
                  <input
                    value={email && email.toLowerCase()}
                    onChange={(e) => handleChangeForm('email', e.target.value)}
                    name="email"
                    placeholder='E-mail'
                    disabled
                  />
                </div>
              </div>
              <div className="edit-profile__aux">
                <div className="edit-profile__aux__input-large">
                  <input
                    placeholder='Senha'
                    value={twoFactor}
                    onChange={(e) => handleChangeForm('twoFactor', e.target.value)}
                  />
                </div>
              </div>
              <div className="edit-profile__aux">
                <div className="edit-profile__aux__input">
                  <input
                    value={phoneMask(phone)}
                    onChange={(e) => handleChangeForm('phone', phoneMask(e.target.value))}
                    name="phone"
                    placeholder='Telefone'
                  />
                </div>
                <div className="edit-profile__aux__input">
                  <input
                    value={birthDate || (me && moment(me.birthDate).format('DD/MM/YYYY'))}
                    onChange={(e) => handleChangeForm('birthDate', e.target.value)}
                    name="birthDate"
                    placeholder="Data de Nasc."
                  />
                </div>
              </div>
              <div className="edit-profile__aux">
                <div className="edit-profile__aux__input"
                  style={{ backgroundColor: '#F2F2F2' }}>
                  <input
                    name="document"
                    value={cpfMask(document)}
                    onChange={(e) => handleChangeForm('document', cpfMask(e.target.value))}
                    placeholder='CPF'
                    disabled
                  />
                </div>
              </div>
              <div className="edit-profile__aux">
                <button
                  type="submit"
                  className="edit-profile__aux__button"
                  onClick={(e) => onFormSubmit(e)}
                >
                  Salvar
                </button>
              </div>
              <div className="edit-profile__return">
                <a className="edit-profile__cancel"
                  onClick={onReturn}>Cancelar</a>
              </div>
            </div>
          </AdvancedForm>
        </>
      ) : (<CollapseProfile />)}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  addTwoFactor: (id, payload) => dispatch(UserActions.addTwoFactor(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseEdit);
import React from 'react';
import { Carousel } from 'antd';
import RenderCondition from '../../../components/no-visual/renderCondition';
import BannerHome from '../../../components/BannerHome/BannerHome';
import BannerCreatina from '../../../components/BannerCreatina/BannerCreatina';

/**
 * @param {Object} props
 * @param {Array} props.bannerProps
 */

const BannerCarousel = ({ bannerProps }) => {

  const bannerHome = bannerProps
    .filter((item) => item?.isBannerHome)
    .reduce((info, key) => Object.assign(info, key), {});

  const bannerCreatina = bannerProps
    .filter((item) => item.isBannerCreatina)
    .reduce((info, key) => Object.assign(info, key), {});

  return (
    <div className="carousel">
      <Carousel
        autoplay
        pauseOnHover={false}
        draggable
      >
        <RenderCondition condition={bannerHome}>
          <BannerHome banner={bannerHome} />
        </RenderCondition>

        <RenderCondition condition={bannerCreatina}>
          <BannerCreatina banner={bannerCreatina} />
        </RenderCondition>

      </Carousel>
    </div>
  );
};

export default BannerCarousel;
import { EvolutionActions } from '../actions';

const initialState = {
  mineEvolution: {},
};

const evolutionReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case EvolutionActions.ACTION_GET_MINE_EVOLUTIONS:
      state = {
        ...state,
        mineEvolution: action.payload,
      };
      return state;
    default:
      return state;
  }
};


export default evolutionReducer;

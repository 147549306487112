import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as FacebookActions from '../../redux/actions/facebook';
import facebookUserActivity from '../../enum/facebookUserActivity';


const PageViewProvider = (props) => {

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      props.userActivity({
        page: document.title,
        source: location.pathname
      }, facebookUserActivity.pageView);
    }, 300);
  },[ location ]);

  return (
    <></>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
});

export default connect(null, mapDispatchToProps)(PageViewProvider);
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CartActions } from '../../redux/actions';
import { CartSymbol } from '../../enum/cartSymbol';
import CheckoutItemCardMobile from '../CheckoutItemCard/CheckoutItemCardMobile';
import { ReactComponent as ArrowUp } from '../../assets/svg/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg';
import { IconButton } from '@material-ui/core';
import { renderMobileCart } from '../../utils/checkout';
import { useLocation } from 'react-router-dom';

const MobileCart = ({
  products,
  finished,
  addProductToCart,
  removeProductFromCart
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { pathname } = useLocation();

  const handleCartOpen = () => {
    setIsOpen(!isOpen);
  };

  const cartIcon = isOpen ? <ArrowDown /> : <ArrowUp />;

  const hasProducts = products.length > 0;

  return (
    <>
      {renderMobileCart(pathname) && (
        <div className='mobile-cart mobile-visible'>
          <div className="mobile-cart__items">
            {products && products.length > 1 && (
              <div className="mobile-cart__items__icon">
                <IconButton onClick={handleCartOpen}>
                  {cartIcon}
                </IconButton>
              </div>
            )}
            <div className="accordion-content"
              aria-expanded={!isOpen}>
              {products.map((product, productKey) => (
                <CheckoutItemCardMobile
                  last={productKey === (products.length - 1)}
                  key={productKey.toString()}
                  product={product}
                  addProductToCart={(id) => addProductToCart(id)}
                  removeProductFromCart={(id) => removeProductFromCart(id)}
                  deleteProductFromCart={(id) => removeProductFromCart(id, product.quantity)}
                  disabled={finished} />
              ))}
            </div>
            {!isOpen && hasProducts && (
              <CheckoutItemCardMobile
                last
                product={products[products.length - 1]}
                addProductToCart={(id) => addProductToCart(id)}
                removeProductFromCart={(id) => removeProductFromCart(id)}
                deleteProductFromCart={(id) => removeProductFromCart(id, products[products.length - 1].quantity)}
                disabled={finished}
              />
            )}
          </div>
        </div >
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products || [],
});

const mapDispatchToProps = (dispatch) => ({
  addProductToCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.ADD)),
  removeProductFromCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.REMOVE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileCart);

const productionHosts = [
  'themens.com.br',
  'www.themens.com.br',
  'consultoriomedico.digital',
  'www.consultoriomedico.digital',
];

export function isProduction () {
  const { hostname } = window.location;

  return productionHosts.includes(hostname);
}
import React from 'react';
import { Col, Container } from 'react-bootstrap';
import IconAttention from '../../assets/png/icon-attention.png';
import InfoScreen from '../../assets/png/info-screen.png';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

const InfoCheckoutMessage = () => {
  return (
    <Container className="mobile-no-padding checkout-status__info__container">
      <Col lg={6}>
        <div className="info-checkout-message">
          <img
            className="info-checkout-message__icon"
            src={IconAttention}
            alt="" />
          <div className="info-checkout-message__title">
            Atenção
          </div>
          <div className="info-checkout-message__text-one">
            {I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.TEXT_ONE')}
          </div>
          <p className="info-checkout-message__text-one">{I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.TEXT_TWO')}<span className="info-checkout-message__text-two">{I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.TEXT_THREE')}</span>{I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.TEXT_FOUR')}</p>
          <br />
          <div className="info-checkout-message__text-one--last">
            <Link to="/avaliacao?carrinho=312974bf-1dac-434d-8084-942e42beed8b&fromProduct=3"
              className="info-checkout-message__link">
              {I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.LINK')}
            </Link>
            {I18n.t('CHECKOUT.STEP_FIVE.INFO_CHECKOUT.TEXT_FIVE')}
          </div>
          <div className="info-checkout-message__image">
            <img
              className="info-checkout-message__image__icon-two"
              src={InfoScreen}
              alt="" />
          </div>
        </div>
      </Col>
    </Container>
  );
};

export default InfoCheckoutMessage;
import React from 'react';
import IconWhatsapp from '../../assets/svg/whatsapp_color.svg';
import { useLocation } from 'react-router-dom';
import { Fab, Zoom } from '@material-ui/core';
import { getPages, QuestionType, QuestionTypeMap } from '../../services/prescription';

const FloatingButtonWhatsapp = () => {

  const { pathname } = useLocation();

  const allowRenderFloatingWhatsapp = (pathname) => {
    const hiddenRender = [
      '/avaliacao',
      '/checkout/revisao',
      '/checkout/dados-de-entrega',
      '/checkout/pagamento',
      '/checkout/resultado-pagamento',
    ];

    Object
      .values(QuestionType)
      .forEach((questionType) => {
        getPages(questionType).map(
          (page) => hiddenRender.push(`/questionario/${QuestionTypeMap.get(questionType)}/passo-${page}`)
        );
      });

    return !hiddenRender.includes(pathname);
  };

  return (
    <>
      {allowRenderFloatingWhatsapp(pathname) && (
        <div className="floating-button">
          <Zoom
            in={true}
            timeout={{ enter: 500, exit: 500 }}
            unmountOnExit
          >
            <Fab
              className="floating-button__fab"
              href="https://api.whatsapp.com/send?phone=5511942120797"
              rel="noreferrer noopener"
              target="_blank"
              size="medium"
            >
              <img
                className="floating-button__fab__img"
                src={IconWhatsapp}
                alt="Ícone do Whatsapp"
              />
            </Fab>
          </Zoom>
        </div>
      )}
    </>
  );
};

export default FloatingButtonWhatsapp;
export const ACTION_VISIBLE_MODAL = 'prductModal:ACTION_VISIBLE_MODAL';
export const ACTION_INVISIBLE_MODAL = 'prductModal:ACTION_INVISIBLE_MODAL';

export const visibleModalProduct = () => ({
  type: ACTION_VISIBLE_MODAL,
});

export const invisibleModalProduct = () => ({
  type: ACTION_INVISIBLE_MODAL,
});

export const showModalProduct = () => (dispatch) => dispatch(visibleModalProduct());

export const hideModalProduct = () => (dispatch) => dispatch(invisibleModalProduct());
import { AuthActions } from '../actions';

const initialState = {
  authenticated: null,
  me: null,
  fakeEmail: null,
};

const authReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AuthActions.ACTION_AUTH_LOGIN:
      return { authenticated: true };
    case AuthActions.ACTION_AUTH_LOGIN_CHECK:
      return { authenticated: action.payload };
    case AuthActions.ACTION_AUTH_ME:
      state = {
        ...state,
        me: action.payload,
      };
      return state;
    case AuthActions.ACTION_AUTH_LOGOUT:
      state = {
        authenticated: null,
        me: null,
      };
      return state;
    case AuthActions.ACTION_SET_FAKE_EMAIL:
      state = {
        ...state,
        fakeEmail: action.payload
      };
      return state;
    default:
      return state;
  }
};

export function getAuth (state) {
  return state.auth.token;
}

export function getMe (state) {
  return state.auth.me;
}

export default authReducer;

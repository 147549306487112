import React, { useRef } from 'react';

import { translate } from '../../services/i18n';
import medias from '../../services/medias';

const RotativeBanner = () => {

  return (
    <>
      <div className="rotative-banner-home-header">
        <h3 className="rotative-banner-home-header__title">
          {translate('COMPONENTS.ROTATIVE_BANNER.TITLE')}
        </h3>
      </div>
      <div className="rotative-banner-home">
        <div className="rotative-banner-home__container">
          {medias && medias.length > 0 && medias.map((item, i) =>
            <a key={i}
              href={item.link}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              alt={item.text}
              rel="noreferrer">
              <img className="rotative-banner-home__container__img"
                src={item.img}
                alt=""/>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default RotativeBanner;

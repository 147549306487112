import axios from 'axios';
import getInstance from './instance';
import { message } from 'antd';
import AuthService from '../services/auth';
import { handleAxiosError } from '../services/api';

export function getBucketInstance (imageType) {
  const header = {
    'Content-Type': `${imageType}`,
    'ContentEncoding': 'base64',
  };

  const axiosInstance = axios.create({
    timeout: 35000,
    headers: header,
  });

  axiosInstance.interceptors.response.use((response) => {
    return response;
  },
  async (err) => {
    if (err.response.status === 401) {
      message.error('Sessão expirada, entre novamente com sua conta. Redirecionando...');
      AuthService.reset('auth');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } else if (err.response.status === 403) {
      // Snackbar(I18n.t('screen.login.errors.user_no_access'));
      // axiosHandleUnatorizedErr();
    }

    return Promise.reject(handleAxiosError(err));
  });
  return axiosInstance;
}

class UtilsRequests {
  static async calculate (body) {
    const instance = getInstance();
    const { data } = await instance.post('/transaction/calculate', body);
    return data;
  }

  static async getAddressByCep (cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  static async uploadImageBase64 (body) {
    const instance = getInstance();
    const { data } = await instance.post('/utils/image-base-64', body);
    return data;
  }

  static async getSecureUrlImage (params) {
    const instance = getInstance();
    const data = await instance.get('/utils/secure-url', { params });
    return data;
  }

}

export default UtilsRequests;

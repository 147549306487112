import React from 'react';
import { message, Upload, } from 'antd';
import { compressImages } from '../../utils/imageUtils';
import { translate } from '../../services/i18n';
import { getBase64 } from '../../utils/string';

class AdvancedFileUploader extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      file: null,
      filePreview: null,
    };
  }

  async onFileChange ({ file }) {
    const {
      onChange,
      value,
      type = 'image',
    } = this.props;

    if (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJpgOrPng && type === 'image') {
        return message.error(translate('SHARED.fileValidation'));
      }

      if (type === 'image') {
        file.base64 = await compressImages([ file ]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);
      this.toggleFilePreview(file ? file.base64 : value || null);
    } else {
      onChange(null);
    }

    this.setState({ file });
  }

  toggleFilePreview (filePreview) {
    this.setState({ filePreview });
  }

  render () {
    const {
      disabled,
      children,
      className,
      uploadClassName,
      multiple,
      defaultImage,
      hidePreview,
      onPreview,
      accept,
    } = this.props;

    const { filePreview } = this.state;

    return (
      <div className={className || 'advanced-file-uploader'}>
        <Upload
          className={uploadClassName || 'advanced-file-uploader__upload'}
          customRequest={(ev) => this.onFileChange(ev)}
          disabled={disabled}
          multiple={multiple}
          onPreview={onPreview || (() => {})}
          accept={accept}
          progress={null}
        >
          {(filePreview || defaultImage) && !hidePreview && (
            <div className="advanced-file-uploader__preview">
              <img
                className="advanced-file-uploader__preview__img"
                src={defaultImage ?? filePreview}
                alt="img preview"
              />
            </div>
          )}
          {children}
        </Upload>
      </div>
    );
  }
}

export default AdvancedFileUploader;

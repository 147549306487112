import React, { useState } from 'react';
import RecomendedProducts from '../../../components/RecomendedProducts/RecomendedProducts';
import sleepGummyFaq from '../../../services/sleepGummyFaq';
import { QuestionCircleOutlined } from '@ant-design/icons';

function LpSleepGummy () {
  const [ faqOpen, setFaqOpen ] = useState(null);

  const openFaqItem = (index) => {
    if (faqOpen === index) return setFaqOpen(null);

    setFaqOpen(index);
  };

  return (
    <div className="lp-sleep-gummy">
      <div className="lp-sleep-gummy__inner">
        <img src="/assets/img/lp-sleep-gummy/banner_initial.png"
          className="lp-sleep-gummy__inner__img " />

        <div className="lp-sleep-gummy__inner__container">
          <div className="lp-sleep-gummy__inner__container__links mobile-hidden">
            <a className="lp-sleep-gummy__inner__container__links__link"
              href="https://api.whatsapp.com/send?phone=5511942120797">
              <p className="lp-sleep-gummy__inner__container__links__link__paragraph">
                <img src="/assets/img/lp-sleep-gummy/whatsapp.png"
                  alt="whatsapp"
                  className="lp-sleep-gummy__inner__container__links__link__paragraph__img" />
                Fale pelo WhatsApp
              </p>
            </a>
            <a className="lp-sleep-gummy__inner__container__links__link"
              href="https://themens.com.br/podemos-ajudar">
              <p className="lp-sleep-gummy__inner__container__links__link__paragraph">
                <QuestionCircleOutlined style={{ color: '#442565' }}
                  className="lp-sleep-gummy__inner__container__links__link__paragraph__img__question" />
                Tire suas dúvidas
              </p>
            </a>
          </div>
          <div className="lp-sleep-gummy__inner__container__content">
            <h4 className="lp-sleep-gummy__inner__container__content__title">A 1<sup>a</sup> gummy do Brasil</h4>
            <h3 className="lp-sleep-gummy__inner__container__content__subtitle">O jeito mais rápido que te<br />auxilia a dormir melhor e a<br />noite inteira</h3>
            <p className="lp-sleep-gummy__inner__container__content__text mobile-hidden" >Descubra como estimular o hormônio natural do sono na hora<br />certa e na dose certa
              para você acalmar a mente e ter uma<br />noite de sono relaxante e tranquila.
            </p>
            <a href="/produtos/9847293873423"><button className="lp-sleep-gummy__inner__container__content__button">QUERO DORMIR</button></a>
          </div>
        </div>
      </div>

      <div className="lp-sleep-gummy__container ">
        <div className="lp-sleep-gummy__container__inner mobile-hidden">
          <img src="/assets/img/lp-sleep-gummy/icon-freeshipping.png"
            className="lp-sleep-gummy__container__inner__icon" />
          <img src="/assets/img/lp-sleep-gummy/icon-brazil.png"
            className="lp-sleep-gummy__container__inner__icon" />
          <img src="/assets/img/lp-sleep-gummy/icon-payment.png"
            className="lp-sleep-gummy__container__inner__icon" />
          <img src="/assets/img/lp-sleep-gummy/icon-security.png"
            className="lp-sleep-gummy__container__inner__icon" />
        </div>

        <div className="lp-sleep-gummy__container-m mobile-visible">
          <div>
            <img src="/assets/img/lp-sleep-gummy/icon-freeshipping.png"
              className="lp-sleep-gummy__container__inner__icon" />
            <img src="/assets/img/lp-sleep-gummy/icon-brazil.png"
              className="lp-sleep-gummy__container__inner__icon" />
          </div>
          <div className="lp-sleep-gummy__container-m__two mobile-visible">
            <img src="/assets/img/lp-sleep-gummy/icon-payment.png"
              className="lp-sleep-gummy__container__inner__icon" />
            <img src="/assets/img/lp-sleep-gummy/icon-security.png"
              className="lp-sleep-gummy__container__inner__icon" />
          </div>
        </div>
      </div>

      <div className="lp-sleep-gummy__gif">

        <img src="https://themens-public.s3.sa-east-1.amazonaws.com/4.gif"
          alt="gif"
          className="lp-sleep-gummy__gif__video mobile-hidden" />

        <div className="lp-sleep-gummy__gif__content">
          <h2 className="lp-sleep-gummy__gif__content__title">Mais qualidade de vida dormindo</h2>
          <img src="https://themens-public.s3.sa-east-1.amazonaws.com/4.gif"
            alt="gif"
            className="lp-sleep-gummy__gif__content__video mobile-visible" />
          <p className="lp-sleep-gummy__gif__content__paragraph ">
            <b>Dormir mais rápido</b> e por <b>mais tempo</b> é a <b>chave</b> para ter
            <b> disposição, humor e clareza mental</b> e só é possível através
            da pureza do hormônio do sono contido na Sleep gummy.<br /><br />
            Acredite, se você estiver contando carneirinhos e não
            consegue dormir, relaxa por que com esses ursinhos em
            gummy suas noites de sono não serão mais as mesmas.
          </p>
        </div>
      </div>

      <div className="lp-sleep-gummy__benefits">
        <h3 className="lp-sleep-gummy__benefits__title mobile-hidden">O que você ganha dormindo bem</h3>
        <img src="/assets/img/lp-sleep-gummy/benefits.png"
          className="lp-sleep-gummy__benefits__image mobile-hidden" />
        <img src="/assets/img/lp-sleep-gummy/benefits-m.png"
          className="lp-sleep-gummy__benefits__image mobile-visible" />
        <a href="/produtos/9847293873423"><button className="lp-sleep-gummy__benefits__button">FAÇA SEU PEDIDO</button></a>
      </div>

      <div className="lp-sleep-gummy__sunset">
        <img src="/assets/img/lp-sleep-gummy/sunset.png"
          className="lp-sleep-gummy__sunset__image mobile-hidden" />
        <img src="/assets/img/lp-sleep-gummy/sunset-m.png"
          className="lp-sleep-gummy__sunset__image mobile-visible" />
        <div className="lp-sleep-gummy__sunset__container">
          <p className="lp-sleep-gummy__sunset__container__content">
            <span className="lp-sleep-gummy__sunset__container__content__title" >Escuta essa...</span><br />
            O corpo humano foi programado para produzir melatonina no período em que o sol se põe (ou quando escurece).<br /><br />
            Celulares, TVs, Computadores prejudicam a produção e consequentemente seu sono.<br /><br />
            Existe uma maneira inteligente de estimular a produção em seu nível natural e ter um sono reparador...
          </p>
        </div>
      </div>

      <div className="lp-sleep-gummy__passaport">
        <div className="lp-sleep-gummy__passaport__content">
          <img className="lp-sleep-gummy__passaport__content__image-woman mobile-visible"
            src="/assets/img/lp-sleep-gummy/passaport.png" />
          <h2 className="lp-sleep-gummy__passaport__content__title">Aqui está seu passaporte para a terra dos sonhos</h2>
          <p className="lp-sleep-gummy__passaport__content__paragraph">
            A Sleep Gummy possui a dose ideal de Melatonina no mais alto grau de pureza que atua para acalmar sua
            mente e promover um sono reparador.<br /><br />
            Essas gomas de melatonina ajudam a estimular o hormônio natural do sono e promovem o relaxamento
            da mente e do corpo para que você acorde revigorado.
          </p>
          <a href="/produtos/9847293873423"><button className="lp-sleep-gummy__passaport__content__button" >QUERO AGORA</button></a>
        </div>
        <div className="lp-sleep-gummy__passaport__image mobile-hidden" >
          <img className="lp-sleep-gummy__passaport__inner__image-woman mobile-hidden"
            src="/assets/img/lp-sleep-gummy/passaport.png" />
        </div>
      </div>

      <div className="lp-sleep-gummy__comparation">
        <h2 className="lp-sleep-gummy__comparation__title">O que faz a Sleep Gummy<br />ser diferente?</h2>
        <div className="lp-sleep-gummy__comparation__others">
          <img src="/assets/img/lp-sleep-gummy/others.png"
            className="lp-sleep-gummy__comparation__image" />
          <p className="lp-sleep-gummy__comparation__x">X</p>
          <img src="/assets/img/lp-sleep-gummy/melatonin.png"
            className="lp-sleep-gummy__comparation__image" />
        </div>
        <div className="lp-sleep-gummy__comparation__container mobile-hidden">
          <div className="lp-sleep-gummy__comparation__container__others">
            <h2 className="lp-sleep-gummy__comparation__container__others__title">OUTROS</h2>
            <ul className="lp-sleep-gummy__comparation__container__list-others" >
              <li>Composição desconhecida</li><br />
              <li>Pureza desconhecida</li><br />
              <li>Cápsulas difíceis de engolir</li><br />
              <li>Necessita de água ou comida<br /> para comer</li><br />
              <li>Começa a absorver no<br />estômago</li><br />
            </ul>
          </div>
          <div className="lp-sleep-gummy__comparation__container__melatonin">
            <h2 className="lp-sleep-gummy__comparation__container__melatonin__title">SLEEP GUMMY</h2>
            <ul className="lp-sleep-gummy__comparation__container__list-melatonin">
              <li><b>10X</b> mais forte e zero açúcar</li><br />
              <li>Pureza de <b>99,3%</b></li><br />
              <li>Gelatina gostosa e <b>fácil de comer</b></li><br />
              <li><b>Não necessita de água</b> ou<br />comida para comer</li><br />
              <li><b>Absorção imediata</b> por ser<br />em gelatina</li><br />
            </ul>
          </div>
        </div>

        <div className="lp-sleep-gummy__comparation__container mobile-visible">
          <div className="lp-sleep-gummy__comparation__container">
            <div className="lp-sleep-gummy__comparation__container__others">
              <h2 className="lp-sleep-gummy__comparation__container__others__title">OUTROS</h2>
              <ul className="lp-sleep-gummy__comparation__container__list-others" >
                <li>Composição<br />desconhecida</li><br />
                <li>Pureza<br />desconhecida</li><br />
                <li>Cápsulas difíceis<br />de engolir</li><br />
                <li>Necessita de<br /> água ou comida<br /> para comer</li><br />
                <li>Começa a<br />absorver no<br />estômago</li><br />
              </ul>
            </div>
            <div className="lp-sleep-gummy__comparation__container__melatonin">
              <h2 className="lp-sleep-gummy__comparation__container__melatonin__title">SLEEP GUMMY</h2>
              <ul className="lp-sleep-gummy__comparation__container__list-melatonin">
                <li><b>10X</b> mais forte e<br />zero açúcar</li><br />
                <li>Pureza de <b>99,3%</b></li><br /><br />
                <li>Gelatina gostosa<br />e <b>fácil de comer</b></li><br />
                <li><b>Não necessita de<br />água</b> ou comida<br />para comer</li><br />
                <li><b>Absorção<br />imediata</b> por ser<br />em gelatina</li><br />
              </ul>
            </div>
          </div>
        </div>
        <img src="/assets/img/lp-sleep-gummy/arrow.png"
          className="lp-sleep-gummy__comparation__arrow" />
      </div>

      <div className="lp-sleep-gummy__product">
        <img src="/assets/img/lp-sleep-gummy/sky.png"
          className="lp-sleep-gummy__product__sky mobile-hidden " />
        <img src="/assets/img/lp-sleep-gummy/sky-m.png"
          className="lp-sleep-gummy__product__sky mobile-visible " />
        <div className="lp-sleep-gummy__product__container">
          <div className="lp-sleep-gummy__product__container__content">
            <a href="/produtos/9847293873423"
              className="mobile-visible">
              <img src="/assets/img/lp-sleep-gummy/product-m.png"
                className="lp-sleep-gummy__product__container__content__product-m mobile-visible" />
              <img src="/assets/img/lp-sleep-gummy/sleepgummy-m.png"
                className="lp-sleep-gummy__product__container__content__image mobile-visible" />
            </a>
            <img src="/assets/img/lp-sleep-gummy/sleepgummy.png"
              className="lp-sleep-gummy__product__container__content__image mobile-hidden" />
            <a href="/produtos/9847293873423"><div className="lp-sleep-gummy__product__container__content__ghost-button mobile-hidden" /></a>
          </div>
        </div>
      </div>

      <div className="lp-sleep-gummy__satisfaction">
        <h2 className="lp-sleep-gummy__satisfaction__title mobile-hidden">Dorminhocos satisfeitos</h2>
        <img src="/assets/img/lp-sleep-gummy/satisfaction.png"
          className="lp-sleep-gummy__satisfaction__image mobile-hidden" />
        <img src="/assets/img/lp-sleep-gummy/satisfaction-m.jpeg"
          className="lp-sleep-gummy__satisfaction__image mobile-visible" />
      </div>

      <div className="lp-sleep-gummy__faq">
        <div className="lp-sleep-gummy__faq__title">
          Perguntas frequentes
        </div>

        <div className="sleep-gummy__content__faq__container">
          {sleepGummyFaq.map((o, i) =>
            <div key={o.question}
              onClick={() => openFaqItem(i)}
              className="sleep-gummy__content__faq__container__item">
              <div className="sleep-gummy__content__faq__container__item__title">
                <p>{o.question}</p> <span><p>+</p></span>
              </div>
              {faqOpen === i && (
                <div className="sleep-gummy__content__faq__container__item__text">
                  {o.answer}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="lp-sleep-gummy__phrase">
        <img src="/assets/img/products-images/the-mens-delivery-phrase.jpg"
          className="lp-sleep-gummy__phrase__image mobile-hidden" />
      </div>

      <div className="lp-sleep-gummy__recomended">
        <RecomendedProducts mobile={window.innerWidth <= 1100} />
      </div>

    </div>
  );
}

export default LpSleepGummy;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowLeftOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import IcAttention from '../../../assets/svg/ic_atention.svg';
import { AuthActions, CartActions, PrescriptionActions } from '../../../redux/actions';
import CheckoutFloatingBar from '../../../components/CheckoutFloatingBar/CheckoutFloatingBar';
import AdvancedFileUploader from '../../../components/AdvancedFileUploader';
import PrescriptionCard from '../../../components/PrescriptionCard/PrescriptionCard';
import { prescriptionNeeded } from '../../../utils/string';
import WhatsappHelpInfo from '../../../components/WhatsappHelpInfo/WhatsappHelpInfo';
import PrescriptionProductType from '../../../enum/prescriptionProductType';
import AuthService from '../../../services/auth';
import { translate } from '../../../services/i18n';

const CheckoutStepTwo = ({
  products,
  prescription,
  changePrescription,
  uploadPrescription,
  checkForValidPrescription,
  getPrescriptionDetails,
  getMe,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [ hasPrescription, setHasPrescription ] = useState(false);

  useEffect(() => {
    AuthService.isFromEcommerceDomain();

    if (!products.length) {
      history.push('/checkout/revisao');
    } else if (products && !prescriptionNeeded(products)) {
      history.push('/checkout/dados-de-entrega');
    }
  }, [ pathname, products ]);

  useEffect(() => {
    checkForPrescriptionAvailable();
  }, []);
  useEffect(() => {
    getMe();
  }, [ pathname ]);

  useEffect(() => {
    if (prescription) {
      setHasPrescription(true);
    }
  }, [ prescription ]);

  const onNextClick = async () => {
    history.push('/checkout/dados-de-entrega');
  };

  const removePrescription = async () => {
    changePrescription(null);
    setHasPrescription(false);
  };

  const getNextLabel = () => {
    if (!prescription) {
      return 'Upload';
    }

    return 'Avançar';
  };

  const onBackClick = () => {
    history.push('/checkout/revisao');
  };

  const getPrescriptionType = () => {
    const minoxidil = products.find(o => o.id === '6f9919da-d5cc-4948-88ae-5160852cb7b7');
    const finasterida = products.find(o => o.id === '40c5747d-1b87-4768-a3aa-c1186a9e3b1f');
    const sleepGummy = products.find(o => o.id === 'e743ec60-df88-4f4b-ac84-748be2473d2b');
    const ed = products.find(o => o.id === '312974bf-1dac-434d-8084-942e42beed8b');

    if (minoxidil && !finasterida) {
      return PrescriptionProductType.MINOXIDIL;
    }

    if (!minoxidil && finasterida) {
      return PrescriptionProductType.FINASTERIDA;
    }

    if (minoxidil && finasterida) {
      return PrescriptionProductType.ALL;
    }

    if (sleepGummy) {
      return PrescriptionProductType.SLEEP_GUMMY;
    }

    if (ed) {
      return PrescriptionProductType.ED;
    }
  };

  const onChangePrescriptionFile = (file) => {
    uploadPrescription({
      prescribedProducts: getPrescriptionType(),
      base64: file.base64,
    });
  };

  const checkForPrescriptionAvailable = () => {
    if (products) {
      const productsIds = products.map((o) => o.id).join(',');
      checkForValidPrescription({
        products: productsIds,
      }, (response) => {
        if (response) {
          history.push('/checkout/dados-de-entrega');
        }
      });
    }
  };

  return (
    <div className="checkout checkout--step-three">
      <button
        className="transparent-button checkout__go-back"
        onClick={() => onBackClick()}
      >
        <ArrowLeftOutlined />
        <span className="checkout__go-back__text">Voltar</span>
      </button>
      <div className="checkout__upper">
        <div className="checkout__upper__title">
          <span className="checkout__upper__title__icon">
            <PaperClipOutlined />
          </span>
          <h2 className="checkout__upper__title__text">
            AVALIAÇÃO MÉDICA
          </h2>
        </div>
      </div>
      <div className="checkout__upper__title__form">
        <div className="checkout__upper__title__form__desc">
          Alguns itens do seu carrinho necessitam de avaliação médica para efetuar a compra. Caso não tenha uma, nós
          temos um time de médicos independentes para atende-lo e gratuitamente:
        </div>
        {!hasPrescription && (
          <div>
            <button
              className="checkout__upper__button"
              onClick={() => history.push('/avaliacao')}
            >
              AVALIAÇÃO MÉDICA
              <br />
              (R$0,00)
            </button>
            <button
              className="checkout__upper__button checkout__upper__button--ghost"
              onClick={() => setHasPrescription(true)}
            >
              JÁ POSSUO PRESCRIÇÃO
            </button>
          </div>
        )}

        {!prescription && hasPrescription && (
          <div>
            <div className="checkout__content__form__upload">
              <AdvancedFileUploader
                uploadClassName="full-width checkout__content__form__upload__uploader"
                onChange={(file) => onChangePrescriptionFile(file)}
                hidePreview
                type="file"
                accept="image/*,.pdf,.doc,.docx"
              >
                <button
                  className="checkout__content__form__upload__uploader__button"
                  type="button"
                >
                  <span className="checkout__content__form__upload__uploader__button__inner">
                    <span className="checkout__content__form__upload__uploader__button__inner__text">
                      <strong>Fazer upload agora</strong>
                      &nbsp;{'\n'}
                      (foto ou arquivo)
                    </span>
                    <span className="checkout__content__form__upload__uploader__button__inner__icon">
                      <UploadOutlined />
                    </span>
                  </span>
                </button>
              </AdvancedFileUploader>
              <div className="checkout__content__form__attention">
                <div className="checkout__content__form__attention__title">
                  <img src={IcAttention} />
                  <div className="checkout__content__form__attention__title__phrase">
                    ATENÇÃO:
                  </div>
                </div>
                Sua prescrição só será aceita caso esteja de acordo com as instruções a seguir:
                1) A prescrição esteja em nome do paciente cadastrado;
                2) A prescrição tenha data legível;
                3) A prescrição tenha sido emitida até 6 mêses da data de solicitação. (Validade);
                4) A prescrição esteja legível e conste a assinatura e carimbo do médico, com CRM válido;
                5) A prescrição não contenha qualquer rasura ou alteração visível; 6) A prescrição esteja com os
                medicamentos e dosagens iguais a solicitada. Não será possível substituir medicamentos (Exemplo:
                Minoxidil
                em comprimido ser trocado por solução; ou minoxidil 2% ser trocado por 5%).
              </div>
            </div>
          </div>
        )}
        {prescription && (
          <div className="checkout__upper checkout__upper--prescription">
            <div>
              <PrescriptionCard
                prescription={prescription}
                onRemove={() => removePrescription()}
              />
            </div>
          </div>
        )}
        {(hasPrescription && !prescription) && (
          <div className="checkout__upper--prescription__cancel">
            <button
              className="checkout__upper--prescription__cancel__button"
              onClick={() => removePrescription()}
            >
              <ArrowLeftOutlined /> Cancelar
            </button>
          </div>
        )}
      </div>
      <div className="checkout__lower">
        <div className="checkout__lower__title">{translate('APPLICATION_NAME')}</div>
        Endereço: Al. dos tupiniquins, 414 - Planalto Paulista - São Paulo / SP - CNPJ: 40.890.511/0001-40
      </div>
      <CheckoutFloatingBar
        onNextClick={() => onNextClick()}
        nextLabel={getNextLabel()}
        disabled={!prescription}
        removed={!prescription}
      />
      <WhatsappHelpInfo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
  products: state.cart.products || [],
  freight: state.cart.freight || null,
  coupon: state.cart.coupon || null,
  values: state.cart.values || null,
  prescription: state.cart.prescription || false,
});

const mapDispatchToProps = (dispatch) => ({
  checkPrescriptionValid: (parameters, callback) => dispatch(PrescriptionActions.checkValid(parameters, callback)),
  uploadPrescription: (parameters) => dispatch(CartActions.uploadPrescriptionFile(parameters)),
  changePrescription: (parameters) => dispatch(CartActions.changePrescription(parameters)),
  checkForValidPrescription: (parameters, callback) => dispatch(PrescriptionActions.checkValid(parameters, callback)),
  getPrescriptionDetails: (id) => dispatch(CartActions.getPrescriptionDetails(id)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepTwo);

const medias = [
  {
    link: 'https://cultura.estadao.com.br/blogs/direto-da-fonte/startup-foca-no-mercado-de-healthcare-masculino/',
    img: '/assets/svg/rotative-banner/estadao.png',
    text: '"estadão"',
  },
  {
    link: 'https://pipelinevalor.globo.com/startups/noticia/eurofarma-lidera-rodada-na-the-mens-um-health-club-masculino.ghtml',
    img: '/assets/svg/rotative-banner/valor.png',
    text: '"valor"',
  },
  {
    link: 'https://saladanoticia.com.br/noticia/7376/startup-the-men-s-desburocratiza-a-saude-e-o-bem-estar-masculino',
    img: '/assets/svg/rotative-banner/saladanoticia.png',
    text: '"saladanoticia"',
  },
  {
    link: 'https://www.projetodraft.com/a-the-mens-quer-simplificar-os-cuidados-dos-homens-com-a-saude-e-o-bem-estar/',
    img: '/assets/svg/rotative-banner/draft.png',
    text: '"draft"',
  },
  {
    link: 'https://cosmeticinnovation.com.br/startup-the-mens-desburocratiza-a-saude-e-o-bem-estar-masculino/',
    img: '/assets/svg/rotative-banner/ci.png',
    text: '"ci"',
  },
];

export default medias;
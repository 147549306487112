import React from 'react';
import moment from 'moment';
import { CloseOutlined } from '@material-ui/icons';

const PrescriptionCard = ({ prescription, onRemove }) => {
  return (
    <div className="prescription-card">
      <div className="prescription-card__meta">
        <div className="prescription-card_meta__title">
          Avaliação médica
        </div>
        <div className="prescription-card_meta__value">
          Criada em: {moment(prescription.createdAt).format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
      <div className="prescription-card__remove">
        <button
          className="transparent-button prescription-card__remove__button"
          onClick={() => onRemove()}
        >
          <CloseOutlined />
        </button>
      </div>
    </div>
  );
};

export default PrescriptionCard;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthActions, CartActions, PrescriptionActions, TransactionActions } from '../../../redux/actions';
import ApprovedBuy from '../../../assets/png/approved_pix.png';
import ApprovedCreditCard from '../../../assets/png/aproved.png';
import FinishSex from '../../../assets/png/saude-sexual.png';
import FinishHair from '../../../assets/png/saude-capilar.png';
import FinishSleep from '../../../assets/png/saude-sono.png';
import FinishSexMobile from '../../../assets/png/saude-sexual-mobile.png';
import FinishHairMobile from '../../../assets/png/saude-capilar-mobile.png';
import FinishSleepMobile from '../../../assets/png/saude-sono-mobile.png';
import * as ActionsCart from '../../../redux/actions/cart';
import { CartSymbol } from '../../../enum/cartSymbol';
import { Col, Container } from 'react-bootstrap';
import AuthService from '../../../services/auth';
import { message } from 'antd';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import { I18n } from 'react-redux-i18n';
import InfoCheckoutMessage from '../../../components/InfoCheckoutMessage/InfoCheckoutMessage';
import { getTransactionsItems } from '../../../services/product';
import RenderCondition from '../../../components/no-visual/renderCondition';
import { QuestionType } from '../../../services/prescription';

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  vertical: false,
  verticalSwiping: false,
  horizontal: true,
  horizontalSwiping: true,
  dotsClass: 'home-dots',
  autoplay: true,
  arrows: false,
  autoplaySpeed: 6000,
  centerPadding: '5px',
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const CheckoutStepFive = ({
  cleanTheCart,
  value,
  isPix,
  paymentDetails,
  me,
  getMe,
  userActivity,
  getTransactionMineDetails,
  products,
  detailsTransactions,
  getUserPrescriptionByType,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [ transactionDetails, setTransactionDetails ] = useState({});
  const [ userPrescription, setUserPrescription ] = useState([]);

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    cleanTheCart(true);
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname ]);

  useEffect(() => {
    if (!paymentDetails) return;

    userActivity({
      transactionId: paymentDetails.id,
      value: paymentDetails.total,
      source: pathname
    }, facebookUserActivity.finishCheckout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paymentDetails ]);

  const getMineDetails = async (product) => {
    const response = await getTransactionMineDetails(product);
    setTransactionDetails(response);
  };

  const getUserPrescription = async () => {
    const response = await getUserPrescriptionByType(QuestionType.ED);
    setUserPrescription(response);
  };

  useEffect(() => {
    getMineDetails('tadala');
    getUserPrescription();
  }, []);

  const { transactionItems } = paymentDetails;
  const identifier = '312974bf-1dac-434d-8084-942e42beed8b';
  const showInfoMessage = transactionItems && getTransactionsItems(transactionItems, identifier);

  return (
    <>
      {isPix && value ? (
        <>
          <div className='checkout__order--pix'>
            {I18n.t('CHECKOUT.STEP_FIVE.SUCCESS')}
          </div>
          <div className="checkout-status">
            <Container className="mobile-no-padding checkout-status__info__container">
              <Col lg={6}>
                <div className="checkout-status__info">
                  <div className="checkout-status__info__title">
                    <img
                      className="checkout-status__info__title__icon"
                      src={ApprovedBuy}
                      alt="" />
                    <div className="checkout-status__info__title__text">
                      <strong className="checkout-status__info__title__text__strong">PEDIDO #{paymentDetails?.code}</strong>
                    </div>
                  </div>
                  <div className="checkout-status__info__body">
                    <strong className="checkout-status__info__body__greet">{me?.name}!</strong>
                    <br />
                    <br />
                    {I18n.t('CHECKOUT.STEP_FIVE.RECEIVED')}
                    <br />
                    <br />
                    {I18n.t('CHECKOUT.STEP_FIVE.PAYMENT')}
                    <br />
                    <div className="checkout__content__info">
                      <div className="checkout__content__info__qr-code">
                        <h3 className="checkout__content__info__qr-code__title">
                          {I18n.t('CHECKOUT_STEP_FIVE.QRCODE.SCAN')}
                        </h3>
                        <img src={paymentDetails.pixQrCodeUrl}
                          alt="qrcode"
                          className="checkout__content__info__qr-code__image" />
                        <div className="checkout__content__info__qr-code__code-text">
                          <strong>{paymentDetails.pixQrCodeText}</strong>
                        </div>
                        <div
                          className="checkout__content__info__qr-code__copy"
                          onClick={() => {
                            navigator.clipboard.writeText(paymentDetails.pixQrCodeText);
                            message.success({ content: 'Código copiado!', className: 'ant-custom-style' });
                          }}
                        >
                          {I18n.t('CHECKOUT.STEP_FIVE.COPY_BUTTON')}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </Col>
            </Container>

            <RenderCondition
              condition={
                showInfoMessage &&
                userPrescription.length === 0 &&
                !transactionDetails.hasPrescription
              }
            >
              <InfoCheckoutMessage />
            </RenderCondition>

            <div className='checkout__content__return'>
              <a className='checkout__content__return__text'
                href="/">
                {I18n.t('CHECKOUT.STEP_FIVE.RETURN')}
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='checkout__order--credit-card'>
            {I18n.t('CHECKOUT.STEP_FIVE.SUCCESS')}
          </div>
          <div className="checkout-status">
            <Container className="mobile-no-padding checkout-status__info__container">
              <Col lg={6}>
                <div className="checkout-status__info">
                  <div className="checkout-status__info__title--credit-card">
                    <img
                      className="checkout-status__info__title__icon"
                      src={ApprovedCreditCard}
                      alt="" />
                    <div className="checkout-status__info__title__text">
                      <strong className="checkout-status__info__title__text__strong">PEDIDO #{paymentDetails?.code}</strong>
                    </div>
                  </div>
                  <div className="checkout-status__info__body">
                    <strong className="checkout-status__info__body__greet">{me?.name}!</strong>
                    <br />
                    <br />
                    {I18n.t('CHECKOUT.STEP_FIVE.APPROVED')}
                    <br />
                    <br />
                    {I18n.t('CHECKOUT.STEP_FIVE.SEND_EMAIL')}
                    <br />
                    <br />
                    {I18n.t('CHECKOUT.STEP_FIVE.ORDER')} <strong><u>{I18n.t('CHECKOUT.STEP_FIVE.MY_ORDER')}</u></strong>
                    <br />
                    <br />
                  </div>
                </div>
              </Col>
            </Container>

            <RenderCondition
              condition={
                showInfoMessage &&
                userPrescription.length === 0 &&
                !transactionDetails.hasPrescription
              }
            >
              <InfoCheckoutMessage />
            </RenderCondition>

            <div className='checkout__order__blog mobile-hidden'>
              <div>
                <h3 className='checkout__order__blog__title'>{I18n.t('CHECKOUT.STEP_FIVE.BLOG_TITLE')} {'Men\'s'}</h3>
                <div className='checkout__order__blog__items'>
                  <div className='checkout__order__blog__items__inner'>
                    <div className='checkout__order__blog__items__inner__img'>
                      <img
                        src={FinishSex}
                        alt=''
                      />
                    </div>
                    <div
                      className='checkout__order__blog__items__button'
                      onClick={() => window.location.href = '/'}>
                    </div>
                  </div>
                  <div className='checkout__order__blog__items__inner'>
                    <div className='checkout__order__blog__items__inner__img'>
                      <img
                        className=''
                        src={FinishSleep}
                        alt=''
                      />
                    </div>
                    <div
                      className='checkout__order__blog__items__button'
                      onClick={() => window.location.href = '/'}>
                    </div>
                  </div>
                  <div className='checkout__order__blog__items__inner'>
                    <div className='checkout__order__blog__items__inner__img'>
                      <img
                        className=''
                        src={FinishHair}
                        alt=''
                      />
                    </div>
                    <div
                      className='checkout__order__blog__items__button--last'
                      onClick={() => window.location.href = '/'}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Container className="mobile-no-padding checkout-status__info__container">
              <Col lg={6}>
                <div className='checkout__order__blog mobile-visible'>
                  <div>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <h3 className='checkout__order__blog__title'>{I18n.t('CHECKOUT.STEP_FIVE.BLOG_TITLE')} Men's</h3>
                    <div className='checkout__order__blog__items'>
                      <div className='checkout__order__blog__items__inner'>
                        <div className='checkout__order__blog__items__inner__img'>
                          <img
                            src={FinishSexMobile}
                            alt=''
                            className='checkout__order__blog__items__inner__img__image'
                          />
                        </div>
                        <a
                          target="_blank"
                          href='https://blog.themens.com.br/sexo/viagra-natural-existe/'
                          rel="noreferrer noopener">

                          <div
                            className='checkout__order__blog__items__button'
                          >
                          </div>
                        </a>
                      </div>
                      <div className='checkout__order__blog__items__inner'>
                        <div className='checkout__order__blog__items__inner__img'>
                          <img
                            className='checkout__order__blog__items__inner__img__image'
                            src={FinishSleepMobile}
                            alt=''
                          />
                        </div>
                        <a
                          target="_blank"
                          href='https://blog.themens.com.br/sono/o-que-causa-muito-sono/'
                          rel="noreferrer noopener">
                          <div
                            className='checkout__order__blog__items__button'
                          >
                          </div>
                        </a>
                      </div>
                      <div className='checkout__order__blog__items__inner'>
                        <div className='checkout__order__blog__items__inner__img'>
                          <img
                            className='checkout__order__blog__items__inner__img__image'
                            src={FinishHairMobile}
                            alt=''
                          />
                        </div>
                        <a
                          target="_blank"
                          href='https://blog.themens.com.br/cabelo/o-que-causa-e-como-tratar-a-queda-de-cabelo/'
                          rel="noreferrer noopener">
                          <div
                            className='checkout__order__blog__items__button--last'
                          >
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>
            <div className='checkout__content__return'>
              <a className='checkout__content__return__text'
                href="/">
                {I18n.t('CHECKOUT.STEP_FIVE.RETURN')}
              </a>
            </div>
          </div>
        </>
      )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  paymentDetails: state.transaction.details,
  products: state.cart.products || [],
  detailsTransactions: state.detailsTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  cleanTheCart: (keepValues) => dispatch(CartActions.cleanTheCart(keepValues)),
  addProductsInCart: (id) => dispatch(ActionsCart.addOrRemoveProduct(id, 1, CartSymbol.ADD)),
  getMe: () => dispatch(AuthActions.getMe()),
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
  getTransactionMineDetails: (params) => dispatch(TransactionActions.getTransactionsMineDetails(params)),
  getUserPrescriptionByType: (type) => dispatch(PrescriptionActions.getUserPrescriptionByType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepFive);

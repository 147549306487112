const TransactionStatus = {
  CREATED: 1, // A transação é criada antes de qualquer pagamento ser realizado
  PROCESSING_PAYMENT: 2, // Caso boleto, caso contrário cai em PAID
  PAID: 3,
  IN_ANALYSIS: 4, // Caso de prescrição não estar aprovada/válida
  SHIPPED: 5,
  DONE: 6,
  CANCELLED: 7,
};

export default TransactionStatus;

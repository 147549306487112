import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import sleepGummyFaq from '../../../services/sleepGummyFaq';
import RecomendedProducts from '../../../components/RecomendedProducts/RecomendedProducts';

function SleepGummy (props) {
  const [ faqOpen, setFaqOpen ] = useState(null);
  const history = useHistory();
  const refs = useRef();
  const refMobile = useRef();

  const openFaqItem = (index) => {
    if (faqOpen === index) return setFaqOpen(null);

    setFaqOpen(index);
  };

  const returnUrl = () => {
    const { host, protocol } = window.location;
    return `${protocol}//${host}/?product=e743ec60-df88-4f4b-ac84-748be2473d2b`;
  };

  const redirectTo = (index) => {
    if (props.redirectToCart) return (window.location.href = returnUrl());

    history.push('/produtos/9847293873423');
  };

  return (
    <div className="sleep-gummy">
      <div className="sleep-gummy__hero">
        <img
          onClick={() => history.push('/')}
          src="/assets/img/sleep-gummy/logo.png"
          className="sleep-gummy__hero__logo"
          alt="Sleep gummy"
        />
        <h4 className="sleep-gummy__hero__text">
          Sleep Gummy te ajuda a acalmar a mente e a ter uma boa noite de sono
        </h4>
        <div
          onClick={() => refs.current.scrollIntoView({ behavior: 'smooth' })}
          className="sleep-gummy__hero__button mobile-hidden"
        >
          DURMA BEM AGORA
        </div>
        <iframe
          className="sleep-gummy__hero__yt"
          src={'https://www.youtube.com/embed/J5UhCjH4-mM'}
          title="video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />{' '}
        <div
          onClick={() =>
            refMobile.current.scrollIntoView({ behavior: 'smooth' })
          }
          className="sleep-gummy__hero__button--mobile mobile-visible"
        >
          DURMA BEM AGORA
        </div>
        <img
          src="/assets/img/sleep-gummy/hero.png"
          className="sleep-gummy__hero__hero"
          alt="Sleep gummy"
        />
      </div>
      <div className="sleep-gummy__content">
        <img
          className="sleep-gummy__content__one mobile-hidden"
          src="/assets/img/sleep-gummy/content-1.png"
          alt="sleep gummy"
        />
        <img
          className="sleep-gummy__content__one mobile-visible"
          src="/assets/img/sleep-gummy/content-1-m.png"
          alt="sleep gummy"
        />
        <img
          className="sleep-gummy__content__two mobile-hidden"
          src="/assets/img/sleep-gummy/content-2.png"
          alt="sleep gummy"
        />
        <img
          className="sleep-gummy__content__two  mobile-visible"
          src="/assets/img/sleep-gummy/content-2-m.png"
          alt="sleep gummy"
        />
        <div className="sleep-gummy__content__three">
          <div className="sleep-gummy__content__three__title">Diferenciais</div>
          <div className="sleep-gummy__content__three__container">
            <div className="sleep-gummy__content__three__container__item">
              <img
                className="sleep-gummy__content__three__container__item__img"
                src="https://themens-public.s3.sa-east-1.amazonaws.com/3.gif"
                alt="natural"
              />
              <div className="sleep-gummy__content__three__container__item__title">
                Natural
              </div>
              <div className="sleep-gummy__content__three__container__item__text">
                A melatonina é um hormônio
                <br /> já produzido e liberado por
                <br /> nossos corpos à noite - é a<br /> maneira do nosso corpo
                nos dizer <br />
                que é hora de dormir.
              </div>
            </div>
            <div className="sleep-gummy__content__three__container__item">
              <img
                className="sleep-gummy__content__three__container__item__img"
                src="https://themens-public.s3.sa-east-1.amazonaws.com/1.gif"
                alt="natural"
              />
              <div className="sleep-gummy__content__three__container__item__title">
                Maior pureza
              </div>
              <div className="sleep-gummy__content__three__container__item__text">
                Aqui você encontra a maior
                <br /> pureza por gummy, são 99.3%
                <br /> de melatonina! O sono virá <br />
                mais rápido além de acordar
                <br /> mais dispostos. Mas vá com
                <br /> calma, o ursinho é forte.
              </div>
            </div>
            <div className="sleep-gummy__content__three__container__item">
              <img
                className="sleep-gummy__content__three__container__item__img"
                src="https://themens-public.s3.sa-east-1.amazonaws.com/2.gif"
                alt="natural"
              />
              <div className="sleep-gummy__content__three__container__item__title">
                Absorção mais rápida
              </div>
              <div className="sleep-gummy__content__three__container__item__text">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Antes que você se pergunte "mas
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <br /> por que em Gummy", nós te <br />
                respondemos: porque a absorção
                <br /> é muito mais rápida do que em
                <br /> pílula. Ou seja: colocou na boca
                <br /> seu corpo já começa a absorver.
              </div>
            </div>
          </div>
        </div>
        <img
          className="sleep-gummy__content__five mobile-hidden"
          src="/assets/img/sleep-gummy/content-4.png"
          alt=""
        />
        <img
          className="sleep-gummy__content__five mobile-visible"
          src="/assets/img/sleep-gummy/content-4-m.png"
          alt=""
        />
        <div className="sleep-gummy__content__four mobile-hidden">
          <div ref={refs}
            className="sleep-gummy__content__four__title">
            Sleep Gummy
          </div>
          <div className="sleep-gummy__content__four__container">
            <div className="sleep-gummy__content__four__container__text">
              Auxilia a dormir melhor e acordar com mais disposição¹.
              <br />Pote com 30 doses de 2mg de
              <br /> melatonina com 99.3% de pureza.
            </div>
            <div className="sleep-gummy__content__four__container__price">
              3x de <span className="bigger">R$ 26,60</span>
              <br />
              ou à vista por
              <span className="big">
                <br />
                R$ 79,90
              </span>{' '}
              + FRETE GRÁTIS
            </div>
            <div
              onClick={() => redirectTo()}
              className="sleep-gummy__content__four__container__button"
            >
              DURMA BEM AGORA
            </div>
            <img
              className="sleep-gummy__content__four__container__banner"
              src="/assets/img/sleep-gummy/price-banner.png"
              alt=""
            />
          </div>
          <div className="sleep-gummy__content__four__disclaimer">
            *Após a compra, você será chamada no whatsapp para realizar uma
            consulta médica gratuita. Caso o médico lhe
            <br /> prescreva, despacharemos seu pedido. Em caso negativo,
            devolveremos seu dinheiro imediatamente.
          </div>
        </div>
        <img
          ref={refMobile}
          onClick={() => redirectTo()}
          className="sleep-gummy__content__four mobile-visible"
          src="/assets/img/sleep-gummy/content-5-m.png"
          alt=""
        />
        <img
          className="sleep-gummy__content__six mobile-hidden"
          src="/assets/img/sleep-gummy/content-6.png"
          alt=""
        />
        <img
          className="sleep-gummy__content__six mobile-visible"
          src="/assets/img/lp-sleep-gummy/satisfaction-m.jpeg"
          alt=""
        />
        <div className="sleep-gummy__content__faq">
          <div className="sleep-gummy__content__faq__title">
            Perguntas frequentes
          </div>
          <div className="sleep-gummy__content__faq__container">
            {sleepGummyFaq.map((o, i) => (
              <div
                key={o.question}
                onClick={() => openFaqItem(i)}
                className="sleep-gummy__content__faq__container__item"
              >
                <div className="sleep-gummy__content__faq__container__item__title">
                  <p>{o.question}</p>{' '}
                  <span>
                    <p>+</p>
                  </span>
                </div>
                {faqOpen === i && (
                  <div className="sleep-gummy__content__faq__container__item__text">
                    {o.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="sleep-gummy__content__recomended">
          <RecomendedProducts mobile={window.innerWidth <= 1100} />
        </div>
      </div>
    </div>
  );
}

export default SleepGummy;

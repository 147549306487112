import React, { useEffect } from 'react';
import { translate } from '../../../services/i18n';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import AuthService from '../../../services/auth';

const { Panel } = Collapse;

const Help = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  return (
    <div className="help">
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <div className="help__breadcrumb">
              <Breadcrumb
                items={[
                  {
                    link: '/',
                    name: 'Home',
                  },
                  {
                    link: '/',
                    name: 'Podemos ajudar?',
                    disabled: true,
                  },
                ]}
              />
            </div>

            <h2 className="help__title">
              {translate('COMPONENTS.HELP.TITLE')}
            </h2>
            <div className="help__block">
              <Collapse bordered={false}>
                {Object.values(translate('COMPONENTS.HELP.ITEMS')).map((item, itemKey) => (
                  <Panel
                    header={item.TITLE}
                    key={itemKey.toString()}
                  >
                    <Row gutter={16}>
                      <a
                        className="help__block__link"
                        href={item.DESCRIPTION_LINK}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.DESCRIPTION}
                      </a>
                    </Row>
                    {item.DESCRIPTION_PRESCRIPTION && (
                      <Row gutter={16}>
                        <a
                          className="help__block__link"
                          href={item.DESCRIPTION_PRESCRIPTION_LINK}
                          // eslint-disable-next-line react/jsx-no-target-blank
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.DESCRIPTION_PRESCRIPTION}
                        </a>
                      </Row>
                    )}
                  </Panel>
                ))}
              </Collapse>
              <Collapse accordion>
                <Panel header={translate('COMPONENTS.HELP.ASKED_FREQUENTLY.TITLE')}>
                  {Object.values(translate('COMPONENTS.HELP.ASKED_FREQUENTLY.DOUBTS')).map((item, itemKey) => (
                    <Collapse key={itemKey.toString()}>
                      <Panel
                        header={item.TITLE}
                        key={itemKey.toString()}
                      >
                        <p>
                          {item.DESCRIPTION}
                        </p>
                      </Panel>
                    </Collapse>
                  ))}
                </Panel>
              </Collapse>
              <h3 className="help__block__title">
                {translate('COMPONENTS.HELP.TALK')}
              </h3>
              <a
                className="help__block__link"
                href="mailto:contato@themens.com.br"
              >
                {translate('COMPONENTS.HELP.EMAIL')}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default Help;

import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { decreaseLoading, increaseLoading } from './loading';
import TrackOrderApi from '../../api/track-order';

export const ACTION_SAVE_STATUS_RESPONSE = 'ACTION_SAVE_STATUS_RESPONSE';


export const getOrderStatus = (code, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await TrackOrderApi.getOrderStatus(code);
    dispatch({
      type: ACTION_SAVE_STATUS_RESPONSE,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback({
        ...response,
        error: false,
      });
    }
  } catch (err) {
    if (callback && typeof callback === 'function') {
      callback({
        error: true,
      });
    }

    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error((`${errors[err.message]}`));
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error((`${errors[err.response]}`));
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

import React from 'react';
import { Helmet } from 'react-helmet-async';

import { translate } from '../../services/i18n';

const PrescriptionContent = (props) => {
  return (
    <div className="prescription-content">
      <Helmet>
        <title>{`${props.pageTitle} - ${translate('APPLICATION_NAME')}`}</title>
      </Helmet>

      <div className="prescription-content__inner">
        {props.children}
      </div>
    </div>
  );
};

export default PrescriptionContent;

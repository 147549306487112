const { newrelic } = window;

export const sendError = (err) => {
  if (process.env.NODE_ENV === 'production') {
    newrelic.noticeError(err);
  }
};

export const setProp = (name, value) => {
  newrelic.interaction()
    .setAttribute(name, value);
};

import { CartActions } from '../actions';

const initialState = {
  productsInCart: [],
  calculatedProducts: {},
  totalQuantity: 0,

  //new
  products: [],
  freight: null,
  freightType: null,
  freightOptions: null,
  coupon: null,
  values: null,
  recurrent: false,
  prescription: null,
  addressByZipcode: null,
  paymentAddressByZipcode: null,
  paymentData: null,
  qsCoupon: null,
  installmentOptions: [],
};

const cartReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CartActions.ACTION_CHANGE_PRODUCTS:
      state = {
        ...state,
        products: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_COUPON:
      state = {
        ...state,
        coupon: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_FREIGHT:
      state = {
        ...state,
        freight: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_FREIGHT_TYPE:
      state = {
        ...state,
        freightType: action.payload,
      };
      return state;

    case CartActions.ACTION_SET_FREIGHT_OPTIONS:
      state = {
        ...state,
        freightOptions: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_VALUES:
      state = {
        ...state,
        values: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_RECURRENT:
      state = {
        ...state,
        recurrent: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_PRESCRIPTION:
      state = {
        ...state,
        prescription: action.payload,
      };
      return state;

    case CartActions.ACTION_CHANGE_PAYMENT_DATA:
      state = {
        ...state,
        paymentData: action.payload,
      };
      return state;

    case CartActions.ACTION_ADDRESS_BY_ZIPCODE:
      state = {
        ...state,
        addressByZipcode: action.payload,
      };
      return state;

    case CartActions.ACTION_PAYMENT_ADDRESS_BY_ZIPCODE:
      state = {
        ...state,
        paymentAddressByZipcode: action.payload,
      };
      return state;

    case CartActions.ACTION_INSTALLMENT_OPTIONS:
      state = {
        ...state,
        installmentOptions: action.payload,
      };
      return state;

    // old
    case CartActions.ACTION_ADD_PRODUCTS_IN_CART:
      state = {
        ...state,
        productsInCart: action.payload,
      };
      return state;
    case CartActions.ACTION_CALCULATE_PRODUCTS_CART:
      state = {
        ...state,
        calculatedProducts: action.payload,
      };
      return state;
    case CartActions.ACTION_COUNT_TOTAL_QUANTITY_CART:
      state = {
        ...state,
        totalQuantity: action.payload,
      };
      return state;

    case CartActions.ACTION_SET_QS_COUPON:
      state = {
        ...state,
        qsCoupon: action.payload,
      };
      return state;
    case CartActions.ACTION_DELETE_ALL_PRODUCTS_IN_CART:
      state = {
        productsInCart: [],
        calculatedProducts: {},
        totalQuantity: 0,
      };
      return state;
    default:
      return state;
  }
};

export default cartReducer;

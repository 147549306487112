import React from 'react';

const CustomText = ({
  customClassName,
  text,
  value,
  className
}) => {
  return (
    <div className={className}>
      {text}
      <span className={customClassName}>
        {value}
      </span>
    </div>
  );
};

export default CustomText;
export const LINK_TYPES = {
  US: '/quem-somos',
  BLOG: 'https://blog.themens.com.br/',
  HELP: '/podemos-ajudar',
  POLICY: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf',
  TERMS: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf',
  PRIVACY: 'https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf',
  SECURE_SITE: 'https://transparencyreport.google.com/safe-browsing/search?url=themens.com.br&hl=pt-PT',
};

export const LINK_TYPES_MAP = {
  'QUEM SOMOS': LINK_TYPES.US,
  'BLOG': LINK_TYPES.BLOG,
  'PODEMOS AJUDAR?': LINK_TYPES.HELP,
  'POLÍTICAS DE TROCAS E DEVOLUÇÕES': LINK_TYPES.POLICY,
  'TERMOS E CONDIÇÕES': LINK_TYPES.TERMS,
  'POLÍTICA DE PRIVACIDADE': LINK_TYPES.PRIVACY,
  'SITE SEGURO': LINK_TYPES.SECURE_SITE,
};

export const LINK_TYPES_CHECKOUT = {
  POLICY: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf',
  TERMS: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf',
  PRIVACY: 'https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf',
};

export const LINK_TYPES__CHECKOUT_MAP = {
  'Política de Devolução': LINK_TYPES_CHECKOUT.POLICY,
  'Termos de Uso': LINK_TYPES_CHECKOUT.TERMS,
  'Política de Privacidade': LINK_TYPES_CHECKOUT.PRIVACY,
};

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import PageFooter from '../PageFooter/PageFooter';
import { translate } from '../../services/i18n';
import PageHeader from '../PageHeader/PageHeader';
import CheckoutCart from '../CheckoutCart/CheckoutCart';
import { connect } from 'react-redux';
import LoginModal from '../LoginModal/LoginModal';
import { useLocation } from 'react-router-dom';
import CheckoutPaginated from '../../pages/Checkout/CheckoutPaginated/CheckoutPaginated';
import CheckoutFooter from '../CheckoutFooter/CheckoutFooter';
import { isCheckoutURL } from '../../utils/checkout';

const CartContent = (props) => {

  const invisiblePaginated = () => {
    return pathname !== '/checkout/resultado-pagamento' && props.products.length > 0;
  };

  const { pathname } = useLocation();

  return (
    <div className="cart-content">
      <LoginModal />
      <Helmet>
        <title>{`${props.pageTitle} - ${translate('APPLICATION_NAME')}`}</title>
      </Helmet>

      <PageHeader />
      {invisiblePaginated() && <CheckoutPaginated />}

      {!props.noCart && (
        <>
          {props.products && props.products.length > 0 && (
            <div className="cart-content__box">
              <div className="cart-content__box__content">
                {props.children}
              </div>
              {window.innerWidth > 1100 && (
                <div className="cart-content__box__cart mobile-hidden">
                  <CheckoutCart
                    onNextClick={props.onNextClick}
                    finished={props.finished}
                    padded={props.padded}
                    nextButtonStyle={props.nextButtonStyle}
                    debounce={props.debounce}
                  />
                </div>
              )}
            </div>
          )}
          {props.products && props.products.length === 0 && (
            <>
              <div className="checkout__empty">
                <h1 className="checkout__empty__title">
                  Carrinho vazio
                </h1>
                <div className="checkout__empty__subtitle">
                  Você ainda não adicionou nenhum item no carrinho.
                </div>
              </div>
            </>
          )}
        </>
      )}
      {props.noCart && (
        <div className="cart-content__boxed">
          {props.children}
        </div>
      )}

      {isCheckoutURL() ? (
        <CheckoutFooter />
      ) : (
        !props.removeFooter && <PageFooter />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products || [],
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CartContent);


import { ChatMessageActions } from '../actions';

const initialState = {
  allChatMessages: null,
  chatMessageByID: null,
  chatUsers: null,
};

const chatMessageReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ChatMessageActions.ACTION_GET_ALL_CHAT_MESSAGE:
      state = {
        ...state,
        allChatMessages: action.payload,
      };
      return state;
    case ChatMessageActions.ACTION_GET_CHAT_MESSAGE_BY_ID:
      state = {
        ...state,
        chatMessageByID: action.payload,
      };
      return state;
    case ChatMessageActions.ACTION_GET_CHAT_USERS:
      state = {
        ...state,
        chatUsers: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default chatMessageReducer;

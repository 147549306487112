import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/i18n';
import { MedicalAppointmentStatus, MedicalAppointmentType } from '../../../enum/medicalAppointment';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { PrescriptionActions, TransactionActions } from '../../../redux/actions';
import * as QuizActions from '../../../redux/actions/quiz';
import * as MedicalAppointmentActions from '../../../redux/actions/medical-appointment';
import { getQuestionTypeColor, QuestionType } from '../../../services/prescription';
import { Button, message } from 'antd';
import { setDataLayer } from '../../../services/analytics';
import { LoadingOutlined } from '@ant-design/icons';
import * as UtilsActions from '../../../redux/actions/utils';
import * as EvolutionActions from '../../../redux/actions/evolution';
import * as ActionsCart from '../../../redux/actions/cart';
import { CartSymbol } from '../../../enum/cartSymbol';
import PrescriptionProductType from '../../../enum/prescriptionProductType';
import goBack from '../../../assets/medical/go-back.svg';
import { get, reset } from '../../../services/storage';
import { pixelTrack } from './../../../utils/pixel';
import defaultBase64 from './../../../utils/defaultBase64';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';

const PrescriptionPayment = ({
  type,
  quizUserResponse,
  setQuizUserResponse,
  setQuizUserPayload,
  createQuiz,
  base64,
  uploadImageBase64,
  createEvolution,
  me,
  userActivity,
  getTransactionMineDetails
}) => {

  const history = useHistory();
  const { pathname } = useHistory();
  const { host, protocol } = window.location;

  const [ transactionDetails, setTransactionDetails ] = useState({});
  const [ lastInteraction, setLastInteraction ] = useState(Date.now());

  const getMineDetails = async (product) => {
    const response = await getTransactionMineDetails(product);
    setTransactionDetails(response);
  };

  useEffect(() => {
    getMineDetails('tadala');
  }, []);

  useEffect(() => {
    const resetTimer = () => {
      setLastInteraction(Date.now());
    };

    const handleMouseMove = () => {
      resetTimer();
    };

    const handleKeyDown = () => {
      resetTimer();
    };

    const timeout = setTimeout(() => {
      const now = Date.now();

      if ((now - lastInteraction) >= 5000) {
        onSubmit();
      }
    }, 5000);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [ lastInteraction ]);


  const redirectToFlow = () => {
    window.location.href = `${protocol}//${host}/?fromPrescriptionPayment=true&ed=true`;
  };

  const verifyTypeForRedirectFlow = () => {
    switch (type) {
      case QuestionType.HAIR_LOSS:
        return history.push('/?fromPrescriptionPayment=true&hairLoss=true');
      case QuestionType.ED:
      case QuestionType.EJ:
        return redirectToFlow(QuestionType.ED);
      case QuestionType.MELATONINA:
        return history.push('/?fromPrescriptionPayment=true&melatonin=true');
      default:
        return history.push('/?fromPrescriptionPayment=true');
    }
  };

  const onSubmit = async () => {
    const filteredQuestions = quizUserResponse && quizUserResponse.filter(o => !o.hidden);
    const availableQuestions = filteredQuestions && filteredQuestions.map(question => question.answers.map(o => ({
      ...o,
      questionId: question.id
    })).filter(o => !o.hidden));

    const prescrptionTypeItems = [
      'sleep',
      'ed',
      'hair',
      'ej',
    ];

    pixelTrack('finishedPrescription', {
      name: me.name,
      email: me.email,
      prescriptionType: prescrptionTypeItems[type - 1]
    }, true);

    userActivity({
      source: pathname
    }, facebookUserActivity.finishMedicalAppointment);

    setDataLayer('medical_evaluation_ended');

    const answered = availableQuestions &&
      availableQuestions.map(o => o.filter(oo => oo.userAnswer)).filter(o => o && o.length > 0);
    const response = [];

    if (answered && answered.length > 0) {
      answered.forEach(question => {
        if (question && question.length > 0) {
          question.forEach(answer => {
            response.push({
              question: answer.questionId,
              type: answer.type,
              answer: answer.id,
              textResponse: answer.userAnswer,
              score: 0,
            });
          });
        }
      });
    }

    let createdEvolution;

    if (type === QuestionType.HAIR_LOSS) {

      const photo = await uploadImageBase64(base64.base64[0][0].thumbUrl);
      new Promise(resolve => setTimeout(resolve, 500));
      const photo2 = await uploadImageBase64(base64.base64[1][0].thumbUrl);
      new Promise(resolve => setTimeout(resolve, 500));
      const photo3 = await uploadImageBase64(base64.base64[2][0].thumbUrl);

      if (!photo || !photo2 || !photo3) {
        message.error('Algo deu errado ao responder o questionário, tente novamente mais tarde.');
        return history.push('/');
      }

      createdEvolution = await createEvolution({
        filePicTop: photo.bucket,
        filePicFront: photo2.bucket,
        filePicBack: photo3.bucket,
      });
    } else if (type === QuestionType.MELATONINA) {
      const photo = await uploadImageBase64(base64.base64[0].thumbUrl);

      if (!photo && !photo.bucket) {
        message.error('Algo deu errado ao responder o questionário, tente novamente mais tarde.');
        return history.push('/');
      }

      createdEvolution = await createEvolution({
        filePicTop: photo.bucket,
        filePicFront: photo.bucket,
        filePicBack: photo.bucket,
      });
    } else {
      const photo = await uploadImageBase64(defaultBase64);

      if (!photo && !photo.bucket) {
        message.error('Algo deu errado ao responder o questionário, tente novamente mais tarde.');
        return history.push('/');
      }

      createdEvolution = await createEvolution({
        filePicTop: photo.bucket,
        filePicFront: photo.bucket,
        filePicBack: photo.bucket,
      });
    }

    const payload = {
      quiz: {
        questionAndResponse: response || [],
        type,
      },
      medicalAppointment: {
        evolutionId: createdEvolution && createdEvolution.id ,
        attendBy: '',
        status: MedicalAppointmentStatus.PENDING_CONSULTATION,
        request: moment().format(),
        typeAttendance: MedicalAppointmentType.COMMON,
      }
    };

    setQuizUserPayload(payload);

    await createQuiz(payload, result => {
      if (result && result === 'quiz_already_exists') {
        verifyTypeForRedirectFlow();
      } else if (result && result.quizUser && result.quizUser.length > 0 && createdEvolution) {
        message.success(translate('SUCCESS.SUCCESS_QUIZ'));
        verifyTypeForRedirectFlow();
        setQuizUserPayload(null);
        setQuizUserResponse(null);
      } else {
        message.error(translate('ERRORS.QUIZ_ERROR'));
      }
    });
  };

  const goToInitalStep = () => {
    if (window.confirm('Deseja ir para o inicio da avaliação?')) {
      history.push('/avaliacao');
    }
  };

  return (
    <div className={'payment'}>
      <div
        className="prescription-step__top-bar"
        style={{ backgroundColor: getQuestionTypeColor(type) }}
      >
        <div
          className="prescription-step__top-bar__go-back"
          onClick={() => goToInitalStep()}
        >
          <img src={goBack}
            alt="go back"
            className="prescription-step__top-bar__go-back__img"/>
        </div>
        <div className="prescription-step__top-bar__fix-style" />
        <div
          className="prescription-step__top-bar__text"
          onClick={() => goToInitalStep()}
        >
          {type === QuestionType.MELATONINA && 'Saúde do sono'}
          {type === QuestionType.ED && 'Saúde sexual'}
          {type === QuestionType.HAIR_LOSS && 'Saúde capilar'}
        </div>
      </div>
      <div className="payment__box">
        <div className="payment__box__aux">
          <h2 className="payment__box__aux__title">
            {translate('COMPONENTS.CLINIC.TITLE_BUY')}
          </h2>
          <div className="payment__box__aux__cart">
            <h3 className="payment__box__aux__cart__title">
              {translate('COMPONENTS.CLINIC.CART.TITLE')}
            </h3>
            <div className="payment__box__aux__cart__aux">
              <p className="payment__box__aux__cart__aux__item">
                {translate('COMPONENTS.CLINIC.CART.ITEM')}
              </p>
              <p className="payment__box__aux__cart__aux__value">
                {translate('COMPONENTS.CLINIC.CART.VALUE')}
              </p>
            </div>
            <h3 className="payment__box__aux__cart__title">
              {translate('COMPONENTS.CLINIC.CART.ITEM_TWO')}
            </h3>
            <div className="payment__box__aux__cart__aux">
              <p className="payment__box__aux__cart__aux__item">
                Desconto Cliente
              </p>
              <p className="payment__box__aux__cart__aux__value--no-text-decoration">
                {translate('COMPONENTS.CLINIC.CART.VALUE_TWO')}
              </p>
            </div>
            <div className="payment__box__aux__cart__aux payment__box__aux__cart__aux--no-border">
              <p className="payment__box__aux__cart__aux__total">
                {translate('COMPONENTS.CLINIC.CART.TOTAL')}
              </p>
              <p className="payment__box__aux__cart__aux__value-total">
                R$0
              </p>
            </div>
          </div>

          <div className="container-button">
            <Button className='container-button__finished-prescription'
              onClick={onSubmit}>
              {translate('COMPONENTS.CART.FINISH_QUIZ')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  quizUserResponse: state.prescription.quizUserResponse,
  createdQuiz: state.quiz.quizUserResponse,
  loading: state.loading,
  base64: state.base64,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  setQuizUserResponse: (payload) => dispatch(PrescriptionActions.setQuizUserResponse(payload)),
  setQuizUserPayload: (payload) => dispatch(PrescriptionActions.setQuizUserPayload(payload)),
  createQuiz: (quiz, callback) => dispatch(QuizActions.createQuiz(quiz, callback)),
  createMedicalAppointment: (data) => dispatch(MedicalAppointmentActions.createMedicalAppointment(data)),
  uploadImageBase64: (photoDataBase64) => dispatch(UtilsActions.uploadImageBase64(photoDataBase64)),
  createEvolution: (data) => dispatch(EvolutionActions.createEvolution(data)),
  addProductsInCart: (idProduct) => dispatch(ActionsCart.addOrRemoveProduct(idProduct, 1, CartSymbol.ADD)),
  setFromPrescriptionPayment: (params) => dispatch(PrescriptionActions.setFromPrescriptionPayment(params)),
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
  getTransactionMineDetails: (params) => dispatch(TransactionActions.getTransactionsMineDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionPayment);
import React from 'react';
import { toCurrencyLocale } from '../../utils/masks';
import AmountPicker from '../AmountPicker/AmountPicker';
import { CloseOutlined } from '@ant-design/icons';
import { getProductById } from '../../services/product';
import PromotionCardCheckout from '../PromotionCardCheckout/PromotionCardCheckout';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import {
  getProductTheme,
  getPromotionProduct,
  getPromotionProductDiscount,
  getPromotionProductValue,
  visiblePromotion,
} from '../../utils/checkout';
import { tagPromotionPrice } from '../../utils/tagPromotionPrice';

const CheckoutItemCard = ({
  product,
  addProductToCart,
  removeProductFromCart,
  deleteProductFromCart,
  last,
  disabled,
  products,
  productsLength,
}) => {
  const { pathname } = useLocation();

  const verifyPriceProduct = () => {
    const promotionPrice = tagPromotionPrice(product.hidePriceCart);

    if (product.tagPromotion && productsLength === 1) {
      product.price = Number(product.oldValue);
      return toCurrencyLocale(product.oldValue);
    }

    return promotionPrice ?? toCurrencyLocale(product.price);
  };

  return (
    <>
      <div
        className={`checkout-item-card ${last && 'checkout-item-card--last'}`}
      >
        <div className="checkout-item-card__thumb">
          <div
            className="checkout-item-card__thumb__img"
            style={{
              backgroundImage: `url(${product.image})`,
            }}
          />
        </div>
        <div className="checkout-item-card__meta">
          <div className="checkout-item-card__meta__title">
            {getProductById(product.id).name}
          </div>
          <div className="checkout-item-card__meta__tag">{product.tag}</div>
          <div className="checkout-item-card__meta__price">
            <strong className="checkout-item-card__meta__price__final">
              {verifyPriceProduct()}
            </strong>
          </div>
          <div className="checkout-item-card__meta__actions">
            <AmountPicker
              value={product.quantity}
              onClickAdd={() => addProductToCart(product.id)}
              onClickRemove={() => removeProductFromCart(product.id)}
              disabled={product.hideQtd}
              removeShadow
            />
          </div>
        </div>
        <div className="checkout-item-card__remove">
          <button
            onClick={() => deleteProductFromCart(product.id)}
            className="transparent-button"
          >
            <CloseOutlined />
          </button>
        </div>
      </div>
      {visiblePromotion(products, product) &&
        pathname === '/checkout/revisao' && (
        <PromotionCardCheckout
          name={product.name}
          theme={getProductTheme(product.id)}
          value={getPromotionProductValue(product.id)}
          discount={getPromotionProductDiscount(product.id)}
          addProductsInCart={() =>
            addProductToCart(getPromotionProduct(product.id))
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products || [],
});

export default connect(mapStateToProps)(CheckoutItemCard);

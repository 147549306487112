const ProductList = {
  ANTI_AGING: 1,
  CHA_ENERGIZANTE: 2,
  TONICO_COM_AUXINA: 3,
  GUMMY_ZERO: 4,
  SHAMPOO_COM_HVIT: 5,
  MINOXIDIL: 6,
  MINOXIDIL_NO_PRESCRIPTION: 61,
  FINASTERIDA: 7,
  SLEEP_GUMMY: 8,
  SLEEP_GUMMY_NO_PRESCRIPTION: 81,
  COMBO_BARBUDO: 9,
  COMBO_GUMMY: 10,
  TADALAFIL: 24,
  COMBO_SLEEP_GUMMY: 26,
};

export default ProductList;
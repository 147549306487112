import QuizRequests from '../../api/quiz';
import { I18n } from 'react-redux-i18n';
import { message } from 'antd';

import { decreaseLoading, increaseLoading } from './loading';

export const ACTION_CREATE_QUIZ = 'quiz:ACTION_CREATE_QUIZ';
export const ACTION_GET_QUIZ_BY_ID = 'quiz:ACTION_GET_QUIZ_BY_ID';
export const ACTION_GET_ALL_QUIZ_WITH_PAGINATION =
  'quiz:ACTION_GET_ALL_QUIZ_WITH_PAGINATION';

export const createQuiz = (quiz, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await QuizRequests.createQuiz(quiz);
    dispatch({
      type: ACTION_CREATE_QUIZ,
      payload,
    });

    if (callback && typeof callback === 'function') {
      callback(payload);
    }

    return payload;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }

    if (callback && typeof callback === 'function') {
      callback(err && err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getQuizById = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await QuizRequests.getQuizById(id);
    dispatch({
      type: ACTION_GET_QUIZ_BY_ID,
      payload,
    });
    return payload;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAllQuizPaginated = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await QuizRequests.getAllQuizPaginated(params);
    dispatch({
      type: ACTION_GET_ALL_QUIZ_WITH_PAGINATION,
      payload,
    });
    return payload;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Arrow from '../../assets/png/arrow.png';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { takeOutBlankSpace } from '../../utils/spaceRegex';
import AdvancedForm from '../AdvancedForm/AdvancedForm';
import * as UserActions from '../../redux/actions/user';
import * as UtilsActions from '../../redux/actions/utils';
import { cpfMask, dateOfBirthMask, phoneMask } from '../../utils/masks';
import {
  emailDomains,
  removeSpecialChars,
  validaCpf,
  validateBirthDate,
  validateEmail,
} from '../../utils/string';
import * as AuthActions from '../../redux/actions/auth';
import AuthService from '../../services/auth';
import * as FacebookActions from '../../redux/actions/facebook';
import facebookUserActivity from '../../enum/facebookUserActivity';
import ProfileModals from '../../enum/profileModals';
import LoginModal from '../LoginModal/LoginModal';
import { ProfileType } from '../../enum/profileType';
import { I18n } from 'react-redux-i18n';
import { AutocompleteDropdown } from '../AutocompleteDropdown/AutocompleteDropdown';

const RegisterModal = props => {
  const { pathname } = useLocation();
  const { createUser, login, userActivity } = props;
  const [ screenType, setScreenType ] = useState(ProfileModals.REGISTER);

  const [ form, setForm ] = useState({
    email: '',
    phone: '',
    password: '',
    documentForm: '',
    firstName: '',
    lastName: '',
    grantType: 'password',
    birthDate: null,
    profileType: ProfileType.USER,
    zipcode: '',
    neighborhood: '',
    street: '',
    city: '',
    uf: '',
    addressNumber: '',
    complement: '',
    confirmpassword: '',
    reference: '',
  });

  const { email, phone, documentForm, firstName, lastName, birthDate } = form;

  const history = useHistory();

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
  });

  const handleChangeForm = (name, value) => {
    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });
  };

  const [ invalidCPF, setInvalidCPF ] = useState(false);
  const [ invalidPhone, setInvalidPhone ] = useState(false);
  const [ invalidEmail, setInvalidEmail ] = useState(false);
  const [ invalidLastName, setInvalidLastName ] = useState(false);

  const onFormSubmit = async () => {
    setInvalidCPF(false);
    setInvalidPhone(false);
    setInvalidEmail(false);
    setInvalidLastName(false);

    if (!validaCpf(removeSpecialChars(documentForm))) {
      setInvalidCPF(true);
      return message.error('CPF inválido');
    }

    if (removeSpecialChars(phone).length < 10 || phone.includes('99999-9999')) {
      setInvalidPhone(true);
      return message.error('Telefone inválido');
    }

    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return message.error('Email inválido');
    }

    if (!lastName || lastName.length < 3) {
      setInvalidLastName(true);
      return message.error(
        I18n.t('PAGES.HOME.REGISTER.FORM.MESSAGE.ERROR.INVALID_LAST_NAME'),
      );
    }

    if (
      !moment(birthDate, 'DD/MM/YYYY').isValid() ||
      !validateBirthDate(birthDate)
    ) {
      return message.error('Digite uma data de nascimento válida');
    }

    const thePayload = {
      ...form,
      name: firstName.concat(' ', lastName),
      document: removeSpecialChars(documentForm),
      password: removeSpecialChars(documentForm),
      phone: removeSpecialChars(phone),
      birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    await createUser(thePayload, async () => {
      await login(thePayload, () => {
        userActivity(
          {
            source: pathname,
          },
          facebookUserActivity.register,
        );
        history.push('/');
        props.onClose();
      });
    });
  };

  function onReturn () {
    setScreenType(ProfileModals.LOGIN);
  }

  return (
    <>
      {screenType === ProfileModals.REGISTER ? (
        <>
          <AdvancedForm onSubmit={() => onFormSubmit()}>
            <h2 className="register-modal__title">Criar Cadastro</h2>
            <p className="register-modal__secondary">
              Crie seu cadastro para poder acessar sua conta.
            </p>
            <div className="register-modal__aux">
              <div className="register-modal__aux__input">
                <input
                  id="firstName"
                  name="firstName"
                  placeholder="Nome*"
                  value={firstName}
                  onChange={e => handleChangeForm('firstName', e.target.value)}
                />
              </div>
              <div className="register-modal__aux__input">
                <input
                  id="lastName"
                  name="lastName"
                  placeholder="Sobrenome*"
                  value={lastName}
                  onChange={e => handleChangeForm('lastName', e.target.value)}
                />
              </div>
            </div>
            <div className="register-modal__aux">
              <div className="register-modal__aux__input">
                <input
                  id="cpf-invalid"
                  name="documentForm"
                  placeholder="CPF*"
                  value={documentForm}
                  onChange={e =>
                    handleChangeForm('documentForm', cpfMask(e.target.value))
                  }
                />
              </div>
              <div className="register-modal__aux__input">
                <input
                  name="birthDate"
                  value={dateOfBirthMask(birthDate)}
                  mask="99/99/9999"
                  onChange={e =>
                    handleChangeForm(
                      'birthDate',
                      dateOfBirthMask(e.target.value),
                    )
                  }
                  placeholder="Data de Nasc.*"
                />
              </div>
            </div>
            <div className="register-modal__aux">
              <div className="register-modal__aux__input-large">
                <input
                  name="phone"
                  placeholder="Seu melhor telefone*"
                  value={phone}
                  onChange={e =>
                    handleChangeForm('phone', phoneMask(e.target.value))
                  }
                />
              </div>
            </div>
            <div className="register-modal__aux">
              <div className='email_field'>
                <AutocompleteDropdown
                  minLength={5}
                  trigger='@'
                  value={email && email.toLowerCase()}
                  onChange={value => handleChangeForm('email', value)}
                  autoCompleteOptions={emailDomains}
                  inputComponent={(inputValue, onChange, onKeyDown, onFocus, onBlur) => (
                    <div className="register-modal__aux__input-large">
                      <input
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.value)}
                        name="email"
                        value={inputValue}
                        onKeyDown={onKeyDown}
                        autoComplete={email.length < 5 ? 'on' : 'off'}
                        placeholder="E-mail*"
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="register-modal__aux">
              <button type="submit"
                className="register-modal__aux__button">
                <img alt="arrow"
                  src={Arrow} />
                Cadastrar
              </button>
            </div>
            <p className="register-modal__account">Já possui uma conta?</p>
            <a className="register-modal__login"
              onClick={onReturn}>
              Acesse aqui
            </a>
          </AdvancedForm>
        </>
      ) : (
        <LoginModal />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  login: (userData, callback) =>
    dispatch(AuthActions.authenticate(userData, callback)),
  createUser: (userData, callback) =>
    dispatch(UserActions.create(userData, callback)),
  getAddressByCep: cep => dispatch(UtilsActions.getAddressByCep(cep)),
  userActivity: (params, userActivity) =>
    dispatch(FacebookActions.userActivity(params, userActivity)),
});

export default connect(null, mapDispatchToProps)(RegisterModal);

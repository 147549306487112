import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import TrackOrderScreen from './TrackOrderScreen/TrackOrderScreen';

const TrackOrderNavigationStack = () => {
  return (
    <div>
      <Switch>
        <Route path={I18n.t('PAGES.TRACK_ORDER.URL')}>
          <TrackOrderScreen />
        </Route>
      </Switch>
    </div>
  );
};

export default TrackOrderNavigationStack;

export default {
  //localhost: 'http://localhost:80/api',
  localhost: 'https://api-dev.themens.com.br/api',
  development: 'https://api-dev.themens.com.br/api',
  production: 'https://api.themens.com.br/api',

  auth: 'https://auth.themens.com.br',
  authApiKeyDev: 'c91ae09b-44c1-462a-865f-52817016285b',
  authApiKeyProd: '5cf7749f-e351-49ee-a7c4-3d8c8090cacb',
  searchCep: 'https://buscacepinter.correios.com.br/app/endereco/index.php?t',
  blogThemens: 'https://blog.themens.com.br/',

  prescriptionProd: 'https://consultoriomedico.digital',
  prescriptionDev: 'https://hml.consultoriomedico.digital',

  ecommerceProd: 'https://themens.com.br',
  ecommerceDev: 'https://dev.themens.com.br',
};

import React, { useEffect, useRef, useState } from 'react';
import ArrowNext from '../../../assets/svg/ic_arrow_next.svg';
import ArrowPrev from '../../../assets/svg/ic_arrow_prev.svg';
import { ReactComponent as CartIcon } from '../../../assets/svg/new_menu/ic_header_bag.svg';
import { connect } from 'react-redux';
import * as ActionsCart from '../../../redux/actions/cart';
import { getProductByCode } from '../../../services/product';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { keepOnlyNumbers } from '../../../utils/string';
import { toCurrencyLocale } from '../../../utils/masks';
import Slider from '@ant-design/react-slick';
import hairKitItems from '../../../services/hairKitItems';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';
import ReactHtmlParser from 'react-html-parser';
import hairKitFaq from '../../../services/hairKitFaq';
import RecomendedProducts from '../../../components/RecomendedProducts/RecomendedProducts';
import { useHistory } from 'react-router-dom';
import { translate } from '../../../services/i18n';
import { message } from 'antd';
import { validateMobile } from '../../../utils/validateMobile';

const SubscriptionMobile = (props) => {
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(0);
  const [ buttonPosition, setButtonPosition ] = useState(null);
  const [ floatingButton, setFloatingButton ] = useState(false);
  const [ cartModalOpen, setCartModalOpen ] = useState(false);
  const [ freightData, setFreightData ] = useState(null);
  const [ dropDown, setDropDown ] = useState(null);
  const [ faqDropDown, setFaqDropDown ] = useState(null);
  const [ zipcode, setZipcode ] = useState('');
  const buttonRef = useRef();
  const history = useHistory();

  const heroImages = [
    '/assets/img/hair-kit/hero/1.png',
    '/assets/img/hair-kit/hero/2.png',
    '/assets/img/hair-kit/hero/3.jpg',
  ];

  const products = [ 4, 5, 6, 7 ].map((o) => ({
    ...getProductByCode(o),
    quantity: 1,
  }));

  const contentOneSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    centerMode: true,
    centerPadding: '9%',
    arrows: false,
  };

  const contentTwoSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    centerMode: true,
    centerPadding: '9%',
    arrows: false,
  };

  useEffect(() => {
    const floatingButtonVisible = () => {
      setButtonPosition(buttonRef.current.offsetTop - document.body.scrollTop);
    };

    if (buttonPosition < 0 && floatingButton === false) {
      setFloatingButton(true);
    } else if (buttonPosition > 0 && floatingButton === true) {
      setFloatingButton(false);
    }

    document
      .querySelector('body')
      .addEventListener('scroll', floatingButtonVisible);
    return () =>
      document
        .querySelector('body')
        .removeEventListener('scroll', floatingButtonVisible);
  }, [ buttonPosition ]);

  const changeCarouselStep = (direction, indexSelected) => {
    if (direction === 'next' && selectedImageIndex < heroImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    } else if (direction === 'prev' && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    } else if (!direction && (indexSelected || indexSelected === 0)) {
      setSelectedImageIndex(indexSelected);
    }
  };

  const manageDropDown = (index, isFaq) => {
    if (isFaq) {
      if (index === faqDropDown) {
        setFaqDropDown(null);
      } else {
        setFaqDropDown(index);
      }
    } else {
      if (index === dropDown) {
        setDropDown(null);
      } else {
        setDropDown(index);
      }
    }
  };

  const addProductToCart = () => {
    if (!props.me || !props.me?.notNeedMedicalPrescription) {
      setCartModalOpen(true);
    } else {
      props.replaceCartProducts(products, true);

      if (validateMobile(window.screen.width))
        return message.success({
          content: 'Produto adicionado ao carrinho.',
          className: 'ant-custom-style',
        });
    }
  };

  const onCalculateFreight = () => {
    if (zipcode) {
      props.calculateFreight(zipcode, [ products ], (response) => {
        if (response && !response.error) {
          setFreightData(response.freight);
        } else {
          setFreightData({ error: true });
        }
      });
    }
  };

  return (
    <>
      {floatingButton && (
        <div className="product-mobile__floating-button">
          <div
            className="product-mobile__floating-button__add-to-cart"
            onClick={() => addProductToCart()}
          >
            ADICIONAR NA SACOLA &nbsp;&nbsp;
            <CartIcon />
          </div>
        </div>
      )}
      {cartModalOpen && (
        <div className="product-mobile__modal">
          <div
            onClick={() => setCartModalOpen(false)}
            className="product-mobile__modal__close"
          >
            <div className="product-mobile__modal__close__button">
              <CloseOutlined />
            </div>
            <p className="product-mobile__modal__text">
              {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.TEXT')}
              <strong>
                {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.PRESCRIPTION')}
              </strong>
              <p>
                {translate(
                  'COMPONENTS.PRODUCT.NEED_PRESCRIPTION.NO_PRESCRIPTION',
                )}
              </p>
            </p>
            <div
              onClick={() => window.open('/avaliacao', '_blank')}
              className="product-mobile__modal__confirm"
            >
              FAZER MINHA 1ª AVALIAÇÃO
            </div>
            <div
              onClick={() => {
                setCartModalOpen(false);
                props.replaceCartProducts(products, true);

                if (validateMobile(window.screen.width))
                  return message.success({
                    content: 'Produto adicionado ao carrinho.',
                    className: 'ant-custom-style',
                  });
              }}
              className="product-mobile__modal__deny"
            >
              JÁ TENHO AVALIAÇÃO
            </div>
          </div>
        </div>
      )}
      <div className="subscription-mobile">
        <div className="subscription-mobile__carousel">
          <div className="subscription-mobile__carousel__main">
            <img
              className="subscription-mobile__carousel__main__image"
              src={heroImages[selectedImageIndex]}
            />
            <span
              className="subscription-mobile__carousel__main__prev"
              onClick={() => changeCarouselStep('prev')}
            >
              <img
                className="subscription-mobile__carousel__main__prev__icon"
                src={ArrowPrev}
              />
            </span>
            <span
              className="subscription-mobile__carousel__main__next"
              onClick={() => changeCarouselStep('next')}
            >
              <img
                className="subscription-mobile__carousel__main__next__icon"
                src={ArrowNext}
              />
            </span>
          </div>
          <div className="subscription-mobile__carousel__indicator">
            {heroImages &&
              heroImages.map((item, index) => (
                <span
                  key={index}
                  className={`subscription-mobile__carousel__indicator__icon ${
                    selectedImageIndex === index &&
                    'subscription-mobile__carousel__indicator__icon--active'
                  }`}
                  onClick={() => changeCarouselStep(null, index)}
                />
              ))}
          </div>
        </div>
        <div className="subscription-mobile__buy">
          <div className="subscription-mobile__buy__title">
            <div className="subscription-mobile__buy__title__text">
              <div className="subscription-mobile__buy__title__text__bold">
                Hair Kit
              </div>
            </div>
            <div className="subscription-mobile__buy__title__rating">
              <strong>4.9</strong>/5.0
              <span className="subscription-mobile__buy__title__rating__star">
                ★
              </span>
            </div>
          </div>
          <div className="subscription-mobile__buy__pricing">
            <div className="subscription-mobile__buy__pricing__top">
              A partir de
            </div>
            <div className="subscription-mobile__buy__pricing__bottom">
              R$93,90
            </div>
          </div>
          <div className="subscription-mobile__buy__add-to-cart">
            <div
              ref={buttonRef}
              className="subscription-mobile__buy__add-to-cart__button"
              onClick={() => addProductToCart()}
            >
              ADICIONAR NA SACOLA &nbsp;&nbsp;
              <CartIcon />
            </div>
          </div>
          <div className="subscription-mobile__buy__qtd">
            <div className="subscription-mobile__buy__qtd__container">
              <img
                src="/assets/img/hair-kit/info/product-info-mobile.svg"
                alt=""
              />
            </div>
          </div>
          <div className="subscription-mobile__buy__product-text">
            Tratamento personalizado para mais cabelos. Receba seu kit em casa e
            conte com acompanhamento médico online por todo o tratamento.
          </div>
          <p className="subscription-mobile__buy__anvisa">
            Aprovado pela{' '}
            <a href="https://blog.themens.com.br/registros-aprovacoes-e-eficacia/?_gl=1*zq0gxw*_ga*MjA4MTA5[…]_ga=2.162447036.1658420718.1639610872-2081095004.1638752415">
              <u>Anvisa.</u>
            </a>
          </p>
        </div>
        <div className="product__info__right__inner__calc product__info__right__inner__calc--mobile">
          <div className="product__info__right__inner__calc__form">
            <div className="product__info__right__inner__calc__form__input">
              <input
                className={`
                      product__info__right__inner__calc__form__input__element product__info__right__inner__calc__form__input__element--mobile
                      ${
    zipcode !== '' &&
                        'product__info__right__inner__calc__form__input__element--hidden'
    }
                    `}
                type="text"
                placeholder="Digite o CEP de entrega"
                value={zipcode}
                onChange={(e) =>
                  ((e.target.value && e.target.value.length <= 8) ||
                    !e.target.value) &&
                  setZipcode(keepOnlyNumbers(e.target.value))
                }
              />
            </div>
            <div className="product__info__right__inner__calc__form__button product__info__right__inner__calc__form__button--mobile">
              <button
                className="product__info__right__inner__calc__form__button__inner product__info__right__inner__calc__form__button__inner--mobile"
                onClick={() => onCalculateFreight()}
                disabled={zipcode?.length < 8}
              >
                <ArrowRightOutlined />
              </button>
            </div>
            <div className="product__info__right__inner__calc__form__container__text-shipping">
              <p>{translate('COMPONENTS.PRODUCT.SHIPPING.DESCRIPTION')}</p>
            </div>
            {freightData && !freightData.error && (
              <div className="product__info__right__inner__calc__form__freight-success">
                <div className="product__info__right__inner__calc__form__freight-success__city">
                  Região: {freightData.city}
                </div>
                <div className="product__info__right__inner__calc__form__freight-success__data">
                  Normal - {Number(freightData.deadline)} dias úteis -{' '}
                  <strong>{toCurrencyLocale(freightData.freight)}</strong>
                </div>
              </div>
            )}
            {freightData && freightData.error && (
              <div>
                <div className="product__info__right__inner__calc__form__freight-error">
                  Querido The Lovers, ainda não operamos na sua região, mas
                  queremos chegar ai! Acreditamos que todos os brasileiros devam
                  se cuidar de forma prática e segura.
                  <br />
                  Estamos trabalhando duro para atingirmos todo o Brasil até o
                  final do ano.
                </div>
                <br />
                <div className="product__info__right__inner__calc__form__freight-normal">
                  {/*eslint-disable-next-line react/no-unescaped-entities*/}
                  Caso queira ser um dos primeiros a se cuidar com the men's na
                  sua região, entre agora na nossa lista exclusiva:&nbsp;
                  <a href="mailto:memanda@themens.com.br">
                    memanda@themens.com.br
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="subscription-mobile__content-one">
          <h3 className="subscription-mobile__content-one__title">
            Menos etapas, resultados mais rápidos
          </h3>
          <p className="subscription-mobile__content-one__subtitle">
            {/*eslint-disable-next-line react/no-unescaped-entities*/}
            A The Men's lida com tudo, desde a avaliação online gratuita até a
            entrega do tratamento no conforto da sua casa e atendimento contínuo
            gratuito.
          </p>
          <div className="subscription-mobile__content-one__slider">
            <Slider {...contentOneSettings}>
              <div className="subscription-mobile__content-one__slider__item">
                <img
                  src="/assets/img/hair-kit/signature-page/mobile-1.png"
                  className="subscription-mobile__content-one__slider__item__img"
                />
                <img
                  src="/assets/img/hair-kit/signature-page/1.svg"
                  className="subscription-mobile__content-one__slider__item__img"
                />
              </div>
              <div className="subscription-mobile__content-one__slider__item">
                <img
                  src="/assets/img/hair-kit/signature-page/mobile-2.png"
                  className="subscription-mobile__content-one__slider__item__img"
                />
                <img
                  src="/assets/img/hair-kit/signature-page/2.svg"
                  className="subscription-mobile__content-one__slider__item__img"
                />
              </div>
              <div className="subscription-mobile__content-one__slider__item">
                <img
                  src="/assets/img/hair-kit/signature-page/mobile-3.png"
                  className="subscription-mobile__content-one__slider__item__img"
                />
                <img
                  src="/assets/img/hair-kit/signature-page/3.svg"
                  className="subscription-mobile__content-one__slider__item__img"
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className="subscription-mobile__content-two">
          <h3 className="subscription-mobile__content-two__title">
            Antes & depois
          </h3>
          <p className="subscription-mobile__content-two__subtitle">
            Fotos de membros The Men’s tiradas após o hair kit
          </p>
          <div className="subscription-mobile__content-two__slider">
            <Slider {...contentTwoSettings}>
              <div className="subscription-mobile__content-two__slider__item">
                <img src="/assets/img/hair-kit/treatment/1.png"
                  alt="" />
                <p>3 meses de tratamento</p>
              </div>
              <div className="subscription-mobile__content-two__slider__item">
                <img src="/assets/img/hair-kit/treatment/4.jpg"
                  alt="" />
                <p>4 meses de tratamento</p>
              </div>
              <div className="subscription-mobile__content-two__slider__item">
                <img src="/assets/img/hair-kit/treatment/5.png"
                  alt="" />
                <p>4 meses de tratamento</p>
              </div>
              <div className="subscription-mobile__content-two__slider__item">
                <img src="/assets/img/hair-kit/treatment/6.png"
                  alt="" />
                <p>3 meses de tratamento</p>
              </div>
              <div className="subscription-mobile__content-two__slider__item">
                <img src="/assets/img/hair-kit/treatment/7.png"
                  alt="" />
                <p>2 meses de tratamento</p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="subscription-mobile__content-three">
          <h3 className="subscription-mobile__content-three__title">
            O quarteto fantástico
          </h3>
          <p className="subscription-mobile__content-three__subtitle">
            Conheça os 4 produtos que compõem o hair kit completo
          </p>
        </div>
        <div className="subscription-mobile__content-three__dropdown">
          {hairKitItems &&
            hairKitItems.map((item, index) => (
              <>
                <div className="subscription-mobile__content-three__dropdown__item">
                  <div className="subscription-mobile__content-three__dropdown__item__top">
                    <h4 className="subscription-mobile__content-three__dropdown__item__top__title">
                      {ReactHtmlParser(item.title)}
                    </h4>
                    <div
                      onClick={() => manageDropDown(index)}
                      className={`subscription-mobile__content-three__dropdown__item__top__arrow ${
                        dropDown === index &&
                        'subscription-mobile__content-three__dropdown__item__top__arrow--active'
                      }`}
                    >
                      <ArrowDown />
                    </div>
                  </div>
                  {dropDown === index && (
                    <div className="subscription-mobile__content-three__dropdown__item__bottom">
                      <img
                        className="subscription-mobile__content-three__dropdown__item__bottom__img"
                        src={item.img}
                      />
                      <p className="subscription-mobile__content-three__dropdown__item__bottom__text">
                        {' '}
                        {ReactHtmlParser(item.text)}{' '}
                      </p>
                    </div>
                  )}
                </div>
              </>
            ))}
        </div>
        <div className="subscription-mobile__content-four">
          <p className="subscription-mobile__content-four__subtitle">
            Mais comprometimento, mais cuidados.
          </p>
          <h3 className="subscription-mobile__content-four__title">
            O que esperar do tratamento
          </h3>
          <div className="subscription-mobile__content-four__slider">
            <Slider {...contentTwoSettings}>
              <div className="subscription-mobile__content-four__slider__item">
                <img src="/assets/img/hair-kit/info/1.png" />
              </div>
              <div className="subscription-mobile__content-four__slider__item">
                <img src="/assets/img/hair-kit/info/2.png" />
              </div>
              <div className="subscription-mobile__content-four__slider__item">
                <img src="/assets/img/hair-kit/info/3.png" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="subscription-mobile__content-five">
          <h3 className="subscription-mobile__content-five__title">
            Depoimentos
          </h3>
          <div className="subscription-mobile__content-five__slider">
            <Slider {...contentTwoSettings}>
              <div className="subscription-mobile__content-five__slider__item">
                <div className="subscription-mobile__content-five__slider__item__stars">
                  ★★★★★
                </div>
                <div className="subscription-mobile__content-five__slider__item__text">
                  Eu já estava me conformando que teria que raspar e resolvi dar
                  uma última chance. Me comprometi a seguir o tratamento e estou
                  muito feliz com os resultados!
                </div>
                <div className="subscription-mobile__content-five__slider__item__name">
                  Guilherme C. - São Paulo
                </div>
              </div>
              <div className="subscription-mobile__content-five__slider__item">
                <div className="subscription-mobile__content-five__slider__item__stars">
                  ★★★★★
                </div>
                <div className="subscription-mobile__content-five__slider__item__text">
                  Posso dizer que já usei outros Minoxidil, mas essa foi a
                  primeira vez que vi resultados de verdade!
                </div>
                <div className="subscription-mobile__content-five__slider__item__name">
                  Danillo O. - São Paulo
                </div>
              </div>
              <div className="subscription-mobile__content-five__slider__item">
                <div className="subscription-mobile__content-five__slider__item__stars">
                  ★★★★☆
                </div>
                <div className="subscription-mobile__content-five__slider__item__text">
                  Não achava que funcionava, mas o respaudo médico me deu mais
                  segurança. Atendimento exelente!!!
                </div>
                <div className="subscription-mobile__content-five__slider__item__name">
                  Marcelo B. - Salvador
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="subscription-mobile__content-six">
          <h3 className="subscription-mobile__content-six__title">
            Sobre queda de cabelo
          </h3>
          <div className="subscription-mobile__content-six__slider">
            <Slider {...contentTwoSettings}>
              <div className="subscription-mobile__content-six__slider__item">
                <a href="https://blog.themens.com.br/calvicie-masculina-causa-sintomas-e-possiveis-tratamentos/">
                  <img src="/assets/img/hair-kit/hair-loss/1.png" />
                  <p>Tratamentos para a calvície masculina: Conheça todos</p>
                  <a href="https://blog.themens.com.br/calvicie-masculina-causa-sintomas-e-possiveis-tratamentos/">
                    Ler este artigo
                  </a>
                </a>
              </div>
              <div className="subscription-mobile__content-six__slider__item">
                <a href="https://blog.themens.com.br/minoxidil-e-o-efeito-shedding-uma-explicacao-cientifica/">
                  <img src="/assets/img/hair-kit/hair-loss/2.png" />
                  <p>
                    Minoxidil e o efeito shedding: uma explicação científica!
                  </p>
                  <a href="https://blog.themens.com.br/minoxidil-e-o-efeito-shedding-uma-explicacao-cientifica/">
                    Ler este artigo
                  </a>
                </a>
              </div>
              <div className="subscription-mobile__content-six__slider__item">
                <a href="https://blog.themens.com.br/guia-da-finasterida-o-que-voce-precisa-saber/">
                  <img src="/assets/img/hair-kit/hair-loss/3.png" />
                  <p>
                    Fases da calvície: Saiba quais são e o que é a Escala de
                    Nordwood
                  </p>
                  <a href="https://blog.themens.com.br/guia-da-finasterida-o-que-voce-precisa-saber/">
                    Ler este artigo
                  </a>
                </a>
              </div>
            </Slider>
          </div>
        </div>
        <div className="subscription-mobile__faq">
          <h3 className="subscription-mobile__faq__title">
            Perguntas frequentes
          </h3>
          <div className="subscription-mobile__faq__dropdown">
            {hairKitFaq &&
              hairKitFaq.map((item, index) => (
                <>
                  <div className="subscription-mobile__faq__dropdown__item">
                    <div className="subscription-mobile__faq__dropdown__item__top">
                      <p className="subscription-mobile__faq__dropdown__item__top__title">
                        {ReactHtmlParser(item.title)}
                      </p>
                      <div
                        onClick={() => manageDropDown(index, true)}
                        className={`subscription-mobile__faq__dropdown__item__top__arrow ${
                          faqDropDown === index &&
                          'subscription-mobile__faq__dropdown__item__top__arrow--active'
                        }`}
                      >
                        <ArrowDown />
                      </div>
                    </div>
                    {faqDropDown === index && (
                      <div className="subscription-mobile__faq__dropdown__item__bottom">
                        {ReactHtmlParser(item.description)}
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="subscription-mobile__phrase">
          <img
            className="subscription-mobile__phrase__img"
            src="/assets/img/products-images/the-mens-delivery-phrase-mobile.png"
          />
        </div>
        <div className="subscription-mobile__recomended">
          <RecomendedProducts mobile />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quantById: state.product.quantById,
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  replaceCartProducts: (products, recurrent) =>
    dispatch(ActionsCart.replaceCartProducts(products, recurrent)),
  calculateFreight: (zipcode, products, callback) =>
    dispatch(
      ActionsCart.calculateFreight(
        {
          products,
          calculateFreight: true,
          zipcode,
        },
        callback,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionMobile);

import React from 'react';
import CreditCards from '../../assets/png/credi_cards.png';
import WhiteHouse from '../../assets/png/white_house.png';
import { translate } from '../../services/i18n';
import Guarantee from '../../assets/png/guarantee.png';
import WhatsappHelpInfo from '../WhatsappHelpInfo/WhatsappHelpInfo';
import { LINK_TYPES__CHECKOUT_MAP } from '../../enum/footer';

const CheckoutFooterMobile = () => {

  return (
    <>
      <div className="checkout__footer__mobile__top mobile-visible">
        <div className="checkout__footer__mobile__top__guarantee">
          <img src={Guarantee}
            className="checkout__footer__mobile__top__guarantee__icon"
            alt="guarantee" />
        </div>
        <p className="checkout__footer__mobile__top__guarantee__text">{translate('CHECKOUT.FOOTER.GUARANTEE')}</p>
        <WhatsappHelpInfo />
      </div>
      <div className="checkout__footer__mobile__bottom mobile-visible">
        <div className="checkout__footer__mobile__bottom__address">
          <img src={WhiteHouse}
            className="checkout__footer__bottom__mobile__address__icon"
            alt="address" />
          <div className="checkout__footer__mobile__bottom__address__text">
            {translate('CHECKOUT.FOOTER.ADDRESS')}
          </div>
        </div>
        <div className="checkout__footer__mobile__bottom__payment">
          <h3 className="checkout__footer__mobile__bottom__payment__title">
            {translate('PAGES.FOOTER.SOCIALS.TITLE_PAYMENT')}
          </h3>
          <div>
            <img
              className="checkout__footer__mobile__bottom__payment__cards"
              src={CreditCards}
              alt=""
            />
          </div>
        </div>
        <div className="checkout__footer__mobile__bottom__terms__items">
          {Object.values(translate('PAGES.FOOTER.LINKS.FOOTER_ITEMS')).map((item, itemKey) => (
            <div key={itemKey}
              className="checkout__footer__bottom__terms__items">
              {Object.keys(LINK_TYPES__CHECKOUT_MAP).includes(item.NAME) &&
                <a
                  href={item.LINK}
                  rel="noopener noreferrer"
                  className="checkout__footer__mobile__bottom__terms__link"
                  target='_blank'
                >
                  <p>{item.NAME}</p>
                </a>
              }
            </div>
          ))}
        </div>
        <div className="checkout__footer__mobile__bottom__info">
          <p className="checkout__footer__mobile__bottom__info__text">
            {translate('PAGES.FOOTER.RODAPE.TEXT')}
          </p>
        </div>
        <div className="checkout__footer__mobile__bottom__rights">
          <p className="checkout__footer__mobile__bottom__rights__text">
            {translate('PAGES.FOOTER.RODAPE.RIGHTS')}
          </p>
        </div>
      </div>
    </>
  );
};

export default CheckoutFooterMobile;

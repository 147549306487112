import React, { useRef, useState } from 'react';
import Arrow from '../../assets/png/arrow.png';
import { cpfMask } from '../../utils/masks';
import { AuthActions, LoginModalActions } from '../../../app/redux/actions';
import { connect } from 'react-redux';
import {
  emailDomains,
  keepOnlyNumbers,
  validaCpf,
  validateEmail,
} from '../../utils/string';
import { takeOutBlankSpace } from '../../utils/spaceRegex';
import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ProfileModals from '../../enum/profileModals';
import RegisterModal from '../RegisterModal/RegisterModal';
import { AutocompleteDropdown } from '../AutocompleteDropdown/AutocompleteDropdown';

const LoginModal = props => {
  const containerRef = useRef();

  const [ screenType, setScreenType ] = useState(ProfileModals.LOGIN);
  const [ form, setForm ] = useState({ email: '', cpf: '' });

  const { email, cpf } = form;

  const checkClickOutSide = e => {
    if (e.target === containerRef.current) {
      setScreenType(ProfileModals.LOGIN);
      return props.onClose();
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!form.email || !validateEmail(form.email))
      return message.error('Informe um e-mail válido.');

    if (!form.cpf || !validaCpf(keepOnlyNumbers(form.cpf)))
      return message.error('Informe um CPF válido.');

    const payload = {
      email: form.email.toLowerCase(),
      password: keepOnlyNumbers(form.cpf),
      document: keepOnlyNumbers(form.cpf),
      grantType: 'password',
    };

    await props.login(payload, loginResponse => {
      if (!loginResponse.error) {
        props.onClose();
      } else {
        message.error(
          'Os dados informados não são válidos. Verifique e tente novamente.',
        );
      }
    });
  };

  const handleInput = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  function close () {
    setScreenType(ProfileModals.LOGIN);
    return props.onClose();
  }

  function closeModal () {
    return props.onClose();
  }

  function onRedirect () {
    setScreenType(ProfileModals.REGISTER);
  }

  return (
    <div
      onClick={e => checkClickOutSide(e)}
      className={`login-modal ${props.isVisible && 'login-modal--visible'}`}
      ref={containerRef}
    >
      <div className="login-modal__inner">
        <div className="login-modal__inner__container">
          <button
            className="login-modal__inner__container__buttonClose"
            onClick={close}
          >
            <CloseOutlined />
          </button>
          {screenType === ProfileModals.LOGIN ? (
            <>
              <h2 className="login-modal__inner__container__title">
                Identificação
              </h2>
              <p className="login-modal__inner__container__welcome">
                Bem-vindo de volta! Insira seu e-mail cadastrado e seu cpf para
                acessar
              </p>
              <form className="login-modal__inner__container__form">
                <label>
                  <p className="login-modal__inner__container__form__label">
                    E-mail
                  </p>
                  <AutocompleteDropdown
                    minLength={5}
                    trigger='@'
                    value={takeOutBlankSpace(email)}
                    onChange={value => handleInput(value, 'email')}
                    autoCompleteOptions={emailDomains}
                    inputComponent={(inputValue, onChange, onKeyDown, onFocus, onBlur) => (
                      <input
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.value)}
                        name="email"
                        className="login-modal__inner__container__form__label__input"
                        value={inputValue}
                        onKeyDown={onKeyDown}
                        autoComplete={takeOutBlankSpace(email).length < 5 ? 'on' : 'off'}
                      ></input>
                    )}
                  />
                </label>
                <label>
                  <p className="login-modal__inner__container__form__label">
                    CPF
                  </p>
                  <input
                    name="cpf"
                    className="login-modal__inner__container__form__label__input"
                    value={cpfMask(cpf)}
                    onChange={e => handleInput(e.target.value, 'cpf')}
                  ></input>
                </label>
                <button
                  type="submit"
                  className="login-modal__inner__container__form__button"
                  onClick={handleSubmit}
                >
                  Conectar
                  <img alt="arrow"
                    src={Arrow} />
                </button>
              </form>
              <p className="login-modal__inner__container__account">
                Ainda não tem uma conta?
              </p>
              <a
                className="login-modal__inner__container__register"
                onClick={onRedirect}
              >
                Cadastre-se aqui
              </a>
            </>
          ) : (
            <RegisterModal onClose={() => closeModal()} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isVisible: state.login,
});

const mapDispatchToProps = dispatch => ({
  login: (data, callback) =>
    dispatch(AuthActions.authenticateV2(data, callback)),
  onClose: () => dispatch(LoginModalActions.removeLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

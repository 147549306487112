import React from 'react';
import { Steps } from 'antd';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

const { Step } = Steps;

const TrackerOrderSteps = ({ orderDetails }) => {
  const returnTitle = (orderDetail) => {
    if (orderDetail) {
      return `${orderDetail.descricao} - ${moment(orderDetail.dtHrCriado).format('DD/MM/YYYY')} - ${orderDetail.unidade.tipo} - ${orderDetail.unidade.endereco.cidade}/${orderDetail.unidade.endereco.uf}`;
    } else {
      I18n.t('PAGES.TRACK_ORDER.OBJECT_NOT_POSTED');
    }
  };

  return (
    <>
      {orderDetails.length ? (
        <Steps direction="vertical"
          progressDot>
          {orderDetails && orderDetails.map((order, i) => (
            <Step
              key={i}
              title={returnTitle(order)}
              description={order.detalhe || ''}
            />
          ))}
        </Steps>

      ) : (
        <Steps progressDot
          direction="vertical">
          <Step
            title={I18n.t('PAGES.TRACK_ORDER.OBJECT_NOT_POSTED')}
            description={I18n.t('PAGES.TRACK_ORDER.PLEASE_WAIT_UPDATES')}
          />
        </Steps>
      )}
    </>
  );
};

export default TrackerOrderSteps;
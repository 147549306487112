export const ACTION_INCREASE_LOADING = 'loading:ACTION_INCREASE_LOADING';
export const ACTION_DESCREASE_LOADING = 'loading:ACTION_DESCREASE_LOADING';

export const increaseLoading = () => ({
  type: ACTION_INCREASE_LOADING,
});

export const decreaseLoading = () => ({
  type: ACTION_DESCREASE_LOADING,
});

export const addLoading = () => (dispatch) => dispatch(increaseLoading());

export const removeLoading = () => (dispatch) => dispatch(decreaseLoading());

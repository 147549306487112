import Axios from 'axios';
import { message } from 'antd';

import AuthService from '../services/auth';
import ApiUtil, { handleAxiosError } from '../services/api';
import AuthApi from '../api/auth';
import { store } from '../../index';
import { ACTION_DELETE_ALL_PRODUCTS_IN_CART } from '../redux/actions/cart';

const handler = {
  unauthorizedError: async () => {

    try {
      store.dispatch({
        type: ACTION_DELETE_ALL_PRODUCTS_IN_CART,
      });
    } catch (e) {
      //
    }

    if (AuthService.isAuthenticated()) {
      try {
        const { refreshToken } = AuthService.get();
        const newData = await AuthApi.refresh(refreshToken);
        AuthService.create(newData);
        window.location.reload();
      } catch (e) {
        message.error('Sessão expirada, entre novamente com sua conta. Redirecionando...');
        AuthService.reset('auth');
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
    } else {
      message.error('Sessão expirada, entre novamente com sua conta. Redirecionando...');
      AuthService.reset('auth');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  },
};

export const setHandleUnauthorizedError = (fn) => {
  handler.unauthorizedError = fn;
};

export function getInstance (isAuth = false) {

  const auth = AuthService.get();

  const axiosInstance = Axios.create({
    baseURL: isAuth ? ApiUtil.getBaseUrlAuth() : ApiUtil.getBaseUrl(),
    timeout: 35000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth && auth.token ? `Bearer ${auth.token}` : undefined,
      'x-api-key': ApiUtil.getAuthToken(),
    },
  });

  axiosInstance.interceptors.response.use((response) => {
    return response;
  },
  async (err) => {
    if (err.response.status === 401) {
      await handler.unauthorizedError(err);
    }

    return Promise.reject(handleAxiosError(err));
  });

  return axiosInstance;
}

export default getInstance;

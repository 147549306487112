import React, { useEffect, useState } from 'react';
import AdvancedForm from '../../../../components/AdvancedForm/AdvancedForm';
import { connect } from 'react-redux';
import AuthService from '../../../../services/auth';
import { message } from 'antd';


import { takeOutBlankSpace } from '../../../../utils/spaceRegex';
import * as UserActions from '../../../../redux/actions/user';
import * as UtilsActions from '../../../../redux/actions/utils';
import * as AuthActions from '../../../../redux/actions/auth';
import { addressNumberMask, cepMask, stateMask } from '../../../../utils/masks';
import { removeSpecialChars } from '../../../../utils/string';
import CollapseAddress from './CollapseAddress';
import { useHistory } from 'react-router';
import { ProfileType } from '../../../../enum/profileType';
import { ProfileAddress } from '../../../../enum/profileForm';


const CollapseEdit = ({
  getMe,
  updateUser,
  loading,
  me,
  getAddressByCep,
}) => {
  const [ form, setForm ] = useState({
    grantType: '',
    profileType: ProfileType.USER,
    zipcode: '',
    neighborhood: '',
    street: '',
    city: '',
    uf: '',
    addressNumber: '',
    complement: '',
  });

  useEffect(async () => {
    AuthService.isFromEcommerceDomain();
    await getMe();
  }, []);

  const [ profileScreen, setProfileScreen ] = useState(ProfileAddress.EDIT_ADDRESS);
  const history = useHistory();

  const {
    zipcode,
    neighborhood,
    street,
    city,
    uf,
    addressNumber,
    complement,
  } = form;

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        grantType: me.grantType,
        profileType: me.profileType,
        zipcode: me.zipcode,
        neighborhood: me.neighborhood,
        street: me.street,
        city: me.city,
        uf: me.uf,
        addressNumber: me.addressNumber,
        complement: me.complement,
      });
    }
  }, [ me ]);

  const onFormSubmit = async (e) => {
    if (e) e.preventDefault();

    await updateUser(me && me.id, {
      ...form,
      zipcode: removeSpecialChars(form.zipcode),
      neighborhood: form.neighborhood,
      street: form.street,
      city: form.city,
      addressNumber: form.addressNumber,
      uf: form.uf,
      complement: form.complement,
    }, async (response) => {
      if (response) {
        await getMe();
        message.success('Usuário atualizado com sucesso.');
        history.push(setProfileScreen(ProfileAddress.REGISTER_ADDRESS));
      }
    });
  };

  const handleChangeForm = (name, value) => {

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });
  };

  const fieldChange = (name, value) => {
    form[name] = value;
    setForm({ ...form, form });
  };

  const getAddressByCeps = async (cep) => {
    const completeAddress = await getAddressByCep(cep);

    if (!completeAddress.erro) {
      fieldChange('uf', completeAddress.uf);
      fieldChange('city', completeAddress.localidade);
      fieldChange('street', completeAddress.logradouro);
      fieldChange('neighborhood', completeAddress.bairro);
    }
  };

  function onReturn () {
    setProfileScreen(ProfileAddress.REGISTER_ADDRESS);
  }

  return (
    <>
      {profileScreen === ProfileAddress.EDIT_ADDRESS ? (
        <>
          <AdvancedForm onSubmit={() => onFormSubmit()}>
            <div className="edit-address">
              <div className="edit-address__aux">
                <div className="edit-address__aux__input-large">
                  <input
                    name="street"
                    value={street}
                    onChange={(e) => handleChangeForm('street', e.target.value)}
                    placeholder='Rua'
                  />
                </div>
              </div>
              <div className="edit-address__aux">
                <div className="edit-address__aux__address">
                  <input
                    name="addressNumber"
                    value={addressNumber}
                    onChange={(e) => handleChangeForm('addressNumber', addressNumberMask(e.target.value))}
                    placeholder='Nº'
                  />
                </div>
                <div className="edit-address__aux__address__input">
                  <input
                    name="neighborhood"
                    value={neighborhood}
                    onChange={(e) => handleChangeForm('neighborhood', e.target.value)}
                    placeholder='Bairro'
                  />
                </div>
              </div>
              <div className="edit-address__aux">
                <div className="edit-address__aux__input">
                  <input
                    name="city"
                    value={city}
                    onChange={(e) => handleChangeForm('city', e.target.value)}
                    placeholder='Cidade'
                  />
                </div>
                <div className="edit-address__aux__input">
                  <input
                    name="uf"
                    value={uf}
                    onChange={(e) => handleChangeForm('uf', stateMask(e.target.value.toUpperCase()))}
                    placeholder='Estado'
                  />
                </div>
              </div>
              <div className="edit-address__aux">
                <div className="edit-address__aux__input">
                  <input
                    name="zipcode"
                    value={cepMask(zipcode)}
                    onChange={(val) => {
                      fieldChange('zipcode', val.target.value);

                      if (val && val.target && val.target.value && removeSpecialChars(val.target.value).length === 8) {
                        getAddressByCeps(removeSpecialChars(val.target.value));
                      }
                    }}
                    placeholder='CEP'
                  />
                </div>
                <div className="edit-address__aux__input">
                  <input
                    name="complement"
                    value={complement}
                    onChange={(e) => handleChangeForm('complement', e.target.value)}
                    placeholder='Complemento'
                  />
                </div>
              </div>
              <div className="edit-address__aux">
                <button
                  type="submit"
                  className="edit-address__aux__button"
                >
                  Salvar
                </button>
              </div>
              <div className="edit-address__return">
                <a className="edit-address__cancel"
                  onClick={onReturn}>Cancelar</a>
              </div>
            </div>
          </AdvancedForm>
        </>
      ) : (<CollapseAddress />)}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseEdit);
import React, { useEffect, useState } from 'react';
import { toCurrencyLocale } from '../../utils/masks';
import { getProductById } from '../../services/product';
import { connect } from 'react-redux';
import WhatsappIconCart from '../../assets/svg/whatsapp_cart_icon.svg';
import CartIcon from '../../assets/svg/cart_icon.svg';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { Link } from 'react-router-dom';
import PromotionCardCheckoutMobile from '../PromotionCardCheckout/PromotionCardCheckoutMobile';
import { getProductTheme, visiblePromotion } from '../../utils/checkout';
import { I18n } from 'react-redux-i18n';

const CheckoutItemCardMobile = ({ product, last, values, products }) => {
  const [ productQuantity, setProductQuantity ] = useState(0);

  useEffect(() => {
    if (products) {
      setProductQuantity(products.reduce((a, b) => a + b.quantity, 0));
    } else {
      setProductQuantity(0);
    }

  }, [ products ]);
  return (
    <>
      <div className={`checkout-item-card-mobile ${last && 'checkout-item-card-mobile--last'}`}>
        <div className="checkout-item-card-mobile__thumb">
          {last && (
            <div className="checkout-item-card-mobile__quantity">
              {(productQuantity !== 0 && productQuantity < 10) ? `0${productQuantity}` : productQuantity}
            </div>
          )}
          <div
            className="checkout-item-card-mobile__thumb__img"
            style={{
              backgroundImage: `url(${product.image})`,
            }} />
        </div>
        <div className="checkout-item-card-mobile__item">
          <div className="checkout-item-card-mobile__meta">
            <div className="checkout-item-card-mobile__meta__title">
              {product.quantity}{' '}{getProductById(product.id).name}
            </div>
            <div className="checkout-item-card-mobile__meta__tag">
              {product.tagPromotion}
            </div>
            {last && (
              <div className="checkout-item-card-mobile__meta__price">
                <strong className="checkout-item-card-mobile__meta__price__final">
                  {I18n.t('CHECKOUT.STEP_ONE.TOTAL')} &nbsp;{toCurrencyLocale(values.total)}
                </strong>
              </div>
            )}
          </div>
          {last && (
            <div className="checkout-item-card-mobile__meta__button">
              <div>
                <a
                  className="footer__bottom__links__aux__talk__icons--last"
                  href="https://api.whatsapp.com/send?phone=5511942120797"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <ButtonIcon
                    className="checkout-item-card-mobile__meta__button__whats"
                    icon={WhatsappIconCart} />
                </a>
              </div>
              <div>
                <Link to="/checkout/revisao">
                  <ButtonIcon
                    className="checkout-item-card-mobile__meta__button__cart"
                    icon={CartIcon} />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {visiblePromotion(products, product) && (
        <PromotionCardCheckoutMobile
          theme={getProductTheme(product.id)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.cart.products || [],
  values: state.cart.values || null,
});

export default connect(mapStateToProps)(CheckoutItemCardMobile);

import { ProductModalActions } from '../actions';

export default (
  state = false,
  action,
) => {
  switch (action.type) {
    case ProductModalActions.ACTION_VISIBLE_MODAL:
      state = true;
      return state;
    case ProductModalActions.ACTION_INVISIBLE_MODAL:
      state = false;
      return state;
    default:
      return state;
  }
};
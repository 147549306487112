import getInstance from './instance';

export default class CouponRequests {
  static async getCouponByTitle (couponTitle, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/coupon/${couponTitle}/title`);
    return data;
  }

  static async getCouponById (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/coupon/${id}`, { params });
    return data;
  }

  static async createCoupon (couponData) {
    const instance = await getInstance();
    const { data } = await instance.post('/coupon', couponData);
    return data;
  }

  static async updateCoupon (id, couponData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/coupon/${id}`, couponData);
    return data;
  }

  static async getAllCouponPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/coupon', { params });
    return data;
  }

  static async deleteCoupon (id, params) {
    const instance = await getInstance();
    const { data } = await instance.delete('/coupon', { params });
    return data;
  }

}

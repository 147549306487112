import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { ReactComponent as ProfileIconMobile } from '../../assets/svg/new_menu/ic_profile_mobile.svg';
import { ReactComponent as DoctorRequested } from '../../assets/svg/new_menu/ic_doctor_requested_mobile.svg';
import { CloseOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

const MobileHeaderMenu = (props) => (
  <div className="mobile-header-menu">
    <Menu>
      <Menu.Item className="mobile-header-menu__lista">
        <div className="mobile-header-menu__container">
          <div className="mobile-header-menu__container__top">
            <ProfileIconMobile className="mobile-header-menu__container__top__icon" />
            <div className="mobile-header-menu__container__top__text">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__top__text__link"
                to={props.isAuthenticated ? '/perfil/registro' : '/login'}>
                {props.isAuthenticated && props.me ? props.me.name.split(' ')[0] : 'Login'}
              </Link>
            </div>
            <div className="mobile-header-menu__container__top__close">
              <CloseOutlined
                onClick={props.onClick}
                className="mobile-header-menu__container__top__close__icon"
              />
            </div>
          </div>
        </div>
        <div className="mobile-header-menu__container--last">
          <div className="mobile-header-menu__container__row">
            <div className="mobile-header-menu__container__row__title">
              CABELO
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/assinatura"
              >
                <strong>Hair Kit</strong> <DoctorRequested />
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/gummy"
              >
                <strong>Vita </strong> gummy
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/shampoo-energizante"
              >
                <strong>Shampoo</strong> energizante
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text mobile-header-menu__container__row__link__text--consultorio"
                to='/avaliacao'
              >
                <strong>Consultório médico</strong> <DoctorRequested />
              </Link>
            </div>
          </div>
          <div className="mobile-header-menu__container__row">
            <div className="mobile-header-menu__container__row__title">
              BARBA
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/tonico-para-barba"
              >
                <strong>Tônico</strong> para barba
              </Link>
            </div>
          </div>
          <div className="mobile-header-menu__container__row">
            <div className="mobile-header-menu__container__row__title">
              PELE
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/anti-aging"
              >
                <strong>Anti-aging</strong> 10% nano VIT C
              </Link>
            </div>
          </div>
          <div className="mobile-header-menu__container__row">
            <div className="mobile-header-menu__container__row__title">
              SONO
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/9847293873423"
              >
                <strong>Sleep </strong>gummy <DoctorRequested />
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/produtos/chocosono"
              >
                <strong>{I18n.t('PRODUCTS.CHOCOSONO.TITLE')}</strong>
              </Link>
            </div>
          </div>
          <div className="mobile-header-menu__container__row">
            <div className="mobile-header-menu__container__row__title">
              INSTITUCIONAL
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/quem-somos"
              >
                Quem somos
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <a
                className="mobile-header-menu__container__row__link__text"
                href="https://blog.themens.com.br/?_gl=1%2A1qft51%2A_ga%2AMTgzNjcxOTg0Mi4xNjI1NDk4MjMy%2A_ga_F39QNV76HC%2AMTYyNjE4NDQ1NS4yLjEuMTYyNjE4NTE4Ny42MA.."
              >
                Blog
              </a>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <Link
                onClick={props.onClick}
                className="mobile-header-menu__container__row__link__text"
                to="/podemos-ajudar"
              >
                Podemos ajudar?
              </Link>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <a
                className="mobile-header-menu__container__row__link__text"
                href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf"
              >
                Política de troca e devoluções
              </a>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <a
                className="mobile-header-menu__container__row__link__text"
                href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf"
              >
                Termos e condições
              </a>
            </div>
            <div className="mobile-header-menu__container__row__link">
              <a
                className="mobile-header-menu__container__row__link__text"
                href="https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf"
              >
                Política de privacidade
              </a>
            </div>
          </div>
        </div>
      </Menu.Item>
    </Menu>
  </div>
);

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

export default connect(mapStateToProps)(MobileHeaderMenu);

import getInstance from './instance';

export default class QuizRequests {
  static async createQuiz (quizData) {
    const instance = await getInstance();
    const { data } = await instance.post('/quiz/with-appointment', quizData);
    return data;
  }

  static async getAllQuizPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/quiz', { params });
    return data;
  }

  static async getQuizById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/quiz/${id}`);
    return data;
  }
}

import getInstance from './instance';

export default class PrescriptionApi {
  static async createPrescription (prescriptionData) {
    const instance = await getInstance();
    const { data } = await instance.post('/prescription', prescriptionData);
    return data;
  }

  static async getPrescriptionById (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/prescription/${id}`, { params });
    return data;
  }

  static async getPrescription () {
    const instance = await getInstance();
    const { data } = await instance.get('/prescription');
    return data;
  }

  static async getUserPrescriptionByType (type) {
    const instance = await getInstance();
    const params = { type };
    const { data } = await instance.get('/quiz/type', { params });
    return data;
  }

  static async uploadPrescription (params) {

    const instance = await getInstance();
    params = {
      ...params,
      isPrivate: true,
    };

    const { data } = await instance.post('/prescription/upload', params);
    return data;
  }

  static async checkValid (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/prescription/valid', { params });
    return data;
  }
}

import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { decreaseLoading, increaseLoading } from './loading';

import CouponRequests from '../../api/coupon';

export const ACTION_GET_ALL_COUPONS = 'coupon:ACTION_GET_ALL_COUPONS';
export const ACTION_GET_COUPON_BY_ID = 'coupon:ACTION_GET_COUPON_BY_ID';
export const ACTION_CREATE_COUPON = 'coupon:ACTION_CREATE_COUPON';
export const ACTION_UPDATE_COUPON = 'coupon:ACTION_UPDATE_COUPON';
export const ACTION_GET_COUPON_BY_TITLE = 'coupon:ACTION_GET_COUPON_BY_TITLE';
export const ACTION_DELETE_COUPON = 'coupon:ACTION_DELETE_COUPON';

export const createCoupon = (data) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await CouponRequests.createCoupon(data);
    dispatch({
      type: ACTION_CREATE_COUPON,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateCoupon = (id, data) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await CouponRequests.updateCoupon(id, data);
    dispatch({
      type: ACTION_UPDATE_COUPON,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getCouponById = (id, parameters) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await CouponRequests.getCouponById(id, parameters);
    dispatch({
      type: ACTION_GET_COUPON_BY_ID,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getCouponsPagination = (parameters) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await CouponRequests.getAllCouponPaginated(parameters);
    dispatch({
      type: ACTION_GET_ALL_COUPONS,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getCouponByTitle = (title, parameters) => async (dispatch) => {
  try {
    const result = await CouponRequests.getCouponByTitle(title, parameters);
    dispatch({
      type: ACTION_GET_COUPON_BY_TITLE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    //
  }
};

export const cleanCoupon = () => async (dispatch) => {
  dispatch({
    type: ACTION_GET_COUPON_BY_TITLE,
    payload: null,
  });
};

export const removeCoupon = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await CouponRequests.deleteCoupon(id);
    dispatch({
      type: ACTION_DELETE_COUPON,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

import { QuizActions } from '../actions';

const initialState = {
  questionById: null,
  allQuestions: [],
};

const quizReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case QuizActions.ACTION_GET_QUIZ_BY_ID:
      state = {
        ...state,
        questionById: action.payload,
      };
      return state;
    case QuizActions.ACTION_GET_ALL_QUIZ_WITH_PAGINATION:
      state = {
        ...state,
        allQuestions: action.payload,
      };
      return state;
    default:
      return state;
  }
};


export default quizReducer;

import getInstance from './instance';

export default class ChatMessageRequests {
  static async getAllChatMessages (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/chat-message', { params });
    return data;
  }

  static async getChatMessageById (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/chat-message/${id}`, { params });
    return data;
  }

  static async updateChatMessage (id, chatMessageData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/chat-message/${id}`, chatMessageData);
    return data;
  }

  static async createChatMessage (chatMessageData) {
    const instance = await getInstance();
    const { data } = await instance.post('/chat-message', chatMessageData);
    return data;
  }

  static async getMineChatMessage (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/chat-message/mine', { params });
    return data;
  }

  static async removeChatMessage (id, params) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/chat-message/${id}`, { params });
    return data;
  }

  static async getChatUsers (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/chat-message/chat-users' , { params });
    return data;
  }
}

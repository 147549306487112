import React from 'react';
import Icon from '../../assets/svg/ic_color_whatsapp.svg';

const WhatsappHelpInfo = () => (
  <div className="whatsapp-help-info">
    <img className="whatsapp-help-info__icon"
      src={Icon} />
    <div className="whatsapp-help-info__data">
      Ficou alguma dúvida?
      &nbsp;
      <a
        className="whatsapp-help-info__data__link"
        href="https://api.whatsapp.com/send?phone=5511942120797"
        target="_blank"
        rel="noopener noreferrer"
      >
        Clique aqui
      </a>
      &nbsp;e fale com a gente!
    </div>
  </div>
);

export default WhatsappHelpInfo;

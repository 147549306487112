import Compress from 'compress.js';

export async function compressImages (images) {
  let response = null;

  try {
    const compress = new Compress();

    response = await compress.compress(images, {
      quality: 0.80,
      maxWidth: 1500,
      size: 1,
      resize: true,
    });

    response = response ? `${response[0].prefix}${response[0].data}` : null;
  } catch (e) {
    response = e;
  }

  return response;
}

export function dataURLtoFile (dataurl, filename) {

  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([ u8arr ], `${filename}.${mime.split('/')[1]}`, { type: mime });
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as AuthActions from '../../../redux/actions/auth';
import * as UserActions from '../../../redux/actions/user';
import * as UtilsActions from '../../../redux/actions/utils';
import { PrescriptionActions } from '../../../redux/actions';
import { Collapse } from 'antd';
import { Col } from 'react-bootstrap';
import CollapseAddress from './CollapseAddress/CollapseAddress';
import CollapseProfile from './CollapseProfile/CollapseProfile';
import CollapsePrescription from './CollapsePrescription/CollapsePrescription';


const CollapseGlobal = ({
  me,
  prescription,
  title,
  className,
  classNamePanel,
  isAddress,
  isProfile,
  isPrescription,
  getPrescription,
}) => {
  const { Panel } = Collapse;

  useEffect(() => {
    getPrescription();
  }, []);
  return (
    <>
      <Col
        lg={6}
        className={className}
      >
        <Collapse
          accordion
        >
          <Panel
            className={classNamePanel}
            header={title}
          >
            {isAddress && (
              <CollapseAddress />
            )}
            {isProfile && (
              <CollapseProfile />
            )}
            {isPrescription && (
              <CollapsePrescription />
            )}
          </Panel>
        </Collapse>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
  prescription: state.prescription.prescription,
});

const mapDispatchToProps = (dispatch) => ({
  getPrescription: () => dispatch(PrescriptionActions.getPrescription()),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseGlobal);
export const onlyLetters = (v) => {
  return v.replace(/\d/g, '');
};

export const onlyLettersUpperCase = (v) => {
  v = v.toUpperCase();
  return v.replace(/\d/g, '');
};

export const onlyLettersLowerCase = (v) => {
  v = v.toLowerCase();
  return v.replace(/\d/g, '');
};

export const onlyNumbers = (v) => {
  return v.replace(/\D/g, '');
};

export const cpfMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') : '';
};

export const cepMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1') : '';
};

export const phoneMask = value => {
  return value ? value
    .replace(/\D/g, '')
    //.replace(/(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})/, '($1) $2-$3') TIREI ESSE EXEMPLO QUE ESTA FUNCIONANDO EM BAIXO DAQUI
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5}|\d{4})\D*(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1') : '';
};

export const addressNumberMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{6})(\d)/, '$1')
    .replace(/(-\d{6})\d+?$/, '$1') : '';
};

export const stateMask = value => {
  value = value ? value.toUpperCase() : '';
  return value
    .replace(/\d/g, '')
    .replace(/(\S{2})(\S)/, '$1')
    .replace(/(-\S{2})\S+?$/, '$1');
};

export const dateCardCreditValidatedMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1') : '';
};

export const dateOfBirthMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1') : '';
};

export const cvvMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1')
    .replace(/(-\d{3})\d+?$/, '$1') : '';
};

export const cardNumberMask = value => {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d{1,2})/, '$1 $2')
    .replace(/( \d{4})\d+?$/, '$1') : '';
};

export function formatMoney (amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '')
      + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
      + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    return '0,00';
  }
}

export function toCurrencyLocale (value, hideCurrency) {
  if (!value) return 'R$ 0,00';

  try {
    const formattedValue = value && parseFloat(value).toFixed(2);
    return `${hideCurrency ? '' : 'R$ '}${formatMoney(formattedValue)}`;
  } catch (err) {
    return 'R$ 0,00';
  }
}

export function formatPriceValue (value) {
  if (typeof value === 'number') {
    return (value !== 0 && value < 10) ? `0${value}` : value;
  }

  return 0;
}


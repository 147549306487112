import moment from 'moment';
import { getProductById } from '../services/product';
import { Settings } from '../enum/settings';

export function getInitials (name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }

  return initials;
}

export function GetMonthFromDate (params) {
  return new Date(params).toLocaleString('default', { month: 'long' });
}

export function validateEmail (str) {
  // eslint-disable-next-line max-len,no-useless-escape
  const exp =
    // eslint-disable-next-line no-useless-escape, max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return exp.test(str);
}

export function removeSpecialChars (str) {
  return str
    ? str
      .toString()
      .replace(/[^A-Za-z0-9]/g, '')
      .replace(/\/s/g, '')
    : '';
}

export async function getBase64 (file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}

/* eslint-disable radix */
/* eslint-disable eqeqeq */
export const validaCpf = (cpf) => {
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;

  let soma = 0;
  let resto;

  for (let k = 1; k <= 9; k++)
    soma += parseInt(cpf.substring(k - 1, k)) * (11 - k);

  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;

  if (resto != parseInt(cpf.substring(9, 10))) return false;

  soma = 0;

  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;

  if (resto != parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const brandCreditCard = {
  /**
   * getCardFlag
   * Return card flag by number
   *
   * @param cardnumber
   */
  getCardFlag (cardnumber) {
    cardnumber = cardnumber.replace(/[^0-9]+/g, '');

    const cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (const flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  },
};

export function keepOnlyLetters (str) {
  return str ? str.replace(/[^a-z]/g, '').replace(/\/s/g, '') : null;
}

export function keepOnlyNumbers (str) {
  return str ? str.replace(/\D/g, '') : '';
}

export function someProductHasPrescription (products) {
  let response = [];

  if (products) {
    response = products.filter(
      (o) =>
        o.quantity > 0 &&
        (getProductById(o.id || o.identifier)?.prescription ||
          getProductById(o.id || o.identifier)?.prescriptionRequired),
    );
  }

  return response && response.length > 0;
}

export function prescriptionNeeded (products) {
  let response = [];

  if (products) {
    response = products.filter((o) => o.prescriptionRequired);
  }

  return response && response.length > 0;
}

export function getProductsDiscountAmount (products) {
  let response = 0;

  if (products && products.items && products.items.length > 0) {
    const items = products.items.filter((o) => o.quantity > 0);
    response = parseInt(items[0].discountPercentage, 10);
  }

  return response;
}

export function getTotalDiscount (value, percent) {
  return (value / 100) * percent;
}

export function formatNumber (
  amount,
  decimalCount = 2,
  decimal = ',',
  thousands = '.',
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    //
  }
}

export function someProductIsNotHairkit (products) {
  let response = [];

  if (products && products.length > 0) {
    response = products
      .filter((o) => o.quantity > 0)
      .filter(
        (o) => !getProductById(o.identifier || o.id).subscriptionDiscount,
      );

    if (response && response.length === 0) {
      const withProducts = products
        .filter((o) => o.quantity > 0)
        .filter((o) => getProductById(o.identifier || o.id));
      return !(withProducts && withProducts.length > 1);
    }
  }

  return response && response.length > 0;
}

export function onlyHairKitProducts (products) {
  let response = [];

  if (products && products.length > 0) {
    response = products.filter(
      (o) => getProductById(o.identifier || o.id).subscriptionDiscount,
    );
  }

  return response && response.length > 0;
}

export function hairKitCart (products) {
  let response = [];

  if (products && products.length > 0) {
    response = products.filter((o) => o.subscriptionDiscount);
  }

  return products && response && response.length === products.length;
}

export function chocosonoKit (products) {
  let response = [];

  if (products && products.length > 0) {
    response = products.filter((o) => o.signature);
  }

  return products && response && response.length === products.length;
}

export function getProductLatestImage (product, first) {
  if (!first) {
    if (product && product.images && product.images.length) {
      return product.images[product.images.length - 1];
    }

    return '';
  }

  if (product.images && product.images.length) {
    return product.images[0];
  }

  return '';
}

export function getCartDiscountValue (products = []) {
  let response = 0;

  if (products && products.length > 0) {
    response = products.reduce((a, b) => a + b.discountValue, 0);
  }

  return response;
}

export function validateBirthDate (birthDate) {
  if (
    !birthDate ||
    !moment(birthDate, 'YYYY-MM-DD').isValid() ||
    parseInt(moment().diff(birthDate, 'years')) > Settings.MAX_AGE ||
    parseInt(moment().diff(birthDate, 'years')) < Settings.MIN_AGE
  )
    return false;

  return true;
}

export function getLastElementFromPath (pathname) {
  const path = pathname.split('/');

  return path[path.length - 1];
}

// List of known domains
export const emailDomains = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'icloud.com',
  'uol.com.br',
  'terra.com.br',
  'live.com',
  'bol.com.br',
  'me.com',
  'msn.com',
];

// Function that completes the email address with a specific domain
export function autoCompleteEmail (value) {
  // Check if the entered email value contains an "@" and a known domain
  const atIndex = value.indexOf('@');

  // If no domain is entered or "@" is at the end, return the original value
  if (atIndex === -1 || atIndex === value.length - 1) {
    return value;
  }

  const prefix = value.substring(0, atIndex + 1);
  const enteredDomain = value.substring(atIndex + 1);

  if (enteredDomain.length === 2) {
    // Find the domain that starts with the characters entered after the "@"
    const partialDomain = enteredDomain.substring(0, 2);
    const foundDomain = emailDomains.find((domain) =>
      domain.startsWith(partialDomain),
    );

    // If a domain is found, return the complete email, otherwise, return the original value
    return foundDomain ? prefix + foundDomain : value;
  }

  return value;
}

import React from 'react';
import CustomText from '../CustomComponents/CustomText';
import { I18n } from 'react-redux-i18n';

const PromotionCardCheckoutMobile = ({
  theme,
}) => {

  return (
    <CustomText
      className={`promotion-card-checkout-mobile__background ${theme.backgroundColor}`}
      customClassName={'promotion-card-checkout-mobile__title'}
      value={I18n.t('CHECKOUT.PROMOTION_CARD.CARD_MOBILE')}
    />
  );
};

export default PromotionCardCheckoutMobile;
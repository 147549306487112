import React from 'react';

const ButtonIcon = ({ className, onClick, icon }) => {
  return (
    <button className={className}
      onClick={onClick}>
      <img src={icon}
        alt='icon'/>
    </button>
  );
};

export default ButtonIcon;
const PrescriptionProductType = {
  MINOXIDIL: 1,
  FINASTERIDA: 2,
  ALL: 3,
  ANTI_AGING: 4,
  TEA: 5,
  TONIC: 6,
  GUMMY: 7,
  SHAMPOO: 8,
  SLEEP_GUMMY: 9,
  ED: 10,
};

export const prescriptionProductsById = {
  MINOXIDIL: '6f9919da-d5cc-4948-88ae-5160852cb7b7',
  FINASTERIDA: '40c5747d-1b87-4768-a3aa-c1186a9e3b1f',
  ALL: '6f9919da-d5cc-4948-88ae-5160852cb7b7,40c5747d-1b87-4768-a3aa-c1186a9e3b1f,e743ec60-df88-4f4b-ac84-748be2473d2b',
  ANTI_AGING: '8cc5dd9a-732f-45cb-bfd1-2dbdbd462719',
  TEA: '3b445d33-0849-4976-aa07-11cc61770141',
  TONIC: 'fbd7397f-8071-4f0a-9a64-90debfbf908a',
  GUMMY: '2ad58d0e-5934-4f1a-9d51-8cba378122b6',
  SHAMPOO: 'e6c99cac-19cf-4f18-8704-0fff17dbb133',
  SLEEP_GUMMY: 'e743ec60-df88-4f4b-ac84-748be2473d2b',
  ED: '312974bf-1dac-434d-8084-942e42beed8b'
};

export default PrescriptionProductType;

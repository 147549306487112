import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import * as ActionProducts from '../../redux/actions/products';

import { Link } from 'react-router-dom';

const FeatureSection = (props) => {
  const { getProductByID } = props;

  const getProduct = async () => {
    await getProductByID(props.id);
  };

  return (
    <div className={`feature-section ${props.last && 'feature-section--last'}`}>
      <Row className={`${props.reverse ? 'flex-row-reverse' : ''}`}>
        <Col
          lg={6}
          className="mobile-no-padding"
        >
          <div className="feature-section__container__wrapper">
            <div className="feature-section__container__inner">
              <div className="feature-section__container__inner__title">
                {props.title}
              </div>
              <div className="feature-section__container__inner__description">
                {props.description}
              </div>
              <div
                className="feature-section__container__inner__buttom"
                style={{
                  borderBottom: props.last && 'none',
                }}
              >
                <Link
                  className="feature-section__container__inner__buttom__link"
                  onClick={() => getProduct()}
                  to={props.buttomUrl}
                >
                  <span className="feature-section__container__inner__buttom__link__inner">
                    {props.buttomText}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          className="mobile-no-padding"
        >
          <div className="feature-section__container__image__wrapper">
            <div
              className={`feature-section__container__image ${props.reverse ? 'feature-section__container__image--left' : 'feature-section__container__image--right'}`}
              style={{
                backgroundImage: `url(${props.imageUrl})`,
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productByID: state.product.productByID,
});

const mapDispatchToProps = (dispatch) => ({
  getProductByID: (id) => dispatch(ActionProducts.getProductByID(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSection);

import { Button, message, Modal, Spin, Steps, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Webcam from 'react-webcam';
import ArrowLeft from '../../../assets/png/left-arrow.png';
import ArrowClinicRight from '../../../assets/svg/arrow_clinic_right.svg';
import EffectOne from '../../../assets/svg/effect_one.svg';
import EffectThree from '../../../assets/svg/effect_three.svg';
import EffectTwo from '../../../assets/svg/effect_two.svg';
import IconBuy from '../../../assets/svg/ic_buy.svg';
import IconCamera from '../../../assets/svg/ic_camera.svg';
import IconClinic from '../../../assets/svg/right_clinic.svg';
import { MedicalAppointmentStatus, MedicalAppointmentType } from '../../../enum/medicalAppointment';
import * as EvolutionActions from '../../../redux/actions/evolution';
import * as MedicalAppointmentActions from '../../../redux/actions/medical-appointment';
import * as QuizActions from '../../../redux/actions/quiz';
import * as UtilsActions from '../../../redux/actions/utils';
import { translate } from '../../../services/i18n';
import WhiteButton from '../../../components/WhiteButton/WhiteButton';
import { compressImages } from '../../../utils/imageUtils';
import { Helmet } from 'react-helmet-async';
import { setDataLayer } from '../../../services/analytics';
import { LoadingOutlined } from '@ant-design/icons';
import { PrescriptionActions } from '../../../redux/actions';
import { QuestionType } from '../../../services/prescription';

const { Step } = Steps;


const antIcon = <LoadingOutlined
  style={{ fontSize: 24 , color: '#FFF' }}
  spin
/>;

const videoConstraints = {
  width: 500,
  height: 500,
  facingMode: 'user'
};

const Clinic = ({
  createQuiz,
  me,
  uploadImageBase64,
  createEvolution,
  createMedicalAppointment,
  loading,
}) => {
  const [ selectedLink, setSelectedLink ] = useState(0);
  const [ current, setCurrent ] = useState(0);

  useEffect(() => {
    setDataLayer('medical_evaluation_start');
  }, []);


  const createQuizValidateAndEvolutionPhotos = async () => {
    try {
      const photo1 = await uploadImageBase64(fileList[0].thumbUrl);
      const photo2 = await uploadImageBase64(fileListTwo[0].thumbUrl);
      const photo3 = await uploadImageBase64(fileListThree[0].thumbUrl);

      const responseEvolution = await createEvolution({
        filePicTop: photo1.bucket,
        filePicFront: photo2.bucket,
        filePicBack: photo3.bucket,
      });

      const responseQuiz = await createQuiz({
        quiz: {
          questionAndResponse: responseStep,
          type: QuestionType.HAIR_LOSS,
        },
        medicalAppointment: {
          evolutionId: responseEvolution && responseEvolution.id,
          attendBy: '',
          status: MedicalAppointmentStatus.PENDING_CONSULTATION,
          request: moment().format(),
          typeAttendance: MedicalAppointmentType.COMMON,
        }
      });

      if (responseQuiz && responseEvolution) {
        await createMedicalAppointment({
          evolutionId: responseEvolution && responseEvolution.id,
          quizId: responseQuiz && responseQuiz.id,
          attendBy: '',
          status: MedicalAppointmentStatus.PENDING_CONSULTATION,
          request: moment().format(),
          typeAttendance: MedicalAppointmentType.COMMON,
        });
        const { host, protocol } = window.location;

        window.location.href = `${protocol}//${host}/?fromPrescriptionPayment=true&hairLoss=true`;
      }
    } catch (error) {
      //
    }
  };

  const isButtonDisabled = () => {
    if (fileList[0].name === 'default1.png' || fileListTwo[0].name === 'default2.png' || fileListThree[0].name === 'default3.png') {
      return true;
    } else if (fileList[0].name === '' || fileListTwo[0].name === '' || fileListThree[0].name === '') {
      return true;
    }

    return false;
  };

  const [ textAreaValues, setTextAreaValues ] = useState({
    response202: '',
    response306: '',
    response307: '',
    response502: '',
    response503: '',
    response602: '',
    response603: '',
    response604: '',
    response702: '',
    response802: '',
    response902: '',
    response1004: '',
  });

  const {
    response202,
    response306,
    response307,
    response502,
    response503,
    response602,
    response603,
    response604,
    response702,
    response802,
    response902
  } = textAreaValues;

  const [ responseStep, setResponseStep ] = useState([]);

  const [ visibleQuestion201_7, setVisibleQuestion201_7 ] = useState(false);
  const [ visibleQuestion301_2, setVisibleQuestion301_2 ] = useState(false);
  const [ visibleQuestion301_3, setVisibleQuestion301_3 ] = useState(false);
  const [ visibleQuestion301_4, setVisibleQuestion301_4 ] = useState(false);
  const [ visibleQuestion501_1, setVisibleQuestion501_1 ] = useState(false);
  const [ visibleQuestion601_2_3_4, setVisibleQuestion601_2_3_4 ] = useState(false);
  const [ visibleQuestion601_5, setVisibleQuestion601_5 ] = useState(false);
  const [ visibleQuestion701_2_3_4_5_6_7_8_9_10_11_12_13_14, setVisibleQuestion701_2_3_4_5_6_7_8_9_10_11_12_13_14 ] = useState(false);
  const [ visibleQuestion801_1, setVisibleQuestion801_1 ] = useState(false);
  const [ visibleQuestion901_1, setVisibleQuestion901_1 ] = useState(false);

  const next = () => {
    setCurrent(current + 1);
  };

  const handleChange = name => e => {
    setTextAreaValues({ ...textAreaValues, [name]: e.target.value });
  };

  const radios201 = document.getElementsByName('201');
  const radios301 = document.getElementsByName('301');
  const radios501 = document.getElementsByName('501');
  const radios601 = document.getElementsByName('601');
  const radios701 = document.getElementsByName('701');
  const radios801 = document.getElementsByName('801');
  const radios901 = document.getElementsByName('901');

  const handleQuestions = (e) => {
    let changeChecked701 = null;

    radios201[6] && radios201[6].checked ? setVisibleQuestion201_7(true) : setVisibleQuestion201_7(false);

    radios301[1] && radios301[1].checked ? setVisibleQuestion301_2(true) : setVisibleQuestion301_2(false);

    radios301[2] && radios301[2].checked ? setVisibleQuestion301_3(true) : setVisibleQuestion301_3(false);

    radios301[3] && radios301[3].checked ? setVisibleQuestion301_4(true) : setVisibleQuestion301_4(false);

    radios501[0] && radios501[0].checked ? setVisibleQuestion501_1(true) : setVisibleQuestion501_1(false);

    radios601[0] && (radios601[1].checked || radios601[2].checked || radios601[3].checked)
      ? setVisibleQuestion601_2_3_4(true) : setVisibleQuestion601_2_3_4(false);

    radios601[4] && radios601[4].checked ? setVisibleQuestion601_5(true) : setVisibleQuestion601_5(false);

    if (radios701[0] && radios701[0].checked) {
      if (e.target.value !== '1') {
        radios701[0].checked = false;
        radios701[13].checked = false;

        changeChecked701 = true;
      } else {
        radios701[1].checked = false;
        radios701[2].checked = false;
        radios701[3].checked = false;
        radios701[4].checked = false;
        radios701[5].checked = false;
        radios701[6].checked = false;
        radios701[7].checked = false;
        radios701[8].checked = false;
        radios701[9].checked = false;
        radios701[10].checked = false;
        radios701[11].checked = false;
        radios701[12].checked = false;
        radios701[13].checked = false;
      }
    }

    if (radios701[13] && radios701[13].checked) {
      if (e.target.value !== '14') {
        radios701[0].checked = false;
        radios701[13].checked = false;
        changeChecked701 = true;
      } else {
        radios701[1].checked = false;
        radios701[2].checked = false;
        radios701[3].checked = false;
        radios701[4].checked = false;
        radios701[5].checked = false;
        radios701[6].checked = false;
        radios701[7].checked = false;
        radios701[8].checked = false;
        radios701[9].checked = false;
        radios701[10].checked = false;
        radios701[11].checked = false;
        radios701[12].checked = false;
      }
    }

    radios701[0] && (radios701[1].checked || radios701[2].checked || radios701[3].checked
      || radios701[4].checked || radios701[5].checked || radios701[6].checked || radios701[7].checked
      || radios701[8].checked || radios701[9].checked || radios701[10].checked || radios701[11].checked
      || radios701[12].checked || radios701[13].checked) ? setVisibleQuestion701_2_3_4_5_6_7_8_9_10_11_12_13_14(true) : setVisibleQuestion701_2_3_4_5_6_7_8_9_10_11_12_13_14(false);

    radios801[0] && radios801[0].checked ? setVisibleQuestion801_1(true) : setVisibleQuestion801_1(false);

    radios901[0] && radios901[0].checked ? setVisibleQuestion901_1(true) : setVisibleQuestion901_1(false);

    return { changeChecked701 };
  };

  const handleRadioButton = (e, object) => {
    handleQuestions(null, object);

    if (responseStep.findIndex(element => element.question === object.QUESTION) === -1) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: object.QUESTION,
        response: object.RESPONSE,
        score: object.SCORE,
      } ]);
    } else {
      const index = responseStep.findIndex(element => element.question === object.QUESTION);
      const aux = [ ...responseStep ];
      aux[index] = {
        question: object.QUESTION,
        response: object.RESPONSE,
        score: object.SCORE,
      };
      setResponseStep(aux);
    }

  };

  const handleCheckboxButton = (e, object) => {
    const { changeChecked701 } = handleQuestions(e, object);

    if (responseStep.findIndex(element => element.question === object.QUESTION) === -1) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: object.QUESTION,
        response: `${object.RESPONSE}`,
        score: object.SCORE,
      } ]);
    } else {
      const index = responseStep.findIndex(element => element.question === object.QUESTION);
      const aux = [ ...responseStep ];

      if (e.target.checked === true) {
        aux[index] = {
          question: object.QUESTION,
          response: `${responseStep[index].response}¨${object.RESPONSE}`,
          score: object.SCORE,
        };

        if (changeChecked701) {
          const multipleResponse = aux[index].response.split('¨');
          const response1 = multipleResponse.findIndex(element => element === '1');
          const response14 = multipleResponse.findIndex(element => element === '14');

          if (response1 !== -1) {
            multipleResponse.splice(response1, 1);
          }

          if (response14 !== -1) {
            multipleResponse.splice(response14, 1);
          }

          aux[index] = {
            question: object.QUESTION,
            response: `${multipleResponse.join('¨')}`,
            score: object.SCORE,
          };
        }
      } else {
        const multipleResponse = aux[index].response.split('¨');
        const indexResponse = multipleResponse.findIndex(element => element === object.RESPONSE);
        multipleResponse.splice(indexResponse, 1);
        aux[index] = {
          question: object.QUESTION,
          response: `${multipleResponse.join('¨')}`,
          score: object.SCORE,
        };
      }

      setResponseStep(aux);
    }
  };

  const addResponseText = () => {

    if (radios201[6] && radios201[6].checked) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '202',
        response: response202,
        score: '0',
      } ]);
    }

    if (radios301[2] && (radios301[2].checked || radios301[3].checked)) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '306',
        response: response306,
        score: '0',
      },
      {
        question: '307',
        response: response307,
        score: '0',
      } ]);
    }

    if (radios501[0] && radios501[0].checked) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '502',
        response: response502,
        score: '0',
      },
      {
        question: '503',
        response: response503,
        score: '0',
      } ]);
    }

    if (radios601[0] && (radios601[1].checked || radios601[2].checked || radios601[3].checked)) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '602',
        response: response602,
        score: '0',
      } ]);
    }

    if (radios601[4] && radios601[4].checked) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '603',
        response: response603,
        score: '0',
      },
      {
        question: '604',
        response: response604,
        score: '0',
      } ]);
    }

    if (radios701[0] && (radios701[1].checked || radios701[2].checked || radios701[3].checked
      || radios701[4].checked || radios701[5].checked || radios701[6].checked
      || radios701[7].checked || radios701[8].checked || radios701[9].checked
      || radios701[10].checked || radios701[11].checked || radios701[12].checked || radios701[13].checked)) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '702',
        response: response702,
        score: '0',
      } ]);
    }

    if (radios801[0] && radios801[0].checked) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '802',
        response: response802,
        score: '0',
      } ]);
    }

    if (radios901[0] && radios901[0].checked) {
      setResponseStep(responseStep => [ ...responseStep, {
        question: '902',
        response: response902,
        score: '0',
      } ]);
    }

    document.querySelector('body').scrollTo(0, 250);
  };

  const addQuestion = (e) => {
    addResponseText();

    switch (current) {
      case 0:

        if (responseStep.findIndex(element => element.question === '101') > -1 &&
          responseStep.findIndex(element => element.question === '102') > -1) {
          setDataLayer('medical_evaluation_stage_1');
          next();
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 1:

        if (responseStep.findIndex(element => element.question === '201') > -1) {
          if (radios201[6].checked) {
            if (response202.length > 0) {
              setDataLayer('medical_evaluation_stage_2');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          } else {
            setDataLayer('medical_evaluation_stage_2');
            next();
          }
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 2:

        if (responseStep.findIndex(element => element.question === '301') > -1) {
          if (radios301[0].checked || radios301[2].checked) {
            if (radios301[2].checked) {
              if (response306.length > 0 && response307.length > 0) {
                setDataLayer('medical_evaluation_stage_3');
                next();
              } else {
                return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
              }
            } else {
              setDataLayer('medical_evaluation_stage_3');
              next();
            }
          }

          if (radios301[1].checked || radios301[3].checked) {
            if (responseStep.findIndex(element => element.question === '302') > -1
              && responseStep.findIndex(element => element.question === '303') > -1
              && responseStep.findIndex(element => element.question === '304') > -1
              && responseStep.findIndex(element => element.question === '305') > -1) {
              if (radios301[3].checked) {
                if (response306.length > 0 && response307.length > 0) {
                  setDataLayer('medical_evaluation_stage_3');
                  next();
                } else {
                  return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
                }
              } else {
                setDataLayer('medical_evaluation_stage_3');
                next();
              }
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
            }
          }
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 3:

        if (responseStep.findIndex(element => element.question === '401') > -1) {
          setDataLayer('medical_evaluation_stage_4');
          next();
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 4:

        if (responseStep.findIndex(element => element.question === '501') > -1) {
          if (radios501[0].checked) {
            if (response502.length > 0 && response503.length > 0) {
              setDataLayer('medical_evaluation_stage_5');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          } else {
            setDataLayer('medical_evaluation_stage_5');
            next();
          }
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 5:

        if (responseStep.findIndex(element => element.question === '601') > -1) {
          if (radios601[1].checked || radios601[2].checked || radios601[3].checked) {
            if (response602.length > 0) {
              setDataLayer('medical_evaluation_stage_6');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          }

          if (radios601[4].checked) {
            if (response603.length > 0 && response604.length > 0) {
              setDataLayer('medical_evaluation_stage_6');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          }

          if (radios601[0].checked) {
            setDataLayer('medical_evaluation_stage_6');
            next();
          }
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 6:

        if (responseStep.findIndex(element => element.question === '701') > -1) {
          if (radios701[0].checked) {
            setDataLayer('medical_evaluation_stage_7');
            next();
          } else {
            if (response702.length > 0) {
              setDataLayer('medical_evaluation_stage_7');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          }
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 7:

        if (responseStep.findIndex(element => element.question === '801') > -1) {
          if (radios801[0].checked) {
            if (response802.length > 0) {
              setDataLayer('medical_evaluation_stage_8');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          } else {
            setDataLayer('medical_evaluation_stage_8');
            next();
          }

        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 8:

        if (responseStep.findIndex(element => element.question === '901') > -1) {
          if (radios901[0].checked) {
            if (response902.length > 0) {
              setDataLayer('medical_evaluation_stage_9');
              next();
            } else {
              return message.error(translate('COMPONENTS.CLINIC.ERROR.EMPTY_TEXT'));
            }
          } else {
            setDataLayer('medical_evaluation_stage_9');
            next();
          }

        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      case 9:

        if (responseStep.findIndex(element => element.question === '1001') > -1
          && responseStep.findIndex(element => element.question === '1002') > -1
        ) {
          setDataLayer('medical_evaluation_stage_10');
          setSelectedLink(2);
        } else {
          return message.error(translate('COMPONENTS.CLINIC.ERROR.FILL_IN_ALL_QUESTIONS'));
        }

        break;

      default:
        break;
    }
  };

  const steps = [
    {
      title: '1',
      content: (
        [
          <div key='1'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_1.TITLE_ONE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.101')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_1.TITLE_TWO')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.102')).map((item, index) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
          </div>
        ]
      )
    },
    {
      title: '2',
      content: (
        [
          <div key='2'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_2.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.201')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="checkbox"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleCheckboxButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion201_7 &&
            <textarea
              type="text"
              placeholder={translate('COMPONENTS.CLINIC.STEP_2.PLACEHOLDER')}
              maxLength="200"
              value={response202}
              onChange={handleChange('response202')}
              name="response202"
            />
            }
          </div>
        ]
      )
    },
    {
      title: '3',
      content: (
        [
          <div key='3'
            className="clinic__questions__box">
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_3.TITLE_ZERO')}
              </h3>
              <div className="clinic__questions__box__input">
                {Object.values(translate('COMPONENTS.CLINIC.ITEMS.301')).map((item) => (
                  <div key={item.KEY}>
                    <input
                      type="radio"
                      id={item.KEY}
                      name={item.QUESTION}
                      value={item.RESPONSE}
                      onClick={(e) => handleRadioButton(e, item)}
                    />
                    <label htmlFor={item.KEY}>{item.ANSWER}</label>
                  </div>
                ))}
              </div>
            </div>
            {visibleQuestion301_3 || visibleQuestion301_4 ?
              <div className="clinic__questions__box aux">
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_FIVE')}
                </h3>
                <textarea
                  placeholder={translate('COMPONENTS.CLINIC.STEP_3.PLACEHOLDER')}
                  maxLength="200"
                  value={response306}
                  onChange={handleChange('response306')}
                  name="response306"
                />
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_SIX')}
                </h3>
                <textarea
                  placeholder={translate('COMPONENTS.CLINIC.STEP_3.PLACEHOLDER')}
                  maxLength="200"
                  value={response307}
                  onChange={handleChange('response307')}
                  name="response307"
                />
              </div>
              : null}
            {visibleQuestion301_2 || visibleQuestion301_4 ?
              <div className="clinic__questions__box aux">
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_ONE')}
                </h3>
                <div className="clinic__questions__box__input">
                  {Object.values(translate('COMPONENTS.CLINIC.ITEMS.302')).map((item) => (
                    <div key={item.KEY}>
                      <input
                        type="radio"
                        id={item.KEY}
                        name={item.QUESTION}
                        value={item.RESPONSE}
                        onClick={(e) => handleRadioButton(e, item)}
                      />
                      <label htmlFor={item.KEY}>{item.ANSWER}</label>
                    </div>
                  ))}
                </div>
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_TWO')}
                </h3>
                <div className="clinic__questions__box__input">
                  {Object.values(translate('COMPONENTS.CLINIC.ITEMS.303')).map((item) => (
                    <div key={item.KEY}>
                      <input
                        type="radio"
                        id={item.KEY}
                        name={item.QUESTION}
                        value={item.RESPONSE}
                        onClick={(e) => handleRadioButton(e, item)}
                      />
                      <label htmlFor={item.KEY}>{item.ANSWER}</label>
                    </div>
                  ))}
                </div>
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_THREE')}
                </h3>
                <div className="clinic__questions__box__input">
                  {Object.values(translate('COMPONENTS.CLINIC.ITEMS.304')).map((item) => (
                    <div key={item.KEY}>
                      <input
                        type="radio"
                        id={item.KEY}
                        name={item.QUESTION}
                        value={item.RESPONSE}
                        onClick={(e) => handleRadioButton(e, item)}
                      />
                      <label htmlFor={item.KEY}>{item.ANSWER}</label>
                    </div>
                  ))}
                </div>
                <h3 className="clinic__questions__box__title">
                  {translate('COMPONENTS.CLINIC.STEP_3.TITLE_FOUR')}
                </h3>
                <div className="clinic__questions__box__input">
                  {Object.values(translate('COMPONENTS.CLINIC.ITEMS.305')).map((item) => (
                    <div key={item.KEY}>
                      <input
                        type="radio"
                        id={item.KEY}
                        name={item.QUESTION}
                        value={item.RESPONSE}
                        onClick={(e) => handleRadioButton(e, item)}
                      />
                      <label htmlFor={item.KEY}>{item.ANSWER}</label>
                    </div>
                  ))}
                </div>
              </div>
              : null}
          </div>
        ]
      )
    },
    {
      title: '4',
      content: (
        [
          <div key='4'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_4.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.401')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
          </div>
        ]
      )
    },
    {
      title: '5',
      content: (
        [
          <div key='5'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_5.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.501')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion501_1 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_5.TITLE_TWO')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_5.PLACEHOLDER')}
                maxLength="200"
                value={response502}
                onChange={handleChange('response502')}
                name="response502"
              />
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_5.TITLE_THREE')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_5.PLACEHOLDER')}
                maxLength="200"
                value={response503}
                onChange={handleChange('response503')}
                name="response503"
              />
            </div>
            }
          </div>
        ]
      )
    },
    {
      title: '6',
      content: (
        [
          <div key='6'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_6.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.601')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion601_2_3_4 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_6.TITLE_TWO')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_6.PLACEHOLDER')}
                maxLength="200"
                value={response602}
                onChange={handleChange('response602')}
                name="response602"
              />
            </div>
            }
            {visibleQuestion601_5 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_6.TITLE_THREE2')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_6.PLACEHOLDER')}
                maxLength="200"
                value={response603}
                onChange={handleChange('response603')}
                name="response603"
              />
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_6.TITLE_FOUR')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_6.PLACEHOLDER')}
                maxLength="200"
                value={response604}
                onChange={handleChange('response604')}
                name="response604"
              />
            </div>
            }
          </div>
        ]
      )
    },
    {
      title: '7',
      content: (
        [
          <div key='7'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_7.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.701')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="checkbox"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleCheckboxButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion701_2_3_4_5_6_7_8_9_10_11_12_13_14 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_7.TITLE_TWO')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_7.PLACEHOLDER')}
                maxLength="200"
                value={response702}
                onChange={handleChange('response702')}
                name="response702"
              />
            </div>
            }
          </div>
        ]
      )
    },
    {
      title: '8',
      content: (
        [
          <div key='8'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_8.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.801')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion801_1 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_8.TITLE_TWO')}
              </h3>
              <textarea
                placeholder={translate('COMPONENTS.CLINIC.STEP_8.PLACEHOLDER')}
                maxLength="200"
                value={response802}
                onChange={handleChange('response802')}
                name="response802"
              />
            </div>
            }
          </div>
        ]
      )
    },
    {
      title: '9',
      content: (
        [
          <div key='9'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_9.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.901')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            {visibleQuestion901_1 &&
            <div className="clinic__questions__box aux">
              <h3 className="clinic__questions__box__title">
                {translate('COMPONENTS.CLINIC.STEP_9.TITLE_TWO')}
              </h3>
              <textarea
                type="text"
                placeholder={translate('COMPONENTS.CLINIC.STEP_9.PLACEHOLDER')}
                maxLength="200"
                value={response902}
                onChange={handleChange('response902')}
                name="response902"
              />
            </div>
            }
          </div>
        ]
      )
    },
    {
      title: '10',
      content: (
        [
          <div key='10'
            className="clinic__questions__box">
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_10.TITLE')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.1001')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
            <h3 className="clinic__questions__box__title">
              {translate('COMPONENTS.CLINIC.STEP_10.TITLE_TWO')}
            </h3>
            <div className="clinic__questions__box__input">
              {Object.values(translate('COMPONENTS.CLINIC.ITEMS.1002')).map((item) => (
                <div key={item.KEY}>
                  <input
                    type="radio"
                    id={item.KEY}
                    name={item.QUESTION}
                    value={item.RESPONSE}
                    onClick={(e) => handleRadioButton(e, item)}
                  />
                  <label htmlFor={item.KEY}>{item.ANSWER}</label>
                </div>
              ))}
            </div>
          </div>
        ]
      )
    },
  ];

  const [ fileList, setFileList ] = useState([
    {
      uid: '-1',
      name: 'default1.png',
      status: 'done',
      url: EffectOne,
    },
  ]);

  const [ fileListTwo, setFileListTwo ] = useState([
    {
      uid: '-1',
      name: 'default2.png',
      status: 'done',
      url: EffectTwo,
    },
  ]);

  const [ fileListThree, setFileListThree ] = useState([
    {
      uid: '-1',
      name: 'default3.png',
      status: 'done',
      url: EffectThree,
    },
  ]);

  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  ///*{ fileList: newFileList }*/,
  const onChange = async (e, setFunction) => {
    const { file } = e;

    if (file) {

      file.extension = file.name && file.name.split('.') && file.name.split('.').pop().toLowerCase();
      const isJpgOrPng = file.extension === 'jpeg' || file.extension === 'png' || file.extension === 'jpg';

      if (!isJpgOrPng) {
        return message.error(translate('SHARED.fileValidation'));
      }

      file.thumbUrl = await compressImages([ file ]);

      const newFileList = [];
      newFileList[0] = {
        ...file,
        thumbUrl: file.thumbUrl,
        status: 'done'
      };

      setFunction(newFileList);
    } else {
      return message.error(translate('SHARED.fileValidation'));
    }
  };

  const onPreview = async file => {
    const src = file.url ? file.url : file.thumbUrl;
    setImageModal(src);
    setIsModalVisible(true);
  };

  const webcamRef = useRef(null);
  const [ imgSrc, setImgSrc ] = useState(null);

  const [ visibleWebCam, setVisibleWebCam ] = useState(false);

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot({ width: 500, height: 500 });
    setImgSrc(imageSrc);

    setFileList([ {
      uid: '-1',
      name: 'image.jpeg',
      status: 'done',
      thumbUrl: imageSrc,
    } ]);
    setVisibleWebCam(!visibleWebCam);

  }, [ webcamRef, setImgSrc, visibleWebCam ]);

  const WebcamComponent = () => (
    <React.Fragment>
      {visibleWebCam === true &&
      <Webcam
        audio={false}
        mirrored={true}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/png"
        width={300}
        videoConstraints={videoConstraints}
      />
      }
      <Button
        className="capture-cam"
        onClick={capture}
      >
        {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
      </Button>
    </React.Fragment>
  );

  const webcamRef2 = useRef(null);
  const [ imgSrc2, setImgSrc2 ] = useState(null);

  const [ visibleWebCam2, setVisibleWebCam2 ] = useState(false);

  const capture2 = React.useCallback(() => {
    const imageSrc2 = webcamRef2.current.getScreenshot({ width: 500, height: 500 });
    setImgSrc2(imageSrc2);
    setFileListTwo([ {
      uid: '-1',
      name: 'image.jpeg',
      status: 'done',
      thumbUrl: imageSrc2,
    } ]);
    //toggleVisible()
    setVisibleWebCam2(!visibleWebCam2);
  }, [ webcamRef2, setImgSrc2, visibleWebCam2 ]);

  const WebcamComponent2 = () => (
    <React.Fragment>
      {visibleWebCam2 === true &&
      <Webcam
        audio={false}
        mirrored={true}
        height={300}
        ref={webcamRef2}
        screenshotFormat="image/jpeg"
        width={300}
        videoConstraints={videoConstraints}
      />
      }
      <Button
        className="capture-cam"
        onClick={capture2}
      >
        {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
      </Button>
    </React.Fragment>
  );

  const webcamRef3 = useRef(null);
  const [ imgSrc3, setImgSrc3 ] = useState(null);

  const [ visibleWebCam3, setVisibleWebCam3 ] = useState(false);

  const capture3 = React.useCallback(() => {
    const imageSrc3 = webcamRef3.current.getScreenshot({ width: 500, height: 500 });
    setImgSrc3(imageSrc3);
    setFileListThree([ {
      uid: '-1',
      name: 'image.jpeg',
      status: 'done',
      thumbUrl: imageSrc3,
    } ]);
    //toggleVisible()
    setVisibleWebCam3(!visibleWebCam3);
  }, [ webcamRef3, setImgSrc3, visibleWebCam3 ]);

  const WebcamComponent3 = () => (
    <React.Fragment>
      {visibleWebCam3 === true &&
      <Webcam
        audio={false}
        mirrored={true}
        height={300}
        ref={webcamRef3}
        screenshotFormat="image/jpeg"
        width={300}
        videoConstraints={videoConstraints}
      />
      }
      <Button
        className="capture-cam"
        onClick={capture3}
      >
        {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
      </Button>
    </React.Fragment>
  );

  const [ isModalVisible, setIsModalVisible ] = useState(false);

  const [ imageModal, setImageModal ] = useState(null);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const startTheClinic = () => {
    setSelectedLink(1);
    setDataLayer('medical_evaluation_agree');
  };

  return (
    <form>
      <div className="clinic">
        <Helmet>
          <style type="text/css">
            {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
          </style>
        </Helmet>
        <div className="clinic__header">
          <div className="clinic__header__img">
            <Link to="/avaliacao">
              <img src={ArrowLeft} />
            </Link>
          </div>
          <h1 className="clinic__header__title">
            {translate('COMPONENTS.CLINIC.TITLE')}
          </h1>
          <div>
          </div>
        </div>
        <div className="clinic__links">
          <div className={`clinic__links__box${selectedLink === 0 ? '__active' : ''}`}>
            <Container>
              <Row>
                <Col lg={12}>
                  {selectedLink === 0 ? (
                    <h3 className={`clinic__links__box${selectedLink === 0 ? '__active' : ''}__title`}>
                      {translate('COMPONENTS.CLINIC.LINKS.ONE')}
                    </h3>
                  ) : (
                    <h3 className="clinic__links__box__title__active__title-two">
                      {translate('COMPONENTS.CLINIC.LINKS.ONE')}
                      <img src={IconClinic} />
                    </h3>
                  )}
                  {selectedLink !== 0 ? (
                    <h3 className="clinic__links__box__title__active__tit/le-two"></h3>
                  ) : (
                    <div className="clinic__links__box__active__aux">
                      <div className="clinic__links__box__active__aux__description">
                        <h3 className="clinic__links__box__active__aux__description__title">
                          {me && me.name}
                        </h3>
                        <p className="clinic__links__box__active__aux__description__subtitle">
                          {translate('COMPONENTS.CLINIC.DESCRIPTION.SUBTITLE')}
                        </p>
                        <ul>
                          <li className="clinic__links__box__active__aux__description__list">
                            {translate('COMPONENTS.CLINIC.DESCRIPTION.LIST_ZERO')}
                          </li>
                          <li className="clinic__links__box__active__aux__description__list">
                            {translate('COMPONENTS.CLINIC.DESCRIPTION.LIST_ONE')}
                          </li>
                          <li className="clinic__links__box__active__aux__description__list">
                            {translate('COMPONENTS.CLINIC.DESCRIPTION.LIST_TWO')}
                          </li>
                          <li className="clinic__links__box__active__aux__description__list">
                            {translate('COMPONENTS.CLINIC.DESCRIPTION.LIST_THREE')}
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              href={translate('COMPONENTS.HELP.ITEMS.ONE.DESCRIPTION_PRESCRIPTION_LINK')}
                            >
                              {translate('COMPONENTS.CLINIC.DESCRIPTION.link')}
                            </a>
                          </li>
                        </ul>
                        <span
                          onClick={() => startTheClinic()}
                          className="clinic__links__box__active__aux__description__button"
                        >
                          {translate('COMPONENTS.CLINIC.DESCRIPTION.BUTTON')}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div className={`clinic__questions${selectedLink == 1 ? '__active' : ''}`}>
            {selectedLink == 3 || selectedLink == 2 ? (
              <h3 className="clinic__questions__title-two">
                {translate('COMPONENTS.CLINIC.LINKS.TWO')}
                <img src={IconClinic} />
              </h3>
            ) : (
              <h3 className={`clinic__questions${selectedLink == 1 ? '__active' : ''}__title`}>
                {translate('COMPONENTS.CLINIC.LINKS.TWO')}
              </h3>
            )}
            {selectedLink != 1 ? (
              <h3 className="clinic__questions__title-two"></h3>
            ) : (
              <div className="clinic__questions">
                <div className="clinic__questions__aux">
                  <Steps current={current}>
                    {steps.map(item => (
                      <Step
                        key={item.title}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                  <div className="steps-content">{steps[current].content}</div>
                  <div className="steps-action">
                    {/* {current > 0 && (
                        <Button className="steps-action__previous" style={{ margin: '0 8px' }} onClick={() => prev()}>
                          <img src={ArrowClinicLeft} />
                          {translate('COMPONENTS.CLINIC.QUESTIONS.PREVIOUS')}
                        </Button>
                      )} */}
                    {current < steps.length && (
                      <Button
                        className="steps-action__next"
                        type="primary"
                        onClick={(e) => addQuestion(e)}
                      >
                        {translate('COMPONENTS.CLINIC.QUESTIONS.NEXT')}
                        <img
                          src={ArrowClinicRight}
                          alt=""
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`clinic__questions${selectedLink == 2 ? '__active' : ''}`}>
            {selectedLink == 3 ? (
              <h3 className="clinic__questions__title-two">
                {translate('COMPONENTS.CLINIC.LINKS.THREE')}
                <img src={IconClinic} />
              </h3>
            ) : (
              <h3 className={`clinic__questions${selectedLink == 2 ? '__active' : ''}__title`}>
                {translate('COMPONENTS.CLINIC.LINKS.THREE')}
              </h3>
            )}
            {selectedLink == 2 ? (

              <div className="clinic__questions__active__box">
                <p className="clinic__questions__active__box__description">
                  {translate('COMPONENTS.CLINIC.PICTURE.DESCRIPTION')}
                </p>
                <p className="clinic__questions__active__box__description-two">
                  {translate('COMPONENTS.CLINIC.PICTURE.DESCRIPTION_TWO')}
                </p>
                <div className="clinic__questions__active__box__form">

                  <div className="clinic__questions__active__box__form__aux">
                    <h3 className="clinic__questions__active__box__form__aux__title">
                      {translate('COMPONENTS.CLINIC.PICTURE.TITLE_ONE')}
                    </h3>
                    {visibleWebCam === true ? (
                      WebcamComponent()
                    ) : (
                      window.innerWidth <= 1100 ? (
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={onPreview}
                          customRequest={(ev) => onChange(ev, setFileList)}
                          accept="image/*"
                        />
                      ) : (
                        <ImgCrop
                          rotate
                          zoom
                          modalTitle="Editar arquivo"
                          modalOk="Utilizar foto"
                          modalCancel="Cancelar"
                          grid
                        >
                          <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={onPreview}
                            customRequest={(ev) => onChange(ev, setFileList)}
                            accept="image/*"
                          >
                            <img
                              src={IconCamera}
                              alt=""
                            />
                            {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                          </Upload>
                        </ImgCrop>
                      )
                    )}
                    <div>
                      <Button
                        className="capture-cam-two"
                        onClick={() => setVisibleWebCam(!visibleWebCam)}
                      >
                        {!visibleWebCam ? translate('COMPONENTS.CLINIC.PICTURE.CAM') : 'CANCELAR'}
                      </Button>
                    </div>
                  </div>

                  <div className="clinic__questions__active__box__form__aux">
                    <h3 className="clinic__questions__active__box__form__aux__title clinic__questions__active__box__form__aux__title__item">
                      {translate('COMPONENTS.CLINIC.PICTURE.TITLE_TWO')}
                    </h3>
                    {visibleWebCam2 === true ? (
                      WebcamComponent2()
                    ) : (
                      window.innerWidth <= 1100 ? (
                        <Upload
                          listType="picture-card"
                          fileList={fileListTwo}
                          onPreview={onPreview}
                          customRequest={(ev) => onChange(ev, setFileListTwo)}
                          accept="image/*"
                        />
                      ) : (
                        <ImgCrop
                          rotate
                          zoom
                          modalTitle="Editar arquivo"
                          modalOk="Utilizar foto"
                          modalCancel="Cancelar"
                          grid
                        >
                          <Upload
                            listType="picture-card"
                            fileList={fileListTwo}
                            onPreview={onPreview}
                            customRequest={(ev) => onChange(ev, setFileListTwo)}
                            accept="image/*"
                          >
                            <img
                              src={IconCamera}
                              alt=""
                            />
                            {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                          </Upload>
                        </ImgCrop>
                      )
                    )}
                    <div>
                      <Button
                        className="capture-cam-two"
                        onClick={() => setVisibleWebCam2(!visibleWebCam2)}
                      >
                        {!visibleWebCam2 ? translate('COMPONENTS.CLINIC.PICTURE.CAM') : 'CANCELAR'}
                      </Button>
                    </div>
                  </div>
                  <div className="clinic__questions__active__box__form__aux">
                    <h3 className="clinic__questions__active__box__form__aux__title clinic__questions__active__box__form__aux__title__item">
                      {translate('COMPONENTS.CLINIC.PICTURE.TITLE_THREE')}
                    </h3>
                    {visibleWebCam3 === true ? (
                      WebcamComponent3()
                    ) : (
                      window.innerWidth <= 1100 ? (
                        <Upload
                          listType="picture-card"
                          fileList={fileListThree}
                          onPreview={onPreview}
                          customRequest={(ev) => onChange(ev, setFileListThree)}
                          accept="image/*"
                        />
                      ) : (
                        <ImgCrop
                          rotate
                          zoom
                          modalTitle="Editar arquivo"
                          modalOk="Utilizar foto"
                          modalCancel="Cancelar"
                          grid
                        >
                          <Upload
                            listType="picture-card"
                            fileList={fileListThree}
                            onPreview={onPreview}
                            customRequest={(ev) => onChange(ev, setFileListThree)}
                            accept="image/*"
                          >
                            <img
                              src={IconCamera}
                              alt=""
                            />
                            {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                          </Upload>
                        </ImgCrop>
                      )
                    )}
                    <div>
                      <Button
                        className="capture-cam-two"
                        onClick={() => setVisibleWebCam3(!visibleWebCam3)}
                      >
                        {!visibleWebCam3 ? translate('COMPONENTS.CLINIC.PICTURE.CAM') : 'CANCELAR'}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="clinic__questions__active__box__next clinic__questions__active__box__next__btn">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <WhiteButton
                          onClick={() => {
                            setSelectedLink(3);
                            setDataLayer('medical_evaluation_foto_send');
                          }}
                          label={translate('COMPONENTS.CLINIC.PICTURE.SEND')}
                          disabled={isButtonDisabled()}
                          full
                          dark
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className={`clinic__payment${selectedLink == 3 ? '__active' : ''}`}>
            {selectedLink == 4 ? (
              <h3 className="clinic__payment__title-two">
                {translate('COMPONENTS.CLINIC.LINKS.FOUR')}
                <img src={IconClinic} />
              </h3>
            ) : (
              <h3 className={`clinic__payment${selectedLink == 3 ? '__active' : ''}__title`}>
                {translate('COMPONENTS.CLINIC.LINKS.FOUR')}
              </h3>
            )}
            {selectedLink == 3 ? (
              <div className="clinic__payment__active__box">
                <div className="clinic__payment__active__box__aux">
                  <div className="clinic__payment__active__box__aux__form">
                    <WhiteButton
                      onClick={() => {
                        createQuizValidateAndEvolutionPhotos();
                      }}
                      label={translate('COMPONENTS.CLINIC.PAYMENT.BUTTON')}
                      disabled={loading > 0}
                      full
                      dark
                    />
                    <Spin
                      className="clinic__payment__active__box__aux__form__spin"
                      spinning={loading === 1}
                      indicator={antIcon}
                    />
                  </div>
                </div>
                <div className="clinic__payment__active__box__aux">
                  <h2 className="clinic__payment__active__box__aux__title">
                    {translate('COMPONENTS.CLINIC.TITLE_BUY')}
                    <img src={IconBuy} />
                  </h2>
                  {selectedLink === 3 ? (
                    <div className="clinic__payment__active__box__aux__cart">
                      <h3 className="clinic__payment__active__box__aux__cart__title">
                        {translate('COMPONENTS.CLINIC.CART.TITLE')}
                      </h3>
                      <div className="clinic__payment__active__box__aux__cart__aux">
                        <p className="clinic__payment__active__box__aux__cart__aux__item">
                          {translate('COMPONENTS.CLINIC.CART.ITEM')}
                        </p>
                        <p className="clinic__payment__active__box__aux__cart__aux__value">
                          {translate('COMPONENTS.CLINIC.CART.VALUE')}
                        </p>
                      </div>
                      <div className="clinic__payment__active__box__aux__cart__aux">
                        <p className="clinic__payment__active__box__aux__cart__aux__item">
                          {translate('COMPONENTS.CLINIC.CART.ITEM_TWO')}
                        </p>
                        <p className="clinic__payment__active__box__aux__cart__aux__value">
                          {translate('COMPONENTS.CLINIC.CART.VALUE_TWO')}
                        </p>
                      </div>
                      <div className="clinic__payment__active__box__aux__cart__aux">
                        <p className="clinic__payment__active__box__aux__cart__aux__total">
                          {translate('COMPONENTS.CLINIC.CART.TOTAL')}
                        </p>
                        <p className="clinic__payment__active__box__aux__cart__aux__value-total">
                          {translate('COMPONENTS.CLINIC.CART.VALUE_TOTAL')}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="clinic__footer" />
      </div>
      <>
        <Modal
          title="Basic Modal"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img src={imageModal} />
        </Modal>
      </>
    </form>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  createQuiz: (quiz) => dispatch(QuizActions.createQuiz(quiz)),
  uploadImageBase64: (photoDataBase64) => dispatch(UtilsActions.uploadImageBase64(photoDataBase64)),
  createEvolution: (data) => dispatch(EvolutionActions.createEvolution(data)),
  createMedicalAppointment: (data) => dispatch(MedicalAppointmentActions.createMedicalAppointment(data)),
  setFromPrescriptionPayment: (params) => dispatch(PrescriptionActions.setFromPrescriptionPayment(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clinic);

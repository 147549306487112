import React from 'react';
import { Container } from 'react-bootstrap';
import { translate } from '../../../services/i18n';
import { ReactComponent as Icon } from '../../../assets/svg/whatsapp_ic.svg';

const ProfileHelp = () => {

  return (
    <div className="help-profile">
      <Container>
        <div className='help-profile__title'>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TITLE')}</div>
        <div>
          <div className="help-profile__item">
            <div className="help-profile__item__box">
              <div className='help-profile__item__box__text'>
                {translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TEXT')}<strong>não existe nada mais importante que o cliente </strong>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TEXT_2')}
              </div>
              <div className='help-profile__item__box__subtitle'>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.SUBTITLE')}</div>
              <a
                href="https://api.whatsapp.com/send?phone=5511942120797"
                target="_blank"
                rel="noopener noreferrer"
                className="help-profile__item__box__button"
              >
                <Icon style={{ width: '15px', height: '15px' }} />{translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TALK')}
              </a>
            </div>
          </div>
          <div className="help__address">
            <img src="/assets/svg/house.svg"
              className="help__address__icon"
              alt="address" />
            <div className="help__address__text">
              Alameda dos Tupiniquins 414, Planalto Paulista - São Paulo/SP - CNPJ: 40.890.511/0001-40
            </div>
          </div>
        </div>
      </Container >
    </div>
  );
};

export default ProfileHelp;
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

function AdvancedMessage (props) {
  const [ active, setActive ] = useState(false);

  useEffect(() => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 3000);
  },[]);

  return (
    <div className={`advanced-message ${active && 'advanced-message--active'}`}>
      {props.icon && (
        <div className="advanced-message__icon">
          {props.icon}
        </div>
      )}
      <div className="advanced-message__message">
        {props.message || ''}
      </div>
      <div onClick={() => setActive(false)}
        className="advanced-message__close">
        <CloseOutlined />
      </div>
    </div>
  );
}

export default AdvancedMessage;
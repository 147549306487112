import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import * as Analytics from '../../services/analytics';

import Login from './Login/Login';
import PasswordRecovery from './PasswordRecovery/PasswordRecovery';
import { translate } from '../../services/i18n';
import PageContent from '../../components/PageContent/PageContent';
import BottomPanel from '../../components/BottomPanel/BottomPanel';
import AuthService from '../../services/auth';
import * as AuthActions from '../../redux/actions/auth';
import { connect } from 'react-redux';

class AuthNavigationStack extends React.PureComponent {
  constructor (props) {
    super(props);

    if (process.env.NODE_ENV === 'production') {
      Analytics.webPageView('/');
    }
  }

  componentDidMount () {
    const { checkLogged } = this.props;
    checkLogged(AuthService.isAuthenticated());
  }

  render () {
    return (
      <Switch>
        <Route
          path={translate('PAGES.AUTH.LOGIN.URL')}
          exact
        >
          <PageContent>
            <Login />
            <BottomPanel />
          </PageContent>
        </Route>

        <Route path={translate('PAGES.AUTH.PASSWORD_RECOVERY.URL')}>
          <PageContent>
            <PasswordRecovery />
            <BottomPanel />
          </PageContent>
        </Route>
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkLogged: (payload) => dispatch(AuthActions.checkLogged(payload)),
});

export default connect(null, mapDispatchToProps)(AuthNavigationStack);

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import AuthService from '../../../services/auth';
import { ReactComponent as Icon } from '../../../assets/svg/whatsapp_ic.svg';
import { RecurrenceActions } from '../../../redux/actions';
import { cepMask } from '../../../utils/masks';
import { getProductLatestImage } from '../../../utils/string';
import { products } from '../../../services/product';
import moment from 'moment';
import { Collapse, Space } from 'antd';
import { translate } from '../../../services/i18n';


const ProfileSignature = ({
  getRecurrence,
  recurrence,
}) => {


  const { Panel } = Collapse;

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    getRecurrence();
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="signature-profile">
        <Container>
          <div className='signature-profile__title'>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.TITLE')}</div>
          <Space direction="vertical">
            {recurrence ? (
              (recurrence.transactions.map((item, i) => (
                <Collapse
                  key={i}
                  accordion
                  className='collapse-request'
                >

                  <Panel
                    className='collapse-request__panel'
                    header={`Pedido Nº ${item.code}`}
                  >
                    <div className="signature-profile__item">
                      <div className="signature-profile__item__box">
                        <div className='signature-profile__item__box__title'>Saúde Capilar</div>
                        <hr />
                        <div className='signature-profile__aux'>
                          <div className='signature-profile__aux__requests'>
                            {item.transactionItems.map((itemTransaction) => {
                              const product = products.find((o) => o.id === itemTransaction.identifier);

                              if (product) {
                                return (
                                  <p
                                    key={itemTransaction.identifier}
                                    className="signature-profile__aux__requests__product"
                                  >
                                    <img
                                      className="signature-profile__content__first__img"
                                      src={`${getProductLatestImage(product)}`}
                                      alt=""
                                    />
                                    {product.name}
                                  </p>
                                );
                              }

                              return null;
                            })}

                          </div>
                          <div className='signature-profile__aux__requests'>
                            <div className='signature-profile__aux__requests__aux'>
                              <h3 className='signature-profile__aux__requests__title'>
                                {translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.STATUS')}
                              </h3>
                              {recurrence.status === 1 ? (
                                <p className='signature-profile__aux__requests__text__active'>Ativa</p>
                              ) : (
                                <p className='signature-profile__aux__requests__text__disabled'>Desativado</p>
                              )}
                            </div>
                            <div className='signature-profile__aux__requests__aux'>
                              <h3 className='signature-profile__aux__requests__title'>
                                {translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.NEXT_REQUEST')}
                              </h3>
                              <p className='signature-profile__aux__requests__text'>
                                {moment(item.lastRenewAt).add(30, 'days').format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className='signature-profile__aux__requests__aux'>
                              <h3 className='signature-profile__aux__requests__title'>
                                {translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.ADDRESS')}
                              </h3>
                              <p className='signature-profile__aux__requests__text'>
                                {`${item.street} ${item.addressNumber} 
                        ${item.neighborhood}
                        ${item.city}/ ${item.uf} 
                        ${cepMask(item.zipcode || '')}`}
                              </p>
                            </div>
                            <div className='signature-profile__aux__requests__aux'>
                              <p className='signature-profile__aux__requests__text'>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.DOUBT')}</p>
                              <p>
                                <a
                                  href="https://api.whatsapp.com/send?phone=5511942120797"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="signature-profile__button"
                                >
                                  <Icon style={{ width: '15px', height: '15px' }} />{translate('PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TALK')}
                                </a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              )))
            ) : (
              <p className='request-profile__aux__no-request'>{translate('PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.NO_SIGNATURE')}</p>
            )}
          </Space>
        </Container >
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  recurrence: state.recurrence.recurrence,
});

const mapDispatchToProps = (dispatch) => ({
  getRecurrence: () => dispatch(RecurrenceActions.getRecurrence())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSignature);

import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import { translate } from '../../services/i18n';
import PageContent from '../../components/PageContent/PageContent';
import PageContentClinic from '../../components/PageContentClinic/PageContentClinic';
import MoneyBack from './MoneyBack/MoneyBack';
import Home from './Home/Home';
import Register from './Register/Register';
import Help from './Help/Help';
import About from './About/About';
import Product from './Product/Product';
import Approved from './Approved/Approved';
import Prescription from './Prescription/Prescription';
import Search from './Search/Search';
import Clinic from './Clinic/Clinic';
import ClinicSuccess from './ClinicSuccess/ClinicSuccess';
import SleepGummy from './SleepGummy/SleepGummy';
import * as AuthActions from '../../redux/actions/auth';
import AuthService from '../../services/auth';
import { connect } from 'react-redux';
import * as ActionsCart from '../../redux/actions/cart';
import Subscription from './Subscription/Subscription';
import { Redirect } from 'react-router';
import { CheckOutlined } from '@ant-design/icons';
import LpSleepGummy from './LpSleepGummy/LpSleepGummy';
import { CartSymbol } from '../../enum/cartSymbol';
import { ProductModalActions } from '../../redux/actions';
import AdvancedMessage from './../../components/AdvancedMessage/AdvancedMessage';
import { validateMobile } from '../../utils/validateMobile';

class HomeNavigationStack extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      newsletterModalVisible: false,
      messageVisible: false,
    };
  }

  componentDidMount () {
    const { checkLogged } = this.props;
    const isAuth = AuthService.isAuthenticated();
    checkLogged(isAuth);

    let shown = false;

    document.addEventListener('mouseleave', (event) => {
      if (event.clientY <= 0
        || event.clientX <= 0
        || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
        if (!isAuth && !shown && window.location.pathname === '/') {
          this.setState({ newsletterModalVisible: true });
          shown = true;
        }
      }
    });
  }

  message = () => {
    this.setState({ messageVisible: true });
    setTimeout(() => {
      this.setState({ messageVisible: false });
    }, 5000);
  }

  render () {
    return (
      <div className="home-navigation-stack">
        {this.state.messageVisible && validateMobile(window.screen.width) && (<AdvancedMessage message={'Produto adicionado à sacola'}
          icon={<CheckOutlined />} />)}
        <div className={`home-navigation-stack  ${this.props.isVisible && 'home-navigation-stack--visible'}`}>
          <Switch>
            <Route
              path={translate('PAGES.HOME.HAIR_KIT.URL')}
            >
              <Redirect to="/assinatura" />
            </Route>
            <Route
              path={translate('PAGES.HOME.URL')}
              exact
            >
              <PageContent
                pageTitle="Um novo jeito de se cuidar"
                changeOpacity={true}
              >
                <Home message={() => this.message()}/>
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.REGISTER.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.REGISTER.PAGE_TITLE')}
              >
                <Register />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.MONEY_BACK.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.MONEY_BACK.PAGE_TITLE')}
              >
                <MoneyBack />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.SLEEP_GUMMY.URL')}
            >
              <PageContent
                removeHeader={true}
                pageTitle={translate('PAGES.HOME.SLEEP_GUMMY.PAGE_TITLE')}
              >
                <SleepGummy />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.SLEEP_GUMMY_TWO.URL')}
            >
              <PageContent
                removeHeader={true}
                pageTitle={translate('PAGES.HOME.SLEEP_GUMMY_TWO.PAGE_TITLE')}
              >
                <SleepGummy redirectToCart />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.LP_SLEEP_GUMMY.URL')}
            >
              <PageContent
                removeHeader={true}
                pageTitle={translate('PAGES.HOME.LP_SLEEP_GUMMY.PAGE_TITLE')}
              >
                <LpSleepGummy/>
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.SUBSCRIPTION.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.SUBSCRIPTION.PAGE_TITLE')}
              >
                <Subscription />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.HELP.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.HELP.PAGE_TITLE')}
              >
                <Help />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.ABOUT.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.ABOUT.PAGE_TITLE')}
              >
                <About />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.PRODUCT.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.PRODUCT.PAGE_TITLE')}
              >
                <Product message={() => this.message()}/>
              </PageContent>
            </Route>
            {/*<Route
              path={translate('PAGES.HOME.CART.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.CART.PAGE_TITLE')}
              >
                <Cart />
              </PageContent>
            </Route>*/}
            <Route
              path={translate('PAGES.HOME.APPROVED.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.APPROVED.PAGE_TITLE')}
              >
                <Approved />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.PRESCRIPTION.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.PRESCRIPTION.PAGE_TITLE')}
              >
                <Prescription />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.RECURRENCE.URL')}
            >
              <Redirect to="/assinatura" />
            </Route>
            <Route
              path={translate('PAGES.HOME.SEARCH.URL')}
            >
              <PageContent
                pageTitle={translate('PAGES.HOME.SEARCH.PAGE_TITLE')}
              >
                <Search />
              </PageContent>
            </Route>
            <Route
              path={translate('PAGES.HOME.CLINIC.URL')}
            >
              <PageContentClinic
                pageTitle={translate('PAGES.HOME.CLINIC.PAGE_TITLE')}
              >
                <Clinic />
              </PageContentClinic>
            </Route>
            <Route
              path={translate('PAGES.HOME.CLINIC_SUCCESS.URL')}
            >
              <PageContentClinic
                pageTitle={translate('PAGES.HOME.CLINIC_SUCCESS.PAGE_TITLE')}
              >
                <ClinicSuccess />
              </PageContentClinic>
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVisible: state.productModal
});

const mapDispatchToProps = (dispatch) => ({
  checkLogged: (payload) => dispatch(AuthActions.checkLogged(payload)),
  addProductsInCart: (id, quantity) => dispatch(ActionsCart.addOrRemoveProduct(id, quantity, CartSymbol.ADD)),
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
  setInvisibleModal: () => dispatch(ProductModalActions.hideModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavigationStack);

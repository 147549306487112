import { UserActions } from '../actions';

const initialState = {
  token: {
    accessToken: null,
    refreshToken: null,
  },
  me: null,
  document: null,
};

const userReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case UserActions.ACTION_USER_REGISTER:
      state = {
        ...state,
        token: action.payload,
      };
      break;
    case UserActions.ACTION_ENABLE_TWO_FACTOR:
      state = {
        ...state,
        document: action.payload,
      };
      break;
    default:
      return state;
  }

  return state;
};


export default userReducer;

import Url from '../config/api.config';

function getBaseUrl () {
  const { hostname } = window.location;
  let apiUrl;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
    case '127.0.0.1':
      apiUrl = Url.localhost;
      break;
    case 'dev.themens.com.br':
    case 'www.dev.themens.com.br':
    case 'hml.consultoriomedico.digital':
      apiUrl = Url.development;
      break;
    case 'themens.com.br':
    case 'www.themens.com.br':
    case 'consultoriomedico.digital':
    case 'www.consultoriomedico.digital':
      apiUrl = Url.production;
      break;
    default:
      apiUrl = Url.production;
  }

  return apiUrl;
}

function getBaseUrlAuth () {
  return Url.auth;
}

function getAuthToken () {
  const { hostname } = window.location;
  let key;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
    case '127.0.0.1':
      key = Url.authApiKeyDev;
      break;
    case 'dev.themens.com.br':
    case 'hml.consultoriomedico.digital':
      key = Url.authApiKeyDev;
      break;
    case 'themens.com.br':
    case 'www.themens.com.br':
    case 'consultoriomedico.digital':
    case 'www.consultoriomedico.digital':
      key = Url.authApiKeyProd;
      break;
    default:
      key = Url.authApiKeyProd;
  }

  return key;
}

export const handleAxiosError = (err) => {
  if (err.response && err.response.status === 400 && err.response.data && err.response.data.error) {
    throw new Error(err.response.data.error);
  }

  if (err.response && err.response.status === 401) {
    throw new Error('UNAUTHORIZED');
  }

  if (err.response && err.response.status === 403) {
    throw new Error('FORBIDEN');
  }

  if (err.response && err.response.status === 404) {
    throw new Error('NOT_FOUND');
  }

  if (err.response && err.response.status === 500) {
    throw new Error('GENERIC');
  }

  if (err.code && err.code === 'ENCONNABORTERD') {
    throw new Error('TIMEOUT');
  }


  const error = {
    url: err.config && err.config.url,
    method: err.config && err.config.method,
    headers: err.config && err.config.headers,
    data: err.config && err.config.data,
    response: null,
    status: null,
    message: err.message,
  };

  if (err.response) {
    error.response = err.response.data;
    error.status = err.response.status;
  }

  return error;
};

export default {
  getBaseUrl,
  getBaseUrlAuth,
  getAuthToken,
};

import React, { useEffect, useMemo } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { chocosonoKit, getCartDiscountValue, hairKitCart, prescriptionNeeded } from '../../utils/string';
import CheckoutItemCard from '../CheckoutItemCard/CheckoutItemCard';
import { CartActions } from '../../redux/actions';
import { CartSymbol } from '../../enum/cartSymbol';
import { connect } from 'react-redux';
import { toCurrencyLocale } from '../../utils/masks';
import { Col } from 'antd';
import { useLocation } from 'react-router';
import { I18n } from 'react-redux-i18n';
import { hasChocosono } from '../../services/product';
import ProductCheckoutPayment from '../../components/ProductCheckoutPayment/ProductCheckoutPayment';
import { Row } from 'react-bootstrap';
import IcLock from '../../assets/svg/ic_lock.svg';
import IcCartSummary from '../../assets/png/ic_summary_cart.png';
import IcLaboratory from '../../assets/svg/ic_laboratory.svg';
import IcPostalService from '../../assets/svg/ic_postal_service.svg';
import { translate } from '../../services/i18n';

const CheckoutCart = ({
  products,
  addProductToCart,
  removeProductFromCart,
  recurrent,
  changeRecurrent,
  calculateTheCart,
  getInstalmentOptions,
  cleanTheCart,
  freight,
  coupon,
  paymentData,
  values,
  finished,
  padded,
  onNextClick,
  nextButtonStyle,
  debounce
}) => {
  useEffect(() => {
    if (!hairKitCart(products) && !chocosonoKit(products)) {
      changeRecurrent(false);
    }
    // eslint-disable-next-line
  }, [products]);

  const onChangeRecurrentToggle = async (value) => {
    await changeRecurrent(!hairKitCart(products) && !chocosonoKit(products) ? false : value);
    await calculateTheCart();
    await getInstalmentOptions();
  };

  const { pathname } = useLocation();

  const invisiblePromotionCheckout = useMemo(() => {
    return hasChocosono(products);
  }, [ products ]);

  const showProductPromotion = () => {
    return !invisiblePromotionCheckout && window.location.pathname === '/checkout/pagamento';
  };

  return (
    <div className={`checkout-cart ${finished && 'checkout-cart--finished'}`}>

      {products && products.length > 0 && (
        <div style={{ paddingTop: padded }}>
          <Row className="checkout-cart__purchase-overview">
            <Col>
              <span className="checkout-cart__purchase-overview__summary-text">
                {translate('CHECKOUT.STEP_ONE.PURCHASE_OVERVIEW').toUpperCase()}{' '}
                <img
                  src={IcCartSummary}
                  alt='Ícone carrinho de compras'
                />
              </span>
            </Col>
            <Col>
              <span className="checkout-cart__purchase-overview__security-text mobile-hidden">
                <img
                  src={IcLock}
                  alt='Ícone cadeado'
                />
                {translate('CHECKOUT.STEP_ONE.PURCHASE_SECURITY').toUpperCase()}{' '}
              </span>
              <span className="checkout-cart__purchase-overview__security-text mobile-visible">
                {translate('CHECKOUT.STEP_ONE.PURCHASE_SECURITY').toUpperCase()}{' '}
                <img
                  src={IcLock}
                  alt='Ícone cadeado'
                />
              </span>
            </Col>
          </Row>

          <div className="checkout-cart__inner">
            {products.filter(product => product.showCart).map((product, productKey) => (
              <CheckoutItemCard
                last={productKey === (products.length - 1)}
                key={productKey.toString()}
                product={product}
                addProductToCart={(id) => addProductToCart(id)}
                removeProductFromCart={(id) => removeProductFromCart(id)}
                deleteProductFromCart={(id) => removeProductFromCart(id, product.quantity)}
                disabled={finished}
                productsLength={products.length}
              />
            ))}

            {hairKitCart(products) && products.length > 0 && (
              <div className={`checkout__recurrent ${finished && !recurrent && 'checkout__recurrent__label--disabled'}`}>
                <div className="checkout__recurrent__container">
                  <div className="checkout__recurrent__container__input">
                    <input
                      id="recurrent_radio"
                      type="radio"
                      onClick={() => onChangeRecurrentToggle(!recurrent)}
                      checked={recurrent} />
                  </div>
                  <div className="checkout__recurrent__container__text">
                    <div className="checkout__recurrent__container__text__label">
                      <>
                        <h3 className="checkout__recurrent__container__text__label__title">
                          Quero assinar mensalmente e ganhar 15% de desconto neste hair kit
                        </h3>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="/assinatura"
                          className="checkout__recurrent__container__text__label__link"
                        >
                          Saiba mais
                        </a>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {chocosonoKit(products) && products.length > 0 && (
              <div className={`checkout__recurrent ${finished && !recurrent && 'checkout__recurrent__label--disabled'}`}>
                <div className="checkout__recurrent__container">
                  <div className="checkout__recurrent__container__input">
                    <input
                      id="recurrent_radio"
                      type="radio"
                      onClick={() => onChangeRecurrentToggle(!recurrent)}
                      checked={recurrent} />
                  </div>
                  <div className="checkout__recurrent__container__text">
                    <div className="checkout__recurrent__container__text__label">
                      <>
                        <h3 className="checkout__recurrent__container__text__label__title checkout__recurrent__container__text__label__title--space-top">
                          Assine, receba mensalmente e ganhe 15% de desconto
                        </h3>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {products && products.length > 0 && (
              <div>
                <div className="checkout-floating-bar__meta">
                  {showProductPromotion() && (
                    <ProductCheckoutPayment
                      product={'de6acabf-8bc1-4a63-bcb5-213c043cef30'}
                    />
                  )}
                  <div className="checkout-floating-bar__meta__value-single__subtotal">
                    <span className="checkout-floating-bar__meta__value-single__subtotal__title">
                      Subtotal:&nbsp;
                    </span>
                    <span className="checkout-floating-bar__meta__value-single__subtotal__value">
                      {toCurrencyLocale(values?.subTotal + getCartDiscountValue(products))}
                    </span>
                  </div>

                  {getCartDiscountValue(products) > 0 && (
                    <div className="checkout-floating-bar__meta__value-single">
                      <span className="checkout-floating-bar__meta__value-single__title">
                        Desconto do produto:&nbsp;
                      </span>
                      <span className="checkout-floating-bar__meta__value-single__value__text-green">
                        -{toCurrencyLocale(getCartDiscountValue(products))}
                      </span>
                    </div>
                  )}

                  {!!values?.discountValue && ((
                    <div className="checkout-floating-bar__meta__value-single">
                      <span className="checkout-floating-bar__meta__value-single__title">
                        Desconto do cupom:&nbsp;
                      </span>
                      <span className="checkout-floating-bar__meta__value-single__value__text-green">
                        -{toCurrencyLocale(values?.discountValue)}
                      </span>
                    </div>
                  ))}

                  {!recurrent && freight && !pathname.includes('/checkout/revisao') && (
                    <div className="checkout-floating-bar__meta__value-single">
                      <span className="checkout-floating-bar__meta__value-single__title">
                        Frete:&nbsp;
                      </span>
                      <span className="checkout-floating-bar__meta__value-single__value">
                        {freight.freight && parseFloat(freight.freight) > 0 && values?.total > 0 ? toCurrencyLocale(freight.freight) :
                          <strong className="checkout-floating-bar__meta__value-single__value__text-green">FRETE GRÁTIS!</strong>}
                      </span>
                    </div>
                  )}

                  {recurrent && (
                    <div className="checkout-floating-bar__meta__value-single">
                      <span className="checkout-floating-bar__meta__value-single__title">
                        Frete:&nbsp;
                      </span>
                      <span className="checkout-floating-bar__meta__value-single__value">
                        <strong className="checkout-floating-bar__meta__value-single__value__text-green">FRETE GRÁTIS!</strong>
                      </span>
                    </div>
                  )}

                  {values && (
                    <div className="checkout-floating-bar__meta__value-single checkout-floating-bar__meta__value-single--total">
                      <span className="checkout-floating-bar__meta__value-single__title">
                        TOTAL:&nbsp;
                      </span>
                      <span className="checkout-floating-bar__meta__value-single__value checkout-floating-bar__meta__value-single--total--value">
                        R${parseInt(values.total)}
                        <span className="checkout-floating-bar__meta__value-single--total--value__small">
                          ,{values.total > 0 ? values.total.toFixed(2).toString().split('.')[1] : '00'}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {products.length > 0 && prescriptionNeeded(products) && (
            <Row className="checkout-cart__purchase-overview">
              <Col>
                <span className="checkout-cart__purchase-overview__laboratory-text">
                  <img
                    width={24}
                    height={24}
                    src={IcLaboratory}
                    alt='Ícone laboratório'
                  />
                  {' '}{translate('CHECKOUT.STEP_ONE.LABELS.LABORATORY')}
                </span>
              </Col>
              <Col>
                <span className="checkout-cart__purchase-overview__postal-service-text">
                  <img
                    width={32}
                    height={32}
                    src={IcPostalService}
                    alt='Ícone correios'
                  />
                  {' '}{translate('CHECKOUT.STEP_ONE.LABELS.POSTAL_SERVICE')}
                </span>
              </Col>
            </Row>
          )}
          {pathname === '/checkout/pagamento' ? (
            <Col className='mobile-hidden'>
              <div
                className={`checkout--step-four__${nextButtonStyle}`}
                onClick={() => debounce(() => onNextClick())}
              >
                <div className="checkout--step-four__buy-button__item">
                  <span className="checkout--step-four__buy-button__item__text">
                    {I18n.t('CHECKOUT.STEP_FOUR.BUY')}
                  </span>
                </div>
              </div>
            </Col>
          ) : (
            <Col className='mobile-hidden'>
              <div
                className="checkout--step-one__next-button"
                onClick={() => onNextClick()}
              >
                <div className="checkout--step-one__next-button__item">
                  <span className="checkout--step-one__next-button__item__text">
                    {I18n.t('CHECKOUT.STEP_ONE.NEXT')}
                  </span>
                  <span className='checkout--step-one__next-button__item__icon'>
                    <ArrowRightOutlined />
                  </span>
                </div>
              </div>
            </Col>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
  products: state.cart.products || [],
  recurrent: state.cart.recurrent || false,
  freight: state.cart.freight || null,
  coupon: state.cart.coupon || null,
  paymentData: state.cart.paymentData || null,
  values: state.cart.values || null,
});

const mapDispatchToProps = (dispatch) => ({
  addProductToCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.ADD)),
  removeProductFromCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.REMOVE)),
  changeRecurrent: (data) => dispatch(CartActions.changeRecurrent(data)),
  calculateTheCart: () => dispatch(CartActions.calculateTheCart()),
  cleanTheCart: () => dispatch(CartActions.cleanTheCart()),
  getInstalmentOptions: () => dispatch(CartActions.getInstalmentOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCart);

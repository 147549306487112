import getInstance from './instance';

export default class AuthRequests {
  static async auth (parameters) {
    const instance = await getInstance(true);
    const { data } = await instance.post('/api/auth', parameters);
    return data;
  }

  static async refresh (refreshToken) {
    const instance = await getInstance(true);
    const { data } = await instance.post('/api/auth/refresh', { refreshToken });
    return data;
  }

  static async sendRecoveryEmail (params) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/password-recovery', params);
    return data;
  }

  static async passwordRecovery (params) {
    const instance = await getInstance();
    const { data } = await instance.put('/user/password-recovery', params);
    return data;
  }

  static async me () {
    const instance = await getInstance();
    const { data } = await instance.get('/user/me');
    return data;
  }

  static async newsLetter (payload) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/news-letter', payload);
    return data;
  }
}

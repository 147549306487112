import getInstance from './instance';

export default class MedicalAppointmentRequests {
  static async getAppointmentsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/medical-appointment', { params });
    return data;
  }

  static async getAppointmentById (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/medical-appointment/${id}`, { params });
    return data;
  }

  static async updateAppointment (id, medicalAppointmentData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/medical-appointment/${id}`, medicalAppointmentData);
    return data;
  }

  static async createAppointment (medicalAppointmentData) {
    const instance = await getInstance();
    const { data } = await instance.post('/medical-appointment', medicalAppointmentData);
    return data;
  }

  static async getMineAppointment (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/medical-appointment/mine', { params });
    return data;
  }

  static async removeAppointment (id, { params }) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/medical-appointment/${id}`, { params });
    return data;
  }
}
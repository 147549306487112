import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';

const AdvancedButton = ({
  type = 'button',
  size = 'large',
  color = 'primary',
  customColor,
  variant = 'contained',
  text,
  onClick,
  startIcon,
  fullWidth,
  disabled = false,
  loading,
  className,
  customBorderRadius,
  customText,
}) => {
  return (
    <div className="advanced-button">
      <Button
        disableRipple={true}
        size={size}
        color={color}
        variant={variant}
        onClick={onClick && (() => onClick())}
        fullWidth={fullWidth || false}
        type={type}
        disabled={disabled}
        style={customColor && {
          backgroundColor: disabled ? '#e0e0e0' : customColor,
          borderRadius: customBorderRadius,
        }}
        className={className || 'advanced-button'}
      >
        <span className="advanced-button__inner">
          {startIcon && (
            <span className="advanced-button__inner__start-icon">
              {startIcon}
            </span>
          )}
          <span className={customText ? customText : 'advanced-button__inner__text'}>
            {text}
          </span>
          {loading && (
            <div className="advanced-button__inner__loading">
              {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }}
                spin />} /> : null}
            </div>
          )}
        </span>
      </Button>
    </div>
  );
};

export default AdvancedButton;

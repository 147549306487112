import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as TheMensLogo } from '../../../assets/svg/TheMens-logo.svg';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeliveryIcon } from '../../../assets/svg/ic_delivery_icon.svg';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/AdvancedButton/AdvancedButton';
import TrackerOrderSteps from '../../../components/TrackerOrderSteps/TrackerOrderSteps';
import { TrackOrderActions } from '../../../redux/actions';
import { message, Spin } from 'antd';
import { CopyOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { getLastElementFromPath } from '../../../utils/string';
import moment from 'moment';

const TrackOrderScreen = ({ getOrderStatus, orderStatus, loading }) => {
  const [ isVisibleStatusOrder, setIsVisibleStatusOrder ] = useState(false);
  const [ buttonCopyUrlDisabled, setButtonCopyUrlDisabled ] = useState(true);
  const [ orderCode, setOrderCode ] = useState('');
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const isCodeByUrl = path.length === 3;

  useEffect(() => {
    if (isCodeByUrl) {
      const lastElementPath = getLastElementFromPath(pathname);
      setOrderCode(lastElementPath);
      trackOrder();
    }
  },[ orderCode ]);


  const trackOrder = async () => {
    await getOrderStatus({ code: orderCode }, (response) => {
      if (!response.error) {
        setIsVisibleStatusOrder(true);

        if (!response.orderStatus.length) {
          setButtonCopyUrlDisabled(true);
        } else {
          setButtonCopyUrlDisabled(false);
        }
      } else {
        setIsVisibleStatusOrder(false);
        setButtonCopyUrlDisabled(true);
      }
    });
  };

  const copyUrlOrderTracking = () => {
    message.success(I18n.t('PAGES.TRACK_ORDER.SUCCESS_COPY_ORDER_TRACKING'));
    navigator.clipboard.writeText(`${window.location.href}${isCodeByUrl ? '' : `/${orderCode}`}`);
  };

  return (
    <div className="track-order">
      <header className="track-order__header">
        <section className="track-order__header__inner">
          <article className="track-order__header__inner__left">
            <Link to="/">
              <TheMensLogo />
            </Link>
          </article>
          <article>
            <Link
              to="/"
              className="track-order__header__inner__menu__profile"
            >
              <p>
                {I18n.t('PAGES.AUTH.LOGIN.LOGIN')}
              </p>
            </Link>
          </article>
        </section>
      </header>
      <main className="track-order__content">
        <section className="track-order__content__tracker">
          <div className="track-order__content__tracker__inner">
            <article className="track-order__content__tracker__inner__title">
              <h2 className="track-order__content__tracker__inner__title__text">
                {I18n.t('PAGES.TRACK_ORDER.TRACK_ORDER_TEXT')}
              </h2>
            </article>
            <article className="track-order__content__tracker__inner__input">
              <AdvancedInput
                disabled={isCodeByUrl}
                value={orderCode}
                placeholder={I18n.t('PAGES.TRACK_ORDER.TYPE_THE_CODE')}
                inputProps={{
                  style: {
                    borderRadius: '50px',
                  }
                }}
                onChange={(e) => setOrderCode(e.target.value)}
              />
            </article>
            <article>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }}
                spin />}
              spinning={loading === 1}/>
            </article>
            <div className="container-buttons">
              <article className="track-order__content__tracker__inner__button">
                <AdvancedButton
                  text={I18n.t('PAGES.TRACK_ORDER.SEND')}
                  customColor="#1746E0"
                  customBorderRadius="50px"
                  customText="track-order__content__tracker__inner__button__text"
                  startIcon={<DeliveryIcon />}
                  onClick={() => trackOrder()}
                  disabled={!orderCode || loading === 1 || isCodeByUrl}
                />
              </article>
              <article className="track-order__content__tracker__inner__button">
                <AdvancedButton
                  text={I18n.t('PAGES.TRACK_ORDER.COPY_ORDER_TRACKING')}
                  customColor="#1746E0"
                  customBorderRadius="50px"
                  customText="track-order__content__tracker__inner__button__text"
                  onClick={() => copyUrlOrderTracking()}
                  disabled={buttonCopyUrlDisabled || loading === 1}
                  startIcon={<CopyOutlined />}
                />
              </article>
              {isCodeByUrl && (
                <article className="track-order__content__tracker__inner__button">
                  <AdvancedButton
                    text="Nova busca"
                    customColor="#1746E0"
                    customBorderRadius="50px"
                    customText="track-order__content__tracker__inner__button__text"
                    onClick={() => window.location.href = '/rastrear-pedido'}
                    startIcon={<SearchOutlined />}
                  />
                </article>
              )}
            </div>
          </div>
        </section>
        {isVisibleStatusOrder && (
          <section className="track-order__steps">
            <article>
              <h2 className="track-order__content__tracker__inner__title__text">
                {I18n.t('PAGES.TRACK_ORDER.FOLLOW_YOUR_ORDER')}
              </h2>
            </article>
            {orderStatus.expectedDate && (
              <article>
                <p className="track-order__content__tracker__inner__title__delivery-forecast">
                  {I18n.t('PAGES.TRACK_ORDER.DELIVERY_FORECAST')} {moment(orderStatus.expectedDate).format('DD/MM/YYYY')}
                </p>
              </article>
            )}

            <article className="track-order__steps__content">
              <TrackerOrderSteps
                orderDetails={orderStatus && orderStatus.orderStatus}
              />
            </article>
          </section>
        )}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orderStatus: state.statusOrder.statusOrder,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getOrderStatus: (payload, callback) => dispatch(TrackOrderActions.getOrderStatus(payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrderScreen);

import { TrackOrderActions } from '../actions';

const initialState = {
  statusOrder: null,
};

const trackOrderReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TrackOrderActions.ACTION_SAVE_STATUS_RESPONSE:
      state = {
        ...state,
        statusOrder: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default trackOrderReducer;

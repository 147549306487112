import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import medias from '../../../services/medias';
import Slider from 'react-slick';
import { I18n } from 'react-redux-i18n';

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  horizontal: true,
  horizontalSwiping: true,
  autoplay: false,
  centerMode: true,
  centerPadding: '10%'
};

const About = (props) => {

  const sliderRef = useRef();

  return (
    <div className="about">
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <div className="about__banner">
        <div className="about__banner__text">
          <h3 className="about__banner__text__blue">
            {I18n.t('COMPONENTS.ABOUT.INNERTEXT.BANNER.TEXT1')}
          </h3>
          <p className="about__banner__text__sub-text">
            {I18n.t('COMPONENTS.ABOUT.INNERTEXT.BANNER.TEXT2')}
          </p>
        </div>
        <img className="about__banner__img mobile-hidden"
          src="/assets/img/about/banner.png"
          alt="banner"/>
        <img className="about__banner__img mobile-visible"
          src="/assets/img/about/banner-mobile.png"
          alt="banner"/>
      </div>
      <div className="about__info">
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT1')}
        </p>
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT2')}
        </p>
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT3')}
        </p>
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.one')} <strong> {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.two')}</strong>{I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.three')}<br />
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.four')} <br />
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.five')} <br />
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.six')} <br />
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT4.seven')} <br />
        </p>
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT5')}
        </p>
        <p className="about__info__text">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.INFO.TEXT6')}
        </p>
      </div>
      <div className="about__timelapse mobile-hidden">
        <img className="about__timelapse__img"
          src="/assets/img/about/timelapse.png"
          alt="timelapse"/>
      </div>
      <div className="about__timelapse--mobile mobile-visible">
        <img className="about__timelapse__img"
          src="/assets/img/about/timelapse-mobile.png"
          alt="timelapse"/>
      </div>
      <div className="about__media">
        <div className="about__media__title">
          {I18n.t('COMPONENTS.ABOUT.INNERTEXT.MEDIA')}
        </div>
        <div className="about__media__container">
          {medias && medias.length > 0 && medias.map((item, i) =>
            <a href={item.link}
              key={i}
              target="_blank"
              alt={item.text}
              rel="noopener noreferrer">
              <img src={item.img}
                alt=""/>
            </a>
          )}
        </div>
      </div>
      <div className="about__media__tec">
        <img className="about__media__tec__img mobile-hidden"
          src="/assets/img/about/tec.png"
          alt=""/>
        <img className="about__media__tec__img mobile-visible"
          src="/assets/img/about/tec-mobile.png"
          alt=""/>
      </div>
      <div className="about__media__rating mobile-hidden">
        <div className="about__media__rating__arrow about__media__rating__arrow--back">
          <img src="/assets/img/about/back.svg" />
        </div>
        <div className="about__media__rating__slick">
          <img className="about__media__rating__item"
            src="/assets/img/about/dep_1.png"
            alt=""/>
          <img className="about__media__rating__item"
            src="/assets/img/about/dep_2.png"
            alt=""/>
          <img className="about__media__rating__item"
            src="/assets/img/about/dep_3.png"
            alt=""/>
        </div>
        <div className="about__media__rating__arrow about__media__rating__arrow--forward">
          <img src="/assets/img/about/forward.svg" />
        </div>
      </div>
      <div className="about__media__rating about__media__rating--mobile mobile-visible">
        <div className="about__media__rating__slick">
          <Slider ref={sliderRef}
            {...settings}>
            <img className="about__media__rating__item"
              src="/assets/img/about/dep_1.png"
              alt=""/>
            <img className="about__media__rating__item"
              src="/assets/img/about/dep_2.png"
              alt=""/>
            <img className="about__media__rating__item"
              src="/assets/img/about/dep_3.png"
              alt=""/>
          </Slider>
        </div>
      </div>
      <div className="about__end-banner">
        <img className="about__end-banner__img mobile-hidden"
          src="/assets/img/about/end_banner.png" />
        <img className="about__end-banner__img mobile-visible"
          src="/assets/img/about/end_banner-mobile.png" />
      </div>
    </div>
  );
};

export default About;

import React from 'react';
import { toCurrencyLocale } from '../../utils/masks';
import CustomTitle from '../CustomComponents/CustomTitle';
import { I18n } from 'react-redux-i18n';
import CustomText from '../CustomComponents/CustomText';
import CustomButton from '../CustomComponents/CustomButton';

const PromotionCardCheckout = ({
  addProductsInCart,
  name,
  value,
  theme,
  discount,
}) => {

  return (
    <>
      <div className="promotion-card-checkout">
        <CustomTitle
          className={`promotion-card-checkout__title ${theme.color}`}
          title={I18n.t('CHECKOUT.PROMOTION_CARD.TITLE')}
        />
        <CustomText
          className={`promotion-card-checkout__text ${theme.color}`}
          text={`Compre agora a 2ª unidade de ${name} com ${discount || 30}% de desconto, por apenas`}
          customClassName={`promotion-card-checkout__price ${theme.color}`}
          value={toCurrencyLocale(value)}
        />
        <CustomButton
          buttonClassName={`promotion-card-checkout__button ${theme.shadowColor}`}
          label={I18n.t('CHECKOUT.PROMOTION_CARD.BUTTON')}
          onClick={addProductsInCart}
          textClassName="promotion-card-checkout__button__text"
        />
      </div >
    </>
  );
};

export default PromotionCardCheckout;
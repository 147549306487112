import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import CartContent from '../../components/CartContent/CartContent';
import { connect } from 'react-redux';
import PaymentMethod from '../../enum/paymentMethod';
import CheckoutStepOne from './CheckoutStepOne/CheckoutStepOne';
import CheckoutStepTwo from './CheckoutStepTwo/CheckoutStepTwo';
import CheckoutStepThree from './CheckoutStepThree/CheckoutStepThree';
import CheckoutStepFour from './CheckoutStepFour/CheckoutStepFour';
import CheckoutStepFive from './CheckoutStepFive/CheckoutStepFive';

import { CartActions } from '../../redux/actions';

import FloatingButtonWhatsapp from '../../components/FloatingButtonWhatsapp/FloatingButtonWhatsapp';
import MobileCart from '../../components/MobileCart/MobileCart';

const CheckoutNavigationStack = ({
  loading,
  calculateTheCart,
  products
}) => {

  const [ paymentForm, setPaymentForm ] = useState(false);
  const [ paymentFormValue, setPaymentFormValue ] = useState(0);

  const pullData = (data, value) => {
    setPaymentForm(data);
    setPaymentFormValue(value);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    calculateTheCart();
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  return (
    <div>
      <Switch>
        <Route path="/checkout/revisao">
          <CheckoutStepOne />
        </Route>
        <Route path="/checkout/avaliacao">
          <CartContent
            pageTitle="Avaliação médica"
            removeFooter={window.innerWidth <= 1100}
            boxed
            padded
          >
            <CheckoutStepTwo />
          </CartContent>
        </Route>

        <Route path="/checkout/dados-de-entrega">
          <CheckoutStepThree />
        </Route>

        <Route path="/checkout/pagamento">
          <CheckoutStepFour upperPaymentForm={pullData} />
        </Route>


        <Route path="/checkout/resultado-pagamento">
          <CartContent
            pageTitle="Pedido efetuado"
            boxed
            noCart
          >
            <CheckoutStepFive
              isPix={paymentForm && paymentForm.type === PaymentMethod.PIX}
              value={paymentFormValue}
            />
          </CartContent>
        </Route>
      </Switch>

      {products && products.length > 0 && window.innerWidth < 1100 ? (
        <MobileCart />
      ) : (
        <FloatingButtonWhatsapp />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  products: state.cart.products || [],
  freight: state.cart.freight || null,
  coupon: state.cart.coupon || null,
  values: state.cart.values || null,
});

const mapDispatchToProps = (dispatch) => ({
  calculateTheCart: () => dispatch(CartActions.calculateTheCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutNavigationStack);

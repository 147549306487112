import { ArrowRightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PasswordType from '../../enum/passwordType';
import { UserActions } from '../../redux/actions';
import { translate } from '../../services/i18n';
import AdvancedInputPassword from '../AdvancedInputPassword/AdvancedInputPassword';

const RecoveryPasswordModal = ({
  setScreenType,
  resetTwoFactors,
  me,
}) => {

  const initialValues = {
    token: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const [ recoveryPasswordForm, setRecoveryPasswordForm ] = useState(initialValues);

  const handleInput = (key, value) => {
    setRecoveryPasswordForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onResetTwoFactor = () => {
    if (recoveryPasswordForm.token.trim() === '') {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.INVALID_TOKEN'));
    } else if (recoveryPasswordForm.newPassword.length < 6) {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.INVALID_LENGHT'));
    } else if (recoveryPasswordForm.newPassword.trim() === '') {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.INVALID_NEW_PASSWORD'));
    } else if (recoveryPasswordForm.confirmNewPassword.trim() === '') {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.CONFIRM_PASSSWORD'));
    } else if (recoveryPasswordForm.newPassword !== recoveryPasswordForm.confirmNewPassword) {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.NO_MATCH_PASSWORD'));
    } else {

      const payload = {
        token: recoveryPasswordForm.token,
        twoFactor: recoveryPasswordForm.newPassword,
      };

      resetTwoFactors(me?.id, payload, () => {
        message.success(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.SUCCESS'));
        setScreenType(PasswordType.PASSWORD);
      });
    }
  };

  return (
    <div className="recovery-password">
      <div className="recovery-password__container">
        <div open
          className="recovery-password__container__inner">
          <div className="recovery-password__container__inner__content-title">
            <p className="recovery-password__container__inner__content-title__title">
              {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.TITLE')}
            </p>
          </div>
          <div className="recovery-password__container__inner__description">
            <div className="recovery-password__container__inner__description__content">
              <p className="recovery-password__container__inner__description__content__text">
                {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.TEXT')}<br />
              </p>
            </div>
          </div>
          <div className="recovery-password__container__inner__container-input">
            <div className="recovery-password__container__inner__container-input__content">
              <label className="recovery-password__container__inner__container-input__content__label">
                {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.LABEL.CODE')}
              </label>
              <AdvancedInputPassword
                onChange={(value) => handleInput('token', value)}
                value={recoveryPasswordForm.token}
                className="recovery-password__container__inner__container-input__content__input"
              />
            </div>
          </div>
          <div className="recovery-password__container__inner__container-input">
            <div className="recovery-password__container__inner__container-input__content">
              <label className="recovery-password__container__inner__container-input__content__label">
                {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.LABEL.NEW_PASSWORD')}
              </label>
              <AdvancedInputPassword
                passwordInput
                onChange={(value) => handleInput('newPassword', value)}
                value={recoveryPasswordForm.newPassword}
                type="password"
                className="recovery-password__container__inner__container-input__content__input"
              />
            </div>
          </div>
          <div className="recovery-password__container__inner__container-input">
            <div className="recovery-password__container__inner__container-input__content">
              <label className="recovery-password__container__inner__container-input__content__label">
                {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.LABEL.CONFIRM_NEW_PASSWORD')}
              </label>
              <AdvancedInputPassword
                passwordInput
                onChange={(value) => handleInput('confirmNewPassword', value)}
                value={recoveryPasswordForm.confirmNewPassword}
                type="password"
                className="recovery-password__container__inner__container-input__content__input"
              />
            </div>
          </div>
          <div className="recovery-password__container__inner__container-button__recovery">
            <button
              onClick={onResetTwoFactor}
              className="recovery-password__container__inner__container-button__recovery__button">
              {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.BUTTON')}
              <ArrowRightOutlined />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  resetTwoFactors: (id, payload, callback) => dispatch(UserActions.resetTwoFactor(id, payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPasswordModal);
import React from 'react';
import { connect } from 'react-redux';
import * as AuthActions from '../../redux/actions/auth';
import * as CartActions from '../../redux/actions/cart';
import { Link, useLocation } from 'react-router-dom';

import { translate } from '../../services/i18n';

const ProfileContentHeader = ({
  me,
  logout,
  deleteAllProductsInCart,
}) => {
  const logoutAccount = () => {
    logout();
    deleteAllProductsInCart();
  };

  const { pathname } = useLocation();

  return (
    <div className="profile-content-header">
      <div className="profile-content-header__inner">
        <div className="profile-content-header__inner__item">
          <div className="profile-content-header__inner__item__register">
            <Link
              className={`profile-content-header__inner__item__register__link ${pathname.includes('/perfil/registro') && 'profile-content-header__inner__item__register__link--active'}`}
              to="/perfil/registro"
            >
              {translate('COMPONENTS.PROFILE_CONTENT_HEADER.ITEMS.FOUR.TITLE')}
            </Link>
          </div>
          <div className="profile-content-header__inner__item__register">
            <Link
              className={`profile-content-header__inner__item__register__link ${pathname.includes('/perfil/pedidos') && 'profile-content-header__inner__item__register__link--active'}`}
              to="/perfil/pedidos"
            >
              {translate('COMPONENTS.PROFILE_CONTENT_HEADER.ITEMS.THREE.TITLE')}
            </Link>
          </div>
          <div className="profile-content-header__inner__item__register">
            <Link
              className={`profile-content-header__inner__item__register__link ${pathname.includes('/perfil/assinaturas') && 'profile-content-header__inner__item__register__link--active'}`}
              to="/perfil/assinaturas"
            >
              {translate('COMPONENTS.PROFILE_CONTENT_HEADER.ITEMS.TWO.TITLE')}
            </Link>
          </div>
        </div>
        <div className="profile-content-header__inner__group-low">
          <div className="profile-content-header__inner__item">
            <div className="profile-content-header__inner__item__register">
              <Link
                className={`profile-content-header__inner__item__register__link ${pathname.includes('/perfil/chat') && 'profile-content-header__inner__item__register__link--active'}`}
                to="/perfil/chat"
              >
                {translate('COMPONENTS.PROFILE_CONTENT_HEADER.ITEMS.ONE.TITLE')}
              </Link>
            </div>
            <div className="profile-content-header__inner__item__register">
              <Link
                className={`profile-content-header__inner__item__register__link ${pathname.includes('/perfil/ajuda') && 'profile-content-header__inner__item__register__link--active'}`}
                to="/perfil/ajuda"
              >
                {translate('COMPONENTS.PROFILE_CONTENT_HEADER.ITEMS.SIX.TITLE')}
              </Link>
            </div>
            <div className="profile-content-header__inner__item__register">
              <Link
                className="profile-content-header__inner__item__register__link"
                to="/perfil/registro"
                onClick={logoutAccount}
                style={{
                  cursor: 'pointer'
                }}
              >
                {translate('COMPONENTS.PROFILE_CONTENT_HEADER.LOGOUT')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  deleteAllProductsInCart: () => dispatch(CartActions.deleteAllProductsInCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContentHeader);

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { LightenDarkenColor } from 'lighten-darken-color';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GenderEnum,
  getLastPage,
  getNextPageByGender,
  getPageColor,
  getProgressAmount,
  getQuestionsByType,
  getQuestionTypeName,
  getResponsesByPage,
  QuestionAnswerType,
  QuestionType,
} from '../../../services/prescription';
import WhiteButton from '../../../components/WhiteButton/WhiteButton';
import { ChatMessageActions, PrescriptionActions, TransactionActions, UserActions } from '../../../redux/actions';
import * as UtilsActions from '../../../redux/actions/utils';
import IcNext from '../../../assets/svg/ic_next_medical.svg';
import IcBack from '../../../assets/svg/ic_back_medical.svg';
import * as QuizActions from '../../../redux/actions/quiz';
import {
  CameraOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import * as MedicalAppointmentActions from '../../../redux/actions/medical-appointment';
import AuthService from '../../../services/auth';
import { translate } from '../../../services/i18n';
import { Button, message, Upload } from 'antd';
import Webcam from 'react-webcam';
import EffectOne from '../../../assets/jpg/selfies.svg';
import up from '../../../assets/hair-loss/up.png';
import front from '../../../assets/hair-loss/front.png';
import back from '../../../assets/hair-loss/back.png';
import sadFace from '../../../assets/hair-loss/sad-face.png';
import ImgCrop from 'antd-img-crop';
import IcAttention from '../../../assets/svg/ic_atention.svg';
import { compressImages } from '../../../utils/imageUtils';
import goBack from '../../../assets/medical/go-back.svg';
import { useBeforeunload } from 'react-beforeunload';
import { get, set } from '../../../services/storage';
import { I18n } from 'react-redux-i18n';
import { EvaluationQuestions, HaveAttentionFollowConsultation, HaveNitrateMedication } from '../../../utils/localStorageVariables';
import { MedicalAppointmentMessage, MedicalAppointmentStatus, MedicalAppointmentType } from '../../../enum/medicalAppointment';
import { AlertCard } from '../../../components/AlertCard/AlertCard';

const PrescriptionStep = ({
  page,
  type,
  me,
  quizUserResponse,
  setQuizUserResponse,
  loading,
  updateUserProfile,
  saveImageBase64,
  getUserPrescriptionByType,
  createQuiz,
}) => {
  const [ selectedAnswers, setSelectedAnswers ] = useState(
    getResponsesByPage(page, type, quizUserResponse),
  );
  const history = useHistory();
  const isHaveEvaluationQuestions = localStorage.getItem(EvaluationQuestions) ? JSON.parse(localStorage.getItem(EvaluationQuestions)) : [];
  const isHaveNitrate = localStorage.getItem(HaveNitrateMedication) ? JSON.parse(localStorage.getItem(HaveNitrateMedication)) : false;
  const isHaveAttentionFollowConsultation = localStorage.getItem(HaveAttentionFollowConsultation) ? JSON.parse(localStorage.getItem(HaveAttentionFollowConsultation)) : false;
  const { pathname } = useLocation();
  const [ visibleWebCam, setVisibleWebCam ] = useState(false);
  const [ gender, setGender ] = useState(get('gender'));
  const [ visibleWebCamHairLoss, setVisibleWebCamHairLoss ] = useState(false);
  const [ visibleWebCamHairLoss2, setVisibleWebCamHairLoss2 ] = useState(false);
  const [ visibleWebCamHairLoss3, setVisibleWebCamHairLoss3 ] = useState(false);
  const webcamRef = useRef(null);
  const webcamRefHairLoss = useRef(null);
  const webcamRefHairLoss2 = useRef(null);
  const webcamRefHairLoss3 = useRef(null);
  const [ imgSrc, setImgSrc ] = useState(null);
  const [ imgSrcHairLoss, setImgSrcHairLoss ] = useState(null);
  const [ imgSrcHairLoss2, setImgSrcHairLoss2 ] = useState(null);
  const [ imgSrcHairLoss3, setImgSrcHairLoss3 ] = useState(null);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ attentionFollowConsultation, setAttentionFollowConsultation ] = useState(false);
  const [ imageModal, setImageModal ] = useState(null);
  const [ endQuestionary, setEndQuestionary ] = useState(false);
  const [ endQuestionaryModal, setEndQuestionaryModal ] = useState(false);
  const [ isSingleSelected, setIsSingleSelected ] = useState(false);
  const [ fileList, setFileList ] = useState([
    {
      uid: '-1',
      name: 'default1.png',
      status: 'done',
      url: EffectOne,
    },
  ]);
  const [ fileListHairLoss, setFileListHairLoss ] = useState([
    {
      uid: '-1',
      name: 'default1.png',
      status: 'done',
      url: up,
    },
  ]);
  const [ fileListHairLoss2, setFileListHairLoss2 ] = useState([
    {
      uid: '-1',
      name: 'default1.png',
      status: 'done',
      url: front,
    },
  ]);
  const [ fileListHairLoss3, setFileListHairLoss3 ] = useState([
    {
      uid: '-1',
      name: 'default1.png',
      status: 'done',
      url: back,
    },
  ]);

  const [ userPrescription, setUserPrescription ] = useState([]);

  const captureHairLoss = React.useCallback(async () => {
    const imageSrc = webcamRefHairLoss.current.getScreenshot({
      width: 500,
      height: 500,
    });

    if (imageSrc) {
      setImgSrcHairLoss(imageSrc);
      setFileListHairLoss([
        {
          uid: '-1',
          name: 'image.jpeg',
          status: 'done',
          thumbUrl: imageSrc,
        },
      ]);
      setVisibleWebCamHairLoss(!visibleWebCamHairLoss);
    }
  }, [ webcamRefHairLoss, setImgSrcHairLoss, visibleWebCamHairLoss ]);

  const WebcamComponentHairLoss = () => (
    <React.Fragment>
      {visibleWebCamHairLoss === true && (
        <Webcam
          audio={false}
          mirrored={true}
          height={300}
          ref={webcamRefHairLoss}
          screenshotFormat="image/png"
          width={300}
          videoConstraints={videoConstraints}
        />
      )}
    </React.Fragment>
  );

  const captureHairLoss2 = React.useCallback(async () => {
    const imageSrc = webcamRefHairLoss2.current.getScreenshot({
      width: 500,
      height: 500,
    });

    if (imageSrc) {
      setImgSrcHairLoss2(imageSrc);
      setFileListHairLoss2([
        {
          uid: '-1',
          name: 'image.jpeg',
          status: 'done',
          thumbUrl: imageSrc,
        },
      ]);
      setVisibleWebCamHairLoss2(!visibleWebCamHairLoss2);
    }
  }, [ webcamRefHairLoss2, setImgSrcHairLoss2, visibleWebCamHairLoss2 ]);

  const WebcamComponentHairLoss2 = () => (
    <React.Fragment>
      {visibleWebCamHairLoss2 === true && (
        <Webcam
          audio={false}
          mirrored={true}
          height={300}
          ref={webcamRefHairLoss2}
          screenshotFormat="image/png"
          width={300}
          videoConstraints={videoConstraints}
        />
      )}
    </React.Fragment>
  );

  const captureHairLoss3 = React.useCallback(async () => {
    const imageSrc = webcamRefHairLoss3.current.getScreenshot({
      width: 500,
      height: 500,
    });

    if (imageSrc) {
      setImgSrcHairLoss3(imageSrc);
      setFileListHairLoss3([
        {
          uid: '-1',
          name: 'image.jpeg',
          status: 'done',
          thumbUrl: imageSrc,
        },
      ]);
      setVisibleWebCamHairLoss3(!visibleWebCamHairLoss3);
    }
  }, [ webcamRefHairLoss3, setImgSrcHairLoss3, visibleWebCamHairLoss3 ]);

  const WebcamComponentHairLoss3 = () => (
    <React.Fragment>
      {visibleWebCamHairLoss3 === true && (
        <Webcam
          audio={false}
          mirrored={true}
          height={300}
          ref={webcamRefHairLoss3}
          screenshotFormat="image/png"
          width={300}
          videoConstraints={videoConstraints}
        />
      )}
    </React.Fragment>
  );

  useBeforeunload((event) => {
    set('redirectToInitialForm', true);
  });

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getUserPrescription = async () => {
    const response = await getUserPrescriptionByType(type);
    setUserPrescription(response);
    return response;
  };

  useEffect(() => {
    if (isHaveAttentionFollowConsultation) {
      setAttentionFollowConsultation(true);
    }

    if (get('redirectToInitialForm')) {
      history.push('/avaliacao');
    }

    setGender(get('gender'));
  }, []);

  useEffect(() => {
    AuthService.isFromPrescriptionDomain();
    hideQuestions(getResponsesByPage(page, type, quizUserResponse), true);
  }, [ pathname ]);

  const answerQuestion = (questionIndex, answerIndex, value, answer) => {
    const questionsCopy = [
      ...selectedAnswers.filter((o) => o.page === page && o.type === type),
    ];

    if (answer && answer.attentionQuestion && value === I18n.t('NO')) {
      localStorage.setItem(HaveAttentionFollowConsultation, true);
      message.error(I18n.t('COMPONENTS.PRESCRIPTION.FOLLOW_CONSULTATION'));
    } else if (answer && answer.attentionQuestion && value === I18n.t('YES')) {
      localStorage.setItem(HaveAttentionFollowConsultation, false);
      setAttentionFollowConsultation(false);
    }

    if (
      value === 'Não me recordo de ter tido uma discussão com meu médico' &&
      type === QuestionType.ED
    ) {
      setEndQuestionary(true);
    } else {
      setEndQuestionary(false);
    }

    if (value === 'Feminino' || value === 'Masculino') {
      set(
        'gender',
        value === 'Masculino' ? GenderEnum.MALE : GenderEnum.FEMALE,
      );
    }

    let update = true;
    questionsCopy[questionIndex].answers.forEach((o, i) => {
      if (o.singleSelect && o.userAnswer) {
        update = false;
      }
    });

    questionsCopy[questionIndex].answers.forEach((o, i) => {
      if (
        !update &&
        !questionsCopy[questionIndex].answers[answerIndex].singleSelect
      ) {
        return null;
      }

      if (
        questionsCopy[questionIndex].answers[answerIndex].type ===
        QuestionAnswerType.MULTIPLE_CHOICE &&
        questionsCopy[questionIndex].answers[answerIndex].removeAll
      ) {
        delete questionsCopy[questionIndex].answers[i].userAnswer;
      } else if (
        questionsCopy[questionIndex].answers[answerIndex].type ===
        QuestionAnswerType.MULTIPLE_CHOICE &&
        !questionsCopy[questionIndex].answers[answerIndex].removeAll
      ) {
        if (questionsCopy[questionIndex].answers[i].removeAll) {
          delete questionsCopy[questionIndex].answers[i].userAnswer;
        }
      } else if (
        !questionsCopy[questionIndex].answers[i].keep &&
        !questionsCopy[questionIndex].answers[answerIndex].keepParent &&
        !questionsCopy[questionIndex].answers[answerIndex].type !==
        QuestionAnswerType.MULTIPLE_CHOICE
      ) {
        delete questionsCopy[questionIndex].answers[i].userAnswer;
      }
    });

    if (
      questionsCopy[questionIndex].answers[answerIndex].type ===
      QuestionAnswerType.MULTIPLE_CHOICE &&
      (update || questionsCopy[questionIndex].answers[answerIndex].singleSelect)
    ) {
      if (questionsCopy[questionIndex].answers[answerIndex].userAnswer) {
        delete questionsCopy[questionIndex].answers[answerIndex].userAnswer;
      } else {
        questionsCopy[questionIndex].answers[answerIndex].userAnswer = value;
      }
    } else if (
      update ||
      questionsCopy[questionIndex].answers[answerIndex].singleSelect
    ) {
      questionsCopy[questionIndex].answers[answerIndex].userAnswer = value;
    }

    if (questionsCopy[questionIndex].answers[answerIndex].singleSelect) {
      setIsSingleSelected(true);
      questionsCopy[questionIndex].answers.forEach((o, i) => {
        if (i !== answerIndex) {
          delete questionsCopy[questionIndex].answers[i].userAnswer;
        }
      });
    } else {
      const singleSelect = questionsCopy[questionIndex].answers.find(res => res.singleSelect);

      if (singleSelect && isSingleSelected) {
        delete singleSelect.userAnswer;
        questionsCopy[questionIndex].answers[answerIndex].userAnswer = value;
        setIsSingleSelected(false);
      }
    }

    hideQuestions(questionsCopy);
  };

  const isSelectedAnswer = (questionIndex, answerIndex) => {
    return (
      selectedAnswers &&
      selectedAnswers.length > 0 &&
      selectedAnswers[questionIndex].answers[answerIndex].userAnswer
    );
  };

  const hideQuestions = (questions, notUpdate) => {
    const questionsCopy = [ ...questions ];

    questionsCopy.forEach((question, questionIndex) => {
      question.answers.forEach((answer, answerIndex) => {
        questionsCopy[questionIndex].answers[answerIndex].hidden =
          !isAllowedToAnswer(answer, questionIndex, questionsCopy);
      });
      questionsCopy[questionIndex].hidden = !isAllowedQuestion(questionIndex);
    });

    questionsCopy.forEach((question, questionIndex) => {
      question.answers.forEach((answer, answerIndex) => {
        if (
          questionsCopy[questionIndex].hidden ||
          questionsCopy[questionIndex].answers[answerIndex].hidden
        ) {
          delete questionsCopy[questionIndex].answers[answerIndex].userAnswer;
        }
      });
    });

    setSelectedAnswers(questionsCopy);

    if (!notUpdate) {
      updateUserAnswers(questionsCopy);
    }
  };

  const updateUserAnswers = (questionsCopy) => {
    const allQuestions = getQuestionsByType(type);
    const allQuestionsCopy = [ ...allQuestions ];

    allQuestionsCopy.forEach((allQuestion, allQuestionIndex) => {
      questionsCopy.forEach((question) => {
        if (
          question.page === allQuestion.page &&
          question.id === allQuestion.id
        ) {
          allQuestion.answers.forEach((allAnswer, allAnswerIndex) => {
            question.answers.forEach((answer) => {
              if (answer.id === allAnswer.id) {
                if (answer.userAnswer) {
                  allQuestionsCopy[allQuestionIndex].answers[
                    allAnswerIndex
                  ].userAnswer = answer.userAnswer;
                }
              }
            });
          });
        }
      });
    });
    setQuizUserResponse(allQuestionsCopy);
  };

  const isAllowedToAnswer = (answer, questionIndex, questions) => {
    let response = false;

    if (answer.selectionNeeded && answer.selectionNeeded.length > 0) {
      const hasAnswer =
        questions[questionIndex] &&
        questions[questionIndex].answers.find((o) => !!o.userAnswer);

      if (hasAnswer) {
        response = answer.selectionNeeded.indexOf(hasAnswer.id) >= 0;
      }
    } else if (answer.questionSelectionNeeded) {
      if (answer.questionSelectionNeeded.length !== undefined) {
        answer.questionSelectionNeeded.forEach((item) => {
          const question = questions.find((o) => o.id === item.id);

          question?.answers?.forEach((a) => {
            if (a.userAnswer === item.value) {
              response = true;
            }
          });
        });
      } else {
        const question = questions.find(
          (o) => o.id === answer.questionSelectionNeeded.id,
        );

        question?.answers?.forEach((a) => {
          if (a.userAnswer === answer.questionSelectionNeeded.value) {
            response = true;
          }
        });
      }
    } else if (answer.hideQuestion) {
      const question = questions.find((o) => o.id === answer.hideQuestion.id);

      question?.answers?.forEach((a) => {
        if (a.userAnswer !== answer.hideQuestion.value) {
          response = true;
        } else {
          response = false;
        }
      });
    } else {
      response = true;
    }

    return response;
  };

  const onSubmit = async () => {
    if (!verifyAnsweredAll()) {
      return false;
    }

    if (page === getLastPage(type) && (isHaveAttentionFollowConsultation || isHaveNitrate)) {
      const filteredQuestions = quizUserResponse.filter(response => !response.hidden);
      const availableQuestions = filteredQuestions.map(question => question.answers.map(o => ({
        ...o,
        questionId: question.id,
      })).filter(o => !o.hidden));
      const answered = availableQuestions.map(answers => answers
        .filter(answer => answer.userAnswer))
        .filter(userAnswer => userAnswer && userAnswer.length > 0);
      const response = [];

      answered.forEach(question => {
        question.forEach(answer => {
          response.push({
            question: answer.questionId,
            type: answer.type,
            answer: answer.id,
            textResponse: answer.userAnswer,
            score: 0,
          });
        });
      });

      const payload = {
        quiz: {
          quizMessage: isHaveAttentionFollowConsultation ? MedicalAppointmentMessage.SIDE_EFFECT : MedicalAppointmentMessage.NITRATE,
          questionAndResponse: response,
          type,
        },
        medicalAppointment: {
          evolutionId: null,
          attendBy: '',
          status: MedicalAppointmentStatus.PENDING_PATIENT,
          request: moment().format(),
          typeAttendance: MedicalAppointmentType.COMMON,
        }
      };

      await createQuiz(payload);

      return history.push(I18n.t('PAGES.PROFILE.PROFILE_CHAT.URL'));
    }

    if (endQuestionary) {
      return setEndQuestionaryModal(true);
    }

    if (!(page === getLastPage(type))) {
      if (type === QuestionType.HAIR_LOSS) {
        history.push(
          `/questionario/${getQuestionTypeName(
            type,
          ).toLowerCase()}/passo-${getNextPageByGender(page, type, gender)}`,
        );
      } else {
        history.push(
          `/questionario/${getQuestionTypeName(type).toLowerCase()}/passo-${page + 1
          }`,
        );
      }
    } else {
      if (type === QuestionType.HAIR_LOSS) {
        if (
          fileListHairLoss[0].name === 'default1.png' ||
          fileListHairLoss2[0].name === 'default1.png' ||
          fileListHairLoss3[0].name === 'default1.png'
        ) {
          return message.error(
            'Por favor tire as fotos para a validação medica',
          );
        } else {
          await saveImageBase64([
            fileListHairLoss,
            fileListHairLoss2,
            fileListHairLoss3,
          ]);
        }
      }

      if (type === QuestionType.MELATONINA) {
        if (
          fileList[0].name === 'default1.png' &&
          type !== QuestionType.HAIR_LOSS
        ) {
          return message.error(translate('SHARED.pictureValidation'));
        } else {
          await saveImageBase64(fileList);
        }
      }

      if (me && me.id && !isHaveNitrate) {
        const userPayload = {
          name: me.name,
          email: me.email,
          phone: me.phone,
          password: me.document,
          document: me.document,
          birthDate: me.birthDate,
          grantType: 'password',
        };

        await updateUserProfile(me.id, userPayload);
      }

      history.push(
        `/questionario/${getQuestionTypeName(type).toLowerCase()}/pagamento`,
      );
    }
  };

  const onChange = async (e, setFunction) => {
    const { file } = e;

    if (file) {
      file.extension =
        file.name &&
        file.name.split('.') &&
        file.name.split('.').pop().toLowerCase();
      const isJpgOrPng =
        file.extension === 'jpeg' ||
        file.extension === 'png' ||
        file.extension === 'jpg';

      if (!isJpgOrPng) {
        return message.error(translate('SHARED.fileValidation'));
      }

      file.thumbUrl = await compressImages([ file ]);

      const newFileList = [];
      newFileList[0] = {
        ...file,
        thumbUrl: file.thumbUrl,
        status: 'done',
      };

      setFunction(newFileList);
    } else {
      return message.error(translate('SHARED.fileValidation'));
    }
  };

  const verifyAnsweredAll = () => {
    let error = null;

    const questionsCopy = [ ...selectedAnswers ];

    const filteredQuestions = questionsCopy.filter((o) => !o.hidden);

    if (filteredQuestions && filteredQuestions.length > 0) {
      const availableQuestions = filteredQuestions.map((question) =>
        question.answers.filter((o) => !o.hidden),
      );

      if (type === QuestionType.ED) {
        const questionAlreadyExists = isHaveEvaluationQuestions.find((question) => question.id === filteredQuestions[0].id);
        let updatedQuestions = [];

        if (questionAlreadyExists) {
          const removeRepeated = isHaveEvaluationQuestions.filter((question) => question.id !== filteredQuestions[0].id);
          updatedQuestions = [ ...removeRepeated, ...filteredQuestions ];
        } else {
          updatedQuestions = [ ...isHaveEvaluationQuestions, ...filteredQuestions ];
        }

        localStorage.setItem(EvaluationQuestions, JSON.stringify(updatedQuestions));
      }


      if (filteredQuestions[2] && filteredQuestions[2].id === 202) {
        const answers = filteredQuestions[2].answers.map(item => item);

        if (answers.length && answers[0].userAnswer) {
          localStorage.setItem(HaveNitrateMedication, true);
        } else {
          localStorage.setItem(HaveNitrateMedication, false);
        }
      } else {
        localStorage.setItem(HaveNitrateMedication, false);
      }

      if (availableQuestions && availableQuestions.length > 0) {
        availableQuestions.forEach((answers) => {
          const singleAnswers = answers.filter(
            (o) => o.type === QuestionAnswerType.SINGLE_CHOICE,
          );
          const multipleAnswers = answers.filter(
            (o) => o.type === QuestionAnswerType.MULTIPLE_CHOICE,
          );
          const openTextAnswers = answers.filter(
            (o) => o.type === QuestionAnswerType.OPEN_TEXT,
          );

          if (singleAnswers.length > 0) {
            const answered = singleAnswers.filter((o) => o.userAnswer);

            if (answered.length > 0) {
              if (
                openTextAnswers.length > 0 &&
                openTextAnswers.filter((o) => o.userAnswer).length === 0
              ) {
                // error = 'Responda também o(s) campo(s) de texto.';
                error = 'Responda todos os campos solicitados.';
              }
            } else {
              // error = 'Selecione pelo menos uma opção.';
              error = 'Responda todos os campos solicitados.';
            }
          }

          if (multipleAnswers.length > 0) {
            const answered = multipleAnswers.filter((o) => o.userAnswer);

            if (answered.length > 0) {
              if (
                openTextAnswers.length > 0 &&
                openTextAnswers.filter((o) => o.userAnswer).length === 0
              ) {
                // error = 'Responda também o(s) campo(s) de texto.';
                error = 'Responda todos os campos solicitados.';
              }
            } else {
              // error = 'Selecione pelo menos uma opção da lista.';
              error = 'Responda todos os campos solicitados.';
            }
          }

          if (singleAnswers.length === 0 && multipleAnswers.length === 0) {
            const answered = openTextAnswers.filter((o) => !o.userAnswer);

            if (answered.length > 0) {
              // error = 'Responda também o(s) campo(s) de texto.';
              error = 'Responda todos os campos solicitados.';
            }
          }
        });
      }
    }

    if (error) {
      message.error(error);
      return false;
    }

    return true;
  };

  const isAllowedQuestion = (questionIndex) => {
    const questionsCopy = [ ...selectedAnswers ];

    if (questionsCopy && questionsCopy.length > 0) {
      const response = questionsCopy[questionIndex].answers.map((answer) =>
        isAllowedToAnswer(answer, questionIndex, questionsCopy),
      );
      return response.filter((o) => !!o).length > 0;
    }

    return false;
  };

  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: 'user',
  };

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: 500,
      height: 500,
    });

    if (imageSrc) {
      setImgSrc(imageSrc);
      setFileList([
        {
          uid: '-1',
          name: 'image.jpeg',
          status: 'done',
          thumbUrl: imageSrc,
        },
      ]);
      setVisibleWebCam(!visibleWebCam);
    }
  }, [ webcamRef, setImgSrc, visibleWebCam ]);

  const WebcamComponent = () => (
    <React.Fragment>
      {visibleWebCam && (
        <Webcam
          audio={false}
          mirrored={true}
          height={300}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={300}
          videoConstraints={videoConstraints}
        />
      )}
      <Button className="capture-cam"
        onClick={capture}>
        <CameraOutlined /> &nbsp;
        {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
      </Button>
    </React.Fragment>
  );

  const onPreview = async (file) => {
    const src = file.url ? file.url : file.thumbUrl;
    setImageModal(src);
    setIsModalVisible(true);
  };

  const goToInitalStep = () => {
    if (
      window.confirm(
        'Deseja ir para o inicio da avaliação? Isto fará com que as respostas até agora sejam reiniciadas.',
      )
    ) {
      history.push('/avaliacao');
    }
  };

  return (
    <div className="prescription-step">
      <div
        className="prescription-step__top-bar"
        style={{ backgroundColor: getPageColor(page, type) }}
      >
        <div
          className="prescription-step__top-bar__go-back"
          onClick={() => goToInitalStep()}
        >
          <img
            src={goBack}
            alt="go back"
            className="prescription-step__top-bar__go-back__img"
          />
        </div>
        <div className="prescription-step__top-bar__fix-style" />
        <div
          className="prescription-step__top-bar__text"
          onClick={() => goToInitalStep()}
        >
          {type === QuestionType.MELATONINA && 'Saúde do sono'}
          {type === QuestionType.ED && 'Saúde sexual - Ereção'}
          {type === QuestionType.EJ && 'Saúde sexual - Ejaculação'}
          {type === QuestionType.HAIR_LOSS && 'Saúde capilar'}
        </div>
      </div>
      {endQuestionaryModal && (
        <div className="prescription-step__end">
          <img
            className="prescription-step__end__img"
            src={sadFace}
            alt="sad Face"
          />
          <p className="prescription-step__end__text">
            Será necessário você ir até uma farmácia ou à um médico que aufira
            (meça) sua pressão para darmos continuidade ao tratamento.
          </p>
          <a className="prescription-step__end__link"
            href="/">
            Voltar para home
          </a>
          <div className="prescription-step__end__footer">
            <button
              className="prescription-step__footer__button prescription-step__footer__button--back"
              type="button"
              onClick={() => history.goBack()}
            >
              <span className="prescription-step__footer__button__icon">
                <img
                  className="prescription-step__footer__button__icon__img prescription-step__footer__button__icon__img--back"
                  src={IcBack}
                  alt=""
                />
              </span>
            </button>

            <button
              className="prescription-step__footer__button prescription-step__footer__button--disabled"
              type="button"
            >
              <span className="prescription-step__footer__button__icon">
                <img
                  className="prescription-step__footer__button__icon__img"
                  src={IcNext}
                  alt=""
                />
              </span>
            </button>
          </div>
        </div>
      )}
      <div className="prescription-step__progress">
        <div className="prescription-step__progress__inner">
          <div className="prescription-step__progress__inner__bar">
            <div
              className="prescription-step__progress__inner__bar__progress"
              style={{
                backgroundColor: getPageColor(page, type),
                width: `${getProgressAmount(page, type)}%`,
              }}
            />
          </div>
        </div>
      </div>

      {selectedAnswers?.map((question, questionIndex) => (
        <div
          className="prescription-step__single"
          key={questionIndex.toString()}
        >
          {question.answers.find((o) => !o.hidden) && (
            <div className="prescription-step__single__title">
              {question.message && (
                <p className="prescription-step__single__message">
                  <b>{I18n.t('COMPONENTS.PRESCRIPTION.ATTENTION')}</b>
                  {question.message}
                </p>
              )}
              {question.title && question.title}
              {question.haveTextBold && (
                <p>{question.initialText} <b>{question.textBold}</b> {question.finalText}</p>
              )}
            </div>
          )}
          <div
            className={`prescription-step__single__answers ${question.inline && 'prescription-step__single__answers--inline'
              }`}
          >
            {question.answers.map((answer, answerIndex) => (
              <div
                key={answerIndex}
                className={`${answer.type === QuestionAnswerType.OPEN_TEXT &&
                  'prescription-step__single__answers--full'
                  }`}
              >
                {answer.type === QuestionAnswerType.SINGLE_CHOICE &&
                  !answer.hidden && (
                  <div
                    className="prescription-step__single__answers__single prescription-step__single__answers__single--single-choice"
                    style={{
                      borderColor: isSelectedAnswer(
                        questionIndex,
                        answerIndex,
                      )
                        ? getPageColor(page, type)
                        : '#7D7D7D',
                      boxShadow:
                          isSelectedAnswer(questionIndex, answerIndex) &&
                          `inset 0px 0px 0px 2px ${getPageColor(page, type)}`,
                      backgroundColor:
                          isSelectedAnswer(questionIndex, answerIndex) &&
                          LightenDarkenColor(getPageColor(page, type), 110),
                    }}
                    onClick={() => {
                      answerQuestion(questionIndex, answerIndex, answer.value, answer);
                      handleClick();
                    }}
                  >
                    <div className="prescription-step__single__answers__single--single-choice__inner">
                      {answer.value}
                    </div>
                    {answer.icon && (
                      <div className="prescription-step__single__answers__single--single-choice__icon">
                        <img
                          className="prescription-step__single__answers__single--single-choice__icon__img"
                          src={answer.icon}
                          alt={answer.value}
                        />
                      </div>
                    )}
                  </div>
                )}

                {answer.type === QuestionAnswerType.OPEN_TEXT &&
                  !answer.hidden && (
                  <div className="prescription-step__single__answers__single prescription-step__single__answers__single--open-text">
                    <div className="prescription-step__single__answers__single--open-text__inner">
                      {answer.value && answer.value !== '' && (
                        <div
                          className={`prescription-step__single__answers__single--open-text__inner__title ${answerIndex === 0 &&
                              'prescription-step__single__answers__single--open-text__inner__title--first'
                              }`}
                        >
                          {answer.value}
                        </div>
                      )}
                      <textarea
                        style={{
                          borderColor: '#7D7D7D',
                          outLine: 'none',
                        }}
                        onChange={(e) =>
                          answerQuestion(
                            questionIndex,
                            answerIndex,
                            e.target.value,
                          )
                        }
                        className="prescription-step__single__answers__single--open-text__inner__textarea"
                        placeholder={
                          answer.placeholder || 'Digite sua resposta aqui'
                        }
                        rows={3}
                        value={
                          (selectedAnswers &&
                              selectedAnswers.length > 0 &&
                              selectedAnswers[questionIndex].answers[
                                answerIndex
                              ].userAnswer) ||
                            ''
                        }
                      />
                    </div>
                  </div>
                )}

                {answer.type === QuestionAnswerType.MULTIPLE_CHOICE &&
                  !answer.hidden && (
                  <>
                    <div
                      className="prescription-step__multiple__answers__single prescription-step__single__answers__single--multiple-choice"
                      style={{
                        borderColor: isSelectedAnswer(
                          questionIndex,
                          answerIndex,
                        )
                          ? getPageColor(page, type)
                          : '#7D7D7D',
                        boxShadow:
                            isSelectedAnswer(questionIndex, answerIndex) &&
                            `inset 0px 0px 0px 2px ${getPageColor(page, type)}`,
                        backgroundColor:
                            isSelectedAnswer(questionIndex, answerIndex) &&
                            LightenDarkenColor(getPageColor(page, type), 110),
                      }}
                      onClick={() => {
                        answerQuestion(questionIndex, answerIndex, answer.value);
                        handleClick();
                      }}
                    >
                      <div className="prescription-step__single__answers__single--multiple-choice__inner">
                        <div
                          className="prescription-step__single__answers__single--multiple-choice__inner__radio"
                          style={{
                            borderColor: isSelectedAnswer(
                              questionIndex,
                              answerIndex,
                            )
                              ? getPageColor(page, type)
                              : '#7D7D7D',
                          }}
                        >
                          {isSelectedAnswer(questionIndex, answerIndex) && (
                            <div
                              className="prescription-step__single__answers__single--multiple-choice__inner__radio__inner"
                              style={{
                                backgroundColor: getPageColor(page, type),
                              }}
                            />
                          )}
                        </div>
                        <div
                          className="prescription-step__single__answers__single--multiple-choice__inner__value"
                          style={{
                            fontWeight:
                                isSelectedAnswer(questionIndex, answerIndex) &&
                                'bold',
                          }}
                        >
                          {answer.value}
                        </div>
                      </div>
                    </div>
                    {(answer.alertCard && isSelectedAnswer(questionIndex, answerIndex)) && (
                      <AlertCard
                        title={answer.alertCard.title}
                        description={answer.alertCard.description}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
            {page === getLastPage(type) &&
              questionIndex + 1 === selectedAnswers.length && (
              <>
                {type === QuestionType.HAIR_LOSS ? (
                  <div className="prescription-step__single__answers__picture--hair-loss">
                    <div className="prescription-step__single__answers__picture--hair-loss__title">
                      <p className="prescription-step__single__answers__picture--hair-loss__title__text">
                          Hora da foto!
                      </p>
                      <p className="prescription-step__single__answers__picture--hair-loss__title__text">
                          Estamos quase lá, agora só faltam as suas fotos. Essa
                          etapa é importante, pois o médico poderá analisar
                          melhor o seu quadro.
                      </p>
                      <p className="prescription-step__single__answers__picture--hair-loss__title__text">
                          Fique tranquilo, suas fotos são sigilosas e não serão
                          publicadas em nenhum local.
                      </p>
                    </div>
                    <div className="prescription-step__single__answers__picture--hair-loss__container">
                      <div className="prescription-step__single__answers__picture--hair-loss__container__item">
                        {visibleWebCamHairLoss && (
                          <>{WebcamComponentHairLoss()}</>
                        )}
                        {!visibleWebCamHairLoss && (
                          <ImgCrop
                            rotate
                            zoom
                            modalTitle="Editar arquivo"
                            modalOk="Utilizar foto"
                            modalCancel="Cancelar"
                            grid
                          >
                            <Upload
                              listType="picture-card"
                              fileList={fileListHairLoss}
                              onPreview={onPreview}
                              customRequest={(ev) =>
                                onChange(ev, setFileListHairLoss)
                              }
                              accept="image/*"
                            >
                              <PaperClipOutlined />
                                &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                            </Upload>
                          </ImgCrop>
                        )}
                        <div className="prescription-step__single__answers__picture--hair-loss__container__label">
                            PARTE DE CIMA DA CABEÇA
                        </div>
                        <div className="prescription-step__single__answers__picture--hair-loss__container__button-cam">
                          {visibleWebCamHairLoss && (
                            <Button
                              className="capture-cam"
                              onClick={captureHairLoss}
                            >
                              <CameraOutlined /> &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
                            </Button>
                          )}
                          <Button
                            className="capture-cam"
                            onClick={() =>
                              setVisibleWebCamHairLoss(!visibleWebCamHairLoss)
                            }
                          >
                            {!visibleWebCamHairLoss ? (
                              <>
                                <CameraOutlined /> &nbsp;
                              </>
                            ) : (
                              ''
                            )}
                            {!visibleWebCamHairLoss
                              ? translate('COMPONENTS.CLINIC.PICTURE.CAM')
                              : translate(
                                'COMPONENTS.PRESCRIPTION.BUTTONS.CANCEL',
                              )}
                          </Button>
                        </div>
                      </div>
                      <div className="prescription-step__single__answers__picture--hair-loss__container__item">
                        {visibleWebCamHairLoss2 && (
                          <>{WebcamComponentHairLoss2()}</>
                        )}
                        {!visibleWebCamHairLoss2 && (
                          <ImgCrop
                            rotate
                            zoom
                            modalTitle="Editar arquivo"
                            modalOk="Utilizar foto"
                            modalCancel="Cancelar"
                            grid
                          >
                            <Upload
                              listType="picture-card"
                              fileList={fileListHairLoss2}
                              onPreview={onPreview}
                              customRequest={(ev) =>
                                onChange(ev, setFileListHairLoss2)
                              }
                              accept="image/*"
                            >
                              <PaperClipOutlined />
                                &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                            </Upload>
                          </ImgCrop>
                        )}
                        <div className="prescription-step__single__answers__picture--hair-loss__container__label">
                            PARTE DA FRENTE
                        </div>
                        <div className="prescription-step__single__answers__picture--hair-loss__container__button-cam">
                          {visibleWebCamHairLoss2 && (
                            <Button
                              className="capture-cam"
                              onClick={captureHairLoss2}
                            >
                              <CameraOutlined /> &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
                            </Button>
                          )}
                          <Button
                            className="capture-cam"
                            onClick={() =>
                              setVisibleWebCamHairLoss2(
                                !visibleWebCamHairLoss2,
                              )
                            }
                          >
                            {!visibleWebCamHairLoss2 ? (
                              <>
                                <CameraOutlined /> &nbsp;
                              </>
                            ) : (
                              ''
                            )}
                            {!visibleWebCamHairLoss2
                              ? translate('COMPONENTS.CLINIC.PICTURE.CAM')
                              : translate(
                                'COMPONENTS.PRESCRIPTION.BUTTONS.CANCEL',
                              )}
                          </Button>
                        </div>
                      </div>
                      <div className="prescription-step__single__answers__picture--hair-loss__container__item">
                        {visibleWebCamHairLoss3 && (
                          <>{WebcamComponentHairLoss3()}</>
                        )}
                        {!visibleWebCamHairLoss3 && (
                          <ImgCrop
                            rotate
                            zoom
                            modalTitle="Editar arquivo"
                            modalOk="Utilizar foto"
                            modalCancel="Cancelar"
                            grid
                          >
                            <Upload
                              listType="picture-card"
                              fileList={fileListHairLoss3}
                              onPreview={onPreview}
                              customRequest={(ev) =>
                                onChange(ev, setFileListHairLoss3)
                              }
                              accept="image/*"
                            >
                              <PaperClipOutlined />
                                &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.UPLOAD')}
                            </Upload>
                          </ImgCrop>
                        )}
                        <div className="prescription-step__single__answers__picture--hair-loss__container__label">
                            PARTE DE TRÁS
                        </div>
                        <div className="prescription-step__single__answers__picture--hair-loss__container__button-cam">
                          {visibleWebCamHairLoss3 && (
                            <Button
                              className="capture-cam"
                              onClick={captureHairLoss3}
                            >
                              <CameraOutlined /> &nbsp;
                              {translate('COMPONENTS.CLINIC.PICTURE.CAPTURE')}
                            </Button>
                          )}
                          <Button
                            className="capture-cam"
                            onClick={() =>
                              setVisibleWebCamHairLoss3(
                                !visibleWebCamHairLoss3,
                              )
                            }
                          >
                            {!visibleWebCamHairLoss3 ? (
                              <>
                                <CameraOutlined /> &nbsp;
                              </>
                            ) : (
                              ''
                            )}
                            {!visibleWebCamHairLoss3
                              ? translate('COMPONENTS.CLINIC.PICTURE.CAM')
                              : translate(
                                'COMPONENTS.PRESCRIPTION.BUTTONS.CANCEL',
                              )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="prescription-step__single__answers__picture">
                    {type === QuestionType.MELATONINA && (
                      <>
                        <h3 className="prescription-step__single__answers__picture__title">
                          {I18n.t('COMPONENTS.SELFIE.TEXT_1')}
                          <strong>SELFIE</strong>{' '}
                          {I18n.t('COMPONENTS.SELFIE.TEXT_2')}
                        </h3>
                        <div className="prescription-step__single__answers__picture__container">
                          {visibleWebCam && <>{WebcamComponent()}</>}
                          {!visibleWebCam && (
                            <ImgCrop
                              rotate
                              zoom
                              modalTitle="Editar arquivo"
                              modalOk="Utilizar foto"
                              modalCancel="Cancelar"
                              grid
                            >
                              <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={onPreview}
                                customRequest={(ev) =>
                                  onChange(ev, setFileList)
                                }
                                accept="image/*"
                              >
                                <PaperClipOutlined />
                                  &nbsp;
                                {translate(
                                  'COMPONENTS.CLINIC.PICTURE.UPLOAD',
                                )}
                              </Upload>
                            </ImgCrop>
                          )}
                          <div className="prescription-step__single__answers__picture__container__button-cam">
                            <Button
                              className="capture-cam"
                              onClick={() => setVisibleWebCam(!visibleWebCam)}
                            >
                              <CameraOutlined /> &nbsp;
                              {!visibleWebCam
                                ? translate('COMPONENTS.CLINIC.PICTURE.CAM')
                                : translate(
                                  'COMPONENTS.PRESCRIPTION.BUTTONS.CANCEL',
                                )}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    { (type === QuestionType.EJ || (type === QuestionType.ED && !isHaveNitrate)) && (
                      <h3 className="prescription-step__single__answers__picture__title">
                        {I18n.t('COMPONENTS.PRESCRIPTION.FINISH')}
                      </h3>
                    )}
                    { type === QuestionType.ED && isHaveNitrate && (
                      <div className="attention-container">
                        <img src={IcAttention}
                          alt={I18n.t('COMPONENTS.CART.FORM.ATENTION.TITLE')}
                          className="attention-icon"/>
                        <h3 className="prescription-step__single__answers__picture__title">
                          {`${me && me.name}, `} {I18n.t('PAGES.HOME.HOW_ARE_YOU')} <br /> {I18n.t('PAGES.HOME.MESSAGE_DEFAULT_NITRATE')}
                        </h3>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}

      <div
        className={`prescription-step__footer ${page === getLastPage(type) && 'prescription-step__footer--end'
          }`}
        ref={ref}
      >
        {page === getLastPage(type) ? (
          <>
            <div className="payment__box__aux">
              <div className="payment__box__aux__form">
                <WhiteButton
                  onClick={onSubmit}
                  medicineColor={'#1D6EA3'}
                  label={I18n.t('COMPONENTS.FINISH_MEDICAL_EVALUATION')}
                  full
                  dark
                  disabled={loading > 0}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <button
              className="prescription-step__footer__button prescription-step__footer__button--back"
              type="button"
              onClick={() => history.goBack()}
            >
              <span className="prescription-step__footer__button__icon">
                <img
                  className="prescription-step__footer__button__icon__img prescription-step__footer__button__icon__img--back"
                  src={IcBack}
                  alt=""
                />
              </span>
            </button>

            <button
              className="prescription-step__footer__button"
              type="button"
              onClick={onSubmit}
            >
              <span className="prescription-step__footer__button__icon">
                <img
                  className="prescription-step__footer__button__icon__img"
                  src={IcNext}
                  alt=""
                />
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  quizUserResponse: state.prescription.quizUserResponse,
  createdQuiz: state.quiz.quizUserResponse,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (id, data, callback) =>
    dispatch(UserActions.updateUser(id, data, callback)),
  setQuizUserResponse: (payload) =>
    dispatch(PrescriptionActions.setQuizUserResponse(payload)),
  setQuizUserPayload: (payload) =>
    dispatch(PrescriptionActions.setQuizUserPayload(payload)),
  getUserPrescriptionByType: (type) =>
    dispatch(PrescriptionActions.getUserPrescriptionByType(type)),
  createQuiz: (quiz) => dispatch(QuizActions.createQuiz(quiz)),
  createMedicalAppointment: (data) =>
    dispatch(MedicalAppointmentActions.createMedicalAppointment(data)),
  uploadImageBase64: (photoDataBase64) =>
    dispatch(UtilsActions.uploadImageBase64(photoDataBase64)),
  saveImageBase64: (imageBase64) =>
    dispatch(UtilsActions.saveImageBase64(imageBase64)),
  createChatMessage: (data) => dispatch(ChatMessageActions.createChatMessage(data)),
  getMineMedicalAppointments: (params) =>
    dispatch(MedicalAppointmentActions.getMedicalAppointmentMine(params)),
  getTransactionMineDetails: (params) => dispatch(TransactionActions.getTransactionsMineDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionStep);

import React from 'react';
/**
 *
 * @param {Object} props.banner
 */

const BannerHome = ({ banner }) => {
  return (
    <div className="carousel-banner-home">
      <h1 className="carousel-banner-home__title">
        {banner?.title}
      </h1>

      <p className="carousel-banner-home__subtitle">
        {banner?.subtitle}
      </p>
      <a
        className="carousel-banner-home__button"
        href="/avaliacao"
        target="_blank"
      >
        {banner?.buttonText}
      </a>

      <p className="carousel-banner-home__subtext">
        {banner?.subtext}
      </p>
    </div>
  );
};

export default BannerHome;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import { translate } from '../../../services/i18n';
import * as AuthActions from '../../../redux/actions/auth';
import AdvancedForm from '../../../components/AdvancedForm/AdvancedForm';
import HomeSlider from '../../../components/HomeSlider/HomeSlider';
import WhiteButton from '../../../components/WhiteButton/WhiteButton';
import { removeSpecialChars } from '../../../utils/string';

import { cpfMask } from '../../../utils/masks';
import AuthService from '../../../services/auth';


const Login = (props) => {

  const { pathname } = useLocation();
  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  const { login, authenticated } = props;

  const [ form, setForm ] = useState({
    email: '',
    password: '',
  });

  const { email, password } = form;

  const history = useHistory();
  const urls = I18n.t('URLS');

  useEffect(() => {
    if (authenticated === true) {
      history.push(urls.USER_PERFIL);
    }
  }, []);

  const handleChange = name => e => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onFormSubmit = async () => {
    if (authenticated === true) {
      history.push(urls.USER_PERFIL);
    }

    const payload = {
      ...form,
      password: removeSpecialChars(form.password),
    };

    await login(payload, (response) => {
      if (response && !response.error) {
        window.location.href = urls.USER_PERFIL;
      }
    });
  };

  return (
    <div className="login">
      <Helmet>
        <title>Entrar</title>
      </Helmet>
      <Container>
        <Row className="login__row">
          <Col
            className="mobile-hidden"
            lg={8}
            md={8}
          >
            <HomeSlider />
          </Col>
          <Col
            lg={4}
            md={4}
          >
            <div className="login__form">
              <div className="login__form__inner">
                <AdvancedForm onSubmit={onFormSubmit}>
                  <Row className="login__form__inner__row">
                    <Col>
                      <div className="login__form__inner__message">
                        <strong className="login__form__inner__message__title">
                          {translate('PAGES.AUTH.LOGIN.FORM.TITLE')}
                        </strong>
                        <p>
                          {translate('PAGES.AUTH.LOGIN.FORM.WELCOME')}
                        </p>
                      </div>
                    </Col>
                    <div className="login__form__inner__box-form mobile-width">
                      <Col>
                        <input
                          className="login__form__inner__login"
                          placeholder={translate('PAGES.AUTH.LOGIN.FORM.EMAIL.PLACEHOLDER')}
                          value={email && email.toLowerCase()}
                          onChange={handleChange('email')}
                        />
                      </Col>
                      <Col>
                        <input
                          className="login__form__inner__password"
                          placeholder={translate('PAGES.AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER')}
                          value={cpfMask(password)}
                          onChange={handleChange('password')}
                        />
                      </Col>
                      <Col>
                        <div className="login__form__inner__button">
                          <WhiteButton
                            label={translate('PAGES.AUTH.LOGIN.FORM.BUTTON.TEXT')}
                            dark
                            full
                            type="submit"
                          />
                        </div>
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <div className="login__form__inner__register">
                      <p className="login__form__inner__register__text-register">
                        {translate('PAGES.AUTH.LOGIN.FORM.TEXT_REGISTER')}
                      </p>
                      <Link to={translate('PAGES.HOME.REGISTER.URL')}>
                        <h3 className="login__form__inner__register__text-link">
                          {translate('PAGES.AUTH.LOGIN.FORM.TEXT_LINK')}
                        </h3>
                      </Link>
                    </div>
                  </Row>
                </AdvancedForm>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  login: (userData, callback) => dispatch(AuthActions.authenticate(userData, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useState } from 'react';
import { Input, message } from 'antd';
import { translate } from '../../services/i18n';

export const UnauthenticatedBlockingModal = ({ onSubmit, onCancel }) => {
  const [ emailTyped, setEmailTyped ] = useState('');

  const handleSubmit = () => {
    if (!(emailTyped.length >= 5 && emailTyped.includes('@'))) {
      message.error('Por favor, digite um email válido');
      return;
    }

    onSubmit(emailTyped);
  };

  const handleKeyDown = (e) => {
    if (e?.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='unauthenticated-blocking-modal-box'>
      <div className='modal-content'>
        <h2>
          {translate('PRODUCTS.BLOCKING_MODAL.HEADER')}
        </h2>
        <Input placeholder="Digite seu email"
          size="large"
          value={emailTyped}
          onChange={e => setEmailTyped(e.target.value)} onKeyDown={handleKeyDown}/>
        <div className='buttons-content'>
          <button onClick={onCancel}
            className='cancel-button'>{translate('PRODUCTS.BLOCKING_MODAL.CANCEL')}</button>
          <button onClick={handleSubmit}
            className='register-button'>{translate('PRODUCTS.BLOCKING_MODAL.CONFIRM')}</button>
        </div>
      </div>
    </div>
  );
};

export const MedicalAppointmentStatus = {
  PENDING_CONSULTATION: 1,
  VIDEO_PENDING_CONSULTATION: 2,
  RESCHEDULING: 3,
  PENDING_PATIENT: 4,
  UNVIEWED_MESSAGE: 5,
  VIDEO_SCHEDULED: 6,
  DONE_VIDEO_CONSULTATION: 7,
  DONE_EVALUATION: 8,
  DONE_PRESCRIPTION: 9,
  PRESCRIPTION_PENDING_PATIENT: 10,
};

export const MedicalAppointmentType = {
  COMMON: 1,
  VIDEO: 2,
};

export const MedicalAppointmentPrice = {
  EVALUATION: 25,
  PRESCRIPTION: 25,
  VIDEO: 25,
};

export const MedicalAppointmentMessage = {
  NITRATE: 1,
  SIDE_EFFECT: 2,
};

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/medical/logo.svg';
import goBack from '../../assets/medical/go-back.svg';
import * as AuthActions from '../../redux/actions/auth';
import * as CartActions from '../../redux/actions/cart';
import { connect } from 'react-redux';

const TopBarTwo = (props) => {
  const {
    type,
    prevPage,
    currentPage
  } = props;

  const typeTexts = [
    'Saúde capilar',
    'Saúde do sono',
    'Saúde Sexual - Ereção',
    'Saúde Sexual - Ejaculação',
  ];

  return (
    <div className={`top-bar-appointment top-bar-appointment--${type} top-bar-appointment--${currentPage === 4 && 'policy'}`}>
      <div className="top-bar-appointment__wrapper">
        <div
          className="top-bar-appointment__wrapper__go-back"
          onClick={() => prevPage()}
        >
          <img
            className="top-bar-appointment__wrapper__go-back__img"
            src={goBack}
            alt="go back logo"
          />
        </div>
        <div className="top-bar-appointment__fix-style"/>
        <div className="top-bar-appointment__logo">
          {type ? (
            <div className={`top-bar-appointment__logo__text ${currentPage === 4 && 'top-bar-appointment__logo__text--policy'}`}>
              {typeTexts[type - 1]}
            </div>
          ) : (
            <Link to='/'>
              <img
                className="top-bar-appointment__logo__img"
                src={Logo}
                alt="top bar logo"
              />
            </Link>
          )}
          {currentPage === 4 && (
            <div className={'top-bar-appointment__logo__text'}>
              Termos de consentimento
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  deleteAllProductsInCart: () => dispatch(CartActions.deleteAllProductsInCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarTwo);
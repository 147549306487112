import React from 'react';
import { Helmet } from 'react-helmet-async';
import { translate } from '../../services/i18n';

const PageContentClinic = (props) => {
  return (
    <div className="page-content">
      <Helmet>
        <title>{`${props.pageTitle} - ${translate('APPLICATION_NAME')}`}</title>
      </Helmet>
      {props.children}
    </div>
  );
};

export default PageContentClinic;

import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { decreaseLoading, increaseLoading } from './loading';
import { translate } from '../../services/i18n';

import TransactionRequests from '../../api/transaction';

export const ACTION_SAVE_PAYMENT_DETAILS =
  'transaction:ACTION_SAVE_PAYMENT_DETAILS';
export const ACTION_IS_PAYMENT_RECURRENT =
  'transaction:ACTION_IS_PAYMENT_RECURRENT';
export const ACTION_MINE_TRANSACTIONS = 'transaction:ACTION_MINE_TRANSACTIONS';
export const ACTION_GET_CREDIT_CARD = 'ACTION_GET_CREDIT_CARD';

export const isPaymentRecurrent = (value) => ({
  type: ACTION_IS_PAYMENT_RECURRENT,
  payload: value,
});

export const createCreditCard = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await TransactionRequests.createCard(data);

    if (callback && typeof callback === 'function') {
      callback(result);
    }

    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(translate('ERROR_CARD'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const createTransaction = (productsList) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await TransactionRequests.create(productsList);
    return response;
  } catch (err) {
    const errors = I18n.t('ERRORS');
    message.error(errors[err.message]);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const payment = (idTransaction, body) => async (dispatch) => {
  let response = {};

  dispatch(increaseLoading());

  try {
    response = await TransactionRequests.checkout(idTransaction, body);
    dispatch({
      type: ACTION_SAVE_PAYMENT_DETAILS,
      payload: response,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');
    message.error(errors[err.message]);
    response.error = err.message;
  } finally {
    dispatch(decreaseLoading());
  }

  return response;
};

export const getMineTransactions = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    params = {
      ...params,
      orderBy: 'status',
      isDesc: true,
      limit: 100,
    };

    const payload = await TransactionRequests.getMineTransactions(params);

    dispatch({
      type: ACTION_MINE_TRANSACTIONS,
      payload,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getCreditCard = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await TransactionRequests.getMineCardsCreditById(id);
    dispatch({
      type: ACTION_GET_CREDIT_CARD,
      payload: response,
    });
  } catch (error) {
    //
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id, body, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let response;

  try {
    response = await TransactionRequests.update(id, body);
  } catch (err) {
    const errors = I18n.t('ERRORS');
    message.error(errors[err.message]);
  } finally {
    dispatch(decreaseLoading());
  }

  if (callback) {
    callback(response);
  }
};

export const getTransactionsMineDetails = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await TransactionRequests.getTransactionDetails(params);
    return response;
  } catch (err) {
    const errors = I18n.t('ERRORS');
    message.error(errors[err.message]);
  } finally {
    dispatch(decreaseLoading());
  }
};

import { I18n } from 'react-redux-i18n';
import { message } from 'antd';
import { pixelTrack } from './../../utils/pixel';
import { decreaseLoading, increaseLoading } from './loading';
import UtilsRequests from '../../api/utils';
import { getProductById, isPromotionalProduct } from '../../services/product';
import { parseItems, setDataLayer } from '../../services/analytics';
import { CartSymbol } from '../../enum/cartSymbol';
import CouponRequests from '../../api/coupon';
import PrescriptionRequests from '../../api/prescription';
import { hairKitCart, removeSpecialChars } from '../../utils/string';
import PaymentMethod from '../../enum/paymentMethod';
import { ACTION_SAVE_PAYMENT_DETAILS } from './transaction';
import { increaseTimer } from './timer';
import { isProduction } from '../../utils/host';
import { checkPromotionProductRemoval, reorderProductsPromotion } from '../../utils/checkout';
import { toCurrencyLocale } from '../../utils/masks';
import { translate } from '../../services/i18n';

export const ACTION_ADD_PRODUCTS_IN_CART = 'cart:ACTION_ADD_PRODUCTS_IN_CART';
export const ACTION_CALCULATE_PRODUCTS_CART = 'cart:ACTION_CALCULATE_PRODUCTS_CART';
export const ACTION_COUNT_TOTAL_QUANTITY_CART = 'cart:ACTION_COUNT_TOTAL_QUANTITY_CART';
export const ACTION_DELETE_ALL_PRODUCTS_IN_CART = 'cart:ACTION_DELETE_ALL_PRODUCTS_IN_CART';

export const ACTION_CHANGE_PRODUCTS = 'cart:ACTION_CHANGE_PRODUCTS';
export const ACTION_CHANGE_COUPON = 'cart:ACTION_CHANGE_COUPON';
export const ACTION_CHANGE_FREIGHT = 'cart:ACTION_CHANGE_FREIGHT';
export const ACTION_CHANGE_FREIGHT_TYPE = 'cart:ACTION_CHANGE_FREIGHT_TYPE';
export const ACTION_SET_FREIGHT_OPTIONS = 'cart:ACTION_SET_FREIGHT_OPTIONS';
export const ACTION_CHANGE_VALUES = 'cart:ACTION_CHANGE_VALUES';
export const ACTION_CHANGE_RECURRENT = 'cart:ACTION_CHANGE_RECURRENT';
export const ACTION_CHANGE_PRESCRIPTION = 'cart:ACTION_CHANGE_PRESCRIPTION';
export const ACTION_ADDRESS_BY_ZIPCODE = 'cart:ACTION_ADDRESS_BY_ZIPCODE';
export const ACTION_CHANGE_PAYMENT_DATA = 'cart:ACTION_CHANGE_PAYMENT_DATA';
export const ACTION_PAYMENT_ADDRESS_BY_ZIPCODE = 'cart:ACTION_PAYMENT_ADDRESS_BY_ZIPCODE';
export const ACTION_SET_QS_COUPON = 'cart:ACTION_SET_QS_COUPON';
export const ACTION_INSTALLMENT_OPTIONS = 'cart:ACTION_INSTALLMENT_OPTIONS';

export const addOrRemoveProduct = (id, quantity, symbol, callback) => async (dispatch, getState) => {
  if (!quantity || quantity === 0) {
    quantity = 1;
  }

  const productById = getProductById(id);

  try {
    if (productById) {
      setDataLayer('add_to_cart', {
        ecommerce: {
          currency: 'BRL',
          items: parseItems([ productById ]),
          value: productById.value,
        }
      });

      if (isProduction()) {
        pixelTrack('AddToCart', {
          content_ids: productById.id,
          content_name: productById.name,
          value: productById.value,
        });
      }

      dispatch(increaseTimer());
    }
  } catch (e) {
    // todo: handle
  }

  let productsInCart = [
    ...getState().cart.products || [],
  ];

  if (!productsInCart.find((o) => o.id === id)) {

    if (symbol === CartSymbol.ADD) {
      productsInCart.push({
        ...productById,
        quantity,
      });

      productsInCart = reorderProductsPromotion(productsInCart, quantity);

    }
  } else {
    if (symbol === CartSymbol.REMOVE) {
      const parentProduct = productsInCart.find(p => p.id === id);

      for (let i = 0; i < productsInCart.length; i += 1) {
        if (parentProduct.quantity > 1) {
          continue;
        }

        const promotionProduct = checkPromotionProductRemoval(productById);

        if (!promotionProduct) {
          continue;
        }

        const index = productsInCart.findIndex(p => p.id === promotionProduct.promotionProductID);

        if (index === -1) {
          continue;
        }

        productsInCart.splice(index, 1);
      }
    }

    for (let i = 0; i < productsInCart.length; i += 1) {
      if (productsInCart[i].id === id) {
        if (symbol === CartSymbol.ADD) {
          productsInCart[i].quantity = productsInCart[i].quantity + quantity;
        } else {
          productsInCart[i].quantity = productsInCart[i].quantity - quantity;

          if (productsInCart[i].quantity <= 0) {
            productsInCart.splice(i, 1);
          }

          if (
            isPromotionalProduct(productsInCart[i]?.id) &&
            !productsInCart.some(product => product.id === productsInCart[i]?.productWithoutPromotionId)
          ) {
            productsInCart.splice(i, 1);
          }

          if (productsInCart.length === 1 && isPromotionalProduct(productsInCart[0]?.id)) {
            productsInCart.splice(0, 1);
          }
        }
      }
    }
  }

  dispatch({
    type: ACTION_CHANGE_PRODUCTS,
    payload: productsInCart,
  });

  await dispatch(calculateTheCart(() => {
    if (callback) callback();
  }));
  await dispatch(getInstalmentOptions());
};

export const replaceCartProducts = (payload, recurrent) => async (dispatch) => {
  if (payload && payload.length > 0) {
    payload.forEach(product => {
      const productById = getProductById(product.id);

      try {
        if (productById) {
          setDataLayer('add_to_cart', {
            ecommerce: {
              currency: 'BRL',
              items: parseItems([ productById ]),
              value: productById.value,
            }
          });
        }
      } catch (e) {
        // todo: handle
      }
    });
  }

  dispatch({
    type: ACTION_CHANGE_PRODUCTS,
    payload,
  });
  dispatch({
    type: ACTION_CHANGE_RECURRENT,
    payload: recurrent,
  });
  dispatch(calculateTheCart());
};

export const createDefaultCart = (products, callback) => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_PRODUCTS,
    payload: products,
  });

  if (callback && typeof callback === 'function') {
    callback();
  }
};

export const calculateTheCart = (callback) => async (dispatch, getState) => {
  dispatch(increaseLoading());

  try {
    const { products } = getState().cart;
    const coupon = getState().cart.coupon?.title;
    const zipcode = getState().cart.addressByZipcode?.zipcode;
    const { recurrent } = getState().cart;
    const { paymentData } = getState().cart;
    const { freightType } = getState().cart;

    const installments = paymentData &&
      paymentData.type === PaymentMethod.CREDIT &&
      !recurrent ? paymentData?.installments : 1;

    const payload = {
      products,
      coupon,
      zipcode,
      installments,
      recurrent: !hairKitCart(products) ? false : recurrent,
      freightType,
    };

    const values = await UtilsRequests.calculate(payload);

    if (values.availableFreights) {
      dispatch({
        type: ACTION_SET_FREIGHT_OPTIONS,
        payload: values.availableFreights,
      });
    }

    dispatch({
      type: ACTION_CHANGE_VALUES,
      payload: values,
    });

    dispatch({
      type: ACTION_CHANGE_FREIGHT,
      payload: values.freight,
    });

    dispatch({
      type: ACTION_CHANGE_FREIGHT_TYPE,
      payload: values.freightType,
    });

    const updatePayload = values.items.map((item) => ({
      ...getProductById(item.identifier),
      ...item,
    }));

    dispatch({
      type: ACTION_CHANGE_PRODUCTS,
      payload: updatePayload,
    });

    if (callback) callback();
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getInstalmentOptions = () => async (dispatch, getState) => {
  dispatch(increaseLoading());

  try {

    const { products, freightType } = getState().cart;

    const coupon = getState().cart.coupon?.title;
    const zipcode = getState().cart.addressByZipcode?.zipcode;

    const { recurrent } = getState().cart?.recurrent || {};
    const installmentsNoFees = [ 1,2,3 ];
    const installmentsWithFees = [ 4,5 ];

    const payload = {
      freightType,
      products,
      coupon,
      zipcode,
      recurrent: !hairKitCart(products) ? false : recurrent,
    };

    const installmentsNoFeesCalculated = await Promise.all(installmentsNoFees.map(async (installment) => {
      const { total } = await UtilsRequests.calculate({ ...payload, installments: installment });
      return {
        value: installment,
        label: `${installment}x ${toCurrencyLocale((total / installment).toFixed(2))} ${translate('SHARED.NO_FEES')}`
      };
    }));

    const installmentsWithFeesCalculated = await Promise.all(installmentsWithFees.map(async (installment) => {

      const { interestValue, total } = await UtilsRequests.calculate({ ...payload, installments: installment });
      return {
        value: installment,
        label: `${installment}x ${toCurrencyLocale((total / installment).toFixed(2))} ${translate('SHARED.WITH_FEES_OF')} ${toCurrencyLocale((interestValue).toFixed(2))}`
      };
    })
    );

    dispatch({
      type: ACTION_INSTALLMENT_OPTIONS,
      payload: installmentsNoFeesCalculated.concat(installmentsWithFeesCalculated),
    });

  } catch (e) {
    dispatch({
      type: ACTION_INSTALLMENT_OPTIONS,
      payload: [],
    });

    message.error(I18n.t('ERRORS.CALCULATE_INSTALLMENT'));

  } finally {
    dispatch(decreaseLoading());
  }
};

export const cleanTheCart = (keepPayment) => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_PRODUCTS,
    payload: [],
  });
  dispatch({
    type: ACTION_CHANGE_COUPON,
    payload: null,
  });
  dispatch({
    type: ACTION_CHANGE_FREIGHT,
    payload: null,
  });
  dispatch({
    type: ACTION_CHANGE_FREIGHT_TYPE,
    payload: null,
  });
  dispatch({
    type: ACTION_CHANGE_VALUES,
    payload: null,
  });
  dispatch({
    type: ACTION_CHANGE_RECURRENT,
    payload: false,
  });
  dispatch({
    type: ACTION_CHANGE_PRESCRIPTION,
    payload: null,
  });
  dispatch({
    type: ACTION_ADDRESS_BY_ZIPCODE,
    payload: null,
  });
  dispatch({
    type: ACTION_CHANGE_PAYMENT_DATA,
    payload: null,
  });
  dispatch({
    type: ACTION_PAYMENT_ADDRESS_BY_ZIPCODE,
    payload: null,
  });
  dispatch({
    type: ACTION_INSTALLMENT_OPTIONS,
    payload: [],
  });

  if (!keepPayment) {
    dispatch({
      type: ACTION_SAVE_PAYMENT_DETAILS,
      payload: null,
    });
  }

  dispatch(calculateTheCart());
};

export const getCouponByTitle = (data) => async (dispatch, getState) => {
  dispatch(increaseLoading());

  try {
    const payload = await CouponRequests.getCouponByTitle(data);

    if (payload.product) {
      const cartItems = getState().cart.products;

      const applicable = cartItems.find((cartItem) => cartItem.id === payload.product.id);

      if (
        !applicable ||
        applicable?.discountValue ||
        applicable?.discountPercentage ||
        applicable?.discountPercent
      ) {
        message.error(I18n.t('ERRORS.not_applicable_coupon'));
        return;
      }
    }

    dispatch({
      type: ACTION_CHANGE_COUPON,
      payload: payload && payload.title ? payload : null,
    });
  } catch (error) {
    message.error('Cupom inválido');
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeCoupon = () => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_COUPON,
    payload: null,
  });
};

export const changeRecurrent = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_RECURRENT,
    payload,
  });
};

export const changeQsCoupon = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_SET_QS_COUPON,
    payload,
  });
};

export const changePrescription = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_PRESCRIPTION,
    payload,
  });
};

export const changePaymentData = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_CHANGE_PAYMENT_DATA,
    payload,
  });
};

export const getAddressByZipcode = (value) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const { data } = await UtilsRequests.getAddressByCep(value);

    if (data.erro) {
      throw new Error();
    }

    const payload = {
      zipcode: removeSpecialChars(data.cep),
      uf: data.uf,
      city: data.localidade,
      street: data.logradouro,
      neighborhood: data.bairro,
    };

    dispatch({
      type: ACTION_ADDRESS_BY_ZIPCODE,
      payload,
    });

    return true;

  } catch (err) {
    dispatch({
      type: ACTION_SET_FREIGHT_OPTIONS,
      payload: [],
    });
    message.error('CEP inválido.');
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getPaymentAddressByZipcode = (value) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const { data } = await UtilsRequests.getAddressByCep(value);

    if (!data.erro) {
      const payload = {
        zipcode: removeSpecialChars(data.cep),
        uf: data.uf,
        city: data.localidade,
        street: data.logradouro,
        neighborhood: data.bairro,
      };

      dispatch({
        type: ACTION_PAYMENT_ADDRESS_BY_ZIPCODE,
        payload,
      });
    } else {
      message.error('CEP inválido.');
    }

  } catch (err) {
    message.error('CEP inválido.');
  } finally {
    dispatch(decreaseLoading());
  }
};

export const uploadPrescriptionFile = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await PrescriptionRequests.uploadPrescription(params);
    dispatch({
      type: ACTION_CHANGE_PRESCRIPTION,
      payload,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error((`${errors[err.message]}`));
    } else if (err && err.response && err.response.response && err.response.response.error && errors[err.response.response.error] !== undefined) {
      message.error((`${errors[err.response.response.error]}`));
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const addProductsInCart = (idProduct, quantity, oldData, productByID) => async (dispatch, getState) => {

  const productById = getProductById(idProduct);

  if (productById) {
    setDataLayer('add_to_cart', {
      ecommerce: {
        currency: 'BRL',
        items: parseItems([ productById ]),
        value: productById.value,
      },
    });
  }

  dispatch(increaseLoading());

  try {
    let payload = [];

    if (oldData && oldData.length > 0) {

      let aux = false;

      oldData.forEach(item => {
        if (item.id === idProduct) {
          aux = true;
          item['quantity'] = item.quantity + quantity;
        }
      });

      if (aux === false) {
        oldData.push({
          ...productByID,
          quantity
        });
      }

      payload = oldData;
    } else {
      payload = [ {
        ...productByID,
        prescription: productByID.prescriptionRequired,
        isKit: !!productByID.subscriptionDiscount,
        quantity
      } ];
    }

    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      products: payload,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      recurrent: calculatedProducts && calculatedProducts.recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getPrescriptionDetails = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await PrescriptionRequests.getPrescriptionById(id);
    dispatch({
      type: ACTION_CHANGE_PRESCRIPTION,
      payload,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error((`${errors[err.message]}`));
    } else if (err && err.response && err.response.response && err.response.response.error && errors[err.response.response.error] !== undefined) {
      message.error((`${errors[err.response.response.error]}`));
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

/*export const replaceCartProducts = (products, recurrent) => async (dispatch, getState) => {

  setDataLayer('add_to_cart', {
    ecommerce: {
      currency: 'BRL',
      items: parseItems(products),
      value: products.reduce((a, b) => a + b.value, 0),
    },
  });

  dispatch(increaseLoading());
  try {
    dispatch(deleteAllProductsInCart());
    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      products,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload: products,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(decreaseLoading());
  }
};*/

export const recalculateCart = (recurrent, coupon, zipcode, installments) => async (dispatch, getState) => {

  try {
    const cartReducer = getState().cart;
    const { freightType } = getState().cart;

    const payload = {
      products: cartReducer.productsInCart && cartReducer.productsInCart.filter((o) => o.quantity > 0),
      recurrent,
      zipcode,
      coupon,
      installments,
      freightType,
    };

    if (recurrent) {
      payload.coupon = null;
    }

    dispatch(calculate(payload, true));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const calculate = (data, updateProducts) => async (dispatch, getState) => {
  dispatch(increaseLoading());
  let payload;

  try {

    const filteredData = {
      ...data,
      products: data.products && data.products.length > 0 ? data.products.filter((o) => o.quantity > 0) : [],
      freightType: data.freightType ?? 0,
      zipcode: data.zipcode,
    };

    if (data && data.recurrent) {
      filteredData.coupon = null;
    }

    payload = await UtilsRequests.calculate(filteredData);

    if (payload.availableFreights) {
      dispatch({
        type: ACTION_SET_FREIGHT_OPTIONS,
        payload: payload.availableFreights,
      });
    }

    if (data.zipcode) {
      payload = {
        ...payload,
        zipcode: data.zipcode,
      };
    }

    if (data.freightType) {
      payload = {
        ...payload,
        freightType: data.freightType,
      };
    }

    if (data.installments) {
      payload = {
        ...payload,
        installments: data.installments,
      };
    }

    if (data && data.recurrent) {
      payload.coupon = null;
    }

    if (updateProducts) {
      const cartReducer = getState().cart;

      const oldItems = cartReducer.productsInCart;
      const newItems = payload.items;

      oldItems.forEach((oldItem, oldItemIndex) => {
        newItems.forEach((newItem) => {
          if (newItem.identifier === oldItem.id) {
            oldItems[oldItemIndex] = {
              ...newItem,
              ...oldItem,
              price: parseFloat(newItem.value) / newItem.quantity,
              value: parseFloat(newItem.value) / newItem.quantity,
            };
          }
        });
      });

      dispatch({
        type: ACTION_CALCULATE_PRODUCTS_CART,
        payload: {
          ...payload,
          items: oldItems,
          recurrent: data.recurrent,
          zipcode: data.zipcode,
          installments: data.installments,
          freightType: data.freightType,
        },
      });
    } else {
      dispatch({
        type: ACTION_CALCULATE_PRODUCTS_CART,
        payload: {
          ...payload,
          recurrent: data.recurrent,
          zipcode: data.zipcode,
          installments: data.installments,
          freightType: data.freightType,
        },
      });
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error((`${errors[err.message]}`));
    } else if (err && err.response && err.response.response && err.response.response.error && errors[err.response.response.error] !== undefined) {
      message.error((`${errors[err.response.response.error]}`));
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }

  return payload;
};

export const calculateFreight = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let payload;

  try {
    const filteredData = {
      ...data,
      products: data.products && data.products.length > 0 ? data.products.filter((o) => o.quantity > 0) : [],
      freightType: data.freightType ?? 0,
      zipcode: data.zipcode,
    };

    if (data && data.recurrent) {
      filteredData.coupon = null;
    }

    dispatch({
      type: ACTION_CHANGE_FREIGHT_TYPE,
      payload: data.freightType,
    });

    payload = await UtilsRequests.calculate(filteredData, true);

    if (payload.availableFreights) {
      dispatch({
        type: ACTION_SET_FREIGHT_OPTIONS,
        payload: payload.availableFreights,
      });
    }

    if (payload.items && payload.items.length > 0) {
      dispatch({
        type: ACTION_CALCULATE_PRODUCTS_CART,
        payload: {
          ...payload,
          zipcode: data.zipcode,
          recurrent: data.recurrent,
          installments: data.installments,
        },
      });
    }

    if (callback && typeof callback === 'function') {
      callback(payload);
    }
  } catch (err) {
    payload = {
      error: true,
    };
  } finally {
    dispatch(decreaseLoading());
  }

  return payload;
};

export const getFreightAmount = (data) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await UtilsRequests.calculate(data, true);

    if (payload.availableFreights) {
      dispatch({
        type: ACTION_SET_FREIGHT_OPTIONS,
        payload: payload.availableFreights,
      });
    }

    dispatch({
      type: ACTION_CHANGE_FREIGHT,
      payload: payload.freight,
    });

    dispatch({
      type: ACTION_CHANGE_FREIGHT_TYPE,
      payload: payload.freightType,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const deleteProductInCartByID = (idProduct, data) => async (dispatch, getState) => {

  const productById = getProductById(idProduct);

  setDataLayer('remove_from_cart', {
    ecommerce: {
      currency: 'BRL',
      items: parseItems([ productById ]),
      value: productById.value,
    }
  });

  try {
    data.forEach((item, index) => {
      if (item.id === idProduct) {
        data.splice(index, 1);
      }
    });

    const payload = data;

    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      products: payload,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      recurrent: calculatedProducts && calculatedProducts.recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllProductsInCart = () => ({
  type: ACTION_DELETE_ALL_PRODUCTS_IN_CART,
});

export const incrementQuantityProductsInCartByID = (idProduct, quantity, oldData, recurrent) => async (dispatch, getState) => {

  const productById = getProductById(idProduct);

  if (productById) {
    setDataLayer('add_to_cart', {
      ecommerce: {
        currency: 'BRL',
        items: parseItems([ productById ]),
        value: productById.value,
      }
    });
  }

  try {
    oldData.forEach(item => {
      if (item.id === idProduct) {
        item['quantity'] = item.quantity + quantity;
      }
    });

    const payload = oldData;

    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      coupon: calculatedProducts && calculatedProducts.coupon && calculatedProducts.coupon.title,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      products: payload,
      recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }, true));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload,
    });

  } catch (error) {
    console.log(error);
  }
};

export const setOneQuantityByProduct = () => async (dispatch, getState) => {
  try {
    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    const calculatedItems = calculatedProducts && calculatedProducts.items.filter((o) => o.quantity > 0).map((o) => ({
      ...getProductById(o.identifier || o.id),
      ...o,
      quantity: 1,
    }));

    dispatch(calculate({
      coupon: calculatedProducts && calculatedProducts.coupon && calculatedProducts.coupon.title,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      products: calculatedItems,
      recurrent: calculatedProducts.recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }, true));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload: calculatedItems,
    });
  } catch (error) {
    console.log(error);
  }
};

export const decrementQuantityProductsInCartByID = (idProduct, quantity, oldData, recurrent) => async (dispatch, getState) => {

  const productById = getProductById(idProduct);

  setDataLayer('remove_from_cart', {
    ecommerce: {
      currency: 'BRL',
      items: parseItems([ productById ]),
      value: (productById.value && productById.value.toString()) || '',
    }
  });

  try {
    oldData.forEach(item => {
      if (item.id === idProduct) {
        item['quantity'] = item.quantity - quantity;
      }
    });

    const payload = oldData;

    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      coupon: calculatedProducts && calculatedProducts.coupon && calculatedProducts.coupon.title,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      products: payload,
      recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }, true));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addHairKitProductsInCart = (idProduct, quantity, oldData, productByID, recurrent) => async (dispatch, getState) => {

  const productById = getProductById(idProduct);

  if (productById) {
    setDataLayer('add_to_cart', {
      ecommerce: {
        currency: 'BRL',
        items: parseItems([ productById ]),
        value: productById.value,
      }
    });
  }

  dispatch(increaseLoading());

  try {
    let payload = [];
    let aux = false;
    oldData.forEach(item => {
      if (item.id === idProduct) {
        aux = true;
        item['quantity'] = item.quantity + quantity;
      }
    });

    if (aux === false) {
      oldData.push({
        id: productByID.id,
        picUrl: productByID.picUrl || '',
        image: productByID.image || '',
        images: productByID.images || '',
        name: productByID.name,
        value: productByID.value,
        description: productByID.description,
        category: productByID.category,
        prescription: productByID.prescription,
        isKit: !!productByID.subscriptionDiscount,
        quantity
      });
    }

    payload = oldData;

    const cartReducer = getState().cart;
    let calculatedProducts;

    if (cartReducer && cartReducer.calculatedProducts) {
      calculatedProducts = cartReducer.calculatedProducts;
    }

    dispatch(calculate({
      products: payload,
      zipcode: calculatedProducts && calculatedProducts.zipcode,
      freight: calculatedProducts && calculatedProducts.freight,
      recurrent: calculatedProducts && calculatedProducts.recurrent || recurrent,
      installments: calculatedProducts && calculatedProducts.installments,
    }));

    dispatch({
      type: ACTION_ADD_PRODUCTS_IN_CART,
      payload,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(decreaseLoading());
  }
};

/* eslint-disable react/no-danger-with-children */
import { Collapse, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  ArrowRightOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet-async';
import * as ActionsCart from '../../../redux/actions/cart';
import ProductMobile from './ProductMobile';
import { ReactComponent as CartIcon } from '../../../assets/svg/new_menu/ic_header_bag.svg';
import { getProductBySlug } from '../../../services/product';
import { Col, Container, Row } from 'react-bootstrap';
import { toCurrencyLocale } from '../../../utils/masks';
import Config from '../../../config/app.config';
import AmountPicker from '../../../components/AmountPicker/AmountPicker';
import { keepOnlyNumbers } from '../../../utils/string';
import { parseItems, setDataLayer } from '../../../services/analytics';
import { CartSymbol } from '../../../enum/cartSymbol';
import RecomendedProducts from '../../../components/RecomendedProducts/RecomendedProducts';
import AuthService from '../../../services/auth';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import CartModal from '../../../components/CartModal/CartModal';
import { translate } from '../../../services/i18n';
import { AuthActions, ProductModalActions } from '../../../redux/actions';
import { PageMaintenceIndicator } from '../../../components/PageMaintenceIndicator/PageMaintenceIndicator';
import { UnauthenticatedBlockingModal } from '../../../components/UnauthenticatedBlockingModal/UnauthenticatedBlockingModal';

const { Panel } = Collapse;

const Product = (props) => {
  const { pathname } = useLocation();
  const { slug } = useParams();
  const history = useHistory();
  const { calculateFreight } = props;
  const [ product, setProduct ] = useState(null);
  const [ quantity, setQuantity ] = useState(1);
  const [ zipcode, setZipcode ] = useState('');
  const [ freightData, setFreightData ] = useState(null);
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(0);
  const [ padding, setPadding ] = useState(0);
  const [ questionsOpen, setQuestionsOpen ] = useState(null);
  const [ cartModalOpen, setCartModalOpen ] = useState(false);
  const [ variantModal, setVariantModal ] = useState(false);
  const [ activeCollapse, setActiveCollapse ] = useState([]);
  const [ selectedVariant, setSelectedVariant ] = useState(0);
  const [ isMaintencePage, setMaintencePage ] = useState(false);

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    document.querySelector('body').scrollTo(0, 0);
    const currentProduct = getProductBySlug(slug);

    if (!currentProduct) {
      window.location.href = '/';
    } else {
      if (currentProduct[currentProduct.slugToUse || 'slug'] !== slug) {
        setMaintencePage(true);
        return;
      }

      setProduct(currentProduct);
    }

    setQuantity(1);
    setFreightData(null);
    setSelectedImageIndex(0);
    setQuestionsOpen(null);
    setCartModalOpen(false);
    setActiveCollapse([]);
  }, [ pathname ]);

  useEffect(() => {
    if (product) {
      setDataLayer('view_item', {
        ecommerce: {
          items: parseItems([ product ]),
        },
      });
      props.userActivity({
        content: `product: ${product.name}`,
        source: pathname
      }, facebookUserActivity.viewContent);
    }
  }, [ product ]);

  const addProductToCart = (productRecomended) => {
    const { addProductsInCart, isVisible, setVisibleModal, setInvisibleModal } = props;

    if (!isVisible && !product.hasVariant && !product.isVariant) {
      setVisibleModal(true);
    } else {
      setInvisibleModal(false);
    }

    if (product.hasVariant) return setVariantModal(true);

    if ((!props.me || !props.me?.notNeedMedicalPrescription) && product.prescriptionRequired) {
      setCartModalOpen(true);
    } else {
      if (productRecomended) {
        addProductsInCart(productRecomended, 1);
      } else {
        addProductsInCart(product.id, quantity);
      }
    }

    if (pathname === '/produtos/sleep-gummy/noprescription') {
      setCartModalOpen(false);
      addProductsInCart(product.id, quantity);
    }
  };

  const addVariantToCart = () => {
    const { addProductsInCart } = props;

    if (!product.hasVariant) return;

    if ((!selectedVariant && selectedVariant !== 0) || !product.hasVariant.variants[selectedVariant]) return message.error({ content: 'Selecione uma opção', className: 'ant-custom-style' });

    addProductsInCart(product.hasVariant.variants[selectedVariant].id, product.hasVariant.variants[selectedVariant].qtd);
    props.setVisibleModal(true);
  };

  const onCalculateFreight = () => {
    if (zipcode) {
      const productToCalculate = (product.hasVariant && product.hasVariant.variants[selectedVariant]) || product;
      calculateFreight(zipcode, [ productToCalculate ], (response) => {
        if (response && !response.error) {
          setFreightData(response.freight);
          props.userActivity({
            content: `product-freight: to ${response.freight.city}`,
            source: pathname
          }, facebookUserActivity.viewContent);
        } else {
          setFreightData({ error: true });
        }
      });
    }
  };

  const calculateInstallmentValue = (value, quantity) => {
    return Number((value / quantity).toFixed(2));
  };

  const setCarouselImage = (index) => {
    setSelectedImageIndex(index);
  };

  const splitProductName = (productName, first) => {
    productName = productName.split(' ');

    if (first && productName.length > 0) {
      return productName[0];
    } else if (!first && productName.length > 0) {
      let lastName = '';
      productName.map((item, index) => {
        if (index !== 0) {
          lastName += ` ${item}`;
        }
      });
      return lastName;
    }

    return productName;
  };

  const openQuestion = (index) => {
    if (index === questionsOpen) {
      setQuestionsOpen(null);
    } else {
      setQuestionsOpen(index);
    }
  };

  const prescriptionRedirect = (prescriptionLink) => {
    const { host, protocol } = window.location;

    if (prescriptionLink) {
      window.open(`${protocol}//${host}/avaliacao?fromProduct=${prescriptionLink}`, '_blank');
    } else {
      window.open(`${protocol}//${host}/avaliacao`, '_blank');
    }
  };

  if (isMaintencePage) {
    return (
      <PageMaintenceIndicator />
    );
  }

  const canShowPage = (props.loading && !props.me) || props.me || props.fakeEmail;

  return (
    <div>
      <div disabled
        className={`product mobile-hidden ${!canShowPage && 'blured-container'}`}>
        {!canShowPage && (
          <UnauthenticatedBlockingModal onSubmit={props.setFakeEmail}
            onCancel={() => history.push('/')}/>
        )}

        <Helmet>
          <title>
            {`${Config.appName} - ${product && product.name}`}
          </title>
          <style type="text/css">
            {`
              #blip-chat-container #blip-chat-open-iframe {
                display: none !important;
              }
            `}
          </style>
        </Helmet>

        <div className="product__info">
          <div className="product__info__left">
            <div className="product__info__carousel">
              {product && product.carouselImages && (
                <>
                  <div className="product__info__carousel__main">
                    <img className="product__info__carousel__main__image"
                      src={product.carouselImages[selectedImageIndex].banner} />
                  </div>
                  <div
                    className="product__info__carousel__clickable"
                  >
                    {product.carouselImages.map((item, index) =>
                      <div
                        key={index}
                        className="product__info__carousel__clickable__item"
                        onClick={() => {
                          setCarouselImage(index);
                          props.userActivity({
                            content: `product-image: ${index + 1}`,
                            source: pathname
                          }, facebookUserActivity.viewContent);
                        }}
                      >
                        <img
                          className={`product__info__carousel__clickable__item__img ${selectedImageIndex === index && 'product__info__carousel__clickable__item__img--selected'}`}
                          src={item.mini}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {product && product.warning &&
              <div className='product__info__left__warning'
                style={{ background: product.warning.background, color: product.warning.color }}>
                <p className='product__info__left__warning__text'>{product.warning.text}</p>
              </div>
            }
            <div className={'product__info__left__advantages'}>
              <div>
                {!!product?.advantagesHeader && (
                  <h2>{product.advantagesHeader}</h2>
                )}
                <div className={'product__info__left__advantages__items-box'}>
                  {product && product.advantages && (
                    product.advantages.map((item, index) =>
                      <div
                        key={index}
                        className="product__info__left__advantages__item"
                      >
                        <div className="product__info__left__advantages__item__img-container">
                          <img className="product__info__left__advantages__item__img-container__img"
                            src={item.icon}
                            alt={item.text} />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {product && product.depoiments && (
              <div className="product__info__left__depoiments">
                <h3 className="product__info__left__depoiments__title">
                  {product.depoiments.title}
                </h3>
                <div className="product__info__left__depoiments__rating">
                  {product.depoiments.ratings.map((item, index) =>
                    <div
                      key={index}
                      className="product__info__left__depoiments__rating__item"
                    >
                      <div className="product__info__left__depoiments__rating__item__stars">
                        <span className="product__info__left__depoiments__rating__item__stars__charcters">
                          {item.stars}
                        </span>
                      </div>
                      <div className="product__info__left__depoiments__rating__item__rating">
                        <p className="product__info__left__depoiments__rating__item__rating__text">
                          {item.text}
                        </p>
                      </div>
                      <div className="product__info__left__depoiments__rating__item__name">
                        {item.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {product && product.commonQuestions && (
              <div className="product__info__left__questions">
                <div className="product__info__left__questions__image-container">
                  <img
                    className="product__info__left__questions__image-container__img"
                    src={product.commonQuestions.banner}
                    alt=""
                  />
                </div>
                <div className="product__info__left__questions__container">
                  {product.commonQuestions.questions.map((item, index) =>
                    <div
                      key={index}
                      onClick={() => {
                        openQuestion(index);
                        props.userActivity({
                          content: `product-question: ${item.name}`,
                          source: pathname
                        }, facebookUserActivity.viewContent);
                      }}
                      className="product__info__left__questions__container__item"
                    >
                      <span
                        className={
                          `product__info__left__questions__container__item__text ${questionsOpen === index && 'product__info__left__questions__container__item__text--bold'}`
                        }
                      >
                        {item.name}
                      </span>
                      <span className="product__info__left__questions__container__item__icon">
                        <ArrowDown />
                      </span>
                      {questionsOpen === index && (
                        <p className="product__info__left__questions__container__item__description">
                          {ReactHtmlParser(item.description)}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {product && product.howWorks && (
              <div className="product__info__left__how-works">
                <h3 className='how_works_header'>
                  Texto informativo com revisão literária
                </h3>
                <h1 className="product__info__left__how-works__title">{product.howWorks.title}</h1>
                <div className="product__info__left__how-works__image-container">
                  <img className="product__info__left__how-works__image"
                    src={product.howWorks.img}
                    alt="" />
                </div>
                <div className="product__info__left__how-works__group">
                  {product && product.howWorksInfo.map((item, index) =>
                    <div
                      key={index}
                      className="product__info__left__how-works__item"
                    >
                      <div className="product__info__left__how-works__item__content">
                        <h4 className="product__info__left__how-works__item__content__title">
                          {item.title}
                        </h4>
                        <p className="product__info__left__how-works__item__content__text">
                          {ReactHtmlParser(item.text)}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {product && product.about && (
              <div className="product__info__left__about">
                {product.about.map((item, index) =>
                  <div
                    key={index}
                    className="product__info__left__about__item"
                  >
                    <div className="product__info__left__about__item__image-container">
                      <img
                        className="product__info__left__about__item__image-container__img"
                        src={item.image}
                        alt=""
                      />
                    </div>
                    <div className="product__info__left__about__item__content">
                      <h4 className="product__info__left__about__item__content__title">
                        {item.title}
                      </h4>
                      <p className="product__info__left__about__item__content__text">
                        {ReactHtmlParser(item.text)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {product && product.whoUses && (
              <div className="product__info__left__who-uses">
                <img className="product__info__left__who-uses__image"
                  src={product.whoUses.image}
                  alt="" />
              </div>
            )}

            {product && product.wyntk && (
              <div className="product__info__left__wyntk">
                <img className="product__info__left__wyntk__image"
                  src={product.wyntk}
                  alt="" />
              </div>
            )}

          </div>

          <div className="product__info__right">
            <div className="product__info__right__inner">
              <div style={{ height: `${padding }px` }}></div>
              {cartModalOpen && (
                <CartModal
                  addToCart={props.addProductsInCart}
                  setCartModalOpen={setCartModalOpen}
                  prescriptionRedirect={prescriptionRedirect}
                  product={product}
                  quantity={quantity}
                />
              )}
              {variantModal && (
                <CartModal
                  setVariantModal={setVariantModal}
                  product={product}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={setSelectedVariant}
                  addVariantToCart={addVariantToCart}
                  isVariant
                />
              )}
              {!cartModalOpen && !variantModal &&
                <>
                  <div className="product__info__right__inner__title">
                    <div className="product__info__right__inner__title__text">
                      <div className="product__info__right__inner__title__text__bold">
                        {product && product.name && splitProductName(product.name, true)}
                      </div>
                      <div className="product__info__right__inner__title__text">
                        {product && product.name && splitProductName(product.name)}
                      </div>
                    </div>
                    <div className="product__info__right__inner__title__rating">
                      <strong>{product && product.rating}</strong>/5.0
                      <span className="product__info__right__inner__title__rating__star">★</span>
                    </div>
                  </div>
                  <div className="product__info__right__inner__pricing">
                    <div className="product__info__right__inner__pricing__left">
                      {product && product.oldValue && (
                        <div className="product__info__right__inner__pricing__left__old-price">
                          R${product && product.oldValue}0
                        </div>
                      )}
                      {product && product.hasVariant && (
                        <div className="product__info__right__inner__pricing__left__old-price product__info__right__inner__pricing__left__old-price--not-underlined">
                          A partir de
                        </div>
                      )}
                      <div className={`product__info__right__inner__pricing__left__price ${product && product.hasVariant && 'product__info__right__inner__pricing__left__price--variant'}`}>
                        <div className='product__info__right__inner__pricing__left__price__box'>
                          <i>{translate('PRODUCTS.PRICE.START')}</i>
                          <b>
                            R${product && product.value}0
                          </b>
                        </div>
                        {product && product.hasVariant && product.hasVariant.freeFreightLabel && (
                          <img className="product__info__right__inner__pricing__left__price__label"
                            alt='freight-label'
                            src={product.hasVariant.freeFreightLabel} />
                        )}
                      </div>
                    </div>
                    {product && product.soldOff ? (
                      <div className="product__info__right__inner__pricing__right--sold-off">
                        ESGOTADO
                      </div>
                    ) : (
                      <div className="product__info__right__inner__pricing__right">
                        {product && !product.hasVariant && (
                          <AmountPicker
                            onClickRemove={() => setQuantity(quantity - 1)}
                            onClickAdd={() => setQuantity(quantity + 1)}
                            value={quantity}
                            blockZero
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="product__info__right__inner__calc">
                    <div className="product__info__right__inner__calc__installments">
                      3x de <strong>{product && toCurrencyLocale(calculateInstallmentValue(product.value, 3))}</strong> sem juros
                    </div>
                    <div className="product__info__right__inner__calc__installments__add-to-cart">
                      <button
                        className={
                          product && product.soldOff ?
                            'product__info__right__inner__calc__installments__add-to-cart__popover__button--disabled'
                            :
                            'product__info__right__inner__calc__installments__add-to-cart__popover__button'
                        }
                        onClick={() => addProductToCart()}
                        disabled={product && product.soldOff}
                      >
                        {product && product.hasVariant ? (
                          <div className="product__info__right__inner__calc__installments__add-to-cart__popover__button--spaced">{translate('CHOOSE_QUANTITY')}</div>
                        ) : (
                          <>{translate('ADD_TO_BAG')} &nbsp;&nbsp;<CartIcon /></>
                        )}
                      </button>
                    </div>
                    <div className="product__info__right__inner__calc__qtd">
                      <div className="product__info__right__inner__calc__qtd__container">
                        <img
                          src={product && product.productInfo.img}
                          className="product__info__right__inner__calc__qtd__container__img"
                          alt={product && product.productInfo.text}
                        />
                      </div>
                    </div>
                    <div className="product__info__right__inner__calc__product-text">
                      {product && product.productInfo && product.productInfo.text && product.productInfo.text.length > 0 && (
                        product.productInfo.text.map(item => <>{ReactHtmlParser(item)} </>)
                      )}
                    </div>
                    <div className="product__info__right__inner__calc__product-text">
                      {product && ReactHtmlParser(product.productInfo?.subText)}
                    </div>
                  </div>
                  <div className="product__info__right__inner__calc">
                    {product && (
                      <div className="product__info__right__inner__calc__form">
                        {product && product.justMen && (
                          <img className="product__info__right__inner__calc__form__just-men"
                            src={product && product.justMen.img}
                            alt={'male female'} />
                        )}
                        <div className="product__info__right__inner__calc__form__container">
                          <div className="product__info__right__inner__calc__form__input">
                            <input
                              className={`product__info__right__inner__calc__form__input__element ${zipcode !== '' && 'product__info__right__inner__calc__form__input__element--hidden'}`}
                              type="text"
                              value={zipcode}
                              placeholder="Digite o CEP de entrega"
                              onChange={(e) => (e.target.value && e.target.value.length <= 8 || !e.target.value) && setZipcode(keepOnlyNumbers(e.target.value))}
                            />
                          </div>
                          <div className="product__info__right__inner__calc__form__button">
                            <button
                              className="product__info__right__inner__calc__form__button__inner"
                              onClick={() => onCalculateFreight()}
                              disabled={zipcode?.length < 8}
                            >
                              <ArrowRightOutlined />
                            </button>
                          </div>
                          <div className="product__info__right__inner__calc__form__container__text-shipping">
                            <p>{translate('COMPONENTS.PRODUCT.SHIPPING.DESCRIPTION')}</p>
                          </div>
                        </div>
                        {freightData && !freightData.error && (
                          <div className="product__info__right__inner__calc__form__freight-success">
                            <div className="product__info__right__inner__calc__form__freight-success__city">
                              Região: {freightData.city}
                            </div>
                            <div className="product__info__right__inner__calc__form__freight-success__data">
                              Normal - {Number(freightData.deadline)} dias úteis - <strong>{freightData.freight === 0 ? 'Frete Grátis' : toCurrencyLocale(freightData.freight)}</strong>
                            </div>
                          </div>
                        )}
                        {freightData && freightData.error && (
                          <div>
                            <div className="product__info__right__inner__calc__form__freight-error">
                              Querido The Lovers, ainda não operamos na sua região, mas queremos chegar ai! Acreditamos que
                              todos os brasileiros devam se cuidar de forma prática e segura.
                              <br />
                              Estamos trabalhando duro para atingirmos todo o Brasil até o final do ano.
                            </div>
                            <br />
                            <div className="product__info__right__inner__calc__form__freight-normal">
                              {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.CONTACT_MESSAGE')}&nbsp;
                              <a href="mailto:memanda@themens.com.br">memanda@themens.com.br</a>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {product && product.hasVariant && product.hasVariant.whoCanUse && (
                      <img className="product__info__right__inner__calc__who-can-use"
                        src={product.hasVariant.whoCanUse}
                        alt={'male female'} />
                    )}
                  </div>
                </>
              }
              <div className="product__info__right__inner__calc__safe-buy">
                <img className="product__info__right__inner__calc__safe-buy__img"
                  src="/assets/img/safe-buy.png"
                  alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="product__faq">
          <Container>
            <Row>
              <Col>
                <div className="product__faq__title">
                  Perguntas frequentes
                </div>
              </Col>
            </Row>
            <Row>
              {product?.faq?.map((faqItem, faqItemIndex) => (
                <Col
                  lg={6}
                  key={faqItemIndex.toString()}
                >
                  <Collapse
                    className="product__faq__collapse"
                    bordered={false}
                    accordion
                    onChange={(e) => {
                      setActiveCollapse(e);
                      props.userActivity({
                        content: `product-faq: ${faqItem.title}`,
                        source: pathname
                      }, facebookUserActivity.viewContent);
                    }}
                    activeKey={activeCollapse}
                  >
                    <Panel
                      className="product__faq__collapse__panel"
                      header={faqItem.title}
                      key={faqItemIndex.toString()}
                    >
                      {ReactHtmlParser(faqItem.description)}
                    </Panel>
                  </Collapse>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="product__phrase mobile-hidden">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <img
                  className="product__phrase__img"
                  src="/assets/img/products-images/the-mens-delivery-phrase.jpg"
                />
              </Col>
            </Row>
          </Container>
        </div>

        {!!product?.otherInformations?.length && (
          <Container className='other-informations-box'>
            {product.otherInformations.map((info, index) => (
              <Row key={index}
                className="justify-content-center"><p className=''>{info}</p></Row>
            ))}
          </Container>
        )}

        {product?.warnings && (
          <div className='warnings-text-box'>
            <h1>{translate('PRODUCTS.WARNING_HEADER')}</h1>
            {product.warnings.map((warn, index) => <p key={index}>{warn}</p>)}
          </div>
        )}

        <div className="mobile-hidden">
          <RecomendedProducts message={props.message} />
        </div>

        <div className="product__floating-bar mobile-visible">
          <div className="product__floating-bar__inner">
            <div className="product__floating-bar__inner__infos">
              <div className="product__floating-bar__inner__infos__name">
                {product && product.name}
              </div>
              <div className="product__floating-bar__inner__infos__pricing">
                <div className="product__floating-bar__inner__infos__pricing__installments">
                  3x de {product && toCurrencyLocale(calculateInstallmentValue(product.value, 3))} sem juros
                </div>
                <div className="product__floating-bar__inner__infos__pricing__price">
                  {product && toCurrencyLocale(product.value)} à vista
                </div>
              </div>
            </div>
            <div className="product__floating-bar__inner__action">
              <Popconfirm
                className="product__popover"
                title="Adicionado no carrinho! Deseja fazer o checkout agora?"
                onConfirm={() => history.push('/checkout/revisao')}
                okText="Sim, ir para o carrinho!"
                cancelText="Não"
                placement="topRight"
              >
                <button
                  className="product__floating-bar__inner__action__button"
                  onClick={() => addProductToCart()}
                  type="button"
                >
                  + <ShoppingCartOutlined />
                </button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
      <div className={`product-mobile mobile-visible ${!canShowPage && 'blured-container'}`}>
        {!canShowPage && (
          <UnauthenticatedBlockingModal onSubmit={props.setFakeEmail}
            onCancel={() => history.push('/')}/>
        )}

        {product && (
          <ProductMobile message={props.message}
            product={product}
            addProductsInCart={props.addProductsInCart}
            calculateFreight={calculateFreight} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  quantById: state.product.quantById,
  loading: state.loading,
  me: state.auth.me,
  fakeEmail: state.auth.fakeEmail,
  isVisible: state.productModal
});

const mapDispatchToProps = (dispatch) => ({
  addProductsInCart: (id, quantity) => dispatch(ActionsCart.addOrRemoveProduct(id, quantity, CartSymbol.ADD)),
  calculateFreight: (zipcode, products, callback) => dispatch(ActionsCart.calculateFreight({
    products,
    calculateFreight: true,
    zipcode
  }, callback)),
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
  setInvisibleModal: () => dispatch(ProductModalActions.hideModalProduct()),
  setFakeEmail: (email) => dispatch(AuthActions.setFakeEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);

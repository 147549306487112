import UtilsRequests from '../../api/utils';
import { decreaseLoading, increaseLoading } from './loading';
import { I18n } from 'react-redux-i18n';
import { message } from 'antd';
import TransactionRequests from '../../api/transaction';

export const UTILS_SAVE_BASE64 = 'UTILS_SAVE_BASE64';

export const calculate = (data) => async (dispatch) => {
  dispatch(increaseLoading());
  let response;

  try {
    const filteredData = {
      ...data,
      products:
        data.products && data.products.length > 0
          ? data.products.filter((o) => o.quantity > 0)
          : [],
    };

    response = await UtilsRequests.calculate(filteredData);
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }

  return response;
};

export const uploadImageBase64 = (photoDataBase64) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const body = {
      file: photoDataBase64,
      isPrivate: true,
    };

    const data = await UtilsRequests.uploadImageBase64(body);

    return data;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const saveImageBase64 = (imageBase64, fileList) => async (dispatch) => {
  dispatch(increaseLoading());

  if (fileList) {
    try {
      dispatch({
        type: UTILS_SAVE_BASE64,
        payload: fileList,
      });
    } catch (error) {
      //
    } finally {
      dispatch(decreaseLoading());
    }
  } else {
    try {
      dispatch({
        type: UTILS_SAVE_BASE64,
        payload: imageBase64,
      });
    } catch (error) {
      //
    } finally {
      dispatch(decreaseLoading());
    }
  }
};

export const getAddressByCep = (cep) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const { data } = await UtilsRequests.getAddressByCep(cep);
    return data;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getSecureImageByFileName = (fileName) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const params = {
      fileName,
    };
    const data = await UtilsRequests.getSecureUrlImage(params);
    return data;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getMyCards = () => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const data = await TransactionRequests.getAllMineCardsCredit();
    return data;
  } catch (err) {
    console.log('erro', err);
  } finally {
    dispatch(decreaseLoading());
  }
};

import ReactGA from 'react-ga';
import { getPathname } from './browserHistory';
import { getProductCategoryTitle } from '../enum/productCategory';
import { getProductById } from './product';
import { store } from '../../index';

export const webLogEvent = (actionName, component) => {
  ReactGA.event({
    action: actionName,
    category: 'EVENT_LOG',
    label: component,
    value: getPathname
  });
};

export const setProp = (name, value) => {
  ReactGA.set({ [name]: value });
};

export const webPageView = (page) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(page);
  }
};

export const dataLayer = window.dataLayer || [];

export const setDataLayer = (event, data) => {

  const user = {};

  if (store) {
    const state = store.getState();

    if (state && state.auth && state.auth.me && state.auth.me.id) {
      const { me } = state.auth;

      user.id = me.id;
      user.email = me.email;
      user.phone = `55${me.phone}`;

      user.firstName = me.name.split(' ') && me.name.split(' ').length > 0
        ? me.name.split(' ')[0]
        : me.name;
      user.lastName = me.name.split(' ') && me.name.split(' ').length > 0
        ? me.name.split(' ')[me.name.split(' ').length - 1]
        : '';

      user.street = `${me.street}, ${me.addressNumber}`;
      user.city = me.city;
      user.uf = me.uf;
      user.zipcode = me.zipcode;
      user.country = 'BR';
    }
  }

  try {
    const payload = {
      event,
      ...data,
    };

    if (user && Object.keys(user).length > 0 && payload && event !== 'view_cart') {
      payload.user_id = user.id;
      payload.email = user.email;
      payload.phone_number = user.phone;

      payload.address = {
        first_name: user.firstName,
        last_name: user.lastName,
        street: user.street,
        city: user.city,
        country: user.country,
        region: user.uf,
        postal_code: user.zipcode,
      };

    } else {
      delete payload.user_id;
      delete payload.email;
      delete payload.phone_number;
      delete payload.address;
    }

    const { hostname } = window.location;

    if (hostname !== 'themens.com.br' && hostname !== 'www.themens.com.br') {
      console.info('Datalayer debug', payload);
    }

    dataLayer.push(payload);
  } catch (e) {
    console.log('DataLayer error', e);
  }
};

export const parseItems = (items) => {
  return items ? items.map((o) => ({
    item_name: o.name,
    item_id: o.identifier || o.id,
    price: (o.price && o.price.toString()) || (o.value && o.value.toString()),
    item_brand: 'The Mens',
    item_category: getProductCategoryTitle(getProductById(o.identifier || o.id).category),
    item_variant: null,
    quantity: o.quantity,
    discount_value: o.discountValue && o.discountValue.toString(),
    discount_percentage: o.discountPercentage && o.discountPercentage.toString(),
  })) : [];
};

const analytics = { webPageView, webLogEvent };

export default analytics;

import React from 'react';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 */

export const AlertCard = ({ title, description }) => {
  return (
    <div className="question_alert_card_container">
      <b>{title}</b> <div>{description}</div>
    </div>
  );
};
import React from 'react';
import { formatPriceValue } from '../../utils/masks';

const AmountPicker = ({
  onClickRemove,
  onClickAdd,
  blockZero,
  value,
  disabledAdd,
  disabled,
  removeShadow,
}) => {
  return (
    <div
      className="amount-picker"
      style={{
        boxShadow: removeShadow && 'none',
        minHeight: removeShadow && 'auto',
        width: removeShadow && 'auto',
        border: removeShadow && '1px solid #ededed',
      }}
    >
      <div className="amount-picker__action">
        <button
          className="amount-picker__action__button"
          type="button"
          onClick={() => {
            if (blockZero && value > 1 && !disabled) {
              onClickRemove();
            } else if (!blockZero && value > 0 && !disabled) {
              onClickRemove();
            }
          }}
          style={{
            opacity: disabled || (blockZero && value === 1) ? 0.3 : !blockZero && value === 0 ? 0.3 : 1,
            cursor: disabled || (blockZero && value === 1) ? 'default' : !blockZero && value === 0 ? 'default' : 'pointer',
          }}
        >
          -
        </button>
      </div>
      <div
        className="amount-picker__value"
        style={{ opacity: disabled ? 0.3 : 1 }}
      >
        {formatPriceValue(value)}
      </div>
      <div className="amount-picker__action">
        <button
          className="amount-picker__action__button"
          type="button"
          onClick={() => {
            if (disabled) return;

            onClickAdd();
          }}
          style={{
            opacity: disabled || (value === 1 && disabledAdd) ? 0.3 : 1,
            cursor: disabled || (value === 1 && disabledAdd) ? 'default' : 'pointer',
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default AmountPicker;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import TransactionStatus from '../../../enum/requestStatus';
import {
  getCreditCard,
  getMineTransactions,
} from '../../../redux/actions/transaction';
import { translate } from '../../../services/i18n';
import { cepMask, toCurrencyLocale } from '../../../utils/masks';
import { CardBrandTypeIcons } from '../../../enum/cardBrandType';
import AuthService from '../../../services/auth';
import { getProductLatestImage } from '../../../utils/string';
import * as ActionProducts from '../../../redux/actions/products';
import { products } from '../../../services/product';
import { Collapse, Space } from 'antd';
import { ReactComponent as Icon } from '../../../assets/svg/ic_creditcard.svg';
import { ReactComponent as IconPix } from '../../../assets/svg/ic_pix.svg';
import AdvancedButton from '../../../components/AdvancedButton/AdvancedButton';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ProfileRequests = ({
  me,
  mineTransactions,
  getMineTransactions,
  getCreditCard,
  creditCart,
  productByID,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [ image, setImage ] = useState('');
  const { Panel } = Collapse;
  const history = useHistory();

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    getMineTransactions(null);
    document.querySelector('body').scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (creditCart) {
      const card = CardBrandTypeIcons.find(
        (o) => o.id === creditCart.brandType,
      );

      if (card) {
        setImage(card.icon);
      }
    }
  }, [ creditCart ]);

  const buyAgain = (products) => {
    const productsId =
      products && products.map((product) => product.identifier);

    if (productsId && productsId.length) {
      history.push(`/checkout/revisao?carrinho=${productsId}`);
    }
  };

  return (
    <>
      <div className="request-profile">
        <Container>
          <div className="request-profile__title">
            {translate('PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.TITLE')}
          </div>
          <Space direction="vertical">
            {mineTransactions &&
            mineTransactions.rows &&
            mineTransactions.rows.length > 0 ? (
                mineTransactions.rows.map((item, i) => (
                  <Collapse key={i}
                    accordion
                    className="collapse-request">
                    <Panel
                      className="collapse-request__panel"
                      header={`Pedido Nº ${item.code}`}
                    >
                      <div className="request-profile__item">
                        <div className="request-profile__item__box">
                          <div className="request-profile__aux">
                            <div className="request-profile__aux__requests">
                              <div className="request-profile__aux__requests__status">
                                {item.status === TransactionStatus.CREATED && (
                                  <h3 className="request-profile__aux__requests__status--created">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status === TransactionStatus.CANCELLED && (
                                  <h3 className="request-profile__aux__requests__status--cancelled">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status === TransactionStatus.DONE && (
                                  <h3 className="request-profile__aux__requests__status--done">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status === TransactionStatus.SHIPPED && (
                                  <h3 className="request-profile__aux__requests__status--shipped">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status ===
                                TransactionStatus.PROCESSING_PAYMENT && (
                                  <h3 className="request-profile__aux__requests__status--processing-payment">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status === TransactionStatus.PAID && (
                                  <h3 className="request-profile__aux__requests__status--paid">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                                {item.status ===
                                TransactionStatus.IN_ANALYSIS && (
                                  <h3 className="request-profile__aux__requests__status--in-analysis">
                                    {translate(
                                      `ENUM.TRANSFER_STATUS.${item.status}.title`,
                                    )}
                                  </h3>
                                )}
                              </div>
                              {item.transactionItems.map((itemTransaction) => {
                                const product = products.find(
                                  (o) => o.id === itemTransaction.identifier,
                                );

                                if (product) {
                                  return (
                                    <div
                                      key={itemTransaction.identifier}
                                      className="signature-profile__aux__requests__product"
                                    >
                                      <img
                                        className="signature-profile__content__first__img"
                                        src={`${getProductLatestImage(product)}`}
                                        alt=""
                                      />
                                      <p className="product-name">
                                        {product.name}
                                      </p>
                                    </div>
                                  );
                                }

                                return <></>;
                              })}
                            </div>
                            <div className="request-profile__aux__requests__line"></div>
                            <div className="request-profile__aux__requests">
                              {[
                                TransactionStatus.DONE,
                                TransactionStatus.PAID,
                                TransactionStatus.SHIPPED,
                              ].includes(item.status) && (
                                <div className="button-buy-again">
                                  <AdvancedButton
                                    onClick={() =>
                                      buyAgain(item.transactionItems)
                                    }
                                    text={I18n.t('BUY_AGAIN')}
                                    size="medium"
                                    type="link"
                                  />
                                </div>
                              )}
                              <div className="request-profile__aux__requests__aux">
                                <h3 className="request-profile__aux__requests__title">
                                  {translate(
                                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.DATE',
                                  )}
                                </h3>
                                <p className="request-profile__aux__requests__text">
                                  {moment(item.createdAt).format('DD/MM/YYYY')}
                                </p>
                              </div>
                              <div className="request-profile__aux__requests__aux">
                                <h3 className="request-profile__aux__requests__title">
                                  {translate(
                                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.CODE',
                                  )}
                                </h3>
                                <p className="request-profile__aux__requests__text">
                                  <a
                                    href="https://rastreamento.correios.com.br/app/index.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="request-profile__link-to"
                                  >
                                    {item.shippingCode}
                                  </a>
                                </p>
                              </div>
                              <div className="request-profile__aux__requests__aux">
                                <h3 className="request-profile__aux__requests__title">
                                  {translate(
                                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.ADDRESS',
                                  )}
                                </h3>
                                <p className="request-profile__aux__requests__text">
                                  {`${item.street} ${item.addressNumber} 
                        ${item.neighborhood}
                        ${item.city}/ ${item.uf} 
                        ${cepMask(item.zipcode || '')}`}
                                </p>
                              </div>
                              <div className="request-profile__aux__requests__aux">
                                <h3 className="request-profile__aux__requests__title">
                                  {translate(
                                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.TOTAL_VALUE',
                                  )}
                                </h3>
                                {item.creditCardId ? (
                                  <p className="request-profile__aux__requests__text">
                                    {toCurrencyLocale(item.total)}
                                    <Icon />
                                  </p>
                                ) : (
                                  <p className="request-profile__aux__requests__text">
                                    {toCurrencyLocale(item.total)}
                                    <IconPix />
                                  </p>
                                )}
                              </div>
                              <div className="request-profile__aux__requests__aux">
                                <h3 className="request-profile__aux__requests__title">
                                  {translate(
                                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.REQUEST_NUMBER',
                                  )}
                                </h3>
                                <p className="request-profile__aux__requests__text">
                                #{item.code}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                ))
              ) : (
                <p className="request-profile__aux__no-request">
                  {translate(
                    'PAGES.HOME.PROFILE_REGISTER.FORM.REQUEST.NO_REQUEST',
                  )}
                </p>
              )}
          </Space>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  mineTransactions: state.transaction.mineTransactions,
  creditCart: state.transaction.creditCart,
  productByID: state.product.productByID,
});

const mapDispatchToProps = (dispatch) => ({
  getMineTransactions: (params) => dispatch(getMineTransactions(params)),
  getCreditCard: (id) => dispatch(getCreditCard(id)),
  getProductByID: (id) => dispatch(ActionProducts.getProductByID(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRequests);

import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import * as Analytics from '../../services/analytics';
import { translate } from '../../services/i18n';
import ProfileContent from '../../components/ProfileContent/ProfileContent';
import PageContent from '../../components/PageContent/PageContent';

import ProfileRegister from './ProfileRegister/ProfileRegister';
import ProfileTalk from './ProfileTalk/ProfileTalk';
import ProfileHelp from './ProfileHelp/ProfileHelp';
import ProfileSignature from './ProfileSignature/ProfileSignature';
import ProfileRequests from './ProfileRequests/ProfileRequests';
import ProfileChat from './ProfileChat/ProfileChat';
import * as AuthActions from '../../redux/actions/auth';
import { connect } from 'react-redux';
import AuthService from '../../services/auth';

class ProfileNavigationStack extends React.PureComponent {
  constructor (props) {
    super(props);

    if (process.env.NODE_ENV === 'production') {
      Analytics.webPageView('/perfil');
    }
  }

  componentDidMount () {
    const { getMe, checkLogged } = this.props;
    const isAuthenticated = AuthService.isAuthenticated();
    checkLogged(isAuthenticated);

    if (isAuthenticated) {
      getMe();
    }
  }

  render () {
    return (
      <div className="profile-navigation-stack">
        <Switch>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_REGISTER.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_REGISTER.PAGE_TITLE')}>
                <ProfileRegister />
              </ProfileContent>
            </ PageContent>
          </Route>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_CHAT.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_CHAT.PAGE_TITLE')}>
                <ProfileChat />
              </ProfileContent>
            </PageContent>
          </Route>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_TALK.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_TALK.PAGE_TITLE')}>
                <ProfileTalk />
              </ProfileContent>
            </ PageContent>
          </Route>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_SIGNATURE.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_SIGNATURE.PAGE_TITLE')}>
                <ProfileSignature />
              </ProfileContent>
            </ PageContent>
          </Route>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_REQUESTS.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_REQUESTS.PAGE_TITLE')}>
                <ProfileRequests />
              </ProfileContent>
            </ PageContent>
          </Route>
          <Route
            path={translate('PAGES.PROFILE.PROFILE_HELP.URL')}
          >
            <PageContent removeEmailFooter>
              <ProfileContent pageTitle={translate('PAGES.PROFILE.PROFILE_HELP.PAGE_TITLE')}>
                <ProfileHelp />
              </ProfileContent>
            </ PageContent>
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMe: () => dispatch(AuthActions.getMe()),
  checkLogged: (payload) => dispatch(AuthActions.checkLogged(payload)),
});

export default connect(null, mapDispatchToProps)(ProfileNavigationStack);

export default {
  appName: 'The Men\'s',
  header: {
    platform: 'web',
    version: '1.0.0',
  },
  timezone: 'America/Sao_Paulo',
  language: 'pt-br',
  geocoderKey: 'AIzaSyCNBcJK-bpge0rP0BJHgcrzV_9ksdO47pA',
  authToken: {
    dev: 'c91ae09b-44c1-462a-865f-52817016285b',
    prod: '5cf7749f-e351-49ee-a7c4-3d8c8090cacb',
  },
  imageCompression: {
    maxWidth: 1500,
    quality: 0.80,
  },
  highImageCompression: {
    maxWidth: 1920,
    quality: 1,
  },
  bugsnag: {
    token: 'ce5a3a90e7f2b73bbe2b1b6e890ab486',
  },
};

import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';

import MedicalAppointmentRequests from '../../api/medical-appointment';

export const ACTION_GET_ALL_MEDICAL_APPOINTMENTS =
  'medicalAppointment:ACTION_GET_ALL_MEDICAL_APPOINTMENTS';
export const ACTION_GET_MEDICAL_APPOINTMENT_BY_ID =
  'medicalAppointment:ACTION_GET_MEDICAL_APPOINTMENT_BY_ID';
export const ACTION_CREATE_MEDICAL_APPOINTMENT =
  'medicalAppointment:ACTION_CREATE_MEDICAL_APPOINTMENT';
export const ACTION_UPDATE_MEDICAL_APPOINTMENT =
  'medicalAppointment:ACTION_UPDATE_MEDICAL_APPOINTMENT';
export const ACTION_GET_MINE_MEDICAL_APPOINTMENT =
  'medicalAppointment:ACTION_GET_MINE_MEDICAL_APPOINTMENT';
export const ACTION_DELETE_MEDICAL_APPOINTMENT =
  'medicalAppointment:ACTION_DELETE_MEDICAL_APPOINTMENT';

export const createMedicalAppointment = (data) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await MedicalAppointmentRequests.createAppointment(data);
    dispatch({
      type: ACTION_CREATE_MEDICAL_APPOINTMENT,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const updateMedicalAppointment = (id, data) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await MedicalAppointmentRequests.updateAppointment(id, data);
    dispatch({
      type: ACTION_UPDATE_MEDICAL_APPOINTMENT,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const getMedicalAppointmentById =
  (id, parameters) => async (dispatch) => {
    dispatch(addLoading);

    try {
      const result = await MedicalAppointmentRequests.getAppointmentById(
        id,
        parameters,
      );
      dispatch({
        type: ACTION_GET_MEDICAL_APPOINTMENT_BY_ID,
        payload: result,
      });
      return result;
    } catch (err) {
      const errors = I18n.t('ERRORS');

      if (err && err.message && errors[err.message] !== undefined) {
        message.error(`${errors[err.message]}`);
      } else if (
        err &&
        err.response &&
        err.response.response &&
        err.response.response.error &&
        errors[err.response.response.error] !== undefined
      ) {
        message.error(`${errors[err.response.response.error]}`);
      } else {
        message.error(errors.genericError);
      }
    } finally {
      dispatch(removeLoading);
    }
  };

export const getMedicalAppointmentsPagination =
  (parameters) => async (dispatch) => {
    dispatch(addLoading);

    try {
      const result = await MedicalAppointmentRequests.getAppointmentsPaginated(
        parameters,
      );
      dispatch({
        type: ACTION_GET_ALL_MEDICAL_APPOINTMENTS,
        payload: result,
      });
      return result;
    } catch (err) {
      const errors = I18n.t('ERRORS');

      if (err && err.message && errors[err.message] !== undefined) {
        message.error(`${errors[err.message]}`);
      } else if (
        err &&
        err.response &&
        err.response.response &&
        err.response.response.error &&
        errors[err.response.response.error] !== undefined
      ) {
        message.error(`${errors[err.response.response.error]}`);
      } else {
        message.error(errors.genericError);
      }
    } finally {
      dispatch(removeLoading);
    }
  };

export const getMedicalAppointmentMine = (parameters) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await MedicalAppointmentRequests.getMineAppointment(
      parameters,
    );
    dispatch({
      type: ACTION_GET_MINE_MEDICAL_APPOINTMENT,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const removeMedicalAppointment = (id) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await MedicalAppointmentRequests.removeAppointment(id);
    dispatch({
      type: ACTION_DELETE_MEDICAL_APPOINTMENT,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

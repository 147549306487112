import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { message } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';

import * as AuthActions from '../../../redux/actions/auth';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import { translate } from '../../../services/i18n';
import AdvancedButton from '../../../components/AdvancedButton/AdvancedButton';
import AdvancedForm from '../../../components/AdvancedForm/AdvancedForm';
import HomeSlider from '../../../components/HomeSlider/HomeSlider';
import AuthService from '../../../services/auth';

const recoveryPasswordInitialValues = {
  emailRecoveryPassword: null,
  newPassword: null,
  confirmPassword: null,
};

const PasswordRecovery = ({
  sendRecoveryEmail,
  passwordRecovery,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  const history = useHistory();

  const [ selectedLink, setSelectedLink ] = useState(0);
  const [ invalidPassword, setInvalidPassword ] = useState(false);
  const [ recoveryPasswordForm, setRecoveryPasswordForm ] = useState(recoveryPasswordInitialValues);

  const { emailRecoveryPassword, code, newPassword, confirmNewPassword } = recoveryPasswordForm;

  const handleRecoveryPasswordChange = name => e => {
    setRecoveryPasswordForm({ ...recoveryPasswordForm, [name]: e.target.value });
  };

  const onRecoveryPasswordFormSubmit = (e) => {
    if (e) e.preventDefault();

    sendRecoveryEmail({ email: emailRecoveryPassword }, () => setSelectedLink(1));
  };

  const onSendRecoveryPasswordFormSubmit = (e) => {
    setInvalidPassword(false);

    if (newPassword !== confirmNewPassword) {
      setInvalidPassword(true);
      return message.error('A senha esta diferente da senha de confirmação');
    }

    if (newPassword.length < 6) {
      setInvalidPassword(true);
      return message.error('A senha deve conter no mínimo 6 caracteres');
    }

    if (e) e.preventDefault();

    passwordRecovery({ email: emailRecoveryPassword, password: newPassword, recoveryToken: code }, () => {
      history.push('/login');
    });
  };

  return (
    <div className="password-recovery">
      <Helmet>
        <title>Recuperar senha</title>
      </Helmet>
      <Container>
        <Row>
          <Col
            className="mobile-hidden"
            lg={8}
          >
            <HomeSlider />
          </Col>
          <Col lg={4}>
            <div>
              {selectedLink === 0 && (
                <div className="password-recovery__form">
                  <div className="password-recovery__form__inner">
                    <AdvancedForm onSubmit={(e) => onRecoveryPasswordFormSubmit(e)}>
                      <Row className="justify-content-center">
                        <Col lg={10}>
                          <Row>
                            <Col>
                              <div className="password-recovery__form__inner__message">
                                <p className="login__form__inner__message__message">
                                  {translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.MESSAGE')}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <AdvancedInput
                                placeholder={translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.EMAIL.PLACEHOLDER')}
                                value={recoveryPasswordForm.emailRecoveryPassword && recoveryPasswordForm.emailRecoveryPassword.toLowerCase()}
                                onChange={handleRecoveryPasswordChange('emailRecoveryPassword')}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="password-recovery__form__inner__bottom">
                                <Link
                                  to="/login"
                                >
                                  <p className="login__form__inner__bottom mb-4">
                                    {translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.TEXT_LINK')}
                                  </p>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="password-recovery__form__inner__button">
                                <AdvancedButton
                                  text={translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.BUTTON.TEXT')}
                                  fullWidth
                                  type="submit"
                                  customColor="rgb(12, 25, 50)"
                                />
                              </div>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </AdvancedForm>
                  </div>
                </div>
              )}

              {selectedLink === 1 && (
                <div className="password-recovery__code">
                  <div className="password-recovery__form__inner">
                    <AdvancedForm onSubmit={(e) => onSendRecoveryPasswordFormSubmit(e)}>
                      <Row className="justify-content-center">
                        <Col lg={12}>
                          <Row>
                            <Col>
                              <div className="password-recovery__form__inner__message">
                                <p className="login__form__inner__message__message">
                                  {translate('PAGES.AUTH.PASSWORD_RECOVERY.CODE.MESSAGE')}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <AdvancedInput
                                placeholder={translate('PAGES.AUTH.PASSWORD_RECOVERY.CODE.PLACEHOLDER')}
                                value={recoveryPasswordForm.code}
                                onChange={handleRecoveryPasswordChange('code')}
                                autoComplete="off"
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col>
                              <AdvancedInput
                                error={invalidPassword}
                                name="newPassword"
                                type="password"
                                label={translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.NEW_PASSWORD.PLACEHOLDER')}
                                value={recoveryPasswordForm.newPassword}
                                onChange={handleRecoveryPasswordChange('newPassword')}
                                autoComplete="off"
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col>
                              <AdvancedInput
                                error={invalidPassword}
                                name="confirmNewPassword"
                                type="password"
                                label={translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.CONFIRM_NEW_PASSWORD.PLACEHOLDER')}
                                value={recoveryPasswordForm.confirmNewPassword}
                                onChange={handleRecoveryPasswordChange('confirmNewPassword')}
                                autoComplete="off"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="password-recovery__form__inner__button mt-4">
                                <AdvancedButton
                                  text="ALTERAR SENHA"
                                  fullWidth
                                  type="submit"
                                  customColor="rgb(12, 25, 50)"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="password-recovery__form__inner__bottom mt-4">
                                <Link to="/login">
                                  <p className="login__form__inner__bottom mb-4">
                                    {translate('PAGES.AUTH.PASSWORD_RECOVERY.FORM.TEXT_LINK')}
                                  </p>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </AdvancedForm>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendRecoveryEmail: (userData, callback) => dispatch(AuthActions.sendRecoveryEmail(userData, callback)),
  passwordRecovery: (data, callback) => dispatch(AuthActions.passwordRecovery(data, callback)),
});

export default connect(null, mapDispatchToProps)(PasswordRecovery);

import { LoadingActions } from '../actions';

export default (
  state = 0,
  action,
) => {
  switch (action.type) {
    case LoadingActions.ACTION_INCREASE_LOADING:
      return state + 1;
    case LoadingActions.ACTION_DESCREASE_LOADING:
      return state - 1;
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import MoneyBackFaqItems from '../../../services/moneyBackFaq';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';

const MoneyBack = () => {
  const [ faqOpen, setFaqOpen ] = useState(null);

  useEffect(() => {
    setFaqOpen(null);
  }, []);

  const changeFaqOpen = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (
    <div className="money-back">
      <div className="money-back__content-one">
        <img
          className="money-back__content-one__img mobile-hidden"
          src="/assets/img/money-back/1.png"
        />
        <img
          className="money-back__content-one__img mobile-visible"
          src="/assets/img/money-back/m-1.png"
        />
      </div>
      <div className="money-back__content-two">
        <img
          className="money-back__content-two__img"
          src="/assets/img/money-back/2.png"
        />
      </div>
      <div className="money-back__content-three">
        <img
          className="money-back__content-three__img mobile-hidden"
          src="/assets/img/money-back/3.png"
        />
        <div className="money-back__content-three__container mobile-visible">
          <div className="money-back__content-three__container__one">
            Veja os resultados em 90 dias ou
          </div>
          <div className="money-back__content-three__container__two">
            Garantimos seu dinheiro de volta
          </div>
          <div className="money-back__content-three__container__three">
            <div className="money-back__content-three__container__three__text">
              Se você não estiver <strong>resultados</strong> visíveis após 90
              dias corridos de tratamento, nós garantimos seu dinheiro de volta!
            </div>
            <div className="money-back__content-three__container__three__text">
              Veja as 6 regras do regulmento, <strong>sem letras miúdas</strong>
            </div>
            <div className="money-back__content-three__container__three__text">
              <strong>Definição de resultados visíveis:</strong> Todo e qualquer
              resultado que seja visível a olho nú pelos vídeos e fotos
              encaminhadas, à exclusivo critério da The Men’s.
            </div>
          </div>
          <div className="money-back__content-three__container__four">
            <img src="/assets/img/money-back/m-3.png" />
          </div>
        </div>
      </div>
      <div className="money-back__content-four">
        <img
          className="money-back__content-four__img mobile-hidden"
          src="/assets/img/money-back/4.png"
        />
        <img
          className="money-back__content-four__img mobile-visible"
          src="/assets/img/money-back/m-4.png"
        />
      </div>
      <div className="money-back__button">
        <a
          className="money-back__button__link"
          href="https://api.whatsapp.com/send?phone=5511942120797"
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="noreferrer"
        >
          Quero participar
        </a>
      </div>
      <div className="money-back__faq">
        <div className="money-back__faq__title">Perguntas frequentes</div>
        <div className="money-back__faq__questions">
          <div className="money-back__faq__container">
            {MoneyBackFaqItems &&
              MoneyBackFaqItems.map((item, index) => (
                <>
                  {index < 5 && (
                    <div
                      className="money-back__faq__container__item"
                      onClick={() => changeFaqOpen(index)}
                    >
                      <div className="money-back__faq__container__item__content">
                        <div className="money-back__faq__container__item__content__title">
                          {item.title}
                        </div>
                        {index === faqOpen && (
                          <div className="money-back__faq__container__item__content__text">
                            {item.text}
                          </div>
                        )}
                      </div>
                      <div className="product-mobile__questions__container__item__icon">
                        <ArrowDown />
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
          <div className="money-back__faq__container">
            {MoneyBackFaqItems &&
              MoneyBackFaqItems.map((item, index) => (
                <>
                  {index > 5 && (
                    <div
                      className="money-back__faq__container__item"
                      onClick={() => changeFaqOpen(index)}
                    >
                      <div className="money-back__faq__container__item__content">
                        <div className="money-back__faq__container__item__content__title">
                          {item.title}
                        </div>
                        {index === faqOpen && (
                          <div className="money-back__faq__container__item__content__text">
                            {item.text}
                          </div>
                        )}
                      </div>
                      <span className="product-mobile__questions__container__item__icon">
                        <ArrowDown />
                      </span>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneyBack;

import React from 'react';
import { toCurrencyLocale } from '../../utils/masks';
import { CloseOutlined } from '@material-ui/icons';
import { CouponType } from '../../enum/couponType';

const CouponCard = ({ coupon, onRemove }) => {
  return (
    <div className="coupon-card">
      <div className="coupon-card__meta">
        <div className="coupon-card_meta__title">
          {coupon.title}
        </div>
        <div className="coupon-card_meta__value">
          -{coupon.type === CouponType.PERCENTAGE ? `${coupon?.percentageValue && parseFloat(coupon?.percentageValue).toFixed()}% no valor do(s) produto(s)` : toCurrencyLocale(coupon.value)}
        </div>
      </div>
      <div className="coupon-card__remove">
        <button
          className="transparent-button coupon-card__remove__button"
          onClick={() => onRemove()}
        >
          <CloseOutlined />
        </button>
      </div>
    </div>
  );
};

export default CouponCard;

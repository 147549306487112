import { useRef } from 'react';

const BOUNCE_RATE = 30000;

export const useDebounce = () => {
  const busy = useRef(false);

  const setDebounce = async (callback) => {
    setTimeout(() => {
      clearDebounce();
    }, BOUNCE_RATE);

    if (!busy.current) {
      busy.current = true;
      callback();
    }
  };

  const clearDebounce = () => {
    busy.current = false;
  };

  return { setDebounce, clearDebounce };
};
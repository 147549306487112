import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as AuthActions from '../../../redux/actions/auth';
import * as UserActions from '../../../redux/actions/user';
import * as UtilsActions from '../../../redux/actions/utils';
import { translate } from '../../../services/i18n';
import AuthService from '../../../services/auth';
import { PrescriptionActions } from '../../../redux/actions';
import CollapseGlobal from './CollapseGlobal';
import RegisterPasswordModal from '../../../components/RegisterPasswordModal/RegisterPasswordModal';
import PasswordType from '../../../enum/passwordType';


const ProfileRegister = ({
  getMe,
  getPrescription,
}) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    AuthService.isFromEcommerceDomain();
    await getMe();
    getPrescription();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ screenType, setSreenType ] = useState(PasswordType.PASSWORD);
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflowY = 'hidden';
    } else {
      window.document.body.style.overflowY = 'scroll';
    }
  }, [ isOpen ]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && screenType === PasswordType.REGISTER_PASSWORD && (
        <RegisterPasswordModal
          isCloseIcon
          handleClose={handleClose}
        />

      )}
      <div className="register__profile">
        <Container>
          <div className="register__profile__drop">
            <CollapseGlobal
              isProfile
              title={translate('PAGES.HOME.PROFILE_REGISTER.FORM.PROFILE.TITLE')}
              className={'register__profile__profile-container'}
              classNamePanel={'register__profile__drop__profile-container__item__title'}
            />

            <CollapseGlobal
              isPrescription
              title={translate('PAGES.HOME.PROFILE_REGISTER.FORM.PRESCRIPTION.TITLE')}
              className={'register__profile__prescription-container'}
              classNamePanel={'register-profile__drop__prescription-container__item__title'}
            />

          </div>

          <div className="register__profile__dropdown">
            <CollapseGlobal
              isAddress
              title={translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.TITLE')}
              className={'register__profile__prescription-container'}
              classNamePanel={'register-profile__drop__prescription-container__item__title'}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  getPrescription: () => dispatch(PrescriptionActions.getPrescription()),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRegister);

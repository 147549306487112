import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../services/i18n';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import * as AuthActions from '../../../redux/actions/auth';
import * as UserActions from '../../../redux/actions/user';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import AdvancedForm from '../../../components/AdvancedForm/AdvancedForm';
import { removeSpecialChars, validaCpf, validateEmail } from '../../../utils/string';
import { cpfMask, dateOfBirthMask, phoneMask } from '../../../utils/masks';
import { message } from 'antd';
import BannerMessage from '../../../assets/png/banner_message.png';
import WhiteButton from '../../../components/WhiteButton/WhiteButton';
import { Helmet } from 'react-helmet-async';
import AuthService from '../../../services/auth';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';

const Prescription = (props) => {

  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  const { login, authenticated, me, createUser } = props;
  const [ selectedLink, setSelectedLink ] = useState(0);
  const [ invalidCPF, setInvalidCPF ] = useState(false);
  const [ invalidPhone, setInvalidPhone ] = useState(false);
  const [ invalidEmail, setInvalidEmail ] = useState(false);

  useEffect(() => {
    AuthService.isFromPrescriptionDomain();

    if (authenticated === true) {
      setSelectedLink(3);
    }
  }, [ authenticated ]);

  const [ registerForm, setRegisterForm ] = useState({
    email: '',
    phone: '',
    document: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    profileType: 1,
    grantType: 'password',
  });

  const [ loginForm, setLoginForm ] = useState({
    email: '',
    password: '',
    grantType: 'password',
  });

  const handleChangeRegisterForm = (name, value) => {

    if (name === 'birthDate') {
      value = dateOfBirthMask(value);
    }

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setRegisterForm({ ...registerForm, [name]: value });
  };

  const handleChangeLoginForm = (name, value) => {

    if (name === 'birthDate') {
      value = dateOfBirthMask(value);
    }


    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleRegister = async (e) => {
    if (e) e.preventDefault();

    setInvalidCPF(false);
    setInvalidPhone(false);
    setInvalidEmail(false);

    if (!registerForm.firstName && !registerForm.lastName) {
      return message.error('Preencha seu nome');
    }

    if (!validaCpf(removeSpecialChars(registerForm.document))) {
      setInvalidCPF(true);
      return message.error('CPF inválido');
    }

    if (!registerForm.birthDate) {
      return message.error('Preencha seu nascimento');
    }

    if (removeSpecialChars(registerForm.phone).length < 10 || registerForm.phone.includes('99999-9999')) {
      setInvalidPhone(true);
      return message.error('Telefone inválido');
    }

    if (!validateEmail(registerForm.email)) {
      setInvalidEmail(true);
      return message.error('Email inválido');
    }

    if (registerForm.password && registerForm.password.length < 8) {
      return message.error('Digite uma senha com pelo menos 8 caracteres');
    }

    const payload = {
      ...registerForm,
      name: registerForm.firstName.concat(' ', registerForm.lastName),
      document: removeSpecialChars(registerForm.document),
      phone: removeSpecialChars(registerForm.phone),
      password: removeSpecialChars(registerForm.document),
      birthDate: moment(registerForm.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    await createUser(payload, () => login(payload, () => setSelectedLink(3)));
  };

  const handleLogin = async () => {
    const payload = {
      ...loginForm,
      password: removeSpecialChars(loginForm.password),
    };

    await login(payload, (response) => {
      if (response && !response.error) {
        setSelectedLink(3);
      }
    });
  };

  return (
    <div className="prescription">
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <div className="prescription__banner">
        <h2 className="prescription__banner__title">
          {translate('COMPONENTS.PRESCRIPTION.TITLE')}
        </h2>
      </div>
      <div className="prescription__numbers">
        <div className="prescription__numbers__block">
          <label className="prescription__numbers__block__number">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.ONE')}
          </label>
          <label className="prescription__numbers__block__description">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_ONE')}
          </label>
        </div>
        <div className="prescription__numbers__block">
          <label className="prescription__numbers__block__number">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.TWO')}
          </label>
          <label className="prescription__numbers__block__description">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_TWO')}
            <b>{translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_TWO_SCROLL')}</b>
          </label>
        </div>
        <div className="prescription__numbers__block">
          <label className="prescription__numbers__block__number">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.THREE')}
          </label>
          <label className="prescription__numbers__block__description">
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_THREE')}
            <b>{translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_THREE_SCROLL')}</b>
            {translate('COMPONENTS.PRESCRIPTION.NUMBERS.DESCRIPTION_THREE_CONTINUE')}
          </label>
        </div>
      </div>
      <div className={`prescription__buttons${selectedLink === 0 ? '' : '__active'}`}>
        <span
          className="prescription__buttons__login"
          onClick={() => setSelectedLink(2)}
        >
          {translate('COMPONENTS.PRESCRIPTION.BUTTONS.LOGIN')}
        </span>
        <span
          className="prescription__buttons__create"
          onClick={() => setSelectedLink(1)}
        >
          {translate('COMPONENTS.PRESCRIPTION.BUTTONS.CREATE')}
        </span>
      </div>
      <div className={`prescription__register${selectedLink === 1 ? '__active' : ''}`}>
        <Row>
          <Col sm={12}>
            <AdvancedForm onSubmit={() => handleRegister()}>
              <span
                className="prescription__login__back"
                onClick={() => setSelectedLink(0)}
              >
                {translate('PAGES.HOME.REGISTER.FORM.COME_BACK')}
              </span>
              <h3 className="prescription__register__title__blue">{translate('PAGES.HOME.REGISTER.FORM.TITLE4')}</h3>
              <label className="prescription__register__title">
                {translate('PAGES.HOME.REGISTER.FORM.TITLE3')}
              </label>
              <div className="prescription__register__aux">
                <div className="prescription__register__aux__input">
                  <AdvancedInput
                    name="firstName"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.FIRST_NAME')}
                    value={registerForm.firstName}
                    onChange={(e) => handleChangeRegisterForm('firstName', e.target.value)}
                  />
                </div>
                <div className="prescription__register__aux__input">
                  <AdvancedInput
                    name="lastName"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.LAST_NAME')}
                    value={registerForm.lastName}
                    onChange={(e) => handleChangeRegisterForm('lastName', e.target.value)}
                  />
                </div>
              </div>
              <div className="prescription__register__aux">
                <div className="prescription__register__aux__input">
                  <AdvancedInput
                    error={invalidCPF}
                    name="document"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.CPF')}
                    value={registerForm.document}
                    onChange={(e) => handleChangeRegisterForm('document', cpfMask(e.target.value))}
                  />
                </div>
                <div className="prescription__register__aux__input">
                  <AdvancedInput
                    name="birthDate"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.BIRTH')}
                    value={registerForm.birthDate}
                    onChange={(e) => handleChangeRegisterForm('birthDate', e.target.value)}
                  />
                </div>
              </div>
              <div className="prescription__register__aux">
                <div className="register__aux__input-large">
                  <AdvancedInput
                    error={invalidPhone}
                    name="phone"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.PHONE')}
                    value={registerForm.phone}
                    onChange={(e) => handleChangeRegisterForm('phone', phoneMask(e.target.value))}
                  />
                </div>
              </div>
              <div className="prescription__register__aux">
                <div className="prescription__register__aux__input-large">
                  <AdvancedInput
                    error={invalidEmail}
                    name="email"
                    label={translate('PAGES.HOME.REGISTER.FORM.LABEL.EMAIL')}
                    value={registerForm.email && registerForm.email.toLowerCase()}
                    onChange={(e) => handleChangeRegisterForm('email', e.target.value)}
                  />
                </div>
              </div>
              <div className="prescription__register__input-button">
                <WhiteButton
                  label={translate('PAGES.HOME.REGISTER.FORM.BUTTON_THREE')}
                  type="submit"
                  dark
                  full
                />
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </div>
      <div>

      </div>
      <div className={`prescription__login${selectedLink === 2 ? '__active' : ''}`}>
        <AdvancedForm onSubmit={() => handleLogin()}>
          <span
            className="prescription__login__back"
            onClick={() => setSelectedLink(0)}
          >
            {translate('PAGES.HOME.REGISTER.FORM.COME_BACK')}
          </span>
          <h3 className="prescription__login__title">
            {translate('COMPONENTS.PRESCRIPTION.LOGIN.TITLE')}
          </h3>
          <p className="prescription__register__title">
            {translate('PAGES.HOME.REGISTER.FORM.TITLE3')}          </p>

          <div className="prescription__login__aux__input">
            <AdvancedInput
              name="email"
              label={translate('PAGES.HOME.REGISTER.FORM.LABEL.EMAIL')}
              value={loginForm.email && loginForm.email.toLowerCase()}
              onChange={(e) => handleChangeLoginForm('email', e.target.value)}
            />
          </div>
          <div className="prescription__login__aux__input">
            <AdvancedInput
              name="passwordLogin"
              label="CPF *"
              value={cpfMask(loginForm.password)}
              onChange={(e) => handleChangeLoginForm('password', e.target.value)}
            />
          </div>
          {/*<div className="prescription__login__aux__recover">
            <Link to="/password-recovery">
              <label className="prescription__login__aux__recover--link">Esqueci minha senha</label>
            </Link>
          </div>*/}
          <div className="prescription__login__aux">
            <WhiteButton
              label={translate('COMPONENTS.PRESCRIPTION.LOGIN.BUTTON')}
              onClick={() => handleLogin()}
              dark
              full
              type="submit"
            />
          </div>
        </AdvancedForm>
      </div>

      <div className={`prescription__welcome${selectedLink === 3 ? '__active' : ''}`}>
        <h3 className="prescription__welcome__title">
          {translate('COMPONENTS.PRESCRIPTION.LOGIN.TITLE_TWO')}{me && me.name}{translate('COMPONENTS.PRESCRIPTION.LOGIN.TITLE_THREE')}
        </h3>
        <p className="prescription__welcome__description">
          {translate('PAGES.HOME.REGISTER.FORM.DESCRIPTION_THREE')}
        </p>
        <div className="prescription__welcome__input-button">
          <WhiteButton
            href={translate('PAGES.HOME.CLINIC.URL')}
            label={translate('PAGES.HOME.REGISTER.FORM.BUTTON_THREE')}
            dark
            full
          />
        </div>
      </div>
      <div className="prescription__talk">
        <div className="prescription__talk__description">
          <h3 className="prescription__talk__description__title">
            {translate('COMPONENTS.PRESCRIPTION.TALK.TITLE')}
          </h3>
          <p className="prescription__talk__description__text">
            {translate('COMPONENTS.PRESCRIPTION.TALK.DESCRIPTION')}
          </p>
        </div>
        <img
          className="prescription__talk__image"
          src={BannerMessage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  login: (userData, callback) => dispatch(AuthActions.authenticate(userData, callback)),
  createUser: (userData, callback) => dispatch(UserActions.create(userData, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Prescription);

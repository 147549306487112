import Visa from '../assets/png/credit-card-brand/Visa.png';
import MasterCard from '../assets/png/credit-card-brand/MasterCard.png';
import Maestro from '../assets/png/credit-card-brand/maestro.svg';
import Elo from '../assets/png/credit-card-brand/elo.svg';
import Payment from 'payment';

export const CardBrandType = {
  VISA: 1,
  MASTERCARD: 2,
  MAESTRO: 3,
  ELO: 4,
  HIPERCARD: 5,
  DINERS_CLUB: 7,
  AMERICAN_EXPRESS: 8,
};
export const CardBrandTypeMap = new Map([
  [ CardBrandType.VISA, { name: 'visa', label: 'VISA' } ],
  [ CardBrandType.MASTERCARD, { name: 'mastercard', label: 'MASTERCARD' } ],
  [ CardBrandType.MAESTRO, { name: 'maestro', label: 'MAESTRO' } ],
  [ CardBrandType.ELO, { name: 'elo', label: 'ELO' } ],
  [ CardBrandType.HIPERCARD, { name: 'hipercard', label: 'HIPERCARD' } ],
  [ CardBrandType.DINERS_CLUB, { name: 'diners-club', label: 'DINERS CLUB' } ],
  [ CardBrandType.AMERICAN_EXPRESS, { name: 'american-express', label: 'AMERICAN EXPRESS' } ],
]);

export const CARD_BRANDS = {
  'visa': CardBrandType.VISA,
  'mastercard': CardBrandType.MASTERCARD,
  'maestro': CardBrandType.MAESTRO,
  'elo': CardBrandType.ELO,
  'hipercard': CardBrandType.HIPERCARD,
  'dinersclub': CardBrandType.DINERS_CLUB,
  'amex': CardBrandType.AMERICAN_EXPRESS,
};

export function detectCardBrand (number) {
  if (!number) return;

  const cardBrand = Payment.fns.cardType(number);

  return CARD_BRANDS[cardBrand];
}

export const CardBrandTypeIcons = [
  { id: CardBrandType.VISA, icon: Visa },
  { id: CardBrandType.MASTERCARD, icon: MasterCard },
  { id: CardBrandType.MAESTRO, icon: Maestro },
  { id: CardBrandType.ELO, icon: Elo },
];

import { ProductsActions } from '../actions';

const initialState = {
  allProducts: null,
  productByID: null,
  quantById: 1,
};

const productReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ProductsActions.ACTION_SET_ALL_PRODUCTS:
      state = {
        ...state,
        allProducts: action.payload,
      };
      return state;
    case ProductsActions.ACTION_GET_PRODUCT_BY_ID:
      state = {
        ...state,
        productByID: action.payload,
      };
      return state;
    case ProductsActions.ACTION_INCREASE_QUANT:
      state = {
        ...state,
        quantById: action.payload,
      };
      return state;
    case ProductsActions.ACTION_DESCREASE_QUANT:
      state = {
        ...state,
        quantById: action.payload,
      };
      return state;
    default:
      return state;
  }
};


export default productReducer;

import getInstance from './instance';

export default class UserRequests {
  static async getPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async getById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}`);
    return data;
  }

  static async update (id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}`, userData);
    return data;
  }

  static async createUser (userData) {
    const instance = await getInstance();
    const data = await instance.post('/user', userData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/user/${id}`);
    return data;
  }

  static async createAdmin (userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/admin', userData);
    return data;
  }

  static async contactUs (payload) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/contact-us', payload);
    return data;
  }

  static async authenticateTwoFactor (payload) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/authenticate-two-factor', payload);
    return data;
  }

  static async enabledTwoFactor (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}/enable-two-factor`);
    return data;
  }

  static async addTwoFactor (id, payload) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}/two-factor`, payload);
    return data;
  }

  static async recoveryTwoFactor (id) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}/two-factor-recovery`);
    return data;
  }

  static async resetTwoFactor (id, payload) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}/reset-two-factor`, payload);
    return data;
  }
}

import { getPages, QuestionType, QuestionTypeMap } from '../services/prescription';
import { getProductById, hasCombo, promotionProducts } from '../services/product';

export function isCheckoutURL () {
  const checkoutPaths = [
    '/checkout/revisao',
    '/checkout/dados-de-entrega',
    '/checkout/pagamento',
    '/checkout/resultado-pagamento'
  ];

  return checkoutPaths.includes(window.location.pathname);
}

export function renderMobileCart (pathname) {
  const hiddenRender = [
    '/avaliacao',
    '/checkout/revisao',
    '/checkout/dados-de-entrega',
    '/checkout/pagamento',
    '/checkout/resultado-pagamento',
  ];

  Object
    .values(QuestionType)
    .forEach((questionType) => {
      getPages(questionType).map(
        (page) => hiddenRender.push(`/questionario/${QuestionTypeMap.get(questionType)}/passo-${page}`)
      );
    });

  return !hiddenRender.includes(pathname);
}

export function getProductTheme (productId) {
  const result = Object.values(promotionProducts).find(p => p.id === productId);
  return {
    color: result.color,
    shadowColor: result.shadowColor,
    backgroundColor: result?.backgroundColor,
  };
}

export function getPromotionProduct (productId) {
  const result = Object.values(promotionProducts).find(p => p.id === productId);
  return result.promotionProductID;
}

export function getPromotionProductValue (productId) {
  const result = Object.values(promotionProducts).find(p => p.id === productId);
  const promotionProduct = getProductById(result.promotionProductID);
  return promotionProduct.value;
}

export function getPromotionProductDiscount (productId) {
  const result = Object.values(promotionProducts).find(p => p.id === productId);
  const promotionProduct = getProductById(result.promotionProductID);
  return promotionProduct.discountPercent;
}

export function visiblePromotion (products, product) {
  let maxPrice = -Infinity; // Initial value for comparison

  let idProduct = ''; // Variable to store the ID of the highest value product

  products.forEach(e => {
    if (!e.isCombo && e.price > maxPrice) {
      maxPrice = e.price;
      idProduct = e.id;
    }
  });

  const originalProduct = Object.values(promotionProducts).find(p => p.id === product.id);

  if (!originalProduct) {
    return false;
  }

  if (products.find(p => p.id === originalProduct.promotionProductID)) {
    return false;
  }

  const productIsACombo = hasCombo(products);

  if (productIsACombo.includes(product.id)) {
    return false;
  }

  return product.id === idProduct;

}

export function checkPromotionProductRemoval (parentProduct) {
  const promotionProduct = Object.values(promotionProducts).find(p => p.id === parentProduct.id);

  if (promotionProduct && parentProduct.quantity <= 1) {
    return promotionProduct;
  }

  return null;
}

export function reorderProductsPromotion (productsInCart, quantity) {
  const productsList = [ ...productsInCart ];
  const lastProduct = productsList[productsList.length - 1];
  const promotionProduct = Object.values(promotionProducts).find(p => p.promotionProductID === lastProduct.id);

  if (!promotionProduct) {
    return productsList;
  }

  const promotionProductInCart = productsList.find(p => p.id === promotionProduct.promotionProductID);
  const parentProductIndex = productsList.findIndex(p => p.id === promotionProduct.id);

  productsList.splice(parentProductIndex + 1, 0, {
    ...promotionProductInCart,
    quantity,
  });

  productsList.pop();
  return productsList;
}
import UserRequests from '../../api/user';
import { I18n } from 'react-redux-i18n';
import { message } from 'antd';
import * as MessageService from '../../services/message';
import { decreaseLoading, increaseLoading } from './loading';
import { uploadImageBase64 } from './utils';
import { setDataLayer } from '../../services/analytics';
import { pixelTrack } from '../../utils/pixel';

export const ACTION_USER_REGISTER = 'user:ACTION_USER_REGISTER';
export const ACTION_ENABLE_TWO_FACTOR = 'user:ACTION_ENABLE_TWO_FACTOR';

export const create = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await UserRequests.createUser(data);

    setDataLayer(
      'sign_up',
      {
        method: 'password',
      },
      true,
    );

    MessageService.success('SUCCESS.REGISTERED');

    if (callback && typeof callback === 'function') {
      callback(response);
    }

    return response;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const createV2 = (data, callback) => async (dispatch) => {
  let response;

  dispatch(increaseLoading());

  try {
    response = await UserRequests.createUser(data);

    setDataLayer(
      'sign_up',
      {
        method: 'password',
      },
      true,
    );
  } catch (err) {
    response = err.message;
  } finally {
    pixelTrack('CompleteRegistration', {
      content_name: data.name,
      currency: 'BRL',
      status: 'done',
    });
    dispatch(decreaseLoading());
  }

  if (callback && typeof callback === 'function') {
    callback(response);
  }
};

export const register = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let response;

  try {
    response = await UserRequests.createUser(data);

    setDataLayer(
      'sign_up',
      {
        method: 'password',
      },
      true,
    );

    MessageService.success('SUCCESS.REGISTERED');
    return response;
  } catch (err) {
    //
  } finally {
    dispatch(decreaseLoading());
  }

  if (callback && typeof callback === 'function') {
    callback(response);
  }
};

export const updateUser = (id, data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const { profilePicBase } = data;

    if (profilePicBase) {
      const { url } = await dispatch(uploadImageBase64(profilePicBase));

      if (url) {
        data = {
          ...data,
          profilePicUrl: url,
        };
      }
    }

    const response = await UserRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateUserPassword = (id, data, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await UserRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const contactUs = (payload, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await UserRequests.contactUs(payload);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    //
  } finally {
    dispatch(decreaseLoading());
  }
};

export const authenticateTwoFactor =
  (payload, callback) => async (dispatch) => {
    dispatch(increaseLoading());

    try {
      await UserRequests.authenticateTwoFactor(payload);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      const errors = I18n.t('ERRORS');

      if (err && err.message && errors[err.message] !== undefined) {
        return message.error(`${errors[err.message]}`);
      } else if (
        err &&
        err.response &&
        err.response.response &&
        err.response.response.error &&
        errors[err.response.response.error] !== undefined
      ) {
        return message.error(`${errors[err.response.response.error]}`);
      } else {
        return message.error(I18n.t('ERRORS.defaultForm'));
      }
    }
  };

export const enabledTwoFactor = (id, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const payload = await UserRequests.enabledTwoFactor(id);
    dispatch({
      type: ACTION_ENABLE_TWO_FACTOR,
      payload,
    });

    if (callback && typeof callback === 'function') {
      callback(payload);
    }

    return payload;
  } catch (err) {
    //
  }
};

export const addTwoFactor = (id, payload, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await UserRequests.addTwoFactor(id, payload);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  }
};

export const recoveryTwoFactor = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await UserRequests.recoveryTwoFactor(id);
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      return message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      return message.error(`${errors[err.response.response.error]}`);
    } else {
      return message.error(I18n.t('ERRORS.defaultForm'));
    }
  }
};

export const resetTwoFactor = (id, payload, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await UserRequests.resetTwoFactor(id, payload);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(
      I18n.t(
        'PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.INAVLID_CODE',
      ),
    );
  }
};

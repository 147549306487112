import { TransactionActions } from '../actions';

const initialState = {
  details: null,
  isPaymentRecurrent: false,
  mineTransactions: {},
  creditCart: null,
};

const transactionReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TransactionActions.ACTION_SAVE_PAYMENT_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    case TransactionActions.ACTION_IS_PAYMENT_RECURRENT:
      state = {
        ...state,
        isPaymentRecurrent: action.payload,
      };
      return state;
    case TransactionActions.ACTION_MINE_TRANSACTIONS:
      state = {
        ...state,
        mineTransactions: action.payload,
      };
      return state;
    case TransactionActions.ACTION_GET_CREDIT_CARD:
      state = {
        ...state,
        creditCart: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default transactionReducer;

export const ACTION_INCREASE_TIMER = 'timer:ACTION_INCREASE_TIMER';
export const ACTION_DESCREASE_TIMER = 'timer:ACTION_DESCREASE_TIMER';

export const increaseTimer = () => ({
  type: ACTION_INCREASE_TIMER,
});

export const decreaseTimer = () => ({
  type: ACTION_DESCREASE_TIMER,
});

export const addTimer = () => (dispatch) => dispatch(increaseTimer());

export const removeTimer = () => (dispatch) => dispatch(decreaseTimer());

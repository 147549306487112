import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const BottomPanel = () => (
  <div className="bottom-panel">
    <Container>
      <Row>
        <Col>
          <div className="bottom-panel__title"></div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default BottomPanel;

import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdvancedButton from '../../../components/AdvancedButton/AdvancedButton';
import AdvancedForm from '../../../components/AdvancedForm/AdvancedForm';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import * as UserActions from '../../../redux/actions/user';
import { translate } from '../../../services/i18n';
import { phoneMask } from '../../../utils/masks';
import { keepOnlyNumbers, removeSpecialChars, validateEmail } from '../../../utils/string';
import { setDataLayer } from '../../../services/analytics';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';

const initialValues = {
  name: '',
  email: '',
  subject: '',
  phone: '',
  message: '',
};

const antIcon = <LoadingOutlined
  style={{ fontSize: 24 }}
  spin
/>;

const ProfileTalk = ({ postContact , loading, me }) => {
  const [ form, setForm ] = useState(initialValues);
  const history = useHistory();

  const onFormSubmit = async () => {
    if (!validateEmail(form.email)) return message.error(translate('ERRORS.invalid_email'));

    if (!form.phone || form.phone.includes('99999-9999') || keepOnlyNumbers(form.phone).length < 10) {
      return message.error('Telefone inválido');
    }

    const data = {
      name: form.name,
      email: form.email,
      phone: removeSpecialChars(form.phone),
      subject: form.subject,
      message: form.message,
    };

    setDataLayer('generate_lead');

    loading = true;
    await postContact(data, () => {
      message.success('Mensagem enviada com sucesso!');
      setForm(initialValues);
      loading = false;
    });
  };

  const onFormChange = (key, value) => {

    if (key === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm(prevState => ({ ...prevState, [key]: value }));
  };

  return (
    <Container>
      <div className="talk-profile">
        <AdvancedForm onSubmit={() => onFormSubmit()}>
          <Row>
            <Col
              lg={6}
              className="talk-profile__column"
            >
              <h2 className="talk-profile__title">
                {translate('PAGES.HOME.TALK.FORM.TITLE')}
              </h2>

              <div className="talk-profile__aux">
                <div className="talk-profile__aux__input-large">
                  <AdvancedInput
                    name="name"
                    label={translate('PAGES.HOME.TALK.FORM.LABEL.NAME')}
                    value={form.name}
                    onChange={(e) => onFormChange('name', e.target.value)}
                    disabled={loading === 1}
                  />
                </div>
              </div>
              <div className="talk-profile__aux">
                <div className="talk-profile__aux__input-large">
                  <AdvancedInput
                    name="email"
                    label={translate('PAGES.HOME.TALK.FORM.LABEL.EMAIL')}
                    value={form.email && form.email.toLowerCase()}
                    onChange={(e) => onFormChange('email', e.target.value)}
                    disabled={loading === 1}
                  />
                </div>
              </div>
              <div className="talk-profile__aux">
                <div className="talk-profile__aux__input">
                  <AdvancedInput
                    name="subject"
                    label={translate('PAGES.HOME.TALK.FORM.LABEL.SUBJECT')}
                    value={form.subject}
                    onChange={(e) => onFormChange('subject', e.target.value)}
                    disabled={loading === 1}
                  />
                </div>
                <div className="talk-profile__aux__input">
                  <AdvancedInput
                    name="phone"
                    label={translate('PAGES.HOME.TALK.FORM.LABEL.PHONE')}
                    value={form.phone}
                    onChange={(e) => onFormChange('phone', phoneMask(e.target.value))}
                    disabled={loading === 1}
                  />
                </div>
              </div>
              <div className="talk-profile__aux">
                <div className="talk-profile__aux__textarea">
                  <AdvancedInput
                    name="message"
                    label={translate('PAGES.HOME.TALK.FORM.LABEL.MESSAGE')}
                    value={form.message}
                    onChange={(e) => onFormChange('message', e.target.value)}
                    rows={4}
                    multiline
                    disabled={loading === 1}
                  />
                </div>
              </div>
              <div className="talk-profile__aux">
                <div className="talk-profile__aux__input-button">
                  <AdvancedButton
                    text={translate('PAGES.HOME.TALK.FORM.BUTTON')}
                    fullWidth
                    type="submit"
                    disabled={loading === 1}
                  />
                </div>
              </div>
              <div className="talk-profile__spin" >
                <Spin
                  spinning={loading === 1}
                  indicator={antIcon}
                />
              </div>
            </Col>
          </Row>
        </AdvancedForm>
      </div>
    </Container>
  );
};


const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  postContact: (payload, callback) => dispatch(UserActions.contactUs(payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTalk);

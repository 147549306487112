import getInstance from './instance';

export default class EvolutionRequests {

  static async create (body) {
    const instance = await getInstance();
    const { data } = await instance.post('/evolution', body);
    return data;
  }

  static async getById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/evolution/${id}`);
    return data;
  }

  static async getMine (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/evolution/mine/', { params });
    return data;
  }

  static async getAll () {
    const instance = await getInstance();
    const { data } = await instance.get('/evolution/');
    return data;
  }

  static async deleteById (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/evolution/${id}`);
    return data;
  }

  static async updateByIdByClient (idEvolution, body) {
    const instance = await getInstance();
    const { data } = await instance.put(`/evolution/${idEvolution}`, body);
    return data;
  }

  static async updateByIdByDoctor (idEvolution, body) {
    const instance = await getInstance();
    const { data } = await instance.put(`/evolution/${idEvolution}`, body);
    return data;
  }

  static async getEvolutionByID (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/evolution/${id}`, { params });
    return data;
  }

}

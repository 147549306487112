import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { CartSymbol } from '../../../enum/cartSymbol';
import { setDataLayer } from '../../../services/analytics';
import { cpfMask, dateOfBirthMask, onlyNumbers, phoneMask } from '../../../utils/masks';
import { keepOnlyNumbers, removeSpecialChars, validaCpf, validateBirthDate } from '../../../utils/string';
import { AuthActions, CartActions, PrescriptionActions, UserActions } from '../../../redux/actions';
import { connect } from 'react-redux';
import { message } from 'antd';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import CheckoutCart from '../../../components/CheckoutCart/CheckoutCart';
import AuthService from '../../../services/auth';
import { getProductById } from '../../../services/product';
import * as ActionsCart from '../../../redux/actions/cart';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';
import CouponCard from '../../../components/CouponCard/CouponCard';
import { translate } from '../../../services/i18n';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import CheckoutInput from '../../../components/CheckoutInput/CheckoutInput';
import { ReactComponent as ProtectionCertificate } from '../../../assets/svg/Protection_certificate.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import CartContent from '../../../components/CartContent/CartContent';
import { I18n } from 'react-redux-i18n';

const formInitialState = {
  name: '',
  email: '',
  password: '',
  phone: '',
  birthDate: '',
};

const couponFormInitialState = {
  coupon: '',
};

const CheckoutStepOne = ({
  getAddressByZipcode,
  createDefaultCart,
  addressByZipcode,
  updateUserProfile,
  register,
  values,
  recurrent,
  freight,
  products,
  calculateTheCart,
  me,
  login,
  getMe,
  replaceCartProducts,
  changeQsCoupon,
  coupon,
  getCouponByTitle,
  removeCoupon,
  setFromPrescriptionPayment,
  fromPrescriptionPayment,
  userActivity,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [ form, setForm ] = useState(formInitialState);
  const [ couponForm, setCouponForm ] = useState(couponFormInitialState);
  const [ couponValidated, setCouponValidated ] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ couponVisible, setCouponVisible ] = useState(false);

  useEffect(() => {
    AuthService.isFromEcommerceDomain();

    setDataLayer('view_cart');

    if (AuthService.isAuthenticated()) {
      getMe();
    }

    setTimeout(async () => {
      const qs = QueryString.parse(window.location.search);

      if (qs && qs.prescritos) {
        const payload = qs.prescritos.split(',').map((o) => ({
          ...getProductById(o),
          quantity: 1,
        }));

        await replaceCartProducts(payload, false);
      }

      removeCoupon();

      if (qs && qs.cupom) {
        changeQsCoupon(qs.cupom.toUpperCase());
        setCouponValidated(true);
        await getCouponByTitle(qs.cupom.toUpperCase());
        calculateTheCart();
      }
    }, 100);

  }, [ pathname ]);

  useEffect(() => {
    if (!coupon && couponValidated) {
      setCouponForm(couponFormInitialState);
    }

    if (coupon) {
      setCouponVisible(true);
    }
  }, [ coupon, couponValidated ]);

  const onNextClick = async () => {

    if (!form.name) {
      return message.error('Informe o nome completo.');
    }

    if (!(/\s/.test(form.name))) {
      return message.error('Informe também o sobrenome');
    }

    if (!form.email) {
      return message.error('Informe um e-mail válido.');
    }

    if (!form.password) {
      return message.error('Informe um CPF válido.');
    }

    if (!validaCpf(removeSpecialChars(form.password))) {
      return message.error('Informe um CPF válido.');
    }

    if (!form.phone || form.phone.includes('99999-9999') || keepOnlyNumbers(form.phone).length < 10) {
      return message.error('Telefone inválido');
    }

    if (!validateBirthDate(form.birthDate)) {
      return message.error('Data de nascimento inválida');
    }

    const payload = {
      name: form.name,
      email: form.email.toLowerCase(),
      phone: onlyNumbers(form.phone),
      birthDate: moment(form.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      password: removeSpecialChars(form.password),
      document: removeSpecialChars(form.password),
      grantType: 'password',
    };

    if (me) {
      updateUserProfile(me.id, payload, () => {
        history.push('/checkout/dados-de-entrega');
      });
    } else {

      await register(payload, async (response) => {

        if (response && response !== 'cpf_already_registered') {
          await login(payload, (loginResponse) => {
            if (!loginResponse.error) {
              userActivity({
                source: pathname
              }, facebookUserActivity.register);
              history.push('/checkout/dados-de-entrega');
            } else {
              message.error('Os dados informados não são válidos. Verifique e tente novamente.');
            }
          });

        } else if (response && response === 'cpf_already_registered') {
          message.error('CPF já cadastrado, verifique o e-mail inserido. Em caso de dificuldades, nos chame no WhatsApp.');
        } else {
          message.error('Erro ao realizar cadastro. Tente novamente mais tarde.');
        }
      });
    }
  };

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        ...me,
        birthDate: moment(me.birthDate).format('DD/MM/YYYY') || '',
        password: me.document,
      });
      onZipcodeUpdate(removeSpecialChars(me.zipcode));
    }

  }, [ me ]);

  useEffect(() => {
    if (addressByZipcode) {
      setForm({
        ...form,
        zipcode: addressByZipcode.zipcode,
      });
    }
  }, [ addressByZipcode ]);


  const handleChangeForm = async (name, value) => {

    if (name === 'birthDate') {
      value = dateOfBirthMask(value);
    }

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });

    if (name === 'zipcode') {
      await onZipcodeUpdate(value);
    }
  };

  const handleChangeCouponForm = (name, value) => {
    setCouponForm({ ...couponForm, [name]: value });
  };

  const onZipcodeUpdate = async (zipcode) => {
    if (zipcode && removeSpecialChars(zipcode).length === 8) {
      await getAddressByZipcode(zipcode);
      await calculateTheCart();
    }
  };

  const onValidateCoupon = async () => {
    if (!couponForm.coupon) {
      return message.error(translate('PAGES.HOME.CART.COUPON.BLANK_COUPON'));
    }

    setCouponValidated(true);
    await getCouponByTitle(couponForm.coupon);
    changeQsCoupon(null);
    calculateTheCart();
  };

  const onRemoveCoupon = () => {
    removeCoupon();
    setCouponValidated(false);
    changeQsCoupon(null);
    calculateTheCart();
  };

  return (
    <CartContent
      pageTitle="Revisar carrinho"
      removeFooter={window.innerWidth <= 1100}
      boxed
      onNextClick={onNextClick}
    >
      <div className="checkout checkout--step-one">
        <div className="checkout__upper">
          {window.innerWidth < 1100 && products && products.length > 0 && (
            <div className="checkout__upper__title mobile-visible">
              <CheckoutCart onNextClick={onNextClick} />
            </div>
          )}

        </div>

        {products && products.length > 0 && (
          <div className="checkout__upper">
            <div className="checkout__upper__title">
              <h2 className="checkout__upper__title__text checkout__upper__title__text--padded mobile-visible">
                {translate('CHECKOUT.STEP_ONE.DATAS_TITLE')}
              </h2>
              <h2 className="checkout__upper__title__form__label mobile-hidden">
                <strong>{translate('CHECKOUT.STEP_ONE.DATAS_TITLE').toUpperCase()}</strong>
              </h2>
              <div className="checkout__upper__title__form">
                <label className="checkout__upper__title__form__label mobile-visible">
                  <strong>{translate('CHECKOUT.STEP_ONE.DELIVERY_SUBTITLE_MOBILE')}</strong>
                </label>
                <p>
                  {translate('CHECKOUT.STEP_ONE.PARAGRAPH_CONTACT')}
                </p>
                <div className="checkout__upper__title__form__item">
                  <Col
                    className="no-padding-left no-padding-mobile"
                  >
                    <div className="checkout__upper__title__form__inner">
                      <div className="checkout__upper__title__form__input--one">
                        <CheckoutInput
                          label={translate('CHECKOUT.STEP_ONE.LABELS.NAME')}
                          value={form.name}
                          onChange={(e) => handleChangeForm('name', e.target.value)}
                          className="checkout__upper__title__form__item__input__name"
                        />
                      </div>
                      <div className="checkout__upper__title__form__input--two">
                        <CheckoutInput
                          label={translate('CHECKOUT.STEP_ONE.LABELS.CPF')}
                          value={cpfMask(form.password)}
                          onChange={(e) => handleChangeForm('password', e.target.value)}
                          disabled={me}
                        />
                      </div>
                    </div>
                  </Col>
                  <br />
                  <Col
                    className="no-padding-left no-padding-mobile"
                  >
                    <div className="checkout__upper__title__form__item__input">
                      <CheckoutInput
                        label={translate('CHECKOUT.STEP_ONE.LABELS.EMAIL')}
                        value={form.email && form.email.toLowerCase()}
                        onChange={(e) => handleChangeForm('email', e.target.value)}
                        disabled={me}
                      />
                    </div>
                  </Col>
                  <br />
                  <Col
                    className="no-padding-left no-padding-mobile"
                  >
                    <div className="checkout__upper__title__form__inner">
                      <div className="checkout__upper__title__form__input--one">
                        <CheckoutInput
                          label={translate('CHECKOUT.STEP_ONE.LABELS.PHONE')}
                          value={phoneMask(form.phone)}
                          onChange={(e) => handleChangeForm('phone', e.target.value)}
                        />
                      </div>
                      <div className="checkout__upper__title__form__input--two">
                        <CheckoutInput
                          label={translate('CHECKOUT.STEP_ONE.LABELS.BORN')}
                          value={form.birthDate || (me && moment(me.birthDate).format('DD/MM/YYYY'))}
                          onChange={(e) => handleChangeForm('birthDate', e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <br />
                </div>
              </div>
            </div>
            {!recurrent && (
              <>
                <div className="checkout__coupon-form">
                  <div className="checkout__coupon-form__title">
                    {translate('CHECKOUT.STEP_ONE.COUPOM_TITLE')}
                  </div>
                  {!coupon ? (
                    <div className="checkout__coupon-form__container">
                      <div className="checkout__coupon-form__container__area">
                        <div className="checkout__coupon-form__container__area__input">
                          <input
                            type="text"
                            placeholder="Inserir"
                            value={couponForm.coupon}
                            onChange={(e) => handleChangeCouponForm('coupon', e.target.value?.toUpperCase())}
                          />
                        </div>
                        <div
                          className="checkout__coupon-form__container__area__input__button"
                          onClick={() => onValidateCoupon()}
                        >
                          {translate('CHECKOUT.STEP_ONE.VALIDATE_COUPOM')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="checkout__content__form">
                      {translate('PAGES.HOME.CART.COUPON.VALID_COUPON')}
                      <CouponCard
                        coupon={coupon}
                        onRemove={() => onRemoveCoupon()}
                      />
                      <strong className="checkout__coupon checkout__coupon--valid">{translate('CHECKOUT.STEP_ONE.VALID_COUPOM')}</strong>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}

        <div className="checkout__certificate-form">
          <div className="checkout__certificate-form__title">
            {translate('CHECKOUT.STEP_ONE.CERTIFICATE_TITLE')}
          </div>
          <div className="checkout__certificate-form__container">
            <ProtectionCertificate className="protection-certificate" />
          </div>
        </div>
        <Col className="mobile-no-padding mobile-visible">
          <div
            className="checkout--step-one__next-button"
            onClick={() => onNextClick()}
          >
            <div className="checkout--step-one__next-button__item">
              <span className="checkout--step-one__next-button__item__text">
                {I18n.t('CHECKOUT.STEP_ONE.NEXT')}
              </span>
              <span className='checkout--step-one__next-button__item__icon'>
                <ArrowRightOutlined />
              </span>
            </div>
          </div>
        </Col>
      </div >
    </CartContent>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
  products: state.cart.products || [],
  freight: state.cart.freight || null,
  values: state.cart.values || null,
  prescription: state.cart.prescription || false,
  addressByZipcode: state.cart.addressByZipcode || null,
  recurrent: state.cart.recurrent || false,
  coupon: state.cart.coupon || null,
  fromPrescriptionPayment: state.prescription.fromPrescriptionPayment || {},
});

const mapDispatchToProps = (dispatch) => ({
  calculateTheCart: () => dispatch(CartActions.calculateTheCart()),
  getAddressByZipcode: (data) => dispatch(CartActions.getAddressByZipcode(data)),
  getFreightAmount: (data) => dispatch(CartActions.getFreightAmount(data)),
  updateUserProfile: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  logoutUser: () => dispatch(AuthActions.logoutUser()),
  login: (data, callback) => dispatch(AuthActions.authenticateV2(data, callback)),
  register: (data, callback) => dispatch(UserActions.createV2(data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  replaceCartProducts: (products, recurrent) => dispatch(ActionsCart.replaceCartProducts(products, recurrent)),
  changeQsCoupon: (value) => dispatch(CartActions.changeQsCoupon(value)),
  getCouponByTitle: (title) => dispatch(CartActions.getCouponByTitle(title)),
  removeCoupon: () => dispatch(CartActions.removeCoupon()),
  addProductsInCart: (idProduct) => dispatch(ActionsCart.addOrRemoveProduct(idProduct, 1, CartSymbol.ADD)),
  setFromPrescriptionPayment: (params) => dispatch(PrescriptionActions.setFromPrescriptionPayment(params)),
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepOne);


import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  horizontal: true,
  horizontalSwiping: true,
  dotsClass: 'home-dots',
  autoplay: true,
  autoplaySpeed: 6000,
};

const HomeSlider = () => (
  <div className="home-box-slider mobile-hidden">
    <Slider {...settings}>
      <a
        href="/assinatura"
        className="home-box-slider__item__inner home-box-slider__item__inner--one"
      />
      <a
        href="/produtos/anti-aging"
        className="home-box-slider__item__inner home-box-slider__item__inner--two"
      />
    </Slider>
  </div>
);

export default HomeSlider;

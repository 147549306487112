const hairKitItems = [
  {
    title: '<strong>Shampoo Energizante: </strong> Limpa profundamente',
    text: '<p><u>O que é:</u> Shampoo com alta tecnologia que fortalece os fios e combate a caspa.</p><p><u>Diferencial:</u> Não resseca, cheiroso e aprovado nos testes clínicos.</p><p><u>Como age:</u> Atingindo as camadas mais profundas do couro cabeludo, ele limpa e retira a oleosidade excessiva, garantindo a melhor absorção dos nutriente. Além de revitaliza e fortalece os fios fracos e quebradiços</p><p><u>Composição:</u> H-Vit, Biotina, Pantenol, Abietoil, Café, Jaborandi.</p>',
    img: '/assets/img/hair-kit/about/shampoo.png'
  },
  {
    title: '<strong>Vita gummy: </strong> Fornece nutrientes',
    text: '<p><u>O que é:</u> Bala de gelatina com 11 vitaminas necessárias para o seu cabelo.</p><p><u>Diferencial:</u> 4x mais forte do mercado e zero açúcar</p><p><u>Como age:</u> Suprindo todas as necessidades diárias de vitaminas e minerais para que o crescimento e fortalecimento do cabelo, se dê em sua máxima performance.</p><p><u>Composição:</u> Vitamina A (1.200mcg), Vitamina B5 (5mg), Biotina (45mg), Vitamina B9 (667mg), Vitamina B12 (8mcg), Vitamina C (60mg), Vitamina D (19mcg), Vitamina E (10mg), Iodo (80mcg) e Zinco (7mg)</p>',
    img: '/assets/img/hair-kit/about/gummy.png'
  },
  {
    title: '<strong>Espuma capilar: </strong>  Acelera o crescimento',
    text: '<p><u>Diferencial:</u> em espuma, não contém álcool e absorção rápida e profunda.</p><p><u>Como age:</u> Vascularizando o local de aplicação, promove a criação de vasos sanguíneos e estimula os bulbos capilares, além de acelerar o crescimento dos fios.</p>',
    img: '/assets/img/hair-kit/about/minoxidil.png'
  },
  {
    title: '<strong>Capsula bloqueadora: </strong> Interrompe a queda capilar',
    text: '<p><u>Diferencial:</u> Pureza ao grau de 100%</p><p><u>Como age:</u> Bloqueando os receptores de DHT (Di-hidrotestosterona) das células capilares, cessando assim a queda capilar por fatores genéticos</p>',
    img: '/assets/img/hair-kit/about/finasterida.png'
  }
];

export default hairKitItems;
const MoneyBackFaqItems = [
  {
    title: 'Como funciona?',
    text: 'Acreditamos muito nos nossos tratamentos e assim, caso você tenha feita a avaliação online e o médico independente tenha prescrito o hair kit completo, você estará automaticamente elegível ao programa. Para participar, basta informar sua vontade no nosso whatsapp e cumprir as regras acima.',
  },
  {
    title: 'Quando recebo o meu dinheiro de volta?',
    text: 'Caso você tenha cumprido todos os 6 critérios integralmente, basta solicitar o reembolso e faremos em até 30 dias em sua conta, após a solicitação.',
  },
  {
    title: 'Tenho uma prescrição, posso participar?',
    text: 'A participação do programa é válida apenas para os clientes que fizeram a nossa avaliação online e que o médico independente tenha prescrito o kit completo. Faça agora a avaliação gratuita.',
  },
  {
    title: 'O médico não me prescreveu todos os produtos, posso participar?',
    text: 'O programa somente é válido para quem foi indicado o uso de todos os produtos.',
  },
  {
    title: 'Onde minha imagem será utilizada?',
    text: 'Iremos utilizar sua imagem em divulgações da marca, expondo os resultados reais e seu nome, para que mais pessoas possam aderir ao tratamento sem objeções =)',
  },
  {
    title: 'Se sobrar produto no mês, o que acontece?',
    text: 'Os produtos foram desenvolvidos para durarem 30 dias exatamente. Caso, por qualquer motivo, eles sobrem, favor nos informar pelo whatsapp, imediatamente, para entendermos se o uso diário está correto ou se há algum erro no processo. Ficará ao critério da The Men’s a exclusão ou continuidade do cliente ao programa, de acordo com as justificativas enviadas.',
  },
  {
    title: 'Se eu não enviar as fotos e os vídeos, o que acontece?',
    text: 'Caso você não envie as fotos e os vídeos acordados na data correta, por qualquer motivo, você não estará mais elegível ao reembolso.',
  },
  {
    title: 'Posso desistir do programa?',
    text: 'Sim, por favor nos informe da desistência e estará tudo bem, sem cobranças ou taxas.',
  },
  {
    title: 'Tenho que utilizar todos os produtos?',
    text: 'Sim, todos os dias e de forma como o médico indicou. Caso deixe de utilizar qualquer produto, por qualquer motivo, você não estará mais elegível ao reembolso.',
  },
  {
    title: 'Se a entrega atrasar, como funciona?',
    text: 'Os kits deverão ser adquiridos sempre nas mesmas datas ou pelo modelo de assinatura. Caso a entrega atrase, favor entrar em contato conosco para entendermos o motivo e solucionarmos o mais rápido possível. Caso o cliente tenha viajado ou não possa receber o kit, automaticamente será desclassificado do programa e inelegível ao reembolso.',
  },
];

export default MoneyBackFaqItems;

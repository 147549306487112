export const ProductCategory = {
  HAIR: 1,
  SKIN: 2,
  BEARD: 3,
  HEALTH: 4,
};

export const prescriptionType = {
  hair: 1,
  sleep: 2,
  ed: 3
};

export const productCategoryArray = [
  { id: ProductCategory.HAIR, name: 'Cabelo' },
  { id: ProductCategory.SKIN, name: 'Pele' },
  { id: ProductCategory.BEARD, name: 'Barba' },
  { id: ProductCategory.HEALTH, name: 'Bem estar' },
];

export const getProductCategoryTitle = (value) => value && (productCategoryArray.find((o) => o.id === value).name);


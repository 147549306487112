const recomendedProductsItems = [
  {
    img: '/assets/img/product-page-recomended/vita-gummy.png',
    stars: '★★★★★',
    price: 'R$66,30',
    name: 'Vita gummy',
    text: 'Nossa vita gummy contém todos os nutrientes necessários para fortalecer seu cabelo, pele e unhas, com um delicioso sabor de abacaxi e zero açúcar.',
    id: '2ad58d0e-5934-4f1a-9d51-8cba378122b6'
  },
  {
    img: '/assets/img/product-page-recomended/chocosono.png',
    stars: '★★★★★',
    price: 'R$59,90',
    name: 'Chocosono Zero Açucar- 5 caixas',
    text: 'A fórmula perfeita e saborosa para você ter uma boa noite de sono, dormindo profundamente e acordando revigorado.',
    id: '3c1ca3a0-8bb4-4419-aac8-ef2e1ed3c136'
  },
  {
    img: '/assets/img/product-page-recomended/serum-vit.png',
    stars: '★★★★☆',
    price: 'R$99,90',
    name: 'Sérum Vit C + Ácido Hialurônico',
    text: 'Um creme que reduz, previne as linhas de expressão e deixa sua pele mais firme, macia e hidratada, dando a você um aspecto mais jovem e saudável.',
    id: '8cc5dd9a-732f-45cb-bfd1-2dbdbd462719'
  },
  {
    img: '/assets/img/product-page-recomended/tonico.png',
    stars: '★★★★',
    price: 'R$64,90',
    name: 'Tônico para barba',
    text: 'Um creme que reduz, previne as linhas de expressão e  deixa sua pele mais firme, macia e hidratada, dando a você um aspecto mais jovem e saudável.',
    id: 'fbd7397f-8071-4f0a-9a64-90debfbf908a'
  },
];

export default recomendedProductsItems;
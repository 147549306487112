import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useState } from 'react';
import { UserActions } from '../../../app/redux/actions';
import { translate } from '../../services/i18n';
import AdvancedInputPassword from '../AdvancedInputPassword/AdvancedInputPassword';
import { connect } from 'react-redux';


const RegisterPasswordModal = ({
  handleClose,
  addTwoFactor,
  me,
  isCloseIcon,
}) => {

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const [ createPassword, setCreatePassword ] = useState(initialValues);

  const handleInput = (key, value) => {
    setCreatePassword((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onCreatePassword = () => {
    if (createPassword.password === '') {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.MESSAGE.ERROR.ENTER_PASSWORD'));
    } else if (createPassword.password !== createPassword.confirmPassword) {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.MESSAGE.ERROR.NO_MATCH_PASSWORD'));
    } else if (createPassword.password.length < 6) {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.ERROR.INVALID_LENGHT'));
    }
    else {
      const payload = {
        twoFactor: createPassword.password,
      };

      addTwoFactor(me?.id, payload, () => {
        message.success(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.MESSAGE.SUCCES.REGISTER_PASSWORD'));
        handleClose();
      });
    }
  };

  return (
    <div className="register-password">
      <div className="register-password__container">
        <div open
          className="register-password__container__inner">
          {isCloseIcon && (
            <div className="close-modal">
              <CloseOutlined onClick={handleClose}
                className="close-icon" />
            </div>
          )}
          <div className="register-password__container__inner__content-title">
            <p className="register-password__container__inner__content-title__title">
              {translate('PAGES.HOME.PROFILE_REGISTER.REGISTER_PASSWORD_MODAL.TITLE')}
            </p>
          </div>
          <div className="register-password__container__inner__container-input">
            <div className="register-password__container__inner__container-input__content">
              <label className="register-password__container__inner__container-input__content__label">
                {translate('PAGES.HOME.PROFILE_REGISTER.REGISTER_PASSWORD_MODAL.TEXT')}
              </label>
              <AdvancedInputPassword
                passwordInput
                onChange={(value) => handleInput('password', value)}
                value={createPassword.password}
                type="password"
                className="register-password__container__inner__container-input__content__input"
              />
            </div>
          </div>
          <div className="register-password__container__inner__container-input">
            <div className="register-password__container__inner__container-input__content">
              <label className="register-password__container__inner__container-input__content__label">
                {translate('PAGES.HOME.PROFILE_REGISTER.REGISTER_PASSWORD_MODAL.CONFIRM_PASSSWORD')}
              </label>
              <AdvancedInputPassword
                passwordInput
                onChange={(value) => handleInput('confirmPassword', value)}
                value={createPassword.confirmPassword}
                type="password"
                className="register-password__container__inner__container-input__content__input"
              />
            </div>
          </div>
          <div className="register-password__container__inner__container-button__create">
            <button
              onClick={onCreatePassword}
              className="register-password__container__inner__container-button__create__button">
              {translate('PAGES.HOME.PROFILE_REGISTER.REGISTER_PASSWORD_MODAL.BUTTON')}
              <ArrowRightOutlined />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  user: state.user.document,
});

const mapDispatchToProps = (dispatch) => ({
  addTwoFactor: (id, payload, callback) => dispatch(UserActions.addTwoFactor(id, payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPasswordModal);
import React from 'react';
import { ReactComponent as Bag } from '../../assets/svg/ic_bag.svg';
import { connect } from 'react-redux';
import * as ActionsCart from '../../redux/actions/cart';
import { CartSymbol } from '../../enum/cartSymbol';
import { translate } from '../../services/i18n';
import { ProductModalActions } from '../../redux/actions';
import { validateMobile } from '../../utils/validateMobile';

const OurProductsCard = (props) => {
  return (
    <div
      className="our-products-card"
      style={{ backgroundImage: `url(${props.backgroundImage && props.backgroundImage})` }}
    >
      <div className="our-products-card__top">
        <div className="our-products-card__top__title">
          {props.name && props.name}
        </div>
        <div className="our-products-card__top__price">
          {props.price && props.price}
        </div>
      </div>
      {/* {props.label && (
        <div className="our-products-card__label">
          medicamento
        </div>
      )} */}
      {props.soldOff && (
        <div className="our-products-card__label our-products-card__label--sold-off">
          {translate('SOLD_OFF.TITLE')}
        </div>
      )}
      <div
        className="our-products-card__bottom"
        onClick={async () => {
          if (!props.soldOff) {
            if (
              props.redirectToPrescription &&
              typeof props.redirectToPrescription === 'function'
            ) {
              props.redirectToPrescription();
              return;
            }

            await props.addProductsInCart(props.productId);
            props.setVisibleModal(true);

            if (validateMobile(window.screen.width)) props.message();
          }
        }}
      >
        <Bag className="our-products-card__bottom__icon" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productByID: state.product.productByID,
  productsInCart: state.cart.productsInCart,
  products: state.cart.products || [],
  fromPrescriptionPayment: state.prescription.fromPrescriptionPayment,
  isVisible: state.productModal
});

const mapDispatchToProps = (dispatch) => ({
  addProductsInCart: (idProduct) => dispatch(ActionsCart.addOrRemoveProduct(idProduct, 1, CartSymbol.ADD)),
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
  setInvisibleModal: () => dispatch(ProductModalActions.hideModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OurProductsCard);
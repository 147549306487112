import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { decreaseLoading, increaseLoading } from './loading';
import { getQuestionsByType, QuestionType } from '../../services/prescription';
import PrescriptionRequests from '../../api/prescription';

export const ACTION_GET_PRESCRIPTION_BY_ID =
  'prescription:ACTION_GET_PRESCRIPTION_BY_ID';
export const ACTION_GET_PRESCRIPTION = 'prescription:ACTION_GET_PRESCRIPTION';
export const ACTION_CREATE_PRESCRIPTION =
  'prescription:ACTION_CREATE_PRESCRIPTION';
export const ACTION_SET_QUIZ_USER_RESPONSE =
  'prescription:ACTION_SET_QUIZ_USER_RESPONSE';
export const ACTION_SET_QUIZ_USER_PAYLOAD =
  'prescription:ACTION_SET_QUIZ_USER_PAYLOAD';
export const ACTION_SET_FROM_PESCRIPTION_PAYMENT =
  'prescription:ACTION_SET_PESCRIPTION_PAYMENT';

export const setQuizUserResponse = (payload, clear) => async (dispatch) => {
  if (clear) {
    payload = getQuestionsByType(QuestionType.HAIR_LOSS);
  }

  dispatch({
    type: ACTION_SET_QUIZ_USER_RESPONSE,
    payload,
  });
};

export const setFromPrescriptionPayment = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_SET_FROM_PESCRIPTION_PAYMENT,
      payload,
    });
  } catch (err) {
    //
  }
};

export const setQuizUserPayload = (payload) => async (dispatch) => {
  dispatch({
    type: ACTION_SET_QUIZ_USER_PAYLOAD,
    payload,
  });
};

export const createPrescription = (data, callback) => async (dispatch) => {
  dispatch(increaseLoading());
  let result;

  try {
    result = await PrescriptionRequests.createPrescription(data);
    dispatch({
      type: ACTION_CREATE_PRESCRIPTION,
      payload: result,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }

  if (callback) {
    callback(result);
  }

  return result;
};

export const getPrescriptionById =
  (id, parameters, callback) => async (dispatch) => {
    dispatch(increaseLoading());
    let result;

    try {
      result = await PrescriptionRequests.getPrescriptionById(id, parameters);
      dispatch({
        type: ACTION_GET_PRESCRIPTION_BY_ID,
        payload: result,
      });
    } catch (err) {
      const errors = I18n.t('ERRORS');

      if (err && err.message && errors[err.message] !== undefined) {
        message.error(`${errors[err.message]}`);
      } else if (
        err &&
        err.response &&
        err.response.response &&
        err.response.response.error &&
        errors[err.response.response.error] !== undefined
      ) {
        message.error(`${errors[err.response.response.error]}`);
      } else {
        message.error(errors.genericError);
      }
    } finally {
      dispatch(decreaseLoading());
    }

    if (callback) {
      callback(result);
    }

    return result;
  };

export const uploadPrescription = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await PrescriptionRequests.uploadPrescription(params);
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const checkValid = (params, callback) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await PrescriptionRequests.checkValid(params);
    callback(response);
  } catch (err) {
    message.error(I18n.t('ERRORS.product_needs_prescription'));
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getById = (id, callback) => async () => {
  try {
    const response = await PrescriptionRequests.getPrescriptionById(id);
    callback(response);
  } catch (err) {
    //
  } finally {
    //
  }
};

export const getUserPrescriptionByType = (type) => async () => {
  let response;

  try {
    response = await PrescriptionRequests.getUserPrescriptionByType(type);
  } catch (err) {
    message.error(err);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return response;
  }
};

export const getPrescription = () => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await PrescriptionRequests.getPrescription();
    dispatch({
      type: ACTION_GET_PRESCRIPTION,
      payload: result,
    });
  } catch (err) {
    message.error(err);
  } finally {
    dispatch(decreaseLoading());
  }
};

import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import packageJson from '../../../package.json';
import HomeNavigationStack from '../pages/Home/HomeNavigationStack';
import AuthNavigationStack from '../pages/Auth/AuthNavigationStack';
import ProfileNavigationStack from '../pages/Profile/ProfileNavigationStack';
import CheckoutNavigationStack from '../pages/Checkout/CheckoutNavigationStack';
import PrescriptionNavigationStack from '../pages/Prescription/PrescriptionNavigationStack';
import PrescriptionSwitchNavigationStack from '../pages/PrescriptionSwitch/PrescriptionSwitchNavigationStack';
import PageViewProvider from '../pages/PageViewProvider/PageViewProvider';
import TimerProvider from '../components/TimerPrivider/TimerProvider';
import TrackOrderNavigationStack from '../pages/TrackOrder/TrackOrderNavigationStack';

const Router = () => {

  const caching = () => {
    const version = localStorage.getItem('version');

    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version',packageJson.version);
    }
  };

  useEffect(() => {
    caching();
  },[]);

  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route path="/">
            <TimerProvider expiryTimestamp={10000}/>
            <PageViewProvider />
            <HomeNavigationStack />
            <CheckoutNavigationStack />
            <AuthNavigationStack />
            <ProfileNavigationStack />
            <PrescriptionNavigationStack />
            <PrescriptionSwitchNavigationStack />
            <TrackOrderNavigationStack />
          </Route>
        </Switch>
      </div>

    </BrowserRouter>
  );
};

export default Router;


import { LoginModalActions } from '../actions';

export default (
  state = 0,
  action,
) => {
  switch (action.type) {
    case LoginModalActions.ACTION_VISIBLE_MODAL:
      return state + 1;
    case LoginModalActions.ACTION_INVISIBLE_MODAL:
      return state - 1;
    default:
      return state;
  }
};
import React, { useEffect, useRef, useState } from 'react';

export const AutocompleteDropdown = ({
  value, onChange, inputComponent,
  autoCompleteOptions, minLength, trigger
}) => {

  const [ activeItem, setActiveItem ] = useState(0);
  const [ focused, setFocused ] = useState(true);
  const scrollRef = useRef(null);

  const autoCompleteList = (() => {
    if (value.length <= minLength) return [];

    const splitedValue = value.split(trigger);

    if (splitedValue.length !== 2) return [];

    const autoCompletes = autoCompleteOptions.filter(autoCompleteItem => autoCompleteItem.indexOf(splitedValue[1]) === 0);
    const listToShow = autoCompletes.map(autoCompleteItem => splitedValue[0] + trigger + autoCompleteItem);

    if (listToShow.length === 1 && listToShow[0] === value) return [];

    return listToShow;
  })();

  const onKeyDown = (event) => {
    if (autoCompleteList.length && activeItem && event.key === 'Enter') {
      event.preventDefault();
      onChange(autoCompleteList[activeItem - 1]);
      return;
    }

    if (!autoCompleteList.length) {
      return;
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      setActiveItem(prev => (() => {
        if (!prev && autoCompleteList.length) {
          if (event.key === 'ArrowDown') return 1;

          return autoCompleteList.length;
        }

        if (prev >= autoCompleteList.length && event.key === 'ArrowDown') return 0;

        return event.key === 'ArrowDown' ? prev + 1 : prev - 1;
      })());
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if (activeItem === 0) return;

    const element = document.getElementById(`auto-complete-${activeItem - 1}`).scrollIntoView();
    // eslint-disable-next-line no-unused-expressions
    element?.scrollIntoView(true);
  }, [ activeItem ]);

  return (
    <>
      {inputComponent(value, onChange, onKeyDown, onFocus, onBlur)}
      {(!!autoCompleteList.length) && (
        <div className="auto-complete-dropdown-container"
          hidden={!focused}
          ref={scrollRef}>
          <ul>
            {autoCompleteList.map((item, index) => (
              <li
                id={`auto-complete-${index}`}
                onMouseDown={() => onChange(item)}
                className={activeItem - 1 === index ? 'active-item' : ''}
                key={item}
              >{item}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
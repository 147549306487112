import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PromoSection = (props) => {
  return (
    <div className="promo-section">
      <Container>
        <Row>
          <Col
            lg={12}
          >
            <div className="promo-section__inner">
              <div className="promo-section__inner__title">
                <h1 className="promo-section__inner__title__text">
                  {props.title}
                </h1>
              </div>
              <div className="promo-section__inner__product">
                <div className="promo-section__inner__product__item">
                  <img
                    src={props.product.IMAGE}
                    className="mobile-visible"
                    alt=""
                  />
                  <h3 className="promo-section__inner__product__item__title">
                    {props.product.TITLE}
                  </h3>
                  <p className="promo-section__inner__product__item__description">
                    {props.product.DESCRIPTION}
                  </p>
                  <p className="promo-section__inner__product__item__description">
                    {props.product.DESCRIPTION2}
                  </p>
                  <p className="promo-section__inner__product__item__description promo-section__inner__product__item__description--bottom">
                    <b>{props.product.BOLD_TEXT}&nbsp;</b>
                    {props.product.TEXT}
                  </p>
                  <div className="promo-section__inner__product__item__price">
                    <div className="promo-section__inner__product__item__price__gray">
                      {props.product.PRICE_FROM}
                    </div>
                    <div className="promo-section__inner__product__item__price__black">
                      {props.product.PRICE_TO}
                    </div>
                  </div>
                  <Link to={props.buttonLink}>
                    <span className="promo-section__inner__product__item__button">
                      {props.buttonText}
                    </span>
                  </Link>
                  <p className="promo-section__inner__product__item__obs">
                    {props.bottomInfo}
                  </p>
                </div>
                <img
                  src={props.product.IMAGE}
                  className="mobile-hidden"
                  alt=""
                />
              </div>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromoSection;

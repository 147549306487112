const ptBr = {
  ORIENTATION_MESSAGE: 'Para uma melhor experiência, sugerimos que utilize o modo retrato do seu smartphone.',
  APPLICATION_NAME: 'The Men\'s',
  SHARED: {
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    fileValidation: 'Por favor insira uma imagem no formato png ou jpg',
    pictureValidation: 'Adicione uma foto para identificação',
    ageValidation: 'Adicione uma data de nascimento válida ',
    ageAlert: 'Você possui menos de 18 anos',
    NO_FEES: 'sem juros',
    WITH_FEES_OF: 'com juros de',
    OF: 'de'
  },
  CURRENCY: {
    LANG: 'pt-BR',
    MONEY: 'BRL',
  },
  APPLICATION: {
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',
      UNAUTHORIZED: 'Acesso expirado. Faça login novamente!',
      NOT_FOUND: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      TIMEOUT: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
    },
  },
  SUCCESS: {
    LOGGED: 'Seja bem vindo(a)',
    REGISTERED: 'Cadastro realizado com sucesso',
    UPDATED: 'Cadastro atualizado',
    SEND_RECOVERY_EMAIL: 'Enviamos o código de recuperação em seu e-mail, verifique também a caixa de spam.',
    RECOVERY_PASSWORD: 'Sua senha foi alterada com sucesso! Entre novamente.',
    SUCCESS_QUIZ: 'Questionário respondido com sucesso! Entraremos em contato.'
  },
  ERRORS: {
    isSDK: true,
    forbidden: 'Você não possui permissão para essa ação',
    invalid_two_factor: 'Não foi possível acessar o perfil.',
    user_already_has_recurrence: 'Você já possui um plano recorrente.',
    bellow_minimum_total_allowed: 'Valor da parcela abaixo do permitido.',
    installments_amount_not_allowed: 'Quantidade de parcelas acima do permitido, máximo 3 parcelas.',
    installments_not_allowed_total_value: 'Parcelamento não disponivel para valor abaixo de R$ 300.00.',
    zipcode_not_allowed: 'Ainda não atendemos na sua região, mas já já estamos ai.',
    invalid_holder: 'Nome impresso no cartão inválido.',
    invalid_brand_type: 'Tipo de cartão não aceito.',
    invalid_expiration_date: 'Dados do cartão não são válidos, verifique e tente novamente.',
    invalid_zipcode: 'CEP inválido.',
    project_setup_not_found: 'Site em manutenção.',
    defaultForm: 'Algo errado aconteceu. Tente novamente mais tarde.',
    invalid_file_extension: 'Extensão da imagem inválida.',
    genericError: 'Algo errado aconteceu. Tente novamente mais tarde.',
    fields: 'Verifique os campos e tente novamente.',
    entity_not_found: 'Algo errado aconteceu, tente novamente mais tarde.',
    invalid_credentials: 'Email ou CPF incorretos, verifique e tente novamente.',
    invalid_password: 'A senha deve ter pelo menos 6 caracteres',
    user_not_found: 'Email ou senha incorretas, verifique e tente novamente.',
    user_no_access: 'Você não tem acesso a essa tela. Entre em contato com um administrador.',
    user_invalid_token: 'Token inválido ou expirado. Faça login novamente.',
    generic: 'Preencha todos os campos, e tente novamente.',
    email: 'E-mail inválido, verifique e tente novamente.',
    passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
    passwordsRequired: 'A senha e confirmação de senha são requeridas.',
    email_already_registered: 'E-mail já utilizado.',
    email_already_used: 'E-mail já utilizado.',
    cpf_already_registered: 'O CPF utilizado já está cadastrado.',
    invalid_email: 'Insira um e-mail válido.',
    invalid_name: 'Nome inválido.',
    invalid_social_media: 'Midia social inválida.',
    email_already_registerd: 'O e-mail inserido já está sendo utilizado.',
    email_and_cellphone_already_registered: 'O e-mail e o celular já estão sendo utilizados.',
    email_and_document_already_registered: 'E-mail e CPF ja cadastrados.',
    cellphone_already_used: 'O celular inserido já está sendo utilizado.',
    cellphone_already_registered: 'O celular inserido já está sendo utilizado.',
    document_already_used: 'O CPF inserido já está sendo utilizado.',
    document_already_registered: 'O CPF inserido já está sendo utilizado.',
    document_and_cellphone_already_registered: 'O CPF e o celular já estão sendo utilizados.',
    document_and_email_already_registered: 'O CPF e o e-mail já estão sendo utilizados.',
    document_and_email_and_cellphone_already_registered: 'O CPF, e-mail e celular já estão sendo utilizados.',
    cpf: 'CPF inválido, verifique e tente novamente.',
    invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
    maxSelectedFiles: 'Apenas um arquivo por vez.',
    fileEmpty: 'Verifique o formato do arquivo, ele está sem registros válidos.',
    invalidDateFormat: 'Existe uma data com formato não suportado.',
    invalidLoginFormat: 'Existe um login com formato não suportado.',
    companyImportingUser: 'Um processo de importação já está acontecendo, tente novamente mais tarde.',
    company_importing_user: 'Um processo de importação já está acontecendo, tente novamente mais tarde.',
    genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
    login_already_used: 'Já existe um usuário com o login informado, verifique e tente novamente.',
    invalid_id: 'Selecione a empresa.',
    invalid_cellphone: 'O documento apresenta algum registro com celular inválido.',
    invalid_block: 'O documento apresenta algum registro com bloco inválido.',
    invalid_tower: 'O documento apresenta algum registro com torre inválido.',
    invalid_number: 'O documento apresenta algum registro com número inválido.',
    duplicated_email: 'O documento apresenta dois ou mais usuários com e-mail duplicados.',
    duplicated_cellphone: 'O documento apresenta dois ou mais usuários com celular duplicados.',
    empty: 'O documento enviado não pode estar vazio.',
    post_office_order_status_not_found: 'Pedido não encontrado',
    newsLetter: {
      email: 'E-mail inválido, verifique e tente novamente.',
      name: 'Nome inváldo, preencha também o seu sobrenome.',
    },
    already_has_recurrence: 'Você já possui um plano recorrente.',
    transaction_already_approved: 'Transação já aprovada.',
    transaction_already_cancellation: 'Transação em cancelamento.',
    above_expected_installment_number: 'Número de parcelas inválido.',
    transaction_waiting_for_payment_confirmation: '',
    payment_error: 'Erro no pagamento, verifique os dados informados e tente novamente.',
    item_already_evaluated: 'Você já avaliou esse item.',
    coupon_not_found: 'O cupom inserido não é válido. Deixe em branco caso não tenha um.',
    not_applicable_coupon: 'Este cupom não é válido para seu carrinho',
    coupon_not_logged: 'Voce precisa estar logado para usar o cupom.',
    product_needs_prescription: 'Este produto precisa de uma prescrição médica',
    CALCULATE_INSTALLMENT: 'Ocorreu um erro ao calcular as parcelas',
    QUIZ_ERROR: 'Algo deu errado ao responder o questionário, tente novamente mais tarde.',
    quiz_already_exists: 'Encontramos uma avaliação vinculada ao seu nome. Aguarde enquanto redirecionamos você!',
  },
  URLS: {
    USER_PERFIL: '/perfil/registro',
    CLINIC: '/clinica',
    PRESCRIPTION: '/avaliacao',
  },
  ENUM: {
    BRANDTYPE: {
      VISA: 'VISA',
      MASTERCARD: 'MASTERCARD',
      MAESTRO: 'MAESTRO',
      ELO: 'ELO',
      HIPERCARD: 'HIPERCARD',
      DINERS_CLUB: 'DINERS CLUB',
      AMERICAN_EXPRESS: 'AMERICAN EXPRESS',
      AMEX: 'AMEX',
    },
    INSTALLMENTS: {
      ONE: '1X',
      TWO: '2X',
      THREE: '3X',
    },
    TRANSFER_STATUS: {
      1: {
        title: 'SUA COMPRA FOI APROVADA',
        description: 'Obrigado por comprar com a gente! Esperamos que sua experiência tenha sido surpreendente',
      },
      2: {
        title: 'COMPRA REALIZADA + BOLETO EMITIDO',
        description: 'Estamos aguardando o pagamento do boleto emitido, o prazo para o boleto ser compensado é de até 4 dias úteis. Você pode encontrar o boleto em seu e-mail.',
      },
      3: {
        title: 'SUA COMPRA FOI APROVADA',
        description: 'Obrigado por comprar com a gente! Esperamos que sua experiência tenha sido surpreendente',
      },
      4: {
        title: 'EM SEPARAÇÃO',
        description: 'Obrigado por comprar com a gente! Esperamos que sua experiência tenha sido surpreendente',
      },
      5: {
        title: 'EM TRÂNSITO',
        description: 'Seu pedido já está em preparação, logo mais estará aí!',
      },
      6: {
        title: 'ENTREGUE',
        description: 'Seu pedido chegou! Experimente e surpreenda-se!',
      },
      7: {
        title: 'CANCELADO',
        description: 'Este pedido foi cancelado',
      },
    }
  },
  ADD_TO_BAG: 'ADICIONAR NA SACOLA',
  CHOOSE_QUANTITY: 'ESCOLHER QUANTIDADE',
  ERROR_CARD: 'Dados do cartão não são válidos, verifique e tente novamente!',
  SUCCESS_CARD: 'Pedido realizado com sucesso!',
  ERROR_BLOCK_ZIPCODE_TITLE: 'Ops...',
  ERROR_BLOCK_ZIPCODE_CONTENT: 'Ainda não operamos na sua região, mas queremos chegar ai! Estamos trabalhando duro para atingirmos todo o Brasil até o final do ano.',
  ERROR_PAYMENT_TITLE: 'Erro no pagamento',
  ERROR_PAYMENT_CONTENT_INTRO: 'Parece que algo deu errado ao realizar o pagamento do seu pedido. Por favor verifique se:',
  ERROR_PAYMENT_CONTENT_OPTIONS_FIRST: '• Os dados inseridos estão corretos;',
  ERROR_PAYMENT_CONTENT_OPTIONS_SECOND: '• O endereço de cobrança e de entrega são iguais.',
  ERROR_PAYMENT_CONTENT_FAREWELL: 'Em caso de dúvidas, entre em contato com a operadora do cartão.',
  PAGES: {
    TRACK_ORDER: {
      URL: '/rastrear-pedido',
      TRACK_ORDER_TEXT: 'Rastreie seu pedido',
      TYPE_THE_CODE: 'LO235569205CN',
      SEND: 'Buscar pedido',
      FOLLOW_YOUR_ORDER: 'Acompanhe o status do seu pedido',
      OBJECT_NOT_POSTED: 'Objeto em preparo/coleta pelos correios',
      PLEASE_WAIT_UPDATES: 'Por favor, aguarde novas atualizações',
      COPY_ORDER_TRACKING: 'Copiar rastreio',
      SUCCESS_COPY_ORDER_TRACKING: 'Link do rastreio copiado!',
      DELIVERY_FORECAST: 'Previsão de entrega: ',
      WARNING: 'ATENÇÃO: Infelizmente os correios estão em greve parcial, podendo afetar algumas entregas.',
      WARNING_WORKING_TO_SOLVE: 'Estamos trabalhando duro para solucionar o mais rápido possível.'
    },
    HOME: {
      URL: '/',
      HOME: {
        PAGE_TITLE: 'Início',
        URL: '/',
      },
      HAIR_KIT: {
        PAGE_TITLE: 'Hair Kit',
        URL: '/hair-kit',
      },
      MONEY_BACK: {
        PAGE_TITLE: 'Garantimos seu dinheiro devolta',
        URL: '/money-back',
      },
      SLEEP_GUMMY: {
        PAGE_TITLE: 'Sleep gummy',
        URL: '/lp-sleep-gummy',
      },
      SLEEP_GUMMY_TWO: {
        PAGE_TITLE: 'Sleep gummy',
        URL: '/lp-sleep-gummy-cart',
      },
      LP_SLEEP_GUMMY: {
        PAGE_TITLE: 'Sleep gummy',
        URL: '/sleep-gummy-lp',
      },
      HELP: {
        PAGE_TITLE: 'Podemos Ajudar?',
        URL: '/podemos-ajudar',
      },
      ABOUT: {
        PAGE_TITLE: 'Quem Somos',
        URL: '/quem-somos',
      },
      PRODUCT: {
        PAGE_TITLE: 'Produtos',
        URL: '/produtos/:slug',
      },
      CART: {
        PAGE_TITLE: 'Finalizar Pagamento',
        URL: '/checkout/revisao',
        COUPON: {
          ADD_COUPON: 'Você possui um cupom de desconto? Caso sim, adicione no formulário abaixo e clique em validar',
          ADD_COUPON_2: 'VALIDAR',
          INVALID_COUPON: 'O cupom inserido não é válido.',
          BLANK_COUPON: 'Cupom em branco não é valido.',
          VALID_COUPON: 'O cupom de desconto abaixo será utilizado em sua compra.',
        },
      },
      APPROVED: {
        PAGE_TITLE: 'Pagamento Aprovado',
        URL: '/pagamento-aprovado',
      },
      PRESCRIPTION: {
        PAGE_TITLE: 'Avaliação Médica',
        URL: '/prescricao',
      },
      SEARCH: {
        PAGE_TITLE: 'Buscar',
        URL: '/busca',
      },
      CLINIC: {
        PAGE_TITLE: 'Clínica ',
        URL: '/clinica',
      },
      CLINIC_SUCCESS: {
        PAGE_TITLE: 'Clínica ',
        URL: '/clinica-sucesso',
      },
      RECURRENCE: {
        PAGE_TITLE: 'Assinatura hairkit',
        URL: '/recorrencia',
      },
      SUBSCRIPTION: {
        PAGE_TITLE: 'Assinatura hair kit',
        URL: '/assinatura',
      },
      REGISTER: {
        IMAGE_PAGE_TITLE: 'Vem com a gente!',
        PAGE_TITLE: 'Cadastro',
        URL: '/cadastro',
        TITLE: 'Cadastre-se',
        FORM: {
          COME_BACK: '< VOLTAR',
          REGISTER: 'Criar Cadastro',
          REGISTER_TWO: 'Crie seu cadastro para poder acessar sua conta.',
          TITLE_ONE: 'DADOS PESSOAIS',
          TITLE_TWO: 'ENDEREÇO DE ENTREGA',
          TITLE1: 'DADOS PESSOAIS',
          TITLE2: 'ENDEREÇO DE ENTREGA',
          TITLE3: 'Preencha os campos abaixo para iniciar a avaliação médica:',
          TITLE4: 'NOVA CONTA',
          DESCRIPTION: 'Preencha os campos abaixo:',
          DESCRIPTION_TWO: 'Todas informações são completamente sigilosas e estarão seguras em nosso site.',
          DESCRIPTION_THREE: 'Estamos prontos para iniciar a avaliação, clique no botão quando estiver também',
          BUTTON: 'Cadastrar',
          BUTTON_THREE: 'COMEÇAR AGORA',
          LABEL: {
            FIRST_NAME: 'Nome *',
            LAST_NAME: 'Sobrenome *',
            CPF: 'CPF *',
            BIRTH: 'Data de Nasc. *',
            PHONE: 'Seu melhor telefone *',
            EMAIL: 'E-mail *',
            PASSWORD: 'Senha *',
            CONFIRMPASSWORD: 'Confirmação de senha *',
            CEP: 'CEP *',
            UNKNOWN_CEP: 'Não sei meu CEP',
            ADDRESS: 'Endereço *',
            NEIGHBORHOOD: 'Bairro *',
            NUMBER: 'Número *',
            COMPLEMENT: 'Complemento',
            CITY: 'Cidade *',
            UF: 'Estado *',
            REFERENCE: 'Ponto de referência',
            CHANGE_PASSWORD: 'Alterar minha senha',
          },
          MESSAGE: {
            ERROR: {
              INVALID_LAST_NAME: 'Digite no mínimo 3 caracteres para o sobrenome',
            },
          },
        },
      },
      HOW_ARE_YOU: 'Tudo bem?',
      MESSAGE_DEFAULT_NITRATE: 'Eu, Dr. Bruno, analisei seu caso e como você há a utilização de medicamento com nitrato, os medicamentos existentes na plataforma poderão interagir de forma nociva. Assim, sugerimos a ida até seu cardiologista para que juntos consigam encontrar a melhor solução para o tratamento sexual, sem prejudicar sua saúde. Ficamos a disposição, Abs.',
      PROFILE_REGISTER: {
        PASSWORD_MODAL: {
          TITLE: 'DIGITE SUA SENHA PARA ACESSAR',
          TITLE_ALTERNATIVE: 'DIGITE OU CADASTRE UMA SENHA PARA ACESSAR',
          TEXT: 'Se não tiver uma, sua senha automaticamente é seu CPF.',
          COMPLETE_FIELDS: 'Complete os campos abaixo:',
          TEXT_ALTERNATIVE: 'Se não tiver uma, sua senha automaticamente é seu CPF, ou',
          COMPLEMENT_TEXT: 'Caso tenha esquecido a sua senha, ',
          SPECIAL_CHARACTERES: 'Se for seu CPF, digite apenas os números.',
          LINK: 'clique aqui',
          FINAL_TEXT: ' para recuperá-la.',
          FINAL_TEXT_ALTERNATIVE: ' para cadastrar.',
          LABEL: 'Digite sua senha',
          LABEL_ALTERNATIVE: 'Digite seu CPF',
          BUTTON: 'Acessar',
          RECOVERY_PASSWORD: {
            TITLE: 'VERIFIQUE SEU E-MAIL',
            TEXT: 'Enviamos uma nova senha para o seu e-mail.',
            BUTTON: 'Cadastrar nova senha',
            LABEL: {
              CODE: 'Insira o código recebido em seu e-mail',
              NEW_PASSWORD: 'Digite sua nova senha',
              CONFIRM_NEW_PASSWORD: 'Confirme sua nova senha',
            },
            MESSAGE: {
              EMAIL_SUCCESS: 'Senha enviada para seu e-mail com sucesso',
              SUCCESS: 'Nova senha cadastrada com sucesso.',
              ERROR: {
                INAVLID_CODE: 'Código inválido.',
                INVALID_TOKEN: 'Insira o código.',
                INVALID_NEW_PASSWORD: 'Insira a nova senha.',
                CONFIRM_PASSSWORD: 'Confirme a nova senha.',
                NO_MATCH_PASSWORD: 'As senhas não correspondem.',
                INVALID_LENGHT: 'A senha deve possuir no mínimo 6 caracteres.'
              }
            },
          },
          MESSAGE: {
            ERROR: {
              ENTER_PASSWORD: 'Inisira uma senha.',
              NO_MATCH_PASSWORD: 'As senhas não correspondem.',
            },
            SUCCES: {
              REGISTER_PASSWORD: 'Senha cadastrada com sucesso.',
            },
          }
        },
        REGISTER_PASSWORD_MODAL: {
          TITLE: 'CADASTRE SUA NOVA SENHA',
          TEXT: 'Inisira sua nova senha',
          CONFIRM_PASSSWORD: 'Confirme sua senha',
          BUTTON: 'CADASTRAR',
        },
        FORM: {
          CANCEL: 'CANCELAR ASSINATURA',
          UPLOAD: 'Alterar foto',
          TITLE1: 'DADOS PESSOAIS',
          TITLE2: 'DADOS DA CONTA',
          TITLE3: 'ENDEREÇO DE ENTREGA',
          TITLE4: 'Assinante mensal hair kit',
          DESCRIPTION: 'Preencha os campos abaixo:',
          BUTTON: 'Salvar',
          BUTTON_PASSWORD: 'Alterar senha',
          BUTTON_PASSWORD_CANCEL: 'Cancelar alteração',
          BUTTON_FORGOT: 'Esqueci minha Senha',
          LABEL: {
            FIRST_NAME: 'Nome *',
            LAST_NAME: 'Sobrenome *',
            CPF: 'CPF *',
            BIRTH: 'Nascimento *',
            PHONE: 'Telefone *',
            EMAIL: 'E-mail *',
            PASSWORD: 'Nova senha *',
            CONFIRMPASSWORD: 'Confirmação de senha *',
            OLD_PASSWORD: 'Senha antiga *',
            CEP: 'CEP',
            UNKNOWN_CEP: 'Não sei meu CEP',
            ADDRESS: 'Rua',
            NEIGHBORHOOD: 'Bairro',
            NUMBER: 'Número',
            COMPLEMENT: 'Complemento',
            CITY: 'Cidade',
            UF: 'Estado',
            REFERENCE: 'Ponto de referência',
            Nº: 'Nº'
          },
          PROFILE: {
            TITLE: 'PERFIL',
            NAME: 'Nome:',
            EMAIL: 'E-mail:',
            PASSWORD: 'Senha:',
            FONE: 'Telefone:',
            BIRTH: 'Data de Nasc.:',
            CPF: 'CPF:',
          },
          ADDRESS: {
            TITLE: 'ENDEREÇO DE ENTREGA',
            ADDRESS: 'Rua:',
            NEIGHBORHOOD: 'Bairro:',
            CITY: 'Cidade:',
            STATE: 'Estado:',
            CEP: 'CEP:',
            COMPLEMENT: 'Complemento:',
          },
          PRESCRIPTION: {
            TITLE: 'PRESCRIÇÕES',
            NEW: 'Fazer nova avaliação médica',
            NOTHING: 'Nenhuma prescrição encontrada',
          },
          PAYMENT: {
            TITLE: 'MÉTODO DE PAGAMENTO',
          },
          HELP: {
            TITLE: 'AJUDA',
            TEXT: 'A The Mens acredita que ',
            TEXT_2: 'e por isso adota um atendimento full time via WhatsApp com nosso time de atendimento afim de encontrar a sua solução o mais rápido possível.',
            SUBTITLE: 'Para falar conosco, basta clicar no botão abaixo:',
            TALK: 'Fale Conosco',
          },
          REQUEST: {
            TITLE: 'PEDIDOS',
            DATE: 'Data do Pedido',
            CODE: 'Código de rastreio',
            ADDRESS: 'Endereço de entrega',
            TOTAL_VALUE: 'Valor total',
            REQUEST_NUMBER: 'Nº do pedido',
            NO_REQUEST: 'Não encontramos nenhum histórico de pedido nesta conta'
          },
          SIGNATURE: {
            TITLE: 'ASSINATURAS',
            STATUS: 'Status',
            NEXT_REQUEST: 'Próximo pedido',
            ADDRESS: 'Endereço de entrega',
            DOUBT: 'Dúvidas, problemas ou deseja cancelar?',
            NO_SIGNATURE: 'Não encontramos nenhum histórico de tratamento nesta conta'
          },
          CHAT: {
            TITLE: 'CHAT',
            BUTTON: 'Começar meu tratamento agora',
            BEST_ANSWER: 'Clique na melhor opção de resposta:',
            NO_CHAT: 'Não existem histórico de conversas.',
            NO_DOCTOR: 'Aguardando algum doutor atender.',
            CONFIRMATION: 'Confirmar horário'
          }
        }
      },
      TALK: {
        FORM: {
          TITLE: 'Podemos ajudar?',
          BUTTON: 'Enviar mensagem',
          LABEL: {
            NAME: 'Nome completo',
            EMAIL: 'E-mail',
            PHONE: 'Celular',
            SUBJECT: 'Assunto',
            DOCUMENT: 'CPF valido',
            MESSAGE: 'Digite aqui sua mensagem',
          },
          success: 'Mensagem enviada com sucesso!'
        }
      },
      PROFILE_EVOLUTION: {
        MESSAGE: {
          UPLOADIMAGETOP: 'Atualize a imagem da parte de cima do cabelo',
          UPLOADIMAGEFRONT: 'Atualize a imagem da parte da frente do cabelo',
          UPLOADIMAGEBACK: 'Atualize a imagem da parte de trás do cabelo',
          UPDATE_BLOCKED: 'As imagens não pode ser atualizadas',
        },
        FORM: {
          TITLE: 'Você está em: Evolução',
        }
      },
      PROFILE_REQUEST: {
        FORM: {
          TITLE: 'Você está em: Pedidos',
        }
      },
    },
    AUTH: {
      LOGIN: {
        URL: '/login',
        FORM: {
          TITLE: 'faça o seu login',
          MESSAGE: 'Doutor acesse agora o seu consultório',
          TEXT_REGISTER: 'Ainda não tem uma conta?',
          TEXT_LINK: 'Cadastre-se aqui',
          WELCOME: 'Bem-vindo à the mens!',
          EMAIL: {
            PLACEHOLDER: 'E-mail cadastrado'
          },
          PASSWORD: {
            PLACEHOLDER: 'CPF'
          },
          PASSWORD_RECOVERY: {
            TEXT: 'Não lembra sua senha?',
            URL: ''
          },
          BUTTON: {
            TEXT: 'CONECTAR'
          }
        },
        LOGIN: 'Login',
        PERFIL: 'Perfil'
      },
      PASSWORD_RECOVERY: {
        URL: '/password-recovery',
        FORM: {
          NEW_PASSWORD: {
            PLACEHOLDER: 'Nova senha',
          },
          CONFIRM_NEW_PASSWORD: {
            PLACEHOLDER: 'Confirmação nova senha',
          },
          MESSAGE: 'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
          EMAIL: {
            PLACEHOLDER: 'Seu e-mail cadastrado'
          },
          TEXT_LINK: 'Voltar ao login',
          BUTTON: {
            TEXT: 'Enviar E-mail'
          }
        },
        CODE: {
          MESSAGE: 'Insira o código recebido em seu e-mail para redefinição de senha',
          PLACEHOLDER: 'Código Recebido',
          TEXT_LINK: 'Voltar ao login',
          BUTTON: {
            TEXT: 'Enviar Código'
          }
        }
      },
      SEARCH: {
        PLACEHOLDER: 'Digite o que esta procurando',
      }
    },
    PANEL: {
      PANEL_HOME: {
        PAGE_TITLE: 'Painel - Consultas',
        URL: '/painel/consultas',
        LABEL: {
          NAME: 'Buscar paciente por nome',
          DATE: 'Busca por data'
        },
        TITLES: {
          TITLE1: 'Consultas pendentes',
          TITLE2: 'Video-consultas pendentes',
          TITLE3: 'Remarcação Horário',
          TITLE4: 'Pendente Paciente ',
          TITLE5: 'Mensagens não visualizadas',
          TITLE6: 'Video consultas agendadas',
        }
      },
      PANEL_MEDICAL_RECORD: {
        PAGE_TITLE: 'Painel - Prontuário',
        URL: '/painel/prontuario',
        HEADER_PANEL: {
          ORDER: 'Solicitação: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          SEX: 'Sexo: Masculino',
          AGE: '31 anos',
          QUERY: '1º consulta',
          ATTENDANCE: 'Atendimento Pendente'
        },
        HISTORY: 'Histórico de Consultas',
        SCORE: {
          SCORE_POINTS: 'SCORE: 100',
          SCORE_DESCRIPTION: 'Fotos do paciente',
          CHECKBOX: {
            TITLE: 'Solicitar ao paciente que refaça as fotos',
            ONE: 'Parte de cima da cabeça',
            TWO: 'Parte da Frente',
            THREE: 'Parte de Trás',
          },
        },
        EVALUATION: {
          TITLE: 'Respostas da Avaliação'
        },
        BUTTONS: {
          ONE: 'Sair da consulta',
          TWO: 'Necessário video consulta',
          THREE: 'Prescrever',
          FOUR: 'Agendar Video Consulta',
          FIVE: 'Não necessita medicamento'
        }
      },
      PRESCRIPTION: {
        PAGE_TITLE: 'Painel - Prescrição',
        URL: '/painel/prescricao',
      },
      SERVICES_PERFORMED: {
        PAGE_TITLE: 'Painel - Atendimentos',
        URL: '/painel/atendimentos',
        TITLE: 'Selecione os filtros',
        SEARCH: 'Buscar paciente por nome',
        VIDEO: 'Video Consultas Realizadas',
        ASSESSMENTS: 'Avaliações Realizadas',
        PRESCRIPTION: 'Prescrições Realizadas',
        DATE: 'Data'
      },
      FINANCIAL: {
        PAGE_TITLE: 'Painel - Financeiro',
        URL: '/painel/financeiro',
        TITLE: 'Selecione os filtros',
        MONTH: 'Mês atual',
        PAYMENT: 'Pagamento Pendente',
        DATE: 'Selecionar Período',
        TOTAL: 'Total',
        FILTER: 'para o filtro selecionado',
        VALUE: 'R$10.750,00'
      },
      SCHEDULE: {
        PAGE_TITLE: 'Painel - Agendamento',
        URL: '/painel/agendamento',
      },
      CHAT: {
        PAGE_TITLE: 'Chat',
        URL: '/painel/chat',
      },
    },
    PROFILE: {
      PROFILE_REGISTER: {
        PAGE_TITLE: 'Perfil - Registro',
        URL: '/perfil/registro',
      },
      PROFILE_TALK: {
        PAGE_TITLE: 'Perfil - Fale Conosco',
        URL: '/perfil/fale-conosco',
      },
      PROFILE_CHAT: {
        PAGE_TITLE: 'Perfil - Chat',
        URL: '/perfil/chat',
        CONFIRMATION: 'OK, obrigado',
        CONFIRMATION_SCHEDULE_GREETINGS: 'Olá Dr.(a), tudo bem?',
        CONFIRMATION_SCHEDULE_HOUR: 'Confirmo o agendamento para ',
        CONFIRMATION_SCHEDULE_FAREWELL: 'Obg, abs',
        QUESTION: 'Doutor, eu tenho uma pergunta',
        NO_PRESCRIPTION: 'Sem tratamento',
        MESSAGE: {
          ERROR: 'Nenhuma conversa encontrada',
        },
      },
      PROFILE_EVOLUTION: {
        PAGE_TITLE: 'Perfil - Assinaturas',
        URL: '/perfil/assinaturas',
      },
      PROFILE_REQUESTS: {
        PAGE_TITLE: 'Perfil - Pedidos',
        URL: '/perfil/pedidos',
      },
      PROFILE_SIGNATURE: {
        PAGE_TITLE: 'Perfil - Assinaturas',
        URL: '/perfil/assinaturas',
      },
      PROFILE_HELP: {
        PAGE_TITLE: 'Perfil - Ajuda',
        URL: '/perfil/ajuda',
      }
    },
    FOOTER: {
      TITLE: 'Fique por dentro das novidades',
      RODAPE: {
        TEXT: 'As informações contidas neste site não devem ser usadas para automedicação e não substituem, em hipótese alguma, as orientações dadas pelo profissional da área médica. Somente o médico está apto a diagnosticar qualquer problema de saúde e prescrever o tratamento adequado. Se você estiver interessado em um produto com prescrição, a The Men’s o ajudará a marcar uma consulta com um médico independente que avaliará se você é ou não um candidato adequado para o produto com prescrição e, se apropriado, pode prescrever uma receita para o produto que você pode solicitar na farmácia de sua escolha. Todos os preços e condições deste site são válidos apenas para compras no site. Destacamos que os preços previstos no site prevalecem aos demais anunciados em outros meios de comunicação e sites de buscas. Em caso de divergência, o preço válido é o do carrinho de compras. Imagens meramente ilustrativas. Confira condições na sacola de compras.',
        RIGHTS: '© the men’s todos os direitos reservados'
      },
      FORM: {
        NAME: 'nome e sobrenome',
        EMAIL: 'seu e-mail',
        BUTTON: 'CADASTRAR',
        SUCCESS: 'E-mail cadastrado com sucesso',
        ERROR: 'Não foi possível cadastrar e-mail',
      },
      POLITIC: 'Ao se cadastrar você concorda em receber comunicação publicitária, com ofertas e novidades, conforme a nossa ',
      POLITIC_LINK: 'política de privacidade',
      SOCIALS: {
        TITLE: 'Siga nossas redes sociais:',
        TITLE_TALK: 'Fale conosco:',
        TITLE_PAYMENT: 'Formas de pagamento:',
      },
      LINKS: {
        TITLE_ONE: 'PRODUTOS',
        TITLE_TWO: 'INSTITUCIONAL',
        MEDICAL: {
          LINK: '/avaliacao',
          TEXT: 'INICIAR A CONSULTA MÉDICA'
        },
        ITEMS_LEFT: {
          ONE: {
            NAME: 'HAIR KIT',
            LINK: '/hair-kit',
            ID: 'e6c99cac-19cf-4f18-8704-0fff17dbb133',
            SOLD_OFF: 'false',
          },
          THREE: {
            NAME: 'VITA GUMMY',
            LINK: '/produtos/gummy',
            ID: '2ad58d0e-5934-4f1a-9d51-8cba378122b6',
            SOLD_OFF: 'false',
          },
          FOUR: {
            NAME: 'ANTI-AGING',
            LINK: '/produtos/anti-aging',
            ID: '8cc5dd9a-732f-45cb-bfd1-2dbdbd462719',
            SOLD_OFF: 'false',
          },
          SIX: {
            NAME: 'TÔNICO PARA BARBA',
            LINK: '/produtos/tonico-para-barba',
            ID: '3b445d33-0849-4976-aa07-11cc61770141',
            SOLD_OFF: 'false',
          },
          SEVEN: {
            NAME: 'SLEEP GUMMY',
            LINK: '/produtos/9847293873423',
            ID: 'e743ec60-df88-4f4b-ac84-748be2473d2b',
            SOLD_OFF: 'true',
          },
          EIGHT: {
            NAME: 'SHAMPOO ENERGIZANTE',
            LINK: '/produtos/shampoo-energizante',
            ID: 'e6c99cac-19cf-4f18-8704-0fff17dbb133',
            SOLD_OFF: 'false',
          },
        },
        ITEMS_RIGHT: {
          ONE: {
            NAME: 'QUEM SOMOS',
            LINK: '/quem-somos',
          },
          TWO: {
            NAME: 'BLOG',
            LINK: 'https://blog.themens.com.br/',
          },
          THREE: {
            NAME: 'PODEMOS AJUDAR?',
            LINK: '/podemos-ajudar',
          },
          FOUR: {
            NAME: 'POLÍTICAS DE TROCAS E DEVOLUÇÕES',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf',
          },
          FIVE: {
            NAME: 'TERMOS E CONDIÇÕES',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf',
          },
          SIX: {
            NAME: 'POLÍTICA DE PRIVACIDADE',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf',
          },
          SEVEN: {
            NAME: 'SITE SEGURO',
            LINK: 'https://transparencyreport.google.com/safe-browsing/search?url=themens.com.br&hl=pt-PT',
          }
        },
        FOOTER_ITEMS: {
          ONE: {
            NAME: 'Termos de Uso',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf',
          },
          TWO: {
            NAME: 'Política de Devolução',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf',
          },
          THREE: {
            NAME: 'Política de Privacidade',
            LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf',
          },
        }
      }
    }
  },
  BUY_AGAIN: 'Comprar novamente',
  LIGHTNING_OFFER: {
    BUTTON: 'Comprar agora com um click',
    DESCOUNT: '-15%',
    COUNTDOWN: {
      TITLE: 'ACABA EM',
      TIMEOUT: 'Tempo esgotado!'
    }
  },
  OFFER_CAROUSEL: {
    TITLE: {
      EXCLUSIVE_OFFER: 'APROVEITE ESSA OFERTA* EXCLUSIVA',
      BUYNOW: 'COMPRE AGORA ',
      ONCLICK: 'COM APENAS UM CLICK ',
      EXCLUSIVE: 'ESSA OFERTA EXCLUSIVA:',
    },
    DESCRIPTION: '*Oferta válida somente em conjunto com o item anteriormente comprado',
    SHAMPOO: {
      URL: '/checkout/pagamento?validatecard=6a55247d-6bcd-4073-b090-00e2a38faeda',
    },
    ANTI_AGING: {
      URL: '/checkout/pagamento?validatecard=bb1db63f-4a7b-4925-b1ee-94f47d3f5d1c',
    },
    CHA_DAILY: {
      URL: '/checkout/pagamento?validatecard=27d86cbd-419b-474b-9503-454340ab5800',
    },
    VITA_GUMMY: {
      URL: '/checkout/pagamento?validatecard=f3f2ee08-d53f-471e-8a50-e1ea0cff86f6',
    },
    TONIC: {
      URL: '/checkout/pagamento?validatecard=38b60b58-235c-4e79-9ddf-d91e784e7a1c',
    },
  },
  OFFER: {
    SHAMPOO: {
      ALT: 'shampoo-energizante',
      TITLE: 'SHAMPOO ENERGIZANTE',
      TEXT: 'Chega de oleosidade, queda de cabelo e caspas!',
      OFPRICE: 'R$ 49,90',
      PRICE: 'R$ 42,41',
    },
    ANTI_AGING: {
      ALT: 'anti-aging',
      TITLE: 'ANTI-AGING 10% NANO VIT C',
      TEXT: 'Envelhecer não precisa dar na cara',
      OFPRICE: 'R$ 99,90',
      PRICE: 'R$ 84,91',
    },
    CHA_DAILY: {
      ALT: 'cha-daily',
      TITLE: 'CHA DAILY BOOST',
      TEXT: 'O melhor caminho para desinchar e se sentir mais disposto durante o dia',
      OFPRICE: 'R$ 68,90',
      PRICE: 'R$ 58,56',
    },
    VITA_GUMMY: {
      ALT: 'vita-gummy',
      TITLE: 'VITA GUMMY',
      TEXT: 'Fortaleça seu cabelo, unhas, pele e sua imunidade com apenas um urso',
      OFPRICE: 'R$ 66,30',
      PRICE: 'R$ 56,35',
    },
    TONIC: {
      ALT: 'tonico-para-barba',
      TITLE: 'TÔNICO PARA BARBA',
      TEXT: 'Chega de falhas! Compre o que é certo.',
      OFPRICE: 'R$ 64,90',
      PRICE: 'R$ 55,16',
    },
  },
  TOP_BAR: {
    SLEEP: {
      TEXT: 'Descubra se você dorme bem!',
      SPAN: 'Quiz do sono:',
      URL: 'https://app.gomerlin.com.br/chat/972dd8c1-59a7-4c38-8b55-50c3895a601f',
    },
    NEWS: {
      TEXT: 'Boas-vindas aos novos investidores, Eurofarma e Adventures Inc.',
      SPAN: 'NEWS:',
      URL: 'https://pipelinevalor.globo.com/startups/noticia/eurofarma-lidera-rodada-na-the-mens-um-health-club-masculino.ghtml',
    },
    HAIR: {
      TEXT: 'Transplante capilar é para mim?',
      SPAN: 'Quiz do cabelo:',
      URL: 'https://app.gomerlin.com.br/chat/83a5781e-0171-46d0-8a74-0260b10a5997',
    }
  },
  HAIR_KIT: {
    TEXT: 'Venda sob prescrição médica.',
    APPROVALS: ' Aprovações.',
  },
  BANNER: {
    HOME: {
      TITLE: 'Simplificando a saúde',
      SUBTITLE: 'Tenha seu tratamento personalizado em minutos, aprovado por médicos.',
      BUTTON: 'Comece agora',
      SUBTEXT: 'Clique. Responda. Receba.'
    },
    CHOCOSONO: {
      LAUNCH: 'Lançamento',
      TITLE: 'Chocosono - Zero Açucar',
      SUBTITLE: 'Chocolate com melatonina. Durma bem a noite toda e acorde mais disposto!',
      SUBTITLE_MOBILE: 'Chocolate com melatonina.',
      SUBTEXT_MOBILE: 'Durma bem a noite toda e acorde mais disposto!',
      BUTTON: 'Conheça o Chocosono - Zero Açucar',
    },
    CREATINA: {
      TITLE_FIRST_LINE: 'Não precisa ser caro, ',
      TITLE_SECOND_LINE: 'só precisa ser creatina. ',
      SUBTITLE_FIRST_LINE: 'Conheça a creatina The Men\'s, ',
      SUBTITLE_SECOND_LINE: 'monohidratada e 100% pura. ',
      BUTTON: 'Comprar agora',
    }
  },
  PRODUCTS: {
    BENEFITS: {
      HEADER: 'Existem muitos benefícios de ter um sono regulado, os principais são³:',
    },
    WARNING_HEADER: 'Advertência',
    BLOCKING_MODAL: {
      HEADER: 'Para visualizar o conteúdo e seguir com a sua compra digite um e-mail valido',
      CANCEL: 'Cancelar',
      CONFIRM: 'Acessar'
    },
    MAINTENCE_PAGE: 'Página em Manutenção',
    PRICE: {
      START: 'A partir de:',
    },
    CHOCOSONO: {
      TITLE: 'Chocosono',
    },
    ONE: {
      ID: 'e6c99cac-19cf-4f18-8704-0fff17dbb133',
      NAME: 'Shampoo',
      BREADCRUMB: 'QUEDA DE CABELO',
    },
    TWO: {
      ID: '3b445d33-0849-4976-aa07-11cc61770141',
      NAME: 'Chá',
      BREADCRUMB: 'BEM ESTAR',
    },
    THREE: {
      ID: '8cc5dd9a-732f-45cb-bfd1-2dbdbd462719',
      NAME: 'Anti-aging',
      BREADCRUMB: 'PELE',
    },
    FOUR: {
      ID: 'fbd7397f-8071-4f0a-9a64-90debfbf908a',
      NAME: 'Tônico para barba',
      BREADCRUMB: 'BARBA',
    },
    FIVE: {
      ID: '2ad58d0e-5934-4f1a-9d51-8cba378122b6',
      NAME: 'Gummy',
      BREADCRUMB: 'GOMA',
    },
    SIX: {
      ID: '40c5747d-1b87-4768-a3aa-c1186a9e3b1f',
      NAME: 'Finasterida',
      BREADCRUMB: 'REMÉDIO',
    },
    SEVEN: {
      ID: '6f9919da-d5cc-4948-88ae-5160852cb7b7',
      NAME: 'Minoxidil',
      BREADCRUMB: 'REMÉDIO',
    },
  },
  CHECKOUT_STEP_FIVE: {
    QRCODE: {
      SCAN: 'Escaneie o QR code para efetuar o pagamento ou copie o código no seu aplicativo preferido'
    },
  },
  SOLD_OFF: {
    TITLE: 'Esgotado',
  },
  COMPONENTS: {
    MODAL: {
      CONFIRM: {
        CONTENT: 'Tem certeza de que gostaria de enviar e pagar a avaliação médica?',
        SUCCESS: 'Avaliação médica paga e enviada com sucesso!',
      }
    },
    PRODUCT: {
      TITLE: 'HOME / QUEDA DE CABELO / ',
      MESSAGE: '*Este Produto vai precisar de uma prescrição médica para finalizar a compra',
      ITEMS_RIGHT: {
        ONE: {
          TITLE: 'H-Vit®',
          DESCRIPTION: 'Sua composição age no controle da oleosidade da pele e couro cabeludo, auxilia na restauração da barreira cutânea, fortalece o bulbo capitar e auxilia na prevenção da queda.',
        },
        TWO: {
          TITLE: 'Abietoil',
          DESCRIPTION: 'Auxilia na redução da oleosidade equilibrando a função sebácea e sem ressecar os cabelos.',
        },
        THREE: {
          TITLE: 'Fios extratos tonificantes',
          DESCRIPTION: 'Composto de extratos glicólicos que em sinergia promovem ações tonificante, antissépticas, cicatrizante, protetora, hidratante, regeneradora, anti-inflamatórias, além de doar brilho e maciez aos cabelos através dos derivados de celulose.',
        }
      },
      ITEMS_LEFT: {
        ONE: {
          TITLE: 'Biotina',
          DESCRIPTION: 'A biotina é uma vitamina do complexo B que, além de ajudar a evitar a queda de cabelo, também contribuí para diminuir a caspa.',
        },
        TWO: {
          TITLE: 'Pantenol',
          DESCRIPTION: 'Vitalidade e beleza para os fios; Auxílio no crescimento capilar; Hidrata de forma profunda e duradoura; Ação anti-inflamatória; Melhora a textura e maciez do cabelo.',
        },
        THREE: {
          TITLE: 'Jaborandi',
          DESCRIPTION: 'a planta possui princípios ativos como os taninos que contam com ação adstringente e auxiliam na limpeza profunda do couro cabeludo, combatendo a oleosidade e a seborreia.',
        },
      },
      ADVICES: {
        TITLE_ONE: 'O que é a gummy?',
        DESCRIPTION_ONE: 'É um suplemento alimentar feito à base de gelatina. Nossa fórmula além de ser zero açúcar contém um alto teor de B7 (Biotina), B9 (Ácido fólico), B5 (Ácido Pantotênico) vitaminas A, B12, C, D, zinco entre outras. Essa super combinação irá promover de forma intensa o fortalecimento dos fios de cabelo, retardar a queda capilar, prevenir a calvície e auxiliar no funcionamento do sistema imune.',
      },
      SHAMPOO: {
        TITLE: 'Lorem Ipsum',
        DESCRIPTION: 'Lorem Ipsum lorem ipsum',
      },
      QUESTIONS: {
        TITLE: 'Perguntas frequentes',
        ITEM_RIGHT: {
          ONE: {
            TITLE: 'A vita gummy engorda?',
            DESCRIPTION: 'Acredite, você queima 1 vita gummy com apenas um copo de água gelada.'
          },
          TWO: {
            TITLE: 'A vita gummy é indicada para todos os tipos de cabelo?',
            DESCRIPTION: 'Sim, as vitaminas servem para todos os tipos de cabelo agindo de dentro para fora dos fios.'
          },
          THREE: {
            TITLE: 'Quando vou ver os resultados?',
            DESCRIPTION: 'Para que o tratamento seja eficaz, recomendamos o uso de nossas gummies por 3 meses, porém os resultados variam de acordo com o estilo de vida e metabolismo de cada pessoa. Para um melhor resultado, recomendamos nosso hair kit.'
          },
        },
        ITEM_LEFT: {
          ONE: {
            TITLE: 'Do que é feita a vita gummy?',
            DESCRIPTION: 'Um produto saboroso, zero açúcar, feito a base de gelatina e pectina vegetal cheio de vitaminas. As gummies contém B7 (Biotina), B9 (Ácido fólico), B5 (Ácido Pantotênico) vitaminas A, B12, C, D, zinco e Iodo.'
          },
          TWO: {
            TITLE: 'Quantas gummies posso comer por dia?',
            DESCRIPTION: 'Consumir uma vita gummy ao dia ou conforme orientação médica.'
          },
          THREE: {
            TITLE: 'Quantas gummies posso comer por dia?',
            DESCRIPTION: 'Nossa vita gummy é contraindicada para Gestantes, Lactantes e Crianças.'
          },
        }
      },
      CAROUSEL: {
        TITLE: 'Gostou? Aproveite e compre o kit completo'
      },
      BEGIN: {
        URL: '/avaliacao',
        TITLE: 'Não sabe por onde começar? Nós te ajudamos a montar o',
        TITLE_BOLD: ' hair kit perfeito!',
        DESCRIPTION: 'Faça uma avaliação com um médico especialista e descubra o tratamento ideal para você.',
        BUTTON: 'INICIAR AVALIAÇÃO MÉDICA'
      },
      ADD: {
        TITLE_ONE: 'Shampoo ',
        TITLE_TWO: 'energizante',
        ML: '200ml',
        DESCRIPTION_ONE: 'Um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo.',
        DESCRIPTION_TWO: 'Indicado para',
        DESCRIPTION_THREE: 'todos os tipos de cabelo!',
        DESCRIPTION_FOUR: 'Especialmente para você que tem aquelas entradinhas aparentes ou quer dar mais volume!',
        VALUE: 'R$44,90',
        BUTTON_ADD: 'ADICIONAR NO CARRINHO',
        BUTTON_KIT: 'ASSINE O HAIR KIT E ECONOMIZE 20%',
        BUTTON_KNOW: 'saiba mais sobre a nossa assinatura',
        FREIGHT_TITLE: 'Consultar prazo e valor do frete',
        BUTTON_FREIGHT: 'CONSULTAR',
      },
      SHIPPING: {
        DESCRIPTION: '*Frete grátis para as principais capitais e cidades do Brasil',
      },
      NEED_PRESCRIPTION: {
        TEXT: 'Para adquirir é necessário ',
        PRESCRIPTION: 'prescrição médica.',
        NO_PRESCRIPTION: 'Caso você não possua uma, viabilizamos consultas gratuitas para que possa encontrar o melhor tratamento.',
        CONTACT_MESSAGE: 'Caso queira ser um dos primeiros a se cuidar com the men\'s na sua região, entre agora na nossa lista exclusiva: ',
      },
    },
    PANEL: {
      TITLE: 'Consultório Médico',
    },
    PAGE_CONTENT_HEADER: {
      ITEMS: {
        ONE: {
          TITLE: 'Queda de cabelo',
          URL: '/hair-kit',
        },
        TWO: {
          TITLE: 'Pele',
          URL: '/produtos/anti-aging',
        },
        THREE: {
          TITLE: 'Barba',
          URL: '/produtos/tonico-para-barba',
        },
        FOUR: {
          TITLE: 'Bem Estar',
          URL: '/produtos/cha-do-dia-fora-de-estoque',
        },
        FIVE: {
          TITLE: 'Podemos ajudar?',
          URL: '/podemos-ajudar',
        },
        SIX: {
          TITLE: 'Blog',
          URL: 'https://blog.themens.com.br/',
        },
        SEVEN: {
          TITLE: 'Avaliação médica',
          URL: '/avaliacao',
        },
      },
      ITEMS_RIGHT: {
        TWO: {
          TITLE: 'Login',
          TITLE_PROFILE: 'Perfil',
          URL: '/login',
          ICON: '/assets/img/content-header/ic_login.png',
          CLASS: 'active_two',
        },
        THREE: {
          TITLE: 'Carrinho',
          URL: '/checkout/revisao',
          ICON: '/assets/img/content-header/ic_cart.png',
          CLASS: 'active_three',
        },
      },
      ITEMS_PRODUCT: {
        ONE: {
          PRODUCT: 'GUMMY',
          LINK: '/produtos/gummy',
        },
        TWO: {
          PRODUCT: 'SHAMPOO',
          LINK: '/produtos/shampoo-energizante',
          ID: 'e6c99cac-19cf-4f18-8704-0fff17dbb133'
        },
        THREE: {
          PRODUCT: 'MINOXIDIL',
          LINK: '/produtos/minoxidil',
          ID: '6f9919da-d5cc-4948-88ae-5160852cb7b7',
        },
        FOUR: {
          PRODUCT: 'FINASTERIDA',
          LINK: '/produtos/finasterida',
          ID: '40c5747d-1b87-4768-a3aa-c1186a9e3b1f',
        },
      },
      SUBMENU: {
        LINK: 'CONHEÇA NOSSO HAIR KIT',
      }
    },
    PANEL_CONTENT_HEADER: {
      LOGOUT: 'Sair',
      USERNAME: 'Olá, Dr. Pedro Barbosa',
      ITEMS: {
        ONE: {
          TITLE: 'Inicio',
          URL: '/painel/consultas',
        },
        TWO: {
          TITLE: 'Atendimentos Realizados',
          URL: '/painel/atendimentos',
        },
        THREE: {
          TITLE: 'Financeiro',
          URL: '/painel/financeiro',
        },
        FOUR: {
          TITLE: 'Chat',
          URL: '/painel/chat',
        },
      }
    },
    PROFILE_CONTENT_HEADER: {
      LOGOUT: 'Sair',
      USERNAME: 'Olá, Dr. Pedro Barbosa',
      ITEMS: {
        FOUR: {
          TITLE: 'Perfil',
          URL: '/perfil/registro',
        },
        THREE: {
          TITLE: 'Pedidos',
          URL: '/perfil/pedidos',
        },
        TWO: {
          TITLE: 'Assinaturas',
          URL: '/perfil/assinaturas',
        },
        ONE: {
          TITLE: 'Chat',
          URL: '/perfil/chat',
        },
        SIX: {
          TITLE: 'Ajuda',
          URL: '/perfil/ajuda',
        },
        FIVE: {
          TITLE: 'Sair',
          URL: '/',
        },
      }
    },
    SERVICES_PERFORMED: {
      ITEMS: {
        ONE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        TWO: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        THREE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        FOUR: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        FIVE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        SIX: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada'
        },
        SEVEN: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          STATUS: 'Prescrição Realizada',
        },
      }
    },
    PROFILE_EVOLUTION: {
      ITEMS: {
        ONE: {
          DATE: 'Agosto de 2020 - 08/08/2020',
          IMAGE_ONE: '/assets/img/content-header/evolution_one.png',
          IMAGE_TWO: '/assets/img/content-header/evolution_two.png',
          IMAGE_THREE: '/assets/img/content-header/evolution_two.png',
        },
        TWO: {
          DATE: 'Setembro de 2020 - 08/08/2020',
          IMAGE_ONE: '/assets/img/content-header/evolution_one.png',
          IMAGE_TWO: '/assets/img/content-header/evolution_two.png',
          IMAGE_THREE: '/assets/img/content-header/evolution_two.png',
        },
        THREE: {
          DATE: 'Outubro de 2020 - 08/08/2020',
          IMAGE_ONE: '/assets/img/content-header/evolution_one.png',
          IMAGE_TWO: '/assets/img/content-header/evolution_two.png',
          IMAGE_THREE: '/assets/img/content-header/evolution_two.png',
        },
      },
    },
    HELP: {
      TITLE: 'Podemos Ajudar?',
      TALK: 'Não encontrou o que procurava? Fale conosco!',
      EMAIL: 'contato@themens.com.br',
      ITEMS: {
        ONE: {
          TITLE: 'Termos e Condições',
          DESCRIPTION: 'Ver termos e condições',
          DESCRIPTION_PRESCRIPTION: 'Ver termos e condições da avaliação médica',
          DESCRIPTION_LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf',
          DESCRIPTION_PRESCRIPTION_LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_avaliacao.pdf',
        },
        TWO: {
          TITLE: 'Política de privacidade',
          DESCRIPTION: 'Ver política de privacidade',
          DESCRIPTION_LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf',
        },
        THREE: {
          TITLE: 'Política de troca e devolução',
          DESCRIPTION: 'Ver política de troca e devolução',
          DESCRIPTION_LINK: 'https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf'
        },
      },
      ASKED_FREQUENTLY: {
        TITLE: 'Dúvidas frequentes',
        DOUBTS: {
          FIRST: {
            TITLE: 'Os produtos são testados em animais?',
            DESCRIPTION: 'Não! A The Men\'s não realiza e é totalmente contra testes feitos em animais.Todos os testes são realizados em institutos credenciados pela ANVISA e seguem os rigorosos protocolos de segurança.',
          },
          SECOUND: {
            TITLE: 'Como consultar o prazo de entrega?',
            DESCRIPTION: 'Ao digitar o CEP no campo de frete e clicar em consultar, os valores e prazos de entrega estarão disponíveis.',
          },
          THIRD: {
            TITLE: 'Em caso de alergia, como devo proceder?',
            DESCRIPTION: 'Caso haja alguma reação alérgica ao produto utilizado, você deverá interromper o uso IMEDIATAMENTE e procurar um médico.',
          },
          FOURTH: {
            TITLE: 'Como faço para acompanhar meu pedido?',
            DESCRIPTION: 'Após finalizar a compra, vá para o seu perfil e acesse  "acompanhar pedido", lá seu status sempre estará atualizado.',
          },
          FIFTH: {
            TITLE: 'Posso parcelar minha compra?',
            DESCRIPTION: 'Compras que não forem da assinatura do hair kit podem ser parceladas em até 3 vezes a partir de R$ 300,00.',
          },
          SIXTH: {
            TITLE: 'Como cancelo minha assinatura?',
            DESCRIPTION: 'Não cancele rs... Mas caso deseje, basta enviar um e-mail para atendimento@themens.com.br solicitando o cancelamento.\nLeia em Termos e Condições as regras para cancelamento.'
          }
        }
      },
    },
    CART: {
      ITEMS: {
        ONE: {
          LINK: '/',
          TEXT: 'HOME /',
        },
        TWO: {
          LINK: '/',
          TEXT: 'QUEDA DE CABELO /',
        },
        THREE: {
          LINK: '/',
          TEXT: 'FINALIZAR COMPRA',
        },
      },
      TITLE: 'FINALIZAR COMPRA',
      TITLE_PERSONAL: 'DADOS PESSOAIS',
      TITLE_PAYMENT: 'FORMA DE PAGAMENTO',
      TITLE_BUY: 'RESUMO DA COMPRA',
      FINISH_QUIZ: 'Avançar',
      TITLE_RESUME: 'DADOS DO PEDIDO',
      BUTTON: {
        LOGIN: 'FAZER LOGIN',
        LINK_LOGIN: '/login',
        CREATE: 'CRIAR CONTA',
        FINISH: 'Seguir para compra',
      },
      BLUE: {
        TITLE: 'Quero assinar mensalmente e ganhar 20% de desconto neste hair kit',
        TITLE_TWO: 'Ganhar 20% de desconto e assinar mensalmente este hair kit',
        DESCRIPTION_ONE: 'Assine o hair kit* e',
        DESCRIPTION_TWO: ' GANHE 20% DE DESCONTO. ',
        DESCRIPTION_THREE: 'Você receberá 1 kit com os mesmos produtos deste carrinho todo mês e nós cobraremos apenas uma vez por mês. Nós nos preocuparemos com o envio e você com seu novo penteado!',
        LINK: 'Saber mais',
      },
      PRODUCT: {
        ITEMS: {
          REMOVE: 'REMOVER',
          IMAGE: '/assets/img/products/product_image.png',
          REMOVE_IMAGE: '/assets/img/products/ic_remove.svg',
          ONE: {
            IMAGE: '/assets/img/products/product_image.png',
            PRODUCT: '01 Shampoo',
            OLD_VALUE: 'R$69,90',
            NEW_VALUE: 'R$58,00',
            REMOVE: 'REMOVER',
            REMOVE_IMAGE: '/assets/img/products/ic_remove.svg',
          },
          TWO: {
            PRODUCT: '01 Shampoo',
            OLD_VALUE: 'R$69,90',
            NEW_VALUE: 'R$58,00',
          },
        },
      },
      IMAGE: '/assets/img/products/product_image.png',
      REMOVE: 'REMOVER',
      REMOVE_IMAGE: '/assets/img/products/ic_remove.svg',
      TOTAL: 'TOTAL:',
      TOTAL_CASH: 'TOTAL À VISTA:',
      VALUE_TOTAL: 'R$ ',
      OLD_VALUE: 'R$ ',
      SUBTOTAL: 'Subtotal:',
      VALUE_SUBTOTAL: 'R$ ',
      DISCOUNT: 'Desconto total:',
      VALUE_DISCOUNT: '-R$ ',
      FREIGHT: 'Frete',
      INTEREST: 'Juros:',
      VALUE_FREIGHT: 'a calcular',
      COUPON: 'Cupom de desconto',
      VALUE_COUPON: 'inserir cupom',
      VALIDATE_COUPON: 'Cupom validado',
      FORM: {
        TITLE: 'Preencha os campos abaixo:',
        LABELS: {
          FIRST_NAME: 'Nome *',
          LAST_NAME: 'Sobrenome *',
          CPF: 'CPF *',
          DATE: 'Data de Nascimento *',
          PHONE: 'Seu melhor telefone *',
          EMAIL: 'E-mail *',
          PASSWORD: 'CPF *',
          PASSWORD_TWO: 'Confirmação de CPF *',
          NUMBER_CARD: 'Número do cartão *',
          NAME_CARD: 'Nome impresso no cartão *',
          MONTH: 'Data de validade *',
          YEAR: 'Ano *',
          COD: 'CVV *',
          CPF_CARD: 'CPF do títular *',
          PLOTS: 'Parcelas *',
          CEP: 'CEP *',
          ADDRESS: 'Endereço *',
          NUMBER: 'Número *',
          COMPLEMENT: 'Complemento',
          CITY: 'Cidade *',
          UF: 'Estado *',
          REFERENCE: 'Ponto de referência',
          CEP_KNOW: 'Não sei meu CEP',
          NEIGHBORHOOD: 'Bairro *'
        },
        UPLOAD: {
          TITLE: 'JÁ TEM UMA PRESCRIÇÃO MÉDICA?',
          DESCRIPTION: 'Alguns itens do seu carrinho necessitam de prescrição médica para efetuar a compra. Por favor, fotografe ou envie a foto dela no campo abaixo.',
        },
        ATENTION: {
          TITLE: 'ATENÇÃO',
          DESCRIPTION: {
            TITLE: 'Sua prescrição só será aceita caso esteja de acordo com as instruções abaixo:',
            ITEMS: {
              ONE: {
                TEXT: '1) A prescrição esteja em nome do paciente cadastrado;',
              },
              TWO: {
                TEXT: '2) A prescrição tenha data legível;',
              },
              THREE: {
                TEXT: '3) A prescrição tenha sido emitida até 6 meses da data de solicitaçao. (Validade);',
              },
              FOUR: {
                TEXT: '4) A prescrição esteja legível e conste a assinatura e carimbo do médico, com CRM válido; ',
              },
              FIVE: {
                TEXT: '5) A prescrição não contenha qualquer rasura ou alteração visível;',
              },
              SIX: {
                TEXT: '6) A prescrição esteja com os medicamentos e dosagens iguais a solicitada. Não será possível substituir medicamentos (Exemplo: Minoxidil em comprimido ser trocado por solução; ou minoxidil 2% ser trocado por 5%).',
              },
            },
          },
        },
        BUTTON_NEXT: 'Próximo',
        OBS: '*Para sua comodidade, enviaremos a Nota Fiscal eletrônica para o e-mail cadastrado no pedido. Assim, a the mens e seus clientes colaboram para diminuir impactos no meio ambiente.',
        TITLE_ADDRESS: 'ENDEREÇO DE ENTREGA',
        CEP_KNOW: 'Não sei meu CEP',
        BUTTON_FINISH: 'CONFIRMAR PEDIDO',
      },
      DATA: {
        BUYER: 'Comprador:',
        NAME_BUYER: 'João Pedro Almeida',
        CPF: 'CPF',
        NUMBER_CPF: '444.222.333-09',
        CARD: 'Nº do cartão:',
        NUMBER_CARD: '•••• •••• •••• ',
        NAME: 'Nome no cartão:',
        NAME_CARD: 'JOAO PEDRO ALM',
        VENC: 'Venc.:',
        DATE_VENC: '01/23',
        COD: 'Cód.:',
        NUMBER_COD: '•••',
        CPF_CARD: 'CPF do títular:',
        NUMBER_CPF_CARD: '444.222.333-09',
        PLOTS: 'Parcelas:',
        NUMBER_PLOTS: '2x de 115,00',
        CEP: 'CEP:',
        NUMBER_CEP: '04550-003',
        ADDRESS: 'Endereço de entrega:',
        NAME_ADDRESS: 'Av Jurema 444 - Moema',
        NUMBER: 'Nº:',
        NUMBER_NUMBER: '303',
        COMPLEMENT: 'Complemento:',
        TEXT_COMPLEMENT: ' Apto 90',
        UF: 'Estado:',
        NAME_UF: 'SP',
        CITY: 'Cidade:',
        NAME_CITY: 'São Paulo'
      },
      BUYING: 'continuar comprando',
      OBS: '* Alguns itens do seu kit necessitam de prescrição médica para efetuar a compra',
      BUTTON_CREATE: 'CRIAR CONTA',
    },
    APPROVED: {
      BLOG: {
        PRODUCT_ONE: 'Pele',
        TITLE_ONE: 'Os 7 erros masculinos mais comuns na hora de se cuidar',
        ARTICLE_ONE: 'Ler este artigo',
        PRODUCT_TWO: 'Cabelo',
        TITLE_TWO: 'Estresse e má alimentação aumenta a queda de cabelo?',
        ARTICLE_TWO: 'Ler este artigo',
      },
      TITLE: 'PAGAMENTO APROVADO',
      REQUEST: {
        TITLE: 'PEDIDO #',
        DESCRIPTION_ONE: 'Dias mais volumosos estão chegando ',
        DESCRIPTION_TWO: 'João!',
        DESCRIPTION_THREE: 'Seu pedido foi aprovado e sua avaliação médica recebida! Em breve entraremos em contato através do e-mail cadastrado ou telefone.',
        DESCRIPTION_FOUR: 'Para acompanhar seu pedido, clique na aba ',
        DESCRIPTION_FIVE: 'meus pedidos.',
        DESCRIPTION_SIX: 'Não esqueça de postar e compartilhar quando seu hair kit chegar! #themens #hairkit',
      },
      PRODUCT: {
        TITLE: 'Você pode gostar:',
      },
      ITEMS: {
        ONE: {
          LINK: '/',
          TEXT: 'HOME /',
        },
        TWO: {
          LINK: '/hair-kit',
          TEXT: 'QUEDA DE CABELO /',
        },
        THREE: {
          LINK: '/',
          TEXT: 'PEDIDO SOLICITADO',
        },
      },
      ITEMS_OTHER: {
        ONE: {
          TITLE: 'Tônico para barba',
          DESCRIPTION: 'barba mais volumosas e com brilho',
          VALUE: 'R$59,90',
          IMAGE: '/assets/img/products/product_image_one.png',
          LINK_BUTTON: '/produtos',
          BUTTON: 'conhecer o produto',
          LINK_CART: '/checkout/revisao',
        },
        TWO: {
          TITLE: 'Combo Shampoo',
          DESCRIPTION: 'Energizante, antiqueda com H-VIT©',
          VALUE: 'R$159,90',
          IMAGE: '/assets/img/products/product_image_two.png',
          LINK_BUTTON: '/produtos',
          BUTTON: 'conhecer o produto',
          LINK_CART: '/checkout/revisao',
        },
        THREE: {
          TITLE: 'Anti-aging',
          DESCRIPTION: 'Efeciência comprovada!',
          VALUE: 'R$129,90',
          IMAGE: '/assets/img/products/product_image_one.png',
          LINK_BUTTON: '/produtos',
          BUTTON: 'conhecer o produto',
          LINK_CART: '/checkout/revisao',
        },
        FOUR: {
          TEXT: 'Estamos quase lá, clique em avançar para confirmar a sua compra e receber a chave pix',
          SUBTEXT: 'Fique à vontade para falar com a gente!',
        },
        FIVE: {
          TEXT: 'Transfira o valor total da compra ',
          TEXT2: ' para a chave (CNPJ) ',
          TEXT3: ' 40.890.511/0001-40 ',
          TEXT4: ' e nos envie o comprovante por WhatsApp no número:',
          NUMBER: '(11) 9 4212-0797',
        },
      },
    },
    SELFIE: {
      TEXT_1: 'Por favor, tire uma ',
      TEXT_2: '(foto do rosto). Ela servirá apenas para validar sua identificação e idade (nunca será divulgada)',
    },
    PRESCRIPTION: {
      TITLE: 'avaliação médica',
      ATTENTION: 'Atenção: ',
      FOLLOW_CONSULTATION: 'Atenção para seguir sua consulta!',
      INVALID_EVALUATION: 'Não foi possível finalizar sua avaliação! Tente novamente.',
      FINISH: 'Declaro que todas as informações acima prestadas são verdadeiras, assumo a inteira responsabilidade pelas mesmas e atesto que o uso dos produtos vinculados ao meu nome serão exclusivos para meu uso.',
      NUMBERS: {
        ONE: '01',
        DESCRIPTION_ONE: 'Responda o questionário e a seguir tire fotos conforme as instruções',
        TWO: '02',
        DESCRIPTION_TWO: 'Um médico independente avaliará seu quadro e prescreverá o ',
        DESCRIPTION_TWO_SCROLL: 'melhor tratamento',
        THREE: '03',
        DESCRIPTION_THREE: 'Com a prescrição disponível, você poderá adquirir ',
        DESCRIPTION_THREE_SCROLL: 'seu kit exclusivo ',
        DESCRIPTION_THREE_CONTINUE: 'sem sair de casa',
      },
      BUTTONS: {
        START_PRESCRIPTION: 'Começar a avaliação',
        LOGIN: 'FAZER LOGIN',
        CREATE: 'CRIAR CONTA',
        UPLOAD: 'Carregar foto',
        OPEN_CAMERA: 'Abrir câmera',
        CANCEL: 'CANCELAR',
      },
      TALK: {
        TITLE: 'Médicos independentes preparados para prescrever o melhor tratamento',
        DESCRIPTION: 'Já pensou em um kit com os melhores produtos feito exclusivamente para você? Faça agora uma avaliação médica e saiba qual é o seu hair kit!',
      },
      LOGIN: {
        TITLE: 'BEM-VINDO(A) A THE MENS!',
        TITLE_TWO: 'Olá, ',
        TITLE_THREE: ', tudo bem?',
        BUTTON: 'ENTRAR',
        LINK: 'Não tem cadastro? Clique aqui',
      },
    },
    ABOUT: {
      TITLE: 'Muito mais que uma nova marca,',
      SUBTITLE: 'um novo jeito de se cuidar',
      SUBTITLE_BLOCK: 'Sabemos que os assuntos saúde, beleza e bem-estar parecem ser um\nuniverso completamente distante. Mas estamos aqui para mudar isso.',
      TITLE_SECOND: 'O medo e a vergonha de se cuidar não devem existir.\nTratamos de forma prática, transparente e sem julgamentos todos\nos tipos de questões masculinas.',
      SUBTITLE_SECOND: 'Pesquisamos e desenvolvemos produtos e tratamentos com fórmulas\nexclusivas e eficácia comprovada, para que você possa cuidar\nda forma como merece.',
      TITLE_THREE: 'Viemos para mostrar que os homens não estão sozinhos, e que juntos\niremos tratar do que realmente importa, você.',
      FINISH_NORMAL: 'Prazer, somos a',
      THEMENS: ' The Men\'s',
      INNERTEXT: {
        BANNER: {
          TEXT1: 'A 1ª marca de saúde personalizada do Brasil',
          TEXT2: 'Mais confiança, disposição e autoestima.',
        },
        INFO: {
          TEXT1: 'Somos a primeira marca de saúde personalizada do Brasil.',
          TEXT2: 'Nascemos para revolucionar a saúde e acabar com o estigma de que só devemos nos cuidar quando estamos doentes.',
          TEXT3: 'Com muita tecnologia e ciência, apoiamos o desenvolvimento de produtos e tratamentos exclusivos, seguindo as devidas legislações sanitárias, para que você possa se cuidar como merece e de forma supervisionada por um médico.',
          TEXT4: {
            one: 'Estamos aqui para sempre te',
            two: 'apoiar',
            three: ', para você ser quem deseja e saber quais são os caminhos que existem para chegar lá:',
            four: 'Se você optar por ficar sem cabelos e com barba, nós te apoiamos;',
            five: 'se você optar por ficar cabeludo e sem barba, nós te apoiamos;',
            six: 'se você optar por dormir melhor, nós te apoiamos;',
            seven: 'e se você optar por não dormir, nós te apoiamos também.'
          },
          TEXT5: 'Queremos que você saiba que agora existem opções e que sejamos uma fonte de conhecimento, inspiração e solução para você.',
          TEXT6: 'Topa entrar nessa revolução com a gente? #TMJ',
        },
        INVEST: 'Investidas por:',
        MEDIA: 'The Men’s na mídia',
      }
    },
    PROFILE_REQUEST: {
      ITEMS: {
        ONE: {
          NUMBER: 'PEDIDO #',
          TITLE_DATE: 'Data da compra:',
          DATE: '10/02/2021 às 22h47',
          TITLE_CPF: 'CPF do comprador:',
          CPF: '444.333.222-90',
          TITLE_TRACKING: 'Código de rastreio:',
          TITLE_REQUEST: 'Resumo da compra',
          TRACKING: 'Não consta',
          PRODUCT: '01 Finasterida*',
          VALUE: 'R$19,80',
          DISCONT: 'Desconto total:',
          VALUE_DISCONT: 'R$19,80',
          TOTAL: 'Total:',
          TITLE_DELIVERY: 'Endereço de entrega:',
          ADDRESS: 'Rua das Acassias, 49 - São Paulo, CEP: 04550-003',
          VALUE_TOTAL: 'R$19,80',
          REGISTER: 'alterar endereço',
          TITLE_PAYMENT_METHOD: 'Forma de pagamento:',
          ICON_CARD: '/assets/img/requests/ic_card.svg',
          NUMBER_CARD: '••••.••••.•••• - 3445',
          NUMBER_VALUE: '1x R$255,00',
          ACTIVE_CART: '',
          ACTIVE_CARD: '',
          ACTIVE_DOCTOR: '',
          ACTIVE_BLOCK: 'active',
          ACTIVE_TRUCK: 'active',
          ACTIVE_DELIVERY: 'active',
          TITLE_FIRST: 'ACOMPANHE O PEDIDO #',
          TITLE_PRESCRIPTION: 'PRESCRIÇÃO MÉDICA EM ANÁLISE',
          DESCRIPTION_PRESCRIPTION: 'Estamos quase lá! Um médico independente está analisando sua avaliação médica. Após a autorização, iniciaremos a montagem do seu hair kit!',
        },
        TWO: {
          NUMBER: 'PEDIDO #33567',
          TITLE_DATE: 'Data da compra:',
          DATE: '10/02/2021 às 22h47',
          TITLE_CPF: 'CPF do comprador:',
          CPF: '444.333.222-90',
          TITLE_TRACKING: 'Código de rastreio:',
          TITLE_REQUEST: 'Resumo da compra',
          TRACKING: 'Não consta',
          PRODUCT: '01 Finasterida*',
          VALUE: 'R$19,80',
          DISCONT: 'Desconto total:',
          VALUE_DISCONT: 'R$19,80',
          TOTAL: 'Total:',
          TITLE_DELIVERY: 'Endereço de entrega:',
          ADDRESS: 'Rua das Acassias, 49 - São Paulo, CEP: 04550-003',
          VALUE_TOTAL: 'R$19,80',
          REGISTER: 'alterar endereço',
          TITLE_PAYMENT_METHOD: 'Forma de pagamento:',
          ICON_CARD: '/assets/img/requests/ic_card.svg',
          NUMBER_CARD: '••••.••••.•••• - 3445',
          NUMBER_VALUE: '1x R$255,00',
          ACTIVE_CART: '',
          ACTIVE_CARD: '',
          ACTIVE_DOCTOR: '',
          ACTIVE_BLOCK: 'active',
          ACTIVE_TRUCK: 'active',
          ACTIVE_DELIVERY: 'active',
          TITLE_FIRST: 'ACOMPANHE O PEDIDO #45678',
          TITLE_PRESCRIPTION: 'PRESCRIÇÃO MÉDICA EM ANÁLISE',
          DESCRIPTION_PRESCRIPTION: 'Estamos quase lá! Um médico independente está analisando sua avaliação médica. Após a autorização, iniciaremos a montagem do seu hair kit!',
        },
      },
    },
    FINANCIAL: {
      ITEMS: {
        ONE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        TWO: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        THREE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        FOUR: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        FIVE: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        SIX: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
        SEVEN: {
          CODE: '#404.901',
          DATE: 'Realizada em: 08/10/2020',
          NAME: 'Nome: Danilo Bertasi',
          AGE: '31 anos',
          NUMBER_QUERY: '1º consulta',
          VALUE: 'R$25,00'
        },
      }
    },
    PANEL_HOME: {
      ITEMS_ONE: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      },
      ITEMS_TWO: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      },
      ITEMS_THREE: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      },
      ITEMS_FOUR: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      },
      ITEMS_FIVE: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      },
      ITEMS_SIX: {
        ONE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TWO: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        THREE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FOUR: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        FIVE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SIX: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        SEVEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        EIGHT: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        NINE: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
        TEN: {
          NAME: 'Danilo Bertasi',
          DATE: 'Solicitação 08/10',
          QUERY: '1º Consulta'
        },
      }
    },
    PANEL_MEDICAL_RECORD: {
      ITEMS: {
        ONE: {
          ASK: '1) Há quanto tempo você percebeu queda de cabelo?',
          ANSWER: 'Menos de um mês',
        },
        TWO: {
          ASK: '2) Sua queda de cabelo tem sido gradual?',
          ANSWER: 'Não, todos meus fios caíram em poucos dias',
        },
        THREE: {
          ASK: '3) Quais sintomas de queda de cabelo você percebeu?',
          ANSWER: 'Dor ou coceira ou vermelhidão no couro cabeludo',
        },
        FOUR: {
          ASK: '4) Você já usou algum medicamento para queda de cabelo (loção, creme, comprimido)?',
          ANSWER: 'Sim, com minoxidil e outro medicamento',
        },
        FIVE: {
          ASK: '5) Outras pessoas da sua família tem queda de cabelo?',
          ANSWER: 'Sim',
        },
        SIX: {
          ASK: '6) Você tem algum problema de saúde na pele ou no cabelo ?',
          ANSWER: 'Sim',
        },
        SEVEN: {
          ASK: '7) Você tem ou já teve algum problema de disfunção sexual?',
          ANSWER: 'Sim, diminuição da libido (desejo sexual)',
        },
        EIGHT: {
          ASK: '8) Você já foi diagnosticado com algum problema de saúde? ',
          ANSWER: 'Sim, osteoporose ou osteopenia (doença nos ossos)',
        },
        NINE: {
          ASK: '9) Você já foi submetido a alguma cirurgia ou internação?',
          ANSWER: 'Sim',
        },
        TEN: {
          ASK: '10) Você usa alguma medicação, suplemento ou vitamina?',
          ANSWER: 'Sim',
        },
        ELEVEN: {
          ASK: '11) Você tem alergia a Minoxidil?',
          ANSWER: 'Não sei, nunca utilizei',
        },
        TWELVE: {
          ASK: '12) Você tem alergia a Finasterida?',
          ANSWER: 'Não sei, nunca utilizei',
        },
        THIRTEEN: {
          ASK: '13) Você tem alguma outra alergia?',
          ANSWER: 'Sim',
        },
      },
      NO_PRESCRIPTION: {
        FEEDBACK: 'Avaliei seu quadro e não é necessário o uso de medicamentos. Sugiro realizar um tratamento preventivo com o shampoo e gummies que evitam a queda e favorecem o crescimento capilar. Qualquer dúvida, fico a disposição.',
        FAREWELL: 'Obg, abs',
      },
    },
    BACK_TO_MEDICAL_EVALUATION: 'Voltar para a avaliação',
    FINISH_MEDICAL_EVALUATION: 'Finalizar consulta',
    PRESCRIPTION_PANEL: {
      ITEMS_CAROUSEL: {
        ONE: {
          DATE: '23/09'
        },
        TWO: {
          DATE: '24/09'
        },
        THREE: {
          DATE: '25/09 '
        },
        FOUR: {
          DATE: '26/09'
        },
        FIVE: {
          DATE: '27/09'
        },
        SIX: {
          DATE: 'Atual '
        },
      },
      ITEMS: {
        ONE: {
          TEXT: 'Finasterida e Minoxidil',
        },
        TWO: {
          TEXT: 'Somente Minoxidil',
        },
        THREE: {
          TEXT: 'Somente Finasterida',
        },
      },
      FORM: {
        PRESCRIPTION: 'Prescrição',
        MESSAGE: 'Mensagem ao paciente',
        SOLUTTI: 'OTP Solutti',
        REGISTER_COUNCIL: 'CRM',
        REGISTER_NUMBER: 'Número de registro',
        REGISTER_UF: 'Estado do registro',
        BUTTON: 'Prescrever e enviar',
        CANCEL: 'Voltar',
        CHECKBOX_TITLE: 'Medicamentos prescritos',
        CHECKBOX1: 'Minoxidil 5% Trichofoam',
        CHECKBOX2: 'Finasterida',
        CHECKBOX3: 'Vita Gummy The Mens',
        CHECKBOX4: 'Shampoo The Mens',
        PRESCRIPTION_TITLE: 'Receituário Médico',
        PRESCRIPTION_TOPICAL_USE: 'Uso Tópico',
        PRESCRIPTION_ORAL_USE: 'Uso Oral',
        PRESCRIPTION_MINOXIDIL_TITLE: 'Minoxidil base a 5% em trichofoam ------------------------------------------------------- uso contínuo',
        PRESCRIPTION_MINOXIDIL_DESCRIPTION: 'Aplicar 1 ml (2 pumps) no couro cabeludo sobre a área calva e áreas adjacentes, 2 vezes ao dia, iniciando a aplicação no centro da área afetada. Não lavar os cabelos por pelo menos 4 horas após aplicação do produto.',
        PRESCRIPTION_SHAMPOO_TITLE: 'Shampoo The Mens -----------------------------------------------------------------uso contínuo',
        PRESCRIPTION_SHAMPOO_DESCRIPTION: 'Lavar bem os cabelos com o shampoo sempre antes de aplicar o Minoxidil.',
        PRESCRIPTION_FINASTERIDA_TITLE: 'Finasterida 1mg -------------------------------------------------------------------- uso contínuo',
        PRESCRIPTION_FINASTERIDA_DESCRIPTION: 'Tomar 1 cápsula 1 vez ao dia (todos os dias no mesmo horário). ',
        PRESCRIPTION_GUMMY_TITLE: 'Gummy The Mens ------------------------------------------------------------------ uso contínuo',
        PRESCRIPTION_GUMMY_DESCRIPTION: 'Tomar 1 Vita gummy 1 vez ao dia.',
        MESSAGE_FEEDBACK: 'Analisei suas respostas e as fotos enviadas.',
        MESSAGE_RECOMMENDATION: 'Sugiro fazermos um tratamento para reverter a queda capilar. Para que o tratamento seja eficaz, recomendo o uso diário dos produtos abaixo e o acompanhamento por 6 meses.',
        MESSAGE_SHAMPOO: '- Shampoo The Mens: Tomar banho ao menos 1x ao dia e utilizá-lo, para assim gerar um ambiente limpo e propício para o crescimento capilar.',
        MESSAGE_GUMMY: '- Gummy The Mens: Tomar apenas 1 ao dia para repor as vitaminas e assim fortalecer os cabelos e diminuir as quedas.',
        MESSAGE_MINOXIDIL: '- Minoxidil Base a 5% em trichofoam: Aplicar 1 ml (2 pumps) no couro cabeludo sobre a área calva e áreas adjacentes, após lavá-lo com o Shampoo The Mens, 2 vezes ao dia, iniciando a aplicação no centro da área afetada. Massageie bem o couro cabeludo e não somente os fios. Não lavar os cabelos por pelo menos 4 horas após aplicação do produto.',
        MESSAGE_FINASTERIDA: '- Finasterida: Tomar 1mg ao dia, sempre no mesmo horário. Ela bloqueará o DHT, hormônio responsável pela queda de cabelos.',
        MESSAGE_CONTACT: 'Caso tenha alguma dúvida agora ou durante o tratamento, basta entrar em contato comigo por aqui.',
        MESSAGE_FAREWELL: 'Obg, abs',
      }
    },
    SCHEDULE: {
      LINK1: 'Agendamento',
      LINK2: 'Forçar agendamento',
      TITLE1: 'Selecione 2 Datas e os horários Disponíveis',
      TITLE2: 'Selecione 1 Data e 1 horário Disponível',
      TIMETABLE: 'Digite o Horário',
      LABEL: {
        MESSAGE: 'Mensagem ao paciente'
      },
      FORM: {
        BUTTON: 'Enviar Mensagem',
        BUTTON2: 'Agendar e avisar o paciente'
      },
      MESSAGE: {
        INTRODUCTION: 'Eu avaliei a sua ficha médica e será necessário agendarmos uma consulta. Segue os horários disponíveis: ',
        INTRODUCTION_FORCED: 'Informo que sua consulta foi agendada para o seguinte dia e horário.',
        CHOOSE: 'Ao selecionar a data, a consulta estará marcada e próximo ao horário você receberá um e-mail meu com instruções.',
        FAREWELL: 'Obg, abs'
      },
    },
    FEATURE_SECTION: {
      CHOCOSONO: {
        IMAGE_URL: '/assets/img/home-carousel/chocosono_box.png',
      },
      ONE: {
        TITLE: 'QUEDA DE CABELO',
        DESCRIPTION: 'Um tratamento completo para queda de cabelo de forma prática, fácil e eficiente. Pode acreditar, agora um médico independente te acompanha por todo processo e seu kit chega em casa todo mês. Sua única preocupação será escolher qual penteado usar!',
        BUTTOM_TEXT: 'Quero conhecer',
        BUTTOM_URL: '/produtos/shampoo-energizante',
        IMAGE_URL: '/assets/img/feature-section/img_feature_section_one.jpg',
      },
      TWO: {
        TITLE: 'CHÁ DAILY BOOST',
        DESCRIPTION: 'Dê um up a qualquer hora do seu dia! Nosso chá foi desenvolvido a partir de ingredientes naturais que podem fornecer energia e ajudar a desinchar sem manchar os dentes. Agora você pode substituir seu cafezinho e ter ânimo sempre que precisar.',
        BUTTOM_TEXT: 'Quero conhecer',
        BUTTOM_URL: '/produtos/cha-do-dia-fora-de-estoque',
        IMAGE_URL: '/assets/img/feature-section/img_feature_section_two.jpg',
      },
      THREE: {
        TITLE: 'BARBA',
        DESCRIPTION: 'Você é o tipo de cara sem barba ou que raspa para esconder as falhas? Calma, estamos aqui para te ajudar! Sua barba vai crescer como nunca cresceu, mais bonita e volumosa.',
        BUTTOM_TEXT: 'Quero conhecer',
        BUTTOM_URL: '/produtos/tonico-para-barba',
        IMAGE_URL: '/assets/img/feature-section/img_feature_section_three.jpg',
      },
      FOUR: {
        TITLE: 'PELE',
        DESCRIPTION: 'Tá afim de deixar a pele mais firme, macia e hidratada? Com eficácia comprovada, nosso anti-aging previne e reduz as linhas de expressão. O seu “Eu” do futuro nos agradecerá!',
        BUTTOM_TEXT: 'Quero conhecer',
        BUTTOM_URL: '/produtos/anti-aging',
        IMAGE_URL: '/assets/img/feature-section/img_feature_section_four.jpg',
      },
    },
    FEATURE_SECTION_FULL: {
      ONE: {
        TITLE: 'VITA GUMMY',
        DESCRIPTION: 'Isso mesmo! Uma vita gummy zero açúcar e cheia de vitaminas que auxiliam no crescimento do seu cabelo e barba. Vai ser difícil comer só uma!',
        BUTTOM_TEXT: 'Quero conhecer',
        BUTTOM_URL: '/produtos/gummy',
        IMAGE_URL: '/assets/img/feature-section-full/img_feature_section_full_one.jpg',
      },
    },
    FEATURE_CAROUSEL: {
      ONE: {
        TITLE: 'Todos os nossos produtos',
        TITLE_BOLD: 'não são testados em animais.',
      },
      TWO: {
        TITLE: 'Nossos produtos são',
        TITLE_BOLD: 'dermatologicamente testados.',
      },
      THREE: {
        TITLE: 'Nossos produtos possuem',
        TITLE_BOLD: 'eficácia comprovada.',
      },
      FOUR: {
        TITLE: 'Nossas fórmulas são biocompatíveis: ',
        TITLE_BOLD: 'possuem afinidade e compatibilidade com nosso corpo.',
      },
      FIVE: {
        TITLE: 'Acreditamos 100%',
        TITLE_BOLD: 'em todos os nossos produtos e em sua qualidade.',
      },
    },
    PROMO_SECTION: {
      ONE: {
        TITLE: 'Desconto especial para você',
        PRODUCT: {
          IMAGE: 'https://themens-terms.s3-sa-east-1.amazonaws.com/img/product_promo_kit.jpg',
          TITLE: 'hair kit',
          DESCRIPTION: 'Uma combinação perfeita dos melhores produtos, com fórmulas únicas para resolver problemas de alopecia.',
          DESCRIPTION2: 'Receba todo mês em casa de forma segura e discreta.',
          BOLD_TEXT: 'Cada kit contém:',
          TEXT: '1 Shampoo energizante, 1 Gummy, 1 Minoxidil* e 1 Finasterida*',
          PRICE_FROM: 'R$ 278,60',
          PRICE_TO: 'R$ 219,99',
          OFF_AMOUNT: '20%',
        },
        BUTTON_TEXT: 'ASSINE AGORA',
        BUTTON_LINK: '/assinatura',
        BOTTOM_INFO: '* Venda sob prescrição médica',
      },
      TWO: {
        TITLE: 'Receba todo mês o seu hair kit exclusivo!',
        PRODUCT: {
          IMAGE: '/assets/img/promo-section/product_promo_two.png',
          TITLE: 'Assinante hair kit',
          DESCRIPTION: 'Todo mês você receberá em casa o hair kit* exclusivo montado por você ou por um dos médicos independentes, além de ter um acompanhamento durante todo o tratamento! Você se preocupa com seus novos penteados, e a gente com todo o resto!',
        },
        BUTTON_TEXT: 'QUERO SER UM ASSINANTE',
        BUTTON_LINK: '/assinatura',
        BOTTOM_INFO: '* Alguns produtos do hair kit, necessitam de prescrição médica para conluir a venda ',
      },
    },
    ROTATIVE_BANNER: {
      TITLE: ' The Men’s na mídia',
    },
    CARD_SECTION: {
      TITLE: 'CONSULTÓRIO MÉDICO',
      BUTTON: 'Atendimento 24hrs, sem agendamentos e sem salas de espera!',
    },
    BEST_SELLER: {
      TITLE: ' Os mais vendidos',
      SEAL: {
        FREE_FREIGHT: 'FRETE GRÁTIS',
        MEDICINE: 'Medicamento',
        NEW: 'Lançamento',
      },
      BUY: 'COMPRAR',
      VERIFIED: 'APROVADO PELA ANVISA',
      COMBO: {
        ONE: 'Combo Barbudo',
        TWO: 'Combo Vita Gummy 30unid',
        THREE: 'Kit Capilar 1 mês',
      },
      PRODUCTS: {
        ONE: {
          DESCRIPTION: 'Tônico para barba 30ml + Vita Gummy 30unid.',
          INSTALLMENTS: 'ou 3x sem juros de 38,30',
        },
        TWO: {
          TITLE: 'Chá Energizante - Daily Boost',
          DESCRIPTION: '60 sachês',
          INSTALLMENTS: 'ou 3x sem juros de 22,97',
        },
        THREE: {
          DESCRIPTION: '3 meses de tratamento capilar',
          INSTALLMENTS: 'ou 3x sem juros de 56,64',
        },
        FOUR: {
          TITLE: 'Minoxidil base 5% com TrichoFoam™',
          DESCRIPTION: '55ml',
          INSTALLMENTS: 'ou 3x sem juros de 21,63',
        },
        FIVE: {
          DESCRIPTION: 'Shampoo Antiqueda 200ml + Vita gummy',
          DESCRIPTION_2: '30unid.',
          INSTALLMENTS: 'ou 3x sem juros de 37,07',
        },
        SIX: {
          TITLE: 'Melatonina em Gummy 30 unid.',
          DESCRIPTION: 'Melatonina em Gummy 30 unid.',
          INSTALLMENTS: 'ou 3x sem juros de 26,64',
        },
      },

    },
    HAIR_KIT: {
      LINKS: {
        LINK_ONE: 'HOME ',
        LINK_TWO: '/ QUEDA DE CABELO',
      },
      TITLE: 'hair kit',
      DESCRIPTION: 'À procura de fios novos e mais fortes? Veio ao lugar certo! Pensando em uma solução eficaz, desenvolvemos 4 produtos que juntos irão auxiliar no crescimento do seu cabelo.',
      DESCRIPTION_TWO: 'Com o hair kit sua única precupação serão os novos penteados!',
      BUTTON: 'ASSINAR HAIR KIT',
      PERCENT: {
        TITLE: 'Mais produtos, mais descontos!',
        DESCRIPTION: 'Aproveite a nossa promoção progressiva, escolha os produtos e monte seu hair kit exclusivo',
        FIRST: {
          PRODUCT: '2 produtos diferentes',
          DISCOUNT: '7%\nOFF',
        },
        SECOND: {
          PRODUCT: '3 produtos diferentes',
          DISCOUNT: '10%\nOFF',
        },
        THIRD: {
          PRODUCT: '4 produtos diferentes',
          DISCOUNT: '15%\nOFF',
        }
      },
      HEADER: {
        CHECKBOX: 'Ganhar 20% de desconto e assinar mensalmente o hair kit'
      },
      BODY: {
        TITLE: 'Seu kit contém:',
        TOTAL_TITLE: 'Total',
        TOTAL_OLD: 'R$329,00',
        TOTAL_CURRENT: 'R$250,00',
        FREIGHT_TITLE: 'Consultar prazo e valor do frete',
        ATENTION: {
          TITLE: '* Alguns itens do seu kit necessitam de prescrição médica. Selecione uma das opções abaixo:',
          TEXT_ONE: 'Já possuo prescrição médica',
          TEXT_TWO: 'Não possuo prescrição médica e preciso de um acompanhamento médico',
          BUY: 'COMPRAR MEU HAIR KIT',
          DESCRIPTION: 'O  pagamento da avaliação médica não garante a prescrição dos produtos acima. Um médico independente avaliará seu quadro e prescreverá o tratamento adequedo, se necessário',
        },
        WAIT: {
          TITLE: 'O que esperar do hair kit?',
          SUBTITLE: 'Um tratamento capilar de verdade',
          TITLE_ONE: '1 a 2 meses de tratamento',
          TITLE_TWO: '3 a 6 meses de tratamento',
          TITLE_THREE: '6 meses em diante',
          DESCRIPTION_ONE: 'Nos primeiros meses, os resultados não serão perceptíveis para grande parte dos casos. Cabelos mais finos ou um aumento da queda capilar é normal e temporário; não se preocupe, os produtos estão agindo para gerar um ambiente perfeito para o crescimento.',
          DESCRIPTION_TWO: 'Nesta etapa você poderá observar o crescimento de fios novos e mais fortes e a regressão da queda capilar. A nossa dica é manter o foco no tratamento e realizar o acompanhamento através do upload das fotos na nossa plataforma.',
          DESCRIPTION_THREE: 'Neste momento, a queda dos fios já deve ter cessado ou até estagnado. Crescimento de novos fios devem ser notados, especialmente na coroa da cabeça.',
        },
        HOW_WORK: {
          TITLE: 'Como fazer para ter mais cabelos?',
          SUBTITLE: 'Nós temos a resposta',
          BLOCK: {
            TITLE_ONE: 'Avaliação online',
            DESCRIPTION_ONE: 'a avaliação online e em poucos cliques, você responderá um questionário médico sobre seu quadro clínico.',
            TITLE_TWO: 'Converse com o médico',
            DESCRIPTION_TWO: 'Um médico independente irá analisar o seu caso e prescreverá o melhor tratamento. Neste momento, e pelos próximos 3 meses, todas as dúvidas poderão ser sanadas em relação à queda de cabelo.',
            TITLE_THREE: 'Assine seu tratamento',
            DESCRIPTION_THREE: 'Após adquirir o kit, os produtos selecionados chegarão em sua casa, todos os meses e com todo suporte do nosso time.',
          }
        },
        EFFECT: {
          TITLE: 'Quais os tipos de alopécia e quando\ndevo procurar um tratamento?',
          BLOCK: {
            TITLE_ONE: 'Queda Capilar',
            TITLE_TWO: 'Alopécia androgenética',
            TITLE_THREE: 'Alopécia areata',
            TITLE_FOUR: 'Falhas ',
            DESCRIPTION_ONE: 'Caso você veja um afinamento dos fios ou queda de cabelo, fique ligado, pois a prevenção é o caminho para continuar com a cabeleira.',
            DESCRIPTION_TWO: 'Esta forma de alopécia está diretamente ligada à herança genética e ao envelhecimento. Nos homens, a perda capilar geralmente ocorre na parte superior e frontal da cabeça. Quando as “famosas” entradinhas começarem a aparecer é um sinal importante para procurar um tratamento.',
            DESCRIPTION_THREE: 'Para alguns homens, a perda de cabelo ocorre em áreas ovaladas, especialmente em cima da cabeça. Mas, não se preocupe, é possível tratar.',
            DESCRIPTION_FOUR: 'É normal com o passar do tempo que os folículos capilares se fechem. Se os fios de cabelo já estiverem finos e o couro cabeludo aparecer, não se desespere, há um tratamento adequado para você. Lembre-se: a prevenção é a melhor maneira de manter seus cabelos.',
          }
        },
        EASY: {
          TITLE: 'Mais fácil que transplante de cabelo e terapias a laser',
          SUBTITLE: 'Um tratamento eficaz e seguro',
          BLOCK: {
            DESCRIPTION_ONE: 'Um tratamento acessível e com\nacompanhamento médico. Aposte\nanos na sua autoestima, sem\nsustos com a fatura do seu cartão!',
            DESCRIPTION_TWO: 'Alguns itens necessitam de prescrição\ne médicos independentes estão prontos\npara te atender. Faça sua avaliação\nmédica e tenha um tratamento que\nrealmente te ajudará. Caso você já\ntenha uma prescrição, é só fazer\nupload no check-out.',
            DESCRIPTION_THREE: 'Ir até a farmácia? Nunca mais! Nós\nentregaremos seu hair kit personalizado\ntodos os meses, de forma discreta e\nsegura na porta da sua casa. Sua única\npreocupação serão os novos penteados.',
          }
        },
        ASK: {
          TITLE: 'Perguntas frequentes a respeito de queda de cabelo',
          ITEMS: {
            ONE: {
              TITLE: 'Quais são as causas típicas de perda de cabelo?',
              DESCRIPTION: 'Existem fatores genéticos e externos. Quando abordamos as causas genéticas, significa\n que há uma predisposição à calvície decorrente da linha familiar, e atualmente, existem\nmedicamentos para tratar e/ou retardar a queda capilar. No caso de fatores externos,\npodemos citar o stress, oleosidade, exposição ao sol e uma alimentação não balanceada.\nPara isso, uma mudança de hábitos, aliada a alguns dos nossos produtos, fará você mais\ncabeludo e feliz!',
            },
            TWO: {
              TITLE: 'Como eu sei qual tipo de perda de cabelo eu tenho?',
              DESCRIPTION: 'Existem diversos tipos de alopecia e as mais comuns são: Androgenética, que é\ncaracterizada pela perda progressiva de cabelos nas zonas da frente da cabeça, as\nfamosas “entradinhas”; a alopecia Areata, manifestada na perda de cabelo em zonas mais\narredondadas do cabelo; e Eflúvio Telógeno, condição geralmente desencadeada pelo\nstress ou trauma, na qual se verifica uma queda difusa dos cabelos. Para esse caso, há \ncura, já para os outros, é possível realizar o tratamento e continuar com os cabelos e bulbos\ncapilares existentes.',
            },
            THREE: {
              TITLE: 'Qual tratamento médico pode ajudar na queda de cabelo?',
              DESCRIPTION: 'Existem diversos tipos de tratamento para queda de cabelo. Assim, o ideal é realizar uma\navaliação médica, para que um médico verifique seu quadro e prescreva o que for mais adequado.\nInúmeras vezes, não vemos mais nossos cabelos, mas o bulbo capilar existe.\nAssim, com os medicamentos adequados, os cabelos voltam a crescer.',
            },
            FOUR: {
              TITLE: 'Há muitos efeitos colaterais quando se toma finasterida?',
              DESCRIPTION: 'Como todo medicamento, há possíveis reações adversas. Como para queda capilar\nutiliza-se uma dosagem baixa de Finasterida (1mg), os efeitos adversos relatados em\ninúmeros estudos foram baixos. O maior deles ocorreu em apenas 1.8% dos casos,\ncontra 1.3% que utilizou placebo, ou seja, apenas 0.5% (1 pessoa em 200) realmente apresentou\numa reação. Ainda, após interromper seu uso, ninguém apresentou mais os sintomas.',
            },
            FIVE: {
              TITLE: 'Existe cura para a queda de cabelo?',
              DESCRIPTION: 'Não há cura para a calvície, mas isso não significa que você precisará ficar careca. A queda\nde cabelo pode ser interrompida e até regredida, de modo que todos seus fios sejam\npreservados e outros nasçam enquanto você realiza o tratamento adequado.',
            },
            SIX: {
              TITLE: 'Quais os medicamentos indicados para queda de cabelo?',
              DESCRIPTION: 'Existem inúmeros medicamentos que podem ser indicados para queda de cabelo. Selecionamos e desenvolvemos os melhores a fim de propiciar o melhor tratamento.',
            },
            SEVEN: {
              TITLE: 'Tranças, coques masculinos ou outros penteados podem causar queda de cabelo?',
              DESCRIPTION: 'Não. Penteados ou uso de bonés não geram queda de cabelo. No entanto, dormir com o\ncabelo molhado, exposição excessiva ao sol, tinturas e outros produtos que podem danificar\na haste capilar, podem sim, levar a queda capilar.',
            },
            EIGHT: {
              TITLE: 'Por quanto tempo eu devo utilizar os produtos?',
              DESCRIPTION: 'Os produtos devem ser utilizados de acordo e pelo tempo recomendado pelo seu médico.\nO tratamento é contínuo, mas passível de exclusão de alguns produtos depois de um\ntempo de uso. Fale com um médico agora e descubra o tratamento mais adequado.',
            },
            NINE: {
              TITLE: 'A partir de qual idade posso utilizar os produtos?',
              DESCRIPTION: 'Os produtos devem ser utilizados de acordo com a prescrição médica. No entanto, a partir dos 18 anos é possível utilizá-los.',
            },
          }
        },
        PRESCRIPTION: {
          TITLE: 'Perguntas frequentes a respeito da prescrição médica',
          ITEMS: {
            ONE: {
              TITLE: 'Por que eu preciso de uma prescrição médica?',
              DESCRIPTION: 'Alguns produtos vendidos no hair Kit são medicamentos, e de acordo com os órgãos reguladores de saúde do Brasil, se faz por necessário a apresentação da prescrição médica para adquiri-lo. ',
            },
            TWO: {
              TITLE: 'Toda prescrição é valida?',
              DESCRIPTION: 'Para uma prescrição ser válida, ela deverá ser assinada digitalmente através de uma certificadora digital brasileira ou fisicamente, com carimbo médico e por um médico devidamente registrado e ativo nos orgãos reguladores. Ainda, toda prescrição é válida por até 6 meses de sua assinatura. ',
            },
            THREE: {
              TITLE: 'Não sei qual produto escolher, como agir?',
              DESCRIPTION: 'Não tente adivinhar qual o melhor produto, pois todos são bons rs... Faça agora uma avaliação médica e descubra qual o tratamento mais adequado. Um médico especialista prescreverá, se necessário, os devidos produtos para reverter a queda capilar. ',
            },
            FOUR: {
              TITLE: 'Como ocorre o envio da prescrição?',
              DESCRIPTION: 'Caso você tenha uma prescrição médica poderá realizar o upload no momento da compra; ou realizar uma avaliação médica, e se prescrita por um médico independente, ela automaticamente estará disponível para você. ',
            },
          },
        }
      },
      CART: {
        ITEMS: {
          ONE: {
            PRODUCT: '01 Shampoo',
            OLD_VALUE: 'R$69,90',
            CURRENT_VALUE: 'R$58,00',
            CLOSE: 'X',
          },
          TWO: {
            PRODUCT: '01 Gummy',
            OLD_VALUE: 'R$99,90',
            CURRENT_VALUE: 'R$84,90',
            CLOSE: 'X',
          },
          THREE: {
            PRODUCT: '01 Minoxidil*',
            OLD_VALUE: 'R$119,90',
            CURRENT_VALUE: 'R$99,50',
            CLOSE: 'X',
          },
          FOUR: {
            PRODUCT: '01 Finasterida*',
            OLD_VALUE: 'R$23,90',
            CURRENT_VALUE: 'R$19,80',
            CLOSE: 'X',
          },
        }
      },
      PRODUCTS: {
        ITEMS: {
          ONE: {
            IMAGE_PRODUCT: '/assets/img/products/product_shampoo.png',
            TITLE: 'Shampoo',
            VALUE: 'R$',
            I: 'i',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo. Composto por Biotina, Pantenol, Café, Jaborandi e H-Vit®️, ideais para tonificar os fios e controlar a oleosidade. Experimente e surpreenda-se!',
          },
          TWO: {
            IMAGE_PRODUCT: '/assets/img/products/product_gummy.png',
            TITLE: 'Gummy',
            VALUE: 'R$',
            I: 'i',
            DESCRIPTION: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tellus risus, tincidunt eu tempor ac, mollis congue nunc.',
          },
          THREE: {
            IMAGE_PRODUCT: '/assets/img/products/product_minoxidil.png',
            TITLE: 'Minoxidil*',
            VALUE: 'R$',
            I: 'i',
            DESCRIPTION: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tellus risus, tincidunt eu tempor ac, mollis congue nunc.',
          },
          FOUR: {
            IMAGE_PRODUCT: '/assets/img/products/product_finasterida.png',
            TITLE: 'Finasterida*',
            VALUE: 'R$',
            I: 'i',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo.',
          },
        }
      }
    },

    RECURRENCE: {
      EXPERIENCE: {
        TITLE: {
          TITLE_ONE: 'A experiência de ',
          TITLE_TWO: ' novos penteados',
        },
        TITLE_ONE: 'Monte seu hair kit e assine',
        DESCRIPTION_ONE: 'Temos 4 produtos para escolher o que é melhor para você.',
        TITLE_TWO: 'Responda o questionário médico',
        // DESCRIPTION_THREE: 'Alguns produtos do kit necessitam de prescrição médica. E médico independentes estão prontos para te ajudar. Caso você já tenha uma, é só fazer upload.',
        TITLE_THREE: 'Receba sua box personalizada!',
        DESCRIPTION_THREE: 'A melhor parte! É o momento de você se surpreender e se cuidar!',
      },
      BLUE: {
        TITLE: 'Por que assinar o hair kit?',
        DESCRIPTION: 'Nossa assinatura é mais do que receber um box com produtos, é a certeza de um tratamento discreto e eficiente. Todo mês você receberá a sua caixa personalizada por um preço justo e sempre estaremos aqui para eventuais dúvidas e ajuda. Alguns produtos do hair kit necessitam de prescrição e há médicos independentes prontos para avaliar o seu quadro e prescrever o melhor tratamento*. Você se preocupa com os novos penteados e nós com todo o resto, ok?',
      },
      DISCOUNT: 'ASSINE E GANHE 20% DE DESCONTO E FRETE GRÁTIS PARA GRANDE SÃO PAULO',
      LINKS: {
        LINK_ONE: 'HOME ',
        LINK_TWO: '/ HAIR KIT',
        LINK_THREE: '/ ASSINATURA',
      },
      TITLE: 'ASSINATURA HAIR KIT',
      DESCRIPTION: 'Monte o seu hair kit e receba os melhores produtos do mercado para tratamento de alopécia mensalmente com acompanhamento médico!',
      BUTTON: 'ASSINAR AGORA ',
      PERCENT: {
        TITLE: 'Descontos exclusivos para assinantes',
        DESCRIPTION: 'Selecione o desconto, monte seu hair kit agora e receba em poucos dias em casa o seu tratamento!',
        BLOCK_ONE: '20% OFF',
        BLOCK_TWO: 'Para o hair kit com 3 produtos entre o shampoo energizante, gummy, minoxidil e finasterida.',
      },
      HEADER: {
        CHECKBOX: 'Ganhar 20% de desconto e assinar mensalmente o hair kit'
      },
      BODY: {
        TITLE: 'Seu kit contém:',
        TOTAL_TITLE: 'Total',
        TOTAL_OLD: 'R$329,00',
        TOTAL_CURRENT: 'R$250,00',
        FREIGHT_TITLE: 'Consultar prazo e valor do frete',
        ATENTION: {
          TITLE: '* Alguns itens do seu kit necessitam de prescrição médica. Selecione uma das opções abaixo:',
          TEXT_ONE: 'Já possuo prescrição médica',
          TEXT_TWO: 'Não possuo prescrição médica e preciso de um acompanhamento médico',
          BUY: 'COMPRAR MEU HAIR KIT',
          DESCRIPTION: 'O  pagamento da avaliação médica não garante a prescrição dos produtos acima. Um médico independente avaliará seu quadro e prescreverá o tratamento adequedo, se necessário',
        },
        ASK: {
          TITLE: 'Perguntas frequentes a respeito da assinatura hair kit',
          ITEMS: {
            ONE: {
              TITLE: 'Posso cancelar a qualquer momento?',
              DESCRIPTION: 'Sim o cancelamento poderá ser realizado em até 5 dias antes do envio do kit para sua casa.',
            },
            TWO: {
              TITLE: 'A assinatura é mensal, trimestral ou anual?',
              DESCRIPTION: 'As assinaturas são mensais. Não se preocupe, não utilizamos o limite do seu cartão para garantir o pagamento. Confiamos em você e apenas cobraremos o valor mensal acordado.',
            },
            THREE: {
              TITLE: 'Quais são as formas de pagamento?',
              DESCRIPTION: 'O pagamento pode ser feito via cartão de crédito ou via transferência bancária. Para esses casos, entre em contato com nosso time.',
            },
            FOUR: {
              TITLE: 'Por quanto tempo é indicado usar o hair kit?',
              DESCRIPTION: 'Deve-se utilizar os produtos de acordo com a prescrição médica. No caso do hair kit, seu uso é contínuo e você perceberá os efeitos a partir do terceiro mês.',
            },
            FIVE: {
              TITLE: 'Por que preciso de prescrição médica para efetuar minha assinatura?',
              DESCRIPTION: 'Alguns produtos vendidos no hair kit são medicamentos, e de acordo com os órgãos reguladores de saúde do Brasil, é necessário a apresentação da prescrição médica para adquiri-lo.',
            },
            SIX: {
              TITLE: 'Como faço para enviar um hair kit de presente?',
              DESCRIPTION: 'Como todo tratamento é único e individual, é necessário a avaliação médica do paciente por um médico independente. Caso queira dar um tratamento de presente, entre em contato conosco e geramos um cupom mediante pagamento para que você presenteie quem você mais gosta.',
            },
            SEVEN: {
              TITLE: 'A partir de qual idade posso utilizar os produtos?',
              DESCRIPTION: 'A partir dos 18 anos já é possível utilizar os produtos. Contudo, é recomendado consultar-se com um médico independente a fim de que ele analise seu quadro e indique o melhor tratamento.',
            },
          }
        },
      },
      CART: {
        ITEMS: {
          ONE: {
            PRODUCT: '01 Shampoo',
            OLD_VALUE: 'R$69,90',
            CURRENT_VALUE: 'R$58,00',
            CLOSE: 'X',
          },
          TWO: {
            PRODUCT: '01 Gummy',
            OLD_VALUE: 'R$99,90',
            CURRENT_VALUE: 'R$84,90',
            CLOSE: 'X',
          },
          THREE: {
            PRODUCT: '01 Minoxidil*',
            OLD_VALUE: 'R$119,90',
            CURRENT_VALUE: 'R$99,50',
            CLOSE: 'X',
          },
          FOUR: {
            PRODUCT: '01 Finasterida*',
            OLD_VALUE: 'R$23,90',
            CURRENT_VALUE: 'R$19,80',
            CLOSE: 'X',
          },
        }
      },
      PRODUCTS: {
        ITEMS: {
          ONE: {
            IMAGE_PRODUCT: '/assets/img/hair-kit/product_shampoo.png',
            TITLE: 'Shampoo',
            VALUE: 'R$69,90',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo. Composto por Biotina, Pantenol, Café, Jaborandi e H-Vit®️, ideais para tonificar os fios e controlar a oleosidade. Experimente e surpreenda-se!',
            I: 'i',
          },
          TWO: {
            IMAGE_PRODUCT: '/assets/img/hair-kit/product_gummy.png',
            TITLE: 'Gummy',
            VALUE: 'R$99,90',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo. Composto por Biotina, Pantenol, Café, Jaborandi e H-Vit®️, ideais para tonificar os fios e controlar a oleosidade. Experimente e surpreenda-se!',
            I: 'i',
          },
          THREE: {
            IMAGE_PRODUCT: '/assets/img/hair-kit/product_minoxidil.png',
            TITLE: 'Minoxidil*',
            VALUE: 'R$99,90',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo. Composto por Biotina, Pantenol, Café, Jaborandi e H-Vit®️, ideais para tonificar os fios e controlar a oleosidade. Experimente e surpreenda-se!',
            I: 'i',
          },
          FOUR: {
            IMAGE_PRODUCT: '/assets/img/hair-kit/product_finasterida.png',
            TITLE: 'Finasterida*',
            VALUE: 'R$23,90',
            DESCRIPTION: 'Pensando em você, a The Men’s desenvolveu um shampoo com ativos que ajudam a revitalizar e fortalecer os fios fracos e quebradiços, além de limpar suavemente e refrescar o couro cabeludo. Composto por Biotina, Pantenol, Café, Jaborandi e H-Vit®️, ideais para tonificar os fios e controlar a oleosidade. Experimente e surpreenda-se!',
            I: 'i',
          },
        }
      }
    },
    BEGIN: {
      TITLE: 'Não sabe por onde começar? ',
      DESCRIPTION: 'Nós te ajudamos a montar o',
      TITLE_BOLD: ' hair kit perfeito!',
      BUTTON: 'INICIAR AVALIAÇÃO MÉDICA'
    },
    CLINIC_SUCCESS: {
      DESCRIPTION: {
        TITLE: 'Boa Danilo!',
        TEXT_ONE: 'Avaliação e pagamento ',
        TEXT_TWO: 'recebidos.',
        TEXT_THREE: 'Um médico independente analisará seu quadro e em até 24horas entrará em contato.',
        TEXT_FOUR: 'Fique atento ao e-mail e telefone cadastrado.',
        BUTTON: 'voltar para a the mens',
      },
    },
    CLINIC: {
      TITLE_BUY: 'Resumo da compra',
      TITLE_PAYMENT: 'DADOS DO CARTÃO',
      TITLE: 'Consultório Médico',
      PAYMENT: {
        DESCRIPTION: '*Para sua comodidade, enviaremos a Nota Fiscal eletrônica para o e-mail cadastrado no pedido. Assim, a the mens e seus clientes colaboram para diminuir impactos no meio ambiente.',
        BUTTON: 'pagar e enviar avaliação médica',
      },
      CART: {
        TITLE: 'Seu carrinho possui:',
        ITEM: '01 Avaliação Médica',
        VALUE: 'R$120,00',
        ITEM_TWO: 'Bonificação',
        VALUE_TWO: '-R$120,00',
        TOTAL: 'TOTAL:',
        VALUE_TOTAL: 'R$0,00',
      },
      LINKS: {
        ONE: 'Autorização ',
        TWO: 'Avaliação médica',
        THREE: 'Hora da foto',
        FOUR: 'Pagamento',
      },
      DESCRIPTION: {
        TITLE: 'Danilo',
        SUBTITLE: 'Precisamos do seu consentimento:',
        LIST_ZERO: 'Confirmo que todas as informações disponibilizadas por mim são verdadeiras;',
        LIST_ONE: 'Sou do sexo biológico masculino, tenho entre 18 e 65 anos;',
        LIST_TWO: 'Serei o único a consumir os produtos, se prescritos;',
        LIST_THREE: 'Confirmo que li e estou de acordo com os',
        link: ' termos e condições médicas.',
        BUTTON: 'Sim, eu concordo',
      },
      QUESTIONS: {
        PREVIOUS: 'Voltar',
        NEXT: 'Próximo',
        IDENTIFICATION: 'Por favor, tire uma foto segurando seu documento. Ela servirá apenas para validar sua identificação e idade (nunca será divulgada)',
        AGE: 'Verificação de idade',
      },
      ERROR: {
        FILL_IN_ALL_QUESTIONS: 'Por favor preencha as perguntas!',
        EMPTY_TEXT: 'O texto não pode ficar vazio!',
      },
      // exemplo 101 = passo 1 questão 01
      // exemplo 201 = passo 2 questão 01
      // exemplo 202 = passo 2 questão 02
      ITEMS: {
        101: {
          ONE: {
            KEY: '1',
            RESPONSE: '1',
            QUESTION: '101',
            SCORE: '0',
            ANSWER: 'Menos de um mês',
          },
          TWO: {
            KEY: '2',
            RESPONSE: '2',
            QUESTION: '101',
            SCORE: '0',
            ANSWER: 'Entre 1 e 6 meses',
          },
          THREE: {
            KEY: '3',
            RESPONSE: '3',
            QUESTION: '101',
            SCORE: '0',
            ANSWER: 'Mais de 6 meses',
          },
        },
        102: {
          ONE: {
            KEY: '4',
            RESPONSE: '1',
            QUESTION: '102',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '5',
            RESPONSE: '2',
            QUESTION: '102',
            SCORE: '0',
            ANSWER: 'Não, todos os meus fios caíram em poucos dias',
          },
        },
        201: {
          ONE: {
            KEY: '6',
            RESPONSE: '1',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Os fios estão mais finos',
          },
          TWO: {
            KEY: '7',
            RESPONSE: '2',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Queda de cabelo na região frontal',
          },
          THREE: {
            KEY: '8',
            RESPONSE: '3',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Caspa',
          },
          FOUR: {
            KEY: '9',
            RESPONSE: '4',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Queda de cabelo por todo couro cabeludo',
          },
          FIVE: {
            KEY: '10',
            RESPONSE: '5',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Queda de cabelo e pelos no corpo inteiro',
          },
          SIX: {
            KEY: '11',
            RESPONSE: '6',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Dor, coceira ou vermelhidão no couro cabeludo',
          },
          SEVEN: {
            KEY: '12',
            RESPONSE: '7',
            QUESTION: '201',
            SCORE: '0',
            ANSWER: 'Outro',
          },
        },
        202: {
          SEVEN: {
            KEY: '13',
            RESPONSE: '7',
            QUESTION: '202',
            SCORE: '0',
            ANSWER: 'Outro',
          },
        },
        301: {
          ONE: {
            KEY: '14',
            RESPONSE: '1',
            QUESTION: '301',
            SCORE: '0',
            ANSWER: 'Não',
          },
          TWO: {
            KEY: '15',
            RESPONSE: '2',
            QUESTION: '301',
            SCORE: '0',
            ANSWER: 'Sim, minoxidil',
          },
          THREE: {
            KEY: '16',
            RESPONSE: '3',
            QUESTION: '301',
            SCORE: '0',
            ANSWER: 'Sim, outro medicamento',
          },
          FOUR: {
            KEY: '17',
            RESPONSE: '4',
            QUESTION: '301',
            SCORE: '0',
            ANSWER: 'Sim, minoxidil e outro medicamento',
          },
        },
        302: {
          ONE: {
            KEY: '18',
            RESPONSE: '1',
            QUESTION: '302',
            SCORE: '0',
            ANSWER: 'Em solução',
          },
          TWO: {
            KEY: '19',
            RESPONSE: '2',
            QUESTION: '302',
            SCORE: '0',
            ANSWER: 'Em espuma - foam',
          },
          THREE: {
            KEY: '20',
            RESPONSE: '3',
            QUESTION: '302',
            SCORE: '0',
            ANSWER: 'Em comprimido',
          },
        },
        303: {
          ONE: {
            KEY: '21',
            RESPONSE: '1',
            QUESTION: '303',
            SCORE: '0',
            ANSWER: 'Menos de 1 mês',
          },
          TWO: {
            KEY: '22',
            RESPONSE: '2',
            QUESTION: '303',
            SCORE: '0',
            ANSWER: 'Entre 3-6 meses',
          },
          THREE: {
            KEY: '23',
            RESPONSE: '3',
            QUESTION: '303',
            SCORE: '0',
            ANSWER: 'Entre 6-12 meses',
          },
          FOUR: {
            KEY: '24',
            RESPONSE: '4',
            QUESTION: '303',
            SCORE: '0',
            ANSWER: 'Acima de 12 meses',
          },
        },
        304: {
          ONE: {
            KEY: '25',
            RESPONSE: '1',
            QUESTION: '304',
            SCORE: '0',
            ANSWER: 'Sim, muita melhora',
          },
          TWO: {
            KEY: '26',
            RESPONSE: '2',
            QUESTION: '304',
            SCORE: '0',
            ANSWER: 'Sim, uma leve melhora',
          },
          THREE: {
            KEY: '27',
            RESPONSE: '3',
            QUESTION: '304',
            SCORE: '0',
            ANSWER: 'Não senti nenhuma melhora',
          },
        },
        305: {
          ONE: {
            KEY: '28',
            RESPONSE: '1',
            QUESTION: '305',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '29',
            RESPONSE: '2',
            QUESTION: '305',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        306: {
          KEY: '30',
          RESPONSE: '2',
          QUESTION: '306',
          SCORE: '0',
          ANSWER: 'Não',
        },
        307: {
          KEY: '31',
          RESPONSE: '2',
          QUESTION: '307',
          SCORE: '0',
          ANSWER: 'Não',
        },
        401: {
          ONE: {
            KEY: '34',
            RESPONSE: '1',
            QUESTION: '401',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '35',
            RESPONSE: '2',
            QUESTION: '401',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        501: {
          ONE: {
            KEY: '36',
            RESPONSE: '1',
            QUESTION: '501',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '37',
            RESPONSE: '2',
            QUESTION: '501',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        502: {
          KEY: '38',
          RESPONSE: '2',
          QUESTION: '502',
          SCORE: '0',
          ANSWER: 'Não',
        },
        503: {
          KEY: '39',
          RESPONSE: '2',
          QUESTION: '503',
          SCORE: '0',
          ANSWER: 'Não',
        },
        // 14: {
        //   ONE: {
        //     KEY: '43',
        //     RESPONSE: '1',
        //     QUESTION: '14',
        //     SCORE: '0',
        //     ANSWER: 'Sim',
        //   },
        //   TWO: {
        //     KEY: '5',
        //     RESPONSE: '2',
        //     QUESTION: '14',
        //     SCORE: '0',
        //     ANSWER: 'Não',
        //   },
        // },
        601: {
          ONE: {
            KEY: '40',
            RESPONSE: '1',
            QUESTION: '601',
            SCORE: '0',
            ANSWER: 'Não',
          },
          TWO: {
            KEY: '41',
            RESPONSE: '2',
            QUESTION: '601',
            SCORE: '0',
            ANSWER: 'Sim, dificuldade para manter a ereção',
          },
          THREE: {
            KEY: '42',
            RESPONSE: '3',
            QUESTION: '601',
            SCORE: '0',
            ANSWER: 'Sim, ejaculação prematura',
          },
          FOUR: {
            KEY: '43',
            RESPONSE: '4',
            QUESTION: '601',
            SCORE: '0',
            ANSWER: 'Sim, diminuição da libido (desejo sexual)',
          },
          FIVE: {
            KEY: '44',
            RESPONSE: '5',
            QUESTION: '601',
            SCORE: '0',
            ANSWER: 'Outro ',
          },
        },
        602: {
          KEY: '45',
          RESPONSE: '2',
          QUESTION: '602',
          SCORE: '0',
          ANSWER: 'Não',
        },
        603: {
          KEY: '46',
          RESPONSE: '2',
          QUESTION: '603',
          SCORE: '0',
          ANSWER: 'Não',
        },
        604: {
          KEY: '47',
          RESPONSE: '2',
          QUESTION: '604',
          SCORE: '0',
          ANSWER: 'Não',
        },
        701: {
          ONE: {
            KEY: '48',
            RESPONSE: '1',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Não',
          },
          TWO: {
            KEY: '49',
            RESPONSE: '2',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, diabetes',
          },
          THREE: {
            KEY: '50',
            RESPONSE: '3',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, pressão alta ou baixa',
          },
          FOUR: {
            KEY: '51',
            RESPONSE: '4',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, câncer',
          },
          FIVE: {
            KEY: '52',
            RESPONSE: '5',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, doenças no fígado',
          },
          SIX: {
            KEY: '53',
            RESPONSE: '6',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, lúpus',
          },
          SEVEN: {
            KEY: '54',
            RESPONSE: '7',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, HIV',
          },
          EIGHT: {
            KEY: '55',
            RESPONSE: '8',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, doenças no sistema imune',
          },
          NINE: {
            KEY: '56',
            RESPONSE: '9',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, doenças nos rins',
          },
          TEN: {
            KEY: '57',
            RESPONSE: '10',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, doença na tireóide',
          },
          ELEVEN: {
            KEY: '58',
            RESPONSE: '11',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, depressão ou ansiedade',
          },
          TWELVE: {
            KEY: '59',
            RESPONSE: '12',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, esquizofrenia',
          },
          THIRTEEN: {
            KEY: '60',
            RESPONSE: '13',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Sim, osteoporose ou osteopenia (doença nos ossos)',
          },
          FOURTEEN: {
            KEY: '61',
            RESPONSE: '14',
            QUESTION: '701',
            SCORE: '0',
            ANSWER: 'Nenhuma das anteriores',
          },
        },
        702: {
          KEY: '62',
          RESPONSE: '2',
          QUESTION: '702',
          SCORE: '0',
          ANSWER: '',
        },
        801: {
          ONE: {
            KEY: '63',
            RESPONSE: '1',
            QUESTION: '801',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '64',
            RESPONSE: '2',
            QUESTION: '801',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        802: {
          KEY: '65',
          RESPONSE: '2',
          QUESTION: '802',
          SCORE: '0',
          ANSWER: '',
        },
        901: {
          ONE: {
            KEY: '66',
            RESPONSE: '1',
            QUESTION: '901',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '67',
            RESPONSE: '2',
            QUESTION: '901',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        902: {
          KEY: '68',
          RESPONSE: '2',
          QUESTION: '902',
          SCORE: '0',
          ANSWER: '',
        },
        1001: {
          ONE: {
            KEY: '69',
            RESPONSE: '1',
            QUESTION: '1001',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '70',
            RESPONSE: '2',
            QUESTION: '1001',
            SCORE: '0',
            ANSWER: 'Não',
          },
          THREE: {
            KEY: '71',
            RESPONSE: '3',
            QUESTION: '1001',
            SCORE: '0',
            ANSWER: 'Não sei, nunca usei',
          },
        },
        1002: {
          ONE: {
            KEY: '72',
            RESPONSE: '1',
            QUESTION: '1002',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '73',
            RESPONSE: '2',
            QUESTION: '1002',
            SCORE: '0',
            ANSWER: 'Não',
          },
          THREE: {
            KEY: '74',
            RESPONSE: '3',
            QUESTION: '1002',
            SCORE: '0',
            ANSWER: 'Não sei, nunca usei',
          },
        },
        1003: {
          ONE: {
            KEY: '75',
            RESPONSE: '1',
            QUESTION: '1003',
            SCORE: '0',
            ANSWER: 'Sim',
          },
          TWO: {
            KEY: '76',
            RESPONSE: '2',
            QUESTION: '1003',
            SCORE: '0',
            ANSWER: 'Não',
          },
        },
        1004: {
          KEY: '77',
          RESPONSE: '1',
          QUESTION: '1004',
          SCORE: '0',
          ANSWER: '',
        },
      },
      STEP_1: {
        TITLE_ONE: 'Há quanto tempo você percebeu queda de cabelo?',
        TITLE_TWO: 'Sua queda de cabelo tem sido gradual?',
      },
      STEP_2: {
        TITLE: 'Quais destes sintomas de perda de cabelo você percebeu?',
        PLACEHOLDER: 'Descreva os outros sintomas em até 200 caracteres',

      },
      STEP_3: {
        TITLE_ZERO: 'Você já usou algum medicamento para queda de cabelo (loção, creme, comprimido)?',
        TITLE_ONE: 'Qual tipo de minoxidil você utilizava?',
        TITLE_TWO: 'Por quanto tempo você utilizou o minoxidil?',
        TITLE_THREE: 'Você sentiu alguma melhora?',
        TITLE_FOUR: 'Você ainda está usando Minoxidil?',
        PLACEHOLDER: 'Digite sua resposta aqui',
        TITLE_FIVE: 'Qual medicamento você utilizou? Por quanto tempo?',
        TITLE_SIX: 'Você teve algum período de melhora? Ainda está em uso?',
        TITLE_SEVEN: 'Qual medicamento você utilizou? Por quanto tempo?',
        TITLE_EIGHT: 'Você teve algum período de melhora? Ainda está em uso?',
        TITLE_NINE: 'Você sentiu alguma melhora?',
        TITLE_TEN: 'Por quanto tempo você utilizou o minoxidil?',
        TITLE_ELEVEN: 'Você sentiu alguma melhora?',
        TITLE_TWELVE: 'Você ainda está usando Minoxidil?',
      },
      STEP_4: {
        TITLE: 'Outras pessoas da sua família tem queda de cabelo?',
        TITLE_TWO: 'Você fez algum tratamento? Se sim, com qual medicamento?',
        TITLE_THREE: 'Você ainda utiliza o remédio prescrito?',
        PLACEHOLDER: 'Digite sua resposta aqui',

      },
      STEP_5: {
        TITLE: 'Você tem algum problema de saúde na pele ou no cabelo?',
        TITLE_TWO: 'Você fez algum tratamento? Se sim, com qual medicamento?',
        TITLE_THREE: 'Você ainda utiliza o remédio prescrito?',
        PLACEHOLDER: 'Digite sua resposta aqui'
      },
      STEP_6: {
        TITLE: 'Você tem ou já teve algum problema de disfunção sexual?',
        TITLE_TWO: 'Você fez algum tratamento? Se sim, com qual medicamento?',
        PLACEHOLDER: 'Digite sua resposta aqui',
        TITLE_THREE: 'Você ainda usa o remédio prescrito?',
        TITLE_THREE2: 'Relate o problema por favor:',
        TITLE_FOUR: 'Qual remédio ou tratamento esta sendo utilizado ou já foi utilizado? Há quanto tempo isso ocorre/ocorreu?',
      },
      STEP_7: {
        TITLE: 'Você já foi diagnosticado com algum problema de saúde?',
        TITLE_TWO: 'Você faz acompanhamento com algum médico? Como vai o tratamento?',
        TITLE_TWO2: 'Você fez algum tratamento? Se sim, com qual medicamento?',
        PLACEHOLDER: 'Digite sua resposta aqui',

      },
      STEP_8: {
        TITLE: 'Você já foi submetido a alguma cirurgia ou internação?',
        TITLE_TWO: 'Por qual motivo você teve que ser submetido a uma cirurgia ou internação? Quando isso ocorreu?',
        PLACEHOLDER: 'Digite sua resposta aqui',

      },
      STEP_9: {
        TITLE: 'Você usa alguma medicação, suplemento ou vitamina?',
        TITLE_TWO: 'Por favor, especifique o que toma:',
        PLACEHOLDER: 'Digite sua resposta aqui',
      },
      STEP_10: {
        TITLE: 'Você tem alergia a Minoxidil?',
        TITLE_TWO: 'Você tem alergia a Finasterida?',
        TITLE_THREE: 'Você usa alguma medicação, suplemento ou vitamina?',
      },
      ITEMS_ELEVEN: {},
      PICTURE: {
        DESCRIPTION: 'Estamos quase lá, agora só faltam as suas fotos. Essa etapa é importante, pois o médico poderá analisar melhor o seu quadro.',
        DESCRIPTION_TWO: 'Fique tranquilo, suas fotos são sigilosas e não serão publicadas em nenhum local.',
        UPLOAD: 'CARREGAR FOTO',
        TITLE_ONE: 'PARTE DE CIMA DA CABEÇA',
        TITLE_TWO: 'PARTE DA FRENTE',
        TITLE_THREE: 'PARTE DE TRÁS',
        SEND: 'enviar fotos',
        CAM: 'Abrir Câmera',
        CAPTURE: 'TIRAR FOTO',
        UPDATE: 'ATUALIZE',
        UPDATE_BUTTON: 'ATUALIZAR',
      }
    },

    HOME_SLIDER: {
      ITEMS: {
        ONE: {
          BACKGROUND_IMAGE: '/assets/img/home-slider/the-mens-home-slider-one.jpg',
          TITLE_ONE: {
            TEXT: 'Mais que um tratamento,\n',
          },
          TITLE_TWO: {
            TEXT: 'uma experiência para\nnovos penteados.',
            BOLD: 't',
          },
          DESCRIPTION: 'Personalize seu kit e receba os melhores\nprodutos para tratamento de alopécia, todo\nmês com acompanhamento médico!',
          BUTTON: {
            LABEL: 'Escolha agora',
            LINK: '/',
          },
        },
        TWO: {
          BACKGROUND_IMAGE: '/assets/img/home-slider/the-mens-home-slider-two.png',
          TITLE_ONE: {
            TEXT: 'Você merece mais tempo.\n',
          },
          TITLE_TWO: {
            TEXT: 'Sua pele também!',
          },
          DESCRIPTION: 'Reduza as linhas de expressão e pareça mais jovem.\nCom 95% de aprovação no teste de qualidade, conheça\no nosso anti idade e aproveite a promoção de lançamento:',
          BUTTON: {
            LABEL: 'Comprar agora',
            LINK: '/',
          },
        },
      },
    },
    subscription: {
      textOne: 'ASSINE E GANHE',
      textTwo: '20% DE DESCONTO + FRETE GRÁTIS',
      textThree: 'PARA TODO BRASIL',
    },
  },
  AVALIATION: {
    TERMS: {
      TERMS_ONE: 'Ao continuar, você concorda incondicionalmente com os',
      TERMS_TWO: 'termos,',
      TERMS_THREE: 'com a',
      TERMS_FOUR: 'política de privacidade',
      TERMS_FIVE: 'e está totalmente ciente da',
      TERMS_SIX: 'consulta médica.'
    },
    CONTINUE: 'Continue',
    STEP: {
      STEP_ONE: 'Confirmo que todas as informações disponibilizadas por mim são verdadeiras',
      STEP_TWO: 'Tenho mais de 18 anos',
      STEP_THREE: 'Serei o unico a consumir os produtos, se prescrito',
      STEP_FOUR: 'Confirmo que li e estou de acordo com os termos e condições médicas'
    },
    SEXUAL: {
      QUESTIONS: {
        TYPE_ANSWER: 'Digite sua resposta',
        FIRST: {
          ANSWERS_OPTIONS: {
            FOURTH: 'Melhorar a ereção',
          }
        },
        THIRD: {
          ANSWERS_OPTIONS: {
            FIFITH: 'Nunca ocorreu',
          }
        }
      },
    },
  },
  NO: 'Não',
  YES: 'Sim',
  SEXUAL_HEALTH: 'Saúde sexual',
  CHECKOUT: {
    PAGINATED: {
      CHECKOUT: 'Checkout',
      DELIVERY: 'Entrega',
      PAYMENT: 'Pagamento'
    },
    STEP_ONE: {
      DATAS_TITLE: 'CONTATO',
      DELIVERY_SUBTITLE_MOBILE: 'Entrega e embalagem 100% discreta.',
      TITLE_DESKTOP: 'Dados Emissão nota fiscal',
      PARAGRAPH_CONTACT: 'Informamos que para sua privacidade, na Nota Fiscal, não fará menção ao nome do produto.',
      COUPOM_TITLE: 'CUPOM DE DESCONTO',
      VALIDATE_COUPOM: 'Validar',
      VALID_COUPOM: 'O cupom inserido é válido!',
      CERTIFICATE_TITLE: 'PROTEGIDO E CRIPTOGRAFADO',
      NEXT: 'Avançar',
      LABELS: {
        NAME: 'Nome',
        CPF: 'CPF',
        EMAIL: 'E-mail',
        PHONE: 'Celular',
        BORN: 'Data de Nasc.',
        LABORATORY: 'Laboratório credenciado',
        POSTAL_SERVICE: 'Parceria com os Correios',
      },
      TOTAL: 'Total:',
      PURCHASE_OVERVIEW: 'Resumo da compra',
      PURCHASE_SECURITY: 'Compra segura',
      PROMOTION_PRICE: 'Preço Promocional',
    },
    STEP_THREE: {
      BACK: 'Voltar',
      ADDRESS: 'ENDEREÇO DE ENTREGA',
      DATA_ADDRESS: 'Preencha os dados abaixo para definir o endereço de entrega:',
      FREIGHT: 'Frete: ',
      FREE: 'GRÁTIS',
      UNAVAILABLE: 'Indisponível',
      DAYS: 'dias úteis após despacho ',
      TYPES: {
        DEFAULT: 'Padrão',
        SEDEX: 'Sedex',
      },
      FREIGHT_FREE: 'Grátis',
      FREIGHT_TERMS: 'Prazo: ',
      INFO: 'Você receberá o código de rastreio por e-mail e pelo whatsapp tão logo o produto seja despachado',
      CHARACTER_WARNING: 'Insira no máximo 30 caracteres',
      DEFAULT_FREIGHT: 'Padrão',
      SEDEX_FREIGHT: 'Sedex',
    },
    STEP_FOUR: {
      PAYMENT: 'DADOS DE PAGAMENTO',
      RECURRENT_INFO: 'Para assinatura, utilize cartão de crédito como meio de pagamento.',
      RECURRENT_INFO_CARD: 'Para pedidos com valor zerado, utilize cartão de crédito como meio de pagamento.',
      RECURRENT_INFO_INSTALLMENT: 'Não é possível parcelar a assinatura.',
      BUY: 'Comprar',
      LABELS: {
        CARD_NUMBER: 'Número do cartão',
        CARD_NAME: 'Nome impresso no cartão',
        VALID_DATE: 'Validade',
        CVV: 'CVV',
        CPF: 'CPF do titular',
        NUMBER: 'Número de parcelas'
      },
    },
    STEP_FIVE: {
      SUCCESS: 'Pedido efetuado com sucesso',
      RECEIVED: 'Pedido recebido!',
      PAYMENT: 'Efetue o pagamento para concluir sua compra',
      COPY_BUTTON: 'Copiar código',
      RETURN: 'Voltar para o início',
      APPROVED: 'Seu pedido foi aprovado!',
      SEND_EMAIL: 'Em breve entraremos em contato através do e-mail cadastrado ou telefone.',
      ORDER: 'Para acompanhar seu pedido, clique em login e depois na aba',
      MY_ORDER: 'meus pedidos',
      BLOG_TITLE: 'Blog The',
      CARD_ONE_TITLE: 'Viagra natural existe?',
      CARD_TWO_TITLE: 'O que causa muito sono?',
      CARD_THREE_TITLE: 'O que causa e como tratar queda de cabelo?',
      ARTICLE_LINK: 'Leia esse artigo',
      SAFE_BUY: 'Você está fazendo uma compra segura',
      INFO_CHECKOUT: {
        TEXT_ONE: 'Nos preocupamos muito com a sua segurança no uso do produto adquirido e por isso é necessário enviar uma prescrição médica.',
        TEXT_TWO: 'Caso você não tenha,',
        TEXT_THREE: 'viabilizamos avaliações médicas',
        TEXT_FOUR: ', a fim de que um médico verifique se o uso do produto é indicado para o seu caso.',
        LINK: 'Clique aqui',
        TEXT_FIVE: 'e siga o passo a passo para a avaliação gratuita. Se cair no carrinho novamente, não é necessário fazer um novo pagamento, só fechar a aba.'
      }
    },
    FOOTER: {
      GUARANTEE: 'Garantia incondicional de 7 dias',
      ADDRESS: 'Alameda dos Tupiniquins 414, Planalto Paulista - São Paulo/SP - CNPJ: 40.890.511/0001-40',
    },
    PROMOTION_CARD: {
      TITLE: 'Promoção desbloqueada',
      BUTTON: 'Adicionar a 2ª unidade',
      CARD_MOBILE: 'Seu carrinho contém medicamento',
    },
    CHOCOSONO_CARD: {
      TAG: '59% OFF',
      TEXT: 'Adicionar agora no carrinho',
      TEXT_BOLD: '2 (duas) caixa de Chocosono por',
      VALUE: 'R$ 26,90',
      SIGN: 'Chocolate com melatonina',
    },
    VITA_GUMMY_CARD: {
      TAG: '59% OFF',
      TEXT: 'Quero adicionar agora no carrinho',
      TEXT_BOLD: '1 (uma) Vita Gummy por',
      VALUE: 'R$ 26,90',
      SIGN: '- Suplemento vitaminico para performance',
    },
    CREATINA_CARD: {
      TAG: '30% OFF',
      TEXT: 'Quero adicionar agora no carrinho',
      TEXT_BOLD: '1 (uma) Creatina por',
      VALUE: 'R$ 129,90',
      SIGN: '- Creatina 500g 100% Pura',
    },
    SHAMPOO_CARD: {
      TAG: '50% OFF',
      TEXT: 'Adicionar agora no carrinho',
      TEXT_BOLD: '1 (um) Shampoo Energizante por',
      VALUE: 'R$ 22,90',
      SIGN: '- Antiqueda, controla a oleosidade e caspas',
    },
    ANTI_AGING_CARD: {
      TAG: '60% OFF',
      TEXT: 'Adicionar agora no carrinho',
      TEXT_BOLD: '1 (um) Anti-aging 10% nano VIT C',
      VALUE: 'R$ 39,90',
      SIGN: '- Hidratante, reduz os sinais de cansaço e estresse',
    },
  },
};

export default ptBr;

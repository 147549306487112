import React from 'react';
import moment from 'moment';
import WhiteButton from '../WhiteButton/WhiteButton';
import CookiesExpirationMonth from '../../enum/cookies';

class CookiesMessage extends React.Component {

  constructor (props) {
    const { acceptedCookies } = localStorage;

    super(props);
    this.state = {
      acceptedCookies,
      showCookiesMessage: false,
    };
  }

  componentDidMount () {
    this.showCookiesMessage();
  }

  showCookiesMessage () {
    const { acceptedCookies } = this.state;
    const now = moment();
    const monthsDifference = now.diff(acceptedCookies, 'months');

    if (!acceptedCookies || monthsDifference >= CookiesExpirationMonth) {
      this.setState({ showCookiesMessage: true });
    }
  }

  toggleAcceptCookies () {
    this.setState({ showCookiesMessage: false });
    localStorage.setItem('acceptedCookies', moment().format());
  }

  render () {
    const { showCookiesMessage } = this.state;

    return (
      <div>
        {showCookiesMessage && (
          <section className="cookies-message">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="cookies-message__phrase">
                    <strong>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      The Men's e a privacidade de dados
                    </strong>
                    <p>
                      Ao continuar navegando em nosso site, você concorda com a nossa&nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf"
                      >
                        Política de privacidade
                      </a>
                      ,<br className="mobile-hidden" /> bem como, com os&nbsp;
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf"
                      >
                        Termos e condições
                      </a>.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="cookies-message__button">
                    <WhiteButton
                      onClick={() => this.toggleAcceptCookies()}
                      label="Eu aceito"
                      customBackground="white"
                      customColor="#025175"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default CookiesMessage;

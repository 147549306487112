import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../services/i18n';
import { Link } from 'react-router-dom';

import IconRight from '../../../assets/svg/ic_right.svg';
import IconCart from '../../../assets/svg/ic_cart.svg';
import ArrowBlog from '../../../assets/svg/arrow_blog.svg';
import ArticleOne from '../../../assets/jpg/article_one.jpg';
import ArticleTwo from '../../../assets/jpg/article_two.jpg';
import { Helmet } from 'react-helmet-async';
import AuthService from '../../../services/auth';

const Approved = ({
  me,
  details,
}) => {

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
    document.querySelector('body').scrollTo(0, 250);
  }, []);

  return (
    <div className="approved">
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <div className="approved__banner">
        <div>
          <div className="approved__banner__links">
            {Object.values(translate('COMPONENTS.APPROVED.ITEMS')).map((item, itemKey) => (
              <Link
                to={item.LINK}
                key={itemKey.toString()}
              >
                <p className="approved__banner__links__text">
                  {item.TEXT}
                </p>
              </Link>
            ))}
          </div>
          <h2 className="approved__banner__links__title">
            {translate('COMPONENTS.APPROVED.TITLE')}
          </h2>
        </div>
      </div>
      <div className="approved__request">
        <h3 className="approved__request__title">
          <img
            src={IconRight}
            alt=""
          />
          {translate('COMPONENTS.APPROVED.REQUEST.TITLE')}
          {details && details.code}
        </h3>
        <p className="approved__request__description">
          {translate('COMPONENTS.APPROVED.REQUEST.DESCRIPTION_ONE')}
          <b>{me && me.name}</b>
        </p>
        <p className="approved__request__description">
          {translate('COMPONENTS.APPROVED.REQUEST.DESCRIPTION_THREE')}
        </p>
        <p className="approved__request__description">
          {translate('COMPONENTS.APPROVED.REQUEST.DESCRIPTION_FOUR')}
          <b>{translate('COMPONENTS.APPROVED.REQUEST.DESCRIPTION_FIVE')}</b>
        </p>
        <p className="approved__request__description">
          {translate('COMPONENTS.APPROVED.REQUEST.DESCRIPTION_SIX')}
        </p>
      </div>
      <div className="approved__products">
        <h3 className="approved__products__title">
          {translate('COMPONENTS.APPROVED.PRODUCT.TITLE')}
        </h3>
        <div className="approved__products__aux">
          {Object.values(translate('COMPONENTS.APPROVED.ITEMS_OTHER')).map((item, itemKey) => (
            <div
              className="approved__products__aux__block"
              key={itemKey.toString()}
            >
              <h3 className="approved__products__aux__block__title">
                {item.TITLE}
              </h3>
              <p className="approved__products__aux__block__description">
                {item.DESCRIPTION}
              </p>
              <p className="approved__products__aux__block__value">
                {item.VALUE}
              </p>
              <img
                src={item.IMAGE}
                className="approved__products__aux__block__image"
                alt=""
              />
              <div className="approved__products__aux__block__aux">
                <Link
                  to={item.LINK_BUTTON}
                  className="approved__products__aux__block__aux__button"
                >
                  {item.BUTTON}
                </Link>
                <Link
                  to={item.LINK_CART}
                  className="approved__products__aux__block__aux__cart"
                >
                  <img
                    src={IconCart}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="approved__blog">
        <div className="approved__blog__aux">
          <Link
            className="approved__blog__aux__link"
            to=""
          >
            <div className="approved__blog__aux__link__block">
              <p className="approved__blog__aux__link__block__product">
                {translate('COMPONENTS.APPROVED.BLOG.PRODUCT_ONE')}
              </p>
              <img
                src={ArticleOne}
                alt=""
              />
              <h3 className="approved__blog__aux__link__block__title">
                {translate('COMPONENTS.APPROVED.BLOG.TITLE_ONE')}
              </h3>
              <p className="approved__blog__aux__link__block__article">
                {translate('COMPONENTS.APPROVED.BLOG.ARTICLE_ONE')}
              </p>
            </div>
          </Link>
          <span className="approved__blog__aux__border"></span>
          <Link
            className="approved__blog__aux__link"
            to=""
          >
            <div className="approved__blog__aux__link__block">
              <p className="approved__blog__aux__link__block__product">
                {translate('COMPONENTS.APPROVED.BLOG.PRODUCT_TWO')}
              </p>
              <img
                src={ArticleTwo}
                alt=""
              />
              <h3 className="approved__blog__aux__link__block__title">
                {translate('COMPONENTS.APPROVED.BLOG.TITLE_TWO')}
              </h3>
              <p className="approved__blog__aux__link__block__article">
                {translate('COMPONENTS.APPROVED.BLOG.ARTICLE_TWO')}
              </p>
            </div>
          </Link>
          <div className="approved__blog__aux">
            <Link
              to=""
              className="approved__blog__aux__link-two"
            >
              <img
                className="approved__blog__aux__link-two__arrow"
                src={ArrowBlog}
                alt=""
              />
              <p className="approved__blog__aux__link-two__text">
                {translate('COMPONENTS.APPROVED.BLOG.ARTICLE_TWO')}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  details: state.transaction.details,
});

export default connect(mapStateToProps, null)(Approved);

import React, { useState } from 'react';
import Slider from 'react-slick';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from '../../services/i18n';

import { ReactComponent as IconOne } from '../../assets/svg/feature-carousel/ic_feature_carousel_one.svg';
import { ReactComponent as IconTwo } from '../../assets/svg/feature-carousel/ic_feature_carousel_two.svg';
import { ReactComponent as IconThree } from '../../assets/svg/feature-carousel/ic_feature_carousel_three.svg';
import { ReactComponent as IconFour } from '../../assets/svg/feature-carousel/ic_feature_carousel_four.svg';
import { ReactComponent as IconFive } from '../../assets/svg/feature-carousel/ic_feature_carousel_five.svg';

import { ReactComponent as IconOneSmall } from '../../assets/svg/feature-carousel/small/ic_feature_carousel_one_small.svg';
import { ReactComponent as IconTwoSmall } from '../../assets/svg/feature-carousel/small/ic_feature_carousel_two_small.svg';
import { ReactComponent as IconThreeSmall } from '../../assets/svg/feature-carousel/small/ic_feature_carousel_three_small.svg';
import { ReactComponent as IconFourSmall } from '../../assets/svg/feature-carousel/small/ic_feature_carousel_four_small.svg';
import { ReactComponent as IconFiveSmall } from '../../assets/svg/feature-carousel/small/ic_feature_carousel_five_small.svg';

let sliderRef = React.createRef();

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
};

const getCurrentIcon = (slide) => {
  if (slide === 1) {
    return <IconOne fill="black" />;
  }

  if (slide === 2) {
    return <IconTwo fill="black" />;
  }

  if (slide === 3) {
    return <IconThree fill="black" />;
  }

  if (slide === 4) {
    return <IconFour
      fill="black"
      stroke="black"
    />;
  }

  if (slide === 5) {
    return <IconFive
      fill="black"
      stroke="black"
    />;
  }
};

const getIconForPagination = (slide, currentSlide) => {
  if (slide === 1) {
    return <IconOneSmall fill={currentSlide === 1 ? 'white' : 'black'} />;
  }

  if (slide === 2) {
    return <IconTwoSmall fill={currentSlide === 2 ? 'white' : 'black'} />;
  }

  if (slide === 3) {
    return <IconThreeSmall fill={currentSlide === 3 ? 'white' : 'black'} />;
  }

  if (slide === 4) {
    return <IconFourSmall
      fill={currentSlide === 4 ? 'white' : 'black'}
      stroke={currentSlide === 4 ? 'white' : 'black'}
    />;
  }

  if (slide === 5) {
    return <IconFiveSmall
      fill={currentSlide === 5 ? 'white' : 'black'}
      stroke={currentSlide === 5 ? 'white' : 'black'}
    />;
  }
};

const FeatureCarousel = () => {
  const [ currentSlide, setCurrentSlide ] = useState(1);

  return (
    <>
      <div className="carousel-icons">
        {getCurrentIcon(currentSlide)}
      </div>
      <div className="feature-carousel">
        <Slider
          {...settings}
          ref={slider => (sliderRef = slider)}
          beforeChange={(o, i) => setCurrentSlide(i + 1)}

        >
          {Object.values(translate('COMPONENTS.FEATURE_CAROUSEL')).map((item, itemIndex) => (
            <div
              className="feature-carousel__wrapper"
              key={itemIndex.toString()}
            >
              <Container>
                <Row>
                  <Col>
                    <div
                      className="feature-carousel__inner"
                    >
                      <div className="feature-carousel__inner__content">
                        <div className="feature-carousel__inner__content__title">
                          <p className="feature-carousel__inner__content__title__text">
                            {item.TITLE}&nbsp;
                            <strong>{item.TITLE_BOLD}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </Slider>

        <div
          className="feature-carousel__inner__indicator"
        >
          <ul className="feature-carousel__inner__indicator__menu">
            {Object.values(translate('COMPONENTS.FEATURE_CAROUSEL')).map((indicator, indicatorIndex) => (
              <li
                className="feature-carousel__inner__indicator__menu__item"
                key={indicatorIndex.toString()}
              >
                <button
                  className={`feature-carousel__inner__indicator__menu__item__button
                              ${(currentSlide - 1) === indicatorIndex ? 'feature-carousel__inner__indicator__menu__item__button--active' : ''}`}
                  onClick={() => sliderRef.slickGoTo(indicatorIndex)}
                >
                  <span className="feature-carousel__inner__indicator__menu__item__button__icon">
                    {getIconForPagination((indicatorIndex + 1), currentSlide)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default FeatureCarousel;

const facebookUserActivity = {
  pageView: 1,
  viewContent: 2,
  addToCart: 3,
  initCheckout: 4,
  paymentMethod: 5,
  finishCheckout: 6,
  initMedicalAppointment: 7,
  finishMedicalAppointment: 8,
  abandonmentCheckout: 9,
  register: 10,
  search: 11
};

export default facebookUserActivity;
import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { connect } from 'react-redux';
import * as TimerActions from '../../redux/actions/timer';
import * as UserActions from '../../redux/actions/facebook';
import facebookUserActivity from '../../enum/facebookUserActivity';

export function TimerProvider (props) {
  const {
    minutes,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  useEffect(() => {
    if (!isRunning && (props.timerRedux || props.timerRedux === 1)) {
      start();
    }

    if (isRunning && (!props.timerRedux || props.timerRedux === 0)) {
      reset();
      pause();
    }
  }, [ props.timerRedux ]);

  useEffect(() => {
    if (minutes !== 5) return;

    if (props.me) {
      props.abandonmentCart(props.cart);
    }

    UserActions.userActivity({ source: window.location.pathname }, facebookUserActivity.abandonmentCheckout);
  }, [ minutes ]);

  return (
    <>
    </>
  );
}

const mapStateToProps = (state) => ({
  timerRedux: state.timer,
  me: state.auth.me,
  cart: state.cart.products
});

const mapDispatchToProps = (dispatch) => ({
  startTimerRedux: () => dispatch(TimerActions.addTimer()),
  stopTimerRedux: () => dispatch(TimerActions.removeTimer()),
  abandonmentCart: (cart) => dispatch(UserActions.abandonmentCart(cart)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerProvider);
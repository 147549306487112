import React from 'react';

const AdvancedForm = ({
  children,
  onSubmit,
}) => {

  const formSubmit = (e) => {
    e.preventDefault();
    return onSubmit();
  };

  return (
    <div className="advanced-form">
      <form
        onSubmit={onSubmit && formSubmit}
      >
        {children}
      </form>
    </div>
  );
};

export default AdvancedForm;

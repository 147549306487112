import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { decreaseLoading, increaseLoading } from './loading';
import RecurrenceRequest from '../../api/recurrence';

export const ACTION_GET_RECURRENCE = 'recurrence:ACTION_GET_RECURRENCE';

export const getRecurrence = () => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const result = await RecurrenceRequest.getRecurrence();
    dispatch({
      type: ACTION_GET_RECURRENCE,
      payload: result,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

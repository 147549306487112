export const AttendanceType = {
  MELATONINA: 1,
  ED: 2,
  HAIR_LOSS: 3,
  EJ: 4,
};

export const AttendanceTypeByName = [
  {
    id: AttendanceType.MELATONINA,
    name: 'Saúde do sono',
  },
  {
    id: AttendanceType.ED,
    name: 'Saúde Sexual',
  },
  {
    id: AttendanceType.HAIR_LOSS,
    name: 'Saúde Capilar',
  },
  {
    id: AttendanceType.EJ,
    name: 'Saúde Sexual',
  },
];

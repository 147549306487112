import React from 'react';
import { Link } from 'react-router-dom';
/**
 *
 * @param {Object} props.banner
 */

const BannerCreatina = ({ banner }) => {
  const linkToCart = '/checkout/revisao?carrinho=29609206-a390-4b73-8213-8464c4c4ea4a';

  return (
    <div className="carousel-banner-creatina">
      <div className="carousel-banner-creatina__inner">

        <div className="carousel-banner-creatina__inner__content">
          <div className="carousel-banner-creatina__inner__content__container mobile-hidden">
            <h1 className="carousel-banner-creatina__inner__content__container__title">
              {banner?.titleFirstLine}<br />
              {banner?.titleSecondLine}
            </h1>
            <label className="carousel-banner-creatina__inner__content__container__subtitle">
              {banner?.subtitleFirstLine}<br />
              {banner?.subtitleSecondLine}
            </label>
            <Link to={linkToCart}>
              <button
                className="carousel-banner-creatina__button-creatina-desk mobile-hidden"
              >
                {banner?.buttonText}
              </button>
            </Link>
          </div>

          <div className="carousel-banner-creatina__inner__content__container mobile-visible">
            <h1 className="carousel-banner-creatina__inner__content__container__title">
              {banner?.titleFirstLine}<br />
              {banner?.titleSecondLine}
            </h1>
            <p className="carousel-banner-creatina__inner__content__container__subtitle">
              {banner?.subtitleFirstLine}<br />
              {banner?.subtitleSecondLine}
            </p>

            <Link to={linkToCart}>
              <button
                className="carousel-banner-creatina__button-creatina-desk mobile-hidden"
              >
                {banner?.buttonText}
              </button>
            </Link>
            <Link to={linkToCart}>
              <button
                className="carousel-banner-creatina__button-creatina-desk mobile-visible"
              >
                {banner?.buttonText}
              </button>
            </Link>
          </div>

        </div>

        <div className="carousel-banner-creatina__inner__creatina-image mobile-hidden">
          <img
            src={banner?.img}
            alt='creatina'
            className="carousel-banner-creatina__inner__creatina-image__image mobile-hidden"
          />
        </div>

        <div className="carousel-banner-creatina__inner__creatina-image mobile-visible">
          <img
            src={banner?.imgMobile}
            alt='creatina'
            className="carousel-banner-creatina__inner__creatina-image__image mobile-visible"
          />
        </div>
      </div>
    </div>
  );
};


export default BannerCreatina;
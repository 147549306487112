import React from 'react';

/**
 * @param {Object} props
 * @param {string} props.handleChangeForm
 * @param {Object} props.deliveryInfos
 */

import { translate } from '../../services/i18n';
import { cepMask } from '../../utils/masks';
import CheckoutInput from '../CheckoutInput/CheckoutInput';

const AdvancedFreight = ({ handleChangeForm, deliveryInfos }) => {
  const firstInputsGroup = [
    { name: 'street', value: deliveryInfos.street, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.ADDRESS') },
    { name: 'addressNumber', value: deliveryInfos.addressNumber, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.Nº') },
  ];

  const secondInputsGroup = [
    { name: 'neighborhood', value: deliveryInfos.neighborhood, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.NEIGHBORHOOD') },
    { name: 'city', value: deliveryInfos.city, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.CITY') },
  ];

  const thirdInputsGroup = [
    { name: 'uf', value: deliveryInfos.uf, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.UF') },
    { name: 'complement', value: deliveryInfos.complement, label: translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.COMPLEMENT') },
  ];

  const handleInputChange = (fieldName, value) => {
    handleChangeForm(fieldName, value);
  };

  return (
    <>
      <div className="checkout__content__form__item__label__input">
        <CheckoutInput
          value={cepMask(deliveryInfos.zipcode)}
          onChange={(e) => handleInputChange('zipcode', e.target.value)}
          label={translate('PAGES.HOME.PROFILE_REGISTER.FORM.LABEL.CEP')}
          type="tel"
        />
      </div>

      <div className='advanced-freight__content-first-line'>
        {firstInputsGroup.map((item) => (
          <div
            key={item.name}
            className={`advanced-freight__content-first-line__${item.name}`}
          >
            <CheckoutInput
              key={item.name}
              value={item.value}
              label={item.label}
              onChange={(e) => handleInputChange(item.name, e.target.value)}
            />
          </div>
        ))}
      </div>

      <div className='advanced-freight__content-second-line'>
        {secondInputsGroup.map((item) => (
          <div
            key={item.name}
            className={`advanced-freight__content-second-line__${item.name}`}
          >
            <CheckoutInput
              key={item.name}
              value={item.value}
              label={item.label}
              onChange={(e) => handleInputChange(item.name, e.target.value)}
            />
          </div>
        ))}
      </div>

      <div className='advanced-freight__content-third-line'>
        {thirdInputsGroup.map((item) => (
          <div
            key={item.name}
            className={`advanced-freight__content-third-line__${item.name}`}
          >
            <CheckoutInput
              key={item.name}
              value={item.value}
              label={item.label}
              onChange={(e) => handleInputChange(item.name, e.target.value)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default AdvancedFreight;
import { UtilsActions } from '../actions';

const initialState = {
  base64: ''
};

const utilsReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case UtilsActions.UTILS_SAVE_BASE64:
      state = {
        ...state,
        base64: action.payload,
      };
      break;
    default:
      return state;
  }

  return state;
};


export default utilsReducer;
import React, { useEffect, useRef, useState } from 'react';
import * as AuthActions from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';


const NewsletterModal = () => {

  const modalRef = useRef();
  const [ modalOpen, setModalOpen ] = useState(false);
  const history = useHistory();

  useEffect (async () => {
    if (window && window.RDStationForms) {
      await new window.RDStationForms('primeira-compra-10-650390558873132e592b', 'UA-184383024-3').createForm();
    }
  }, []);

  const useOutsideClick = async (ref, callback) => {

    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(async () => {
      await new Promise((r) => setTimeout(r, 1000));
      document.addEventListener('click', handleClick);

      return () => {
        document.addEventListener('click', handleClick);
      };
    });
  };

  useOutsideClick(modalRef, () => {
    setModalOpen(false);
  });

  return (
    <div>
      <div className={`newsletter-modal mobile-hidden newsletter-modal--${modalOpen ? 'visible' : 'hidden'}`}>
        <div ref={modalRef}
          role="main"
          id="primeira-compra-10-650390558873132e592b"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  sendNewsletter: (data, callback) => dispatch(AuthActions.newsLetter(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterModal);

import React from 'react';

const CustomButton = ({
  buttonClassName,
  label,
  onClick,
  textClassName
}) => {
  return (
    <button className={buttonClassName}
      onClick={onClick}>
      <span className={textClassName}>
        {label}
      </span>
    </button>
  );
};

export default CustomButton;
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';

import ChatMessageRequests from '../../api/chat-message';

export const ACTION_GET_ALL_CHAT_MESSAGE =
  'chatMessage:ACTION_GET_ALL_CHAT_MESSAGE';
export const ACTION_GET_CHAT_MESSAGE_BY_ID =
  'chatMessage:ACTION_GET_CHAT_MESSAGE_BY_ID';
export const ACTION_CREATE_CHAT_MESSAGE =
  'chatMessage:ACTION_CREATE_CHAT_MESSAGE';
export const ACTION_UPDATE_CHAT_MESSAGE =
  'chatMessage:ACTION_UPDATE_CHAT_MESSAGE';
export const ACTION_GET_MINE_CHAT_MESSAGE =
  'chatMessage:ACTION_GET_MINE_CHAT_MESSAGE';
export const ACTION_DELETE_CHAT_MESSAGE =
  'chatMessage:ACTION_DELETE_CHAT_MESSAGE';
export const ACTION_GET_CHAT_USERS = 'chatMessage:ACTION_GET_CHAT_USERS';

export const createChatMessage = (data) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.createChatMessage(data);
    dispatch({
      type: ACTION_CREATE_CHAT_MESSAGE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const updateChatMessage = (id, data) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.updateChatMessage(id, data);
    dispatch({
      type: ACTION_UPDATE_CHAT_MESSAGE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const getChatMessageById = (id, parameters) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.getChatMessageById(id, parameters);
    dispatch({
      type: ACTION_GET_CHAT_MESSAGE_BY_ID,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const getAllChatMessages = (parameters) => async (dispatch) => {
  dispatch(addLoading);

  try {
    parameters = {
      ...parameters,
      offset: 0,
      limit: 99999,
    };
    const result = await ChatMessageRequests.getAllChatMessages(parameters);
    dispatch({
      type: ACTION_GET_ALL_CHAT_MESSAGE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const getChatMessageMine = (parameters) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.getMineChatMessage(parameters);
    dispatch({
      type: ACTION_GET_MINE_CHAT_MESSAGE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const removeChatMessage = (id) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.removeChatMessage(id);
    dispatch({
      type: ACTION_DELETE_CHAT_MESSAGE,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (err && err.response && errors[err.response] !== undefined) {
      message.error(`${errors[err.response]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

export const getChatUsers = (medicalAppointmentId) => async (dispatch) => {
  dispatch(addLoading);

  try {
    const result = await ChatMessageRequests.getChatUsers({
      medicalAppointmentId,
    });
    dispatch({
      type: ACTION_GET_CHAT_USERS,
      payload: result,
    });
    return result;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(removeLoading);
  }
};

import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/i18n';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import { message } from 'antd';

import AdvancedButton from '../../../components/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';

import {
  MedicalAppointmentStatus,
  MedicalAppointmentType,
} from '../../../enum/medicalAppointment';

import * as MedicalAppointmentActions from '../../../redux/actions/medical-appointment';
import * as AuthActions from '../../../redux/actions/auth';
import * as ChatMessageActions from '../../../redux/actions/chat-message';
import * as PrescriptionActions from '../../../redux/actions/prescription';
import ReactHtmlParser from 'react-html-parser';
import { setDataLayer } from '../../../services/analytics';
import AuthService from '../../../services/auth';
import PrescriptionProductType, { prescriptionProductsById } from '../../../enum/prescriptionProductType';
import { ReactComponent as Icon } from '../../../assets/svg/whatsapp_ic.svg';
import RenderCondition from '../../../components/no-visual/renderCondition';
import { useHistory } from 'react-router-dom';
import { AttendanceTypeByName } from '../../../enum/attendanceType';

const ProfileChat = (props) => {
  const {
    mineMedicalAppointments,
    allChatMessages,
    me,
    createChatMessage,
    getPrescriptionByID,
    getAllChatMessages,
    getMineMedicalAppointments,
    updateMedicalAppointment,
    getChatUsers,
  } = props;

  const [ activeMessage, setActiveMessage ] = useState({});
  const [ enableMessage, setEnableMessage ] = useState(false);
  const [ newMessage, setNewMessage ] = useState(0);
  const [ notFinishedStatus, setNotFinishedStatus ] = useState(null);
  const [ notFinishedType, setNotFinishedType ] = useState(null);
  const [ confirmedScheduleDate, setConfirmedScheduleDate ] = useState(null);
  const [ doctorSendMessage, setDoctorSendMessage ] = useState(false);
  const [ disabledInput, setDisabledInput ] = useState(false);
  const [ timeOut, setTimeOut ] = useState(0);
  const [ doctorName, setDoctorName ] = useState(null);

  const history = useHistory();

  useEffect(() => {
    async function fetchData () {
      AuthService.isFromEcommerceDomain();
      loadChat();
      const chatData =
        allChatMessages &&
        allChatMessages.filter(
          (chat) =>
            chat.medicalAppointmentId === mineMedicalAppointments[0]?.id,
        );
      const lastMessage = chatData && chatData[chatData.length - 1];
      const firstMessage = mineMedicalAppointments[0];
      const isDisableInput = localStorage.getItem('disableInputMessage');

      if (lastMessage && lastMessage.sender !== me?.id) {
        setDoctorSendMessage(true);
        setDisabledInput(false);
      } else if (
        lastMessage &&
        lastMessage.sender === me?.id &&
        JSON.parse(isDisableInput)
      ) {
        setDoctorSendMessage(false);
        setDisabledInput(true);
      }

      setActiveMessage({
        ...firstMessage,
        selected: true,
      });

      if (
        mineMedicalAppointments.length > 0 &&
        mineMedicalAppointments[0]?.attendBy
      ) {
        const { doctor } = await getChatUsers(mineMedicalAppointments[0]?.id);
        setDoctorName(doctor.name);
      } else {
        setDoctorName('');
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimeOut((state) => {
        return state + 1;
      });

      if (mineMedicalAppointments && mineMedicalAppointments[0]?.id) {
        getAllChatMessages({
          medicalAppointmentId: mineMedicalAppointments[0].id,
        });
      }
    }, 300000);
  }, [ activeMessage, getAllChatMessages, timeOut ]);

  const loadChat = async () => {
    await getMineMedicalAppointments();

    if (mineMedicalAppointments && mineMedicalAppointments.length > 0) {
      await getAllChatMessages({
        medicalAppointmentId: mineMedicalAppointments[0]?.id,
      });
    } else {
      return message.error(I18n.t('PAGES.PROFILE.PROFILE_CHAT.MESSAGE.ERROR'));
    }
  };

  const toggleActiveMessage = async (message) => {
    setActiveMessage({
      ...message,
      selected: true,
    });
    setEnableMessage(false);
    await getAllChatMessages({
      medicalAppointmentId: message.id,
    });

    setTimeout(() => {
      const objDiv = document.getElementById('messages');

      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 0);
  };

  const hasISend = (message, me) => {
    return message?.sender === me?.id;
  };

  const addMessage = async (
    activeMessage,
    defaultMessage,
    medicalAppointmentStatus,
    medicalAppointmentType,
  ) => {
    await updateMedicalAppointment(activeMessage && activeMessage.id, {
      status:
        medicalAppointmentStatus || MedicalAppointmentStatus.UNVIEWED_MESSAGE,
      typeAttendance: medicalAppointmentType,
      videoSchedule:
        medicalAppointmentStatus !== MedicalAppointmentStatus.VIDEO_SCHEDULED
          ? activeMessage.videoSchedule
          : moment(
            `${confirmedScheduleDate.date} ${confirmedScheduleDate.hour}`,
            'DD/MM/YYYY HH:mm',
          ).format(),
    });

    await createChatMessage({
      medicalAppointmentId: activeMessage.id,
      message: defaultMessage ? defaultMessage : newMessage,
      sender: me && me.id,
      sentWhen: moment().format(),
    });

    await getAllChatMessages({
      medicalAppointmentId: activeMessage && activeMessage.id,
    });

    setNewMessage(0);
    setActiveMessage(activeMessage);
    setTimeout(() => {
      const objDiv = document.getElementById('messages');

      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 0);
  };

  const makeTreatment = async (prescriptionId) => {
    const response = await getPrescriptionByID(prescriptionId);

    if (response.prescribedProductsList) {
      return (window.location.href = `/checkout/revisao?prescritos=${response.prescribedProductsList}`);
    }

    switch (response && response?.prescribedProducts) {
      case PrescriptionProductType.MINOXIDIL:
        return history.push(`/checkout/revisao?prescritos=${prescriptionProductsById.MINOXIDIL}`);

      case PrescriptionProductType.FINASTERIDA:
        return history.push(`/checkout/revisao?prescritos=${prescriptionProductsById.FINASTERIDA}`);

      case PrescriptionProductType.SLEEP_GUMMY:
        return history.push(`/checkout/revisao?prescritos=${prescriptionProductsById.SLEEP_GUMMY}`);

      case PrescriptionProductType.ALL:
        return history.push(`/checkout/revisao?prescritos=${prescriptionProductsById.ALL}`);

      case PrescriptionProductType.ED:
        return history.push(`/checkout/revisao?prescritos=${prescriptionProductsById.ED}`);

      default:
        return history.push('/checkout/revisao');
    }
  };

  const parseAvailableDates = (message, item) => {
    const splitted = message.replace(/ /g, '').split('\n');
    const splittedWithSpaces = message.split('\n');

    const dates = [ splitted[5], splitted[6] ];

    const topLines = [
      splittedWithSpaces[1].trim(),
      splittedWithSpaces[2].trim(),
      splittedWithSpaces[3].trim(),
      splittedWithSpaces[4].trim(),
    ];

    const bottomLines = [
      '<br />',
      splittedWithSpaces[7].trim(),
      splittedWithSpaces[8].trim(),
      splittedWithSpaces[9].trim(),
    ];

    const finalDates = {
      dates: [],
      topLines,
      bottomLines,
    };

    dates.forEach((date) => {
      const anchors = date.split('<a>');
      anchors.splice(0, 1);
      const hours = anchors.map((anchor) =>
        anchor.replace(/<\/a>/g, '').replace(/<br\/>/g, ''),
      );
      const obj = {
        selectedDate: date.substr(0, 10),
        hours,
      };
      finalDates.dates.push(obj);
    });

    return (
      <div>
        {finalDates && allChatMessages && allChatMessages.length > 0 && (
          <div className="chat-panel__right__messages__single__inner__text__confirmation">
            {finalDates.topLines.map((line, i) => (
              <div
                key={i}
                className="chat-panel__right__messages__single__inner__text__confirmation__text"
              >
                {ReactHtmlParser(line)}
              </div>
            ))}
            <div className="chat-panel__right__messages__single__inner__text__confirmation__dates__wrapper">
              {finalDates.dates.map((date, i) => (
                <div key={i}
                  className="chat-panel__right__messages__single__inner__text__confirmation__dates">
                  <div className="chat-panel__right__messages__single__inner__text__confirmation__dates__selected-date">
                    {date.selectedDate}
                  </div>
                  {date.hours.map((hour, i) => (
                    <button
                      key={i}
                      className="chat-panel__right__messages__single__inner__text__confirmation__dates__hour"
                      onClick={() =>
                        setSelectedDateTime(date.selectedDate, hour)
                      }
                      style={{
                        backgroundColor:
                          allChatMessages &&
                          allChatMessages[allChatMessages.length - 1].id ===
                            item.id &&
                          confirmedScheduleDate?.date === date.selectedDate &&
                          confirmedScheduleDate?.hour === hour &&
                          '#336ACE',
                        color:
                          allChatMessages &&
                          allChatMessages[allChatMessages.length - 1].id ===
                            item.id &&
                          confirmedScheduleDate?.date === date.selectedDate &&
                          confirmedScheduleDate?.hour === hour &&
                          'white',
                        cursor:
                          allChatMessages &&
                          allChatMessages[allChatMessages.length - 1].id ===
                            item.id &&
                          'pointer',
                      }}
                    >
                      {hour}
                    </button>
                  ))}
                </div>
              ))}
            </div>
            {finalDates.bottomLines.map((line, i) => (
              <div key={i}
                className="chat-panel__right__messages__single__inner__text__confirmation__text">
                {ReactHtmlParser(line)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const setSelectedDateTime = (date, hour) => {
    setConfirmedScheduleDate({ date, hour });
  };

  return (
    <div className="chat-profile">
      <Container>
        <Row>
          <Col>
            <div className="chat-profile__title">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.TITLE')}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chat-profile__tabs">
              <RenderCondition condition={mineMedicalAppointments && mineMedicalAppointments.length > 0}>
                {mineMedicalAppointments.map((item) => {
                  return (
                    <div
                      className={`chat-profile__consult ${
                        item.id === activeMessage.id &&
                        activeMessage.selected &&
                        'chat-profile__consult--active'
                      }`}
                      key={item.id.toString()}
                      onClick={() => toggleActiveMessage(item)}
                    >
                      <div className="chat-profile__consult__text">
                        {item.seqAttendance}º consulta - {' '}
                        {AttendanceTypeByName[item?.quiz?.type - 1].name}
                      </div>
                    </div>
                  );
                })}
              </RenderCondition>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="chat-margin">
              {mineMedicalAppointments && mineMedicalAppointments.length > 0 ? (
                mineMedicalAppointments.map((item) => (
                  <div key={item.id}>
                    <RenderCondition
                      condition={
                        item?.id === activeMessage.id &&
                        activeMessage.selected
                      }
                    >
                      <div
                        key={item?.id}
                        className="chat-profile__item"
                      >
                        <div className="chat-profile__item__header">
                          <div className="chat-profile__item__header__doctor">
                            {allChatMessages?.length > 0
                              ? `Conversa com Dr. ${doctorName}`
                              : translate('PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.NO_DOCTOR')
                            }
                          </div>

                          <div className="chat-profile__item__header__right">
                            <RenderCondition condition={activeMessage.prescriptionId}>
                              <button
                                className="chat-profile__item__header__right__button"
                                onClick={() => makeTreatment(activeMessage.prescriptionId)}
                              >
                                {translate(
                                  'PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.BUTTON'
                                )}
                              </button>
                            </RenderCondition>
                          </div>
                        </div>

                        <hr className="chat-profile__item__header__underline" />

                        <Row>
                          <Col>
                            <div
                              className="chat-profile__item__messages"
                              id="messages"
                            >
                              {allChatMessages?.map((item) => (
                                <div
                                  key={`${item.id}`}
                                  className="chat-panel__wrapper"
                                >
                                  <div
                                    key={`${item.id}`}
                                    className="chat-panel__right__messages__single"
                                    style={{
                                      justifyContent: hasISend(item, me)
                                        ? 'flex-end'
                                        : 'flex-start',
                                    }}
                                  >
                                    <div
                                      className={`chat-panel__right__messages__single__inner ${
                                        hasISend(item, me)
                                          ? 'chat-panel__right__messages__single__inner--mine'
                                          : 'chat-panel__right__messages__single__inner--other'
                                      }`}
                                      style={{
                                        backgroundColor: hasISend(item, me)
                                          ? '#E5e5e5'
                                          : '#5596BE',
                                        color: hasISend(item, me)
                                          ? 'black'
                                          : 'white',
                                      }}
                                    >
                                      <div
                                        className="chat-panel__right__messages__single__inner__date"
                                        style={{
                                          textAlign: hasISend(item, me)
                                            ? 'left'
                                            : 'right',
                                        }}
                                      >
                                        {moment(item.sentWhen).format(
                                          'DD/MM/YYYY HH:mm',
                                        )}
                                      </div>
                                      <div className="chat-panel__right__messages__single__inner__text">
                                        {item.message &&
                                        item.message.includes('<a>') ? (
                                            parseAvailableDates(item.message, item)
                                          ) : (
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                              {ReactHtmlParser(item.message)}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>

                        <hr className="chat-profile__item__header__underline" />
                        <div className="chat-panel__right__answer-area">
                          {item.id === activeMessage.id &&
                        activeMessage?.status === 4 &&
                        !enableMessage ? (
                              <React.Fragment>
                                {activeMessage?.typeAttendance !==
                            MedicalAppointmentType.VIDEO ? (
                                    <React.Fragment>
                                      {doctorSendMessage && (
                                        <>
                                          <div className="chat-panel__right__answer-area__click">
                                            {translate(
                                              'PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.BEST_ANSWER',
                                            )}
                                          </div>
                                          <div className="chat-panel__right__answer-area__options">
                                            <div className="chat-panel__right__answer-area__options--first">
                                              <AdvancedButton
                                                className="chat-button__round"
                                                text={translate(
                                                  'PAGES.PROFILE.PROFILE_CHAT.CONFIRMATION',
                                                )}
                                                onClick={() => {
                                                  setDoctorSendMessage(false);
                                                  setDisabledInput(true);
                                                  localStorage.setItem(
                                                    'disableInputMessage',
                                                    true,
                                                  );
                                                  setEnableMessage(false);
                                                  setNewMessage(0);
                                                  setNotFinishedStatus(null);
                                                  setNotFinishedType(null);
                                                  addMessage(
                                                    activeMessage,
                                                    translate(
                                                      'PAGES.PROFILE.PROFILE_CHAT.CONFIRMATION',
                                                    ),
                                                    MedicalAppointmentStatus.DONE_EVALUATION,
                                                    MedicalAppointmentType.COMMON,
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="chat-panel__right__answer-area__options--second">
                                              <AdvancedButton
                                                className="chat-button__round"
                                                text={translate(
                                                  'PAGES.PROFILE.PROFILE_CHAT.QUESTION',
                                                )}
                                                onClick={() => {
                                                  addMessage(
                                                    activeMessage,
                                                    translate(
                                                      'PAGES.PROFILE.PROFILE_CHAT.QUESTION',
                                                    ),
                                                  );
                                                  localStorage.setItem(
                                                    'disableInputMessage',
                                                    false,
                                                  );
                                                  setDoctorSendMessage(false);
                                                  setDataLayer(
                                                    'medical_evaluation_question',
                                                  );
                                                  setNewMessage(0);
                                                  setEnableMessage(true);
                                                  setNotFinishedStatus(
                                                    MedicalAppointmentStatus.UNVIEWED_MESSAGE,
                                                  );
                                                  setNotFinishedType(
                                                    MedicalAppointmentType.COMMON,
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <>
                                      <div className="chat-panel__right__answer-area__click">
                                        {translate(
                                          'PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.BEST_ANSWER',
                                        )}
                                      </div>
                                      <div className="chat-panel__right__answer-area__options">
                                        <div className="chat-panel__right__answer-area__options--first">
                                          <AdvancedButton
                                            className="chat-button__round"
                                            text={translate(
                                              'PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.CONFIRMATION',
                                            )}
                                            onClick={() => {
                                              setEnableMessage(false);
                                              setNewMessage(0);
                                              setNotFinishedStatus(null);
                                              setNotFinishedType(null);
                                              addMessage(
                                                activeMessage,
                                              `
${translate('PAGES.PROFILE.PROFILE_CHAT.CONFIRMATION_SCHEDULE_GREETINGS')}

Gostaria de confirmar o horário: ${confirmedScheduleDate?.date} as ${
                                            confirmedScheduleDate?.hour
                                  }

${translate('PAGES.PROFILE.PROFILE_CHAT.CONFIRMATION_SCHEDULE_FAREWELL')}`,
                                              MedicalAppointmentStatus.VIDEO_SCHEDULED,
                                              MedicalAppointmentType.VIDEO,
                                              );
                                            }}
                                            disabled={!confirmedScheduleDate}
                                          />
                                        </div>
                                        <div className="chat-panel__right__answer-area__options--second">
                                          <AdvancedButton
                                            className="chat-button__round"
                                            text="Propor um novo horário"
                                            onClick={() => {
                                              setNewMessage(0);
                                              setEnableMessage(true);
                                              setNotFinishedStatus(
                                                MedicalAppointmentStatus.RESCHEDULING,
                                              );
                                              setNotFinishedType(
                                                MedicalAppointmentType.COMMON,
                                              );
                                            }}
                                            type="primary"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </React.Fragment>
                            ) : null}
                          <div className="chat-panel__right__answer-area__items">
                            {enableMessage ? (
                              <React.Fragment>
                                <div className="chat-panel__right__answer-area__items__single chat-panel__right__answer-area__items__single--input">
                                  <AdvancedInput
                                    placeholder="Digite uma mensagem"
                                    variant="standard"
                                    value={newMessage || ''}
                                    onChange={(e) =>
                                      setNewMessage(e.target.value)
                                    }
                                    disabled={disabledInput}
                                  />
                                </div>
                                <div className="chat-panel__right__answer-area__items__single">
                                  <AdvancedButton
                                    className="chat-button__round"
                                    text="Enviar"
                                    onClick={() => {
                                      addMessage(
                                        activeMessage,
                                        null,
                                        notFinishedStatus,
                                        notFinishedType,
                                      );
                                      setDoctorSendMessage(false);
                                    }}
                                    customColor="#3b5c7a"
                                    disabled={disabledInput || !newMessage}
                                  />
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {activeMessage?.status !== 4 && !enableMessage ? (
                                  <div>
                                  Aguarde o médico responder para que você possa
                                  mandar uma nova mensagem.
                                  </div>
                                ) : null}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                        <Col>
                          <div className="chat-profile__doubt">
                            <p className="chat-profile__doubt__text">
                              {translate(
                                'PAGES.HOME.PROFILE_REGISTER.FORM.SIGNATURE.DOUBT',
                              )}
                            </p>
                            <p>
                              <a
                                href="https://api.whatsapp.com/send?phone=5511942120797"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="chat-profile__doubt-button"
                              >
                                <Icon style={{ width: '15px', height: '15px' }} />
                                {translate(
                                  'PAGES.HOME.PROFILE_REGISTER.FORM.HELP.TALK',
                                )}
                              </a>
                            </p>
                          </div>
                        </Col>
                      </div>
                    </RenderCondition>
                  </div>
                ))
              ) : (
                <div className="chat-profile__no-chat">
                  {translate('PAGES.HOME.PROFILE_REGISTER.FORM.CHAT.NO_CHAT')}
                </div>
              )}
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mineMedicalAppointments: state.medicalAppointment.mineMedicalAppointments,
  loading: state.loading,
  me: state.auth.me,
  allChatMessages: state.chatMessage.allChatMessages,
  prescriptionByID: state.prescription.prescriptionByID,
});

const mapDispatchToProps = (dispatch) => ({
  getMineMedicalAppointments: (params) =>
    dispatch(MedicalAppointmentActions.getMedicalAppointmentMine(params)),
  getMe: () => dispatch(AuthActions.getMe()),
  getChatUsers: (params) => dispatch(ChatMessageActions.getChatUsers(params)),
  getAllChatMessages: (params) =>
    dispatch(ChatMessageActions.getAllChatMessages(params)),
  createChatMessage: (data) =>
    dispatch(ChatMessageActions.createChatMessage(data)),
  getPrescriptionByID: (id, params) =>
    dispatch(PrescriptionActions.getPrescriptionById(id, params)),
  updateMedicalAppointment: (id, data) =>
    dispatch(MedicalAppointmentActions.updateMedicalAppointment(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileChat);

import EvolutionRequests from '../../api/evolution';
import { I18n } from 'react-redux-i18n';
import { message } from 'antd';

import { decreaseLoading, increaseLoading } from './loading';

export const ACTION_CREATE_EVOLUTION = 'evolution:ACTION_CREATE_EVOLUTION';
export const ACTION_GET_EVOLUTION_BY_ID =
  'evolution:ACTION_GET_EVOLUTION_BY_ID';
export const ACTION_GET_MINE_EVOLUTIONS =
  'evolution:ACTION_GET_MINE_EVOLUTIONS';

export const createEvolution = (array) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    const response = await EvolutionRequests.create(array);
    return response;
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(errors.genericError);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getEvolutionById = (id) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    await EvolutionRequests.getById(id);
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getMine = (params) => async (dispatch) => {
  dispatch(increaseLoading());

  try {
    params = {
      ...params,
      limit: 20,
      isDesc: true,
    };
    const payload = await EvolutionRequests.getMine(params);
    dispatch({
      type: ACTION_GET_MINE_EVOLUTIONS,
      payload,
    });
  } catch (err) {
    const errors = I18n.t('ERRORS');

    if (err && err.message && errors[err.message] !== undefined) {
      message.error(`${errors[err.message]}`);
    } else if (
      err &&
      err.response &&
      err.response.response &&
      err.response.response.error &&
      errors[err.response.response.error] !== undefined
    ) {
      message.error(`${errors[err.response.response.error]}`);
    } else {
      message.error(I18n.t('ERRORS.defaultForm'));
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateByIdByClientEvolution =
  (idEvolution, body) => async (dispatch) => {
    dispatch(increaseLoading());

    try {
      await EvolutionRequests.updateByIdByClient(idEvolution, body);
    } catch (err) {
      const errors = I18n.t('ERRORS');

      if (err && err.message && errors[err.message] !== undefined) {
        message.error(`${errors[err.message]}`);
      } else if (
        err &&
        err.response &&
        err.response.response &&
        err.response.response.error &&
        errors[err.response.response.error] !== undefined
      ) {
        message.error(`${errors[err.response.response.error]}`);
      } else {
        message.error(I18n.t('ERRORS.defaultForm'));
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

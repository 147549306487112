import { TimerActions } from '../actions';

export default (
  state = 0,
  action,
) => {
  switch (action.type) {
    case TimerActions.ACTION_INCREASE_TIMER:
      return state = 1;
    case TimerActions.ACTION_DESCREASE_TIMER:
      return state = 0;
    default:
      return state;
  }
};

import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import UserRegister from '../../../assets/svg/ic_user_register.svg';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';
import AdvancedForm from '../../../components/AdvancedForm/AdvancedForm';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import VerticalBoxSlider from '../../../components/VerticalBoxSlider/VerticalBoxSlider';
import * as UserActions from '../../../redux/actions/user';
import * as UtilsActions from '../../../redux/actions/utils';
import { translate } from '../../../services/i18n';
import { cpfMask, dateOfBirthMask, phoneMask } from '../../../utils/masks';
import { removeSpecialChars, validaCpf, validateBirthDate, validateEmail } from '../../../utils/string';
import * as AuthActions from '../../../redux/actions/auth';
import { Helmet } from 'react-helmet-async';
import AuthService from '../../../services/auth';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import { useDebounce } from '../../../hook/useDebounce';

const headerCarouselItems = [
  {
    id: 1,
    title: '',
    description: '',
    imageUrl: '/assets/img/vertical-slider/img_vertical_header_one.png',
  },
];

const Register = (props) => {
  const { pathname } = useLocation();
  const { createUser, login, userActivity } = props;
  const { setDebounce, clearDebounce } = useDebounce();

  const [ form, setForm ] = useState({
    email: '',
    phone: '',
    password: '',
    documentForm: '',
    firstName: '',
    lastName: '',
    grantType: 'password',
    birthDate: null,
    profileType: 1,
    zipcode: '',
    neighborhood: '',
    street: '',
    city: '',
    uf: '',
    addressNumber: '',
    complement: '',
    confirmpassword: '',
    reference: '',
  });

  const {
    email,
    phone,
    documentForm,
    firstName,
    lastName,
    birthDate,
  } = form;

  const history = useHistory();

  useEffect(() => {
    AuthService.isFromEcommerceDomain();
  });

  const handleChangeForm = (name, value) => {

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });
  };

  const [ invalidCPF, setInvalidCPF ] = useState(false);
  const [ invalidPhone, setInvalidPhone ] = useState(false);
  const [ invalidEmail, setInvalidEmail ] = useState(false);

  const onFormSubmit = async () => {
    setInvalidCPF(false);
    setInvalidPhone(false);
    setInvalidEmail(false);

    if (!validaCpf(removeSpecialChars(documentForm))) {
      setInvalidCPF(true);
      return message.error('CPF inválido');
    }

    if (removeSpecialChars(phone).length < 10 || phone.includes('99999-9999')) {
      setInvalidPhone(true);
      return message.error('Telefone inválido');
    }

    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return message.error('Email inválido');
    }

    if (!moment(birthDate, 'DD/MM/YYYY').isValid() || !validateBirthDate(birthDate)) {
      return message.error('Digite uma data de nascimento válida');
    }

    const payload = {
      ...form,
      name: firstName.concat(' ', lastName),
      document: removeSpecialChars(documentForm),
      password: removeSpecialChars(documentForm),
      phone: removeSpecialChars(phone),
      birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    await createUser(payload,
      async () => {
        await login(payload, () => {
          userActivity({ source: pathname }, facebookUserActivity.register);
          clearDebounce();
          history.push('/');
        });
      });

    clearDebounce();
  };

  return (
    <Container>
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <div className="register">
        <Link to={translate('PAGES.HOME.HAIR_KIT.URL')}>
          <VerticalBoxSlider
            items={headerCarouselItems}
          />
        </Link>
        <Col
          className="register__image-container"
          lg={7}
        >
          <h1 className="register__image-container__title">
            {translate('PAGES.HOME.REGISTER.IMAGE_PAGE_TITLE')}
          </h1>
          <img
            className="register__image-container__image"
            src={'/assets/img/register/register.png'}
            alt={translate('PAGES.HOME.REGISTER.IMAGE_PAGE_TITLE')}
          />
        </Col>
        <Col
          className="register__form-container"
          lg={5}
        >
          <AdvancedForm onSubmit={() => setDebounce(() => onFormSubmit())}>
            <h2 className="register__title">
              {translate('PAGES.HOME.REGISTER.FORM.REGISTER')}
            </h2>
            <p className="register__title__secondary">
              {translate('PAGES.HOME.REGISTER.FORM.REGISTER_TWO')}
            </p>
            <h2 className="register__sub-title">
              {translate('PAGES.HOME.REGISTER.FORM.TITLE_ONE')}
              <img src={UserRegister}
                alt="user-register" />
            </h2>
            <div className="register__aux">
              <div className="register__aux__input">
                <AdvancedInput
                  id="firstName"
                  error={false}
                  name="firstName"
                  label={translate('PAGES.HOME.REGISTER.FORM.LABEL.FIRST_NAME')}
                  value={firstName}
                  onChange={(e) => handleChangeForm('firstName', e.target.value)}
                />
              </div>
              <div className="register__aux__input">
                <AdvancedInput
                  id="lastName"
                  error={false}
                  name="lastName"
                  label={translate('PAGES.HOME.REGISTER.FORM.LABEL.LAST_NAME')}
                  value={lastName}
                  onChange={(e) => handleChangeForm('lastName', e.target.value)}
                />
              </div>
            </div>
            <div className="register__aux">
              <div className="register__aux__input">
                <AdvancedInput
                  id="cpf-invalid"
                  error={invalidCPF}
                  name="documentForm"
                  label={translate('PAGES.HOME.REGISTER.FORM.LABEL.CPF')}
                  value={documentForm}
                  onChange={(e) => handleChangeForm('documentForm', cpfMask(e.target.value))}
                />
              </div>
              <div className="register__aux__input">
                <AdvancedInput
                  name="birthDate"
                  value={dateOfBirthMask(birthDate)}
                  mask="99/99/9999"
                  onChange={(e) => handleChangeForm('birthDate', dateOfBirthMask(e.target.value))}
                  label={translate('COMPONENTS.CART.FORM.LABELS.DATE')}
                />
              </div>
            </div>
            <div className="register__aux">
              <div className="register__aux__input-large">
                <AdvancedInput
                  error={invalidPhone}
                  name="phone"
                  label={translate('PAGES.HOME.REGISTER.FORM.LABEL.PHONE')}
                  value={phone}
                  onChange={(e) => handleChangeForm('phone', phoneMask(e.target.value))}
                />
              </div>
            </div>
            <div className="register__aux">
              <div className="register__aux__input-large">
                <AdvancedInput
                  error={invalidEmail}
                  name="email"
                  label={translate('PAGES.HOME.REGISTER.FORM.LABEL.EMAIL')}
                  value={email && email.toLowerCase()}
                  onChange={(e) => handleChangeForm('email', e.target.value)}
                />
              </div>
            </div>
            <div className="register__aux">
              <div className="register__aux__input-button">
                <button
                  type="submit"
                  className="register__aux__input-button__button"
                >
                  {translate('PAGES.HOME.REGISTER.FORM.BUTTON')}
                </button>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (userData, callback) => dispatch(AuthActions.authenticate(userData, callback)),
  createUser: (userData, callback) => dispatch(UserActions.create(userData, callback)),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
  userActivity: (params, userActivity) => dispatch(FacebookActions.userActivity(params, userActivity)),
});

export default connect(null, mapDispatchToProps)(Register);

export const ACTION_VISIBLE_MODAL = 'loginModal:ACTION_VISIBLE_MODAL';
export const ACTION_INVISIBLE_MODAL = 'loginModal:ACTION_INVISIBLE_MODAL';

export const visibleLogin = () => ({
  type: ACTION_VISIBLE_MODAL,
});

export const invivisibleLogin = () => ({
  type: ACTION_INVISIBLE_MODAL,
});

export const addLogin = () => (dispatch) => dispatch(visibleLogin());

export const removeLogin = () => (dispatch) => dispatch(invivisibleLogin());
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../services/i18n';
import { useLocation } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';
import WhiteButton from '../../../components/WhiteButton/WhiteButton';
import { Helmet } from 'react-helmet-async';
import { setDataLayer } from '../../../services/analytics';
import AuthService from '../../../services/auth';

const Clinic = ({ me }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
    AuthService.isFromPrescriptionDomain();
    setDataLayer('medical_evaluation_end');
  }, [ pathname ]);
  return (
    <div className="clinic-success">
      <Helmet>
        <style type="text/css">
          {`
            #blip-chat-container #blip-chat-open-iframe {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <div className="clinic-success__header">
        <h1 className="clinic-success__header__title">
          {translate('COMPONENTS.CLINIC.TITLE')}
        </h1>
      </div>

      <div className="clinic-success__content">
        <Container>
          <Row>
            <Col lg={6} />
            <Col lg={6}>
              <div className="clinic-success__content__right">
                <div>
                  <div className="clinic-success__content__right__title">
                    Avaliação e pagamento&nbsp;
                    <strong className="clinic-success__content__right__title__strong">recebidos.</strong>
                  </div>
                  <div className="clinic-success__content__right__phrase">
                    {'Um médico independente analisará seu quadro\ne em até 24 horas entrará em contato.\n\nFique atento ao e-mail cadastrado.'}
                  </div>
                  <div className="clinic-success__content__right__button">
                    <WhiteButton
                      href="/"
                      label="VOLTAR PARA A THE MEN'S"
                      dark
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="clinic-success__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
});

export default connect(mapStateToProps, null)(Clinic);

import React from 'react';
import { Helmet } from 'react-helmet-async';

import PageFooter from '../PageFooter/PageFooter';
import { translate } from '../../services/i18n';
import PageHeader from '../PageHeader/PageHeader';
import LoginModal from '../LoginModal/LoginModal';

const PageContent = (props) => {
  return (
    <div className="page-content">
      <LoginModal />
      <Helmet>
        <title>{`${props.pageTitle} - ${translate('APPLICATION_NAME')}`}</title>
      </Helmet>
      {!props.removeHeader && (
        <PageHeader />
      )}
      {props.children}
      {!props.removeFooter && (
        <PageFooter removeEmailFooter={props.removeEmailFooter}/>
      )}
    </div>
  );
};

export default PageContent;

import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Popover } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';

import MobileHeaderMenu from '../MobileHeaderMenu/MobileHeaderMenu';
import { takeOutBlankSpace } from '../../utils/spaceRegex';
import { ReactComponent as TheMensLogo } from '../../assets/svg/TheMens-logo.svg';
import { ReactComponent as DoctorRequested } from '../../assets/svg/new_menu/ic_doctor_requested.svg';
import { ReactComponent as CartIcon } from '../../assets/svg/new_menu/ic_header_bag.svg';

import { connect } from 'react-redux';
import { getProductLatestImage, removeSpecialChars, someProductHasPrescription } from '../../utils/string';
import AmountPicker from '../AmountPicker/AmountPicker';
import { cpfMask, toCurrencyLocale } from '../../utils/masks';
import { translate } from '../../services/i18n';
import WhiteButton from '../WhiteButton/WhiteButton';
import * as ActionsCart from '../../redux/actions/cart';
import * as ActionProducts from '../../redux/actions/products';
import * as AuthActions from '../../redux/actions/auth';
import AdvancedForm from '../AdvancedForm/AdvancedForm';
import { CartActions, ProductModalActions } from '../../redux/actions';
import { CartSymbol } from '../../enum/cartSymbol';
import PaymentMethod from '../../enum/paymentMethod';
import {
  ArrowRightOutlined,
  CaretDownOutlined,
  CloseOutlined, MenuOutlined
} from '@ant-design/icons';
import { LoginModalActions } from '../../../app/redux/actions';
import { I18n } from 'react-redux-i18n';
import { getProductById } from '../../services/product';

const PageHeader = ({
  productsInCart,
  deleteProductInCartByID,
  authenticated,
  login,
  products,
  addOrRemoveProduct,
  values,
  freight,
  removeProductFromCart,
  paymentData,
  addLogin,
  createDefaultCart,
  me,
  setInvisibleModal,
  setVisibleModal,
  isVisible
}) => {

  const [ productQuantity, setProductQuantity ] = useState(0);
  const [ dropDownVisible, setDropDownVisible ] = useState(false);
  const [ healthMenuVisible, setHealthMenuVisible ] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [ visibleCart, setVisibleCart ] = useState(false);
  const [ visibleLogin, setVisibleLogin ] = useState(false);
  const [ form, setForm ] = useState({ email: '', password: '' });
  const [ scrollTop, setScrollTop ] = useState(0);
  const [ fixedHeader, setFixedHeader ] = useState(false);
  const { email, password } = form;
  const [ headerWidth, setHeaderWidth ] = useState(0);
  const [ dropdownVisible, setDropdownVisible ] = useState(false);

  const pathnamesWithoutModalCart = [ '/checkout/dados-de-entrega', '/perfil/registro', '/checkout/pagamento' ];

  useEffect(() => {
    if (products) {
      setProductQuantity(products.reduce((a, b) => a + b.quantity, 0));
    } else {
      setProductQuantity(0);
    }

  }, [ products ]);

  useEffect(() => {
    if (!pathnamesWithoutModalCart.includes(pathname)) {
      setVisibleCart(true);
    } else {
      setVisibleCart(false);
    }

  }, [ productQuantity ]);

  useEffect(() => {
    setTimeout(() => {
      setInvisibleModal(false);
      setVisibleCart(false);
    }, 2000);

    document.querySelector('body').addEventListener('scroll', () => {
      if (visibleCart || isVisible) {
        setVisibleCart(false);
      }

      if (visibleLogin) {
        setVisibleLogin(false);
      }
    });

    document.querySelector('body').addEventListener('mousemove', () => {
      setInvisibleModal(false);
    });

  }, [ isVisible ]);

  useEffect(() => {
    setDropDownVisible(false);
    setHealthMenuVisible(false);
    setVisibleCart(false);

    document.querySelector('body').scrollTo(0, 0);
  }, [ pathname ]);

  useEffect(() => {
    const qs = QueryString.parse(window.location.search);

    if (qs && qs.carrinho) {
      const payload = qs.carrinho.split(',').map((o) => ({
        ...getProductById(o),
        quantity: 1,
      }));
      createDefaultCart(payload);
    }
  }, [ window.location.search ]);

  useEffect(() => {
    const qs = QueryString.parse(window.location.search);

    if (qs && qs.validatecard) {
      const payload = qs.validatecard.split(',').map((o) => ({
        ...getProductById(o),
        quantity: 1,
      }));
      createDefaultCart(payload);
    }
  }, [ window.location.search ]);

  const handleVisibleChange = visible => {
    if (pathname !== '/checkout/revisao') {
      setVisibleCart(visible);
    }
  };

  const handleChange = name => e => {
    if (name === 'email') {
      setForm({ ...form, [name]: takeOutBlankSpace(e.target.value) });
    } else {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  const onFormSubmit = async () => {
    if (authenticated === true) {
      history.push('/perfil/registro');
    }

    const payload = {
      ...form,
      password: removeSpecialChars(form.password),
    };

    await login(payload, (response) => {
      if (response && !response.error) {
        history.push('/perfil/registro');
      }
    });
  };

  useEffect(() => {
    const getScrolPosition = () => {
      setScrollTop(document.body.scrollTop);
    };

    if (scrollTop > 80 && fixedHeader === false) {
      setFixedHeader(true);
    }

    if (scrollTop < 80 && fixedHeader === true) {
      setFixedHeader(false);
    }

    document.querySelector('body').addEventListener('scroll', getScrolPosition);

    return () => window.removeEventListener('scroll', getScrolPosition);
  }, [ scrollTop ]);

  useEffect(() => {
    setHeaderWidth(document.querySelector('.page-header').clientWidth);
  }, []);

  return (
    <>
      <div className={`page-header mobile-hidden ${(fixedHeader || document.location.pathname !== '/' || dropDownVisible) && 'page-header--fixed'}`}>
        <Container>
          <Row>
            <Col className="mobile-no-padding">
              <div className="page-header__inner">
                <div className="page-header__inner__left">
                  <Link to="/">
                    <TheMensLogo />
                  </Link>
                </div>
                <div className="page-header__inner__right">
                  <div className="page-header__inner__right__dropdown">
                    <Popover
                      className="page-header__inner__right__dropdown__popover"
                      placement="bottom"
                      trigger="click"
                      visible={dropDownVisible}
                      onVisibleChange={(val) => setDropDownVisible(val)}
                      content={(
                        <div
                          style={{ width: headerWidth }}
                        >
                          <div className="page-header__inner__menu__item__popover">
                            <div className="page-header__inner__menu__item__popover__container">
                              <div className="page-header__inner__menu__item__popover__container__column">
                                <div className="page-header__inner__menu__item__popover__container__column__row">
                                  <div className="page-header__inner__menu__item__popover__container__column__row__title page-header__inner__menu__item__popover__container__column__row__title--no-padding">
                                    CABELO
                                  </div>
                                  <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/assinatura"
                                    >
                                      <strong>Hair Kit</strong> <DoctorRequested />
                                    </Link>
                                  </div>
                                  <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/produtos/gummy"
                                    >
                                      <strong>Vita</strong> gummy
                                    </Link>
                                  </div>
                                  <div className="page-header__inner__menu__item__popover__container__column__row__link page-header__inner__menu__item__popover__container__column__row__link--doc-icon">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/produtos/shampoo-energizante"
                                    >
                                      <strong>Shampoo</strong> energizante
                                    </Link>
                                  </div>
                                  {/* <div className="page-header__inner__menu__item__popover__container__column__row__link page-header__inner__menu__item__popover__container__column__row__link--doc-icon">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/produtos/minoxidil"
                                    >
                                      <strong>Minoxidil</strong> base em espuma <DoctorRequested />
                                    </Link>
                                  </div>
                                  <div className="page-header__inner__menu__item__popover__container__column__row__link page-header__inner__menu__item__popover__container__column__row__link--doc-icon">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/produtos/finasterida"
                                    >
                                      <strong>Finasterida</strong> <DoctorRequested />
                                    </Link>
                                  </div> */}
                                  <div className="page-header__inner__menu__item__popover__container__column__row__link page-header__inner__menu__item__popover__container__column__row__link--doc-icon">
                                    <Link
                                      className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                      to="/avaliacao"
                                      target="_blank"
                                    >
                                      <strong className="page-header__inner__menu__item__popover__container__column__row__link__text__doctor">Consultório médico</strong> <DoctorRequested />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="page-header__inner__menu__item__popover__container__column">
                                <div className="page-header__inner__menu__item__popover__container__column__row__title">
                                  BARBA
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link page-header__inner__menu__item__popover__container__column__row__link--padding-bottom">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/produtos/tonico-para-barba"
                                  >
                                    <strong>Tônico</strong> para barba
                                  </Link>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__title">
                                  PELE
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/produtos/anti-aging"
                                  >
                                    <strong>Anti-aging</strong> 10% nano VIT C
                                  </Link>
                                </div>
                              </div>
                              <div className="page-header__inner__menu__item__popover__container__column">
                                <div className="page-header__inner__menu__item__popover__container__column__row__title page-header__inner__menu__item__popover__container__column__row__title--no-padding">
                                  SONO
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/produtos/9847293873423"
                                  >
                                    <strong>Sleep </strong>gummy <DoctorRequested />
                                  </Link>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/produtos/chocosono"
                                  >
                                    <strong>{I18n.t('PRODUCTS.CHOCOSONO.TITLE')}</strong>
                                  </Link>
                                </div>
                              </div>
                              <div className="page-header__inner__menu__item__popover__container__column">
                                <div className="page-header__inner__menu__item__popover__container__column__row__title">
                                  INSTITUCIONAL
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/quem-somos"
                                  >
                                    Quem somos
                                  </Link>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <a
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    href="https://blog.themens.com.br/?_gl=1%2A1qft51%2A_ga%2AMTgzNjcxOTg0Mi4xNjI1NDk4MjMy%2A_ga_F39QNV76HC%2AMTYyNjE4NDQ1NS4yLjEuMTYyNjE4NTE4Ny42MA.."
                                  >
                                    Blog
                                  </a>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <Link
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    to="/podemos-ajudar"
                                  >
                                    Podemos ajudar?
                                  </Link>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <a
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_devolucao_troca.pdf"
                                  >
                                    Política de troca e devoluções
                                  </a>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <a
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf"
                                  >
                                    Termos e condições
                                  </a>
                                </div>
                                <div className="page-header__inner__menu__item__popover__container__column__row__link">
                                  <a
                                    className="page-header__inner__menu__item__popover__container__column__row__link__text"
                                    href="https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf"
                                  >
                                    Política de privacidade
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    >
                      <span className={`page-header__inner__right__dropdown__link ${(fixedHeader || document.location.pathname !== '/' || dropDownVisible) && 'page-header__inner__right__dropdown__link--active'}`}>
                        ver tudo
                        <CaretDownOutlined />
                      </span>
                    </Popover>
                  </div>
                  <div className="page-header__inner__menu__item">
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      autoAdjustOverflow={false}
                      visible={visibleLogin}
                      onVisibleChange={(e) => {
                        if (authenticated) {
                          history.push('/perfil/registro');
                          setVisibleLogin(false);
                        } else {
                          addLogin();
                        }
                      }}
                      content={!authenticated && (
                        <div id="cart">
                          <Menu className="little-cart-form">
                            <div>
                              <div className="login__form">
                                <div className="login__form__close">
                                  <button
                                    className="login__form__close__button"
                                    onClick={() => setVisibleLogin(false)}
                                  >
                                    <CloseOutlined />
                                  </button>
                                </div>
                                <div className="login__form__inner">
                                  <AdvancedForm onSubmit={onFormSubmit}>
                                    <Row className="login__form__inner__row">
                                      <Col>
                                        <div className="login__form__inner__message">
                                          <strong className="login__form__inner__message__title">
                                            {translate('PAGES.AUTH.LOGIN.FORM.TITLE')}
                                          </strong>
                                          <p className="mt-5">
                                            {translate('PAGES.AUTH.LOGIN.FORM.WELCOME')}
                                          </p>
                                        </div>
                                      </Col>
                                      <div className="login__form__inner__box-form">
                                        <Col>
                                          <input
                                            className="login__form__inner__login"
                                            placeholder={translate('PAGES.AUTH.LOGIN.FORM.EMAIL.PLACEHOLDER')}
                                            value={email && email.toLowerCase()}
                                            onChange={handleChange('email')}
                                          />
                                        </Col>

                                        <Col>
                                          <input
                                            className="login__form__inner__password"
                                            placeholder={translate('PAGES.AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER')}
                                            value={cpfMask(password)}
                                            onChange={handleChange('password')}
                                          />
                                        </Col>

                                        <Col>
                                          <div className="login__form__inner__button">
                                            <WhiteButton
                                              label={translate('PAGES.AUTH.LOGIN.FORM.BUTTON.TEXT')}
                                              type="submit"
                                              full
                                              dark
                                            />
                                          </div>
                                        </Col>
                                      </div>
                                    </Row>
                                    <Row>
                                      <div className="login__form__inner__register">
                                        <p className="login__form__inner__register__text-register">
                                          {translate('PAGES.AUTH.LOGIN.FORM.TEXT_REGISTER')}
                                        </p>
                                        <Link to={translate('PAGES.HOME.REGISTER.URL')}>
                                          <h3 className="login__form__inner__register__text-link mt-4">
                                            {translate('PAGES.AUTH.LOGIN.FORM.TEXT_LINK')}
                                          </h3>
                                        </Link>
                                      </div>
                                    </Row>
                                  </AdvancedForm>
                                </div>
                              </div>
                            </div>
                          </Menu>
                        </div>
                      )}
                    >
                      <Link
                        className={`page-header__inner__menu__item__link ${(fixedHeader || document.location.pathname !== '/' || dropDownVisible) && 'page-header__inner__menu__item__link--active'}`}
                        to={authenticated ? '/perfil/registro' : '/login'}
                      >
                        {authenticated && me ? translate('PAGES.AUTH.LOGIN.PERFIL') : translate('PAGES.AUTH.LOGIN.LOGIN')}
                      </Link>
                    </Popover>
                  </div>
                  <div className="page-header__inner__menu__item">
                    <div className={`page-header__inner__menu__item__link--cart ${(fixedHeader || document.location.pathname !== '/' || dropDownVisible) && 'page-header__inner__menu__item__link--active'}`}>
                      <span className="page-header__inner__menu__item__link__icon">
                        <CartIcon
                          className="page-header__inner__menu__item__link__icon__img"
                          alt=""
                        />
                      </span>
                      {productQuantity > 0 && (
                        <span className="page-header__inner__menu__item__link__icon">
                          {productQuantity > 9 ? '9+' : productQuantity}
                        </span>
                      )}
                      <Popover
                        trigger="hover"
                        className="content-header"
                        visible={visibleCart && pathname !== '/checkout/revisao'}
                        mouseLeaveDelay={1}
                        autoAdjustOverflow={false}
                        onVisibleChange={handleVisibleChange}
                        placement="bottomRight"
                        content={(
                          <div id="cart">
                            {products && products.length > 0 ? (
                              <Menu className="little-cart">
                                <div>
                                  <Menu.Item>
                                    <div className="little-cart__aux__block__checkout mobile-hidden">
                                      <div className="little-cart__aux__block__checkout__white">
                                        {products?.map((item, index) => (
                                          <div key={index.toString()}>
                                            <div className="little-cart__aux__block__checkout__white__product">
                                              <div className="little-cart__aux__block__checkout__white__product__aux">
                                                <div className="little-cart__aux__block__checkout__white__product__aux__first">
                                                  <img
                                                    className="little-cart__aux__block__checkout__white__product__aux__first__img"
                                                    src={`${getProductLatestImage(item)}`}
                                                    alt=""
                                                  />
                                                  <div className="little-cart__aux__block__checkout__white__product__aux__first__aux">
                                                    <p className="little-cart__aux__block__checkout__white__product__aux__first__aux__text">
                                                      {item.name}
                                                      {item.prescription && (
                                                        <strong>&nbsp;*</strong>
                                                      )}
                                                    </p>
                                                    <AmountPicker
                                                      onClickRemove={() => addOrRemoveProduct(item.id, CartSymbol.REMOVE)}
                                                      onClickAdd={() => addOrRemoveProduct(item.id, CartSymbol.ADD)}
                                                      value={item.quantity}
                                                      disabled={item.hideQtd}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="little-cart__aux__block__checkout__white__product__aux__second">
                                                <div className="little-cart__aux__block__checkout__white__product__aux__second__values">
                                                  {parseInt(item.subValue * item.quantity, 10) > parseInt(item.value * item.quantity, 10) && (
                                                    <p className="little-cart__aux__block__checkout__white__product__aux__second__values__old-value">
                                                      {toCurrencyLocale(item.subValue * item.quantity)}
                                                    </p>
                                                  )}
                                                  <p className="little-cart__aux__block__checkout__white__product__aux__second__values__new-value">
                                                    {toCurrencyLocale(parseFloat(item.price) * item.quantity)}
                                                  </p>
                                                </div>
                                                <button
                                                  onClick={() => removeProductFromCart(item.id, item.quantity)}
                                                  className="little-cart__aux__block__checkout__white__product__aux__second__remove"
                                                >
                                                  {translate('COMPONENTS.CART.REMOVE')}
                                                  <img
                                                    src={translate('COMPONENTS.CART.REMOVE_IMAGE')}
                                                    alt=""
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                        {values?.total > 0 && (
                                          <div>
                                            <div className="little-cart__aux__block__checkout__white__total">
                                              <div className="little-cart__aux__block__checkout__white__total__aux">
                                                <p className="little-cart__aux__block__checkout__white__total__aux__text">
                                                  {translate('COMPONENTS.CART.TOTAL')}
                                                </p>
                                                <p className="little-cart__aux__block__checkout__white__total__aux__text-value">
                                                  {toCurrencyLocale(values?.total)}
                                                </p>
                                              </div>
                                              <div>
                                                <p className="little-cart__aux__block__checkout__white__total__aux__text-value-item">

                                                  &nbsp;{paymentData?.type === PaymentMethod.CREDIT ? ` (${paymentData.installments}x ${paymentData.installments > 3 ? 'com juros' : 'sem juros'})` : 'à vista ou 3x'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {products && products.length > 0 && (
                                          <div className="little-cart__aux__block__checkout__white__bottom">
                                            <button
                                              className="little-cart__aux__block__checkout__white__bottom__button"
                                              onClick={() => history.push('/checkout/revisao')}
                                            ><>Seguir para compra &nbsp;&nbsp;< ArrowRightOutlined /></></button>
                                            {someProductHasPrescription(products) && (
                                              <div className="little-cart__aux__block__checkout__white__obs">
                                                {translate('COMPONENTS.CART.OBS')}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Menu.Item>
                                </div>
                              </Menu>
                            ) : (
                              <div className="little__aux__block__checkout__white">
                                {!products?.length && (
                                  <div className="little__aux__block__checkout__white__buying">
                                    <h1>Sua sacola está vazia.</h1>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      >
                        <Link
                          className={`page-header__inner__menu__item__link--cart ${(fixedHeader || document.location.pathname !== '/' || dropDownVisible) && 'page-header__inner__menu__item__link--active'}`}
                          to="/checkout/revisao"
                        >
                          item(s)
                          <CaretDownOutlined />
                        </Link>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="page-header-mobile mobile-visible">
        <div className="page-header-mobile__container">
          <div className="page-header-mobile__container__logo">
            <Link to="/">
              <TheMensLogo />
            </Link>
          </div>
          <div className="page-header-mobile__container__dropdown">
            <Dropdown
              visible={dropdownVisible}
              overlay={<MobileHeaderMenu isAuthenticated={authenticated}
                onClick={() => { setDropdownVisible(!dropdownVisible); }}
                trigger={[ 'click' ]} />}
              className="page-header-mobile__container__dropdown__item"
            >
              <a className="page-header-mobile__container__dropdown__item__link"
                onClick={() => { setDropdownVisible(!dropdownVisible); }}>
                <MenuOutlined className="page-header-mobile__container__dropdown__item__link__icon" />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      <script data-cfasync="false"
        async="true"
        id="cartstack"
        src="https://app.cartstack.com.br/activeAPI/load.js"
        type="text/javascript"></script>
    </>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  products: state.cart.products,
  values: state.cart.values,
  paymentData: state.cart.paymentData || null,
  me: state.auth.me,
  isVisible: state.productModal,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProductInCartByID: (idProduct, data) => dispatch(ActionsCart.deleteProductInCartByID(idProduct, data)),
  getProductByID: (id) => dispatch(ActionProducts.getProductByID(id)),
  login: (userData, callback) => dispatch(AuthActions.authenticate(userData, callback)),
  sendRecoveryEmail: (userData, callback) => dispatch(AuthActions.sendRecoveryEmail(userData, callback)),
  passwordRecovery: (data, callback) => dispatch(AuthActions.passwordRecovery(data, callback)),
  addOrRemoveProduct: (id, symbol) => dispatch(CartActions.addOrRemoveProduct(id, 1, symbol)),
  removeProductFromCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.REMOVE)),
  createDefaultCart: (payload) => dispatch(CartActions.createDefaultCart(payload)),
  addLogin: () => dispatch(LoginModalActions.addLogin()),
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
  setInvisibleModal: () => dispatch(ProductModalActions.hideModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);

import React from 'react';
import Slider from '@ant-design/react-slick';
import recomendedProductsItems from '../../services/recomendedProductsItems';
import { ReactComponent as CartIcon } from '../../assets/svg/new_menu/ic_header_bag.svg';
import { connect } from 'react-redux';
import * as ActionsCart from '../../redux/actions/cart';
import { CartSymbol } from '../../enum/cartSymbol';
import { translate } from '../../services/i18n';
import { ProductModalActions } from '../../redux/actions';
import { message } from 'antd';
import { validateMobile } from '../../utils/validateMobile';

const RecomendedProducts = (props) => {
  const { addProductsInCart } = props;
  const { setVisibleModal } = props;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: props.mobile ? 1 : 3,
    slidesToScroll: props.mobile ? 1 : 3,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    centerMode: true,
    centerPadding: props.mobile ? '4%' : '8%',
  };

  return (
    <>
      <div className="recomended-products mobile-hidden">
        <h3 className="recomended-products__title">Recomendado para você</h3>
        <div className="recomended-products__slider">
          <Slider {...settings}>
            {recomendedProductsItems &&
              recomendedProductsItems.map((item, i) => (
                <div key={i}
                  className="recomended-products__slider__item">
                  <img
                    src={item.img}
                    className="recomended-products__slider__item__img"
                  />
                  <p className="recomended-products__slider__item__stars">
                    {item.stars}
                  </p>
                  <p className="recomended-products__slider__item__price">
                    {item.price}
                  </p>
                  <p className="recomended-products__slider__item__name">
                    {item.name}
                  </p>
                  <p className="recomended-products__slider__item__text">
                    {item.text}
                  </p>
                  <button
                    onClick={() => {
                      addProductsInCart(item.id);

                      if (validateMobile(window.screen.width)) props.message();

                      setVisibleModal(true);
                    }}
                    disabled={item && item.name === 'Tônico para barba'}
                    className="recomended-products__slider__item__button"
                  >
                    ADICIONAR NA SACOLA &nbsp;&nbsp;
                    <CartIcon />
                  </button>
                </div>
              ))}
          </Slider>
        </div>
      </div>
      <div className="recomended-products-mobile mobile-visible">
        <h3 className="recomended-products-mobile__title">
          Recomendado para você
        </h3>
        <div className="recomended-products-mobile__slider">
          <Slider {...settings}>
            {recomendedProductsItems &&
              recomendedProductsItems.map((item, i) => (
                <div key={i}
                  className="recomended-products-mobile__slider__item">
                  <img
                    src={item.img}
                    className="recomended-products-mobile__slider__item__img"
                  />
                  <p className="recomended-products-mobile__slider__item__stars">
                    {item.stars}
                  </p>
                  <p className="recomended-products-mobile__slider__item__price">
                    {item.price}
                  </p>
                  <p className="recomended-products-mobile__slider__item__name">
                    {item.name}
                  </p>
                  <p className="recomended-products-mobile__slider__item__text">
                    {item.text}
                  </p>
                  <div
                    onClick={() => {
                      addProductsInCart(item.id);
                      props.setVisibleModal(true);
                    }}
                    className="recomended-products-mobile__slider__item__button"
                  >
                    ADICIONAR NA SACOLA &nbsp;&nbsp;
                    <CartIcon />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quantById: state.product.quantById,
  loading: state.loading,
  me: state.auth.me,
  isVisible: state.productModal,
});

const mapDispatchToProps = (dispatch) => ({
  addProductsInCart: (id) =>
    dispatch(ActionsCart.addOrRemoveProduct(id, 1, CartSymbol.ADD)),
  calculateFreight: (zipcode, products, callback) =>
    dispatch(
      ActionsCart.calculateFreight(
        {
          products,
          calculateFreight: true,
          zipcode,
        },
        callback,
      ),
    ),
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecomendedProducts);

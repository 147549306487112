import React from 'react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  horizontal: true,
  horizontalSwiping: true,
  dotsClass: 'vertical-dots',
  autoplay: true,
  autoplaySpeed: 5000,
};

const VerticalBoxSlider = (props) => (
  <div className="vertical-box-slider">
    <Slider {...settings}>
      {props.items && props.items.map((item, itemIndex) => (
        <div
          className="vertical-box-slider__item"
          key={itemIndex.toString()}
        >
          <div
            className="vertical-box-slider__item__inner"
            style={{
              backgroundImage: `url(${item.imageUrl})`,
            }}
          >
            <div className="vertical-box-slider__item__inner__box">
              <div className="vertical-box-slider__item__inner__box__content">
                <div className="vertical-box-slider__item__inner__box__content__title">
                  {item.title}
                </div>
                <div className="vertical-box-slider__item__inner__box__content__description">
                  {item.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default VerticalBoxSlider;

import React from 'react';
import { Link } from 'react-router-dom';

const WhiteButton = ({
  type,
  label,
  onClick,
  href,
  icon,
  dark,
  full,
  disabled,
  isMobile,
  small,
  customBackground,
  customColor,
  medicineColor
}) => {
  return href ? (
    <Link
      className="white-button"
      to={href}
      disabled={disabled}
      style={{
        padding: (icon && '12px 17px') || (small && '10px 13px'),
        backgroundColor: ((dark && medicineColor) || customBackground) || '#25505B',
        color: dark ? 'white' : customColor,
        width: (full || isMobile) && '100%',
        fontSize: small && 12,
      }}
    >
      {label}
    </Link>
  ) : (
    <button
      className="white-button"
      onClick={() => (type !== 'submit' ? onClick() : {})}
      type={type || 'button'}
      disabled={disabled}
      style={{
        padding: icon && '14px 17px',
        backgroundColor: ((dark && medicineColor) || customBackground) || '#25505B',
        color: dark ? 'white' : customColor,
        width: (full || isMobile) && '100%',
      }}
    >
      {label}
    </button>
  );
};

export default WhiteButton;

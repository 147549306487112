const sleepGummyFaq = [
  {
    question: 'Posso usar todos os dias?',
    answer: 'Siga a orientação médica. Normalmente, recomenda-se uma dose ao dia.'
  },
  {
    question: 'Há contraindicações?',
    answer: 'Sim. Pacientes com hipersensibilidade a qualquer componente do produto. Além disso, não é indicada para crianças e casos de gravidez e aleitamentos. Por ser zero açúcar diabéticos podem tomar.'
  },
  {
    question: 'Devo tomar quantas horas antes de dormir?',
    answer: 'Deve-se ingerir de 1-2 horas antes de dormir e nunca de estômago vazio.'
  },
  {
    question: 'Por quanto tempo tenho que usar?',
    answer: 'Deve-se utilizar o produto de acordo com a orientação médica. Faça aqui sua avaliação médica. Basta selecionar SONO e seguir o passo a passo.'
  },
  {
    question: 'Há reações adversas?',
    answer: 'A melatonina é considerada uma substância de baixa toxicidade e uso seguro principalmente se comparada com diversos fármacos de uso comum, principalmente os hipnoindutores. Caso tenha qualquer reação, seu uso deverá ser suspenso imediatamente e o seu médico alertado.'
  },
];

export default sleepGummyFaq;
import React from 'react';

const Breadcrumb = ({
  items,
}) => {
  return (
    <div className="breadcrumb">
      {items && items.map((item, itemIndex) => (
        <div
          key={itemIndex.toString()}
          className="breadcrumb__item"
        >
          <a
            className={`breadcrumb__item__link ${item.disabled ? 'breadcrumb__item__link--disabled' : ''}`}
            href={item.link}
          >
            {item.name}
          </a>
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;

import React, { useState } from 'react';
import { translate } from '../../services/i18n';
import CreditCards from '../../assets/png/credi_cards.png';
import IconEmail from '../../assets/svg/ic_email.svg';
import IconFacebook from '../../assets/svg/ic_facebook.svg';
import IconInstagram from '../../assets/svg/ic_instagram.svg';
import IconLinkedin from '../../assets/svg/ic_linkedin.svg';
import IconWhatsapp from '../../assets/svg/ic_whatsapp.svg';
import ArrowUp from '../../assets/svg/footer-arrow-up.svg';
import ArrowDown from '../../assets/svg/footer-arrow-down.svg';
import { LINK_TYPES_MAP } from '../../enum/footer';

const PageFooterMobile = ({ getProductByID }) => {

  const [ productOpen, setProductOpen ] = useState(false);
  const [ institucionalOpen, setInstitucionalOpen ] = useState(false);

  const getProduct = async (id) => {
    await getProductByID(id);
    document.querySelector('body').scrollTo(0, 0);
  };

  return (
    <div className="footer-mobile__bottom">
      <div className="footer-mobile__bottom__links">
        <div className="footer-mobile__bottom__links__medical">
          <a className="footer-mobile__bottom__links__medical__link"
            href={translate('PAGES.FOOTER.LINKS.MEDICAL.LINK')}>
            {translate('PAGES.FOOTER.LINKS.MEDICAL.TEXT')}
          </a>
        </div>
        <div className="footer-mobile__bottom__links__products">
          <h3 onClick={() => setProductOpen(!productOpen)}
            className="footer-mobile__bottom__links__products__title">
            {translate('PAGES.FOOTER.LINKS.TITLE_ONE')}
            <img className="footer-mobile__bottom__links__products__title__arrow"
              src={productOpen ? ArrowUp : ArrowDown}
              alt={'arrow'} />
          </h3>
          {productOpen && (
            <>
              {Object.values(translate('PAGES.FOOTER.LINKS.ITEMS_LEFT')).map((item, itemKey) => (
                <a
                  key={itemKey}
                  href={item.LINK && item.SOLD_OFF !== 'true'}
                  onClick={item.ID && item.SOLD_OFF !== 'true' ? () => getProduct(item.ID) : null}
                  className="footer-mobile__bottom__links__products__link"
                >
                  {item.SOLD_OFF === 'true' ? (
                    <p className="footer-mobile__bottom__links__products__link__text--sold-off">{item.NAME}</p>
                  ) : (
                    <p className="footer-mobile__bottom__links__products__link__text">{item.NAME}</p>
                  )}
                </a>
              ))}
            </>
          )}

        </div>
        <div className="footer-mobile__bottom__links__institucional">
          <h3 onClick={() => setInstitucionalOpen(!institucionalOpen)}
            className="footer-mobile__bottom__links__institucional__title">
            {translate('PAGES.FOOTER.LINKS.TITLE_TWO')}
            <img className="footer-mobile__bottom__links__institucional__title__arrow"
              src={institucionalOpen ? ArrowUp : ArrowDown}
              alt={'arrow'} />
          </h3>
          {institucionalOpen && (
            <>
              {Object.values(translate('PAGES.FOOTER.LINKS.ITEMS_RIGHT')).map((item, itemKey) => (
                <div key={itemKey}>
                  {Object.keys(LINK_TYPES_MAP).includes(item.NAME) &&
                    <a
                      href={item.LINK}
                      rel="noopener noreferrer"
                      className="footer__bottom__links__institucional__link"
                      target="_blank"
                    >
                      <p>{item.NAME}</p>
                    </a>
                  }
                </div>
              ))}
            </>
          )}
        </div>
        <div className="footer-mobile__bottom__links__aux">
          <div className="footer-mobile__bottom__links__aux__social">
            <h3 className="footer-mobile__bottom__links__aux__social__title">
              {translate('PAGES.FOOTER.SOCIALS.TITLE')}
            </h3>
            <div className="footer-mobile__bottom__links__aux__social__icons">
              <a
                href="https://www.facebook.com/themensbr"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={IconFacebook}
                  alt=""
                />
              </a>
              <a
                href="https://instagram.com/themens_oficial"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={IconInstagram}
                  alt=""
                />
              </a>
              <a
                className="footer-mobile__bottom__links__aux__social__icons--last"
                href="https://www.linkedin.com/company/themens"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={IconLinkedin}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-mobile__bottom__links__aux__talk">
            <h3 className="footer-mobile__bottom__links__aux__talk__title">
              {translate('PAGES.FOOTER.SOCIALS.TITLE_TALK')}
            </h3>
            <div className="footer-mobile__bottom__links__aux__talk__icons">
              <a
                href="mailto:contato@themens.com.br"
                rel="noreferrer noopener"
                target="_blank"
              >
                <img
                  src={IconEmail}
                  alt=""
                />
              </a>
              <a
                className="footer-mobile__bottom__links__aux__talk__icons--last"
                href="https://api.whatsapp.com/send?phone=5511942120797"
                rel="noreferrer noopener"
                target="_blank"
              >
                <img
                  src={IconWhatsapp}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-mobile__bottom__links__aux__payment">
            <h3 className="footer-mobile__bottom__links__aux__payment__title">
              {translate('PAGES.FOOTER.SOCIALS.TITLE_PAYMENT')}
            </h3>
            <div>
              <img
                className="footer-mobile__bottom__links__aux__payment__cards"
                src={CreditCards}
                alt=""
              />
            </div>
          </div>
          <script data-cfasync="false"
            async="true"
            id="cartstack"
            src="https://app.cartstack.com.br/activeAPI/load.js"
            type="text/javascript"></script>
        </div>
      </div>
    </div>
  );
};

export default PageFooterMobile;
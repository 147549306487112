import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as ActionsProducts from '../../../redux/actions/products';
import * as ActionsCart from '../../../redux/actions/cart';
import { Col, Container, Row } from 'react-bootstrap';
import FeatureSection from '../../../components/FeatureSection/FeatureSection';
import { translate } from '../../../services/i18n';
import FeatureCarousel from '../../../components/FeatureCarousel/FeatureCarousel';
import BestSeller from '../../../components/BestSeller/BestSeller';
import { useHistory, useLocation } from 'react-router-dom';
import ProductCardSection from '../../../components/ProductCardSection/ProductCardSection';
import RotativeBanner from '../../../components/RotativeBanner/RotativeBanner';
import OurProducts from '../../../components/OurProducts/OurProducts';
import { CartSymbol } from '../../../enum/cartSymbol';
import AuthService from '../../../services/auth';
import NewsletterModal from '../../../components/NewsletterModal/NewsletterModal';
import { getProductByCode } from '../../../services/product';
import ProductList from '../../../enum/productList';
import { CartActions, PrescriptionActions } from '../../../redux/actions';
import BannerCarousel from '../BannerCarousel/BannerCarousel';

const Home = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [ sizeHeader, setSizeHeader ] = useState(0);
  const cardSection = useRef();

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
    AuthService.isFromEcommerceDomain();
  }, [ pathname ]);

  const {
    addProductsInCart,
    products,
    getCouponByTitle
  } = props;

  const addProductInCart = async (id) => {
    addProductsInCart(id);
    props.message();
  };

  const setProductFromPrevLocation = () => {
    const useQuery = new URLSearchParams(window.location.search);

    const queryMedicalPrescription = useQuery.get('fromPrescriptionPayment');
    const queryMelatonin = useQuery.get('melatonin');
    const queryEd = useQuery.get('ed');
    const queryHairLoss = useQuery.get('hairLoss');

    const minoxidilFound = products.find((item) => (item.id === getProductByCode(ProductList.MINOXIDIL).id));
    const sleepGummyFound = products.find((item) => (item.id === getProductByCode(ProductList.SLEEP_GUMMY).id));
    const tadalafilFound = products.find((item) => (item.id === getProductByCode(ProductList.TADALAFIL).id));

    if (queryMedicalPrescription) {
      if (queryMelatonin && !sleepGummyFound) {
        addProductsInCart(getProductByCode(ProductList.SLEEP_GUMMY).id);
      }

      if (queryHairLoss && !minoxidilFound) {
        addProductsInCart(getProductByCode(ProductList.MINOXIDIL).id);
        addProductsInCart(getProductByCode(ProductList.SHAMPOO_COM_HVIT).id);
        addProductsInCart(getProductByCode(ProductList.FINASTERIDA).id);
        addProductsInCart(getProductByCode(ProductList.GUMMY_ZERO).id);
      }

      if (queryEd && !tadalafilFound) {
        const product = getProductByCode(ProductList.TADALAFIL);

        addProductsInCart(product.id, 1);
      }

      history.push('/checkout/revisao');
    }

    const product = useQuery.get('product');
    const quantity = useQuery.get('quantity');

    if (product) {
      addProductsInCart(product, parseInt(quantity));
      history.push('/checkout/revisao');
    }
  };

  useEffect(() => {
    if (document.querySelector('.page-header')) {
      setSizeHeader(document.querySelector('.page-header').offsetHeight + 1);
    }

    setProductFromPrevLocation();
  }, []);

  const bannerInfos = [
    {
      title: translate('BANNER.HOME.TITLE'),
      subtitle: translate('BANNER.HOME.SUBTITLE'),
      buttonText: translate('BANNER.HOME.BUTTON'),
      subtext: translate('BANNER.HOME.SUBTEXT'),
      isBannerHome: true,
      img: ''
    },
    {
      titleFirstLine: translate('BANNER.CREATINA.TITLE_FIRST_LINE'),
      titleSecondLine: translate('BANNER.CREATINA.TITLE_SECOND_LINE'),
      subtitleFirstLine: translate('BANNER.CREATINA.SUBTITLE_FIRST_LINE'),
      subtitleSecondLine: translate('BANNER.CREATINA.SUBTITLE_SECOND_LINE'),
      buttonText: translate('BANNER.CREATINA.BUTTON'),
      subtext: '',
      isBannerCreatina: true,
      img: '/assets/img/home-carousel/creatina_banner.png',
      imgMobile: '/assets/img/home-carousel/creatina_banner_mobile.png',
    }
  ];

  return (
    <div className="home">
      <NewsletterModal />
      <div className="home__section">
        <BannerCarousel bannerProps={bannerInfos} />
        <Container ref={cardSection}
          className="mobile-no-padding">
          <Row>
            <Col className="mobile-no-padding">
              <ProductCardSection addProductInCart={(product) => addProductInCart(product.id)} />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="mobile-no-padding">
              <BestSeller
                addProductInCart={(product, quantity) => addProductInCart(product.id)}
                soldOff
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="mobile-no-padding">
              <OurProducts message={props.message} />
            </Col>
          </Row>
        </Container>
        <Container className="mobile-no-padding">
          <Row>
            <Col className="mobile-no-padding">
              <RotativeBanner />
            </Col>
          </Row>
        </Container>

        <FeatureCarousel />

        <FeatureSection
          title={translate('COMPONENTS.FEATURE_SECTION.ONE.TITLE')}
          description={translate('COMPONENTS.FEATURE_SECTION.ONE.DESCRIPTION')}
          buttomText={translate('COMPONENTS.FEATURE_SECTION.ONE.BUTTOM_TEXT')}
          imageUrl={translate('COMPONENTS.FEATURE_SECTION.ONE.IMAGE_URL')}
          buttomUrl={translate('PAGES.HOME.HAIR_KIT.URL')}
          id="e6c99cac-19cf-4f18-8704-0fff17dbb133"
        />

        <FeatureSection
          title={translate('COMPONENTS.FEATURE_SECTION.THREE.TITLE')}
          description={translate('COMPONENTS.FEATURE_SECTION.THREE.DESCRIPTION')}
          buttomText={translate('COMPONENTS.FEATURE_SECTION.THREE.BUTTOM_TEXT')}
          imageUrl={translate('COMPONENTS.FEATURE_SECTION.THREE.IMAGE_URL')}
          buttomUrl={translate('COMPONENTS.FEATURE_SECTION.THREE.BUTTOM_URL')}
          reverse
          id="fbd7397f-8071-4f0a-9a64-90debfbf908a"
        />

        <FeatureSection
          title={translate('COMPONENTS.FEATURE_SECTION.FOUR.TITLE')}
          description={translate('COMPONENTS.FEATURE_SECTION.FOUR.DESCRIPTION')}
          buttomText={translate('COMPONENTS.FEATURE_SECTION.FOUR.BUTTOM_TEXT')}
          imageUrl={translate('COMPONENTS.FEATURE_SECTION.FOUR.IMAGE_URL')}
          buttomUrl={translate('COMPONENTS.FEATURE_SECTION.FOUR.BUTTOM_URL')}
          id="8cc5dd9a-732f-45cb-bfd1-2dbdbd462719"
          last
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productByID: state.product.productByID,
  productsInCart: state.cart.productsInCart,
  products: state.cart.products || [],
  fromPrescriptionPayment: state.prescription.fromPrescriptionPayment
});

const mapDispatchToProps = (dispatch) => ({
  getProductByID: (id) => dispatch(ActionsProducts.getProductByID(id)),
  addProductsInCart: (idProduct, quantity = 1, callback) => dispatch(ActionsCart.addOrRemoveProduct(idProduct, quantity, CartSymbol.ADD, callback)),
  getCouponByTitle: (title) => dispatch(CartActions.getCouponByTitle(title)),
  setFromPrescriptionPayment: (params) => dispatch(PrescriptionActions.setFromPrescriptionPayment(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import ProfileContentHeader from '../ProfileContentHeader/ProfileContentHeader';
import { translate } from '../../services/i18n';
import PasswordType from '../../enum/passwordType';
import PasswordModal from '../PasswordModal/PasswordModal';
import RecoveryPasswordModal from '../RecoveryPasswordModal/RecoveryPasswordModal';
import RegisterPasswordModal from '../RegisterPasswordModal/RegisterPasswordModal';

const ProfileContent = (props) => {
  const [ isOpen, setIsOpen ] = useState(true);
  const [ screenType, setScreenType ] = useState(PasswordType.PASSWORD);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'scroll';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [ isOpen ]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="profile-content">
      <Helmet>
        <title>{`${props.pageTitle} - ${translate('APPLICATION_NAME')}`}</title>
      </Helmet>
      <div className="profile-content__aux">
        {isOpen && (
          <>
            {screenType === PasswordType.PASSWORD && (
              <PasswordModal
                setScreenType={setScreenType}
                handleClose={handleClose}
              />
            )}
            {screenType === PasswordType.RECOVERY_PASSWORD && (
              <RecoveryPasswordModal
                setScreenType={setScreenType}
              />
            )}
            {screenType === PasswordType.REGISTER_PASSWORD && (
              <RegisterPasswordModal
                handleClose={handleClose}
              />
            )}
          </>
        )}
        <ProfileContentHeader />
        {props.children}
      </div>
    </div>
  );
};

export default ProfileContent;
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({ basename: window.location.pathname });
export const { getPathname } = history;

export const checkActivePath = (match) => {
  const currentLocation = window.location.toString();
  const result =
    match && match.isExact && match.path !== '/'
      ? currentLocation.includes(match.path)
      : match && match.path === '/'
        ? currentLocation.lastIndexOf('/') === currentLocation.length - 1
        : false;

  return result;
};

export default {
  checkActivePath,
  getPathname,
  history,
};

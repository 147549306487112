import 'bootstrap/dist/css/bootstrap-grid.min.css';

import React from 'react';
import { pixelInit, pixelPage } from './app/utils/pixel';
import Router from './app/router/Router';
import CookiesMessage from './app/components/CookiesMessage';

class App extends React.Component {
  componentDidMount () {
    pixelInit();
    pixelPage();
  }
  render () {
    return (
      <div>
        <Router />
        <CookiesMessage />
      </div>
    );
  }
}

export default App;

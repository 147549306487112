export const ACTION_PAGE_VIEW = 'ACTION_PAGE_VIEW';

export const userActivity = (params, userActivity) => async (dispatch) => {
  //
};

export const addToCart = (params) => async (dispatch) => {
  //
};

export const initCheckout = (params) => async (dispatch) => {
  //
};

export const abandonmentCart = (cart) => async (dispatch) => {
  //
};

import React from 'react';
import { useLocation } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

const CheckoutPaginated = () => {
  const { pathname } = useLocation();

  let isCheckoutActive = pathname === '/checkout/revisao' || pathname.includes('/checkout/dados-de-entrega');
  let isDeliveryActive = pathname.includes('/checkout/dados-de-entrega');
  const isPaymentActive = pathname.includes('/checkout/pagamento');

  //If payment is active, it means that it has already gone through the two previous stages.
  if (isPaymentActive) {
    isCheckoutActive = true;
    isDeliveryActive = true;
  }

  return (
    <div className="checkout-paginated">
      <div className={`checkout-paginated__checkout ${isCheckoutActive && 'checkout-paginated__checkout--active'}`}>
        <p className={`checkout-paginated__checkout__text ${isCheckoutActive && 'checkout-paginated__checkout__text--active'}`}>
          {I18n.t('CHECKOUT.PAGINATED.CHECKOUT')}
        </p>
      </div>
      <div className={`checkout-paginated__delivery ${isDeliveryActive && 'checkout-paginated__delivery--active'}`}>
        <p className={`checkout-paginated__delivery__text ${isDeliveryActive && 'checkout-paginated__delivery__text--active'}`}>
          {I18n.t('CHECKOUT.PAGINATED.DELIVERY')}
        </p>
      </div>
      <div className={`checkout-paginated__payment ${isPaymentActive && 'checkout-paginated__payment--active'}`}>
        <p className={`checkout-paginated__payment__text ${isPaymentActive && 'checkout-paginated__payment__text--active'}`}>
          {I18n.t('CHECKOUT.PAGINATED.PAYMENT')}
        </p>
      </div>
    </div>
  );
};

export default CheckoutPaginated;

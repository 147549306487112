import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as AuthActions from '../../../../redux/actions/auth';
import * as UserActions from '../../../../redux/actions/user';
import * as UtilsActions from '../../../../redux/actions/utils';
import { translate } from '../../../../services/i18n';
import { cepMask } from '../../../../utils/masks';
import { removeSpecialChars } from '../../../../utils/string';
import AuthService from '../../../../services/auth';

import { PrescriptionActions } from '../../../../redux/actions';
import CollapseEdit from '../CollapseAddress/CollapseEdit';
import { ProfileType } from '../../../../enum/profileType';
import { ProfileAddress } from '../../../../enum/profileForm';


const CollapseAddress = ({
  getMe,
  loading,
  me,
  getAddressByCep,
  getPrescription,
}) => {
  const [ form, setForm ] = useState({
    grantType: '',
    profileType: ProfileType.USER,
    zipcode: '',
    neighborhood: '',
    street: '',
    city: '',
    uf: '',
    addressNumber: '',
    complement: '',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    AuthService.isFromEcommerceDomain();
    await getMe();
    getPrescription();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ AddressScreen ] = useState(ProfileAddress.REGISTER_ADDRESS);

  const {
    zipcode,
    neighborhood,
    street,
    city,
    uf,
  } = form;

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        grantType: me.grantType,
        profileType: me.profileType,
        zipcode: me.zipcode,
        neighborhood: me.neighborhood,
        street: me.street,
        city: me.city,
        uf: me.uf,
        addressNumber: me.addressNumber,
        complement: me.complement,
      });
    }
  }, [ me ]);

  const fieldChange = (name, value) => {
    form[name] = value;
    setForm({ ...form, form });
  };

  const getAddressByCeps = async (cep) => {
    const completeAddress = await getAddressByCep(cep);

    if (!completeAddress.erro) {
      fieldChange('uf', completeAddress.uf);
      fieldChange('city', completeAddress.localidade);
      fieldChange('street', completeAddress.logradouro);
      fieldChange('neighborhood', completeAddress.bairro);
    }
  };

  return (
    <>

      {AddressScreen === ProfileAddress.REGISTER_ADDRESS ? (
        <>
          <div className="register__profile__dropdown__address-container__item__text">
            <div className="register__profile__dropdown__address-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.ADDRESS')}
              <input
                className='register__profile__dropdown__address-container__item__text__user-item'
                value={street}
                disabled
              />
            </div>
            <div className="register__profile__dropdown__address-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.NEIGHBORHOOD')}
              <input
                className='register__profile__dropdown__address-container__item__text__user-item'
                value={neighborhood}
                disabled
              />
            </div>
            <div className="register__profile__dropdown__address-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.CITY')}
              <input
                className='register__profile__dropdown__address-container__item__text__user-item'
                value={city}
                disabled
              />
            </div>
            <div className="register__profile__dropdown__address-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.STATE')}
              <input
                className='register__profile__dropdown__address-container__item__text__user-item'
                value={uf}
                disabled
              />
            </div>
            <div className="register__profile__dropdown__address-container__item__text__subitem">
              {translate('PAGES.HOME.PROFILE_REGISTER.FORM.ADDRESS.CEP')}
              <input
                className='register__profile__dropdown__address-container__item__text__user-item'
                value={cepMask(zipcode)}
                onChange={(val) => {
                  fieldChange('zipcode', val.target.value);

                  if (val && val.target && val.target.value && removeSpecialChars(val.target.value).length === 8) {
                    getAddressByCeps(removeSpecialChars(val.target.value));
                  }
                }}
                disabled
              />
            </div>
            <div className='register__profile__dropdown__address-container__edit-button' />
          </div>
        </>
      ) : (<CollapseEdit />)}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  me: state.auth.me,
});

const mapDispatchToProps = (dispatch) => ({
  getPrescription: () => dispatch(PrescriptionActions.getPrescription()),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  updateUserPassword: (id, data, callback) => dispatch(UserActions.updateUserPassword(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseAddress);
import { PrescriptionActions } from '../actions';

const initialState = {
  prescriptionByID: null,
  quizUserResponse: null,
  payload: null,
  fromPrescriptionPayment: null,
  prescription: null,
};

const prescriptionReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PrescriptionActions.ACTION_GET_PRESCRIPTION_BY_ID:
      state = {
        ...state,
        prescriptionByID: action.payload,
      };
      return state;

    case PrescriptionActions.ACTION_SET_QUIZ_USER_RESPONSE:
      state = {
        ...state,
        quizUserResponse: action.payload,
      };
      return state;

    case PrescriptionActions.ACTION_SET_QUIZ_USER_PAYLOAD:
      state = {
        ...state,
        payload: action.payload,
      };
      return state;

    case PrescriptionActions.ACTION_SET_FROM_PESCRIPTION_PAYMENT:
      state = {
        ...state,
        fromPrescriptionPayment: action.payload,
      };
      return state;

    case PrescriptionActions.ACTION_GET_PRESCRIPTION:
      state = {
        ...state,
        prescription: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default prescriptionReducer;

const hairKitFaq = [
  {
    title: '<strong> Posso usar todos os produtos?</strong>',
    description: '<p>Recomendamos que realize a avaliação médica para saber se está apto a usar todos os produtos. O médico indicará o tratamento ideal para o seu caso. </p>',
  },
  {
    title: '<strong>É melhor que transplante?<strong/>',
    description: '<p>Nossos produtos devem ser utilizados antes de qualquer tratamento cirúrgico. O nível de eficácia do Hair Kit é de 91.5%, ou seja, mais de 9 pessoas em 10 tem os resultados desejados. </p>',
  },
  {
    title: '\'<strong>Fiz transplante, devo usar The Men\'s?<strong/>\'',
    description: '<p>Muitas pessoas acreditam que após realizar o transplante não será necessário o uso do minoxidil e finasterida. Contudo, todos protocolos médicos indicam o uso de pelo menos um ao longo de toda vida do paciente para manter-se com os cabelos transplantados e diminuir ou cessar a queda dos outros fios.',
  },
  {
    title: '<strong>São médicos reais que atendem?<strong/>',
    description: '<p>Sim, são médicos, formados nas melhores faculdades do Brasil, prontos para lhe atender a qualquer momento.</p>',
  },
  {
    title: '<strong>Há efeitos colaterais?<strong/>',
    description: '<p>Impossível dizer que não. Há um número bem pequeno de pacientes que apresentam algum efeito colateral, seja ele irritação no couro cabeludo.</p>',
  },
  {
    title: '<strong>O que é Finasterida?<strong/>',
    description: '<p>O medicamento mais prescrito no mundo para interrupção da queda capilar</p>',
  },
  {
    title: '<strong>O que é Minoxidil em espuma?<strong/>',
    description: '<p>O medicamento de maior sucesso para crescimento capilar</p>',
  },
  {
    title: '<strong>O que é Shampoo energizante?<strong/>',
    description: '<p>O melhor Shampoo para limpar profundamente, retirar a oleosidade e deixar o couro pronto apto para receber o Minoxidil em espuma. </p>',
  },
  {
    title: '<strong>O que é Vita gummy?<strong/>',
    description: '<p>A gummy mais forte do Brasil, com muitas vitaminas e minerais que ajudam seu cabelo a crescer mais forte e mais saudável</p>',
  },
  {
    title: '<strong>O que é o Programa Money Back?<strong/>',
    description: '<p>É um programa de reembolso. Caso o tratamento proposto pelo médico e comprovadamente utilizado pelo paciente não funcione, reembolsamos integralmente o valor ao paciente. Só queremos vender aquilo que funcione.</p>',
  },
];

export default hairKitFaq;
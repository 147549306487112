import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const ValidationTextField = withStyles({
  root: {
    '& input': {
      borderColor: '#DDDDDD',
      borderWidth: 1,
    },
    '& input:valid + fieldset': {
      borderColor: '#3B5C7A',
      borderWidth: 1,
    },
    '& input:invalid + fieldset': {
      borderColor: '#CECECE',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderColor: '#3B5C7A',
      borderWidth: 1,
    },
  },
})(TextField);

const AdvancedInput = ({
  value,
  label,
  placeholder,
  variant = 'outlined',
  type,
  onChange,
  multiline = false,
  error,
  id,
  disabled,
  notRequired,
  onBlur,
  rows,
  autoComplete = 'on',
  maxlength,
  name,
  onFocus,
  inputProps,
  className
}) => {

  return (
    <div className={className || 'advanced-input'}>
      <ValidationTextField
        rows={rows}
        id={id}
        error={error}
        required={!notRequired}
        fieldset="valid"
        type={type}
        value={value || ''}
        label={label}
        placeholder={placeholder}
        variant={variant}
        onChange={onChange}
        fullWidth
        multiline={multiline}
        disabled={disabled}
        InputLabelProps={{ required: false, shrink: type === 'date' ? true : undefined }}
        onBlur={onBlur ? (() => onBlur()) : () => { }}
        onFocus={onFocus ? ((e) => onFocus(e)) : () => { }}
        name={name}
        autoComplete={autoComplete}
        maxlength={maxlength}
        InputProps={inputProps}
      />
    </div>
  );
};

export default AdvancedInput;

import { ArrowRightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserActions } from '../../../app/redux/actions';
import PasswordType from '../../enum/passwordType';
import { translate } from '../../services/i18n';
import { cpfMask } from '../../utils/masks';
import AdvancedInputPassword from '../AdvancedInputPassword/AdvancedInputPassword';
import { Link } from 'react-router-dom';
import * as AuthActions from '../../redux/actions/auth';
import * as CartActions from '../../redux/actions/cart';

const PasswordModal = ({
  setScreenType,
  handleClose,
  authTwoFactor,
  me,
  user,
  recoveryTwoFactor,
  enabledTwoFactor,
  logout,
  deleteAllProductsInCart,
  addTwoFactor,
}) => {
  const initialValues = {
    password: '',
  };

  const logoutAccount = () => {
    logout();
    deleteAllProductsInCart();
  };

  const [ passwordForm, setPasswordForm ] = useState(initialValues);
  const [ isTrue, setIsTrue ] = useState(false);

  useEffect(() => {
    if (me && me.id) {
      enabledTwoFactor(me.id, (payload) => {
        if (payload.twoFactorEnabled) {
          setIsTrue(true);
        } else if (payload && !payload.twoFactorEnabled) {
          addTwoFactor(me.id, { twoFactor: me.document });
        }
      });
    }
  }, [ me?.id ]);

  const handleInput = (key, value) => {
    setPasswordForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onRecoveryTwoFactor = () => {
    if (me && me.id) {
      recoveryTwoFactor(me.id);
      message.success(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.RECOVERY_PASSWORD.MESSAGE.EMAIL_SUCCESS'));
      setScreenType(PasswordType.RECOVERY_PASSWORD);
    }
  };

  const onSubmitPassword = async () => {
    if (passwordForm.password.trim() === '') {
      message.error(translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.MESSAGE.ERROR.ENTER_PASSWORD'));
    } else {
      const payload = {
        twoFactor: passwordForm.password
      };

      authTwoFactor(payload, () => {
        handleClose();
      });
    }
  };



  return (
    <div className="register-modal">
      <div className="register-modal__container">
        <div open
          className="register-modal__container__inner">
          <div className="register-modal__container__inner__content-title">
            <p className="register-modal__container__inner__content-title__title">
              {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.TITLE')}
            </p>
          </div>
          <div className="register-modal__container__inner__description">
            <div className="register-modal__container__inner__description__content">
              {isTrue ? (
                <>
                  <p className="register-modal__container__inner__description__content__text">
                    {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.COMPLEMENT_TEXT')}
                    <a
                      className="register-modal__container__inner__description__content__text__link"
                      onClick={onRecoveryTwoFactor}
                    >
                      {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.LINK')}
                    </a>
                    {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.FINAL_TEXT')}
                  </p>
                  <p className="register-modal__container__inner__description__content__text">
                    {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.SPECIAL_CHARACTERES')}
                  </p>
                </>
              ) : (
                <p className="register-modal__container__inner__description__content__text">
                  {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.TEXT_ALTERNATIVE')}<br />
                  <a
                    className="register-modal__container__inner__description__content__text__link"
                    onClick={() => setScreenType(PasswordType.REGISTER_PASSWORD)}
                  >
                    {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.LINK')}
                  </a>
                  {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.FINAL_TEXT_ALTERNATIVE')}
                </p>
              )}
            </div>
            <div className="register-modal__container__inner__container-input">
              <div className="register-modal__container__inner__container-input__content">
                {user && user.twoFactorEnabled ? (
                  <>
                    <label className="register-modal__container__inner__container-input__content__label">
                      {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.LABEL')}
                    </label>
                    <AdvancedInputPassword
                      passwordInput
                      value={passwordForm.password}
                      onChange={(value) => handleInput('password', value)}
                      type="password"
                      className="register-modal__container__inner__container-input__content__input"
                    />
                  </>

                ) : (
                  <>
                    <label className="register-modal__container__inner__container-input__content__label">
                      {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.LABEL_ALTERNATIVE')}
                    </label>
                    <AdvancedInputPassword
                      passwordInput
                      value={passwordForm.password}
                      onChange={(value) => handleInput('password', value)}
                      type="password"
                      className="register-modal__container__inner__container-input__content__input"
                    />
                  </>
                )}


              </div>
            </div>
          </div>
          <div className="register-modal__container__inner__container-button">
            <button onClick={onSubmitPassword}
              className="register-modal__container__inner__container-button__button">
              {translate('PAGES.HOME.PROFILE_REGISTER.PASSWORD_MODAL.BUTTON')}
              <ArrowRightOutlined />
            </button>
          </div>
          <div className="register-modal__container__inner__logout">
            <Link
              className="register-modal__container__inner__logout__link"
              to="/perfil/registro"
              onClick={logoutAccount}
              style={{
                cursor: 'pointer'
              }}
            >
              {translate('COMPONENTS.PROFILE_CONTENT_HEADER.LOGOUT')}
            </Link>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  user: state.user.document,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  authTwoFactor: (payload, callback) => dispatch(UserActions.authenticateTwoFactor(payload, callback)),
  recoveryTwoFactor: (id) => dispatch(UserActions.recoveryTwoFactor(id)),
  enabledTwoFactor: (id, callback) => dispatch(UserActions.enabledTwoFactor(id, callback)),
  logout: () => dispatch(AuthActions.logout()),
  deleteAllProductsInCart: () => dispatch(CartActions.deleteAllProductsInCart()),
  addTwoFactor: (id, payload) => dispatch(UserActions.addTwoFactor(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal);
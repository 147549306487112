import React, { useEffect, useRef, useState } from 'react';
import AmountPicker from '../../../components/AmountPicker/AmountPicker';
import { ReactComponent as CartIcon } from '../../../assets/svg/new_menu/ic_header_bag.svg';
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { toCurrencyLocale } from '../../../utils/masks';
import { keepOnlyNumbers } from '../../../utils/string';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as CartIconMini } from '../../../assets/svg/new_menu/ic_header_bag_mini.svg';
import { message } from 'antd';
import ArrowNext from '../../../assets/svg/ic_arrow_next.svg';
import ArrowPrev from '../../../assets/svg/ic_arrow_prev.svg';
import Slider from 'react-slick';
import { translate } from '../../../services/i18n';
import { Col, Container, Row } from 'react-bootstrap';
import { UnauthenticatedBlockingModal } from '../../../components/UnauthenticatedBlockingModal/UnauthenticatedBlockingModal';
import { connect } from 'react-redux';
import { AuthActions } from '../../../redux/actions';

const ProductMobile = (props) => {
  const { product, calculateFreight } = props;
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(0);
  const [ quantity, setQuantity ] = useState(1);
  const [ freightData, setFreightData ] = useState(null);
  const { pathname } = useLocation();
  const [ zipcode, setZipcode ] = useState('');
  const [ questionsOpen, setQuestionsOpen ] = useState(null);
  const [ faqOpen, setFaqOpen ] = useState(null);
  const [ whoUsesPosition, setWhoUsesPosition ] = useState(0);
  const [ depoimentsPosition, setDepoimentsPosition ] = useState(0);
  const [ touchStart, setTouchStart ] = useState(0);
  const [ floatingButton, setFloatingButton ] = useState(false);
  const [ buttonPosition, setButtonPosition ] = useState(null);
  const [ modalOpen, setModalOpen ] = useState(null);
  const [ variant, setVariant ] = useState(0);
  const buttonRef = useRef();
  let sliderRef = useRef();
  const history = useHistory();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    centerMode: true,
  };

  const whoUsesStyle = {
    transform: `translate(${whoUsesPosition}px, 0px)`
  };
  const depoimentsStyle = {
    transform: `translate(${depoimentsPosition}px, 0px)`
  };

  useEffect(() => {
    setFreightData(null);
    setWhoUsesPosition(0);
    setDepoimentsPosition(0);
    setFaqOpen(null);
    setQuestionsOpen(null);
    setSelectedImageIndex(0);

  }, [ pathname ]);

  useEffect(() => {
    const floatingButtonVisible = () => {
      setButtonPosition(buttonRef.current.offsetTop - document.body.scrollTop);
    };

    if (buttonPosition < 0 && floatingButton === false) {
      setFloatingButton(true);
    } else if (buttonPosition > 0 && floatingButton === true) {
      setFloatingButton(false);
    }

    document.querySelector('body').addEventListener('scroll', floatingButtonVisible);
    return () => document.querySelector('body').removeEventListener('scroll', floatingButtonVisible);
  }, [ buttonPosition ]);

  const changeCarouselStep = (direction, indexSelected) => {
    if (direction === 'next' && selectedImageIndex < product.carouselImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    } else if (direction === 'prev' && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    } else if (!direction && (indexSelected || indexSelected === 0)) {
      setSelectedImageIndex(indexSelected);
    }
  };

  const addProductToCart = (productRecomended) => {

    if (product && product.hasVariant) return addVariantToCart();

    const { addProductsInCart } = props;

    if ((!props.me || !props.me?.notNeedMedicalPrescription) && product.prescriptionRequired) {
      setModalOpen(true);
    } else {
      if (productRecomended) {
        addProductsInCart(productRecomended, 1);
      } else {
        addProductsInCart(product.id, quantity);
      }

      props.message();
    }
  };

  const addVariantToCart = () => {
    const { addProductsInCart } = props;

    if (!product.hasVariant) return;

    if ((!variant && variant !== 0) || !product.hasVariant.variants[variant]) return message.error({ content: 'Selecione uma opção', className: 'ant-custom-style' });

    addProductsInCart(product.hasVariant.variants[variant].id, product.hasVariant.variants[variant].qtd);
    props.message();
  };

  const splitProductName = (productName, first) => {
    productName = productName.split(' ');

    if (first && productName.length > 0) {
      return productName[0];
    } else if (!first && productName.length > 0) {
      let lastName = '';
      productName.map((item, index) => {
        if (index !== 0) {
          lastName += ` ${item}`;
        }
      });
      return lastName;
    }

    return productName;
  };

  const onCalculateFreight = () => {
    if (zipcode) {
      const productToCalculate = (product.hasVariant && product.hasVariant.variants[variant]) || product;
      calculateFreight(zipcode, [ productToCalculate ], (response) => {
        if (response && !response.error) {
          setFreightData(response.freight);
        } else {
          setFreightData({ error: true });
        }
      });
    }
  };

  const openQuestion = (index) => {
    if (index === questionsOpen) {
      setQuestionsOpen(null);
    } else {
      setQuestionsOpen(index);
    }
  };

  const openFaq = (index) => {
    if (index === faqOpen) {
      setFaqOpen(null);
    } else {
      setFaqOpen(index);
    }
  };

  const drag = (ub) => {
    const maxLength = document.querySelector('.product-mobile__who-uses__img-container').clientWidth - document.querySelector('body').clientWidth;
    const movement = ub - touchStart;

    if ((movement < 0 || whoUsesPosition < 0) && (whoUsesPosition * -1 < maxLength - 5 || movement > 0)) {
      if (movement < 0) {
        setWhoUsesPosition(whoUsesPosition - 12);
      } else {
        setWhoUsesPosition(whoUsesPosition + 12);
      }
    }
  };

  const prescriptionRedirect = (prescriptionLink) => {
    const { host, protocol } = window.location;

    if (prescriptionLink) {
      window.open(`${protocol}//${host}/avaliacao?fromProduct=${prescriptionLink}`, '_blank');
    } else {
      window.open(`${protocol}//${host}/avaliacao`, '_blank');
    }
  };

  return (
    <div>
      {floatingButton && (
        <div className="product-mobile__floating-button">
          <div
            className="product-mobile__floating-button__add-to-cart"
            onClick={() => addProductToCart()}
          >
            ADICIONAR NA SACOLA &nbsp;&nbsp;<CartIcon />
          </div>
        </div>
      )}
      {modalOpen && (
        <div className="product-mobile__modal">
          <div onClick={() => setModalOpen(false)}
            className="product-mobile__modal__close">
            <div className="product-mobile__modal__close__button">
              <CloseOutlined />
            </div>
            <p className="product-mobile__modal__text">
              Para adquirir é necessário <strong>prescrição médica.&nbsp;</strong> Caso você não possua uma, viabilizamos consultas gratuitas para que possa encontrar o melhor tratamento.
            </p>
            <div onClick={() => prescriptionRedirect(product.prescriptionLink)}
              className="product-mobile__modal__confirm">
              FAZER MINHA 1ª AVALIAÇÃO
            </div>
            <div
              onClick={() => {
                setModalOpen(false);
                props.addProductsInCart(product.id, quantity);
                props.message();
              }}
              className="product-mobile__modal__deny"
            >
              JÁ TENHO AVALIAÇÃO
            </div>
          </div>
        </div>
      )}
      <div className="product-mobile__carousel">
        <div className="product-mobile__carousel__main">
          <img className="product-mobile__carousel__main__image"
            src={product.carouselImages[selectedImageIndex].banner} />
          <span
            className="product-mobile__carousel__main__prev"
            onClick={() => changeCarouselStep('prev')}
          >
            <img className="product-mobile__carousel__main__prev__icon"
              src={ArrowPrev} />
          </span>
          <span
            className="product-mobile__carousel__main__next"
            onClick={() => changeCarouselStep('next')}
          >
            <img className="product-mobile__carousel__main__next__icon"
              src={ArrowNext} />
          </span>
        </div>
        <div className="product-mobile__carousel__indicator">
          {product && product.carouselImages && product.carouselImages.map((item, index) =>
            <span
              key={index}
              className={
                `product-mobile__carousel__indicator__icon ${selectedImageIndex === index && 'product-mobile__carousel__indicator__icon--active'}`
              }
              onClick={() => changeCarouselStep(null, index)}
            />
          )}
        </div>
      </div>
      <div className="product-mobile__buy">
        <div className="product-mobile__buy__title">
          <div className="product-mobile__buy__title__text">
            <div className="product-mobile__buy__title__text__bold">
              {product && product.name && splitProductName(product.name, true)}
            </div>
            <div className="product-mobile__buy__title__text">
              {product && product.name && splitProductName(product.name)}
            </div>
          </div>
          <div className="product-mobile__buy__title__rating">
            <strong>{product && product.rating}</strong>/5.0
            <span className="product-mobile__buy__title__rating__star">★</span>
          </div>
        </div>
        {product && !product.hasVariant && (
          <>
            <div className="product-mobile__buy__pricing">
              <div className="product-mobile__buy__pricing__left">
                {product && product.oldValue && (
                  <div className="product-mobile__buy__pricing__right__old-price">
                    R${product && product.oldValue}0
                  </div>
                )}
                <div className="product-mobile__buy__pricing__right__price">
                  R${product && product.value}0
                </div>
              </div>
              {product && product.soldOff ? (
                <div className="product__info__right__inner__pricing__right--sold-off">
                  ESGOTADO
                </div>
              ) : (
                <div className="product-mobile__buy__pricing__right">
                  <AmountPicker
                    onClickRemove={() => setQuantity(quantity - 1)}
                    onClickAdd={() => setQuantity(quantity + 1)}
                    value={quantity}
                    blockZero
                  />
                </div>
              )}
            </div>
          </>
        )}
        {product && product.hasVariant && (
          <>
            <div className="product-mobile__buy__variants">
              {product.hasVariant.variants && product.hasVariant.variants.map((item, index) =>
                <div
                  key={index}
                  className={
                    `product-mobile__buy__variants__item 
                    ${variant === index && 'product-mobile__buy__variants__item--selected'}`
                  }
                  onClick={() => setVariant(index)}
                >
                  <div className="product-mobile__buy__variants__item__radio">
                    {variant === index && (
                      <div className="product-mobile__buy__variants__item__radio__selected" />
                    )}
                  </div>
                  <img className="product-mobile__buy__variants__item__img"
                    src={item.image}
                    alt="" />
                  <div className={`product-mobile__buy__variants__item__info ${variant === index && 'product-mobile__buy__variants__item__info--selected'}`}>
                    <div className="product-mobile__buy__variants__item__info__name">
                      {item.name}
                    </div>
                    <div className="product-mobile__buy__variants__item__info__price">
                      R$ {item.price}0
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className="product-mobile__buy__add-to-cart">
          <div
            ref={buttonRef}
            className="product-mobile__buy__add-to-cart__button"
            onClick={() => addProductToCart()}
          >
            ADICIONAR NA SACOLA &nbsp;&nbsp;<CartIcon />
          </div>
        </div>
        <div className="product-mobile__buy__qtd">
          <div className="product-mobile__buy__qtd__container">
            <img src={product.productInfo.img}
              alt={product && product.productInfo?.subText} />
          </div>
        </div>
        <div className="product-mobile__buy__product-text">
          {product && product.productInfo && product.productInfo.text && product.productInfo.text.length > 0 && (
            product.productInfo.text.map(item => ReactHtmlParser(item))
          )}
        </div>
        <p className="product-mobile__buy__anvisa">
          {product && ReactHtmlParser(product.productInfo?.subText)}
        </p>
      </div>
      {product && product.hasVariant && product.hasVariant.whoCanUse && (
        <div className="product__info__right__inner__calc product__info__right__inner__calc--mobile">
          <img className="product-mobile__buy__who-can-use"
            src="/assets/img/product-page-info/male-female-mobile.png" />
        </div>
      )}
      {product && (!product.hasVariant || !product.hasVariant.hideCep) && (
        <div className="product__info__right__inner__calc product__info__right__inner__calc--mobile">
          {product && product.justMen && (
            <div className='product__info__right__inner__calc__form__just-men--mobile'>
              {product && product.justMen && (
                <img className="product__info__right__inner__calc__form__just-men--mobile"
                  src={product && product.justMen.img}
                  alt={'male female'} />
              )}
            </div>
          )}
          <div className="product__info__right__inner__calc__form">
            <div className="product__info__right__inner__calc__form__input">
              <input
                className={`
                        product__info__right__inner__calc__form__input__element product__info__right__inner__calc__form__input__element--mobile
                        ${zipcode !== '' && 'product__info__right__inner__calc__form__input__element--hidden'}
                      `}
                type="text"
                placeholder='Digite o CEP de entrega'
                value={zipcode}
                onChange={(e) => (e.target.value && e.target.value.length <= 8 || !e.target.value) && setZipcode(keepOnlyNumbers(e.target.value))}
              />
            </div>
            <div className="product__info__right__inner__calc__form__button product__info__right__inner__calc__form__button--mobile">
              <button
                className="product__info__right__inner__calc__form__button__inner product__info__right__inner__calc__form__button__inner--mobile"
                onClick={() => onCalculateFreight()}
                disabled={zipcode?.length < 8}
              >
                <ArrowRightOutlined />
              </button>
            </div>
            <div className="product__info__right__inner__calc__form__container__text-shipping">
              {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.TEXT')}<strong>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.PRESCRIPTION')}</strong>
              <p>{translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.NO_PRESCRIPTION')}</p>
            </div>
            {freightData && !freightData.error && (
              <div className="product__info__right__inner__calc__form__freight-success">
                <div className="product__info__right__inner__calc__form__freight-success__city">
                  Região: {freightData.city}
                </div>
                <div className="product__info__right__inner__calc__form__freight-success__data">
                  Normal - {Number(freightData.deadline)} dias úteis - <strong>{toCurrencyLocale(freightData.freight)}</strong>
                </div>
              </div>
            )}
            {freightData && freightData.error && (
              <div>
                <div className="product__info__right__inner__calc__form__freight-error">
                  Querido The Lovers, ainda não operamos na sua região, mas queremos chegar ai! Acreditamos que
                  todos os brasileiros devam se cuidar de forma prática e segura.
                  <br />
                  Estamos trabalhando duro para atingirmos todo o Brasil até o final do ano.
                </div>
                <br />
                <div className="product__info__right__inner__calc__form__freight-normal">
                  {translate('COMPONENTS.PRODUCT.NEED_PRESCRIPTION.CONTACT_MESSAGE')}&nbsp;
                  <a href="mailto:memanda@themens.com.br">memanda@themens.com.br</a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="product-mobile__advantages">
        <div>
          {!!product?.advantagesHeader && (
            <h2>{product.advantagesHeader}</h2>
          )}
          <div className="product-mobile__advantages__items-box">
            {product && product.advantages && (
              product.advantages.map((item, index) =>
                <div key={index}
                  className="product-mobile__advantages__item">
                  <div className="product-mobile__advantages__item__img-container">
                    <img className="product-mobile__advantages__item__img-container__img"
                      src={item.icon}
                      alt="" />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {product && product.depoiments && (
        <div className="product-mobile__depoiments">
          <h3 className="product-mobile__depoiments__title">
            {product.depoiments.title}
          </h3>
          <div
            className="product-mobile__depoiments__draggable"
            style={depoimentsStyle}
          >
            <Slider ref={slider => (sliderRef = slider)}
              {...settings}>
              {product && product.depoiments && product.depoiments.ratings.map((item) =>
                <>
                  <div className="product-mobile__depoiments__container">
                    <span className="product-mobile__depoiments__container__stars">
                      {item.stars}
                    </span>
                    <p className="product-mobile__depoiments__container__text">
                      {item.text}
                    </p>
                    <p className="product-mobile__depoiments__container__name">
                      {item.name}
                    </p>
                  </div>
                </>
              )}
            </Slider>

          </div>
        </div>
      )}
      {product && product.commonQuestions && (
        <div className="product-mobile__questions">
          <div className="product-mobile__questions__image-container">
            <img
              className="product-mobile__questions__image-container__img"
              src={product.commonQuestions.banner}
              alt=""
            />
          </div>
          <div className="product-mobile__questions__container">
            {product.commonQuestions.questions.map((item, index) =>
              <div
                key={index}
                onClick={() => openFaq(index)}
                className="product-mobile__questions__container__item"
              >
                <span
                  className={
                    `product-mobile__questions__container__item__text ${faqOpen === index && 'product-mobile__questions__container__item__text--bold'}`
                  }
                >
                  {item.name}
                </span>
                <span className="product-mobile__questions__container__item__icon">
                  <ArrowDown />
                </span>
                {faqOpen === index && (
                  <p className="product-mobile__questions__container__item__description">
                    {ReactHtmlParser(item.description)}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {product && product.howWorks && (
        <div className="product-mobile__how-works">
          <h3 className='how_works_header'>
            Texto informativo com revisão literária
          </h3>
          <h1 className="product-mobile__how-works__title">{product.howWorks.title}</h1>
          <div className="product-mobile__how-works__image-container">
            <img className="product-mobile__how-works__image"
              src={product.howWorks.imgMobile}
              alt="" />
          </div>
          <div className="product-mobile__how-works__group">
            {product && product.howWorksInfo.map((item, index) =>
              <div key={index}
                className="product-mobile__how-works__item">
                <div className="product-mobile__how-works__item__content">
                  <h4 className="product-mobile__how-works__item__content__title">
                    {item.title}
                  </h4>
                  <p className="product-mobile__how-works__item__content__text">
                    {ReactHtmlParser(item.text)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {product && product.about && (
        <div className="product-mobile__about">
          {product.about.map((item, index) =>
            <div key={index}
              className="product-mobile__about__item">
              <div className="product-mobile__about__item__image-container">
                <img
                  className="product-mobile__about__item__image-container__img"
                  src={item.image}
                  alt=""
                />
              </div>
              <div className="product-mobile__about__item__content">
                <h4 className="product-mobile__about__item__content__title">
                  {item.title}
                </h4>
                <p className="product-mobile__about__item__content__text">
                  {ReactHtmlParser(item.text)}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {product && product.whoUses && (
        <div className="product-mobile__who-uses">
          <h3 className="product-mobile__who-uses__title">
            Quem usa
          </h3>
          <div
            className="product-mobile__who-uses__img-container"
            style={whoUsesStyle}
            draggable="true"
            onTouchStart={(e) => setTouchStart(e.changedTouches[0].clientX)}
            onTouchMove={(e) => drag(e.changedTouches[0].clientX)}
          >
            <img className="product-mobile__who-uses__img-container__image"
              src={product.whoUses.imageMobile}
              alt="" />
          </div>
        </div>
      )}
      {product && product.commonQuestions && (
        <div className="product-mobile__questions product-mobile__questions--faq">
          <h3 className="product-mobile__questions__title">
            Perguntas frequentes
          </h3>
          <div className="product-mobile__questions__container">
            {product.faq.map((item, index) =>
              <div
                key={index}
                onClick={() => openQuestion(index)}
                className="product-mobile__questions__container__item"
              >
                <span
                  className={
                    `product-mobile__questions__container__item__text ${questionsOpen === index && 'product-mobile__questions__container__item__text--bold'}`
                  }
                >
                  {item.title}
                </span>
                <span className="product-mobile__questions__container__item__icon">
                  <ArrowDown />
                </span>
                {questionsOpen === index && (
                  <p className="product-mobile__questions__container__item__description">
                    {ReactHtmlParser(item.description)}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="product-mobile__phrase">
        <img
          className="product-mobile__phrase__img"
          src="/assets/img/products-images/the-mens-delivery-phrase-mobile.png"
        />
      </div>

      {product?.otherInformations?.length && (
        <Container className='other-informations-box'>
          {product.otherInformations.map((info, index) => (
            <Row key={index}
              className="justify-content-center"><p className=''>{info}</p></Row>
          ))}
        </Container>
      )}

      {product?.warnings && (
        <div className='warnings-text-box'>
          <h1>{translate('PRODUCTS.WARNING_HEADER')}</h1>
          {product.warnings.map((warn, index) => <p key={index}>{warn}</p>)}
        </div>
      )}

      <div className="product-mobile__recomended">
        <h3 className="product-mobile__recomended__title">
          Recomendado para você
        </h3>
        <div
          className="product-mobile__recomended__container"
        >
          <Slider {...settings}
            centerPadding='1px' >
            <div className="product-mobile__recomended__container__item product-mobile__recomended__container__item--first">
              <div className="product-mobile__recomended__container__item__image-container">
                <img
                  className="product-mobile__recomended__container__item__image-container__img"
                  src="/assets/img/product-page-recomended/vita-gummy.png"
                  alt=""
                />
              </div>
              <div className="product-mobile__recomended__container__item__content">
                <span className="product-mobile__recomended__container__item__content__stars">
                  ★★★★★
                </span>
                <p className="product-mobile__recomended__container__item__content__price">
                  R$66,30
                </p>
                <h5 className="product-mobile__recomended__container__item__content__title">
                  Vita gummy
                </h5>
                <p className="product-mobile__recomended__container__item__content__text">
                  Nossa vita gummy contém todos os nutrientes necessários para fortalecer seu cabelo, pele e unhas, com um delicioso sabor de maçã verde e zero açúcar.
                </p>
                <div
                  className="product-mobile__recomended__container__item__content__button"
                  onClick={() => addProductToCart('2ad58d0e-5934-4f1a-9d51-8cba378122b6')}
                >
                  <span className="product-mobile__recomended__container__item__content__button__text">
                    ADICIONAR NA SACOLA
                  </span>
                  <span className="product-mobile__recomended__container__item__content__button__icon">
                    <CartIconMini className="product-mobile__recomended__container__item__content__button__icon__svg" />
                  </span>
                </div>
              </div>
            </div>
            <div className="product-mobile__recomended__container__item">
              <div className="product-mobile__recomended__container__item__image-container">
                <img
                  className="product-mobile__recomended__container__item__image-container__img"
                  src="/assets/img/product-page-recomended/chocosono.png"
                  alt=""
                />
              </div>
              <div className="product-mobile__recomended__container__item__content">
                <span className="product-mobile__recomended__container__item__content__stars">
                  ★★★★★
                </span>
                <p className="product-mobile__recomended__container__item__content__price">
                  R$59,90
                </p>
                <h5 className="product-mobile__recomended__container__item__content__title">
                  Chocosono - 5 caixas
                </h5>
                <p className="product-mobile__recomended__container__item__content__text">
                  A fórmula perfeita e saborosa para você ter uma boa noite de sono, dormindo profundamente e acordando revigorado.
                </p>
                <div
                  className="product-mobile__recomended__container__item__content__button"
                  onClick={() => addProductToCart('3c1ca3a0-8bb4-4419-aac8-ef2e1ed3c136')}
                >
                  <span className="product-mobile__recomended__container__item__content__button__text">
                    ADICIONAR NA SACOLA
                  </span>
                  <span className="product-mobile__recomended__container__item__content__button__icon">
                    <CartIconMini className="product-mobile__recomended__container__item__content__button__icon__svg" />
                  </span>
                </div>
              </div>
            </div>
            <div className="product-mobile__recomended__container__item">
              <div className="product-mobile__recomended__container__item__image-container">
                <img
                  className="product-mobile__recomended__container__item__image-container__img"
                  src="/assets/img/product-page-recomended/serum-vit.png"
                  alt=""
                />
              </div>
              <div className="product-mobile__recomended__container__item__content">
                <span className="product-mobile__recomended__container__item__content__stars">
                  ★★★★☆
                </span>
                <p className="product-mobile__recomended__container__item__content__price">
                  R$99,90
                </p>
                <h5 className="product-mobile__recomended__container__item__content__title">
                  Sérum Vit C + Ácido Hialurônico
                </h5>
                <p className="product-mobile__recomended__container__item__content__text">
                  Um creme que reduz, previne as linhas de expressão e deixa sua pele mais firme, macia e hidratada, dando a você um aspecto mais jovem e saudável.
                </p>
                <div
                  className="product-mobile__recomended__container__item__content__button"
                  onClick={() => addProductToCart('8cc5dd9a-732f-45cb-bfd1-2dbdbd462719')}
                >
                  <span className="product-mobile__recomended__container__item__content__button__text">
                    ADICIONAR NA SACOLA
                  </span>
                  <span className="product-mobile__recomended__container__item__content__button__icon">
                    <CartIconMini className="product-mobile__recomended__container__item__content__button__icon__svg" />
                  </span>
                </div>
              </div>
            </div>
            <div className="product-mobile__recomended__container__item">
              <div className="product-mobile__recomended__container__item__image-container">
                <img
                  className="product-mobile__recomended__container__item__image-container__img"
                  src="/assets/img/product-page-recomended/tonico.png"
                  alt=""
                />
              </div>
              <div className="product-mobile__recomended__container__item__content">
                <span className="product-mobile__recomended__container__item__content__stars">
                  ★★★★
                </span>
                <p className="product-mobile__recomended__container__item__content__price">
                  R$64,90
                </p>
                <h5 className="product-mobile__recomended__container__item__content__title">
                  Tônico para barba
                </h5>
                <p className="product-mobile__recomended__container__item__content__text">
                  Um creme que reduz, previne as linhas de expressão e  deixa sua pele mais firme, macia e hidratada, dando a você um aspecto mais jovem e saudável.
                </p>
                <div
                  className="product-mobile__recomended__container__item__content__button"
                  onClick={() => addProductToCart('8cc5dd9a-732f-45cb-bfd1-2dbdbd462719')}
                >
                  <span className="product-mobile__recomended__container__item__content__button__text">
                    ADICIONAR NA SACOLA
                  </span>
                  <span className="product-mobile__recomended__container__item__content__button__icon">
                    <CartIconMini className="product-mobile__recomended__container__item__content__button__icon__svg" />
                  </span>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProductMobile;

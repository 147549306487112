import { CouponActions } from '../actions';

const initialState = {
  couponByTitle: null,
  couponByID: null,
};

const couponReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CouponActions.ACTION_GET_COUPON_BY_TITLE:
      state = {
        ...state,
        couponByTitle: action.payload,
      };
      return state;
    case CouponActions.ACTION_GET_COUPON_BY_ID:
      state = {
        ...state,
        couponByID: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default couponReducer;
